import { Component } from "react";
import { CoverColor } from "../CoverThumbnail";
import BackgroundColorPanel from "./backgroundColorPanel";
import { PickerPanel } from "./styles";

export interface Props {
  onChangeColor?: (color: CoverColor) => void;
  selectedColor: CoverColor;
}

export interface State {
  selectedColor: CoverColor;
  editColorActive: boolean;
}

class ColorPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editColorActive: true,
      selectedColor: this.props.selectedColor,
    };
  }

  public onChangeColorHandler = (color: CoverColor) => {
    this.setState({ selectedColor: color });
    if (this.props.onChangeColor) {
      this.props.onChangeColor(color);
    }
  };

  public render(): JSX.Element | any {
    return (
      <PickerPanel {...this.state}>
        <div className="color-picker">
          <BackgroundColorPanel
            onChangeColor={this.onChangeColorHandler}
            selectedColor={this.state.selectedColor}
          />
        </div>
      </PickerPanel>
    );
  }
}

export default ColorPanel;
