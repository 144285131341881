import React, { useState } from "react";

export const SiteContext = React.createContext({
  isPreview: false,
  setIsPreview: () => {},
});

export const SiteContextProvider = (props: { children?: any }) => {
  const [isPreviewing, setIsPreviewing] = useState(false);

  const isPreviewingHandler = () => {
    setIsPreviewing(true);
  };

  return (
    <SiteContext.Provider
      value={{ setIsPreview: isPreviewingHandler, isPreview: isPreviewing }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

export default SiteContextProvider;
