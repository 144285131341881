import * as H from "history";

const DEFAULT_SECTION = "screens";

export function getSection(location: Pick<H.Location<{}>, "pathname">): string {
  const { pathname } = location;

  if (pathname === "/") {
    return DEFAULT_SECTION;
  }

  const section = location.pathname.split("/")[1];
  return section;
}
