import {
  Icon,
  Loader,
  Thumbnail,
} from "@screencloud/screencloud-ui-components";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import DateTime from "../DateTime";
import { Styled } from "./styles";

export enum CastItemAction {
  Navigate = "navigate",
  Stop = "stop",
}

export type CastItemOnCallBack = (
  action: CastItemAction,
  data: { [key: string]: string }
) => void;

export interface CastItemProps {
  itemId: string;
  contentName: string;
  contentImage: string;
  screenCount: number;
  mimeType: string;
  color?: string;
  coverStyle?: { [key: string]: any };
  duration?: number;
  castDate: string;
  onCallback: CastItemOnCallBack;
}

export interface CastItemState {
  isStopping: boolean;
}

export class CastItem extends React.PureComponent<
  CastItemProps,
  CastItemState
> {
  public static contextType = AppContext;
  public context: AppContextType;
  public state = {
    isStopping: false,
  };

  constructor(props: CastItemProps) {
    super(props);
  }

  public getDateTimeFormat = (dateTime: string) => {
    return format(new Date(dateTime), "dd MMM YYYY H:mm a");
  };

  public getDurationFormat = (seconds: number) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substring(11, 19);
  };

  public handleCastStop = (event: React.SyntheticEvent<any>) => {
    this.props.onCallback(CastItemAction.Stop, {
      id: this.props.itemId,
      name: this.props.contentName,
      type: this.props.mimeType,
    });
    this.setState({
      isStopping: true,
    });
  };

  public handleLinkClick = (event: React.SyntheticEvent<any>) => {
    this.props.onCallback(CastItemAction.Navigate, {
      id: this.props.itemId,
      name: this.props.contentName,
    });
  };

  public render() {
    return (
      <Styled className="cast-item">
        <div className="media-core" onClick={this.handleLinkClick}>
          <div className="media-alpha">
            {this.props.mimeType !== "playlist" &&
            this.props.mimeType !== "channel" ? (
              <div className="thumbnail-preview">
                <Thumbnail
                  size="medium"
                  src={this.props.contentImage ? this.props.contentImage : ""}
                />
              </div>
            ) : this.props.mimeType === "playlist" ? (
              <div
                className="thumbnail-preview"
                style={{ backgroundColor: this.props.color }}
              >
                <div className="thumbnail">
                  <div className="wrapper">
                    <Icon name="playlist" />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="thumbnail-preview"
                style={this.props.coverStyle}
              />
            )}

            <div className="media-title">
              <h3 title={this.props.contentName}>
                <span>{this.props.contentName}</span>
              </h3>
              {isValid(parseISO(this.props.castDate)) && (
                <FormattedMessage
                  id="ui_component.cast_item.recent_casted_on_date"
                  defaultMessage="Casting since {castDate}"
                  values={{
                    castDate: <DateTime value={this.props.castDate} />,
                  }}
                />
              )}
              <span className="meta-responsive">
                {this.props.mimeType}{" "}
                {this.props.duration ? (
                  ` · ${this.getDurationFormat(this.props.duration)}`
                ) : (
                  <FormattedMessage
                    id="ui_component.cast_item.cast_duration_indefinitely"
                    defaultMessage="Indefinitely"
                  />
                )}
              </span>
            </div>
          </div>
          <div className="media-duration">
            <span>
              {this.props.duration ? (
                this.getDurationFormat(this.props.duration)
              ) : (
                <FormattedMessage
                  id="ui_component.cast_item.cast_duration_indefinitely"
                  defaultMessage="Indefinitely"
                />
              )}
            </span>
          </div>
          <div className="media-kind">
            <span>{this.props.mimeType}</span>
          </div>
          <div className="media-screens">
            <span>
              <Icon name="casting" />
              <span className="screen-count">{this.props.screenCount}</span>
            </span>
          </div>
        </div>
        {this.context.currentPermissions.validateCurrentSpace(
          "screen",
          "cast"
        ) && (
          <div data-testid="media-action" className="media-action">
            {!this.state.isStopping ? (
              <div
                title="Stop casting"
                className="screen-stop"
                onClick={this.handleCastStop}
              >
                <Icon name="stop" />
              </div>
            ) : (
              <div title="stopping" className="screen-pending">
                <Loader inline active size="small" />
              </div>
            )}
          </div>
        )}
      </Styled>
    );
  }
}

export default CastItem;
