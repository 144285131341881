import { Popover } from "@screencloud/screencloud-ui-components";
import classNames from "clsx";
import { useStateInnerWidth } from "../../../hooks/useStateInnerWidth";
import ImageLoader from "../../ImageLoader";
import { PopoverStyled, Styled } from "./styles";
import { useGetAllContentIdsInSpace } from "src/hooks/entities/space/useGetAllContentIdsInSpace";
import { useAppContext } from "src/hooks/useAppContext";
import {
  getTotalNumberOfPlaylistContentItems,
  mapPlaylistToContentListPreview,
} from "src/helpers/playlistHelper";
import { Playlist } from "src/types.g";
import { useEffect, useState } from "react";
import { ListContentItem } from "src/constants/constants";
export interface PlaylistItemType {
  id: string;
  url: string;
  content: any;
  contentType: string;
  contentTitle: string;
}
export interface PlaylistItemsPreviewProps {
  className?: string;
  isInPicker?: boolean;
  playlist: Playlist;
  ["data-testid"]?: string;
  onPreviewClick: (content: any, contentType: string) => void;
  readOnly?: boolean;
}

export const calculatePreviewItemBreak = (
  width: number,
  isInPicker?: boolean
) => {
  if (isInPicker) {
    return 4;
  } else if (width > 2559) {
    return 20;
  } else if (width > 1919) {
    return 12;
  } else if (width > 1599) {
    return 8;
  } else if (width > 1151) {
    return 6;
  } else {
    return 4;
  }
};

export const getContentLisByActiveIds = (
  list: ListContentItem[],
  ids: string[]
) => {
  if (list.length === 0) {
    return [];
  }
  if (ids.length === 0) {
    return list;
  }
  const orderedList: ListContentItem[] = [];
  const remainingItems: ListContentItem[] = [];

  for (const item of list) {
    if (ids.includes(item.content._ref.id)) {
      orderedList.push(item);
    } else {
      remainingItems.push(item);
    }
  }

  // Append the remaining items to the ordered list to maintain the total count
  orderedList.push(...remainingItems);

  return orderedList;
};

const getActiveContentInPlaylist = (playlist: Playlist) => {
  return [
    ...(playlist.filesByPlaylistId?.nodes ?? []).map((item) => item.id),
    ...(playlist.appInstancesByPlaylistId?.nodes ?? []).map((item) => item.id),
    ...(playlist.linksByPlaylistId?.nodes ?? []).map((item) => item.id),
    ...(playlist.sitesByPlaylistId?.nodes ?? []).map((item) => item.id),
  ];
};

const PlaylistItemsPreview = (
  props: PlaylistItemsPreviewProps
): JSX.Element => {
  const { className, playlist, isInPicker } = props;
  const [totalItems, setTotalItems] = useState(0);

  const width = useStateInnerWidth();
  const context = useAppContext();

  /**
   * this is used to calculate the total number of items in the playlist
   */
  const allActiveContentIds = useGetAllContentIdsInSpace(
    context.currentSpace?.id
  );

  useEffect(() => {
    const totalItems = getTotalNumberOfPlaylistContentItems(
      playlist,
      allActiveContentIds ?? 0
    );
    setTotalItems(totalItems);
  }, [allActiveContentIds]);

  const previewItemBreak = calculatePreviewItemBreak(width, isInPicker);

  /**
   * this is used to calculate the order of the items in the playlist (playlist got only 10 items for each type to preview)
   * to be more clear, search for PlaylistByIdForPlaylistListing fragment
   */
  const activeContentInPlaylist = getActiveContentInPlaylist(playlist);

  /**
   * need to grab the content base on activeContentInPlaylist into the beginning of the list
   *
   * example:
   * playlist.content.list = [10,6,3,1,5,2]
   * activeContentInPlaylist = [1,2,3]
   * orderedContentList = [3,1,2,10,6,5]
   * 10, 6, 5 may not be in the activeContentInPlaylist because it is not in the first 10 items of the content
   * so we need to append it to the end of the list
   */
  const orderedContentList = getContentLisByActiveIds(
    playlist.content.list,
    activeContentInPlaylist
  );

  const showThumbnail = mapPlaylistToContentListPreview(
    orderedContentList.slice(0, isInPicker ? 4 : previewItemBreak),
    playlist,
    context.secureMediaPolicy
  );

  let previewLeftMargin = 0;
  const previewContents = showThumbnail.map((item, index) => {
    const thumbnailSource = item?.content.fileByThumbnailFileId?.source;
    const iconUrl = thumbnailSource ? thumbnailSource : item?.url;
    previewLeftMargin = index * 15;
    return (
      <Popover
        key={`playlist-preview-firt-row-${index}`}
        inverted
        trigger={
          <div
            onClick={() =>
              !props.readOnly &&
              props.onPreviewClick(item?.content, item?.contentType!)
            }
            className={classNames("trigger-preview", {
              ["preview-read-only"]: props.readOnly,
            })}
            style={{
              zIndex: showThumbnail.length - index,
            }}
          >
            <ImageLoader src={iconUrl} title={item?.contentTitle} />
          </div>
        }
        content={
          <PopoverStyled>
            <img src={iconUrl} title={item?.contentTitle} />
          </PopoverStyled>
        }
        className="playlist-item-thumbnail"
        position="top center"
      />
    );
  });
  return (
    <Styled
      data-testid={props["data-testid"] ?? "playlist-items-preview"}
      className={className}
    >
      {previewContents}
      {totalItems > previewItemBreak && (
        <span
          className="more-preview"
          style={{ left: previewLeftMargin + 45 + "px" }}
        >
          +{totalItems > 99 ? "99" : totalItems - previewItemBreak}
        </span>
      )}
    </Styled>
  );
};

export default PlaylistItemsPreview;
