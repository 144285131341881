import { uniqBy } from "lodash";
import { fieldsPolicyType } from "./apolloClient";

export const allScreensField: fieldsPolicyType = {
  allScreens: {
    keyArgs: ["filter", "orderBy"],
    merge(existing = { nodes: [] }, incoming, { mergeObjects }) {
      if (existing.nodes.length === 0) {
        return incoming;
      }

      const nodes = uniqBy([...existing.nodes, ...incoming.nodes], "__ref");

      return {
        ...mergeObjects(existing, incoming),
        nodes,
        pageInfo: incoming.pageInfo,
      };
    },
  },
};
