import {
  PickerCustomText,
  PickerDisplayMode,
  PickerFileMetadata,
} from "filestack-js/build/main/lib/picker";
import { Component } from "react";
import { appConfig } from "../../appConfig";
import client from "../../helpers/filestackHelper";
import {
  FilestackAllowedFileExtensionsWithAudio,
  FileStackSource,
} from "../../constants/constants";
export interface Props {
  onUploadSuccess?: (res: PickerFileMetadata[]) => void;
}

export interface State {
  activeIndex: string;
}

class ImagesPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    this.onPickerReady();
  }

  public onPickerReady = () => {
    client
      .picker({
        accept: FilestackAllowedFileExtensionsWithAudio,
        container: "#droppane",
        customText: {
          "My Device": "",
          "Select Files to Upload": "Select Files to Upload",
          "or Drag and Drop, Copy and Paste Files":
            "Drop your first video, photo or document here",
        } as PickerCustomText,
        displayMode: PickerDisplayMode.inline,
        dropPane: {
          customText:
            "Select File to Upload \n or Drop your video, photo or document here",
          onSuccess: this.onUploadSuccess,
          overlay: false,
        },
        fromSources: FileStackSource,
        maxFiles: 1,
        rootId: "filepicker",
        storeTo: {
          access: "public",
          container: appConfig.uploadsBucket,
          location: appConfig.uploadsLocation,
          path: "",
          region: appConfig.s3Region,
        },
        transformations: {
          crop: {
            aspectRatio: 450 / 600,
            force: true,
          },
          force: true,
          rotate: true,
        },
        uploadConfig: {
          intelligent: false,
        },
        uploadInBackground: false,
      })
      .open();
  };

  public onUploadSuccess = async (res: PickerFileMetadata[]) => {
    if (this.props.onUploadSuccess) {
      this.props.onUploadSuccess(res);
    }
  };

  public render(): JSX.Element | any {
    return (
      <div
        className="image-container"
        data-testid="image-container"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <div className="droppane-wrapper">
          <div id="droppane" />
        </div>
      </div>
    );
  }
}

export default ImagesPanel;
