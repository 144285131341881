import {
  useDeleteLink,
  UseDeleteLink,
} from "../../hooks/entities/link/useDeleteLink";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  AllTagsQueryHookResult,
  CastByCastIdFragment,
  CreateCastsByLinkIdMutationFn,
  Exact,
  LinkByIdQueryHookResult,
  LinkByIdQueryVariables,
  Maybe,
  Scalars,
  ScreenCastStopInput,
  SetScreenContentByLinkIdMutationFn,
  UpdateLinkByIdMutationFn,
  UpdateLinkIsFavoriteMutationFn,
  useAllTagsQuery,
  useCreateCastsByLinkIdMutation,
  useLinkByIdQuery,
  useSetScreenContentByLinkIdMutation,
  useUpdateLinkByIdMutation,
  useUpdateLinkIsFavoriteMutation,
} from "../../types.g";
import { compose } from "../../utils/compose";
import { useScreenCastStop } from "../../hooks/useStopCasts";
import { ComponentType } from "react";

export interface LinkProps extends RouteComponentProps<any>, UseDeleteLink {
  linkById?: NonNullable<LinkByIdQueryHookResult["data"]>["linkById"];
  refetch: LinkByIdQueryHookResult["refetch"];
  linkId: Scalars["UUID"];
  getAllTags: AllTagsQueryHookResult;
  createCastsByLinkId: CreateCastsByLinkIdMutationFn;
  updateLinkById: UpdateLinkByIdMutationFn;
  updateLinkIsFavorite: UpdateLinkIsFavoriteMutationFn;
  screenCastStop: (
    stopCastsInput: Exact<{
      input: ScreenCastStopInput;
    }>,
    castItem?: Maybe<CastByCastIdFragment>
  ) => Promise<any>;
  setContentByLinkId: SetScreenContentByLinkIdMutationFn;
}

export const withData = compose(
  withRouter,
  (Component: ComponentType<LinkProps>) => (props: LinkProps) => {
    const { linkId } = props;

    const queryVar: LinkByIdQueryVariables = {
      id: linkId,
    };

    const linkByIdData = useLinkByIdQuery({
      variables: queryVar,
      fetchPolicy: "cache-and-network",
    });
    const { screenCastStop } = useScreenCastStop();
    const getAllTags = useAllTagsQuery();
    const [updateLinkById] = useUpdateLinkByIdMutation();
    const [updateLinkIsFavorite] = useUpdateLinkIsFavoriteMutation();
    const [setContentByLinkId] = useSetScreenContentByLinkIdMutation();
    const [createCastsByLinkId] = useCreateCastsByLinkIdMutation();
    return (
      <Component
        {...props}
        linkById={linkByIdData.data?.linkById}
        refetch={linkByIdData.refetch}
        getAllTags={getAllTags}
        updateLinkById={updateLinkById}
        updateLinkIsFavorite={updateLinkIsFavorite}
        setContentByLinkId={setContentByLinkId}
        createCastsByLinkId={createCastsByLinkId}
        {...useDeleteLink()}
        screenCastStop={screenCastStop}
      />
    );
  }
);
