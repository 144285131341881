import styled from "styled-components";

export const Styled = styled.div`
  background: var(--color-grey20);
  border-radius: 0 0 8px 8px;
  height: 100%;
  padding: 0 20px 20px;
  overflow: auto;

  .sub-heading {
    display: flex;
    gap: 10px;
    padding: 20px 0;
    width: 100%;

    .badge {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background: var(--color-white-100);
      border: 1px solid var(--color-grey40);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: var(--radius-8);

      i {
        width: 30px;
        height: 30px;
        background: var(--color-grey100);
      }
    }
  }

  .text {
    width: 100%;

    .title {
      font-size: 16px;
      line-height: 1.5;
      color: var(--color-grey100);
    }

    .sub-title {
      font-size: 14px;
      line-height: 1.4285;
      color: var(--color-grey70);
    }
  }

  .subheader {
    border-bottom: 1px solid var(--color-grey40);
  }

  .admin-settings > .select-all {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    align-items: center;
  }

  .personal-notifications > .personal-email {
    padding-left: 50px;
    justify-content: start;
    gap: 10px;
    display: flex;
    align-items: center;
    color: var(--color-grey100);
    padding-bottom: 30px;

    > i {
      background-color: var(--color-grey100);
    }

    .sub-heading {
      padding-bottom: 5px;
    }
  }

  .notification-threshold {
    margin-left: 50px;
    margin-bottom: 20px;

    &__options {
      margin-top: 10px;
    }
  }
`;
