import {
  File,
  Playlist,
  UpdatePlaylistMutationVariables,
  useUpdatePlaylistMutation,
} from "../../../types.g";
import { genUuid } from "@screencloud/uuid";

export const createContentRefObject = (file: File) => {
  let videoFileOutput;
  if ((file.mimetype ?? "").startsWith("video") && file.fileOutputsByFileId) {
    videoFileOutput = file!.fileOutputsByFileId.nodes.find((fileOutput) =>
      (fileOutput.mimetype ?? "").startsWith("video")
    );
  }
  const duration = videoFileOutput ? videoFileOutput.metadata.duration : 0;
  const meta = videoFileOutput ? videoFileOutput.metadata : file!.metadata; // It may not be empty if the file type is video
  return {
    content: {
      _ref: {
        id: file!.id,
        type: "file",
      },
      meta,
      props: {
        duration,
      },
    },
    list_id: genUuid(),
  };
};

export function useAddFilesToPlaylistMutation() {
  const [updatePlaylistMutation] = useUpdatePlaylistMutation();
  const addFilesToPlaylist = async ({
    files,
    playlist,
  }: {
    files: File[];
    playlist: Playlist;
  }) => {
    const allContents = files.map(createContentRefObject);
    const list = [...playlist.draft?.content.list, ...allContents];
    const duration = allContents.reduce(
      (sum, current) => current.content.props.duration + sum,
      0
    );
    const updatePlaylistMutationVariables: UpdatePlaylistMutationVariables = {
      input: {
        content: {
          ...playlist.draft?.content,
          list,
          props: {
            ...playlist.draft?.content.props,
            total_durations:
              playlist.draft?.content.props.total_durations + duration,
            total_items: list.length,
          },
        },
        id: playlist.id,
      },
    };
    return updatePlaylistMutation({
      variables: updatePlaylistMutationVariables,
    });
  };

  return {
    addFilesToPlaylist,
  };
}
