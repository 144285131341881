import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export interface MediaItemStyle {
  totalDescriptionCols?: number;
}

export const StyledMediaItem = styled.div<MediaItemStyle>`
  border-bottom: 1px solid var(--color-gray-100);
  cursor: pointer;
  padding: var(--spacing-sm) 0;

  .media-share {
    opacity: 0;
  }

  .media-share.isShared {
    opacity: 1;
  }

  div,
  span,
  p,
  i {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  &.media-item {
    &:hover {
      .media-share {
        opacity: 1;
      }
    }

    display: flex;
    padding-right: var(--spacing-sm);
    justify-content: space-between;

    &.side-bar {
      .media-core {
        .media-alpha {
          width: 100%;
        }
      }
    }
    .media-core {
      align-items: center;
      display: flex;
      width: calc(100% - 64px);

      [class^="media-"] {
        text-align: center;

        span {
          color: var(--color-gray-300);
          font-size: 0.875em;
          font-style: italic;

          span {
            font-size: 1em;
          }
        }
      }

      .media-alpha {
        align-items: center;
        ${Theme.default.borderRadius};
        cursor: pointer;
        display: flex;
        flex: 0 0 auto;
        width: 32%;

        .thumbnail-preview {
          width: 56px;
          height: 56px;
          position: relative;

          .thumbnail {
            width: 100%;
            height: 100%;

            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              right: auto;
              opacity: 0;
              transition: opacity 0.2s ease-in-out;
              width: 100%;
              height: 100%;
              ${Theme.default.borderRadius};
              overflow: hidden;
              background: rgba(0, 0, 0, 0.2);
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              i {
                margin: 0;
              }
              &:hover {
                opacity: 1;
              }
            }
          }
        }

        .media-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 0 0 8px;
          width: calc(100% - 56px);

          * {
            text-align: left;
          }

          h3 {
            display: inline-flex;
            padding: 0;
            margin-bottom: 0;
            font-weight: normal;
            white-space: nowrap;
            border-bottom: 1px solid transparent;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              font-size: 1em;
              color: var(--color-foreground);
              font-style: normal;
              line-height: 24px;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: calc(100% - 24px);

              :hover {
                color: var(--color-foreground-active);
              }
            }

            i {
              background-color: var(--color-gray-200);
              cursor: pointer;
              display: none;
              align-self: center;
              width: 24px;
              mask-size: 16px;
              mask-position: 5px;
              position: relative;
              z-index: 1;
              flex-shrink: 0;
            }
          }

          div {
            display: flex;

            span {
              font-size: 12px;
              line-height: 16px;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .media-item__meta {
              display: none;
              overflow: visible;
            }

            .media-item__date {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .inline-input {
            font-size: 1em;
            padding: 0;
            margin: 0 auto;
          }
        }

        :hover {
          .media-title {
            h3 {
              i {
                display: inline-block;
              }
            }
          }
        }
      }

      .media-scheduled,
      .media-starred,
      .media-casting,
      .media-duration,
      .media-kind,
      .media-size,
      .media-tags,
      .media-share {
        width: ${(props) =>
          props.totalDescriptionCols && `${68 / props.totalDescriptionCols}%`};
      }
      .media-kind,
      .media-type {
        .failed-msg {
          color: ${Theme.color.error};
        }
      }
      .media-kind {
        span {
          display: inline-flex;
          align-items: center;
          i {
            margin-left: 5px;
            height: 16px;
            width: 24px;
          }
        }
      }
      .media-scheduled {
        .scheduled {
          cursor: pointer;
          display: flex;
          align-items: center;
          position: relative;
          width: 32px;
          height: 32px;
          justify-content: center;
          cursor: pointer;
          border-radius: var(--radius-md);

          &.warning i {
            background-color: var(--color-red-500);
            width: 16px;
            height: 16px;
          }

          i {
            width: 20px;
            height: 20px;
            background-color: var(--color-green-500);
          }

          i[name="warning"] {
            width: 12px;
            height: 12px;
            right: 0;
          }

          :hover {
            background: var(--color-gray-100);
          }
        }
      }

      .media-starred {
        .toggle.star {
          opacity: 0;

          &.active {
            opacity: 1;
          }
        }
      }

      .media-casting {
        .button {
          padding: 0 6px;
          display: flex;
          align-items: center;
          width: auto;
        }

        .screen-count {
          font-style: normal;
          color: var(--color-gray-950);
        }
      }
    }

    .media-delete {
      display: flex;
      align-items: center;
      justify-content: center;

      > button {
        opacity: 0;
      }
    }
    .folder-delete {
      width: 32px;
      visibility: hidden;
    }
    .media-options {
      display: flex;
      align-items: center;
      justify-content: center;

      > i.checked {
        background-color: ${Theme.color.darkGrey};
      }

      .media-dropdown {
        opacity: 0;

        &.active {
          opacity: 1;
        }

        .menu {
          right: 0;
          left: auto;
        }
      }
      .media-button {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          background-color: var(--color-gray-200);
          &.checked {
            background-color: var(--color-gray-950);
          }
          :hover {
            background-color: var(--color-gray-950);
          }
        }

        &.search {
          :hover {
            background: transparent;
          }
        }
      }
    }

    :hover {
      background: rgba(0, 0, 0, 0.01);

      .media-core {
        .media-alpha {
          .media-title {
            h3 {
              i {
                :hover {
                  background-color: var(--color-gray-950);
                }
              }
            }
          }
        }

        .media-starred {
          .toggle.star {
            opacity: 1;
            cursor: pointer;
          }
        }
      }

      .media-delete {
        > button {
          opacity: 1;
        }
      }
      .media-options {
        .media-dropdown {
          opacity: 1;
        }
      }
    }
  }

  &.media-folder {
    cursor: pointer;
    .media-core {
      .media-alpha {
        .thumbnail-preview {
          .thumbnail.folder {
            .wrapper {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .media-starred {
        margin-left: 9%;
      }
    }
    &.selectable {
      .media-core {
        .media-starred {
          margin-left: ${(props) =>
            props.totalDescriptionCols &&
            `${(68 / props.totalDescriptionCols) * 2}%`};
        }

        .media-share {
          margin-left: ${(props) =>
            props.totalDescriptionCols &&
            `${(68 / props.totalDescriptionCols) * 4}%`};
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeXHD}) {
    &.media-item {
      .media-core {
        .media-alpha {
          width: 37%;
        }

        .media-scheduled,
        .media-starred,
        .media-casting,
        .media-duration,
        .media-kind,
        .media-size,
        .media-tags,
        .media-share {
          width: ${(props) =>
            props.totalDescriptionCols &&
            `${63 / props.totalDescriptionCols}%`};
        }
      }

      &.media-folder {
        .media-core {
          .media-starred {
            margin-left: 10%;
          }
        }

        &.selectable {
          .media-core {
            .media-starred {
              margin-left: ${(props) =>
                props.totalDescriptionCols &&
                `${(63 / props.totalDescriptionCols) * 2}%`};
            }

            .media-share {
              margin-left: ${(props) =>
                props.totalDescriptionCols &&
                `${(63 / props.totalDescriptionCols) * 4}%`};
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.large}) {
    &.media-item {
      .media-core {
        .media-alpha {
          .media-title {
            div {
              .media-item__date {
                .author {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    &.media-item {
      .media-core {
        .media-alpha {
          width: 40%;

          .media-title {
            div {
              .media-item__date {
                display: initial;
              }
              .media-item__meta {
                display: none;
              }
            }
          }
        }

        .media-scheduled,
        .media-starred,
        .media-casting,
        .media-duration,
        .media-kind,
        .media-tags,
        .media-share {
          width: calc(60% / 7);
        }

        .media-size {
          display: none;
        }
      }

      &.media-folder {
        &.selectable {
          .media-core {
            .media-starred {
              margin-left: calc(60% / 7 * 2);
            }

            .media-share {
              margin-left: calc(60% / 7 * 3);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    &.media-item {
      .media-core {
        .media-alpha {
          width: 60%;

          .media-title {
            div {
              .media-item__date {
                display: none;
              }
              .media-item__meta {
                display: initial;
              }
            }
          }
        }

        .media-scheduled,
        .media-starred,
        .media-casting {
          width: 13.33%;
        }

        .media-size,
        .media-kind,
        .media-duration,
        .media-tags,
        .media-share {
          display: none;
        }
      }

      &.media-folder {
        .media-core {
          .media-starred {
            margin-left: 13.66%;
          }
        }

        &.selectable {
          .media-core {
            .media-starred {
              margin-left: 26.66%;
            }
          }
        }
      }

      .media-options {
        .media-dropdown {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    &.media-item {
      align-items: center;

      .media-core {
        width: calc(100% - 64px);

        .media-alpha {
          width: calc(100% - 160px);

          .thumbnail-preview {
            height: 40px;
            margin-left: 0;
            width: 40px;

            i {
              width: 16px;
              height: 16px;
            }

            .wrapper {
              img {
                height: 100%;
                object-fit: contain;
                width: 100%;
              }
            }
          }

          .media-title {
            h3 {
              font-size: 1em;
              height: auto;

              span {
                max-width: 100%;
              }
            }

            div {
              padding: 0;
            }
          }
        }

        .media-scheduled {
          width: 32px;
        }

        .media-starred,
        .media-casting {
          width: 64px;
        }
      }

      &.media-folder {
        &.selectable {
          .media-core {
            .media-starred {
              margin-left: 96px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    &.media-item {
      .media-core {
        .media-alpha {
          width: calc(100% - 64px);
        }

        .media-starred {
          display: none;
        }

        .media-casting,
        .media-scheduled {
          width: 32px;
        }
      }
    }
  }

  // Media New Folder
  &.media-new-folder {
    .inline-input input {
      margin-top: 0;
      width: 100%;
    }

    :hover {
      background: transparent;
    }

    .media-core {
      .media-alpha {
        :hover {
          background: transparent;
        }
      }
    }
  }

  :focus {
    outline: none;
  }

  &.readonly {
    .media-core {
      display: flex;

      .media-alpha {
        width: 50%;

        .thumbnail-preview {
          :hover {
            background-color: transparent;
          }
        }

        .media-title {
          h3 {
            :hover {
              background: transparent;
            }

            span {
              max-width: 100%;
            }

            i,
            i:hover {
              display: none;
            }
          }
          .media-item__date {
            .author {
              display: none;
            }
          }
        }
        :hover {
          .media-title {
            h3 {
              i {
                display: none;
              }
            }
          }
        }
      }

      .media-scheduled,
      .media-casting,
      .media-starred,
      .media-duration,
      .media-kind,
      .media-share {
        display: block;
        width: 10%;
      }

      .media-starred {
        .toggle.star {
          opacity: 0;
          display: none;

          &.active {
            opacity: 1;
            display: inline-flex;
          }
        }
      }

      .media-size {
        display: none;
      }
    }

    .media-checked {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      i {
        opacity: 0;
        background-color: ${Theme.color.darkGrey};
        z-index: 2;
      }

      :after {
        background: var(--color-gray-100);
        border-radius: 100%;
        content: "";
        display: block;
        height: 16px;
        position: absolute;
        width: 16px;
        z-index: 1;
      }
    }

    :hover {
      background-color: rgba(0, 0, 0, 0.01);

      .media-core {
        .media-starred {
          .toggle.star {
            opacity: 0;

            :hover {
              background: transparent;
            }

            &.active {
              opacity: 1;
            }
          }
        }
      }

      .media-checked {
        i {
          opacity: 0.2;
        }
      }
    }

    @media screen and (max-width: ${Theme.break.mediumAlt}) {
      .media-core {
        .media-alpha {
          width: 60%;
        }

        .media-scheduled,
        .media-casting,
        .media-starred {
          display: block;
          width: 13.33%;
        }

        .media-duration,
        .media-preview,
        .media-kind,
        .media-tags,
        .media-share {
          display: none;
        }
      }
    }

    @media screen and (max-width: ${Theme.break.small}) {
      .media-core {
        width: calc(100% - 64px);

        .media-alpha {
          width: calc(100% - 180px);

          .thumbnail-preview {
            margin-left: 20px;
          }

          .media-title {
            width: calc(100% - 70px);

            div {
              padding: 0;
            }
          }
        }

        .media-casting,
        .media-starred {
          width: 64px;
        }
      }

      .media-checked {
        width: 32px;
      }
    }

    @media screen and (max-width: ${Theme.break.tiny}) {
      .media-core {
        .media-alpha {
          width: calc(100% - 40px);
        }

        .media-starred,
        .media-casting {
          display: none;
        }
      }
    }
  }

  &.media-item.selectable {
    cursor: pointer;
  }

  @keyframes fadeIt {
    0% {
      background-color: var(--color-gray-100);
    }
    100% {
      background-color: transparent;
    }
  }

  &.new-folder-hilight {
    animation: fadeIt 4s ease-in-out 1;
  }

  &.selected:active,
  &.selected:hover,
  &.selected {
    background-color: var(--color-gray-100);
    box-shadow: none;
    border-top-color: var(--color-gray-200);

    .media-options {
      .media-dropdown {
        opacity: 1;

        i {
          background-color: ${Theme.color.base};
        }
      }
    }

    .media-checked {
      i {
        opacity: 1;
      }
    }
  }

  /* Small */
  &.media-item-small {
    min-height: 60px;

    .media-core {
      width: 100%;

      .media-alpha {
        width: calc(100% - 40px);

        .thumbnail-preview {
          width: 40px;
          height: 40px;

          .thumbnail {
            img {
              max-width: 40px;
              max-height: 40px;
            }

            &.folder {
              img {
                width: 28px;
              }
            }
          }
        }

        .media-title {
          h3 {
            font-size: 0.875em;
            height: auto;

            span {
              padding: 0;
              line-height: 16px;
              max-width: 100%;
            }
          }
        }

        :hover {
          background: transparent;
          cursor: inherit;

          .media-title {
            h3 {
              i {
                display: none;
              }
            }
          }
        }
      }

      .media-scheduled {
        width: auto;
      }

      .media-starred,
      .media-casting,
      .media-duration,
      .media-size,
      .media-kind,
      .media-tags,
      .media-share {
        display: none;
      }
    }
    .media-options {
      display: none;
    }
  }
`;
