import React from "react";
import { UUID } from "@screencloud/uuid";
import {
  Playlist,
  useSearchPlaylistPickerQuery,
  SearchPlaylistPickerQueryVariables,
} from "src/types.g";
import { shouldUseSpecificSpaceId } from "src/utils/shouldUseSpecificSpaceId";

import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useLoadMore } from "src/hooks/useLoadMore";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";

export interface UseSearchPlaylistPicker {
  playlists: Playlist[];
  loading: boolean;
  renderFetchMoreButton: React.ReactNode;
}

export function useSearchPlaylistPicker(query: string, spaceId?: UUID) {
  const variables: SearchPlaylistPickerQueryVariables = {
    query,
    spaceId: shouldUseSpecificSpaceId(spaceId),
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
  };

  const { data, fetchMore, loading } = useSearchPlaylistPickerQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !query,
  });

  const playlistNodes = (data?.searchPlaylist?.nodes ?? []) as Playlist[];
  const playlistTotalCount = data?.searchPlaylist?.totalCount;
  const isQueryHasNextPage = data?.searchPlaylist?.pageInfo.hasNextPage;
  const endCursor = data?.searchPlaylist?.pageInfo.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(playlistNodes?.length),
    Number(playlistTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) && playlistNodes.length! < playlistTotalCount!,
    loadMore
  );

  return {
    playlists: playlistNodes,
    loading,
    renderFetchMoreButton: fetchMoreButton,
  };
}
