import { isUuid } from "@screencloud/uuid";
export const SHARE_BY_OTHER_FOLDER_ID = "shared";

export const shouldRedirectToRootFolderId = (folderId: string) => {
  return !(folderId === SHARE_BY_OTHER_FOLDER_ID || isUuid(folderId));
};

export const isInRootSharedFolderFromOtherSpaces = (folderId: string) => {
  return folderId === SHARE_BY_OTHER_FOLDER_ID;
};

/**
 * We do not subscribe in case that folder id is non uuid shared/search
 *
 * @param folderId
 */
export const shouldSubscribeToLiveUpdate = (folderId: string) => {
  return isUuid(folderId);
};

/**
 * // TODO : We do not support lives update in case of root shared folder from other spaces yet
 */
export const isSupportLiveUpdate = (folderId: string) => {
  return (
    folderId !== SHARE_BY_OTHER_FOLDER_ID &&
    shouldSubscribeToLiveUpdate(folderId)
  );
};
