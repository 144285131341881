import { Icon } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { generateImgixThumbnail } from "../../helpers/mediaHelper";
import { Site } from "../../types.g";
import { SiteListItemActions } from "../SiteListItem";
import { StyledItem } from "./styles";

export type SiteItemCallBack = (
  action: SiteListItemActions,
  value: Partial<Site> | string | boolean,
  event?: React.SyntheticEvent
) => void;

export interface SiteContentItemProps {
  onClickSiteCallback: SiteItemCallBack;
  site: Partial<Site>;
  className?: string;
  secureMediaPolicy: string | undefined;
}

class SiteContentItem extends React.Component<SiteContentItemProps, {}> {
  public handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (event && (event.shiftKey || event.metaKey || event.ctrlKey)) {
      this.props.onClickSiteCallback(
        SiteListItemActions.SELECTED,
        this.props.site,
        event
      );
    } else {
      this.props.onClickSiteCallback(
        SiteListItemActions.PREVIEW,
        this.props.site,
        event
      );
    }
  };
  public handleAddSiteItem = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (event && (event.shiftKey || event.metaKey || event.ctrlKey)) {
      this.props.onClickSiteCallback(
        SiteListItemActions.SELECTED,
        this.props.site,
        event
      );
    } else {
      this.props.onClickSiteCallback(
        SiteListItemActions.ADD,
        this.props.site,
        event
      );
    }
  };

  public render() {
    const { id, name, fileByThumbnailId } = this.props.site;
    const thumbnail = fileByThumbnailId
      ? generateImgixThumbnail(fileByThumbnailId, this.props.secureMediaPolicy)
      : "";
    return (
      <StyledItem className={this.props.className} onClick={this.handleOnClick}>
        <div className="list" key={id}>
          <div className="site-thumbnail" onClick={this.handleAddSiteItem}>
            <img src={thumbnail} className="thumbnail" />
            <div className="overlay">
              <Icon name="plus" color="#fff" />
            </div>
          </div>

          <div className="site-info">
            <p title={name} className="site-title">
              {name}
            </p>
          </div>
        </div>
      </StyledItem>
    );
  }
}

export default SiteContentItem;
