import { DataValue, withApollo } from "@apollo/client/react/hoc";
import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ApolloClient, FetchResult } from "@apollo/client";
import {
  MutationFunction,
  MutationResult,
} from "@apollo/client/react/types/types";
import { debounce } from "lodash";
import { FolderPathItem } from "../../../components/Media/mediaBreadCrumb";
import { useMedialist, UseMediaList } from "../../../hooks/useMediaList";

import {
  CastByCastIdFragment,
  CreateCastsByFileIdMutationFn,
  CreateFileMutationFn,
  CreateFolderMutation,
  CreatePlaylistInput,
  Exact,
  File,
  Folder,
  FolderByIdProps,
  Maybe,
  Playlist,
  Scalars,
  ScreenCastStopInput,
  SearchMediaQuery,
  SetScreenContentByFileIdMutationFn,
  UpdateFileByIdMutationFn,
  UpdatePlaylistMutation,
  UpdatePlaylistMutationVariables,
  UpdateUserSettingsMutationFn,
  useCreateCastsByFileIdMutation,
  useSetScreenContentByFileIdMutation,
  useUpdateFileByIdMutation,
  useUpdatePlaylistMutation,
  useUpdateUserSettingsMutation,
} from "../../../types.g";
import { compose } from "../../../utils/compose";
import { MediaListComponentProps } from "./media";
import { useBreadCrumbFolderPathItems } from "./useBreadCrumbFolderPathItems";
import {
  CreateNewFolderVariables,
  useCreateNewFolderMutation,
} from "./useCreateNewFolderMutation";
import {
  useUpdateFolderIsFavourite,
  UpdateFolderIsFavourite,
} from "../hooks/useUpdateFolderIsFavourite";
import {
  DeleteFolder,
  useDeleteFolder,
} from "../../../hooks/entities/folder/useDeleteFolder";
import { useDeleteFolders } from "../../../hooks/entities/folder/useDeleteFolders";
import { useRenameFolder, RenameFolder } from "../hooks/useRenameFolder";
import { useMoveFolder, MoveFolder } from "../hooks/useMoveFolder";
import {
  UpdateFileIsFavourite,
  useUpdateFileIsFavourite,
} from "../hooks/useUpdateFileIsFavorite";
import { MoveFile, useMoveFile } from "../hooks/useMoveFile";
import { useBulkAddTagsMutation } from "./useBulkAddTags";
import { isFile, isFolder } from "../../../helpers/mediaHelper";
import { useBulkAddFilesToPlaylistsMutation } from "./useBulkAddFilesToPlaylist";
import { useGetAllMediaByFolderId } from "./useGetAllMediasByFolderId";
import { useSetFileAvailabilityMutation } from "./useSetFileAvialabilityMutation";
import { useSelectAll } from "../../../hooks/useSelectAll";
import { useCreatePlaylist } from "../../../hooks/useCreatePlaylist";
import { DEBOUNCE_TIMEOUT_MS, UUID } from "../../../constants/constants";
import { useScreenCastStop } from "../../../hooks/useStopCasts";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useAbleToShowAddToPlaylist } from "../../../hooks/useAbleToShowAddToPlaylist";
import { RenameFile, useRenameFile } from "../hooks/useRenameFile";
import {
  UseDeleteFile,
  useDeleteFile,
} from "src/hooks/entities/file/useDeleteFile";
import { useDeleteFiles } from "src/hooks/entities/file/useDeleteFiles";
import {
  useReplaceContentStatus,
  ReplacementJob,
} from "src/components/ReplaceContentStatus/hooks/useReplaceContentStatus";
import { useReplaceContent } from "src/hooks/useReplaceContent";
export interface MedialistApolloProps
  extends FolderByIdProps,
    RouteComponentProps<any>,
    UpdateFileIsFavourite,
    UpdateFolderIsFavourite,
    UseDeleteFile,
    DeleteFolder,
    RenameFile,
    RenameFolder,
    MoveFolder,
    MoveFile,
    UseMediaList {
  client: ApolloClient<any>;
  folderId: Scalars["UUID"];
  createPlaylist: (
    createPlaylistInput: Exact<{
      input: CreatePlaylistInput;
    }>,
    currentSpaceId: UUID
  ) => Promise<any>;
  updateFileById: UpdateFileByIdMutationFn;
  createFile: CreateFileMutationFn;
  createCastsByFileId: CreateCastsByFileIdMutationFn;
  updateUserSettings: UpdateUserSettingsMutationFn;
  screenCastStop: (
    stopCastsInput: Exact<{
      input: ScreenCastStopInput;
    }>,
    castItem?: Maybe<CastByCastIdFragment>
  ) => Promise<any>;
  setContentByFileId: SetScreenContentByFileIdMutationFn;
  searchTerms: string;
  clearSearchTerms: () => void;
  query: string;
  updateSearchTerms: (query: string) => void;
  updatePlaylist: MutationFunction<
    UpdatePlaylistMutation,
    UpdatePlaylistMutationVariables
  >;
  searchMediaQuery: DataValue<SearchMediaQuery>;
  mediaFiles: File[];
  mediaFolders: Folder[];
  isLoading: boolean;
  folderPathItems: FolderPathItem[];
  createNewFolder: (
    params: CreateNewFolderVariables
  ) => MutationResult<CreateFolderMutation>;
  isSelectAll: boolean;
  toggleSelectedAll: () => void;
  updateSelectedMediaItems: (mediaItems: (File | Folder)[]) => void;
  selectedMediaItems: (File | Folder)[];
  selectedItemsCount: number;
  isSelectAllIndeterminate: boolean;
  onBulkAddTags: (tags: string[]) => Promise<any>;
  addSelectedFilesToPlayLists: (playlists: Playlist[]) => Promise<any>;
  onBulkDeleteSelectedItems: () => Promise<any>;
  setSelectedFilesAvailability: ({
    availableTime,
    expireTime,
  }: {
    availableTime: string;
    expireTime: string;
  }) => Promise<any>;
  isHotKeyPress: boolean;
  clearSelectedItems: () => void;
  addFilesToPlaylists: ({
    files,
    playlists,
  }: {
    files: File[];
    playlists: Playlist[];
  }) => Promise<
    FetchResult<
      UpdatePlaylistMutation,
      Record<string, any>,
      Record<string, any>
    >[]
  >;
  showAddToPlaylist: boolean;
  replacementJobs: ReplacementJob[];
}

export const withFolderData = compose(
  withApollo,
  withRouter,
  (Component) => (props: RouteComponentProps<any>) => {
    const { history } = props;
    const queryString = "q";
    const query =
      new URLSearchParams(window.location.search).get(queryString) || "";
    const finalQuery = query.replace(/\s/g, " +");
    const [searchTerms, setSearchTerms] = useState(query);

    const updateQuery = useCallback(
      debounce((criteria: string) => {
        history.push({
          pathname: "/media/search",
          search: `?${queryString}=${encodeURIComponent(criteria)}`,
        });
      }, DEBOUNCE_TIMEOUT_MS),
      []
    );

    const updateSearchTerms = (criteria: string) => {
      if ((criteria ?? "") === "") {
        clearSearchTerms();
      } else {
        setSearchTerms(criteria);
        updateQuery(criteria);
      }
    };

    const clearSearchTerms = () => {
      updateQuery.cancel();
      history.replace({ pathname: "/media" });
      setSearchTerms("");
    };

    return (
      <Component
        {...props}
        updateSearchTerms={updateSearchTerms}
        clearSearchTerms={clearSearchTerms}
        searchTerms={searchTerms}
        query={finalQuery}
      />
    );
  },
  (Component) => (props: MedialistApolloProps & MediaListComponentProps) => {
    const {
      query,
      isInRootSharedFolderFromOtherSpaces,
      isSearchMode,
      isInRootFolder,
      folderId,
    } = props;
    return (
      <Component
        {...props}
        {...useMedialist({
          query,
          isInRootSharedFolderFromOtherSpaces,
          isSearchMode,
          isInRootFolder,
          folderId,
        })}
      />
    );
  },
  (Component) => (props: MedialistApolloProps & MediaListComponentProps) => {
    const {
      folderId,
      isSearchMode,
      isInRootFolder,
      isInRootSharedFolderFromOtherSpaces,
      isInSharedFolderFromOtherSpaces,
    } = props;
    const folderPathItems = useBreadCrumbFolderPathItems({
      folderId,
      isSearch: isSearchMode,
      isInRootFolder,
      isInRootSharedFolderFromOtherSpaces,
      isInSharedFolderFromOtherSpaces,
    });
    return <Component {...props} folderPathItems={folderPathItems} />;
  },
  (Component) => (props: any) => {
    const { createNewFolder } = useCreateNewFolderMutation();
    const { deleteFile } = useDeleteFile();
    const { deleteFolder } = useDeleteFolder();
    const { updateFileIsFavorite } = useUpdateFileIsFavourite();
    const { updateFolderIsFavourite } = useUpdateFolderIsFavourite();
    const { renameFolder } = useRenameFolder();
    const { moveFile } = useMoveFile();
    const { moveFolder } = useMoveFolder();
    const { renameFile } = useRenameFile();
    const { createPlaylist } = useCreatePlaylist();
    const { screenCastStop } = useScreenCastStop();
    const [updateFileById] = useUpdateFileByIdMutation();
    const [createCastsByFileId] = useCreateCastsByFileIdMutation();
    const [updatePlaylist] = useUpdatePlaylistMutation();
    const [updateUserSettings] = useUpdateUserSettingsMutation();
    const [setContentByFileId] = useSetScreenContentByFileIdMutation();
    return (
      <Component
        {...props}
        createNewFolder={createNewFolder}
        updateFileIsFavorite={updateFileIsFavorite}
        updateFolderIsFavourite={updateFolderIsFavourite}
        deleteFile={deleteFile}
        deleteFolder={deleteFolder}
        renameFile={renameFile}
        renameFolder={renameFolder}
        moveFolder={moveFolder}
        moveFile={moveFile}
        createPlaylist={createPlaylist}
        updateFileById={updateFileById}
        createCastsByFileId={createCastsByFileId}
        updatePlaylist={updatePlaylist}
        screenCastStop={screenCastStop}
        updateUserSettings={updateUserSettings}
        setContentByFileId={setContentByFileId}
      />
    );
  },
  /**
   *
   * @param Component Bulk Update Inline Component
   */
  (Component) => (props: MedialistApolloProps & MediaListComponentProps) => {
    const { deleteFiles } = useDeleteFiles();
    const { deleteFolders } = useDeleteFolders();
    const {
      folderId,
      allItemTotalCount,
      mediaFiles,
      mediaFolders,
      isSearchMode,
    } = props;
    const [selectedMediaItems, setSelectedMediaItems] = useState(
      [] as (File | Folder)[]
    );
    const {
      addTagsToAllFilesByFolderId,
      addTagsToFiles,
    } = useBulkAddTagsMutation();
    const {
      addAllFilesByFolderIdToPlaylists,
      addFilesToPlaylists,
    } = useBulkAddFilesToPlaylistsMutation();
    const { getAllFilesAndFolders, getAllFiles } = useGetAllMediaByFolderId();
    const { setFileAvailability } = useSetFileAvailabilityMutation();
    const {
      clearSelectedItems,
      updateSelectedItems,
      isSelectAll,
      selectAll,
      isSelectAllIndeterminate,
      toggleSelectedAll,
    } = useSelectAll<File | Folder>({
      allItems: [...mediaFolders, ...mediaFiles],
      allItemTotalCount,
      setSelectedItems: setSelectedMediaItems,
    });

    const unselectRemovedItems = () => {
      const allMediaItems = [...mediaFolders, ...mediaFiles];
      const ids = allMediaItems.map((item) => item.id) as string[];
      const updatedItems = selectedMediaItems.filter((item) =>
        ids.includes(item.id)
      );
      updateSelectedItems(updatedItems);
    };

    /** When we change folder we must clear all selection */
    useEffect(() => {
      clearSelectedItems();
    }, [folderId]);

    /**
     * When load all items, should marks new loaded item as selected
     */
    useEffect(() => {
      if (isSelectAll) {
        selectAll();
      } else {
        unselectRemovedItems();
      }
    }, [mediaFolders, mediaFiles]);

    const selectedItemsCount = isSelectAll
      ? allItemTotalCount
      : selectedMediaItems.length;

    const onBulkAddTags = (tags: string[]) => {
      const shouldAddTagsToAllFilesInFolder = isSelectAll && !isSearchMode;
      if (shouldAddTagsToAllFilesInFolder) {
        addTagsToAllFilesByFolderId({ folderId, tags });
      } else {
        addTagsToFiles({
          files: selectedMediaItems.filter(isFile) as File[],
          tags,
        });
      }
    };

    const onBulkDeleteSelectedItems = async () => {
      const shouldDeleteAllFilesInFolders = isSelectAll && !isSearchMode;
      if (shouldDeleteAllFilesInFolders) {
        const { allFiles, allFolders } = await getAllFilesAndFolders(folderId);
        await Promise.all([
          deleteFolders(allFolders as Folder[]),
          deleteFiles(allFiles as File[]),
        ]);
      } else {
        const selectedFolders = selectedMediaItems.filter(isFolder) as Folder[];
        const selectedFiles = selectedMediaItems.filter(isFile) as File[];
        await Promise.all([
          deleteFolders(selectedFolders),
          deleteFiles(selectedFiles),
        ]);
      }
      clearSelectedItems();
    };

    const addSelectedFilesToPlayLists = (playlists: Playlist[]) => {
      const shouldAddAllFilesInFolderToPlaylist = isSelectAll && !isSearchMode;
      if (shouldAddAllFilesInFolderToPlaylist) {
        addAllFilesByFolderIdToPlaylists({ folderId, playlists });
      } else {
        addFilesToPlaylists({
          files: selectedMediaItems.filter(isFile) as File[],
          playlists,
        });
      }
    };

    const setSelectedFilesAvailability = async ({
      availableTime,
      expireTime,
    }: {
      availableTime: string;
      expireTime: string;
    }) => {
      const shouldApplyToAllFilesInFolder = isSelectAll && !isSearchMode;
      if (shouldApplyToAllFilesInFolder) {
        const allFiles = await getAllFiles(folderId);
        return Promise.all(
          allFiles.map((file) =>
            setFileAvailability({ file, availableTime, expireTime })
          )
        );
      } else {
        const files = selectedMediaItems.filter(isFile) as File[];
        return Promise.all(
          files.map((file) =>
            setFileAvailability({ file, availableTime, expireTime })
          )
        );
      }
    };

    const isMetaKeyPress = useKeyPress("Meta");
    const isCtrlKeyPress = useKeyPress("Control");

    return (
      <Component
        {...props}
        isSelectAll={isSelectAll}
        isSelectAllIndeterminate={isSelectAllIndeterminate}
        updateSelectedMediaItems={updateSelectedItems}
        selectedMediaItems={selectedMediaItems}
        toggleSelectedAll={toggleSelectedAll}
        selectedItemsCount={selectedItemsCount}
        onBulkAddTags={onBulkAddTags}
        onBulkDeleteSelectedItems={onBulkDeleteSelectedItems}
        addSelectedFilesToPlayLists={addSelectedFilesToPlayLists}
        addFilesToPlaylists={addFilesToPlaylists}
        setSelectedFilesAvailability={setSelectedFilesAvailability}
        isHotKeyPress={isMetaKeyPress || isCtrlKeyPress}
        clearSelectedItems={clearSelectedItems}
      />
    );
  },
  (Component) => (props: MedialistApolloProps & MediaListComponentProps) => {
    const showAddToPlaylist = useAbleToShowAddToPlaylist();
    return <Component {...props} showAddToPlaylist={showAddToPlaylist} />;
  },
  (Component) => (props: MedialistApolloProps & MediaListComponentProps) => {
    const { jobs } = useReplaceContentStatus();
    return <Component {...props} replacementJobs={jobs} />;
  }
);

export const withOpenReplaceModal = compose((Component) => (props) => {
  const { openReplaceContentModal } = useReplaceContent();
  return (
    <Component {...props} openReplaceContentModal={openReplaceContentModal} />
  );
});
