import { useState } from "react";
import { Loader } from "@screencloud/screencloud-ui-components";

import { Styled } from "src/components/Loading/style";
import scLogo from "src/images/sc-hd-logo.png";

interface LoadingProps {
  withLogo?: boolean;
}

const Loading = (props: LoadingProps) => {
  const [isShowLogo, setIsShowLogo] = useState(false);

  if (props.withLogo) {
    setTimeout(() => setIsShowLogo(true), 1000);
  }
  return (
    <Styled noBg={!props.withLogo}>
      {isShowLogo ? (
        <div className="logo">
          <img width={"40%"} src={scLogo} />
        </div>
      ) : (
        <Loader active />
      )}
    </Styled>
  );
};

export default Loading;
