import { UUID } from "@screencloud/uuid";
import {
  getMediaPicker,
  getMediaPickerSkipProps,
} from "src/domains/mediaPicker/mediaPicker";
import { useAvailableAppInstalls } from "src/hooks/useAvailableAppInstalls";
import { AppInstall } from "src/types.g";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { useAllAppInstallsInCurrentOrg } from "./useAllAppInstallsInCurrentOrg";

export interface UseAppInstallPicker {
  appInstalls: AppInstall[];
  loading: boolean;
}
// TODO vite migration - remove any
export function useAppInstallsPicker(spaceId?: UUID): UseAppInstallPicker {
  const isOrgWide = isOrgWidePath();
  const { skipOrgWideQuery, skipSpaceWideQuery } = getMediaPickerSkipProps({
    isOrgWide,
  });
  return getMediaPicker({
    isOrgWide,
    orgWideMediaPicker: useAllAppInstallsInCurrentOrg({
      skip: skipOrgWideQuery,
    }) as any,
    spaceWideMediaPicker: useAvailableAppInstalls({
      spaceId: spaceId ?? undefined,
      skip: skipSpaceWideQuery,
    }) as any,
  }) as any;
}
