import { MediaOrderBy } from "../../../constants/constants";
import { useState, useEffect } from "react";
import { UUID } from "@screencloud/uuid";
import {
  Exact,
  FilesOrderBy,
  FolderByIdExtendedAssociationsQuery,
  FoldersOrderBy,
  Maybe,
  useFolderByIdExtendedAssociationsQuery,
} from "../../../types.g";
import {
  renderFetchMoreButton,
  renderFetchMorePickerButton,
} from "src/helpers/generalHelper";
export interface UseFolderById {
  data: FolderByIdExtendedAssociationsQuery | undefined;
  hasNextPage: boolean;
  loading: boolean;
  isFetching: boolean;
  refetch: (
    variables?: Partial<
      Exact<{
        folderId: any;
        endCursor: any;
        fileOrderBy: Maybe<FilesOrderBy[]>;
        folderOrderBy: Maybe<FoldersOrderBy[]>;
        allowMimeType: Maybe<string[]>;
      }>
    >
  ) => void;
  loadMore: () => void;
  allItemsTotalCount: number;
  renderFetchMoreButton: React.ReactNode;
  renderFetchMorePickerButton: React.ReactNode;
  spaceId?: UUID;
}
interface FolderByIdProps {
  folderId: string;
  skip: boolean;
  filesOrderBy?: FilesOrderBy[];
  foldersOrderBy?: FoldersOrderBy[];
  spaceId: UUID;
}

export function useFolderById(props: FolderByIdProps): UseFolderById {
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const { folderId, skip, filesOrderBy, foldersOrderBy, spaceId } = props;

  const queryVar = {
    fileOrderBy: filesOrderBy ?? [MediaOrderBy.FILE_CREATED_AT_DESC],
    folderId,
    spaceId,
    folderOrderBy: foldersOrderBy ?? [MediaOrderBy.FOLDER_CREATED_AT_DESC],
    endCursor: null,
  };

  const {
    data,
    fetchMore,
    refetch,
    loading,
  } = useFolderByIdExtendedAssociationsQuery({
    variables: queryVar,
    fetchPolicy: "cache-first",
    skip,
  });

  const loadMore = () => {
    if (!isFetching) {
      setIsFetching(true);
      fetchMore({
        variables: {
          endCursor: data?.folderById?.filesByFolderId?.pageInfo?.endCursor,
        },
      }).then(() => setIsFetching(false));
    }
  };

  useEffect(() => {
    setHasNextPage(
      Boolean(data?.folderById?.filesByFolderId.pageInfo.hasNextPage) &&
        data?.folderById?.filesByFolderId.nodes.length !==
          data?.folderById?.filesByFolderId.totalCount
    );
  }, [
    data?.folderById?.filesByFolderId.pageInfo.hasNextPage,
    data?.folderById?.filesByFolderId.totalCount,
  ]);

  const filesTotalCount = data?.folderById?.filesByFolderId?.totalCount ?? 0;
  const foldersTotalCount =
    data?.folderById?.foldersByParentId?.totalCount ?? 0;
  const allItemsTotalCount = filesTotalCount + foldersTotalCount;

  const currentItemsCount = data?.folderById?.filesByFolderId.nodes.length ?? 0;
  const totalCount = data?.folderById?.filesByFolderId.totalCount ?? 0;

  return {
    data,
    hasNextPage,
    loadMore,
    refetch,
    loading,
    isFetching,
    allItemsTotalCount,
    renderFetchMoreButton: renderFetchMoreButton(
      currentItemsCount,
      totalCount,
      isFetching,
      hasNextPage,
      loadMore
    ),
    renderFetchMorePickerButton: renderFetchMorePickerButton(
      currentItemsCount,
      totalCount,
      isFetching,
      hasNextPage,
      loadMore
    ),
  };
}
