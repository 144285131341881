import styled from "styled-components";

export const Styled = styled.div`
  display: inline-block;
  .labelled {
    align-items: center;
    display: inline-flex;
    .ui.label.circular {
      margin-left: 6px;
      text-transform: uppercase;
    }
  }
`;
