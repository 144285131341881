import { AxiosError } from "axios";

/**
 * Parse an Apollo error into a simple string.
 *
 * @param error Error payload from Apollo.
 */
export function parseApollo(error: any): string {
  if (typeof error === "string") {
    return error;
  }
  if (
    error?.networkError?.result &&
    Array.isArray(error.networkError.result.errors) &&
    error.networkError.result.errors.length &&
    error.networkError.result.errors[0].message
  ) {
    return error.networkError.result.errors[0].message as string;
  }
  if (error.message) {
    return error.message as string;
  }
  return "Oops! Something went wrong. Please try again later.";
}

/**
 * Parse an Axios error into a simple string.
 *
 * @param error Error payload from Axios.
 * @param defaultMessage DEfault message to return if the error from Axios is empty.
 */
export function parseAxios(error: AxiosError<{ message: string }> | Error, defaultMessage = ""): string {
  if (error instanceof AxiosError) {
    return error.response?.data.message || defaultMessage;
  } else if (error instanceof Error) {
    return error.message || defaultMessage;
  } else {
    return defaultMessage;
  }
}
