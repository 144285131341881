import * as React from "react";
import { RefType } from "../../constants/constants";
import { File } from "../../types.g";

export interface ModalContextState {
  selectedMediaFiles: File[];
  updateSelectedMediaFiles: React.Dispatch<React.SetStateAction<File[]>>;
  selectedSection: RefType;
  updateSelectedSection: React.Dispatch<React.SetStateAction<RefType>>;
}

const ModalContext = React.createContext({} as ModalContextState);

export function ModalContextProvider(props: { children?: any }) {
  const [selectedMediaFiles, updateSelectedMediaFiles] = React.useState(
    [] as File[]
  );
  const [selectedSection, updateSelectedSection] = React.useState(
    RefType.PLAYLIST
  );
  return (
    <ModalContext.Provider
      value={{
        selectedMediaFiles,
        updateSelectedMediaFiles,
        selectedSection,
        updateSelectedSection,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}

export function useModalContext() {
  return React.useContext(ModalContext) as ModalContextState;
}
