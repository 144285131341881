import apiKeyIcon from "./images/identity-providers/api_key.svg";
import basicAuthIcon from "./images/identity-providers/basic_auth.svg";
import oauth2Icon from "./images/identity-providers/oauth2.svg";

export const API_KEY_PROVIDER_URN = "urn:scrn:auth:api-key";
export const BASIC_AUTH_PROVIDER_URN = "urn:scrn:auth:basic";
export const OAUTH2_PROVIDER_URN = "urn:scrn:auth:oauth2";

export const API_KEY_ICON = apiKeyIcon;
export const BASIC_AUTH_ICON = basicAuthIcon;
export const OAUTH2_ICON = oauth2Icon;
