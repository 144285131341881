import { ApolloQueryResult } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  FIRST_FETCH_ITEMS,
  MAXIMUM_FETCH_MORE_ITEMS,
  MINIMUM_FETCH_MORE_ITEMS,
} from "../constants/constants";
import { renderFetchMoreButton } from "../helpers/generalHelper";
import {
  Maybe,
  Exact,
  AvailableAppInstanceFragment,
  useSearchAppInstanceQuery,
  SearchAppInstanceQueryVariables,
  SearchAppInstanceQuery,
  AppInstanceFilter,
} from "../types.g";
import { useAppContext } from "./useAppContext";

export interface UseSearchAppInstances {
  appInstances: AvailableAppInstanceFragment[];
  hasNextPage: boolean;
  loadMore: () => void;
  loading: boolean;
  isLoading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            query: Maybe<string>;
            first: Maybe<number>;
            endCursor: any;
            spaceId: any;
            appId: any;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<SearchAppInstanceQuery>>;
  isFirstTimeAlreadyLoaded: boolean;
  totalCount: number;
  renderFetchMoreButton: React.ReactNode;
}

export interface SearchAppInstancesProps {
  searchTerms: string;
  filter: AppInstanceFilter;
  appId?: string;
}

function useSearchAppInstancesSection(props: SearchAppInstancesProps) {
  const context = useAppContext();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const spaceId = context?.currentSpace?.id;

  const variables: SearchAppInstanceQueryVariables = {
    query: props.searchTerms,
    first: FIRST_FETCH_ITEMS,
    spaceId,
    endCursor: null,
    filter: props.filter,
  };

  const {
    data: searchAppInstances,
    fetchMore,
    refetch,
    loading,
  } = useSearchAppInstanceQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !props.searchTerms,
  });

  const loadMore = () => {
    if (!isLoading) {
      setIsLoading(true);
      const endCursor =
        searchAppInstances?.searchAppInstance?.pageInfo?.endCursor;
      fetchMore({
        variables: {
          first:
            fetchCount > 1
              ? MAXIMUM_FETCH_MORE_ITEMS
              : MINIMUM_FETCH_MORE_ITEMS,
          endCursor,
        },
      }).then(() => {
        setIsLoading(false);
        setFetchCount(fetchCount + 1);
      });
    }
  };

  useEffect(() => {
    setTotalCount(searchAppInstances?.searchAppInstance?.totalCount ?? 0);
  }, [searchAppInstances?.searchAppInstance?.totalCount]);

  useEffect(() => {
    setHasNextPage(
      Boolean(searchAppInstances?.searchAppInstance?.pageInfo.hasNextPage) &&
        searchAppInstances?.searchAppInstance?.nodes.length! <
          searchAppInstances?.searchAppInstance?.totalCount!
    );
  }, [
    searchAppInstances?.searchAppInstance?.pageInfo!.hasNextPage,
    searchAppInstances?.searchAppInstance?.totalCount,
  ]);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(Boolean(searchAppInstances?.searchAppInstance));
  }, [searchAppInstances?.searchAppInstance]);

  const fechMoreButton = renderFetchMoreButton(
    Number(searchAppInstances?.searchAppInstance?.nodes.length),
    Number(searchAppInstances?.searchAppInstance?.totalCount),
    isLoading,
    hasNextPage,
    loadMore
  );

  return {
    appInstances: searchAppInstances?.searchAppInstance?.nodes ?? [],
    hasNextPage,
    loadMore,
    refetch,
    loading,
    isLoading,
    isFirstTimeAlreadyLoaded,
    totalCount,
    renderFetchMoreButton: fechMoreButton,
  };
}

export function useSearchAppInstances(query: string) {
  const context = useAppContext();
  const spaceId = context?.currentSpace?.id;
  const variables: SearchAppInstancesProps = {
    searchTerms: query,
    filter: {
      appId: { notEqualTo: context.canvasAppId },
      spaceId: { equalTo: spaceId },
    },
  };
  return useSearchAppInstancesSection(variables);
}

export function useSharedSearchAppInstances(query: string) {
  const context = useAppContext();
  const spaceId = context?.currentSpace?.id;
  const variables: SearchAppInstancesProps = {
    searchTerms: query,
    filter: {
      appId: { notEqualTo: context.canvasAppId },
      spaceId: { notEqualTo: spaceId },
    },
  };
  return useSearchAppInstancesSection(variables);
}

export function useSearchAppInstancesPicker(query: string) {
  const context = useAppContext();
  const variables: SearchAppInstancesProps = {
    searchTerms: query,
    filter: { appId: { notEqualTo: context.canvasAppId } },
  };
  return useSearchAppInstancesSection(variables);
}
