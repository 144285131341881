import { useAppContext } from "../../../hooks/useAppContext";
import { ReactElement, useCallback, useEffect, useState } from "react";
import {
  hasIncognitoModeAccess,
  isChromeBrowser,
  isChromeExtensionInstalled,
} from "./chromeExtensionUtils";
import IncognitoModeExplainer from "./IncognitoModeExplainer";
import Init from "./Init";
import InstallExtension from "./InstallExtension";
import UnsupportedBrowser from "./UnsupportedBrowser";

const SecureSiteChecks = (): ReactElement<{}> => {
  const appContext = useAppContext();
  const [isInitialising, setIsInitialising] = useState(true);
  const [isUsingChrome, setIsUsingChrome] = useState(false);
  const [isIncognitoEnabled, setIsIncognitoEnabled] = useState(false);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  const openSecureSiteEditor = useCallback(() => {
    appContext.modal.closeModals();
    appContext.modal.openSecureSiteConfigure("APP NAME");
  }, [appContext]);

  useEffect(() => {
    const isUsingChrome = isChromeBrowser();
    setIsUsingChrome(isUsingChrome);

    if (!isUsingChrome) {
      setIsInitialising(false);
      return;
    }

    isChromeExtensionInstalled().then((isInstalled) => {
      setIsExtensionInstalled(isInstalled);
      if (!isInstalled) {
        setIsInitialising(false);
        return;
      }

      hasIncognitoModeAccess().then((hasIncognitoAccess) => {
        setIsIncognitoEnabled(hasIncognitoAccess);
        setIsInitialising(false);
        if (hasIncognitoAccess) {
          openSecureSiteEditor();
        }
      });
    });
  }, []);

  if (isInitialising) {
    return <Init />;
  }

  if (!isUsingChrome) {
    return <UnsupportedBrowser />;
  }

  if (!isExtensionInstalled) {
    return (
      <InstallExtension
        onExtensionInstalled={() => {
          setIsExtensionInstalled(true);
          hasIncognitoModeAccess().then((hasIncognitoAccess) => {
            setIsIncognitoEnabled(hasIncognitoAccess);
            if (hasIncognitoAccess) {
              openSecureSiteEditor();
            }
          });
        }}
      />
    );
  }

  if (!isIncognitoEnabled) {
    return <IncognitoModeExplainer onContinue={openSecureSiteEditor} />;
  }

  return <></>;
};

export default SecureSiteChecks;
