import { Icon } from "@screencloud/screencloud-ui-components";
import { Link } from "react-router-dom";
import { AppContextState } from "src/AppContextProvider";
import { isHome } from "src/helpers/channelHelper";
import { Shareable, canBeShared } from "src/helpers/shareableHelper";
import { SharedSpaceWithSharingMode, Space } from "src/types.g";
import { EmbedShareOptions } from ".";

export enum SharingMode {
  Disabled = "disabled",
  ReadOnly = "read_only",
  Editable = "editable",
}

export const sharingModeText = {
  [SharingMode.Disabled]: "Disabled",
  [SharingMode.ReadOnly]: "Can Play Content Only",
  [SharingMode.Editable]: "Can Play and Add Local Content",
};

export const getAllSpaces = (context: AppContextState) => {
  return (context?.allSpaces ?? [])
    .map((space) => {
      if (space!.name === "_default_space") {
        space!.name = context.intl.formatMessage({
          id: "defaults._default_space",
        });
      }
      return space;
    })
    .sort((space, anotherSpace) =>
      (space?.name ?? "").localeCompare(anotherSpace?.name ?? "", undefined, {
        sensitivity: "base",
      })
    );
};

export const getEmbedStatus = (embedSharedOption: EmbedShareOptions) => {
  return {
    isEmbedEnabled: embedSharedOption !== EmbedShareOptions.Disabled,
    isEmbedPublic: embedSharedOption === EmbedShareOptions.Public,
  };
};

export const getOwnerSpace = (
  context: AppContextState,
  shareable: Shareable,
  searchQuery?: string
) => {
  return getAllSpaces(context)
    .filter((space) =>
      space.name.toLowerCase().includes(searchQuery?.toLowerCase() || "")
    )
    .find((space) => space.id === shareable.spaceId) as Pick<
    Space,
    "id" | "name"
  >;
};

export const getNonOwnerSpaces = (
  context: AppContextState,
  shareable: Shareable,
  searchQuery?: string,
  sharedSpaces?: SharedSpaceWithSharingMode[]
) => {
  return getAllSpaces(context)
    .filter((space) => space.id !== shareable.spaceId)
    .filter((space) =>
      space.name.toLowerCase().includes(searchQuery?.toLowerCase() || "")
    )
    .map((space) => {
      return {
        ...space,
        sharingMode: sharedSpaces
          ? getDropdownValueForSpaceItem(sharedSpaces, space.id)
          : null,
      };
    });
};

export const shouldShowDefaultChannelMessage = (
  shareable: Shareable,
  context: AppContextState
) => {
  return (
    isChannel(shareable) &&
    isHome({ targetId: shareable.id, context }) &&
    canBeShared({ context, shareable })
  );
};

export const renderDefaultChannelMessage = (context: AppContextState) => {
  return (
    <>
      Company TV is the default channel for all your screens. To unshare
      channel, you have to first change the default channel in{" "}
      <span className="highlight">
        <Link to={`/organization`} onClick={() => context.modal.closeModals()}>
          Organization Settings
        </Link>
      </span>{" "}
      <Icon name="external" />
    </>
  );
};

export const handleEmbedShareDropdownChange = (
  embedSharedOption: EmbedShareOptions,
  onEmbedChange?: (embedStatus: {
    isEmbedEnabled: boolean;
    isEmbedPublic: boolean;
  }) => void
) => {
  const embedStatus = getEmbedStatus(embedSharedOption);
  onEmbedChange && onEmbedChange(embedStatus);
};

export const getChannelShareOptions = () => [
  {
    key: SharingMode.Disabled,
    text: sharingModeText[SharingMode.Disabled],
    value: SharingMode.Disabled,
  },
  {
    key: SharingMode.ReadOnly,
    text: sharingModeText[SharingMode.ReadOnly],
    value: SharingMode.ReadOnly,
  },
  {
    key: SharingMode.Editable,
    text: sharingModeText[SharingMode.Editable],
    value: SharingMode.Editable,
  },
];

export const canResetPermissions = (
  sharingModes: SharingMode[],
  shareAllSharingMode: SharingMode
): boolean => {
  if (sharingModes.length === 0) {
    return false;
  }
  return !sharingModes.every((mode) => mode === shareAllSharingMode);
};

export const getShareAllValue = (shareable: Shareable) => {
  if (isChannel(shareable)) {
    return shareable.preferences?.default_sharing_mode ?? SharingMode.Disabled;
  } else {
    return SharingMode.Disabled;
  }
};

export const getShareAllText = (shareable, shareAllValue) => {
  if (isChannel(shareable)) {
    // convert value to text
    const shareAllText = getChannelShareOptions().find(
      (item) => item.value === shareAllValue
    )?.text;
    return shareAllText ?? sharingModeText[SharingMode.Disabled];
  } else {
    return sharingModeText[SharingMode.Disabled];
  }
};

// get default value for each space id in the list
export const getDropdownValueForSpaceItem = (
  sharedSpaces: SharedSpaceWithSharingMode[],
  spaceId: string
) => {
  const sharedSpace = sharedSpaces.find((item) => item.space?.id === spaceId);

  // if shared spaces is empty then return disabled
  if (!sharedSpace) {
    return SharingMode.Disabled;
  } else {
    // if space is readonly then return readonly
    if (sharedSpace?.readOnly === true) {
      return SharingMode.ReadOnly;
    } else {
      return SharingMode.Editable;
    }
  }
};

export function isChannel(shareable: { __typename?: string }): boolean {
  return shareable.__typename === "Channel";
}

export function isAppInstance(shareable: { __typename?: string }): boolean {
  return shareable.__typename === "AppInstance";
}

export function isCanvas(shareable: {
  __typename?: string;
  appByAppId?: { name?: string };
}): boolean {
  return isAppInstance(shareable) && shareable.appByAppId?.name === "Canvas";
}

export function isCanvasTemplate(shareable: {
  __typename?: string;
  appByAppId?: { name?: string };
  isTemplate?: boolean;
  appVersionByAppInstanceId?: { appByAppId?: { name?: string } };
}): boolean {
  const appName =
    shareable.appByAppId?.name ??
    shareable.appVersionByAppInstanceId?.appByAppId?.name;
  return Boolean(
    isCanvas({ ...shareable, appByAppId: { name: appName } }) &&
      shareable.isTemplate
  );
}

export function isAllowShareAll(
  context: AppContextState,
  shareable: { __typename?: string }
): boolean {
  switch (shareable.__typename) {
    case "Channel":
      return context.currentPermissions.validateOrg("channel", "share");
    case "Playlist":
      return context.currentPermissions.validateOrg("playlist", "share");
    case "Folder":
      return context.currentPermissions.validateOrg("media", "share");
    case "AppInstance":
      return context.currentPermissions.validateOrg("app_instance", "share");
    case "Site":
      return context.currentPermissions.validateOrg("site", "share");
    default:
      return false;
  }
}

export function getEntityName(shareable: Shareable): string {
  switch (shareable.__typename) {
    case "AppInstance":
      return "app instance";
    case "Playlist":
      return "playlist";
    case "Site":
      return "dashboard";
    case "Folder":
      return "folder";
    default:
      return "";
  }
}
