import React from "react";
import { Icon } from "@screencloud/screencloud-ui-components";
import { Styled } from "./style";

interface StatusItemProps {
  title: string;
}

const StatusItem: React.FunctionComponent<StatusItemProps> = ({ title }) => (
  <Styled>
    <Icon name="image" />
    <span className="job-title">{title}</span>
  </Styled>
);

export default StatusItem;
