import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "../../utils/compose";
import { ssm } from "../../state/session/ssm";
import {
  AvailableAppInstallsProps,
  AvailableAppInstallsQueryVariables,
  withAvailableAppInstalls,
} from "../../types.g";

import {
  UseAllAppInstances,
  useAllAppInstances,
} from "../../hooks/useAllAppinstances";
import {
  useSearchAppInstancesPicker,
  UseSearchAppInstances,
} from "../../hooks/useSearchAppinstances";
export interface AppQueryInputProps {
  searchTerms: string;
}

export interface AppInstanceQueryInputProps {
  searchTerms: string;
  appId: string;
}

export interface AppInstallProps
  extends AvailableAppInstallsProps,
    RouteComponentProps<any>,
    WithApolloClient<{}> {}

export const withAppInstallData = compose(
  withRouter,
  withApollo,
  withAvailableAppInstalls({
    options: () => {
      const queryVar: AvailableAppInstallsQueryVariables = {
        spaceId: ssm.current.settings.spaceId,
      };
      return {
        fetchPolicy: "cache-and-network",
        variables: queryVar,
      };
    },
  })
);

export interface ApolloProps
  extends RouteComponentProps<any>,
    WithApolloClient<{}>,
    UseAllAppInstances {
  searchAppInstance: UseSearchAppInstances;
}

export const withData = compose(
  withRouter,
  withApollo,
  (Component) => (props: AppInstanceQueryInputProps) => {
    const { appId } = props;
    const allAppinstances = useAllAppInstances({
      appId,
    });
    return <Component {...props} {...allAppinstances} />;
  },
  (Component) => (props: AppInstanceQueryInputProps) => {
    const searchAppInstances = useSearchAppInstancesPicker(props.searchTerms);
    return <Component {...props} searchAppInstance={searchAppInstances} />;
  }
);
