import coreIcon from "./imgs/core.svg";
import proIcon from "./imgs/pro.svg";

type Plan = {
  name: {
    id: string;
    defaultMessage: string;
  };
  icon: string;
  description: {
    id: string;
    defaultMessage: string;
  };
  price: {
    monthly: {
      gbp: { cost: number; unit: string };
      usd: { cost: number; unit: string };
      eur: { cost: number; unit: string };
    };
    yearly: {
      gbp: { cost: number; unit: string };
      usd: { cost: number; unit: string };
      eur: { cost: number; unit: string };
    };
  };
  featureTitle: {
    id: string;
    defaultMessage: string;
  };
  features: {
    id: string;
    defaultMessage: string;
  }[];
};

type Plans = {
  core: Plan;
  pro: Plan;
};

export const plans: Plans = {
  core: {
    name: {
      id: "trial_expire_modal.planName.core",
      defaultMessage: "Core",
    },
    icon: coreIcon,
    description: {
      id: "trial_expire_modal.planDescription.core",
      defaultMessage:
        "Showcase your brand, tell stories, and engage customers and employees",
    },
    price: {
      monthly: {
        gbp: { cost: 18, unit: "£" },
        usd: { cost: 24, unit: "$" },
        eur: { cost: 21, unit: "€" },
      },
      yearly: {
        gbp: { cost: 16, unit: "£" },
        usd: { cost: 20, unit: "$" },
        eur: { cost: 18, unit: "€" },
      },
    },
    featureTitle: {
      id: "trial_expire_modal.featureTitle.core",
      defaultMessage: "Featuring",
    },
    features: [
      {
        id: "trial_expire_modal.features.core.feature1",
        defaultMessage: "70+ apps & integrations",
      },
      {
        id: "trial_expire_modal.features.core.feature2",
        defaultMessage: "Unlimited users",
      },
      {
        id: "trial_expire_modal.features.core.feature3",
        defaultMessage: "24/5 professional support",
      },
    ],
  },
  pro: {
    name: {
      id: "trial_expire_modal.planName.pro",
      defaultMessage: "Pro",
    },
    icon: proIcon,
    description: {
      id: "trial_expire_modal.planDescription.pro",
      defaultMessage:
        "Expand your screen network and securely amplify messages to your audience",
    },
    price: {
      monthly: {
        gbp: { cost: 24, unit: "£" },
        usd: { cost: 30, unit: "$" },
        eur: { cost: 27, unit: "€" },
      },
      yearly: {
        gbp: { cost: 24, unit: "£" },
        usd: { cost: 30, unit: "$" },
        eur: { cost: 27, unit: "€" },
      },
    },
    featureTitle: {
      id: "trial_expire_modal.featureTitle.pro",
      defaultMessage: "Core plan features plus:",
    },
    features: [
      {
        id: "trial_expire_modal.features.pro.feature1",
        defaultMessage: "Microsoft integrations",
      },
      {
        id: "trial_expire_modal.features.pro.feature2",
        defaultMessage: "Secure dashboards",
      },
      {
        id: "trial_expire_modal.features.pro.feature3",
        defaultMessage: "Remote device management",
      },
    ],
  },
};
