import { OnBoardingRefType, View } from "../../constants/constants";
import queryHelper from "../../state/helper/query";

import { UpdateUserSettingsMutationVariables, User } from "../../types.g";
import { CurrentFeatureFlag } from "src/constants/featureFlag";

export interface UserPreferencesSettings {
  lang?: string;
  defaultSpaceId?: string;
  lastAccessedSpaceId?: string;
  defaultSpaceName?: string;
  canvas_view?: string;
  template_view?: string;
  appinstance_view?: string;
  media_view?: string;
  confirm_before_publish?: boolean;
  [OnBoardingRefType.ORG_INDUSTRY_KEY]?: string;
  firsttime_login_utc_timestamp?: number;
}

export interface UserJobInfo {
  title?: string;
  jobFunction?: string;
  otherFunction?: string;
}

export interface UserInfo {
  job: UserJobInfo;
}

export const defaultUserPreferencesSettings: Pick<
  UserPreferencesSettings,
  "lang" | "canvas_view" | "template_view" | "appinstance_view" | "media_view"
> = {
  lang: "en",
  canvas_view: View.Grid,
  template_view: View.Grid,
  appinstance_view: "list",
  media_view: "list",
};

/**
 * TODO: use this in code later when have time to test things properly
 * This is a valid business logic, settings is any even in CurrentUser typing
 * so this encapsulate the missing knowledge from the missing type
 *
 * @returns
 */
export function getSettings(
  user: Pick<User, "preferences">
): UserPreferencesSettings {
  return user?.preferences?.settings || {};
}

/**
 * TODO: use this in code later when have time to test things properly
 *
 * @returns
 */
export function getLanguage(user: Pick<User, "preferences">) {
  return getSettings(user)?.lang || defaultUserPreferencesSettings.lang;
}

/**
 * get first time login timestamp
 *
 * @returns a timestamp
 */
export function getFirstTimeLogInTimestamp(
  user: Pick<User, "preferences">
): number | undefined {
  return getSettings(user)?.firsttime_login_utc_timestamp;
}

export function getUserEmail(user: Pick<User, "email">): string | null {
  return user?.email;
}

/**
 * checking in user settings if there's a firsttime login timestamp
 *
 * @returns boolean
 */
export function isFirstTimeLoggedIn(user: Pick<User, "preferences">): boolean {
  return !getFirstTimeLogInTimestamp(user);
}

export function updateFirstTimeLoginTimeStamp(user: Pick<User, "preferences">) {
  const userSettings = getSettings(user);
  const variables: UpdateUserSettingsMutationVariables = {
    input: {
      settingsData: {
        ...userSettings,
        firsttime_login_utc_timestamp: new Date().getTime(),
      },
    },
  };
  return queryHelper.updateUserSettings(variables);
}

/**
 * get SignUp date for gainsight if it's not there in user settings
 * should call update user settings first
 *
 * @returns a timestamp
 */
export async function getSignUpDate(
  currentUser: Pick<User, "preferences"> | null
): Promise<number | undefined> {
  let signUpDate: number | undefined;

  if (currentUser) {
    if (isFirstTimeLoggedIn(currentUser)) {
      const updatedUser = await updateFirstTimeLoginTimeStamp(currentUser);
      signUpDate = getFirstTimeLogInTimestamp(updatedUser);
    } else {
      signUpDate = getFirstTimeLogInTimestamp(currentUser);
    }
  }
  return signUpDate;
}

export function isScreenCloudUser(
  currentUser: Pick<User, "email"> | null
): boolean {
  if (!currentUser) return false;
  return !!(
    getUserEmail(currentUser)?.includes("screencloud.io") ||
    getUserEmail(currentUser)?.includes("screencloud.com") ||
    getUserEmail(currentUser)?.includes("next.sc")
  );
}

export async function getGainsightUserIdentity(
  currentUser: User | null,
  featureFlags: CurrentFeatureFlag
) {
  const userIdentity: { [key: string]: any } = {
    id: currentUser?.id, // Required for logged in app users
    email: currentUser?.email,
    firstName: currentUser?.givenName,
    lastName: currentUser?.familyName,
    signUpDate: await getSignUpDate(currentUser),
  };

  const scIdentity: { [key: string]: any } = {
    id: "0000",
    email: "screenclouduser@screencloud.io",
    firstName: "ScreenCloud",
    lastName: "User",
    signUpDate: new Date("01/01/2022").getTime(),
  };

  if (currentUser && isScreenCloudUser(currentUser)) {
    return scIdentity;
  } else {
    return userIdentity;
  }
}

export function getUserInfo(user: Pick<User, "preferences">): UserInfo {
  return user?.preferences?.userInfo || {};
}
