import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const BrowserErrorDialog = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .warning {
    border-radius: 5px;
    background: ${Theme.color.white};
    min-width: 80%;
    padding: 60px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .title {
      color: ${Theme.color.base};
      font-size: 1.25em;
      line-height: 1.4;
    }

    & .description {
      font-size: 1em;
      font-weight: 400;
      line-height: 1.3125;
      color: #${Theme.color.midGrey};
      margin-top: 10px;
    }

    & .download {
      margin-top: 20px;
    }
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;

  .button-save {
    div {
      button {
        width: auto !important;
      }
    }
  }

  .sites {
    img {
      margin: 0 auto;
    }
  }
`;

export const Styled = styled.div`
  .chrome-extension-warning {
    text-align: center;
    border-radius: 20px;
    background: white;
    width: 950px;
    height: 350px;
    position: absolute;
    top: 0;
    bottom: 350px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .extension-warning-text {
    color: black;
    font-size: X-Large;
    padding-top: 75px;
  }

  .sub-text {
    color: grey;
    font-size: smaller;
    padding-top: 10px;
  }

  .icon {
    > img {
      height: 32px;
      width: 32px;
    }
  }

  .url-bar-container {
    padding: 16px;
    background-color: var(--color-gray-50);
  }

  .url-bar-input-container {
    display: flex;
  }

  .url-bar-input {
    border: none;
    flex: 1;
    font-size: 14px;
    padding: 0px 8px;
    border: 1px solid #bbb;
  }

  .url-bar-button {
    background-color: var(--color-primary-background);
    border: 1px solid var(--color-primary-background);
    padding: 8px 16px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }

  .url-bar-button:disabled {
    background-color: #bbb;
    border-color: #bbb;
    cursor: not-allowed;
  }

  .url-bar-info {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #bb0000;
  }
`;
export const SiteRecorderErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span:not(:last-child) {
    margin-bottom: 16px;
  }

  span {
    text-align: center;
  }
`;

export const ConfirmDashboardSetup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span:not(:last-child) {
    margin-bottom: 16px;
  }

  span {
    text-align: center;
  }
`;
