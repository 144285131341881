import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const SleepStatusStyled = styled.div`
  display: flex;
  gap: 4px;
  width: fit-content;
  padding: 4px 9px 4px 4px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;
  border-radius: 11px;
  background: ${Theme.color.sleep};
  color: ${Theme.color.white100};

  > i {
    width: 14px;
    height: 14px;
  }
`;

export const OffHourNoticeInfoStyled = styled.div`
  background: ${Theme.color.darkGrey};
  color: ${Theme.color.white};
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  ${Theme.default.borderRadius};
  font-size: 14px;
`;
