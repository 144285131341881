import { Icon } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "../../utils/compose";
import { Styled } from "./styles";

import { PrimaryButton } from "../../helpers/whiteLabel";
import errorPlaceholder from "../../images/error.svg";
import { ScreenCloudContactUrl } from "../../constants/url";

interface Props extends RouteComponentProps<any> {}
type State = {};

class GenericNotFound extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <Styled>
        <div>
          <img src={errorPlaceholder} />
          <h1>
            <FormattedMessage
              id="ui_component.label.404_page_header"
              defaultMessage="Something went wrong!"
            />
          </h1>
          <div className="message">
            <FormattedMessage
              id="ui_component.label.404_page_description"
              defaultMessage="Oops! The requested page was not found. if you need help, please"
            />{" "}
            <a href={ScreenCloudContactUrl} target="_blank" rel="noreferrer">
              <FormattedMessage
                id="ui_component.label.contact_support"
                defaultMessage="contact support"
              />
              <Icon name="external" />
            </a>
            .
          </div>
          <PrimaryButton onClick={() => this.props.history.push("/screens")}>
            <FormattedMessage
              id="ui_component.label.404_back_button"
              defaultMessage="Back to Screens"
            />
          </PrimaryButton>
        </div>
      </Styled>
    );
  }
}

export default compose(withRouter)(GenericNotFound) as React.ComponentType<{}>;
