/* eslint-disable no-redeclare */
import { Direction, TransitionType } from "@screencloud/signage-player";

interface TransitionDirectionOption {
  key: Direction;
  text: string;
  value: Direction;
}

interface Transitions {
  [key: string]: {
    text: string;
    defaultDirectionValue?: Direction;
    directions: TransitionDirectionOption[];
    isColor?: boolean;
  };
}

const defaultDirections: TransitionDirectionOption[] = [
  {
    key: "right",
    text: "Right",
    value: "right",
  },
  {
    key: "left",
    text: "Left",
    value: "left",
  },
  {
    key: "up",
    text: "Up",
    value: "up",
  },
  {
    key: "down",
    text: "Down",
    value: "down",
  },
];

const centerDirection: TransitionDirectionOption = {
  key: "center",
  text: "Center",
  value: "center",
};

const clockwiseDirections: TransitionDirectionOption[] = [
  {
    key: "clockwise",
    text: "Clockwise",
    value: "clockwise",
  },
  {
    key: "counterclockwise",
    text: "Counterclockwise",
    value: "counterclockwise",
  },
];

const flipDirections: TransitionDirectionOption[] = [
  {
    key: "right",
    text: "Horizontal",
    value: "right",
  },
  {
    key: "down",
    text: "Vertical",
    value: "down",
  },
];

export const Transitions: Transitions = {
  [TransitionType.None]: {
    text: "None",
    directions: [],
  },
  [TransitionType.Dissolve]: {
    text: "Dissolve",
    directions: [],
  },
  [TransitionType.Fade]: {
    text: "Fade",
    directions: [],
    isColor: true,
  },
  [TransitionType.Flip]: {
    text: "Flip",
    defaultDirectionValue: flipDirections[0].value,
    directions: flipDirections,
  },
  [TransitionType.Slide]: {
    text: "Slide",
    defaultDirectionValue: defaultDirections[0].value,
    directions: defaultDirections,
  },
  [TransitionType.Bounce]: {
    text: "Bounce",
    defaultDirectionValue: centerDirection.value,
    directions: [centerDirection, ...defaultDirections],
  },
  [TransitionType.Rotate]: {
    text: "Rotate",
    defaultDirectionValue: clockwiseDirections[0].value,
    directions: clockwiseDirections,
  },
  [TransitionType.Zoom]: {
    text: "Zoom",
    defaultDirectionValue: centerDirection.value,
    directions: [centerDirection, ...defaultDirections],
  },
  [TransitionType.Roll]: {
    text: "Roll",
    defaultDirectionValue: defaultDirections[0].value,
    directions: defaultDirections,
  },
  [TransitionType.JackInTheBox]: {
    text: "Jack In The Box",
    directions: [],
  },
};
