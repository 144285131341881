import { ApolloQueryResult, ObservableSubscription } from "@apollo/client";
import {
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  Icon,
  Popover,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getCastContentItem } from "src/domains/cast/castContent";
import { AppContextState } from "../../AppContextProvider";
import { RefType } from "../../constants/constants";
import {
  getChannelContentByOwner,
  getChannelName,
  getDefaultChannelNameOrg,
} from "../../helpers/channelHelper";
import { getBadgeIconByIdAndType } from "../../helpers/screenHelper";
import { useAppContext } from "../../hooks/useAppContext";
import client from "../../state/apolloClient";
import {
  Org,
  ScreenContentHistoriesByScreenIdDocument,
  ScreenContentHistoriesByScreenIdQuery,
  ScreenContentHistory,
  CastByCastIdFragment,
  Maybe,
} from "../../types.g";
import { Styled } from "./styles";

export interface ContentItem {
  id: UUID;
  name: string;
  type: RefType;
  mimeType?: string;
  badgeIcon?: React.ReactNode;
}
export interface ScreenContentPickerProps {
  screenId: UUID;
  disabled?: boolean;
  onCastStart?: () => void;
  onCastStop?: (castItem?: Maybe<CastByCastIdFragment>) => void;
  onStopContent?: () => void;
  onHistoryPick?: (id: UUID, type: RefType) => void;
  inline?: boolean;
  className?: string;
  castByCastId: Maybe<CastByCastIdFragment>;
  contentItem?: ContentItem;
  "data-testid"?: string;
}

export interface ScreenContentPickerState {
  recentlyPlayedContent: ScreenContentHistory[];
  castItem?: Maybe<CastByCastIdFragment>;
  contentItem?: ContentItem;
}

export const handleCastStart = (
  e: React.MouseEvent | React.KeyboardEvent,
  onCastStart?: () => void
) => {
  e && e.stopPropagation();
  if (onCastStart) {
    onCastStart();
  }
};

export const handleCastStop = (
  e: React.MouseEvent | React.KeyboardEvent,
  onCastStop?: (castItem?: Maybe<CastByCastIdFragment>) => void,
  castItem?: Maybe<CastByCastIdFragment>
) => {
  e && e.stopPropagation();
  if (onCastStop) {
    onCastStop(castItem);
  }
};

export const renderRecentContent = (
  context: AppContextState,
  contentHistories: ScreenContentHistory[],
  onHistoryPick?: (id: UUID, type: RefType) => void
) => {
  return contentHistories
    ?.filter((contentHistory) => {
      return (
        contentHistory?.fileByFileIdAndOrgId ||
        contentHistory?.linkByLinkIdAndOrgId ||
        contentHistory?.siteBySiteIdAndOrgId ||
        contentHistory?.appInstanceByAppInstanceIdAndOrgId ||
        contentHistory?.playlistByPlaylistIdAndOrgId ||
        contentHistory?.channelByChannelIdAndOrgId
      );
    })
    .map((contentHistory, idx) => {
      let name;
      let type = contentHistory?.content._ref.type;
      let badgeIcon;
      switch (contentHistory?.content._ref.type) {
        case RefType.CHANNEL:
          name = getChannelName(
            contentHistory.channelByChannelIdAndOrgId!,
            context.currentOrg! as Org
          );
          const channel = getChannelContentByOwner({
            context,
            channel: contentHistory.channelByChannelIdAndOrgId,
          });

          badgeIcon = getBadgeIconByIdAndType(
            RefType.CHANNEL,
            channel,
            context
          );
          break;
        case RefType.FILE:
          name = contentHistory.fileByFileIdAndOrgId?.name;
          const fileType =
            contentHistory.fileByFileIdAndOrgId?.mimetype &&
            contentHistory.fileByFileIdAndOrgId?.mimetype.split("/")[0];
          switch (fileType) {
            case "image":
              type = "image";
              break;
            case "video":
              type = "video";
              break;
            case "audio":
              type = "audio";
              break;
            case "application":
              type = "document";
              break;
          }
          break;
        case RefType.LINK:
          name = contentHistory.linkByLinkIdAndOrgId?.name;
          break;
        case RefType.APP:
          name = contentHistory.appInstanceByAppInstanceIdAndOrgId?.name;
          break;
        case RefType.PLAYLIST:
          name = contentHistory.playlistByPlaylistIdAndOrgId?.name;
          badgeIcon = getBadgeIconByIdAndType(
            RefType.PLAYLIST,
            contentHistory.playlistByPlaylistIdAndOrgId,
            context
          );
          break;
        case RefType.SITE:
          name = contentHistory.siteBySiteIdAndOrgId?.name;
          type = "dashboard";
          break;
        default:
          break;
      }
      return (
        <DropdownItem
          data-testid={`screen-content-histories-${idx}`}
          key={`screen-content-histories-${contentHistory?.content._ref.id}`}
          onClick={() => {
            onHistoryPick &&
              onHistoryPick(
                contentHistory?.content._ref.id,
                contentHistory?.content._ref.type
              );
          }}
        >
          <Icon name={type} />
          <span>{name}</span> {badgeIcon}
        </DropdownItem>
      );
    });
};

const getContentIcon = (contentItemType: string) => {
  if (contentItemType === RefType.HREF) {
    return "arrow-up-right-circle";
  }
  return contentItemType;
};

export const RecentContent = ({
  context,
  props,
  castItemType,
  castName,
  isCasting,
  recentlyPlayedContent,
  contentItem,
  castItem,
}: {
  context: AppContextState;
  props: ScreenContentPickerProps;
  castItemType: string;
  castName: string;
  isCasting: boolean;
  recentlyPlayedContent: ScreenContentHistory[];
  contentItem: ContentItem | undefined;
  castItem: Maybe<CastByCastIdFragment>;
}) => {
  const detaultChannelName = getDefaultChannelNameOrg(
    context.currentOrgStartableChannels,
    context.currentOrg as Org
  );
  const recentContent = renderRecentContent(
    context,
    recentlyPlayedContent,
    props.onHistoryPick
  );
  return (
    <Dropdown
      data-testid="recent-content-dropdown"
      disabled={
        !context.currentPermissions.validateCurrentSpace("screen", "content") ||
        props.disabled
      }
      trigger={
        <div className="dropdown-trigger">
          {(isCasting ||
            (context.currentOrg?.brandChannelId !== contentItem?.id &&
              context.currentOrg?.blankChannelId !== contentItem?.id)) &&
            castName !== detaultChannelName && (
              <Icon name={getContentIcon(castItemType)} />
            )}
          <div data-testid="trigger-text" className="trigger-text">
            {castName}
            {!isCasting && props.contentItem?.badgeIcon}
          </div>
        </div>
      }
    >
      <DropdownMenu>
        {isCasting && (
          <>
            <DropdownItem
              data-testid="stop-casting-content"
              className="stop-playing"
              onClick={(e) => handleCastStop(e, props.onCastStop, castItem)}
            >
              <Icon name="stop" />
              <span>Stop Casting</span>
            </DropdownItem>
            {recentContent?.length === 0 && <DropdownDivider />}
          </>
        )}
        {!isCasting && context.currentOrg?.startChannelId !== contentItem?.id && (
          <>
            <DropdownItem
              data-testid="stop-playing-content"
              className="stop-playing"
              onClick={() => {
                if (props.onStopContent) {
                  props.onStopContent();
                }
              }}
            >
              <Icon name="stop" />
              <span>Stop Playing</span>
            </DropdownItem>
            {recentContent?.length === 0 && <DropdownDivider />}
          </>
        )}
        {recentContent && recentContent.length > 0 && (
          <>
            <DropdownHeader>Recently Played</DropdownHeader>
            {recentContent}
            <DropdownDivider />
          </>
        )}
        <DropdownItem
          disabled={!canAccessAnySection(context)}
          onClick={(e) => handleCastStart(e, props.onCastStart)}
        >
          <Icon name="screen-play" />
          <span>Browse more</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export const canAccessAnySection = (context: AppContextState): boolean => {
  const canReadChannel = context.currentPermissions.validateCurrentSpace(
    "channel",
    "read"
  );

  const canReadPlaylist = context.currentPermissions.validateCurrentSpace(
    "playlist",
    "read"
  );

  const canReadMedia = context.currentPermissions.validateCurrentSpace(
    "media",
    "read"
  );

  const canReadApp = context.currentPermissions.validateCurrentSpace(
    "app_instance",
    "read"
  );

  const canReadLink = context.currentPermissions.validateCurrentSpace(
    "link",
    "read"
  );

  const canReadSite = context.currentPermissions.validateCurrentSpace(
    "site",
    "read"
  );

  return (
    canReadChannel ||
    canReadPlaylist ||
    canReadMedia ||
    canReadApp ||
    canReadLink ||
    canReadSite
  );
};

export const ScreenContentPicker = (props: ScreenContentPickerProps) => {
  const context = useAppContext();
  let querySubcription: ObservableSubscription;
  let screenHistoryDebounce;
  const [castItem, setCastItem] = useState<Maybe<CastByCastIdFragment>>(
    props.castByCastId
  );
  const [contentItem, setContentItem] = useState<ContentItem | undefined>(
    props.contentItem
  );
  const [recentlyPlayedContent, setRecentlyPlayedContent] = useState<
    ScreenContentHistory[]
  >([]);

  useEffect(() => {
    setCastItem(props.castByCastId);
  }, [props.castByCastId]);

  useEffect(() => {
    setContentItem(props.contentItem);
  }, [props.contentItem]);

  useEffect(() => {
    return querySubcription?.unsubscribe();
  }, []);

  const onDropDownHover = () => {
    clearDebounceScreenHistory();
    screenHistoryDebounce = setTimeout(() => {
      if (!querySubcription) {
        try {
          querySubcription = client
            .watchQuery({
              query: ScreenContentHistoriesByScreenIdDocument,
              variables: {
                id: props.screenId,
                first: 5,
                spaceId: context.user.settings.spaceId,
              },
              fetchPolicy: "cache-first",
            })
            .subscribe({
              next: ({
                data,
              }: ApolloQueryResult<ScreenContentHistoriesByScreenIdQuery>) => {
                if (data?.screenById?.screenContentHistoriesByScreenId) {
                  setRecentlyPlayedContent(
                    data.screenById.screenContentHistoriesByScreenId
                      .nodes as ScreenContentHistory[]
                  );
                }
              },
              error: (e) => console.error("error = ", e),
            });
        } catch (error) {
          console.error("error: ", error);
        }
      }
    }, 250);
  };

  const clearDebounceScreenHistory = () => {
    // canceled timeout
    if (screenHistoryDebounce) {
      clearTimeout(screenHistoryDebounce);
      screenHistoryDebounce = null;
    }
  };

  const { inline } = props;
  let componentClassname = "";

  if (inline) {
    componentClassname = "inline";
  }
  const detaultChannelName = getDefaultChannelNameOrg(
    context.currentOrgStartableChannels,
    context.currentOrg as Org
  );

  let castItemType = contentItem?.type ?? "channel";
  let castItemName = contentItem?.name ?? detaultChannelName;

  if (contentItem) {
    if (castItemType === RefType.FILE) {
      const fileType =
        contentItem.mimeType && contentItem.mimeType.split("/")[0];
      switch (fileType) {
        case "image":
          castItemType = "image";
          break;
        case "video":
          castItemType = "video";
          break;
        case "audio":
          castItemType = "audio";
          break;
        case "application":
          castItemType = "document";
          break;
      }
    }
  }

  castItemType = castItemType === RefType.SITE ? "dashboard" : castItemType;

  if (castItem) {
    ({ castItemType, castItemName } = getCastContentItem(castItem));
  }

  const canCast = context.currentPermissions.validateCurrentSpace(
    "screen",
    "cast"
  );
  const canSetContent = context.currentPermissions.validateCurrentSpace(
    "screen",
    "content"
  );
  return (
    <Styled
      disabled={props.disabled}
      showfeature={canCast}
      className={componentClassname}
      onMouseOver={onDropDownHover}
      onMouseOut={clearDebounceScreenHistory}
      data-testid={props["data-testid"]}
    >
      <div
        className={`picker-container set-content ${
          castItem ? "screen-casting" : ""
        }`}
      >
        <div className="content-nav">
          <div className={`status playing ${castItem ? "casting-label" : ""}`}>
            {castItem ? (
              <FormattedMessage
                id="ui_component.screen_content_nav.casting"
                defaultMessage="Casting"
              />
            ) : (
              <FormattedMessage
                id="ui_component.screen_content_nav.now_playing"
                defaultMessage="Now Playing"
              />
            )}
          </div>
          {RecentContent({
            context,
            props,
            castItemType,
            castName: castItemName,
            isCasting: castItem ? true : false,
            recentlyPlayedContent,
            contentItem,
            castItem,
          })}
        </div>
        {(!props.disabled || !canSetContent) && (
          <>
            {canSetContent &&
              (castItem ? (
                <div
                  data-testid="screen-cast-stop"
                  title="Stop casting"
                  className="screen-stop"
                  onClick={(e) => handleCastStop(e, props.onCastStop, castItem)}
                >
                  <Icon name="stop" />
                </div>
              ) : canAccessAnySection(context) ? (
                <Popover
                  trigger={
                    <div
                      data-testid="screen-cast-start"
                      className="screen-picker"
                      onClick={(e) => handleCastStart(e, props.onCastStart)}
                    >
                      <Icon name="screen-play" />
                    </div>
                  }
                  content="Set content"
                  position="top center"
                  inverted
                />
              ) : null)}
          </>
        )}
      </div>
    </Styled>
  );
};

export default ScreenContentPicker;
