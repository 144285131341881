import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
  InlineInput,
  Loader,
  TagSelector,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "../../../AppContextProvider/type";
import { TAG_LIMIT } from "../../../constants/constants";
import { AppContext } from "../../../AppContextProvider/AppContext";

import Header from "../../../components/Header";
import StyledTag from "../../../components/Tag";
import { hasSchedule } from "../../../helpers/scheduleableHelper";
import { AppInstanceAction } from "../../App/AppContainer";
import AvailabilityStatusIcon from "../../AvailabilityStatusIcon";
import { isGallerySection, isTemplateSection } from "src/helpers/canvasHelper";

export interface Props {
  appIcon?: string;
  title: string;
  instanceName: string | undefined;
  textPlaceholder: string;
  isEdit: boolean;
  isTemplate?: boolean | null;
  children: React.ReactNode;
  handleClose: () => Promise<void>;
  handleNameSaved: (event: React.SyntheticEvent<any>, value: string) => void;
  disabled?: boolean;
  className?: string;
  disabledInline?: boolean;
  onAppActionCallback?: (action: AppInstanceAction) => void;
  shouldShowShareOption?: boolean;
  shouldShowSetToScreenOption?: boolean;
  shoudShowSetAvailabilityOption?: boolean;
  shouldShowDeleteOption?: boolean;
  shoudShowSaveAndCloseOption?: boolean;
  shoudShowSaveAsTemplateOption?: boolean;
  shouldShowAddToPlaylistOption?: boolean;
  shouldShowDuplicateOption?: boolean;
  shouldShowFindAndReplace?: boolean;
  expireAt?: string;
  availableAt?: string;
  allowTags?: boolean;
  onUpdateTagCallback?: (tags: string[]) => void;
  availableTags?: string[];
  tags?: string[];
  isAvailableTagsLoading?: boolean;
}

class FullScreenModalHeader extends React.Component<Props> {
  public static contextType = AppContext;
  public context: AppContextType;

  public render() {
    const {
      instanceName,
      disabledInline,
      textPlaceholder,
      handleNameSaved,
      disabled,
      isEdit,
      children,
      title,
      appIcon,
      className,
      isTemplate,
      shoudShowSaveAndCloseOption,
      shouldShowShareOption,
      shoudShowSetAvailabilityOption,
      shouldShowSetToScreenOption,
      shouldShowDeleteOption,
      shouldShowAddToPlaylistOption,
      shouldShowDuplicateOption,
      shouldShowFindAndReplace,
      shoudShowSaveAsTemplateOption,
      expireAt,
      availableAt,
      allowTags,
      availableTags,
      tags,
      onUpdateTagCallback,
      isAvailableTagsLoading,
    } = this.props;

    const shouldHideOptions =
      !shoudShowSaveAndCloseOption &&
      !shouldShowShareOption &&
      !shoudShowSetAvailabilityOption &&
      !shouldShowSetToScreenOption &&
      !shouldShowDeleteOption &&
      !shouldShowDuplicateOption &&
      !shouldShowAddToPlaylistOption &&
      !shouldShowFindAndReplace &&
      !isEdit;

    const pathname = window.location.pathname;

    const isCanvasTemplate =
      isTemplateSection(pathname) || isGallerySection(pathname);

    const shouldShowSaveAsTemplateOption =
      isCanvasTemplate && shoudShowSaveAsTemplateOption;

    return (
      <Header className="app-config-header">
        <div className="wrapper detail">
          <div className="column-title">
            {/** TODO: This component is FullScreenModalHeader so className should not specify to be prefix with app- */}
            <div title={title} className={`app-title ${className}`}>
              <img src={appIcon} alt="" data-testid="header-img" />
            </div>
            <div className="app-instance-title" title={instanceName}>
              <div className="title">
                <InlineInput
                  data-testid="inline-input"
                  placeholder={textPlaceholder}
                  value={instanceName}
                  onSaved={handleNameSaved}
                  editmode={disabled ? false : isEdit}
                  showIcon={true}
                  disabled={disabledInline ?? disabled}
                />
              </div>
              <div className="tag-input">
                {!isAvailableTagsLoading ? (
                  allowTags && (
                    <TagSelector
                      inline
                      placeholder={this.context.intl.formatMessage({
                        defaultMessage: "add tags",
                        id: "common.text.tags_add_tags",
                      })}
                      tags={tags ?? []}
                      availableTags={availableTags ?? []}
                      additionLabel="Addition label"
                      onTagChanged={(tags: string[]) => {
                        onUpdateTagCallback?.(tags);
                      }}
                      limit={TAG_LIMIT}
                    />
                  )
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          {!disabled && (
            <div className="column-options">
              {hasSchedule({ availableAt, expireAt }) && (
                <div className="column-availbility">
                  <AvailabilityStatusIcon
                    expireAt={expireAt}
                    availableAt={availableAt}
                    callback={() => {
                      this.props.onAppActionCallback &&
                        this.props.onAppActionCallback(
                          AppInstanceAction.SET_AVAILABILITY
                        );
                    }}
                  />
                </div>
              )}
              {isTemplate && (
                <div className="column-tag">
                  <StyledTag color="#1f60ec">
                    <span data-testid="template-tag">
                      {this.context.intl.formatMessage({
                        defaultMessage: "TEMPLATE EDITOR",
                        id: "canvas.templates.tag",
                      })}
                    </span>
                  </StyledTag>
                </div>
              )}
              {children}
              {!shouldHideOptions && (
                <Dropdown
                  checkEmpty
                  icon={
                    <Button icon>
                      <Icon name="dots" />
                    </Button>
                  }
                  direction={"right"}
                  className="app-action-dropdown"
                  data-testid="app-action-dropdown"
                >
                  <DropdownMenu>
                    {shouldShowSetToScreenOption && (
                      <DropdownItem
                        data-testid="set-to-screen-button"
                        onClick={() =>
                          this.props.onAppActionCallback &&
                          this.props.onAppActionCallback(
                            AppInstanceAction.SET_TO_SCREEN
                          )
                        }
                      >
                        <Icon name="screen-play" />
                        <FormattedMessage
                          id="ui_component.common.label.set_to_screen"
                          defaultMessage="Set to Screen"
                        />
                      </DropdownItem>
                    )}

                    {shouldShowSaveAsTemplateOption && (
                      <DropdownItem
                        onClick={() => {
                          this.props.onAppActionCallback &&
                            this.props.onAppActionCallback(
                              AppInstanceAction.SAVE_AS_TEMPLATE
                            );
                        }}
                        data-testid="save-as-template-button"
                      >
                        <Icon name="save" />{" "}
                        <FormattedMessage
                          id="ui_component.common.label.save_as_template"
                          defaultMessage="Save As Template"
                        />
                      </DropdownItem>
                    )}

                    {(shouldShowSetToScreenOption ||
                      shoudShowSaveAsTemplateOption) && <DropdownDivider />}

                    {shouldShowAddToPlaylistOption && (
                      <DropdownItem
                        onClick={() => {
                          this.props.onAppActionCallback &&
                            this.props.onAppActionCallback(
                              AppInstanceAction.ADD_TO_PLAYLISTS
                            );
                        }}
                        data-testid="add-to-playlist-button"
                      >
                        <Icon name="playlist-create" />{" "}
                        <FormattedMessage
                          id="media.add_to_playlists"
                          defaultMessage="Add to Playlists"
                        />
                      </DropdownItem>
                    )}

                    {shouldShowDuplicateOption && (
                      <DropdownItem
                        data-testid="duplicate-button"
                        onClick={() => {
                          this.props.onAppActionCallback &&
                            this.props.onAppActionCallback(
                              AppInstanceAction.DUPLICATE
                            );
                        }}
                      >
                        <Icon name="duplicate" />
                        <FormattedMessage
                          id="ui_component.common.label.duplicate"
                          defaultMessage="Duplicate"
                        />
                      </DropdownItem>
                    )}

                    {shouldShowShareOption && (
                      <DropdownItem
                        data-testid="share-button"
                        onClick={() =>
                          this.props.onAppActionCallback &&
                          this.props.onAppActionCallback(
                            AppInstanceAction.SHARE
                          )
                        }
                      >
                        <Icon name="users" />
                        {isTemplate ? (
                          <FormattedMessage
                            id="common.label.publish_and_share"
                            defaultMessage="Publish and Share"
                          />
                        ) : (
                          <FormattedMessage
                            id="ui_component.label.share"
                            defaultMessage="Share"
                          />
                        )}
                      </DropdownItem>
                    )}

                    {shoudShowSetAvailabilityOption && (
                      <DropdownItem
                        data-testid="availablility-button"
                        onClick={() =>
                          this.props.onAppActionCallback &&
                          this.props.onAppActionCallback(
                            AppInstanceAction.SET_AVAILABILITY
                          )
                        }
                      >
                        <Icon name="calendar" />
                        <FormattedMessage
                          id="ui_component.common.label.set_availability"
                          defaultMessage="Set Availability"
                        />
                      </DropdownItem>
                    )}
                    {shouldShowFindAndReplace && (
                      <DropdownItem
                        onClick={() =>
                          this.props.onAppActionCallback &&
                          this.props.onAppActionCallback(
                            AppInstanceAction.FIND_AND_REPLACE
                          )
                        }
                        data-testid="appinstance-find-and-replace"
                        data-cy="appinstance-find-and-replace"
                      >
                        <Icon name="swap" />
                        <FormattedMessage
                          id="ui_component.media_item.find_replace"
                          defaultMessage="Find & Replace"
                        />
                      </DropdownItem>
                    )}
                    {shouldShowDeleteOption && (
                      <DropdownItem
                        data-testid="appinstance-delete"
                        onClick={() =>
                          this.props.onAppActionCallback &&
                          this.props.onAppActionCallback(
                            AppInstanceAction.DELETE
                          )
                        }
                        className="danger"
                      >
                        <Icon name="trash" />
                        <FormattedMessage
                          id="ui_component.common.label.delete"
                          defaultMessage="Delete"
                        />
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
          )}

          <div className="column-back">
            <Button
              className="close-btn"
              onClick={() => this.props.handleClose()}
              data-testid="close-button"
              icon
            >
              <Icon name="close" />
            </Button>
          </div>
        </div>
      </Header>
    );
  }
}

export default FullScreenModalHeader;
