import styled from "styled-components";

type BoundLabelStyledProps = {
  direction?: string;
  tiny?: boolean;
  light?: boolean;
};

export const BoundLabelStyled = styled.div<BoundLabelStyledProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  color: ${({ light }) =>
    light
      ? "var(--color-gray-300, #A1A2A7)"
      : "var(--color-gray-950, #19191b)"};
  font-size: ${({ tiny }) => (tiny ? "10px" : "14px")};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ tiny }) => (tiny ? "14px" : "20px")};
  gap: 4px;
  .highlight {
    color: ${({ light }) =>
      light
        ? "var(--color-yellow-500, #FECF00);"
        : "var(--color-yellow-700, #a66d02)"};
    font-weight: ${({ tiny }) => (tiny ? "400" : "700")};
  }
`;
