import { makeVar } from "@apollo/client";
import { locationDetailsProps } from "../../components/ScreenLocation/minimap";

// shape of local state
export interface AppState {
  selectedScreenLocation: locationDetailsProps | null;
  fakeScreenName: string | null;
  fakeScreenId: string | null;
}

const initialState: AppState = {
  selectedScreenLocation: null,
  fakeScreenName: null,
  fakeScreenId: null,
};

export const appState = makeVar<AppState>(initialState);
