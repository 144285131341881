import { Theme } from "@screencloud/screencloud-ui-components";
import { WhiteLabelStyles } from "src/helpers/whiteLabel";
import styled from "styled-components";

interface LogoBannerStyledProps {
  supportResponsive: boolean;
}
export const Styled = styled.div<LogoBannerStyledProps & WhiteLabelStyles>`
  .logo-banner {
    display: flex;
    align-items: center;

    width: 100%;

    > .banner-organization-name {
      margin-left: 14px;

      font-size: 20px;
      font-weight: 700;

      color: ${(props) =>
        props.textColor ?? `var(--color-primary-foreground-active)`};

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ${(props) => (props.supportResponsive ? responsive : "")}
  }
`;

const responsive: string = `
  @media screen and (max-width: ${Theme.break.largeHD}) {
    justify-content: center;

    > .banner-organization-name {
      display: none;
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    justify-content: flex-start;
    height: 48px;

    > .banner-organization-name {
      display: block;
    }
  }
`;
