import { Countries } from "src/billing/constants";
import { getOrgRegionFromUrl } from "src/helpers/urlHelper";
import { appConfig } from "src/appConfig";

const isDevelopment = appConfig.environment === "development";

export interface AppInstanceConfig {
  version: string;
  config: Record<string, unknown>;
}

export interface AppInstancesConfig {
  [key: string]: {
    [key: string]: AppInstanceConfig;
  };
}
export interface AppInfo {
  appName: string;
  appInstancesName: string[];
}

export const Apps = {
  WEATHER: "Weather",
  CLOCK: "Clock",
  SOCCER_SCORES: "Soccer Scores",
  CANVAS: "Canvas",
  SPORTS_LIVE_SCORES: "Sports Live Scores",
  STOCKS: isDevelopment ? "Stocks" : "Stocks & Exchanges",
  QUICK_POST: "Quick Post",
};

export enum AppInstances {
  PREMIER_LEAUGE_APP = "Premier League App",
  LOCAL_TIME = "Local Time",
  WEATHER_INSTANCE = "Weather Instance",
  MLB_SCORES = "MLB Scores",
  STOCKS = "Stocks",
  QUICK_POST = "Example: Quick Post",
}

export const getAppWithInstances = (
  countryCode: string
): AppInstancesConfig => {
  const country = Countries[countryCode] || "United Kingdom";

  return {
    [Apps.WEATHER]: {
      [AppInstances.WEATHER_INSTANCE]: {
        version: "2.0.8",
        config: {
          lang: "en",
          unit: "c",
          ttlayout: "advanced",
          location1: country,
          screenLocation: false,
        },
      },
    },
    [Apps.CLOCK]: {
      [AppInstances.LOCAL_TIME]: {
        version: "2.0.8",
        config: {
          ampm: getOrgRegionFromUrl().isUs,
          date: false,
          lang: "en",
          style: "simple",
          second: false,
          address: country,
        },
      },
    },
    [Apps.SOCCER_SCORES]: {
      [AppInstances.PREMIER_LEAUGE_APP]: {
        version: "1.0.0",
        config: {
          lang: "en",
          delay: 5,
          sports: "soccer-epl",
        },
      },
    },
    [Apps.SPORTS_LIVE_SCORES]: {
      [AppInstances.MLB_SCORES]: {
        version: "stable",
        config: {
          lang: "en",
          delay: 10,
          sports: "mlb",
        },
      },
    },
    [Apps.STOCKS]: {
      [AppInstances.STOCKS]: {
        version: "2.0.8",
        config: {
          symbol: "googl, ftse, aapl, btc/usd, amzn, tsla, pypl, msft",
        },
      },
    },
    [Apps.QUICK_POST]: {
      [AppInstances.QUICK_POST]: {
        version: "stable",
        config: {}, // Quick post config value comes from value from Platform API
      },
    },
  };
};
