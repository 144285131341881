import { Icon, Loader, Search } from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { updateAndToggleSelectedItems } from "../../helpers/updateAndToggleSelectedItemsHelper";
import { Maybe, Site, SiteFragment, SitesOrderBy } from "../../types.g";
import EmptyState from "../EmptyState";
import { SiteListHeaderActions } from "../SiteListHeader";
import { ApolloProps, withData } from "./apollo";
import SiteContentPickerItem from "./siteContentPickerItem";
import { SitePickerItem, Styled } from "./styles";
import { useAppContext } from "src/hooks/useAppContext";

export interface SitePickerComponentProps {
  isMultipleSelect: boolean;
  spaceId?: UUID;
  callback: (ids: string[], selectedSites: Maybe<Partial<Site>>[]) => void;
  handleCreateSiteCallback: () => void;
}

export interface SitePickerComponentState {
  searchTerms: string;
  selectedIds: string[];
  sortBy: SiteListHeaderActions;
  isOrderByAscending: boolean;
  isFetchMore: boolean;
}

export const SitePickerComponent = (
  props: SitePickerComponentProps & ApolloProps
) => {
  const context = useAppContext();
  const [isOrderByAscending, setIsOrderByAscending] = React.useState(true);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [sortBy, setSortBy] = React.useState<SiteListHeaderActions>(
    SiteListHeaderActions.SORT_BY_NAME
  );

  const toggleSortAndOrderMediaItem = (action: SiteListHeaderActions) => {
    if (sortBy === action) {
      setSortBy(action);
      setIsOrderByAscending(!isOrderByAscending);
      props.refetch({
        orderBy: isOrderByAscending
          ? [SitesOrderBy.NameDesc, SitesOrderBy.CreatedAtDesc]
          : [SitesOrderBy.NameAsc, SitesOrderBy.CreatedAtAsc],
        spaceId: props.spaceId ?? context.user.settings.spaceId,
      });
    } else {
      setIsOrderByAscending(true);
      setSortBy(action);
    }
  };

  const renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  const onToggleSelection = (site: SiteFragment) => {
    if (props.sites.length && site) {
      const { selectedArray } = updateAndToggleSelectedItems(
        site.id,
        site,
        selectedIds,
        [],
        props.isMultipleSelect
      );

      setSelectedIds(selectedArray);
      const selectedSites = props.sites.filter(
        (site) => site.id && selectedArray.includes(site.id)
      );
      props.callback(selectedArray, selectedSites as Site[]);
    }
  };

  const siteContentPickerItemCallback = () => {
    props.refetch({
      orderBy: [SitesOrderBy.CreatedAtDesc, SitesOrderBy.NameAsc],
      spaceId: props.spaceId ?? context.user.settings.spaceId,
    });
  };

  const renderSiteItem = (): JSX.Element[] => {
    if (!props.sites.length) return [];
    return props.sites.map((site) => (
      <SitePickerItem
        className="media-item"
        key={`playlist-${site && site.id}`}
        onClick={() => onToggleSelection(site)}
        selected={selectedIds.includes(site && site.id)}
      >
        <SiteContentPickerItem
          site={site}
          callBack={siteContentPickerItemCallback}
          secureMediaPolicy={context.secureMediaPolicy}
        />
      </SitePickerItem>
    ));
  };

  const renderEmptyState = () => {
    if (props.sites.length > 0) return;
    if (props.searchString) {
      return (
        <EmptyState
          data-testid="sites-no-result"
          cover={false}
          section="search-site"
        >
          <h3>
            <FormattedMessage
              id="common.search.no_results"
              defaultMessage="We couldn’t find any matches"
            />
          </h3>
          <p>
            <FormattedMessage
              id="common.search.no_results_description"
              defaultMessage="Try adjusting or using different search terms."
            />
          </p>
        </EmptyState>
      );
    } else {
      return (
        <EmptyState section="site-picker" cover={false} className="empty">
          <h3>
            <FormattedMessage
              id="sites.site_empty.no_content"
              defaultMessage="No Sites created yet"
            />
          </h3>
          <p>
            <FormattedMessage
              id="sites.site_empty.help_text"
              defaultMessage="Add a site here"
            />
          </p>
        </EmptyState>
      );
    }
  };

  return (
    <Styled className="media-content link">
      <div className="content-header">
        <Search
          className="search"
          onClear={props.clearSearch}
          onChange={(e, data) => {
            props.updateSearchString(data.value);
          }}
          placeholder="Search Dashboards"
          showNoResults={false}
          value={props.searchString}
        />
      </div>
      {!props.isFirstTimeAlreadyLoaded ? (
        <div>
          <Loader active={props.loading} />
        </div>
      ) : (
        <div className="container">
          <div className="media-item-header">
            <div className="media-core">
              <div
                className="media-alpha"
                onClick={() =>
                  toggleSortAndOrderMediaItem(
                    SiteListHeaderActions.SORT_BY_NAME
                  )
                }
              >
                <FormattedMessage id="common.text.name" defaultMessage="Name" />
                {sortBy === SiteListHeaderActions.SORT_BY_NAME &&
                  renderOrderCaretIcon()}
              </div>
              <div
                className="media-starred"
                onClick={() =>
                  toggleSortAndOrderMediaItem(
                    SiteListHeaderActions.SORT_BY_STARRED_ASC
                  )
                }
              >
                <FormattedMessage
                  id="ui_component.common.label.starred"
                  defaultMessage="Starred"
                />
                {sortBy === SiteListHeaderActions.SORT_BY_STARRED_ASC ? (
                  renderOrderCaretIcon()
                ) : (
                  <div className="empty-icon" />
                )}
              </div>
              <div className="media-type">
                <FormattedMessage
                  id="ui_component.common.label.type"
                  defaultMessage="Type"
                />
              </div>
              <div className="media-url">
                <FormattedMessage
                  id="ui_component.common.label.url"
                  defaultMessage="Url"
                />
              </div>
              <div className="media-tags">
                <FormattedMessage
                  id="ui_component.common.label.tags"
                  defaultMessage="Tags"
                />
              </div>
            </div>
          </div>
          {props.sites.length ? (
            <div className="layout-list" id="scrollableDiv">
              {renderSiteItem()}
              {props.renderFetchMoreButton}
            </div>
          ) : (
            renderEmptyState()
          )}
          <div className="empty">
            <Loader active={props.loading} />
          </div>
        </div>
      )}
    </Styled>
  );
};

export default withData(SitePickerComponent);
