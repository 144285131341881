import { AppNames } from "src/constants/constants";
import { AllAppsQuery } from "../../types.g";

export interface AppInfo {
  id: string;
  name: string;
  iconUrl: string;
  category: string;
}

export const getAppInfo = (
  result: AllAppsQuery,
  appName: AppNames
): AppInfo | undefined => {
  if (result && result.allApps && result.allApps.nodes.length > 0) {
    const appInfo = result.allApps.nodes.find((node) => node.name === appName);
    if (appInfo) {
      return {
        id: appInfo.id,
        name: appInfo.name,
        category: appInfo.categories[0] || "",
        iconUrl: appInfo.iconUrl || "",
      };
    }
  }
  return undefined;
};
