import { produceSecureUrl } from "@screencloud/studio-media-client";
import { buildURL } from "react-imgix";
import { FormattedMessage } from "react-intl";
import { Icon } from "@screencloud/screencloud-ui-components";
import {
  File,
  FileListItemForPlaylistFragment,
  FileListItemFragment,
  FileOutput,
  JobStatus,
  Maybe,
  Scalars,
} from "../../src/types.g";
import { appConfig } from "../appConfig";
import { MediaType, NameMaxLengthAllow } from "../constants/constants";
import { ssm } from "../state/session/ssm";
import queryHelper from "../state/helper/query";
import Thumbnail from "../components/MediaThumbnail";
import failedProcessingThumbnail from "../images/processing_failure_thumb.svg";
import imagePlaceholder from "../images/image_thumb_placeholder.svg";
import videoPlaceholder from "../images/video_thumb_placeholder.svg";
import documentPlaceholder from "../images/document_thumb_placeholder.svg";
import audioPlaceholder from "../images/audio_thumb_placeholder.svg";
import { getTheUsedPlace } from "./deleteStuffHelper";
import DeleteWarning from "../components/DeleteWarning";

export { failedProcessingThumbnail };
export { imagePlaceholder };

export interface FSProgressEvent {
  totalPercent: number;
  totalBytes: number;
}

/**
 * Looks through the fileOutputs of a given File and returns a image FileOutput
 */
export const getFileOutputImageSet = (
  media: Partial<File> | FileListItemFragment | FileListItemForPlaylistFragment
) => {
  return (
    media.fileOutputsByFileId &&
    media.fileOutputsByFileId.nodes.find((fileOutput) => isImage(fileOutput!))
  );
};

/**
 * Looks through the fileOutputs of a given File and returns a video FileOutput
 * if it exists.
 * NB: This video will be the transcoded variant of the original video file.
 */
export const getFileOutputVideo = (media: Partial<File>) => {
  return (
    media.fileOutputsByFileId &&
    media.fileOutputsByFileId.nodes.find((fileOutput) => isVideo(fileOutput!))
  );
};

export const getFileOutputs4kVideoDetail = (media: Partial<File>) => {
  const fileOutputs =
    media.fileOutputsByFileId &&
    media.fileOutputsByFileId.nodes.find(
      (fileOutput) => fileOutput.mimetype === "application/dash+xml"
    );
  const metadata = fileOutputs?.metadata?.filter(
    (d) => d.videoDetails?.widthInPx || d.videoDetails?.heightInPx
  );
  const result = Object.values(
    (metadata ?? []).reduce((r, o) => {
      r[o.widthInPx] =
        r[o.widthInPx] && r[o.widthInPx].widthInPx > o.widthInPx ? r : o;
      return r;
    }, {} as FileOutput)
  ) as FileOutput[] | undefined;
  const fileoutputTmp = fileOutputs && { ...fileOutputs };
  if (fileoutputTmp?.metadata) {
    fileoutputTmp.metadata =
      Array.isArray(result) && result.length > 0
        ? result[0]
        : fileOutputs?.metadata;
  }
  return fileoutputTmp;
};

export const getFileOutputAudio = (media: Partial<File>) => {
  return (
    media.fileOutputsByFileId &&
    media.fileOutputsByFileId.nodes.find((fileOutput) => isAudio(fileOutput!))
  );
};

export const getFileDuration = (
  media: Partial<File>,
  defaultDuration: number
): number => {
  const videoFileOutput = getFileOutputVideo(media);
  return videoFileOutput?.metadata?.duration
    ? videoFileOutput.metadata.duration
    : defaultDuration;
};

export const getFileAudioDuration = (
  media: Partial<File>,
  defaultDuration: number
): number => {
  const videoFileOutput = getFileOutputAudio(media);
  return videoFileOutput?.metadata?.duration
    ? videoFileOutput.metadata.duration
    : defaultDuration;
};

interface HasMimeType {
  mimetype?: Maybe<Scalars["String"]>;
}

export const isDocument = (media: HasMimeType) =>
  ["text", "application"].some((documentType) =>
    (media.mimetype ?? "").startsWith(documentType)
  );

export const isVideo = (media: HasMimeType) =>
  (media?.mimetype ?? "").startsWith("video");

export const isImage = (media: HasMimeType) =>
  (media?.mimetype ?? "").startsWith("image");

export const isAudio = (media: HasMimeType) =>
  (media?.mimetype ?? "").startsWith("audio");

export const isMediaWithAudio = (media?: HasMimeType) =>
  media && (isAudio(media) || isVideo(media));

export const getMediaType = (media?: Pick<HasMimeType, "mimetype">) => {
  if (media && media.mimetype) {
    if (isDocument(media)) {
      return MediaType.DOCUMENT;
    }
    if (isImage(media)) {
      return MediaType.IMAGE;
    }
    if (isAudio(media)) {
      return MediaType.AUDIO;
    }
    if (isVideo(media)) {
      return MediaType.VIDEO;
    }
  }
  return MediaType.UNKNOWN;
};

export const getPlaceholderThumbnail = (mediaType: MediaType) => {
  switch (mediaType) {
    case MediaType.IMAGE:
      return imagePlaceholder;
    case MediaType.AUDIO:
      return audioPlaceholder;
    case MediaType.DOCUMENT:
      return documentPlaceholder;
    case MediaType.VIDEO:
      return videoPlaceholder;
  }
};

export const generateImgixURL = (
  media: MediaInput,
  width: number,
  height: number,
  secureMediaUrlPolicy: string | undefined,
  defaultPlaceHoldertype?: MediaType
): string => {
  const imgixParams = { w: width, h: height, fit: "clip", fm: "jpg" };
  const nodes =
    media?.fileOutputsByFileId && media.fileOutputsByFileId.nodes
      ? media.fileOutputsByFileId.nodes
      : [];
  const mediaImageKey = media?.metadata?.key;
  let fileThumbnail = imagePlaceholder;
  if (media?.fileProcessingStatus === JobStatus.Failed) {
    fileThumbnail = failedProcessingThumbnail;
  } else if (media?.fileProcessingStatus === JobStatus.Completed) {
    fileThumbnail = secureMediaUrlPolicy
      ? getResizedMedia(
          `${appConfig.secureMediaUrl}/${
            ssm.current.claims.orgId
          }/originals/${encodeURIComponent(mediaImageKey)}`,
          height,
          width
        )
      : `${appConfig.imgixBaseUrl}/${
          ssm.current.claims.orgId
        }/originals/${encodeURIComponent(mediaImageKey)}`;
  }
  // if the file is a vid or doc, and has not been transcoded, return a thumb placeholder.
  if (!nodes.length && media && !isImage(media)) {
    if (defaultPlaceHoldertype) {
      return getPlaceholderThumbnail(defaultPlaceHoldertype);
    } else {
      return isVideo(media)
        ? videoPlaceholder
        : isAudio(media)
        ? audioPlaceholder
        : documentPlaceholder;
    }
  }

  if (nodes.length) {
    const processedFile = nodes.find((fileOutput) => isImage(fileOutput!));
    // if the file has been processed, but somehow has no keys, render a thumb placeholder.
    if (processedFile && processedFile.content.keys) {
      fileThumbnail = `${appConfig.imgixBaseUrl}/${
        processedFile.content!.keys[0]
      }`;
    } else if (defaultPlaceHoldertype) {
      return getPlaceholderThumbnail(defaultPlaceHoldertype);
    } else {
      return isVideo(media)
        ? videoPlaceholder
        : media && isAudio(media)
        ? audioPlaceholder
        : documentPlaceholder;
    }
  }

  if (secureMediaUrlPolicy && fileThumbnail) {
    fileThumbnail = produceSecureUrl({
      baseUrl: fileThumbnail,
      policy: secureMediaUrlPolicy,
    });

    fileThumbnail = replaceWithSecureURL(fileThumbnail, height, width);
  }

  return buildURL(fileThumbnail, imgixParams);
};

/**
 * Tries to generate an Imgix URL for a given image in our S3 source.
 * If an image can't be found, returns a .png thumb placeholder for the
 * given mimetype.
 */
export const generateImgixThumbnail = (
  media: MediaInput,
  secureMediaUrlPolicy: string | undefined,
  small: boolean = false,
  defaultPlaceholderType?: MediaType
) => {
  const imgixParams = { w: 320, h: 180 };
  if (small) {
    imgixParams.h = imgixParams.w = 60;
  }
  return generateImgixURL(
    media,
    imgixParams.w,
    imgixParams.h,
    secureMediaUrlPolicy,
    defaultPlaceholderType
  );
};

export const getPreviewImageURLFromFile = (
  media: MediaInput,
  width: number = 320,
  height: number = 180,
  secureMediaUrlPolicy: string | undefined
): string | string[] => {
  return generateImgixURL(media, width, height, secureMediaUrlPolicy);
};

export const getMediaPlaceHolder = (media: HasMimeType): string => {
  if (!media) {
    return "";
  } else if (isVideo(media)) {
    return videoPlaceholder;
  } else if (isAudio(media)) {
    return audioPlaceholder;
  } else if (isDocument(media)) {
    return documentPlaceholder;
  } else if (isImage(media)) {
    return imagePlaceholder;
  } else {
    return "";
  }
};

export const getImageDimensions = async (handle: string) => {
  const imageSizeURL = `https://cdn.filestackcontent.com/imagesize/security=policy:${appConfig.fileStackSecurityPolicy},signature:${appConfig.fileStackSecuritySignature}/${handle}`;
  return fetch(imageSizeURL);
};

// We use a 120-char length database constraint on media file names to display
// more nicely
export const trimToLessThan80Char = (fileName: string) => {
  return fileName.trim().substring(0, NameMaxLengthAllow).trim();
};

export const getMediaTypeText = (media) => {
  const mediaType = getMediaType(media);
  switch (mediaType) {
    case MediaType.DOCUMENT:
      return "Document";
    case MediaType.IMAGE:
      return "Image";
    case MediaType.AUDIO:
      return "Audio";
    case MediaType.VIDEO:
      return "Video";
    default:
      return "";
  }
};

export const getMediaTypeTextFormatted = (media) => {
  const mediaType = getMediaType(media);
  switch (mediaType) {
    case MediaType.DOCUMENT:
      return (
        <FormattedMessage defaultMessage="Document" id="media.type.document" />
      );
    case MediaType.IMAGE:
      return <FormattedMessage defaultMessage="Image" id="media.type.image" />;
    case MediaType.AUDIO:
      return <FormattedMessage defaultMessage="Audio" id="media.type.audio" />;
    case MediaType.VIDEO:
      return <FormattedMessage defaultMessage="Video" id="media.type.video" />;
    default:
      return "";
  }
};

export const is4KAvailable = (media: Partial<File>): boolean => {
  const getFileDetail = getFileOutputs4kVideoDetail(media);
  const details = getFileDetail?.metadata.videoDetails;
  return details && Math.max(details.widthInPx, details.heightInPx) > 1920;
};

export function isFolder(media: { __typename: string }) {
  return media.__typename === "Folder";
}

export function isFile(media: { __typename: string }) {
  return media.__typename === "File";
}

export const getDeleteMediaConfirmMsg = async (media: File) => {
  const file = await queryHelper.getFileAssociation(media.id);
  let message = (
    <>
      <h2>
        <FormattedMessage
          id="ui_component.confirm.ui_component.label.move_to_trash_question"
          defaultMessage="Move to Trash?"
          values={{ name: media.name }}
        />
      </h2>
      <p>
        <FormattedMessage
          id="ui_component.confirm.move_to_trash_message_with_cast"
          defaultMessage="{name} will be deleted and moved to the Trash."
          values={{
            name: (
              <strong
                className="delete-text"
                style={{ overflowWrap: "break-word" }}
              >
                {media.name}
              </strong>
            ),
          }}
        />
      </p>
    </>
  );
  if (
    (file?.data.fileById?.associationsByToFileAndOrgId.totalCount ?? 0 > 0) &&
    media.__typename === "File"
  ) {
    const places = getTheUsedPlace(
      file?.data.fileById?.associationsByToFileAndOrgId.nodes
    );
    message = (
      <DeleteWarning name={media.name} places={places} isSoftDelete={true} />
    );
  }
  return message;
};

export const getContentThumbnail = (
  contentType: string,
  contentData: any,
  secureMediaPolicy: string | undefined
) => {
  switch (contentType) {
    case "app":
      return (
        <div
          className="thumbnail app-thumbnail"
          style={{ backgroundImage: `url(${contentData.thumbnail})` }}
        />
      );
    case "link":
    case "file":
      return (
        <Thumbnail
          media={contentData.thumbnail}
          height={100}
          width={100}
          secureMediaPolicy={secureMediaPolicy}
        />
      );
    case "playlist":
      return (
        <div
          className="thumbnail playlist-thumbnail"
          style={{ backgroundColor: contentData.thumbnail }}
        >
          <Icon name="playlist" />
        </div>
      );
    case "channel":
      const channelData = contentData.thumbnail;
      const result =
        channelData?.coverImageId && channelData?.fileByCoverImageId
          ? generateImgixURL(
              channelData?.fileByCoverImageId,
              600,
              600,
              secureMediaPolicy
            )
          : `linear-gradient(-45deg, ${channelData?.coverData.color.top} 0%, ${channelData?.coverData.color.bottom} 100%)`;
      const coverStyle = result.includes("http")
        ? { backgroundImage: `url(${result})` }
        : { backgroundImage: result };
      return <div className="thumbnail channel-thumbnail" style={coverStyle} />;
    default:
      return;
  }
};

export type MediaInput = Pick<
  File,
  "metadata" | "fileProcessingStatus" | "mimetype" | "source"
> & {
  fileOutputsByFileId: {
    nodes: Pick<FileOutput, "content" | "mimetype">[];
  };
};

export const replaceWithSecureURL = (
  url: string,
  height?: number | undefined,
  width?: number | undefined
) => {
  // list of valid screen cloud media origins
  const validOrigins = [
    `${appConfig.imgixBaseUrl}`,
    `${appConfig.mediaOrigin}`,
  ];

  const reg = new RegExp("^https?://[^#?/]+");

  if (validOrigins.filter((domain) => url.includes(domain)).length > 0) {
    return url.replace(reg, appConfig.secureMediaUrl);
  }

  // add height and width in place of original
  height && width && url.replace("originals", `${height}x${width}_cover}`);

  return url;
};

/**
 * returns resized media url
 */

export const getResizedMedia = (
  media: string,
  height: number,
  width: number,
  fit = "cover"
) => {
  return media.replace("originals", `${width}x${height}_${fit}`);
};
