import { ApolloQueryResult, ObservableSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { Plan } from "src/billing/types";
import { isCorePlan, isProPlan } from "src/helpers/planHelper";
import { useAppContext } from "src/hooks/useAppContext";
import client from "src/state/apolloClient";
import { GetPlanDocument, GetPlanQuery } from "src/types.g";
import { provider } from "src/billinglatest/util/provider";

interface BillingPlan {
  isCorePlan?: boolean;
  isProPlan?: boolean;
}

export interface WithBillingPlan {
  billingPlan?: BillingPlan;
}

const withCachedBillingPlan = <T extends {}>(
  Component: React.ComponentType<T & WithBillingPlan>
) => (props: T) => {
  const { currentSpace } = useAppContext();
  const [billingPlan, setBillingPlan] = useState<BillingPlan>();
  useEffect(() => {
    if (provider.isNotChargebee()) {
      return;
    }
    const result = client.readQuery<ApolloQueryResult<GetPlanQuery>>({
      query: GetPlanDocument,
      variables: {
        spaceId: currentSpace?.id,
      },
    });
    if (result && result.data) {
      setBillingPlan({
        isCorePlan: isCorePlan(result.data.getBillingPlan as Plan),
        isProPlan: isProPlan(result.data.getBillingPlan as Plan),
      });
    }
  }, []);

  useEffect(() => {
    if (provider.isNotChargebee()) {
      return;
    }
    const billingPlanSubscription: ObservableSubscription = client
      .watchQuery({
        query: GetPlanDocument,
        variables: {
          spaceId: currentSpace?.id,
        },
        fetchPolicy: "cache-only",
      })
      .subscribe({
        next: ({ data }: ApolloQueryResult<GetPlanQuery>) => {
          setBillingPlan({
            isCorePlan: isCorePlan(data.getBillingPlan as Plan),
          });
        },
      });
    return () => {
      billingPlanSubscription.unsubscribe();
    };
  }, []);

  return <Component {...props} billingPlan={billingPlan} />;
};

export default withCachedBillingPlan;
