import * as types from "../types.g";

//
// Global
//
export interface Dictionary<P = any> {
  [key: string]: P;
}
export enum LoadingType {
  Blocking = "blocking",
  Minimal = "minimal",
}
export enum BillingPath {
  Account = "account",
  Manage = "manage",
  Relationship = "relationship",
}

//
// Chargebee
//
export enum OnOff {
  On = "on",
  Off = "off",
}
export enum SubscriptionStatus {
  Future = "future",
  InTrial = "in_trial",
  Active = "active",
  NonRenewing = "non_renewing",
  Paused = "paused",
  Cancelled = "cancelled",
}
export enum CfSubscriptionPrimary {
  Signage = "signage",
  Studio = "studio",
}
export enum CfSubscriptionType {
  NoScreens = "no-screens",
  AnnuallyDiscounted = "annually-discounted",
  AnnuallyInvoiced = "annually-invoiced",
  Deprecated = "deprecated",
}
export enum CfPartner {
  Distributor = "distributor",
  ValueAddedReseller = "value_added_reseller",
  Customer = "customer",
}
export enum PeriodUnit {
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}
export enum PlanId {
  noScreensUsd = "no-screens-usd",
  noScreensGbp = "no-screens-gbp",
  noScreensEur = "no-screens-eur",
  monthlySubscriptionUsd = "monthly-subscription-usd",
  monthlySubscriptionGbp = "monthly-subscription-gbp",
  monthlySubscriptionEur = "monthly-subscription-eur",
  annualSubscriptionDiscountedUsd = "annual-subscription-discounted-usd",
  annualSubscriptionDiscountedGbp = "annual-subscription-discounted-gbp",
  annualSubscriptionDiscountedEur = "annual-subscription-discounted-eur",
  annualInvoicedSubscriptionUsd = "annual-invoiced-subscription-usd",
  annualInvoicedSubscriptionGbp = "annual-invoiced-subscription-gbp",
  annualInvoicedSubscriptionEur = "annual-invoiced-subscription-eur",
}
export enum CouponStatus {
  Active = "active",
  Expired = "expired",
  Archived = "archived",
  Deleted = "deleted",
}
export enum CouponDiscountType {
  FixedAmount = "fixed_amount",
  Percentage = "percentage",
}
export enum CouponDurationType {
  OneTime = "one_time",
  Forever = "forever",
  LimitedPeriod = "limited_period",
}
export enum PaymentMethodType {
  Card = "card",
  PaypalExpressCheckout = "paypal_express_checkout",
  DirectDebit = "direct_debit",
  Invoice = "invoice",
  AddPaymentMethodLater = "add_payment_method_later",
}
export enum PaymentStatus {
  Valid = "valid",
  Expiring = "expiring",
  Expired = "expired",
  Invalid = "invalid",
  PendingVerification = "pending_verification",
}
export enum CardType {
  Visa = "visa",
  Mastercard = "mastercard",
  AmericanExpress = "american_express",
  Discover = "discover",
  Jcb = "jcb",
  DinersClub = "diners_club",
  Other = "other",
}
export enum BankAccountType {
  Checking = "checking",
  Savings = "savings",
  BusinessChecking = "business_checking",
}
export enum ECheckType {
  Web = "web",
  Ppd = "ppd",
  Ccd = "ccd",
}
export enum AccountHolderType {
  Individual = "individual",
  Company = "company",
}
export interface ICard {
  first_name?: string;
  last_name?: string;
  iin: string;
  last4: string;
  brand: CardType;
  funding_type: string;
  expiry_month: number;
  expiry_year: number;
  billing_addr1?: string;
  billing_addr2?: string;
  billing_city?: string;
  billing_state_code?: string;
  billing_state?: string;
  billing_country?: types.CountryCode;
  billing_zip?: string;
  masked_number?: string;
}
export interface IPayPal {
  email?: string;
  agreement_id?: string;
}
export interface IBankAccount {
  last4: string;
  name_on_account?: string;
  bank_name?: string;
  mandate_id?: string;
  account_type?: BankAccountType;
  echeck_type?: ECheckType;
  account_holder_type?: AccountHolderType;
}

//
// API
//
export enum RelationshipRecipient {
  Parent = "parent",
  Child = "child",
}

//
// Address
//
export interface Address extends Required<types.AddressFragment> {
  line1: string;
  country: types.CountryCode;
  zip: string;
}

//
// Customer
//
export interface Customer extends Required<types.CustomerFragment> {
  autoCollection: OnOff;
  company: string;
  email: string;
  cfPartner: types.Maybe<CfPartner>;
  billingResellerByBillingResellerId: types.Maybe<
    Pick<types.BillingReseller, "id" | "company">
  >;
}

//
// Coupon
//
export interface Coupon extends Required<types.CouponFragment> {
  discountType: CouponDiscountType;
  status: CouponStatus;
  durationType: CouponDurationType;
}
export type Coupons = Coupon[];

//
// Licences
//
export type Licences = types.GetScreenLicencesPayload;

//
// Payment Sources
//
export interface PaymentSource extends Required<types.PaymentSourceFragment> {
  type: PaymentMethodType;
  status: PaymentStatus;
  card: ICard;
  bankAccount: IBankAccount;
  paypal: IPayPal;
}
export type PaymentSources = PaymentSource[];

//
// Plan
//
export interface Plan extends Required<types.PlanFragment> {
  name: string;
  cfSubscriptionType: CfSubscriptionType;
  periodUnit: PeriodUnit;
  price: number;
  cfInvoicedPayment: boolean;
}
export type Plans = Plan[];

//
// Renewal
//
export interface Renewal extends Required<types.RenewalFragment> {
  total: number;
  subTotal: number;
  taxAmount: number;
}
export interface RenewalFuture extends Required<types.RenewalFutureFragment> {
  total: number;
}

//
// Subscription
//
export interface Subscription extends Required<types.SubscriptionFragment> {
  currencyCode: types.CurrencyCode;
  planId: PlanId;
  status: SubscriptionStatus;
  autoCollection: OnOff;
  cfPrimary: CfSubscriptionPrimary;
  planUnitPrice: number;
  billingAddonSubscriptionsByBillingSubscriptionId: types.BillingAddonSubscriptionsConnection;
}
export interface SubscriptionFuture
  extends Required<types.SubscriptionFutureFragment> {
  currencyCode: types.CurrencyCode;
  planId: PlanId;
  status: SubscriptionStatus;
  autoCollection: OnOff;
  cfPrimary: CfSubscriptionPrimary;
  planUnitPrice: number;
}

//
// Accounts (child accounts)
//
export type AccountSpace = Required<types.AccountSpaceFragment>;
