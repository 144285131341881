import * as React from "react";

export interface Props {
  children: React.ReactNode;
}

const FullScreenModalContentMain = (props: Props) => (
  <div className="panel-preview">{props.children}</div>
);

export default FullScreenModalContentMain;
