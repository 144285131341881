import { ApolloQueryResult, ObservableSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { BillingSubscription } from "src/domains/billing/types";
import { useAppContext } from "src/hooks/useAppContext";
import client from "src/state/apolloClient";
import {
  GetBillingSubscriptionDocument,
  GetBillingSubscriptionQuery,
} from "src/types.g";
import { provider } from "src/billinglatest/util/provider";

export interface WithBillingSubscription {
  billingSubscription: BillingSubscription;
}

const withCachedBillingSubscription = <T extends {}>(
  Component: React.ComponentType<T & WithBillingSubscription>
) => (props: T) => {
  const { currentSpace } = useAppContext();
  const [
    billingSubscription,
    setBillingSubscription,
  ] = useState<BillingSubscription>({
    status: null,
    trialEnd: null,
  });

  useEffect(() => {
    if (provider.isNotChargebee()) {
      return;
    }
    const billingSubscriptionSub: ObservableSubscription = client
      .watchQuery({
        query: GetBillingSubscriptionDocument,
        variables: {
          spaceId: currentSpace?.id,
        },
        fetchPolicy: "cache-only",
      })
      .subscribe({
        next: ({ data }: ApolloQueryResult<GetBillingSubscriptionQuery>) => {
          setBillingSubscription({
            status: data.getBillingSubscription?.status || null,
            trialEnd: data.getBillingSubscription?.trialEnd || null,
          });
        },
      });
    return () => {
      billingSubscriptionSub.unsubscribe();
    };
  }, []);

  return <Component {...props} billingSubscription={billingSubscription} />;
};

export default withCachedBillingSubscription;
