import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex: 5;
    height: calc(100% - 65px);
  }

  .themes-container {
    width: 100%;
    overflow-y: auto;
  }

  .theme-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    padding-right: 8px;
    border-bottom: 1px solid ${Theme.color.borderSection};
    background-color: transparent;
    &:hover {
      background-color: ${Theme.color.nearWhite};
      cursor: pointer;
    }
  }

  .theme-container-selected {
    background-color: ${Theme.color.nearWhite};
  }

  .theme-option-left {
    display: flex;
  }

  .theme-option-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;

    .h3-text {
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .theme-options {
    display: flex;
    align-items: center;
    justify-content: center;

    .theme-dropdown {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        background: ${Theme.color.silver};

        i {
          background-color: ${Theme.color.base};
        }
      }

      &:hover {
        background: ${Theme.color.silver};

        i {
          background-color: ${Theme.color.base};
        }
      }
    }
  }

  .theme-option-pane {
    display: flex;
    flex: 2;
  }

  .theme-preview-pane {
    display: flex;
    flex: 3;
    justify-content: center;
    align-items: center;
    border-left: 1px solid ${Theme.color.borderSection};
    background-color: ${Theme.color.nearWhite};

    .theme-preview-wrapper {
      width: 90%;
      display: flex;
      flex-direction: column;
    }

    .theme-preview-text {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      justify-content: center;
      height: 20px;
      align-items: center;

      .h3-text {
        margin: 0;
        margin-right: 10px;
      }
    }
  }

  .theme-option-default {
    margin-top: 5px;
    width: fit-content;
  }

  @media (max-width: ${Theme.break.small}) {
    .theme-preview-pane {
      display: none;
    }
  }
`;
