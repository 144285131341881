import { Space } from "src/types.g";
import { AppContextState } from "../AppContextProvider";

export const isSpaceBasedBillingEnable = (
  allSpaces: Pick<Space, "billingCustomerId">[]
) => {
  // if any space billing customer id is not null mean it has space based billing
  return allSpaces.some((space) => Boolean(space.billingCustomerId));
};

export const isSpaceBasedBillingEnabledByContext = (
  context: Pick<AppContextState, "allSpaces">
) => {
  return isSpaceBasedBillingEnable(context.allSpaces);
};

export const getSpaceNameById = (
  context: Pick<AppContextState, "allSpaces" | "intl">,
  spaceId?: string | undefined
) => {
  const spaceById = (context.allSpaces ?? []).find(
    (space) => space!.id === spaceId
  );
  return spaceById?.name === "_default_space"
    ? context.intl.formatMessage({ id: "defaults._default_space" })
    : spaceById?.name || "";
};
