import { RefType } from "src/constants/constants";
import { isCanvasInstance } from "src/helpers/appHelper";
import { isMediaWithAudio } from "src/helpers/mediaHelper";
import { AvailableAppInstanceFragment, File } from "src/types.g";

const PRIMARY_AUDIO_CONTENT_SUPPORT = {
  [RefType.APP]: true,
  [RefType.APP_INSTANCE]: true,
  [RefType.PLAYLIST]: true,
};

interface PrimaryAudio {
  isPrimaryAudioFeatureFlag: boolean;
  contentType: string;
  fileById?: File;
  appInstanceById?: AvailableAppInstanceFragment;
}

export const shouldDisplayPrimaryAudio = ({
  isPrimaryAudioFeatureFlag,
  contentType,
  fileById,
  appInstanceById,
}: PrimaryAudio) => {
  const isCanvas = appInstanceById ? isCanvasInstance(appInstanceById) : false;
  return (
    !isCanvas &&
    (PRIMARY_AUDIO_CONTENT_SUPPORT[contentType] ||
      isMediaWithAudio(fileById)) &&
    isPrimaryAudioFeatureFlag
  );
};
