import { Dropdown, Loader } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { StyleItem } from "../styles";
import { SharingMode, getChannelShareOptions } from "../helper";

export interface ShareItemProps {
  id: string;
  name: string;
  isOwner: boolean;
  isDisabled?: boolean;
  value?: string;
  onItemChange?: (spaceId: string, shared: string) => void;
  isLoading: boolean;
}

const ChannelShareItem = (props: ShareItemProps) => {
  const [dropdownValue, setDropdownValue] = React.useState(props.value);

  const handleChange = async (event: React.SyntheticEvent<any>, data: any) => {
    const res = await props.onItemChange?.(props.id, data.value);
    res && setDropdownValue(data.value);
  };
  React.useEffect(() => {
    setDropdownValue(props.value);
  }, [props.value]);

  return (
    <StyleItem className="share-item">
      <div className="space-name">
        <h3>{props.name}</h3>
      </div>
      <div className="options">
        {props.isOwner ? (
          <p data-testid="owner-space-text">
            <FormattedMessage id="common.text.owner" defaultMessage="Owner" />
          </p>
        ) : props.isLoading ? (
          <Loader inline active size="small" />
        ) : (
          <Dropdown
            data-testid={`channel-share-item-${props.name}`}
            options={getChannelShareOptions()}
            value={dropdownValue || SharingMode.Disabled}
            onChange={handleChange}
            disabled={props.isDisabled}
            selection
          />
        )}
      </div>
    </StyleItem>
  );
};

export { ChannelShareItem };
