import * as React from "react";
import { imagePlaceholder } from "../../helpers/mediaHelper";
export interface ImageLoaderProps {
  src: string;
  title?: string;
}

export interface ImageLoaderState {}

class ImageLoader extends React.Component<ImageLoaderProps, ImageLoaderState> {
  private imgRef: React.RefObject<HTMLImageElement>;
  constructor(props: ImageLoaderProps) {
    super(props);

    this.imgRef = React.createRef();
  }

  public componentDidMount() {
    const previewImg = new Image();
    previewImg.src = this.props.src;

    previewImg.onload = () => {
      if (this.imgRef.current) {
        this.imgRef.current.src = this.props.src;
      }
    };

    previewImg.onerror = () => {
      if (this.imgRef.current) {
        this.imgRef.current.src = imagePlaceholder;
      }
    };
  }

  public render() {
    return (
      <img ref={this.imgRef} src={this.props.src} title={this.props.title} />
    );
  }
}

export default ImageLoader;
