export const getMediaPickerSkipProps = ({
  isOrgWide,
}: {
  isOrgWide: boolean;
}) => {
  return {
    skipSpaceWideQuery: isOrgWide,
    skipOrgWideQuery: !isOrgWide,
  };
};

// TODO vite migration - remove any
interface GetMediaPickerProps {
  spaceWideMediaPicker: any;
  orgWideMediaPicker: any;
  isOrgWide: boolean;
}

export function getMediaPicker(props: GetMediaPickerProps): JSX.Element {
  const { isOrgWide, orgWideMediaPicker, spaceWideMediaPicker } = props;
  return isOrgWide ? orgWideMediaPicker : spaceWideMediaPicker;
}
