import { find, pull, remove } from "lodash";

export const updateAndToggleSelectedItems = (
  id,
  item: any,
  selectedIds: string[],
  selectedItems: any[],
  isMultiple?: boolean
) => {
  let selectedArray = selectedIds.slice();
  let selectedData = selectedItems.slice();
  if (isMultiple && isMultiple === true) {
    if (
      find(selectedArray, (selectedItemId) => {
        return selectedItemId === id;
      })
    ) {
      selectedArray = pull(selectedArray, id);
      remove(selectedData, { id });
    } else {
      selectedArray.push(id);
      selectedData.push(item);
    }
  } else {
    if (
      find(selectedArray, (selectedItemId) => {
        return selectedItemId === id;
      })
    ) {
      selectedArray = pull(selectedArray, id);
      remove(selectedData, { id });
    } else {
      selectedArray = [id];
      selectedData = [item];
    }
  }
  return {
    selectedArray,
    selectedData,
  };
};
