export interface ITimeZoneProps {
  description?: string;
  key: string;
  text: string;
  value: string;
}

export const timeZoneList = [
  {
    key: "Pacific/Niue",
    text: "Niue Time",
    value: "Pacific/Niue",
  },
  {
    key: "Pacific/Pago_Pago",
    text: "Samoa Standard Time",
    value: "Pacific/Pago_Pago",
  },
  {
    key: "Pacific/Rarotonga",
    text: "Cook Islands Standard Time",
    value: "Pacific/Rarotonga",
  },
  {
    key: "Pacific/Honolulu",
    text: "Hawaii-Aleutian Standard Time",
    value: "Pacific/Honolulu",
  },
  {
    key: "America/Adak",
    text: "Hawaii-Aleutian Time",
    value: "America/Adak",
  },
  {
    key: "Pacific/Tahiti",
    text: "Tahiti Time",
    value: "Pacific/Tahiti",
  },
  {
    key: "Pacific/Marquesas",
    text: "Marquesas Time",
    value: "Pacific/Marquesas",
  },
  {
    key: "America/Anchorage",
    text: "Alaska Time - Anchorage",
    value: "America/Anchorage",
  },
  {
    key: "America/Juneau",
    text: "Alaska Time - Juneau",
    value: "America/Juneau",
  },
  {
    key: "America/Nome",
    text: "Alaska Time - Nome",
    value: "America/Nome",
  },
  {
    key: "America/Sitka",
    text: "Alaska Time - Sitka",
    value: "America/Sitka",
  },
  {
    key: "America/Yakutat",
    text: "Alaska Time - Yakutat",
    value: "America/Yakutat",
  },
  {
    key: "Pacific/Gambier",
    text: "Gambier Time",
    value: "Pacific/Gambier",
  },
  {
    key: "America/Dawson",
    text: "Pacific Time - Dawson",
    value: "America/Dawson",
  },
  {
    key: "America/Los_Angeles",
    text: "Pacific Time - Los Angeles",
    value: "America/Los_Angeles",
  },
  {
    key: "America/Metlakatla",
    text: "Pacific Time - Metlakatla",
    value: "America/Metlakatla",
  },
  {
    key: "America/Tijuana",
    text: "Pacific Time - Tijuana",
    value: "America/Tijuana",
  },
  {
    key: "America/Vancouver",
    text: "Pacific Time - Vancouver",
    value: "America/Vancouver",
  },
  {
    key: "America/Whitehorse",
    text: "Pacific Time - Whitehorse",
    value: "America/Whitehorse",
  },
  {
    key: "Pacific/Pitcairn",
    text: "Pitcairn Time",
    value: "Pacific/Pitcairn",
  },
  {
    key: "America/Hermosillo",
    text: "Mexican Pacific Standard Time",
    value: "America/Hermosillo",
  },
  {
    key: "America/Chihuahua",
    text: "Mexican Pacific Time - Chihuahua",
    value: "America/Chihuahua",
  },
  {
    key: "America/Mazatlan",
    text: "Mexican Pacific Time - Mazatlan",
    value: "America/Mazatlan",
  },
  {
    key: "America/Creston",
    text: "Mountain Standard Time - Creston",
    value: "America/Creston",
  },
  {
    key: "America/Dawson_Creek",
    text: "Mountain Standard Time - Dawson Creek",
    value: "America/Dawson_Creek",
  },
  {
    key: "America/Fort_Nelson",
    text: "Mountain Standard Time - Fort Nelson",
    value: "America/Fort_Nelson",
  },
  {
    key: "America/Phoenix",
    text: "Mountain Standard Time - Phoenix",
    value: "America/Phoenix",
  },
  {
    key: "America/Boise",
    text: "Mountain Time - Boise",
    value: "America/Boise",
  },
  {
    key: "America/Cambridge_Bay",
    text: "Mountain Time - Cambridge Bay",
    value: "America/Cambridge_Bay",
  },
  {
    key: "America/Denver",
    text: "Mountain Time - Denver",
    value: "America/Denver",
  },
  {
    key: "America/Edmonton",
    text: "Mountain Time - Edmonton",
    value: "America/Edmonton",
  },
  {
    key: "America/Inuvik",
    text: "Mountain Time - Inuvik",
    value: "America/Inuvik",
  },
  {
    key: "America/Ojinaga",
    text: "Mountain Time - Ojinaga",
    value: "America/Ojinaga",
  },
  {
    key: "America/Yellowknife",
    text: "Mountain Time - Yellowknife",
    value: "America/Yellowknife",
  },
  {
    key: "America/Belize",
    text: "Central Standard Time - Belize",
    value: "America/Belize",
  },
  {
    key: "America/Costa_Rica",
    text: "Central Standard Time - Costa Rica",
    value: "America/Costa_Rica",
  },
  {
    key: "America/El_Salvador",
    text: "Central Standard Time - El Salvador",
    value: "America/El_Salvador",
  },
  {
    key: "America/Guatemala",
    text: "Central Standard Time - Guatemala",
    value: "America/Guatemala",
  },
  {
    key: "America/Managua",
    text: "Central Standard Time - Managua",
    value: "America/Managua",
  },
  {
    key: "America/Regina",
    text: "Central Standard Time - Regina",
    value: "America/Regina",
  },
  {
    key: "America/Swift_Current",
    text: "Central Standard Time - Swift Current",
    value: "America/Swift_Current",
  },
  {
    key: "America/Tegucigalpa",
    text: "Central Standard Time - Tegucigalpa",
    value: "America/Tegucigalpa",
  },
  {
    key: "America/Bahia_Banderas",
    text: "Central Time - Bahia Banderas",
    value: "America/Bahia_Banderas",
  },
  {
    key: "America/North_Dakota/Beulah",
    text: "Central Time - Beulah, North Dakota",
    value: "America/North_Dakota/Beulah",
  },
  {
    key: "America/North_Dakota/Center",
    text: "Central Time - Center, North Dakota",
    value: "America/North_Dakota/Center",
  },
  {
    key: "America/Chicago",
    text: "Central Time - Chicago",
    value: "America/Chicago",
  },
  {
    key: "America/Indiana/Knox",
    text: "Central Time - Knox, Indiana",
    value: "America/Indiana/Knox",
  },
  {
    key: "America/Matamoros",
    text: "Central Time - Matamoros",
    value: "America/Matamoros",
  },
  {
    key: "America/Menominee",
    text: "Central Time - Menominee",
    value: "America/Menominee",
  },
  {
    key: "America/Merida",
    text: "Central Time - Merida",
    value: "America/Merida",
  },
  {
    key: "America/Mexico_City",
    text: "Central Time - Mexico City",
    value: "America/Mexico_City",
  },
  {
    key: "America/Monterrey",
    text: "Central Time - Monterrey",
    value: "America/Monterrey",
  },
  {
    key: "America/North_Dakota/New_Salem",
    text: "Central Time - New Salem, North Dakota",
    value: "America/North_Dakota/New_Salem",
  },
  {
    key: "America/Rainy_River",
    text: "Central Time - Rainy River",
    value: "America/Rainy_River",
  },
  {
    key: "America/Rankin_Inlet",
    text: "Central Time - Rankin Inlet",
    value: "America/Rankin_Inlet",
  },
  {
    key: "America/Resolute",
    text: "Central Time - Resolute",
    value: "America/Resolute",
  },
  {
    key: "America/Indiana/Tell_City",
    text: "Central Time - Tell City, Indiana",
    value: "America/Indiana/Tell_City",
  },
  {
    key: "America/Winnipeg",
    text: "Central Time - Winnipeg",
    value: "America/Winnipeg",
  },
  {
    key: "Pacific/Galapagos",
    text: "Galapagos Time",
    value: "Pacific/Galapagos",
  },
  {
    key: "America/Eirunepe",
    text: "Acre Standard Time - Eirunepe",
    value: "America/Eirunepe",
  },
  {
    key: "America/Rio_Branco",
    text: "Acre Standard Time - Rio Branco",
    value: "America/Rio_Branco",
  },
  {
    key: "America/Bogota",
    text: "Colombia Standard Time",
    value: "America/Bogota",
  },
  {
    key: "America/Havana",
    text: "Cuba Time",
    value: "America/Havana",
  },
  {
    key: "Pacific/Easter",
    text: "Easter Island Time",
    value: "Pacific/Easter",
  },
  {
    key: "America/Atikokan",
    text: "Eastern Standard Time - Atikokan",
    value: "America/Atikokan",
  },
  {
    key: "America/Cancun",
    text: "Eastern Standard Time - Cancun",
    value: "America/Cancun",
  },
  {
    key: "America/Jamaica",
    text: "Eastern Standard Time - Jamaica",
    value: "America/Jamaica",
  },
  {
    key: "America/Panama",
    text: "Eastern Standard Time - Panama",
    value: "America/Panama",
  },
  {
    key: "America/Detroit",
    text: "Eastern Time - Detroit",
    value: "America/Detroit",
  },
  {
    key: "America/Grand_Turk",
    text: "Eastern Time - Grand Turk",
    value: "America/Grand_Turk",
  },
  {
    key: "America/Indiana/Indianapolis",
    text: "Eastern Time - Indianapolis",
    value: "America/Indiana/Indianapolis",
  },
  {
    key: "America/Iqaluit",
    text: "Eastern Time - Iqaluit",
    value: "America/Iqaluit",
  },
  {
    key: "America/Kentucky/Louisville",
    text: "Eastern Time - Louisville",
    value: "America/Kentucky/Louisville",
  },
  {
    key: "America/Indiana/Marengo",
    text: "Eastern Time - Marengo, Indiana",
    value: "America/Indiana/Marengo",
  },
  {
    key: "America/Kentucky/Monticello",
    text: "Eastern Time - Monticello, Kentucky",
    value: "America/Kentucky/Monticello",
  },
  {
    key: "America/Nassau",
    text: "Eastern Time - Nassau",
    value: "America/Nassau",
  },
  {
    key: "America/New_York",
    text: "Eastern Time - New York",
    value: "America/New_York",
  },
  {
    key: "America/Nipigon",
    text: "Eastern Time - Nipigon",
    value: "America/Nipigon",
  },
  {
    key: "America/Pangnirtung",
    text: "Eastern Time - Pangnirtung",
    value: "America/Pangnirtung",
  },
  {
    key: "America/Indiana/Petersburg",
    text: "Eastern Time - Petersburg, Indiana",
    value: "America/Indiana/Petersburg",
  },
  {
    key: "America/Port-au-Prince",
    text: "Eastern Time - Port-au-Prince",
    value: "America/Port-au-Prince",
  },
  {
    key: "America/Thunder_Bay",
    text: "Eastern Time - Thunder Bay",
    value: "America/Thunder_Bay",
  },
  {
    key: "America/Toronto",
    text: "Eastern Time - Toronto",
    value: "America/Toronto",
  },
  {
    key: "America/Indiana/Vevay",
    text: "Eastern Time - Vevay, Indiana",
    value: "America/Indiana/Vevay",
  },
  {
    key: "America/Indiana/Vincennes",
    text: "Eastern Time - Vincennes, Indiana",
    value: "America/Indiana/Vincennes",
  },
  {
    key: "America/Indiana/Winamac",
    text: "Eastern Time - Winamac, Indiana",
    value: "America/Indiana/Winamac",
  },
  {
    key: "America/Guayaquil",
    text: "Ecuador Time",
    value: "America/Guayaquil",
  },
  {
    key: "America/Lima",
    text: "Peru Standard Time",
    value: "America/Lima",
  },
  {
    key: "America/Boa_Vista",
    text: "Amazon Standard Time - Boa Vista",
    value: "America/Boa_Vista",
  },
  {
    key: "America/Manaus",
    text: "Amazon Standard Time - Manaus",
    value: "America/Manaus",
  },
  {
    key: "America/Porto_Velho",
    text: "Amazon Standard Time - Porto Velho",
    value: "America/Porto_Velho",
  },
  {
    key: "America/Barbados",
    text: "Atlantic Standard Time - Barbados",
    value: "America/Barbados",
  },
  {
    key: "America/Blanc-Sablon",
    text: "Atlantic Standard Time - Blanc-Sablon",
    value: "America/Blanc-Sablon",
  },
  {
    key: "America/Curacao",
    text: "Atlantic Standard Time - Curaçao",
    value: "America/Curacao",
  },
  {
    key: "America/Martinique",
    text: "Atlantic Standard Time - Martinique",
    value: "America/Martinique",
  },
  {
    key: "America/Port_of_Spain",
    text: "Atlantic Standard Time - Port of Spain",
    value: "America/Port_of_Spain",
  },
  {
    key: "America/Puerto_Rico",
    text: "Atlantic Standard Time - Puerto Rico",
    value: "America/Puerto_Rico",
  },
  {
    key: "America/Santo_Domingo",
    text: "Atlantic Standard Time - Santo Domingo",
    value: "America/Santo_Domingo",
  },
  {
    key: "Atlantic/Bermuda",
    text: "Atlantic Time - Bermuda",
    value: "Atlantic/Bermuda",
  },
  {
    key: "America/Glace_Bay",
    text: "Atlantic Time - Glace Bay",
    value: "America/Glace_Bay",
  },
  {
    key: "America/Goose_Bay",
    text: "Atlantic Time - Goose Bay",
    value: "America/Goose_Bay",
  },
  {
    key: "America/Halifax",
    text: "Atlantic Time - Halifax",
    value: "America/Halifax",
  },
  {
    key: "America/Moncton",
    text: "Atlantic Time - Moncton",
    value: "America/Moncton",
  },
  {
    key: "America/Thule",
    text: "Atlantic Time - Thule",
    value: "America/Thule",
  },
  {
    key: "America/La_Paz",
    text: "Bolivia Time",
    value: "America/La_Paz",
  },
  {
    key: "America/Guyana",
    text: "Guyana Time",
    value: "America/Guyana",
  },
  {
    key: "America/Caracas",
    text: "Venezuela Time",
    value: "America/Caracas",
  },
  {
    key: "America/St_Johns",
    text: "Newfoundland Time",
    value: "America/St_Johns",
  },
  {
    key: "America/Campo_Grande",
    text: "Amazon Time (Campo Grande)",
    value: "America/Campo_Grande",
  },
  {
    key: "America/Cuiaba",
    text: "Amazon Time (Cuiaba)",
    value: "America/Cuiaba",
  },
  {
    key: "America/Argentina/Buenos_Aires",
    text: "Argentina Standard Time - Buenos Aires",
    value: "America/Argentina/Buenos_Aires",
  },
  {
    key: "America/Argentina/Catamarca",
    text: "Argentina Standard Time - Catamarca",
    value: "America/Argentina/Catamarca",
  },
  {
    key: "America/Argentina/Cordoba",
    text: "Argentina Standard Time - Cordoba",
    value: "America/Argentina/Cordoba",
  },
  {
    key: "America/Argentina/Jujuy",
    text: "Argentina Standard Time - Jujuy",
    value: "America/Argentina/Jujuy",
  },
  {
    key: "America/Argentina/La_Rioja",
    text: "Argentina Standard Time - La Rioja",
    value: "America/Argentina/La_Rioja",
  },
  {
    key: "America/Argentina/Mendoza",
    text: "Argentina Standard Time - Mendoza",
    value: "America/Argentina/Mendoza",
  },
  {
    key: "America/Argentina/Rio_Gallegos",
    text: "Argentina Standard Time - Rio Gallegos",
    value: "America/Argentina/Rio_Gallegos",
  },
  {
    key: "America/Argentina/Salta",
    text: "Argentina Standard Time - Salta",
    value: "America/Argentina/Salta",
  },
  {
    key: "America/Argentina/San_Juan",
    text: "Argentina Standard Time - San Juan",
    value: "America/Argentina/San_Juan",
  },
  {
    key: "America/Argentina/Tucuman",
    text: "Argentina Standard Time - Tucuman",
    value: "America/Argentina/Tucuman",
  },
  {
    key: "America/Argentina/Ushuaia",
    text: "Argentina Standard Time - Ushuaia",
    value: "America/Argentina/Ushuaia",
  },
  {
    key: "America/Araguaina",
    text: "Brasilia Standard Time - Araguaina",
    value: "America/Araguaina",
  },
  {
    key: "America/Bahia",
    text: "Brasilia Standard Time - Bahia",
    value: "America/Bahia",
  },
  {
    key: "America/Belem",
    text: "Brasilia Standard Time - Belem",
    value: "America/Belem",
  },
  {
    key: "America/Fortaleza",
    text: "Brasilia Standard Time - Fortaleza",
    value: "America/Fortaleza",
  },
  {
    key: "America/Maceio",
    text: "Brasilia Standard Time - Maceio",
    value: "America/Maceio",
  },
  {
    key: "America/Recife",
    text: "Brasilia Standard Time - Recife",
    value: "America/Recife",
  },
  {
    key: "America/Santarem",
    text: "Brasilia Standard Time - Santarem",
    value: "America/Santarem",
  },
  {
    key: "America/Santiago",
    text: "Chile Time",
    value: "America/Santiago",
  },
  {
    key: "Atlantic/Stanley",
    text: "Falkland Islands Standard Time",
    value: "Atlantic/Stanley",
  },
  {
    key: "America/Cayenne",
    text: "French Guiana Time",
    value: "America/Cayenne",
  },
  {
    key: "Antarctica/Palmer",
    text: "Palmer Time",
    value: "Antarctica/Palmer",
  },
  {
    key: "America/Asuncion",
    text: "Paraguay Time",
    value: "America/Asuncion",
  },
  {
    key: "America/Punta_Arenas",
    text: "Punta Arenas Time",
    value: "America/Punta_Arenas",
  },
  {
    key: "Antarctica/Rothera",
    text: "Rothera Time",
    value: "Antarctica/Rothera",
  },
  {
    key: "America/Miquelon",
    text: "St. Pierre & Miquelon Time",
    value: "America/Miquelon",
  },
  {
    key: "America/Paramaribo",
    text: "Suriname Time",
    value: "America/Paramaribo",
  },
  {
    key: "America/Montevideo",
    text: "Uruguay Standard Time",
    value: "America/Montevideo",
  },
  {
    key: "America/Godthab",
    text: "West Greenland Time",
    value: "America/Godthab",
  },
  {
    key: "America/Argentina/San_Luis",
    text: "Western Argentina Standard Time",
    value: "America/Argentina/San_Luis",
  },
  {
    key: "America/Sao_Paulo",
    text: "Brasilia Time",
    value: "America/Sao_Paulo",
  },
  {
    key: "America/Noronha",
    text: "Fernando de Noronha Standard Time",
    value: "America/Noronha",
  },
  {
    key: "Atlantic/South_Georgia",
    text: "South Georgia Time",
    value: "Atlantic/South_Georgia",
  },
  {
    key: "Atlantic/Azores",
    text: "Azores Time",
    value: "Atlantic/Azores",
  },
  {
    key: "Atlantic/Cape_Verde",
    text: "Cape Verde Standard Time",
    value: "Atlantic/Cape_Verde",
  },
  {
    key: "America/Scoresbysund",
    text: "East Greenland Time",
    value: "America/Scoresbysund",
  },
  {
    key: "UTC",
    text: "Coordinated Universal Time",
    value: "UTC",
  },
  {
    key: "Etc/GMT",
    text: "Greenwich Mean Time",
    value: "Etc/GMT",
  },
  {
    key: "Africa/Abidjan",
    text: "Greenwich Mean Time - Abidjan",
    value: "Africa/Abidjan",
  },
  {
    key: "Africa/Accra",
    text: "Greenwich Mean Time - Accra",
    value: "Africa/Accra",
  },
  {
    key: "Africa/Bissau",
    text: "Greenwich Mean Time - Bissau",
    value: "Africa/Bissau",
  },
  {
    key: "America/Danmarkshavn",
    text: "Greenwich Mean Time - Danmarkshavn",
    value: "America/Danmarkshavn",
  },
  {
    key: "Africa/Monrovia",
    text: "Greenwich Mean Time - Monrovia",
    value: "Africa/Monrovia",
  },
  {
    key: "Atlantic/Reykjavik",
    text: "Greenwich Mean Time - Reykjavik",
    value: "Atlantic/Reykjavik",
  },
  {
    key: "Africa/Sao_Tome",
    text: "Greenwich Mean Time - São Tomé",
    value: "Africa/Sao_Tome",
  },
  {
    key: "Europe/Dublin",
    text: "Ireland Time",
    value: "Europe/Dublin",
  },
  {
    key: "Antarctica/Troll",
    text: "Troll Time",
    value: "Antarctica/Troll",
  },
  {
    key: "Europe/London",
    text: "United Kingdom Time",
    value: "Europe/London",
  },
  {
    key: "Atlantic/Canary",
    text: "Western European Time - Canary",
    value: "Atlantic/Canary",
  },
  {
    key: "Atlantic/Faroe",
    text: "Western European Time - Faroe",
    value: "Atlantic/Faroe",
  },
  {
    key: "Europe/Lisbon",
    text: "Western European Time - Lisbon",
    value: "Europe/Lisbon",
  },
  {
    key: "Atlantic/Madeira",
    text: "Western European Time - Madeira",
    value: "Atlantic/Madeira",
  },
  {
    key: "Africa/Algiers",
    text: "Central European Standard Time - Algiers",
    value: "Africa/Algiers",
  },
  {
    key: "Africa/Tunis",
    text: "Central European Standard Time - Tunis",
    value: "Africa/Tunis",
  },
  {
    key: "Europe/Amsterdam",
    text: "Central European Time - Amsterdam",
    value: "Europe/Amsterdam",
  },
  {
    key: "Europe/Andorra",
    text: "Central European Time - Andorra",
    value: "Europe/Andorra",
  },
  {
    key: "Europe/Belgrade",
    text: "Central European Time - Belgrade",
    value: "Europe/Belgrade",
  },
  {
    key: "Europe/Berlin",
    text: "Central European Time - Berlin",
    value: "Europe/Berlin",
  },
  {
    key: "Europe/Brussels",
    text: "Central European Time - Brussels",
    value: "Europe/Brussels",
  },
  {
    key: "Europe/Budapest",
    text: "Central European Time - Budapest",
    value: "Europe/Budapest",
  },
  {
    key: "Africa/Ceuta",
    text: "Central European Time - Ceuta",
    value: "Africa/Ceuta",
  },
  {
    key: "Europe/Copenhagen",
    text: "Central European Time - Copenhagen",
    value: "Europe/Copenhagen",
  },
  {
    key: "Europe/Gibraltar",
    text: "Central European Time - Gibraltar",
    value: "Europe/Gibraltar",
  },
  {
    key: "Europe/Luxembourg",
    text: "Central European Time - Luxembourg",
    value: "Europe/Luxembourg",
  },
  {
    key: "Europe/Madrid",
    text: "Central European Time - Madrid",
    value: "Europe/Madrid",
  },
  {
    key: "Europe/Malta",
    text: "Central European Time - Malta",
    value: "Europe/Malta",
  },
  {
    key: "Europe/Monaco",
    text: "Central European Time - Monaco",
    value: "Europe/Monaco",
  },
  {
    key: "Europe/Oslo",
    text: "Central European Time - Oslo",
    value: "Europe/Oslo",
  },
  {
    key: "Europe/Paris",
    text: "Central European Time - Paris",
    value: "Europe/Paris",
  },
  {
    key: "Europe/Prague",
    text: "Central European Time - Prague",
    value: "Europe/Prague",
  },
  {
    key: "Europe/Rome",
    text: "Central European Time - Rome",
    value: "Europe/Rome",
  },
  {
    key: "Europe/Stockholm",
    text: "Central European Time - Stockholm",
    value: "Europe/Stockholm",
  },
  {
    key: "Europe/Tirane",
    text: "Central European Time - Tirane",
    value: "Europe/Tirane",
  },
  {
    key: "Europe/Vienna",
    text: "Central European Time - Vienna",
    value: "Europe/Vienna",
  },
  {
    key: "Europe/Warsaw",
    text: "Central European Time - Warsaw",
    value: "Europe/Warsaw",
  },
  {
    key: "Europe/Zurich",
    text: "Central European Time - Zurich",
    value: "Europe/Zurich",
  },
  {
    key: "Africa/Casablanca",
    text: "Morocco Time",
    value: "Africa/Casablanca",
  },
  {
    key: "Africa/Lagos",
    text: "West Africa Standard Time - Lagos",
    value: "Africa/Lagos",
  },
  {
    key: "Africa/Ndjamena",
    text: "West Africa Standard Time - Ndjamena",
    value: "Africa/Ndjamena",
  },
  {
    key: "Africa/El_Aaiun",
    text: "Western Sahara Time",
    value: "Africa/El_Aaiun",
  },
  {
    key: "Africa/Khartoum",
    text: "Central Africa Time - Khartoum",
    value: "Africa/Khartoum",
  },
  {
    key: "Africa/Maputo",
    text: "Central Africa Time - Maputo",
    value: "Africa/Maputo",
  },
  {
    key: "Africa/Windhoek",
    text: "Central Africa Time - Windhoek",
    value: "Africa/Windhoek",
  },
  {
    key: "Africa/Cairo",
    text: "Eastern European Standard Time - Cairo",
    value: "Africa/Cairo",
  },
  {
    key: "Europe/Kaliningrad",
    text: "Eastern European Standard Time - Kaliningrad",
    value: "Europe/Kaliningrad",
  },
  {
    key: "Africa/Tripoli",
    text: "Eastern European Standard Time - Tripoli",
    value: "Africa/Tripoli",
  },
  {
    key: "Asia/Amman",
    text: "Eastern European Time - Amman",
    value: "Asia/Amman",
  },
  {
    key: "Europe/Athens",
    text: "Eastern European Time - Athens",
    value: "Europe/Athens",
  },
  {
    key: "Asia/Beirut",
    text: "Eastern European Time - Beirut",
    value: "Asia/Beirut",
  },
  {
    key: "Europe/Bucharest",
    text: "Eastern European Time - Bucharest",
    value: "Europe/Bucharest",
  },
  {
    key: "Europe/Chisinau",
    text: "Eastern European Time - Chisinau",
    value: "Europe/Chisinau",
  },
  {
    key: "Asia/Damascus",
    text: "Eastern European Time - Damascus",
    value: "Asia/Damascus",
  },
  {
    key: "Asia/Gaza",
    text: "Eastern European Time - Gaza",
    value: "Asia/Gaza",
  },
  {
    key: "Asia/Hebron",
    text: "Eastern European Time - Hebron",
    value: "Asia/Hebron",
  },
  {
    key: "Europe/Helsinki",
    text: "Eastern European Time - Helsinki",
    value: "Europe/Helsinki",
  },
  {
    key: "Europe/Kiev",
    text: "Eastern European Time - Kiev",
    value: "Europe/Kiev",
  },
  {
    key: "Asia/Nicosia",
    text: "Eastern European Time - Nicosia",
    value: "Asia/Nicosia",
  },
  {
    key: "Europe/Riga",
    text: "Eastern European Time - Riga",
    value: "Europe/Riga",
  },
  {
    key: "Europe/Sofia",
    text: "Eastern European Time - Sofia",
    value: "Europe/Sofia",
  },
  {
    key: "Europe/Tallinn",
    text: "Eastern European Time - Tallinn",
    value: "Europe/Tallinn",
  },
  {
    key: "Europe/Uzhgorod",
    text: "Eastern European Time - Uzhhorod",
    value: "Europe/Uzhgorod",
  },
  {
    key: "Europe/Vilnius",
    text: "Eastern European Time - Vilnius",
    value: "Europe/Vilnius",
  },
  {
    key: "Europe/Zaporozhye",
    text: "Eastern European Time - Zaporozhye",
    value: "Europe/Zaporozhye",
  },
  {
    key: "Asia/Famagusta",
    text: "Famagusta Time",
    value: "Asia/Famagusta",
  },
  {
    key: "Asia/Jerusalem",
    text: "Israel Time",
    value: "Asia/Jerusalem",
  },
  {
    key: "Africa/Johannesburg",
    text: "South Africa Standard Time",
    value: "Africa/Johannesburg",
  },
  {
    key: "Asia/Baghdad",
    text: "Arabian Standard Time - Baghdad",
    value: "Asia/Baghdad",
  },
  {
    key: "Asia/Qatar",
    text: "Arabian Standard Time - Qatar",
    value: "Asia/Qatar",
  },
  {
    key: "Asia/Riyadh",
    text: "Arabian Standard Time - Riyadh",
    value: "Asia/Riyadh",
  },
  {
    key: "Africa/Juba",
    text: "East Africa Time - Juba",
    value: "Africa/Juba",
  },
  {
    key: "Africa/Nairobi",
    text: "East Africa Time - Nairobi",
    value: "Africa/Nairobi",
  },
  {
    key: "Europe/Kirov",
    text: "Kirov Time",
    value: "Europe/Kirov",
  },
  {
    key: "Europe/Minsk",
    text: "Moscow Standard Time - Minsk",
    value: "Europe/Minsk",
  },
  {
    key: "Europe/Moscow",
    text: "Moscow Standard Time - Moscow",
    value: "Europe/Moscow",
  },
  {
    key: "Europe/Simferopol",
    text: "Moscow Standard Time - Simferopol",
    value: "Europe/Simferopol",
  },
  {
    key: "Antarctica/Syowa",
    text: "Syowa Time",
    value: "Antarctica/Syowa",
  },
  {
    key: "Europe/Istanbul",
    text: "Turkey Time",
    value: "Europe/Istanbul",
  },
  {
    key: "Asia/Tehran",
    text: "Iran Time",
    value: "Asia/Tehran",
  },
  {
    key: "Asia/Yerevan",
    text: "Armenia Standard Time",
    value: "Asia/Yerevan",
  },
  {
    key: "Europe/Astrakhan",
    text: "Astrakhan Time",
    value: "Europe/Astrakhan",
  },
  {
    key: "Asia/Baku",
    text: "Azerbaijan Standard Time",
    value: "Asia/Baku",
  },
  {
    key: "Asia/Tbilisi",
    text: "Georgia Standard Time",
    value: "Asia/Tbilisi",
  },
  {
    key: "Asia/Dubai",
    text: "Gulf Standard Time",
    value: "Asia/Dubai",
  },
  {
    key: "Indian/Mauritius",
    text: "Mauritius Standard Time",
    value: "Indian/Mauritius",
  },
  {
    key: "Indian/Reunion",
    text: "Réunion Time",
    value: "Indian/Reunion",
  },
  {
    key: "Europe/Samara",
    text: "Samara Standard Time",
    value: "Europe/Samara",
  },
  {
    key: "Europe/Saratov",
    text: "Saratov Time",
    value: "Europe/Saratov",
  },
  {
    key: "Indian/Mahe",
    text: "Seychelles Time",
    value: "Indian/Mahe",
  },
  {
    key: "Europe/Ulyanovsk",
    text: "Ulyanovsk Time",
    value: "Europe/Ulyanovsk",
  },
  {
    key: "Europe/Volgograd",
    text: "Volgograd Standard Time",
    value: "Europe/Volgograd",
  },
  {
    key: "Asia/Kabul",
    text: "Afghanistan Time",
    value: "Asia/Kabul",
  },
  {
    key: "Indian/Kerguelen",
    text: "French Southern & Antarctic Time",
    value: "Indian/Kerguelen",
  },
  {
    key: "Indian/Maldives",
    text: "Maldives Time",
    value: "Indian/Maldives",
  },
  {
    key: "Antarctica/Mawson",
    text: "Mawson Time",
    value: "Antarctica/Mawson",
  },
  {
    key: "Asia/Karachi",
    text: "Pakistan Standard Time",
    value: "Asia/Karachi",
  },
  {
    key: "Asia/Dushanbe",
    text: "Tajikistan Time",
    value: "Asia/Dushanbe",
  },
  {
    key: "Asia/Ashgabat",
    text: "Turkmenistan Standard Time",
    value: "Asia/Ashgabat",
  },
  {
    key: "Asia/Samarkand",
    text: "Uzbekistan Standard Time - Samarkand",
    value: "Asia/Samarkand",
  },
  {
    key: "Asia/Tashkent",
    text: "Uzbekistan Standard Time - Tashkent",
    value: "Asia/Tashkent",
  },
  {
    key: "Asia/Aqtau",
    text: "West Kazakhstan Time - Aqtau",
    value: "Asia/Aqtau",
  },
  {
    key: "Asia/Aqtobe",
    text: "West Kazakhstan Time - Aqtobe",
    value: "Asia/Aqtobe",
  },
  {
    key: "Asia/Atyrau",
    text: "West Kazakhstan Time - Atyrau",
    value: "Asia/Atyrau",
  },
  {
    key: "Asia/Oral",
    text: "West Kazakhstan Time - Oral",
    value: "Asia/Oral",
  },
  {
    key: "Asia/Qyzylorda",
    text: "West Kazakhstan Time - Qyzylorda",
    value: "Asia/Qyzylorda",
  },
  {
    key: "Asia/Yekaterinburg",
    text: "Yekaterinburg Standard Time",
    value: "Asia/Yekaterinburg",
  },
  {
    key: "Asia/Colombo",
    text: "India Standard Time - Colombo",
    value: "Asia/Colombo",
  },
  {
    key: "Asia/Kolkata",
    text: "India Standard Time - Kolkata",
    value: "Asia/Kolkata",
  },
  {
    key: "Asia/Kathmandu",
    text: "Nepal Time",
    value: "Asia/Kathmandu",
  },
  {
    key: "Asia/Dhaka",
    text: "Bangladesh Standard Time",
    value: "Asia/Dhaka",
  },
  {
    key: "Asia/Thimphu",
    text: "Bhutan Time",
    value: "Asia/Thimphu",
  },
  {
    key: "Asia/Almaty",
    text: "East Kazakhstan Time - Almaty",
    value: "Asia/Almaty",
  },
  {
    key: "Asia/Qostanay",
    text: "East Kazakhstan Time - Qostanay",
    value: "Asia/Qostanay",
  },
  {
    key: "Indian/Chagos",
    text: "Indian Ocean Time",
    value: "Indian/Chagos",
  },
  {
    key: "Asia/Bishkek",
    text: "Kyrgyzstan Time",
    value: "Asia/Bishkek",
  },
  {
    key: "Asia/Omsk",
    text: "Omsk Standard Time",
    value: "Asia/Omsk",
  },
  {
    key: "Asia/Urumqi",
    text: "Urumqi Time",
    value: "Asia/Urumqi",
  },
  {
    key: "Antarctica/Vostok",
    text: "Vostok Time",
    value: "Antarctica/Vostok",
  },
  {
    key: "Indian/Cocos",
    text: "Cocos Islands Time",
    value: "Indian/Cocos",
  },
  {
    key: "Asia/Yangon",
    text: "Myanmar Time",
    value: "Asia/Yangon",
  },
  {
    key: "Asia/Barnaul",
    text: "Barnaul Time",
    value: "Asia/Barnaul",
  },
  {
    key: "Indian/Christmas",
    text: "Christmas Island Time",
    value: "Indian/Christmas",
  },
  {
    key: "Antarctica/Davis",
    text: "Davis Time",
    value: "Antarctica/Davis",
  },
  {
    key: "Asia/Hovd",
    text: "Hovd Standard Time",
    value: "Asia/Hovd",
  },
  {
    key: "Asia/Bangkok",
    text: "Indochina Time - Bangkok",
    value: "Asia/Bangkok",
  },
  {
    key: "Asia/Ho_Chi_Minh",
    text: "Indochina Time - Ho Chi Minh City",
    value: "Asia/Ho_Chi_Minh",
  },
  {
    key: "Asia/Krasnoyarsk",
    text: "Krasnoyarsk Standard Time - Krasnoyarsk",
    value: "Asia/Krasnoyarsk",
  },
  {
    key: "Asia/Novokuznetsk",
    text: "Krasnoyarsk Standard Time - Novokuznetsk",
    value: "Asia/Novokuznetsk",
  },
  {
    key: "Asia/Novosibirsk",
    text: "Novosibirsk Standard Time",
    value: "Asia/Novosibirsk",
  },
  {
    key: "Asia/Tomsk",
    text: "Tomsk Time",
    value: "Asia/Tomsk",
  },
  {
    key: "Asia/Jakarta",
    text: "Western Indonesia Time - Jakarta",
    value: "Asia/Jakarta",
  },
  {
    key: "Asia/Pontianak",
    text: "Western Indonesia Time - Pontianak",
    value: "Asia/Pontianak",
  },
  {
    key: "Antarctica/Casey",
    text: "Australian Western Standard Time - Casey",
    value: "Antarctica/Casey",
  },
  {
    key: "Australia/Perth",
    text: "Australian Western Standard Time - Perth",
    value: "Australia/Perth",
  },
  {
    key: "Asia/Brunei",
    text: "Brunei Darussalam Time",
    value: "Asia/Brunei",
  },
  {
    key: "Asia/Makassar",
    text: "Central Indonesia Time",
    value: "Asia/Makassar",
  },
  {
    key: "Asia/Macau",
    text: "China Standard Time - Macau",
    value: "Asia/Macau",
  },
  {
    key: "Asia/Shanghai",
    text: "China Standard Time - Shanghai",
    value: "Asia/Shanghai",
  },
  {
    key: "Asia/Choibalsan",
    text: "Choibalsan Standard Time",
    value: "Asia/Choibalsan",
  },
  {
    key: "Asia/Hong_Kong",
    text: "Hong Kong Standard Time",
    value: "Asia/Hong_Kong",
  },
  {
    key: "Asia/Irkutsk",
    text: "Irkutsk Standard Time",
    value: "Asia/Irkutsk",
  },
  {
    key: "Asia/Kuala_Lumpur",
    text: "Malaysia Time - Kuala Lumpur",
    value: "Asia/Kuala_Lumpur",
  },
  {
    key: "Asia/Kuching",
    text: "Malaysia Time - Kuching",
    value: "Asia/Kuching",
  },
  {
    key: "Asia/Manila",
    text: "Philippine Standard Time",
    value: "Asia/Manila",
  },
  {
    key: "Asia/Singapore",
    text: "Singapore Standard Time",
    value: "Asia/Singapore",
  },
  {
    key: "Asia/Taipei",
    text: "Taipei Standard Time",
    value: "Asia/Taipei",
  },
  {
    key: "Asia/Ulaanbaatar",
    text: "Ulaanbaatar Standard Time",
    value: "Asia/Ulaanbaatar",
  },
  {
    key: "Australia/Eucla",
    text: "Australian Central Western Standard Time",
    value: "Australia/Eucla",
  },
  {
    key: "Asia/Dili",
    text: "East Timor Time",
    value: "Asia/Dili",
  },
  {
    key: "Asia/Jayapura",
    text: "Eastern Indonesia Time",
    value: "Asia/Jayapura",
  },
  {
    key: "Asia/Tokyo",
    text: "Japan Standard Time",
    value: "Asia/Tokyo",
  },
  {
    key: "Asia/Pyongyang",
    text: "Korean Standard Time - Pyongyang",
    value: "Asia/Pyongyang",
  },
  {
    key: "Asia/Seoul",
    text: "Korean Standard Time - Seoul",
    value: "Asia/Seoul",
  },
  {
    key: "Pacific/Palau",
    text: "Palau Time",
    value: "Pacific/Palau",
  },
  {
    key: "Asia/Chita",
    text: "Yakutsk Standard Time - Chita",
    value: "Asia/Chita",
  },
  {
    key: "Asia/Khandyga",
    text: "Yakutsk Standard Time - Khandyga",
    value: "Asia/Khandyga",
  },
  {
    key: "Asia/Yakutsk",
    text: "Yakutsk Standard Time - Yakutsk",
    value: "Asia/Yakutsk",
  },
  {
    key: "Australia/Darwin",
    text: "Australian Central Standard Time",
    value: "Australia/Darwin",
  },
  {
    key: "Australia/Brisbane",
    text: "Australian Eastern Standard Time - Brisbane",
    value: "Australia/Brisbane",
  },
  {
    key: "Australia/Lindeman",
    text: "Australian Eastern Standard Time - Lindeman",
    value: "Australia/Lindeman",
  },
  {
    key: "Pacific/Guam",
    text: "Chamorro Standard Time",
    value: "Pacific/Guam",
  },
  {
    key: "Pacific/Chuuk",
    text: "Chuuk Time",
    value: "Pacific/Chuuk",
  },
  {
    key: "Antarctica/DumontDUrville",
    text: "Dumont-d’Urville Time",
    value: "Antarctica/DumontDUrville",
  },
  {
    key: "Pacific/Port_Moresby",
    text: "Papua New Guinea Time",
    value: "Pacific/Port_Moresby",
  },
  {
    key: "Asia/Ust-Nera",
    text: "Vladivostok Standard Time - Ust-Nera",
    value: "Asia/Ust-Nera",
  },
  {
    key: "Asia/Vladivostok",
    text: "Vladivostok Standard Time - Vladivostok",
    value: "Asia/Vladivostok",
  },
  {
    key: "Australia/Adelaide",
    text: "Central Australia Time - Adelaide",
    value: "Australia/Adelaide",
  },
  {
    key: "Australia/Broken_Hill",
    text: "Central Australia Time - Broken Hill",
    value: "Australia/Broken_Hill",
  },
  {
    key: "Pacific/Bougainville",
    text: "Bougainville Time",
    value: "Pacific/Bougainville",
  },
  {
    key: "Australia/Currie",
    text: "Eastern Australia Time - Currie",
    value: "Australia/Currie",
  },
  {
    key: "Australia/Hobart",
    text: "Eastern Australia Time - Hobart",
    value: "Australia/Hobart",
  },
  {
    key: "Australia/Melbourne",
    text: "Eastern Australia Time - Melbourne",
    value: "Australia/Melbourne",
  },
  {
    key: "Australia/Sydney",
    text: "Eastern Australia Time - Sydney",
    value: "Australia/Sydney",
  },
  {
    key: "Pacific/Kosrae",
    text: "Kosrae Time",
    value: "Pacific/Kosrae",
  },
  {
    key: "Australia/Lord_Howe",
    text: "Lord Howe Time",
    value: "Australia/Lord_Howe",
  },
  {
    key: "Antarctica/Macquarie",
    text: "Macquarie Island Time",
    value: "Antarctica/Macquarie",
  },
  {
    key: "Asia/Magadan",
    text: "Magadan Standard Time",
    value: "Asia/Magadan",
  },
  {
    key: "Pacific/Noumea",
    text: "New Caledonia Standard Time",
    value: "Pacific/Noumea",
  },
  {
    key: "Pacific/Norfolk",
    text: "Norfolk Island Time",
    value: "Pacific/Norfolk",
  },
  {
    key: "Pacific/Pohnpei",
    text: "Ponape Time",
    value: "Pacific/Pohnpei",
  },
  {
    key: "Asia/Sakhalin",
    text: "Sakhalin Standard Time",
    value: "Asia/Sakhalin",
  },
  {
    key: "Pacific/Guadalcanal",
    text: "Solomon Islands Time",
    value: "Pacific/Guadalcanal",
  },
  {
    key: "Asia/Srednekolymsk",
    text: "Srednekolymsk Time",
    value: "Asia/Srednekolymsk",
  },
  {
    key: "Pacific/Efate",
    text: "Vanuatu Standard Time",
    value: "Pacific/Efate",
  },
  {
    key: "Asia/Anadyr",
    text: "Anadyr Standard Time",
    value: "Asia/Anadyr",
  },
  {
    key: "Pacific/Fiji",
    text: "Fiji Time",
    value: "Pacific/Fiji",
  },
  {
    key: "Pacific/Tarawa",
    text: "Gilbert Islands Time",
    value: "Pacific/Tarawa",
  },
  {
    key: "Pacific/Kwajalein",
    text: "Marshall Islands Time - Kwajalein",
    value: "Pacific/Kwajalein",
  },
  {
    key: "Pacific/Majuro",
    text: "Marshall Islands Time - Majuro",
    value: "Pacific/Majuro",
  },
  {
    key: "Pacific/Nauru",
    text: "Nauru Time",
    value: "Pacific/Nauru",
  },
  {
    key: "Asia/Kamchatka",
    text: "Petropavlovsk-Kamchatski Standard Time",
    value: "Asia/Kamchatka",
  },
  {
    key: "Pacific/Funafuti",
    text: "Tuvalu Time",
    value: "Pacific/Funafuti",
  },
  {
    key: "Pacific/Wake",
    text: "Wake Island Time",
    value: "Pacific/Wake",
  },
  {
    key: "Pacific/Wallis",
    text: "Wallis & Futuna Time",
    value: "Pacific/Wallis",
  },
  {
    key: "Pacific/Auckland",
    text: "New Zealand Time",
    value: "Pacific/Auckland",
  },
  {
    key: "Pacific/Enderbury",
    text: "Phoenix Islands Time",
    value: "Pacific/Enderbury",
  },
  {
    key: "Pacific/Fakaofo",
    text: "Tokelau Time",
    value: "Pacific/Fakaofo",
  },
  {
    key: "Pacific/Tongatapu",
    text: "Tonga Standard Time",
    value: "Pacific/Tongatapu",
  },
  {
    key: "Pacific/Chatham",
    text: "Chatham Time",
    value: "Pacific/Chatham",
  },
  {
    key: "Pacific/Apia",
    text: "Apia Time",
    value: "Pacific/Apia",
  },
  {
    key: "Pacific/Kiritimati",
    text: "Line Islands Time",
    value: "Pacific/Kiritimati",
  },
] as ITimeZoneProps[];
