import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledSearchAllList = styled.div<{ loading: boolean }>`
  ${(props) =>
    props.loading &&
    `display: flex;
    align-items: center;
    justify-content: center;
  `}
  height: 100%;
  .breadcrumb-sidebar {
    padding: 0 20px;
  }
  .padding-justify {
    padding: 0 20px;
  }

  .ui.button {
    &.upload-media {
      i {
        background-color: ${Theme.color.white};
        width: 14px;
      }
    }
  }

  .ui.breadcrumb {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .ui.dropdown {
      .menu {
        .item {
          font-size: 14px;
        }
      }
    }
  }

  .media-folder {
    > .grid {
      > .row {
        padding: 0 !important;
      }
    }
  }

  .media-file {
    > .grid {
      > .row {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }
  .layout-container {
    position: relative;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 55px;
  }
  .layout-list {
    padding: 0 20px;

    .column {
      :first-child {
        .row {
          .column {
            .media-item.media-item-small {
              border-top: none;
            }
          }
        }
      }
    }
  }

  .ui.layout-list {
    > .row {
      width: calc(100% + 40px) !important;
      margin-left: -20px;
      margin-right: -20px;

      .media-item {
        > .grid {
          margin: 0 !important;

          > .row {
            .media-alpha {
              width: 100%;

              .media-breakdown {
                width: calc(100% - 75px);
              }
            }

            .media-details,
            .media-actions {
              display: none;
            }
          }
        }

        &.selected {
          box-shadow: none;
        }
      }
    }

    .empty-media {
      width: 100%;
      min-height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;

      p {
        font-size: 0.875em;
        color: rgba(31, 32, 39, 0.5);
      }
    }
  }

  .app-instance {
    display: flex;
    padding: 10px 0;
    border-top: 1px solid ${Theme.color.silver};
    cursor: pointer;

    :hover {
      background-color: rgba(0, 0, 0, 0.01);
    }

    .app-instance-thumbnail {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0 10px;
      &:hover {
        .overlay {
          opacity: 0.5;
        }
      }

      .thumbnail {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 2px;
        width: 50px;
        height: 40px;
        max-width: 50px;
        max-height: 40px;
        .wrapper {
          position: initial;
        }
      }

      .overlay {
        position: absolute;
        background: gray;
        width: 40px;
        height: 40px;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        ${Theme.default.borderRadius};
        transition: 0.3s;
      }
    }

    .app-instance-info {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      width: 100%;

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .app-instance-title {
          margin-bottom: 0;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
          overflow: hidden;
        }

        .app-instance-type {
          text-transform: lowercase;
          font-style: italic;
          color: #bbb;
        }
      }
    }
  }
`;
