import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  .button {
    min-width: auto;
  }
`;

export const CastedScreenInfoStyled = styled.div`
  .header {
    background: ${Theme.color.base};
    border-radius: ${Theme.default.borderRadiusValue}
      ${Theme.default.borderRadiusValue} 0 0;
    margin-left: -12px;
    margin-top: -6px;
    padding: 12px;
    width: calc(100% + 24px);

    span {
      font-weight: bold;
    }
  }

  .screen-item {
    align-items: center;
    border-bottom: 1px solid ${Theme.color.darkGreyHover};
    display: inline-flex;
    font-size: 16px;
    height: 60px;
    width: 228px;

    .screen-alpha {
      padding-right: 10px;
      width: calc(100% - 28px);

      h3 {
        margin: 0;
        cursor: pointer;
        font-size: 1em;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span {
        color: ${Theme.color.grey};
        font-size: 0.875em;
      }
    }

    .screen-pending {
      .loader {
        background-color: ${Theme.color.red};
        border-radius: 100%;
        height: 28px;
        left: 0;
        padding: 0;
        width: 28px;

        :before {
          border-color: ${Theme.color.white};
          opacity: 0.3;
          right: 6px;
          top: 6px;
        }

        :after {
          border-color: ${Theme.color.white} transparent transparent;
          right: 6px;
          top: 6px;
        }
      }
    }

    .screen-stop {
      border: 1px solid ${Theme.color.grey};
      border-radius: 100%;
      width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex-shrink: 0;

      i {
        width: 20px;
        height: 20px;
        background-color: ${Theme.color.grey};
      }

      :hover {
        border-color: ${Theme.color.red};
        background: ${Theme.color.red};

        i {
          background-color: ${Theme.color.white};
        }
      }
    }

    :last-child {
      border-bottom: none;
    }
  }
`;
