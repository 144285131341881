import { orderBy, uniqBy } from "lodash";
import { FilesOrderBy, FoldersOrderBy } from "../types.g";
import { fieldsPolicyType } from "./apolloClient";

export const mediaListField: fieldsPolicyType = {
  filesByFolderId: {
    keyArgs: ["folderId", "orderBy", "filter"],
    merge(existing, incoming, options) {
      const orderbyState = options.variables?.fileOrderBy
        ? options.variables?.fileOrderBy[0]
        : FilesOrderBy.CreatedAtDesc;
      const existingNodes = existing ? [...existing.nodes] : [];
      const incomingNode = incoming?.nodes ? [...incoming.nodes] : [];
      const nodes =
        incomingNode.length < existingNodes.length &&
        options.variables?.endCursor === null &&
        existing.totalCount > incoming.totalCount
          ? incomingNode
          : uniqBy([...existingNodes, ...incomingNode], "__ref");

      const getFileOrderBy = () => {
        switch (orderbyState) {
          case FilesOrderBy.NameAsc:
            return [(node) => node?.name?.toLowerCase()];
          case FilesOrderBy.IsFavoriteDesc:
            return ["isFavorite"];
          default:
            return ["created"];
        }
      };
      const fileOrderBy = getFileOrderBy();
      const reOrder = orderBy(
        nodes.map((node) => {
          const name = options.readField("name", node) as string;
          const created = options.readField("createdAt", node) as string;
          const isFavorite = options.readField("isFavorite", node) as boolean;
          return {
            name,
            created,
            isFavorite,
            node,
          };
        }),
        fileOrderBy,
        orderbyState === FilesOrderBy.NameAsc ? ["asc"] : ["desc"]
      );
      const finalNodesRef = reOrder.map((re) => re.node);

      // fix pageInfo for reorder and fetchmore
      const pageInfo =
        !options.variables?.endCursor && existing?.pageInfo
          ? existing.pageInfo
          : incoming?.pageInfo;
      return {
        ...options.mergeObjects(existing, incoming),
        nodes: finalNodesRef,
        pageInfo,
      };
    },
  },
  foldersByParentId: {
    keyArgs: ["folderId", "orderBy"],
    merge(existing, incoming, options) {
      const orderbyState = options.variables?.folderOrderBy
        ? options.variables?.folderOrderBy[0]
        : FoldersOrderBy.CreatedAtDesc;
      const existingNodes = existing ? [...existing.nodes] : [];
      const incomingNode = incoming?.nodes ? [...incoming.nodes] : [];
      let nodes = uniqBy([...existingNodes, ...incomingNode], "__ref");

      if (existing && existing.nodes.length > existing.totalCount) {
        nodes = incomingNode;
      }

      const getFoldersOrderBy = () => {
        switch (orderbyState) {
          case FoldersOrderBy.NameAsc:
            return [(node) => node?.name?.toLowerCase()];
          case FoldersOrderBy.IsFavoriteDesc:
            return ["isFavorite"];
          default:
            return ["created"];
        }
      };
      const foldersOrderBy = getFoldersOrderBy();
      const reOrder = orderBy(
        nodes.map((node) => {
          const name = options.readField("name", node) as string;
          const created = options.readField("createdAt", node) as string;
          const isFavorite = options.readField("isFavorite", node) as boolean;
          return {
            name,
            created,
            isFavorite,
            node,
          };
        }),
        foldersOrderBy,
        orderbyState === FoldersOrderBy.NameAsc ? ["asc"] : ["desc"]
      );
      const finalNodesRef = reOrder.map((re) => re.node);

      return {
        ...options.mergeObjects(existing, incoming),
        nodes: finalNodesRef,
      };
    },
  },
};

export const searchMediaField: fieldsPolicyType = {
  searchFile: {
    keyArgs: ["query", "spaceId", "orderBy"],
    merge(existing, incoming, options) {
      const orderbyState = options.variables?.fileOrderBy
        ? options.variables?.fileOrderBy[0]
        : FilesOrderBy.CreatedAtDesc;
      const existingNodes = existing ? [...existing.nodes] : [];
      const incomingNode = incoming?.nodes ? [...incoming.nodes] : [];
      let nodes = uniqBy([...existingNodes, ...incomingNode], "__ref");

      if (existing && existing.nodes.length > existing.totalCount) {
        nodes = incomingNode;
      }

      const getFileOrderBy = () => {
        switch (orderbyState) {
          case FilesOrderBy.NameAsc:
            return [(node) => node?.name?.toLowerCase()];
          case FilesOrderBy.IsFavoriteDesc:
            return ["isFavorite"];
          default:
            return ["created"];
        }
      };
      const fileOrderBy = getFileOrderBy();

      const reOrder = orderBy(
        nodes.map((node) => {
          const name = options.readField("name", node) as string;
          const created = options.readField("createdAt", node) as string;
          return {
            name,
            created,
            node,
          };
        }),
        fileOrderBy,
        orderbyState === FilesOrderBy.NameAsc ? ["asc"] : ["desc"]
      );
      const finalNodesRef = reOrder.map((re) => re.node);

      // fix pageInfo for reorder and fetchmore
      const pageInfo =
        !options.variables?.endCursor && existing?.pageInfo
          ? existing.pageInfo
          : incoming?.pageInfo;
      return {
        ...options.mergeObjects(existing, incoming),
        nodes: finalNodesRef,
        pageInfo,
      };
    },
  },
  searchFolder: {
    keyArgs: ["query", "spaceId", "orderBy"],
    merge(existing, incoming, options) {
      const orderbyState = options.variables?.folderOrderBy
        ? options.variables?.folderOrderBy[0]
        : FoldersOrderBy.CreatedAtDesc;
      const existingNodes = existing ? [...existing.nodes] : [];
      const incomingNode = incoming?.nodes ? [...incoming.nodes] : [];
      let nodes = uniqBy([...existingNodes, ...incomingNode], "__ref");

      if (existing && existing.nodes.length > existing.totalCount) {
        nodes = incomingNode;
      }

      const getFoldersOrderBy = () => {
        switch (orderbyState) {
          case FoldersOrderBy.NameAsc:
            return [(node) => node?.name?.toLowerCase()];
          case FoldersOrderBy.IsFavoriteDesc:
            return ["isFavorite"];
          default:
            return ["created"];
        }
      };
      const foldersOrderBy = getFoldersOrderBy();
      const reOrder = orderBy(
        nodes.map((node) => {
          const name = options.readField("name", node) as string;
          const created = options.readField("createdAt", node) as string;
          return {
            name,
            created,
            node,
          };
        }),
        foldersOrderBy,
        orderbyState === FoldersOrderBy.NameAsc ? ["asc"] : ["desc"]
      );
      const finalNodesRef = reOrder.map((re) => re.node);

      // fix pageInfo for reorder and fetchmore
      const pageInfo =
        !options.variables?.endCursor && existing?.pageInfo
          ? existing.pageInfo
          : incoming?.pageInfo;
      return {
        ...options.mergeObjects(existing, incoming),
        nodes: finalNodesRef,
        pageInfo,
      };
    },
  },
};

export const mediaPickerForOrgWideField: fieldsPolicyType = {
  allChildFoldersFromAllRootFolders: {
    keyArgs: ["orderBy"],
    merge(existing, incoming, options) {
      const orderbyState = options.variables?.folderOrderBy
        ? options.variables?.folderOrderBy[0]
        : FoldersOrderBy.CreatedAtDesc;
      const existingNodes = existing ? [...existing.nodes] : [];
      const incomingNode = incoming?.nodes ? [...incoming.nodes] : [];
      let nodes = uniqBy([...existingNodes, ...incomingNode], "__ref");

      if (existing && existing.nodes.length > existing.totalCount) {
        nodes = incomingNode;
      }

      const foldersOrderBy =
        orderbyState === FoldersOrderBy.NameAsc
          ? [(node) => node?.name?.toLowerCase()]
          : ["created"];
      const reOrder = orderBy(
        nodes.map((node) => {
          const name = options.readField("name", node) as string;
          const created = options.readField("createdAt", node) as string;
          return {
            name,
            created,
            node,
          };
        }),
        foldersOrderBy,
        orderbyState === FoldersOrderBy.NameAsc ? ["asc"] : ["desc"]
      );
      const finalNodesRef = reOrder.map((re) => re.node);

      return {
        ...options.mergeObjects(existing, incoming),
        nodes: finalNodesRef,
      };
    },
  },
  allFilesFromAllRootFolders: {
    keyArgs: ["orderBy"],
    merge(existing, incoming, options) {
      const orderbyState = options.variables?.fileOrderBy
        ? options.variables?.fileOrderBy[0]
        : FilesOrderBy.CreatedAtDesc;
      const existingNodes = existing ? [...existing.nodes] : [];
      const incomingNode = incoming?.nodes ? [...incoming.nodes] : [];
      const nodes =
        incomingNode.length < existingNodes.length &&
        options.variables?.endCursor === null &&
        existing.totalCount > incoming.totalCount
          ? incomingNode
          : uniqBy([...existingNodes, ...incomingNode], "__ref");

      const fileOrderBy =
        orderbyState === FilesOrderBy.NameAsc
          ? [(node) => node?.name?.toLowerCase()]
          : ["created"];
      const reOrder = orderBy(
        nodes.map((node) => {
          const name = options.readField("name", node) as string;
          const created = options.readField("createdAt", node) as string;
          return {
            name,
            created,
            node,
          };
        }),
        fileOrderBy,
        orderbyState === FilesOrderBy.NameAsc ? ["asc"] : ["desc"]
      );
      const finalNodesRef = reOrder.map((re) => re.node);

      // fix pageInfo for reorder and fetchmore
      const pageInfo =
        !options.variables?.endCursor && existing?.pageInfo
          ? existing.pageInfo
          : incoming?.pageInfo;
      return {
        ...options.mergeObjects(existing, incoming),
        nodes: finalNodesRef,
        pageInfo,
      };
    },
  },
};
