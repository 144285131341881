import * as React from "react";
import { Styled } from "./styles";

export interface MainProps {
  children?: React.ReactNode;
  [key: string]: any;
}

const Main = ({ children, style }: MainProps) => (
  <Styled style={style}>{children}</Styled>
);

export default Main;
