import { ApolloQueryResult } from "@apollo/client";
import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
  InlineInput,
  ModalSize,
  Popover,
  ToggleStar,
} from "@screencloud/screencloud-ui-components";
import { EntityType } from "@screencloud/signage-firestore-client";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "../../utils/compose";

import classNames from "clsx";
import { AppContext } from "../../AppContextProvider/AppContext";
import { TOTAL_MEDIA_COLUMN } from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import {
  getFolderImage,
  getTotalCount,
  shouldFolderShowShareButton,
  shouldShowShareFolderIcon,
} from "../../helpers/folderHelper";
import { isInMultipleSelection } from "../../helpers/reactEventHelper";
import {
  canBeDeleted,
  canBeUpdated,
  getShareButtonContent,
  isSharedBySpaceOrLink,
} from "../../helpers/shareableHelper";
import { FolderShareModal } from "../../pages/Media/FolderShareModal";
import { subscribeToDocumentUpdates } from "../../state/liveUpdateManager";
import {
  File,
  Folder,
  Maybe,
  NestedFilesByFolderIdDocument,
  NestedFilesByFolderIdQuery,
} from "../../types.g";
import { StyledMediaItem } from "./styleMediaItem";
import { AppContextType } from "src/AppContextProvider/type";

export enum MediaFolderActions {
  CREATE_PLAYLIST = "CREATE_PLAYLIST",
  DELETE = "DELETE",
  MOVE = "MOVE",
  RENAME = "RENAME",
  SHARE = "SHARE",
  ENTER_FOLDER = "ENTER_FOLDER",
  SELECTED = "SELECTED",
  FAVORITE = "FAVORITE",
  CHECKED = "CHECKED",
  ADD = "ADD",
  REFETCH = "REFETCH",
}

const withData = compose(withRouter, withApollo);

interface ApolloProps extends WithApolloClient<{}>, RouteComponentProps<any> {}

export interface MediaFolderProps {
  callBack: (
    id: string,
    action: MediaFolderActions,
    value: Maybe<Partial<File>>[] | string | boolean,
    event?: React.SyntheticEvent,
    index?: number
  ) => void;
  className?: string;
  index?: number;
  media: Folder;
  isReadonly?: boolean;
  isSelected?: boolean;
  isNewFolder?: boolean;
  isCompactLayout?: boolean;
  isSelectionActive?: boolean;
  canSelect?: boolean;
  showFavorite?: boolean;
  showActionMenu?: boolean;
  isVisible?: boolean;
  hideShareIcon?: boolean;
  hideCheckbox?: boolean;
}

export interface MediaFolderState {
  isRenameState: boolean;
}

class MediaFolder extends React.Component<
  MediaFolderProps & ApolloProps,
  MediaFolderState
> {
  public static defaultProps: Partial<MediaFolderProps> = {
    isCompactLayout: false,
  };

  public static contextType = AppContext;
  public context: AppContextType;
  private _isMounted: boolean = false;
  private unsubscribeLiveUpdateFn?: () => void;

  constructor(props: MediaFolderProps & ApolloProps) {
    super(props);
    this.state = {
      isRenameState: false,
    };
  }

  public getClassName = (): string => {
    const classes: string[] = ["media-item", "media-folder"];

    if (this.props.isReadonly) {
      classes.push("readonly");
    }

    if (this.props.canSelect) {
      classes.push("selectable");
    }

    if (this.props.isNewFolder) {
      classes.push("new-folder-hilight");
    }

    if (this.props.isSelected === true) {
      classes.push("selected");
    }

    if (this.props.isCompactLayout) {
      classes.push("media-item-small");
    }

    if (this.props.className) {
      classes.push(this.props.className);
    }

    return classes.join(" ");
  };

  public componentDidMount() {
    this._isMounted = true;
    this.subscribeToLiveUpdate();
  }

  public componentWillUnmount() {
    this._isMounted = false;
    this.unsubscribeFromLiveUpdate();
  }

  public subscribeToLiveUpdate() {
    this.unsubscribeFromLiveUpdate();

    if (this.props.media.id) {
      this.unsubscribeLiveUpdateFn = subscribeToDocumentUpdates(
        this.props.media.orgId || "",
        EntityType.FOLDER,
        this.props.media.id,
        () => {
          if (this._isMounted) {
            this.props.callBack(
              this.props.media.id,
              MediaFolderActions.REFETCH,
              false,
              undefined,
              this.props.index
            );
          }
        },
        true
      );
    }
  }

  public unsubscribeFromLiveUpdate() {
    if (this.unsubscribeLiveUpdateFn) {
      this.unsubscribeLiveUpdateFn();
      this.unsubscribeLiveUpdateFn = undefined;
    }
  }

  public handleCreatePlaylist = (event: React.SyntheticEvent) => {
    this.props.callBack(
      this.props.media.id,
      MediaFolderActions.CREATE_PLAYLIST,
      "",
      event,
      this.props.index
    );
  };

  public handleItemClick = (event: React.MouseEvent) => {
    if (this.props.canSelect) {
      this.props.callBack(
        this.props.media.id,
        MediaFolderActions.SELECTED,
        "",
        event,
        this.props.index
      );
    } else if (!this.state.isRenameState && !isInMultipleSelection(event)) {
      this.props.callBack(
        this.props.media.id,
        MediaFolderActions.ENTER_FOLDER,
        "",
        event,
        this.props.index
      );
    }
  };

  public handleEnterFolder = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!this.state.isRenameState && !isInMultipleSelection(event)) {
      this.props.callBack(
        this.props.media.id,
        MediaFolderActions.ENTER_FOLDER,
        "",
        event,
        this.props.index
      );
    }
  };

  public getAllFilesFromFolderId = async (
    folderId: string
  ): Promise<File[]> => {
    const nestedFilesByFolderIdQuery: ApolloQueryResult<NestedFilesByFolderIdQuery> = await this.props.client!.query(
      {
        query: NestedFilesByFolderIdDocument,
        fetchPolicy: "network-only",
        variables: {
          folderId,
        },
      }
    );
    const files =
      (nestedFilesByFolderIdQuery.data.nestedFilesByFolderId
        ?.nodes as File[]) ?? [];
    return files;
  };

  public handleThumbnailClick = async (event: React.MouseEvent) => {
    const { isCompactLayout, media, index, callBack } = this.props;
    event.stopPropagation();
    if (!this.state.isRenameState) {
      if (isCompactLayout && media.filesByFolderId) {
        const files = await this.getAllFilesFromFolderId(media.id);
        callBack(media.id, MediaFolderActions.ADD, files, event, index);
      } else {
        callBack(media.id, MediaFolderActions.ENTER_FOLDER, "", event, index);
      }
    }
  };

  public handleRename = (event: React.SyntheticEvent) => {
    const keyboardEvent = event as React.KeyboardEvent<any>;
    if (!keyboardEvent.shiftKey && !keyboardEvent.metaKey) {
      this.setState({ isRenameState: true });
    }
  };

  public handleDelete = (event: React.SyntheticEvent) => {
    this.props.callBack(
      this.props.media.id,
      MediaFolderActions.DELETE,
      "",
      event,
      this.props.index
    );
  };

  public handleCheck = (
    event: React.SyntheticEvent,
    data: { checked: boolean }
  ) => {
    event.stopPropagation();
    this.props.callBack(
      this.props.media.id,
      MediaFolderActions.CHECKED,
      data.checked,
      event,
      this.props.index
    );
  };

  public handleFavorite = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (!event.shiftKey && !event.metaKey) {
      // TODO: not sure why we detect event.ctrlKey, but it can't click favorite on Folder item
      this.props.callBack(
        this.props.media.id,
        MediaFolderActions.FAVORITE,
        !this.props.media.isFavorite,
        event,
        this.props.index
      );
    }
  };

  public onNameSave = async (
    event: React.SyntheticEvent<any>,
    value: string
  ) => {
    await this.setState({ isRenameState: false });

    // check if name is stay the same should not call save func
    if (this.props.media.name !== value) {
      this.props.callBack(
        this.props.media.id,
        MediaFolderActions.RENAME,
        value,
        event,
        this.props.index
      );
    }
  };

  public handleShare = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation();
    const { media } = this.props;
    this.context.modal.openModal(
      <FolderShareModal folder={media as Folder} />,
      "Share Folder",
      {
        opts: {
          size: ModalSize.MEDIUM,
        },
      }
    );
  };

  public render() {
    const {
      hideShareIcon,
      media,
      isVisible,
      showActionMenu,
      hideCheckbox,
    } = this.props;
    const totalCount = getTotalCount(media);
    const itemUnit = totalCount > 1 ? "items" : "item";
    const canCreatePlaylist = this.context.currentPermissions.validateCurrentSpace(
      "playlist",
      "create"
    );
    const canDeleteMedia = canBeDeleted({
      context: this.context,
      shareable: media,
    });
    const canUpdateMedia = canBeUpdated({
      context: this.context,
      shareable: media,
    });
    const canPerformAction =
      canCreatePlaylist ||
      shouldFolderShowShareButton({ context: this.context, folder: media }) ||
      canDeleteMedia;
    const shouldShowMediaOptions = showActionMenu && canPerformAction;

    let totalDescriptionCols = TOTAL_MEDIA_COLUMN;
    if (!this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING)) {
      totalDescriptionCols = totalDescriptionCols - 1;
    }
    if (!this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.STARRING)) {
      totalDescriptionCols = totalDescriptionCols - 1;
    }

    return (
      <StyledMediaItem
        data-cy="media-item"
        totalDescriptionCols={totalDescriptionCols}
        className={this.getClassName()}
        onClick={this.handleItemClick}
      >
        {isVisible && (
          <>
            <div className="media-core">
              <div className="media-alpha">
                <div
                  className="thumbnail-preview"
                  onClick={this.handleThumbnailClick}
                >
                  <div className="thumbnail folder">
                    <div className="wrapper">
                      <img
                        src={getFolderImage({
                          folder: media,
                          context: this.context,
                        })}
                        className="folder-img"
                      />
                      {this.props.isCompactLayout && (
                        <div className="overlay">
                          <Icon name="plus" color={"#fff"} className="icon" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="media-title">
                  {this.state.isRenameState === true ? (
                    <InlineInput
                      value={media.name}
                      onSaved={this.onNameSave}
                      editmode={true}
                    />
                  ) : (
                    <h3 title={media.name}>
                      <span onClick={this.handleEnterFolder}>{media.name}</span>
                      {canUpdateMedia && (
                        <Icon
                          name="edit"
                          onClick={this.handleRename}
                          title="Rename"
                        />
                      )}
                    </h3>
                  )}
                  <div>
                    <span className="media-item__count">
                      {totalCount} {itemUnit}
                    </span>
                  </div>
                </div>
              </div>
              {this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.STARRING) &&
                this.props.showFavorite && (
                  <div className="media-starred">
                    <ToggleStar
                      active={media.isFavorite!}
                      onClick={this.handleFavorite}
                    />
                  </div>
                )}
              {!hideShareIcon &&
                shouldShowShareFolderIcon({
                  folder: media,
                  context: this.context,
                }) && (
                  <div
                    className={classNames("media-share", {
                      isShared: isSharedBySpaceOrLink({
                        context: this.context,
                        shareable: media,
                      }),
                    })}
                  >
                    <Popover
                      trigger={
                        <Button icon borderless mini onClick={this.handleShare}>
                          <Icon name="users" />
                        </Button>
                      }
                      position="top center"
                      inverted
                      content={getShareButtonContent({
                        shareable: media,
                        context: this.context,
                      })}
                    />
                  </div>
                )}
            </div>

            <div className="folder-delete" />
            <div className="media-options">
              {this.props.isSelectionActive && !hideCheckbox ? (
                <Button icon borderless mini>
                  {this.props.isSelected ? (
                    <Icon name="checkbox-checked" className="checked" />
                  ) : (
                    <Icon name="checkbox-empty" />
                  )}
                </Button>
              ) : (
                <>
                  {shouldShowMediaOptions ? (
                    <Dropdown
                      icon={
                        <Button icon borderless mini>
                          <Icon name="dots" />
                        </Button>
                      }
                      className="media-dropdown"
                    >
                      <DropdownMenu>
                        {canCreatePlaylist && (
                          <DropdownItem onClick={this.handleCreatePlaylist}>
                            <Icon name="playlist-create" />
                            <FormattedMessage
                              id="ui_component.media_item.create_playlist"
                              defaultMessage="Create Playlist"
                            />
                          </DropdownItem>
                        )}
                        {shouldFolderShowShareButton({
                          context: this.context,
                          folder: media,
                        }) && (
                          <>
                            <DropdownDivider />
                            <DropdownItem onClick={this.handleShare}>
                              <Icon name="users" />
                              <FormattedMessage
                                id="ui_component.label.share"
                                defaultMessage="Share"
                              />
                            </DropdownItem>
                          </>
                        )}
                        {canDeleteMedia && (
                          <>
                            <DropdownDivider />
                            <DropdownItem
                              onClick={this.handleDelete}
                              className="danger"
                            >
                              <Icon name="trash" />{" "}
                              <FormattedMessage
                                id="ui_component.common.label.delete_folder"
                                defaultMessage="Delete Folder"
                              />
                            </DropdownItem>
                          </>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                </>
              )}
            </div>
          </>
        )}
      </StyledMediaItem>
    );
  }
}

export default withData(MediaFolder) as React.ComponentType<MediaFolderProps>;
