import { ApolloQueryResult, FetchResult } from "@apollo/client";
import { LoaderBar } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { ScreenPickerActions } from "../../../components/ScreenPicker";
import { AppContext } from "../../../AppContextProvider/AppContext";
import { MediaPickerActionMode } from "../../../components/MediaPicker/media";
import {
  DUMMY_APP_INSTANCE_ID,
  RefType,
  TitlePageSufix,
} from "../../../constants/constants";
import { createAppThumbnail } from "../../../helpers/appHelper";
import {
  AllCommonAppInstanceTemplatesDocument,
  AppInstance,
  AppInstanceByIdExtendedAssociationQueryVariables,
  AppInstanceByIdProps,
  AppInstanceByIdQuery,
  AppVersion,
  AvailableAppInstancesBySpaceIdDocument,
  DuplicateAppInstanceMutationFn,
  File,
  FilesConnection,
  Scalars,
  TagsByAppIdAndSpaceIdQueryVariables,
  UpdateAppInstanceAvailableMutationFn,
  UpdateAppInstanceExpireMutationFn,
  UpdateAppInstanceMutation,
  UpdateAppInstanceMutationFn,
  UpdateAppInstanceMutationVariables,
  UpdateShareAppInstanceToAllSpacesMutationFn,
  UpdateShareAppInstanceToSpacesMutationFn,
  useAppInstanceByIdExtendedAssociationQuery,
  useDuplicateAppInstanceMutation,
  useTagsByAppIdAndSpaceIdQuery,
  useUpdateAppInstanceAvailableMutation,
  useUpdateAppInstanceExpireMutation,
  useUpdateAppInstanceMutation,
  useUpdateShareAppInstanceToAllSpacesMutation,
  useUpdateShareAppInstanceToSpacesMutation,
} from "../../../types.g";
import { callPreAppConfigureSaveHook } from "../../../utils/callPreAppConfigureSaveHook";
import { compose } from "../../../utils/compose";
import AppConfigure from "../AppConfigure";
import AppEditor from "../AppEditor";
import { getFormDataForAppInstance } from "./utils";
import {
  UseDeleteAppInstance,
  useDeleteAppInstance,
} from "../../../hooks/entities/appInstance/useDeleteAppInstance";
import { AppContextType } from "src/AppContextProvider/type";
import { AppFile } from "@screencloud/studio-player-sdk";
import { UUID } from "@screencloud/uuid";
import { useGetScreenSize } from "../../../hooks/useGetScreenSize";
import {
  useAddAppsToPlaylistsMutation,
  UseAddAppsToPlaylistsMutation,
} from "src/pages/Apps/AppInstances/hooks/useAddAppsToPlaylist";
import { useAppContext } from "src/hooks/useAppContext";
import { isGallerySection, isTemplateSection } from "src/helpers/canvasHelper";
import { useLocation } from "react-router";
import { useReplaceContent } from "src/hooks/useReplaceContent";
import {
  ReplacementJob,
  useReplaceContentStatus,
} from "src/components/ReplaceContentStatus/hooks/useReplaceContentStatus";

export interface AppContainerProps
  extends AppInstanceByIdProps,
    UseDeleteAppInstance {
  id: string;
  appInstanceById: AppInstanceByIdQuery["appInstanceById"];
  loading: boolean;
  appInstance?: AppInstance;
  appName?: string;
  appVersion?: AppVersion;
  className?: string;
  onUpdateSuccess?: (success: boolean) => void;
  onCreateInstance?: (
    instanceName: string,
    jsonConfig: Scalars["JSON"],
    thumbnail?: string,
    appInstanceId?: UUID
  ) => Promise<void>;
  updateAppInstanceAvailable: UpdateAppInstanceAvailableMutationFn;
  updateAppInstanceExpire: UpdateAppInstanceExpireMutationFn;
  updateShareAppInstanceToSpaces: UpdateShareAppInstanceToSpacesMutationFn;
  updateShareAppInstanceToAllSpaces: UpdateShareAppInstanceToAllSpacesMutationFn;
  handleAddAppIntoPlaylists: UseAddAppsToPlaylistsMutation["addAppsToPlaylists"];
  duplicateAppInstance: DuplicateAppInstanceMutationFn;
  updateAppInstance: UpdateAppInstanceMutationFn;
  availableTags: string[];
  isMobileView: boolean;
  isAvailableTagsLoading: boolean;
  openReplaceContentModal: ({ originalContent, associations }: any) => void;
  replacementJobs: ReplacementJob[];
  isGallery: boolean;
}

const withData = compose(
  (Component) => (props: AppContainerProps) => {
    if (!props.id || !props.id) {
      throw new Error(`Query fired before router state.`);
    }

    const context = useAppContext();

    const queryVar: AppInstanceByIdExtendedAssociationQueryVariables = {
      id: props.id,
      spaceId: context.currentSpace?.id,
    };

    const { data, loading } = useAppInstanceByIdExtendedAssociationQuery({
      fetchPolicy: "cache-and-network",
      variables: queryVar,
    });
    return (
      <Component
        {...props}
        appInstanceById={data?.appInstanceById}
        loading={loading}
      />
    );
  },
  (Component) => (props: AppContainerProps) => {
    const [updateAppInstance] = useUpdateAppInstanceMutation();
    const [
      updateAppInstanceAvailable,
    ] = useUpdateAppInstanceAvailableMutation();
    const [updateAppInstanceExpire] = useUpdateAppInstanceExpireMutation();
    const { deleteAppInstance } = useDeleteAppInstance();
    const [
      updateShareAppInstanceToSpaces,
    ] = useUpdateShareAppInstanceToSpacesMutation();
    const [
      updateShareAppInstanceToAllSpaces,
    ] = useUpdateShareAppInstanceToAllSpacesMutation();

    const { addAppsToPlaylists } = useAddAppsToPlaylistsMutation();
    const [duplicateAppInstance] = useDuplicateAppInstanceMutation();

    return (
      <Component
        {...props}
        updateAppInstance={updateAppInstance}
        deleteAppInstance={deleteAppInstance}
        updateAppInstanceAvailable={updateAppInstanceAvailable}
        updateAppInstanceExpire={updateAppInstanceExpire}
        updateShareAppInstanceToSpaces={updateShareAppInstanceToSpaces}
        updateShareAppInstanceToAllSpaces={updateShareAppInstanceToAllSpaces}
        handleAddAppIntoPlaylists={addAppsToPlaylists}
        duplicateAppInstance={duplicateAppInstance}
      />
    );
  },
  (Component) => (props: AppContainerProps) => {
    const { isMobileView } = useGetScreenSize();
    return <Component {...props} isMobileView={isMobileView} />;
  },
  (Component) => (props: AppContainerProps) => {
    const [isAvailableTagsLoading, setIsAvailableTagsLoading] = React.useState(
      false
    );
    const appId = props.appInstanceById?.appId;
    const { currentSpace } = useAppContext();
    const { pathname } = useLocation();
    const spaceId = currentSpace?.id as string;
    const variables: TagsByAppIdAndSpaceIdQueryVariables = {
      appId,
      spaceId,
      isTemplate: isTemplateSection(pathname),
      isShared: false,
    };
    const { data, loading } = useTagsByAppIdAndSpaceIdQuery({
      variables,
      fetchPolicy: "cache-and-network",
      skip: !appId,
    });
    const isNewlyCreateAppInstance = props.id === DUMMY_APP_INSTANCE_ID;
    React.useEffect(() => {
      if (
        !isNewlyCreateAppInstance &&
        (!data?.tagsByAppIdAndSpaceId || loading)
      ) {
        setIsAvailableTagsLoading(true);
      } else {
        setIsAvailableTagsLoading(false);
      }
    }, [data, loading]);

    const availableTags = (data?.tagsByAppIdAndSpaceId ?? []).filter((item) =>
      Boolean(item)
    ) as string[];

    return (
      <Component
        {...props}
        availableTags={availableTags}
        isAvailableTagsLoading={isAvailableTagsLoading}
      />
    );
  },
  (Component) => (props: AppContainerProps) => {
    const { openReplaceContentModal } = useReplaceContent();
    return (
      <Component {...props} openReplaceContentModal={openReplaceContentModal} />
    );
  },
  (Component) => (props: AppContainerProps) => {
    const { jobs } = useReplaceContentStatus();
    return <Component {...props} replacementJobs={jobs} />;
  },
  (Component) => (props: AppContainerProps) => {
    const { pathname } = useLocation();
    const isGallery = isGallerySection(pathname);
    return <Component {...props} isGallery={isGallery} />;
  }
);

export interface AppContainerState {
  data: ApolloQueryResult<AppInstanceByIdQuery> | null;
  selectedFiles: Partial<FilesConnection>;
}

export enum AppInstanceAction {
  SHARE = "SHARE",
  DELETE = "DELETE",
  SET_AVAILABILITY = "SET_AVAILABILITY",
  SET_TO_SCREEN = "SET_TO_SCREEN",
  ADD_TO_PLAYLISTS = "ADD_TO_PLAYLISTS",
  DUPLICATE = "DUPLICATE",
  FIND_AND_REPLACE = "FIND_AND_REPLACE",
  SAVE_AS_TEMPLATE = "SAVE_AS_TEMPLATE",
}

class AppContainer extends React.Component<
  AppContainerProps,
  AppContainerState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: AppContainerProps) {
    super(props);
    this.state = {
      data: null,
      selectedFiles: { nodes: [] },
    };
  }

  // save appInstanceId optionally on app creation
  public handleAppInstanceCreate = async (
    name,
    config,
    thumbnail?: string,
    appInstanceId?: UUID
  ) => {
    if (this.props.onCreateInstance) {
      await this.props.onCreateInstance(name, config, thumbnail, appInstanceId);
    }
  };

  public handleAppConfigureSave = async (name, config, thumbnail?: string) => {
    const { appInstanceById } = this.props;
    if (appInstanceById?.appVersionByAppInstanceId) {
      const appVersion = appInstanceById.appVersionByAppInstanceId;
      const jsonSchema = { ...appVersion.manifestJson };
      if (
        jsonSchema &&
        jsonSchema.hooks &&
        jsonSchema.hooks.preAppConfigureSave
      ) {
        try {
          config = await callPreAppConfigureSaveHook(
            jsonSchema.hooks.preAppConfigureSave,
            appInstanceById.id,
            config
          );
        } catch (error) {
          console.log("Failed to get config: ", error);
        }
      }
      const updateAppInstanceVar: UpdateAppInstanceMutationVariables = {
        input: {
          config,
          id: appInstanceById.id,
          name,
          spaceId: this.context.user.settings.spaceId,
          state: {},
          version: appInstanceById.appVersionByAppInstanceId.version,
        },
      };
      const appResp: void | FetchResult<UpdateAppInstanceMutation> = await this.props.updateAppInstance(
        {
          refetchQueries: [
            {
              query: AvailableAppInstancesBySpaceIdDocument,
              variables: { spaceId: this.context.user.settings.spaceId },
            },
          ],
          variables: updateAppInstanceVar,
        }
      );

      if (this.props.onUpdateSuccess) {
        let success = false;
        if (appResp?.data?.updateAppInstance?.appInstance) {
          success = true;
        }
        this.props.onUpdateSuccess(success);
      }
      this.saveAppThumbnail({
        appInstanceId: appInstanceById.id,
        config,
        name,
        thumbnail,
        appVersion: appVersion.version,
        isGallery: this.props.isGallery,
      });
    }
  };

  public openMediaPicker = async (payload: {
    mimeTypes: string | string[];
    multiSelect: boolean;
  }): Promise<{ media: AppFile[] }> => {
    const mimeTypes =
      typeof payload.mimeTypes === "string"
        ? [payload.mimeTypes]
        : payload.mimeTypes;

    const result = await this.context.modal.openMediaPicker(
      MediaPickerActionMode.ADD,
      "Media Picker",
      {
        allowMediaMimeType: mimeTypes,
        menu: [RefType.FILE],
        multiple: payload.multiSelect,
        section: RefType.FILE,
        screenPickerAction: ScreenPickerActions.SET_CONTENT,
      }
    );

    return new Promise(
      (resolve: (value: { media: AppFile[] }) => void, reject) => {
        if (result.data) {
          this.setState({
            selectedFiles: {
              nodes: [
                ...(this.state.selectedFiles.nodes as File[]),
                ...(result.data as File[]),
              ],
            },
          });
          resolve({ media: result.data as AppFile[] });
        } else {
          resolve({ media: [] });
        }
      }
    );
  };

  public render() {
    const {
      appInstanceById,
      loading,
      updateAppInstanceAvailable,
      updateAppInstanceExpire,
      deleteAppInstance,
      updateShareAppInstanceToSpaces,
      updateShareAppInstanceToAllSpaces,
      handleAddAppIntoPlaylists,
      duplicateAppInstance,
      availableTags,
      isAvailableTagsLoading,
      openReplaceContentModal,
    } = this.props;
    let view;

    if (appInstanceById?.appVersionByAppInstanceId) {
      // Editing existing app
      const appInstance = appInstanceById;
      const appVersion = appInstanceById?.appVersionByAppInstanceId;
      const appName =
        appVersion && appVersion.appByAppId ? appVersion.appByAppId.name : "";
      const appId =
        appVersion && appVersion.appByAppId ? appVersion.appByAppId.id : "";

      const jsonSchema = { ...appVersion?.manifestJson };
      document.title = `${appInstance?.name} - ${TitlePageSufix}`;

      if (appVersion?.configurationType === "FORM") {
        view = (
          <AppConfigure
            appId={appId}
            appName={appName}
            formData={getFormDataForAppInstance(
              { ...appInstance?.config },
              jsonSchema.schemaForm
            )}
            appInstance={appInstance as AppInstance}
            instanceName={appInstance?.name || ""}
            appVersion={appVersion as AppVersion}
            jsonSchema={jsonSchema}
            onSave={this.handleAppConfigureSave}
            displayAppPreview={true}
            openMediaPicker={this.openMediaPicker}
            deleteAppInstance={deleteAppInstance}
            updateAppInstanceAvailable={updateAppInstanceAvailable}
            updateAppInstanceExpire={updateAppInstanceExpire}
            updateShareAppInstanceToSpaces={updateShareAppInstanceToSpaces}
            updateShareAppInstanceToAllSpaces={
              updateShareAppInstanceToAllSpaces
            }
            addAppIntoPlaylists={handleAddAppIntoPlaylists}
            duplicateAppInstance={duplicateAppInstance}
            availableTags={availableTags}
            isAvailableTagsLoading={isAvailableTagsLoading}
            openReplaceContentModal={openReplaceContentModal}
          />
        );
      } else if (appVersion?.configurationType === "EDITOR") {
        if (
          this.state.selectedFiles.nodes &&
          this.state.selectedFiles.nodes.length > 0
        ) {
          this.state.selectedFiles.nodes.map((file) => {
            appInstance?.filesByAppInstanceId.nodes.push({
              ...file,
              ...{ __typename: "File" },
            });
          });
        }
        view = (
          <AppEditor
            appId={appId}
            appName={appName}
            appInstance={appInstance as AppInstance}
            instanceName={appInstance?.name || ""}
            appVersion={appVersion as AppVersion}
            onSave={this.handleAppConfigureSave}
            openMediaPicker={this.openMediaPicker}
            deleteAppInstance={deleteAppInstance}
            updateAppInstanceAvailable={updateAppInstanceAvailable}
            updateAppInstanceExpire={updateAppInstanceExpire}
            updateShareAppInstanceToSpaces={updateShareAppInstanceToSpaces}
            updateShareAppInstanceToAllSpaces={
              updateShareAppInstanceToAllSpaces
            }
            addAppIntoPlaylists={handleAddAppIntoPlaylists}
            duplicateAppInstance={duplicateAppInstance}
            availableTags={availableTags ?? []}
            isMobileView={this.props.isMobileView}
            isAvailableTagsLoading={isAvailableTagsLoading}
            openReplaceContentModal={openReplaceContentModal}
            updateAppInstance={this.props.updateAppInstance}
          />
        );
      }
      return (
        <>
          {view}
          {loading && <LoaderBar />}
        </>
      );
    } else if (
      this.props.appInstance &&
      this.props.appVersion &&
      this.props.appName
    ) {
      // Creating a new instance
      document.title = `${this.props.appName} - ${TitlePageSufix}`;

      if (this.props.appVersion.configurationType === "FORM") {
        // TODO: This is a quick fix, figure out why when create new canvas there is no __typename for appInstance
        const appInstance = {
          ...this.props.appInstance,
          __typename: "AppInstance",
        } as AppInstance;
        view = (
          <AppConfigure
            appId={this.props.id}
            appName={this.props.appName}
            formData={getFormDataForAppInstance(
              this.props.appInstance.config,
              this.props.appVersion.manifestJson.schemaForm
            )}
            appInstance={appInstance}
            instanceName={this.props.appInstance.name || ""}
            appVersion={this.props.appVersion}
            jsonSchema={this.props.appVersion.manifestJson}
            onSave={this.handleAppInstanceCreate}
            displayAppPreview={false}
            openMediaPicker={this.openMediaPicker}
            deleteAppInstance={deleteAppInstance}
            updateAppInstanceAvailable={updateAppInstanceAvailable}
            updateAppInstanceExpire={updateAppInstanceExpire}
            updateShareAppInstanceToSpaces={updateShareAppInstanceToSpaces}
            updateShareAppInstanceToAllSpaces={
              updateShareAppInstanceToAllSpaces
            }
            addAppIntoPlaylists={handleAddAppIntoPlaylists}
            duplicateAppInstance={duplicateAppInstance}
            availableTags={availableTags}
            isAvailableTagsLoading={isAvailableTagsLoading}
            openReplaceContentModal={openReplaceContentModal}
          />
        );
      } else if (this.props.appVersion.configurationType === "EDITOR") {
        // TODO: This is a quick fix, figure out why when create new canvas there is no __typename for appInstance
        const appInstance = {
          ...this.props.appInstance,
          __typename: "AppInstance",
        } as AppInstance;
        appInstance.filesByAppInstanceId = this.state
          .selectedFiles as FilesConnection;
        view = (
          <AppEditor
            appId={this.props.id}
            appName={this.props.appName}
            appInstance={appInstance}
            instanceName={this.props.appInstance.name || ""}
            appVersion={this.props.appVersion}
            onSave={this.handleAppInstanceCreate}
            openMediaPicker={this.openMediaPicker}
            deleteAppInstance={deleteAppInstance}
            updateAppInstanceAvailable={updateAppInstanceAvailable}
            updateAppInstanceExpire={updateAppInstanceExpire}
            updateShareAppInstanceToSpaces={updateShareAppInstanceToSpaces}
            updateShareAppInstanceToAllSpaces={
              updateShareAppInstanceToAllSpaces
            }
            addAppIntoPlaylists={handleAddAppIntoPlaylists}
            duplicateAppInstance={duplicateAppInstance}
            availableTags={availableTags ?? []}
            isMobileView={this.props.isMobileView}
            isAvailableTagsLoading={isAvailableTagsLoading}
            openReplaceContentModal={openReplaceContentModal}
            updateAppInstance={this.props.updateAppInstance}
          />
        );
      }
      return (
        <>
          {view}
          {loading && <LoaderBar />}
        </>
      );
    } else {
      return <div />;
    }
  }

  private saveAppThumbnail = async ({
    appInstanceId,
    config,
    name,
    isGallery,
    thumbnail,
    appVersion,
  }: {
    appInstanceId: string;
    config: any;
    name: string;
    isGallery: boolean;
    thumbnail?: string;
    appVersion?: string | null;
  }) => {
    if (thumbnail) {
      try {
        const thumbnailId = await createAppThumbnail(
          thumbnail,
          `${appInstanceId}`,
          this.context.user.settings.spaceId
        );
        const updateAppInstanceVar: UpdateAppInstanceMutationVariables = {
          input: {
            config,
            id: appInstanceId,
            name,
            spaceId: this.context.user.settings.spaceId,
            state: {},
            version: appVersion,
            thumbnailFileId: thumbnailId,
          },
        };
        const refetchQueries = [
          {
            query: AvailableAppInstancesBySpaceIdDocument,
            variables: { spaceId: this.context.user.settings.spaceId },
          },
        ];
        const updatedRefetchQueries = isGallery
          ? [
              ...refetchQueries,
              { query: AllCommonAppInstanceTemplatesDocument },
            ]
          : refetchQueries;

        await this.props.updateAppInstance({
          refetchQueries: updatedRefetchQueries,
          variables: updateAppInstanceVar,
        });
      } catch (error) {
        console.warn("Could not save app instance thumbnail:", error);
      }
    }
  };
}

export default withData(AppContainer);
