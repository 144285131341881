import { Plan, TermUnit } from "../types";

/**
 * Retrieves a human-readable label for the plan term/frequency.
 *
 * @param periodUnit - The period_unit from Chargebee to be parsed.
 */
export function termLabel(period: number, periodUnit: TermUnit): string {
  switch (periodUnit) {
    case "year":
      if (period === 1) {
        return "Annually";
      }
      return `Every ${period} years`;
    case "week":
      if (period === 1) {
        return "Weekly";
      }
      return `Every ${period} weeks`;
    case "day":
      if (period === 1) {
        return "Daily";
      }
      return `Every ${period} days`;
    case "month":
      if (period === 1) {
        return "Monthly";
      }
      if (period === 3) {
        return "Quarterly";
      }
      if (period === 6) {
        return "Semi-annually";
      }
      return `Every ${period} months`;
    default:
      return "";
  }
}

/**
 * Retrieves a human-readable label for the plan term/frequency.
 *
 * @param periodUnit - The period_unit from Chargebee to be parsed.
 */
export function periodUnit(period: number, periodUnit: TermUnit): string {
  switch (periodUnit) {
    case "year":
      if (period === 1) {
        return "year";
      }
      return `${period} years`;
    case "week":
      if (period === 1) {
        return "week";
      }
      return `${period} weeks`;
    case "day":
      if (period === 1) {
        return "day";
      }
      return `${period} days`;
    case "month":
      if (period === 1) {
        return "month";
      }
      if (period === 3) {
        return "quarter";
      }
      if (period === 6) {
        return "semi-annual";
      }
      return `${period} months`;
    default:
      return "";
  }
}

/**
 * Calculates the percentage saving between two prices.
 *
 * @param base - The base price for comparison (usually the higher value, e.g., monthly cost).
 * @param comparison - The price to compare against the base (usually the lower value with the potential saving).
 *
 * @remarks
 * This function is commonly employed to highlight potential savings when considering alternative terms, like opting
 * for an annual payment to enjoy a discount. The result is always a non-negative value, making it easy to handle
 * scenarios where the savings information should be hidden.
 */
export function savingsPercentage(base: number, comparison: number): number {
  const difference = base - comparison;
  const percentage = Math.round((difference / base) * 100);
  return percentage > 0 ? percentage : 0;
}

/**
 * Calculates the total price for the given number of licenses on a tiered plan.
 *
 * @remarks
 * When calculating the total price, quantities are purchased from successive tiers, starting from the lowest.
 * eg. 0-10 = $15, 11-20 = $10, if someone purchased 15 units price would be ($15 x 10)+($10 x 5)
 */
export function calculateTieredPrice(plan: Plan, licenses: number): number {
  let quantity = 0;
  let totalPrice = 0;
  const tiers = plan.tiers;
  tiers?.filter((tier) => {
    if (licenses < tier.startingUnit) {
      return;
    }
    if (licenses > tier.endingUnit) {
      totalPrice += (tier.endingUnit - quantity) * tier.price;
      quantity = tier.endingUnit;
    } else {
      totalPrice += (licenses - tier.startingUnit + 1) * tier.price;
    }
  });
  return totalPrice;
}
