import styled from "styled-components";
import { MessageType } from "./types";

interface NotificationBadgeProps {
  type: MessageType;
}
export const NotificationBadge = styled.div<NotificationBadgeProps>`
  display: flex;
  padding: 3px 6px;

  align-items: center;
  gap: var(--spacing-xxxs, 2px);

  border-radius: 12px;

  box-shadow: 0px 1px 2px 0px var(--color-black-5, rgba(0, 0, 0, 0.05));

  font-size: var(--font-size-xs, 12px);
  font-style: normal;
  font-weight: 700;
  line-height: 1px; /* 8.333% */
  white-space: nowrap;

  i[name="info"] {
    width: 14px;
    height: 14px;
  }

  ${(props: NotificationBadgeProps) => {
    switch (props.type) {
      case "critical":
        return `
                      border: 2px solid var(--color-red-600, #F40E0E);
                      background: var(--color-red-100, #FFDEDE);
                      color: var(--color-red-800, #AA0A0A);
                      i[name=info] {
                          background-color: var(--color-red-800, #AA0A0A);
                      }
                  `;
      case "error":
        return `
                      border: 2px solid var(--color-purple-600, #8442E9);
                      background: var(--color-purple-100, #E2DFFF);
                      color: var(--color-purple-800, #5D2DA6);
                      i[name=info] {
                          background-color: var(--color-purple-800, #5D2DA6);
                      }
                  `;
      case "warning":
        return `
                      border: 2px solid var(--color-orange-600, #D37800);
                      background: var(--color-orange-100, #FFF5C0);
                      color: var(--color-orange-800, #8A4009);
                      i[name=info] {
                          background-color: var(--color-orange-800, #8A4009);
                      }
                  `;
      case "info":
        return `
                      border: 2px solid var(--color-blue-500, #219AFF);
                      background: var(--color-blue-50, #EDF8FF);
                      color: var(--color-blue-800, #0A4EC3);
                      i[name=info] {
                          background-color: var(--color-blue-800, #0A4EC3);
                      }
                  `;
    }
  }}
`;

export const PopoverBadgeStyled = styled.div`
  width: 360px;
  max-height: 480px;

  border-radius: var(--radius-md, 8px);

  border: 1px solid var(--color-black-10, rgba(0, 0, 0, 0.1));
  background: var(--color-white-100, #fff);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-md, 8px);

  overflow: auto;
  overflow-x: hidden;

  text-align: left;
  font-family: var(--font-family-studio, Lato);
  font-size: var(--font-size-sm, 14px);

  i[name="info"] {
    width: var(--size-xs, 20px);
    height: var(--size-xs, 20px);
  }

  .notification-row:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-100, #e2e3e5);
  }

  .notification-row {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    column-gap: 8px;
    align-items: center;
    padding: 12px 16px;

    &.background-critical {
      background-color: var(--color-red-50, #fff0f0);
      border-bottom: 1px solid var(--color-destructive, #ffc3c3);
    }
    &.background-error {
      border-bottom: 1px solid var(--color-purple-200, #cec6ff);
      background-color: var(--color-purple-50, #f0eeff);
    }
    &.background-warning {
      border-bottom: 1px solid var(--color-orange-200, #ffe885);
      background-color: var(--color-orange-50, #fffae5);
    }
  }

  .col-start-2 {
    grid-column-start: 2;
  }
  .col-end-4 {
    grid-column-end: 4;
  }
  .row-start-3 {
    grid-row-start: 3;
  }
  .font-weight-700 {
    font-weight: 700;
  }

  .critical {
    color: var(--color-red-600, #f40e0e);
  }

  .icon-background-critical {
    background-color: var(--color-red-700, #aa0a0a);
  }

  .error {
    color: var(--color-purple-600, #8442e9);
  }
  .icon-background-error {
    background: var(--color-purple-600, #5d2da6);
  }

  .warning {
    color: var(--color-orange-600, #d37800);
  }

  .icon-background-warning {
    background-color: var(--color-orange-600, #d37800);
  }

  .info {
    color: var(--color-blue-600, #097aff);
  }
  .icon-background-info {
    background-color: var(--color-blue-600, #097aff);
  }

  .critical-800 {
    color: var(--color-red-800, #aa0a0a);
  }
  .error-800 {
    color: var(--color-purple-800, #5d2da6);
  }
  .warning-800 {
    color: var(--color-orange-800, #8a4009);
  }
  .info-800 {
    color: var(--color-gray-800, #363639);
  }
  .gray-400 {
    color: var(--color-gray-400, #7d7e84);
  }

  .gray-300 {
    color: var(--color-gray-300, #a1a2a7);
  }
  .gray-800 {
    color: var(--color-gray-800, #363639);
  }
  .markdown-preview {
    background-color: transparent;
    color: var(--color-gray-500, #62636a);
  }
`;

export const PopoverNotificationBadgesStyled = styled.div`
  div[role="dialog"] {
    z-index: 10;
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  .popover-badge-style {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs, 4px);
  }
`;
