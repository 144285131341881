import { useState, useEffect } from "react";
import {
  Exact,
  Maybe,
  FilesOrderBy,
  FolderByIdFilterByMimeTypeQueryVariables,
  useFolderByIdFilterByMimeTypeQuery,
  FolderByIdFilterByMimeTypeQuery,
  FoldersOrderBy,
} from "../../../types.g";
import { MediaOrderBy } from "../../../constants/constants";
import {
  renderFetchMoreButton,
  renderFetchMorePickerButton,
} from "src/helpers/generalHelper";

export interface UseFolderByIdFilterByMimeType {
  data: FolderByIdFilterByMimeTypeQuery | undefined;
  hasNextPage: boolean;
  loading: boolean;
  isLoading: boolean;
  refetch: (
    variables?: Partial<
      Exact<{
        folderId: any;
        endCursor: any;
        fileOrderBy: Maybe<FilesOrderBy[]>;
        folderOrderBy: Maybe<FoldersOrderBy[]>;
        allowMimeType: Maybe<string[]>;
      }>
    >
  ) => void;
  loadMore: () => void;
  renderFetchMoreButton: React.ReactNode;
  renderFetchMorePickerButton: React.ReactNode;
  allItemsTotalCount: number;
}

interface FolderByIdProps {
  folderId: string;
  skip: boolean;
  allowMediaMimeType?: string[];
  filesOrderBy?: FilesOrderBy[];
  foldersOrderBy?: FoldersOrderBy[];
}

export function useFolderByIdFilterByMimeType(
  props: FolderByIdProps
): UseFolderByIdFilterByMimeType {
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const { folderId, skip, allowMediaMimeType } = props;

  let mimeTypes: string[] = allowMediaMimeType ?? [];
  if (allowMediaMimeType?.some((type) => type === "image/*")) {
    mimeTypes = [
      ...mimeTypes,
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
    ];
  }
  if (allowMediaMimeType?.some((type) => type === "video/*")) {
    mimeTypes = [
      ...mimeTypes,
      "video/mp4",
      "video/m4p",
      "video/m4v",
      "video/avi",
      "video/mov",
      "video/wmv",
    ];
  }
  if (allowMediaMimeType?.some((type) => type === "audio/*")) {
    mimeTypes = [...mimeTypes, "audio/mpeg", "audio/mp3"];
  }

  const folderByIdFilterByMimeTypeQueryVar: FolderByIdFilterByMimeTypeQueryVariables = {
    fileOrderBy: [MediaOrderBy.FILE_CREATED_AT_DESC],
    folderId,
    folderOrderBy: [MediaOrderBy.FOLDER_CREATED_AT_DESC],
    endCursor: null,
    allowMimeType: mimeTypes,
  };

  const {
    data,
    fetchMore,
    refetch,
    loading,
  } = useFolderByIdFilterByMimeTypeQuery({
    variables: folderByIdFilterByMimeTypeQueryVar,
    fetchPolicy: "cache-and-network",
    skip,
  });

  const loadMore = () => {
    if (!loading) {
      setIsLoading(true);
      fetchMore({
        variables: {
          endCursor: data?.folderById?.filesByFolderId?.pageInfo?.endCursor,
        },
      }).then(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    setHasNextPage(
      Boolean(data?.folderById?.filesByFolderId.pageInfo.hasNextPage) &&
        data?.folderById?.filesByFolderId.nodes.length !==
          data?.folderById?.filesByFolderId.totalCount &&
        !isLoading
    );
  }, [data?.folderById?.filesByFolderId.pageInfo.hasNextPage, isLoading]);

  const filesTotalCount = data?.folderById?.filesByFolderId?.totalCount ?? 0;
  const foldersTotalCount =
    data?.folderById?.foldersByParentId?.totalCount ?? 0;
  const allItemsTotalCount = filesTotalCount + foldersTotalCount;
  const currentItems = Number(data?.folderById?.filesByFolderId.nodes.length);

  return {
    data,
    hasNextPage,
    loadMore,
    isLoading,
    refetch,
    loading,
    renderFetchMoreButton: renderFetchMoreButton(
      currentItems,
      filesTotalCount,
      isLoading,
      hasNextPage,
      loadMore
    ),
    renderFetchMorePickerButton: renderFetchMorePickerButton(
      currentItems,
      filesTotalCount,
      isLoading,
      hasNextPage,
      loadMore
    ),
    allItemsTotalCount,
  };
}
