import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledDraggableWrapper = styled.div`
  > div:first-child + div + div {
    display: none !important;
  }
`;

export const StyledItem = styled.li`
  list-style: none;
  padding-left: 0;

  div,
  span,
  p,
  i {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  &.selected:active,
  &.selected:hover,
  &.selected {
    background-color: ${Theme.color.silver};
    box-shadow: none;
    border-top-color: ${Theme.color.lightGrey};
  }

  .list {
    display: flex;
    padding: 10px 0;
    cursor: pointer;
    border-top: 1px solid ${Theme.color.silver};

    .link-thumbnail {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0 10px;
      &:hover {
        .overlay {
          opacity: 0.5;
        }
      }

      .thumbnail {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 2px;
        width: auto;
        max-width: 40px;
        max-height: 40px;
      }

      .overlay {
        position: absolute;
        background: gray;
        width: 40px;
        height: 40px;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        ${Theme.default.borderRadius};
        transition: 0.3s;
      }
    }

    .link-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      width: 66%;

      .link-title {
        color: #4c4d52;
        margin-bottom: 0;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
      }

      .link-url {
        text-transform: lowercase;
        font-style: italic;
        color: #bbb;
        cursor: pointer;
        margin-bottom: 0;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .list:hover {
    background-color: rgba(0, 0, 0, 0.01);
  }
`;
