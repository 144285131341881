import {
  Datetime,
  DatetimeProps,
} from "@screencloud/screencloud-ui-components";
import { useAppContext } from "../../hooks/useAppContext";

const DateTime = (props: DatetimeProps) => {
  const { value, format, ignoreTimeZone, locale: localProp } = props;
  const context = useAppContext();
  const locale =
    localProp ?? context.currentUser?.preferences?.settings?.locale;
  return (
    <Datetime
      value={value}
      format={format}
      locale={locale}
      ignoreTimeZone={ignoreTimeZone}
    />
  );
};

export default DateTime;
