import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  .small {
    width: auto;
    max-height: 23px;
    max-width: 23px;
    border-radius: 2px;
    border-style: initial !important;
  }
  .medium {
    width: auto;
    max-height: 56px;
    max-width: 56px;
    border-radius: 2px;
    border-style: initial !important;
  }
  .large {
    width: auto;
    max-height: 50px;
    border-radius: 2px;
    border-style: initial !important;
  }
  &.thumbnail {
    .wrapper > .overlay {
      margin: 0;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    ${Theme.default.borderRadius};
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    > .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      grid-auto-columns: 10px;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      width: 100%;
      height: 100%;
      ${Theme.default.borderRadius};
      overflow: hidden;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        margin: 0;
      }
    }
    &:hover > .overlay {
      opacity: 1;
    }
  }
`;
