import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export interface StyledProps {
  showSecureSiteColumns: boolean;
}

export const Styled = styled.div<StyledProps>`
  .list-container {
    border-bottom: 1px solid ${Theme.color.lightGrey};
    z-index: 1;
    position: relative;
  }

  .column-title {
    span {
      color: black;
      font-size: large;
      font-weight: bold;
    }
  }

  .ui.inline.dropdown {
    .dropdown.icon {
      margin-left: 5px;
      color: ${Theme.color.grey};
    }

    .menu {
      .item {
        span {
          height: auto;
        }
      }
    }
  }

  span,
  .button {
    color: ${Theme.color.grey};
    z-index: 1;
    position: relative;
    height: 40px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  .site-core {
    width: calc(100% - 60px);
    display: flex;

    [class^="site-"] {
      text-align: center;
    }

    .site-name {
      width: 34%;
      text-align: left;
    }

    .site-starred {
      width: 9.4%;
    }

    .site-casting {
      width: 9.4%;
    }

    .site-type {
      width: ${(props) => (props.showSecureSiteColumns ? "9.4%" : "18.8%")};
    }

    .site-compute {
      display: ${(props) => (props.showSecureSiteColumns ? "block" : "none")};
      width: 9.4%;
    }

    .site-usage {
      display: ${(props) => (props.showSecureSiteColumns ? "block" : "none")};
      width: 9.4%;
    }

    .site-tags {
      width: ${(props) => (props.showSecureSiteColumns ? "19%" : "28.4%")};
    }

    .site-share {
      width: ${(props) => (props.showSecureSiteColumns ? "9.4%" : "18.8%")};
    }
  }

  @media screen and (max-width: ${Theme.break.large}) {
    .site-core {
      .site-starred {
        width: 11%;
      }

      .site-casting {
        width: 11%;
      }

      .site-type {
        width: ${(props) => (props.showSecureSiteColumns ? "11%" : "22%")};
      }

      .site-compute {
        display: ${(props) => (props.showSecureSiteColumns ? "block" : "none")};
        width: 11%;
      }

      .site-usage {
        display: ${(props) => (props.showSecureSiteColumns ? "block" : "none")};
        width: 11%;
      }

      .site-tags {
        width: ${(props) => (props.showSecureSiteColumns ? "11%" : "22%")};
      }

      .site-share {
        width: ${(props) => (props.showSecureSiteColumns ? "11%" : "22%")};
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .site-core {
      .site-name {
        width: 40%;
        min-width: 40%;
      }

      .site-starred {
        width: 15%;
      }

      .site-casting {
        width: 15%;
      }

      .site-type {
        width: 15%;
      }

      .site-compute {
        display: none;
      }

      .site-usage {
        display: none;
      }

      .site-tags {
        width: 15%;
      }

      .site-share {
        width: 15%;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .site-core {
      .site-name {
        width: 60%;
      }

      .site-starred {
        width: 20%;
      }

      .site-casting {
        width: 20%;
      }

      .site-type {
        display: none;
      }

      .site-compute {
        display: none;
      }

      .site-usage {
        display: none;
      }

      .site-tags {
        display: none;
      }

      .site-share {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .site-core {
      .site-name {
        width: calc(100% - 80px);
      }

      .site-starred {
        width: 80px;
      }

      .site-casting {
        display: none;
      }

      .site-type {
        display: none;
      }

      .site-compute {
        display: none;
      }

      .site-usage {
        display: none;
      }

      .site-tags {
        display: none;
      }

      .site-share {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .site-core {
      .site-name {
        width: 100%;
      }

      .site-starred {
        display: none;
      }
    }
  }
`;
