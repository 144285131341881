import { Dropdown } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledPublishedDropdownItem = styled.div`
  overflow: hidden;
  .username {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledDuplicateDropdownOption = styled(Dropdown)`
  .button {
    min-width: 45px;
  }
`;
