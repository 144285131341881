export enum MimeType {
  CHANNEL = "channel",
  APP = "app",
  LINK = "link",
  SITE = "site",
  PLAYLIST = "playlist",
}

export interface CastItemProps {
  id: string;
  name: string;
  mimeType: string;
  color?: string;
  coverStyle?: { [key: string]: any };
  duration?: number;
  source: string;
  screenCount: number;
  castedDate: string;
}
