import {
  Button,
  Icon,
  InlineInput,
  Loader,
  Popover,
  Tab,
  TabPane,
  ToggleStar,
} from "@screencloud/screencloud-ui-components";
import classNames from "clsx";
import { useState, RefObject, useEffect, createRef, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { DropdownItemProps } from "semantic-ui-react";
import LinkDetails from "./details";
import { NameMaxLengthAllow, UUID } from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { Styled } from "./styles";
import { CastedScreen, renderCastingStatus } from "../../helpers/castingHelper";
import { CastedScreenInfoActions } from "../CastedScreenInfo";
import { LinkByIdQueryHookResult, Scalars } from "../../types.g";
import LinkAdvanceSettings, {
  LinkAdVanceSettingsPayload,
} from "../LinkAdvanceSettings";
import { useAppContext } from "../../hooks/useAppContext";
import imagePlaceholder from "../../images/image_thumb_placeholder.svg";
import {
  generateQR,
  getQrcodePositionStyleProps,
  QRcodePosition,
} from "../../helpers/qrcodeHelper";
import {
  canGenerateQrCode,
  shouldRenderQrcode,
} from "../../helpers/linkHelper";
export interface DataProps {
  [key: string]: any;
}

export enum LinkPreviewActions {
  DELETE = "delete",
  CAST = "cast",
  DOWNLOAD = "download",
  RENAME = "rename",
  FAVORITE = "favorite",
  ADD_EXPIRY_DATE = "add_expiry_date",
  REMOVE_EXPIRY_DATE = "remove_expiry_date",
  ADD_AVAILABLE_DATE = "add_available_at",
  REMOVE_AVAILABLE_DATE = "remove_available_date",
  RESET_PUBLICATION_DATES = "reset_publication_dates",
  UPDATE_TAGS = "update_tags",
  SHOW_SCREEN_PICKER = "show_screen_picker",
  SHOW_DELETE_CONFIRM = "show_delete_confirm",
  HIDE_DELETE_CONFIRM = "hide_delete_confirm",
  UPDATE_CACHE_OPTIONS = "update_cache_options",
  UPDATE_CURRENT_URL = "update_current_url",
}

export interface LinkPreviewPayload {
  title?: string;
  checked?: boolean;
  imageUrl?: string;
  availableDate?: string;
  expiryDate?: string;
  tags?: object;
  tagsList?: DropdownItemProps[];
  screenIds?: string[];
  cacheBusting?: boolean;
  autoReload?: boolean;
  autoReloadDurationMs?: number;
  params?: Scalars["JSON"];
  linkById?: NonNullable<LinkByIdQueryHookResult["data"]>["linkById"];
}

export interface LinkPreviewProps {
  callBack?: (
    mediaId: string,
    action: LinkPreviewActions,
    data: LinkPreviewPayload
  ) => void;
  advanceSettingsCallBack?: (
    mediaId: string,
    action: LinkPreviewActions,
    data: LinkAdVanceSettingsPayload
  ) => void;
  castedScreensCallback?: (
    data: string,
    action: CastedScreenInfoActions
  ) => void;
  id: UUID;
  spaceId: UUID;
  src: string;
  title: string;
  url: string;
  tags: string[];
  tagsList: DropdownItemProps[];
  logs: object;
  favorite: boolean;
  expiryDate?: string;
  availableDate?: string;
  createdAt: string;
  createdBy: string;
  isCastButtonDisabled?: boolean;
  showScreenPicker?: boolean;
  screenPickerComponent?: React.ReactNode;
  showDeleteConfirm?: boolean;
  castedScreens?: number;
  castedScreensData?: CastedScreen[];
  cacheBusting?: boolean;
  autoReload?: boolean;
  autoReloadDurationMs?: number;
  params?: Scalars["JSON"];
  linkById?: NonNullable<LinkByIdQueryHookResult["data"]>["linkById"];
  qrcodeEnabled?: boolean;
}

export interface LinkPreviewState {
  isLoading: boolean;
  isRenameState: boolean;
  qrcode: string;
}

const LinkPreviewUI = (props: LinkPreviewProps) => {
  const context = useAppContext();

  let linkImageRef: RefObject<HTMLDivElement>;

  const {
    id,
    src,
    url,
    tagsList,
    createdAt,
    createdBy,
    title,
    castedScreens,
    showScreenPicker,
    showDeleteConfirm,
    tags,
    cacheBusting,
    autoReload,
    autoReloadDurationMs,
    params,
    favorite,
    castedScreensData,
    isCastButtonDisabled,
    screenPickerComponent,
    callBack,
    castedScreensCallback,
    advanceSettingsCallBack,
  } = props;
  const [isLoading, setIsLoading] = useState<LinkPreviewState["isLoading"]>(
    true
  );
  const [isRenameState, setIsRenameState] = useState<
    LinkPreviewState["isRenameState"]
  >(false);
  const [qrcode, setQrcode] = useState<LinkPreviewState["qrcode"]>("");
  const linkPreviewClasses: string[] = [];
  if (showScreenPicker) {
    linkPreviewClasses.push("casting");
  }

  if (showDeleteConfirm) {
    linkPreviewClasses.push("deleting");
  }

  const canDeleteink = context.currentPermissions.validateCurrentSpace(
    "link",
    "delete"
  );
  const canCastToScreen = context.currentPermissions.validateCurrentSpace(
    "screen",
    "cast"
  );
  const canUpdateLink = context.currentPermissions.validateCurrentSpace(
    "link",
    "update"
  );

  useEffect(() => {
    if (linkImageRef.current) {
      loadImage(src, linkImageRef.current);
      generateQrcode();
    }
  }, []);

  const generateQrcode = useCallback(async () => {
    const { linkById } = props;

    if (linkById && canGenerateQrCode(linkById)) {
      const qrcode = await generateQR(linkById.url);
      setQrcode(qrcode);
    }
  }, [props.linkById?.url, props.linkById?.qrcodeEnabled]);

  const loadImage = (src: string, divElement: HTMLDivElement) => {
    const img = new Image();
    img.onload = () => {
      setIsLoading(false);
      divElement.setAttribute("style", `background-image: url('${src}')`);
    };
    img.onerror = () => {
      setIsLoading(false);
      divElement.setAttribute(
        "style",
        `width: 400px; background-image: url('${imagePlaceholder}')`
      );
    };
    img.src = src;
    divElement.setAttribute(
      "style",
      `width: 400px; background-image: url('${imagePlaceholder}')`
    );
  };

  const handleClick = async (action: LinkPreviewActions, data = {}) => {
    if (action === LinkPreviewActions.SHOW_DELETE_CONFIRM) {
      const { confirm } = await context.modal.confirm(
        <>
          <h2>
            <FormattedMessage
              id="ui_component.confirm.delete_heading_name"
              defaultMessage="Delete {name}?"
              values={{ name: title }}
            />
          </h2>
          <p>
            <FormattedMessage
              id="ui_component.confirm.delete_message_with_cast"
              defaultMessage="{screenCount, select, 0 {{name} will be deleted permanently and this action cannot be undone.} 1 {{name} is currently casting on {screenName}. Are you sure you want to continue? This action cannot be undone.} other {{name} is currently casting on {screenCount} screens, Are you sure you want to continue? This action cannot be undone.}}"
              values={{
                name: <strong>{title}</strong>,
                screenCount: castedScreens || 0,
                screenName: (
                  <strong>
                    {castedScreensData && castedScreensData.length
                      ? castedScreensData[0].name
                      : ""}
                  </strong>
                ),
              }}
            />
          </p>
        </>,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.delete"
              defaultMessage="Delete"
            />
          ),
          isDanger: true,
        }
      );
      if (confirm) {
        if (callBack) {
          callBack(id, LinkPreviewActions.DELETE, data);
        }
      }
    } else {
      if (callBack) {
        callBack(id, action, data);
      }
    }
    setIsRenameState(false);
  };

  const renderLinkImage = () => {
    const { linkById } = props;
    linkImageRef = createRef();
    if (linkById && shouldRenderQrcode(linkById, src)) {
      return (
        <div className="link-content" ref={linkImageRef}>
          <div className="image-wrapper">
            <div className="link">
              <div className="qrcode">
                <img src={qrcode} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div className="link-content" ref={linkImageRef} />;
  };

  const renderTitle = () => {
    if (isRenameState) {
      return (
        <h1 className="inline-input">
          <InlineInput
            maxLength={NameMaxLengthAllow}
            editmode
            value={title}
            onSaved={(e, value) =>
              handleClick(LinkPreviewActions.RENAME, { title: value })
            }
            disabled={!canUpdateLink && !isLinkOwner()}
            spellCheck={false}
          />
        </h1>
      );
    } else {
      const startEditing = () => setIsRenameState(true);
      return (
        <h1>
          <div
            onClick={canUpdateLink ? startEditing : () => {}}
            className={classNames("title", { clickable: canUpdateLink })}
          >
            {title}
          </div>
          {canUpdateLink && (
            <Icon
              name="edit"
              data-testid="edit-title"
              onClick={startEditing}
              title="Rename"
            />
          )}
        </h1>
      );
    }
  };

  const isLinkOwner = () => {
    return context.user.settings.spaceId === props.spaceId;
  };

  const renderDeleteButton = () => {
    return (
      <Popover
        trigger={
          <Button
            icon
            borderless
            danger
            onClick={() => handleClick(LinkPreviewActions.DELETE)}
          >
            <Icon name="trash" />
          </Button>
        }
        content="Remove"
        position="top center"
        inverted
      />
    );
  };

  const qrcodeStyleProps = props.qrcodeEnabled
    ? getQrcodePositionStyleProps(
        props.linkById?.qrcodeConfig.prop?.position ??
          QRcodePosition.BOTTOM_RIGHT
      )
    : {};
  return (
    <Styled {...qrcodeStyleProps} className={linkPreviewClasses.join(" ")}>
      <div className="preview-title">{renderTitle()}</div>
      <div className="link-preview">
        <div className="icon-button-tab">
          {context.shouldShowFeature(FEATURE_FLAGS_ENUM.STARRING) &&
            isLinkOwner() && (
              <ToggleStar
                onClick={() =>
                  handleClick(LinkPreviewActions.FAVORITE, {
                    checked: !favorite,
                  })
                }
                active={favorite}
              />
            )}
          {renderCastingStatus({
            castScreenData: castedScreensData as any,
            callBack: castedScreensCallback!,
            context,
          })}
        </div>
        {isLoading && <Loader className="preview-spinner" active />}
        <div className="content-preview">{renderLinkImage()}</div>
      </div>

      <div className="link-info">
        <Tab
          className={classNames(
            "link-tabs",
            !canDeleteink && !canCastToScreen && "no-footer"
          )}
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: "Details",
              render: () => (
                <TabPane attached={false}>
                  <LinkDetails
                    id={id}
                    url={url}
                    tags={tags}
                    tagsList={tagsList}
                    callBack={callBack}
                    createdAt={createdAt}
                    createdBy={createdBy}
                    isLinkOwner={isLinkOwner()}
                    linkById={props.linkById}
                  />
                </TabPane>
              ),
            },
            {
              menuItem: "Advanced",
              render: () => (
                <TabPane
                  attached={false}
                  data-testid="link-advanced-settings-tab"
                >
                  <LinkAdvanceSettings
                    url={url}
                    callBack={(payload) =>
                      advanceSettingsCallBack &&
                      advanceSettingsCallBack(
                        id,
                        LinkPreviewActions.UPDATE_CACHE_OPTIONS,
                        payload
                      )
                    }
                    cacheBusting={cacheBusting}
                    autoReload={autoReload}
                    autoReloadDurationMs={autoReloadDurationMs}
                    params={params}
                  />
                </TabPane>
              ),
            },
          ]}
        />

        {context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) &&
          isLinkOwner() &&
          !isCastButtonDisabled &&
          canCastToScreen && (
            <div className="link-options">
              <Popover
                trigger={
                  <Button
                    icon
                    borderless
                    className="link-cast"
                    onClick={() =>
                      handleClick(LinkPreviewActions.SHOW_SCREEN_PICKER)
                    }
                    title="Set to Screen"
                  >
                    <Icon name="screen-play" />
                  </Button>
                }
                content="Set to Screen"
                position="top center"
                inverted
              />
              {isLinkOwner() && canDeleteink && renderDeleteButton()}
            </div>
          )}
      </div>

      <div className="cast-on">
        <div className="head">
          <span>Select screens</span>
        </div>
        {screenPickerComponent}
      </div>
    </Styled>
  );
};

export default LinkPreviewUI;
