import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  align-items: center;
  background-color: ${Theme.color.darkGrey};
  color: ${Theme.color.grey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;
  width: 100%;

  > div {
    max-width: 600px;
  }

  h1 {
    color: ${Theme.color.white};
    margin: 40px 0 10px;
    font-size: 1.25em;
  }

  .message {
    font-size: 1em;
  }

  a {
    color: ${Theme.color.silver};
    font-weight: bold;

    i {
      background-color: ${Theme.color.silver};
      margin-left: 5px;
    }

    :hover {
      color: ${Theme.color.white};

      i {
        background-color: ${Theme.color.white};
      }
    }
  }

  .ui.button {
    margin-top: 20px;
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
  }

  @media screen and (max-width: ${Theme.break.small}) {
    margin-left: 0;
    padding: 0 20px;
    width: 100%;

    img {
      width: 240px;
    }
  }
`;
