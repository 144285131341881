import { Button, Icon, Popover } from "@screencloud/screencloud-ui-components";
import { DocumentNode } from "graphql";
import { FormattedMessage } from "react-intl";
import { canAccessNotificationSettings } from "src/domains/screenNotification/screenNotification";
import useScreenNotification from "src/hooks/ScreenNotification/useScreenNotification";
import { useAppContext } from "src/hooks/useAppContext";
import {
  Screen,
  useGetAllNotificationRulesByScreenIdQuery,
} from "../../types.g";
import { StyledStatusNotificationButton } from "./styledNotificationButton";
import { useEffect, useState } from "react";

interface Props {
  screen: Screen;
  refetchQueries?: DocumentNode[];
  iconOnly?: boolean;
  isNotificationActive?: boolean;
}

export default function ScreenStatusNotificationButton(props: Props) {
  const context = useAppContext();
  const [isRuleActive, setIsRuleActive] = useState<boolean>(false);
  const { showSetNotificationModal } = useScreenNotification(
    props.screen.id,
    props.refetchQueries
  );

  const { data } = useGetAllNotificationRulesByScreenIdQuery({
    variables: {
      screenId: { equalTo: props.screen.id },
      currentSpaceId: { equalTo: context.currentSpace?.id },
    },
    skip: !props.screen.isNotificationActive,
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (props.isNotificationActive !== undefined) {
      setIsRuleActive(props.isNotificationActive);
    } else {
      const notificationRules = data?.allNotificationRules?.nodes;
      const isRuleActive =
        notificationRules?.[0]?.notificationSubscriptionsByNotificationRuleId
          ?.nodes?.[0]?.active;
      setIsRuleActive(!!isRuleActive);
    }
  }, [data]);

  const getTooltipContent = () => {
    return (
      <div className="notification-btn-tooltip">
        <div className="title">
          <FormattedMessage
            defaultMessage={`
      Email notification is ${isRuleActive ? "active" : "inactive"}`}
            id={`ui_component.screen_notification.${
              isRuleActive ? "on" : " off"
            }.tool_tip.title`}
          />
        </div>
        <div className="sub-title">
          <FormattedMessage
            defaultMessage={`You ${
              isRuleActive ? "will be notified via email" : "won't be notified"
            } when this screen goes offline.`}
            id={`ui_component.screen_notification.${
              isRuleActive ? "on" : " off"
            }.tool_tip.sub-title`}
          />
        </div>
      </div>
    );
  };

  const getIcon = () => {
    return isRuleActive ? (
      <Icon data-testid="bell-on" name="bell-on" />
    ) : (
      <Icon data-testid="bell-off" name="bell-off" />
    );
  };

  const getNotificationButton = (canAccessNotificationSettings = false) => {
    const className = `${
      isRuleActive
        ? `button-set-notification ${props.iconOnly ? "icon-only" : ""}`
        : `button-set-notification-off ${props.iconOnly ? "icon-only" : ""}`
    }`;

    return (
      canAccessNotificationSettings && (
        <Button
          icon={props.iconOnly}
          className={className}
          data-testid="set-notification-button"
          onClick={(e) => {
            showSetNotificationModal(props.screen.id, setIsRuleActive);
            e.stopPropagation();
          }}
        >
          {getIcon()}
        </Button>
      )
    );
  };

  return (
    <StyledStatusNotificationButton>
      <Popover
        data-testid="pro-tip"
        className="tips"
        trigger={getNotificationButton(canAccessNotificationSettings(context))}
        content={getTooltipContent()}
        inverted
      />
    </StyledStatusNotificationButton>
  );
}
