import { debounce, isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { DEBOUNCE_TIMEOUT_MS } from "src/constants/constants";

export interface UseStateSearch {
  searchString: string;
  updateSearchString: (criteria: string) => void;
  clearSearch: () => void;
  isSearch: boolean;
  query: string;
}

interface Prop {
  defaultQuery?: string;
}

/**
 * searchString - use this to show in textbox
 * query - use this when query
 * updateSearchString - update string and update query with 500 millisecond debounce
 * clearSearce - use this instead of updateSearchString when want to clear query as this has no delay
 */
export function useStateSearch(props?: Prop): UseStateSearch {
  const [query, setQuery] = useState(props?.defaultQuery || "");
  const [searchString, setSearchString] = useState(props?.defaultQuery || "");
  const setQueryWithDebounce = useCallback(
    debounce((criteria: string) => {
      const finalCriteria = criteria.replace(/\s/g, " +");
      setQuery(finalCriteria);
    }, DEBOUNCE_TIMEOUT_MS),
    []
  );

  const clearSearch = () => {
    setSearchString("");
    setQuery("");
  };

  const updateSearchString = (criteria: string) => {
    if (criteria.trim() === "") {
      clearSearch();
      setQueryWithDebounce("");
    } else {
      setSearchString(criteria);
      setQueryWithDebounce(criteria);
    }
  };

  const isSearch = !isEmpty(query);

  return { searchString, updateSearchString, clearSearch, isSearch, query };
}
