import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  background: ${Theme.color.nearWhite};
  border-radius: 0 0 ${Theme.default.borderRadiusValue}
    ${Theme.default.borderRadiusValue};
  height: 100%;

  .embed-preview {
    background-color: ${Theme.color.darkGrey};
    min-height: 270px;
    width: 100%;

    .player-preview {
      .preview {
        min-height: 270px;

        .available-space {
          height: 270px;
        }
      }
    }
  }

  .embed-code {
    padding: 20px;

    textarea {
      display: block;
      font-family: "Fira Code", monospace;
      font-size: 0.875em;
      line-height: 1.357;
      color: ${Theme.color.base};
      min-height: 110px;
      resize: none;
    }

    .button {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .embed-preview {
      min-height: auto;
    }
  }
`;
