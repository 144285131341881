import { useCallback, useState } from "react";
import { Button, Icon } from "@screencloud/screencloud-ui-components";
import classNames from "clsx";
import { FormattedMessage } from "react-intl";

import { ContentAccordionWrapper, IconWrapper, Information } from "./styles";

type ContentAccordionProps = {
  title: string | JSX.Element;
  description: string;
  content: JSX.Element;
};
export const ContentAccordion = (props: ContentAccordionProps) => {
  const [isShowItems, setIsShowItems] = useState(false);

  const toggleShowItem = useCallback(() => {
    setIsShowItems(!isShowItems);
  }, [isShowItems]);

  return (
    <ContentAccordionWrapper>
      <div
        className={classNames("banner", {
          "with-items": isShowItems,
        })}
      >
        <IconWrapper>
          <Icon name="lock" />
        </IconWrapper>
        <Information>
          <div className="title">{props.title}</div>
          <div className="description">{props.description}</div>
        </Information>
        <div>
          <Button mini onClick={toggleShowItem}>
            {isShowItems ? (
              <FormattedMessage id="common.hide" defaultMessage="Hide" />
            ) : (
              <FormattedMessage id="common.show" defaultMessage="Show" />
            )}
          </Button>
        </div>
      </div>

      {isShowItems && props.content}
    </ContentAccordionWrapper>
  );
};
