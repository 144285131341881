import { Button, Icon, Loader } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import EmptyState from "../../components/EmptyState";
import MediaBreadCrumb from "../../components/Media/mediaBreadCrumb";
import { MediaSharedFolder } from "../../components/Media/mediaSharedFolder";
import { SHARE_BY_OTHER_FOLDER_ID } from "../../pages/Media/MediasList/mediaListHelper";
import { File, Folder, Scalars } from "../../types.g";
import { MediaFileActions } from "../Media/mediaFile";
import { MediaFolderActions } from "../Media/mediaFolder";
import MediaItems from "../Media/mediaItems";
import MediaNewFolder from "../Media/mediaNewFolder";
import { MediaTabPaneDataProps, withMediaTabPaneData } from "./apollo";
import { StyledMediaList } from "./styles";

export interface MediaTabPaneProps {
  folderId: string;
  updateFolderId: (folderId: string) => void;
  isSearch: boolean;
  query: string;
  clearSearch: () => void;
  selectedMediaItems: Partial<File>[];
  selectedMediaFileCallback?: (media: File | Folder) => void;
  onSelectMediaItems?: (mediaItems: (File | Folder)[]) => void;
  onClickMediaFileCallback: (
    mediaId: string[],
    action: MediaFileActions,
    value: Partial<File>[] | string | boolean
  ) => void;
  onClickMediaFolderCallback: (
    mediaId: string,
    action: MediaFolderActions,
    value: Partial<File>[]
  ) => void;
}
export interface MediaTabPaneState {
  isPreviewOpen: boolean;
  isOpenMediaSideBarUpload: boolean;
  isCreatingFolder: boolean;
  showCreateFolderForm: boolean;
  selectedNewFolderId: Scalars["UUID"];
  customContainer: React.ReactNode | null;
}

class MediaTabPane extends React.Component<
  MediaTabPaneProps & MediaTabPaneDataProps,
  MediaTabPaneState
> {
  public static contextType = AppContext;
  public context: AppContextType;
  public _isMounted: boolean;

  constructor(props: MediaTabPaneProps & MediaTabPaneDataProps) {
    super(props);

    this.state = {
      customContainer: null,
      isCreatingFolder: false,
      isOpenMediaSideBarUpload: false,
      isPreviewOpen: false,
      selectedNewFolderId: "",
      showCreateFolderForm: false,
    };
  }
  public componentDidMount() {
    this._isMounted = true;
  }
  public async componentWillUnmount() {
    this._isMounted = false;
  }

  public refetchMediaQuery = () => {
    const { folderByIdQuery, refetch } = this.props;
    if (folderByIdQuery) {
      refetch();
    }
  };

  public handleMediaUploadOpen = async () => {
    if (this.props.folderId) {
      const uploadedFiles = await this.context.modal.openMediaUpload(
        <FormattedMessage
          id="media.media_upload"
          defaultMessage="Media Upload"
        />,
        { folderId: this.props.folderId }
      );
      if (uploadedFiles.length > 0) {
        this.refetchMediaQuery();
      }
      this.context.modal.closeUploadModals();
    }
  };

  public onMediaBreadCrumbUpdate = async (folderId: string) => {
    const { updateFolderId } = this.props;
    updateFolderId(folderId);
  };

  public handleMediaFolderClick = (
    folderId: any,
    action: MediaFolderActions,
    value: File[] | string | boolean
  ) => {
    const { onClickMediaFolderCallback, updateFolderId } = this.props;
    switch (action) {
      case MediaFolderActions.ENTER_FOLDER:
        updateFolderId(folderId);
        break;
      case MediaFolderActions.ADD:
        if (value) {
          onClickMediaFolderCallback(folderId, action, value as File[]);
        }
        break;
      default:
    }
  };

  public onScrollToNewFolderFinish = () => {
    this.setState({ selectedNewFolderId: "" });
  };

  public onNewFolderClick = () => {
    this.setState({
      isCreatingFolder: false,
      showCreateFolderForm: true,
    });
  };

  public onNewFolderSave = async (
    e: React.SyntheticEvent<any>,
    value: string
  ) => {
    if (value.trim() === "") {
      this.setState({
        isCreatingFolder: false,
        showCreateFolderForm: false,
      });
    } else {
      const { createNewFolder, folderId, mediaFolders, refetch } = this.props;
      const parentFolderId = this.props.isInRootFolder
        ? this.context.currentSpace?.rootFolderId
        : folderId;
      this.setState({ isCreatingFolder: true });
      const resp = await createNewFolder({
        name: value,
        parentFolderId,
        currentFolders: mediaFolders,
      });
      if (resp?.data && this._isMounted) {
        refetch();
        this.setState({
          isCreatingFolder: false,
          selectedNewFolderId: resp.data!.createFolder!.folder!.id,
          showCreateFolderForm: false,
        });
      }
    }
  };

  public handleLiveUpdate = () => {
    this.props.refetchFolder();
  };

  public handleSelectMediaItems = (mediaItems: (File | Folder)[]) => {
    if (this.props.onSelectMediaItems) {
      this.props.onSelectMediaItems(mediaItems);
    }
  };

  public render() {
    const {
      isLoading,
      shouldShowSharedFolder,
      updateFolderId,
      folderPathItems,
      selectedMediaItems,
      mediaFiles,
      mediaFolders,
      isSearch,
      isInSharedFolderFromOtherSpaces,
      loadMore,
      hasNextPage,
      showAddToPlaylists,
    } = this.props;

    const canCreateMedia =
      !isInSharedFolderFromOtherSpaces &&
      this.context.currentPermissions.validateCurrentSpace("media", "create");
    const hasItems = mediaFiles.length > 0 || mediaFolders.length > 0;

    return (
      <StyledMediaList>
        <div className="media-breadcrumb-container">
          <div className="breadcrumb-sidebar">
            <MediaBreadCrumb
              libraryName="Library"
              canCreateFolder={canCreateMedia}
              onUpdate={this.onMediaBreadCrumbUpdate}
              action={false}
              folderPathItems={folderPathItems}
              maxFolderPath={1}
              onClickAddNewFolder={this.onNewFolderClick}
            />
          </div>
          {canCreateMedia && (
            <Button
              className="upload-media"
              mini
              icon
              onClick={this.handleMediaUploadOpen}
              data-testid="upload-media-button"
            >
              <Icon name="plus" />
            </Button>
          )}
        </div>
        <div
          className="layout-container"
          id="sidebar-right"
          ref={(el) => {
            if (el && !this.state.customContainer) {
              this.setState({ customContainer: el });
            }
          }}
        >
          <div className="layout-list">
            {this.state.showCreateFolderForm && (
              <MediaNewFolder
                onSaved={this.onNewFolderSave}
                isCreating={this.state.isCreatingFolder}
              />
            )}
            {shouldShowSharedFolder && (
              <MediaSharedFolder
                isCompactLayout={true}
                isMediaPicker={true}
                onClick={() => updateFolderId(SHARE_BY_OTHER_FOLDER_ID)}
              />
            )}
            {isLoading ? (
              <div className="mediatab-loader">
                <Loader inline size="small" />
              </div>
            ) : (
              <>
                {hasItems && (
                  <InfiniteScroll
                    style={{ overflow: "none", height: "none" }}
                    dataLength={mediaFiles.length + mediaFolders.length}
                    next={loadMore}
                    scrollableTarget="sidebar-right"
                    hasMore={hasNextPage}
                    loader={
                      <div className={`layout-list`} key="loading-message" />
                    }
                  >
                    <MediaItems
                      customContainer={this.state.customContainer}
                      files={mediaFiles}
                      folders={mediaFolders}
                      selectedMediaItems={selectedMediaItems as File[]}
                      scrollToMediaId={this.state.selectedNewFolderId}
                      isDraggableFile={true}
                      isDraggableFolder={true}
                      isDroppableFolder={false}
                      isCompactLayout={true}
                      canAddFileItem={true}
                      canDeleteFileItem={false}
                      canSelectFile={true}
                      canPreview={true}
                      isGridMode={false}
                      showFavorite={false}
                      showActionMenu={false}
                      showAddToPlaylists={showAddToPlaylists}
                      useReactDnD={false}
                      isMultiSelect={true}
                      showTags={true}
                      onSelectMediaItems={this.handleSelectMediaItems}
                      onScrollToNewFolderFinish={this.onScrollToNewFolderFinish}
                      onMediaFolderCallback={this.handleMediaFolderClick}
                      onMediaFileCallback={this.props.onClickMediaFileCallback}
                      selectedMediaFileCallback={
                        this.props.selectedMediaFileCallback
                      }
                      onLiveUpdateCallback={this.handleLiveUpdate}
                      targetContainerId="sidebar-right"
                    />
                  </InfiniteScroll>
                )}
                {!hasItems && isSearch && !isLoading && (
                  <EmptyState section="media-sidebar" cover={false}>
                    <h3>
                      <FormattedMessage
                        id="common.text.no_result_found"
                        defaultMessage="No results foundy"
                      />
                    </h3>
                  </EmptyState>
                )}
                {!hasItems &&
                  isSearch &&
                  !shouldShowSharedFolder &&
                  !isLoading && (
                    <EmptyState
                      section="media-sidebar"
                      cover={false}
                      className="empty"
                    >
                      <h3>No content uploaded yet</h3>
                      {canCreateMedia && (
                        <p>Upload your content to add to this playlist</p>
                      )}
                    </EmptyState>
                  )}
              </>
            )}
          </div>
        </div>
      </StyledMediaList>
    );
  }
}

export default withMediaTabPaneData(
  MediaTabPane
) as React.ComponentType<MediaTabPaneProps>;
