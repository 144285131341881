import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledAppinstall = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .app-section {
    align-items: center;
    display: flex;
    font-weight: 700;
    height: 46px;
    margin: 0 !important;
    padding: 0 20px 10px;

    .ui.breadcrumb {
      display: flex;
      align-items: center;
      line-height: 1.2;
    }

    .add-appinstance {
      margin-left: auto;
    }
  }

  .layout-container {
    position: relative;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    flex: 1 0 0;

    > div {
      height: 100%;
    }
  }

  .app-loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app-instance-list {
    height: 100%;
    list-style: none;
    padding-left: 0;
    padding-bottom: 55px;
    .app-item {
      padding: 0 20px;
    }
    .app-instance {
      display: flex;
      padding: 10px 0;
      border-top: 1px solid ${Theme.color.silver};
      cursor: pointer;
      align-items: center;
      gap: 10px;

      .canvas-orientation {
        font-size: 14px;
        color: #757576 !important;
      }

      :hover {
        background-color: rgba(0, 0, 0, 0.01);
      }

      .app-instance-thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        &:hover {
          .overlay {
            opacity: 0.5;
          }
        }

        .thumbnail {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          width: 50px;
          height: 40px;
          max-width: 50px;
          max-height: 40px;
          .wrapper {
            position: initial;
          }
        }

        .overlay {
          position: absolute;
          background: gray;
          width: 40px;
          height: 40px;
          opacity: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          ${Theme.default.borderRadius};
          transition: 0.3s;
        }
      }

      .app-instance-info {
        font-size: 14px;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .app-instance-title {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .app-instance-type {
            text-transform: lowercase;
            font-style: italic;
            color: var(--color-grey70);
          }
        }
      }
    }
  }
  .app-install-item {
    background: ${Theme.color.white};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease-out;
    padding: 10px 0;
    margin-bottom: 10px;
    height: 80px;
    display: flex;
  }
`;
export const StyledDraggableWrapper = styled.div`
  > div:first-child + div + div {
    display: none !important;
  }
`;

export const Styled = styled.div`
  height: 100%;

  .empty-app > p {
    font-size: 0.875em;
    color: rgba(31, 32, 39, 0.5);
  }
`;
