import { Component } from "react";
import { LayoutContainer } from "./styles";

class Layout extends Component<{}, {}> {
  public static Actions = ({ children }) => (
    <div className="actions-grid-section">{children}</div>
  );
  public static Navigator = ({ children }) => (
    <div className="navigator-grid-section">{children}</div>
  );
  public static Controls = ({ visible, children }) => (
    <div className={`controls-grid-section ${visible ? "visible" : "hidden"}`}>
      {children}
    </div>
  );
  public static Pane = ({ visible, children }) => (
    <div className={`pane-grid-section ${visible ? "visible" : "hidden"}`}>
      {children}
    </div>
  );
  public static Configuration = ({ children }) => (
    <div className="configuration-grid-section">{children}</div>
  );

  public render() {
    return <LayoutContainer>{this.props.children}</LayoutContainer>;
  }
}

export default Layout;
