/**
 * Feature flags and its description is in here.
 * https://www.notion.so/screencloud/8962592a58694e7a842b9a5275f35c3b?v=7ff0ba447d9d41fabd5892ab292ee27f
 *
 * To update feature flags locally, you need to use management console frontend.
 * https://github.com/screencloud/management-console-frontend
 */
export enum FEATURE_FLAGS_ENUM {
  ORG_ALLOW_SOFT_DELETE_SELF = "org_allow_soft_delete_self",
  CANVAS = "canvas",
  CASTING = "casting",
  CHANNEL_TRANSITION = "channel_transition",
  CLOUD_RENDERING = "cloud_rendering",
  CREDENTIALS_SETTINGS = "credentials_settings",
  DELETE_ORG = "delete_org",
  DUPLICATE_SPACE = "duplicate_space",
  DUPLICATE_CONTENT = "duplicate_content",
  EMBED_PLAYER = "embed_player",
  ENGAGE = "engage",
  HIDDEN_ZONES = "channel_zone_hidden",
  TOUCH = "app_touch",
  IS_COMMON_APP_INSTANCE_TEMPLATE = "is_common_app_instance_template_source",
  IS_ENTERPRISE_CUSTOMER = "is_enterprise_customer",
  INVOICE_PAYMENT = "invoice_payment",
  LANGUAGE = "language",
  LINK_CREDENTIAL = "link_credential",
  ONBOARDING = "onboarding",
  ORG_CREATION_REQUIRES_KEY = "org_creation_requires_key",
  PREMIUM_ADS = "premium_ads",
  SCREEN_ENROLLMENT = "screen_enrollment",
  SCREENSHOT = "screenshot",
  SECURE_DASHBOARDS = "secure_dashboards",
  /**
   * is_secure_sites_customer: this determines whether the upsell page is shown or
   * whether the user can get access to view and create sites etc.
   * Credit: Rober newman
   */
  IS_SECURE_SITES_CUSTOMER = "is_secure_sites_customer",
  SHARING = "sharing",
  SPACE_BASED_BILLING = "space_based_billing",
  BILLING_BETA = "billing_beta",
  SSO = "sso",
  STARRING = "starring",
  WHITE_LABEL = "white_label",
  UNRESTRICTED_TRIAL = "unrestricted_trial",
  CUSTOM_PERMISSION_SETS = "custom_permission_sets",
  /**
   * This is said to always be True now and it seems we not really use it anywhere.
   * I think we might use this somewhere layer when we explicitly need it.
   */
  SITES_MENU = "sites_menu",
  LINKS_MENU = "links_menu",
  VIDEO_SUPPORT_4K = "video_support_4k",
  CUSTOM_FONT = "custom_font",
  PLAYBACK_LOGS = "playback_logs",
  MAINTENANCE = "maintenance",
  RESELLER = "reseller",
  QRCODE_METRICS = "qrcode_metrics",
  RECYCLE_BIN = "recycle_bin",
  SCREEN_PLAYBACK_MODE = "screen_playback_mode",
  SCREENS_MANAGER = "screens_manager",
  SECURE_MEDIA_URLS = "secure_media_urls",
  MUTE_AUDIO = "mute_audio",
  SNAPSHOT_CONTENT = "snapshot_content",
  IMPORT_CONTENT = "import_content",
  RDM_DASHBOARD = "rdm_dashboard",
  FULL_AUDIT_LOGS = "full_audit_logs",
  SCREEN_NOTIFICATIONS = "screen_notifications",
  PLAYLIST_DEFINITIONS = "playlist_definitions",
  UNSUB_GAINSIGHT = "unsub_gainsight",
  KC_BOT = "kc_bot",
  STUDIO_DEV = "studio_dev",
  APP_PRO_PLAN = "app_pro_plan",
  BILLING_MAINTENANCE = "billing_maintenance",
  AI_FEATURES = "ai_features",
}

export type CurrentFeatureFlag = string[];
