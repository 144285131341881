import * as React from "react";
import {
  AvailableAppInstanceFragment,
  File,
  Folder,
  Link,
  Site,
} from "../../types.g";
import { AppTabPaneItemActions } from "../AppTabPane/appItem";
import { LinkListItemActions } from "../LinkListItem";
import { MediaFileActions } from "../Media/mediaFile";
import { MediaFolderActions } from "../Media/mediaFolder";
import { SiteListItemActions } from "../SiteListItem/searchItem";
import AllSearchList from "./allsearchlist";
import { ApolloProps, withData } from "./apollo";

export interface SearchTabPaneProps {
  query: string;
  selectedMediaFileCallback?: (media: File | Folder) => void;
  onClickMediaFileCallback: (
    mediaId: string[],
    action: MediaFileActions,
    value: Partial<File>[] | string | boolean
  ) => void;
  onClickMediaFolderCallback: (
    mediaId: string,
    action: MediaFolderActions,
    value: Partial<File>[]
  ) => void;
  onClickLinkCallback: (
    action: LinkListItemActions,
    value: Partial<Link> | string | boolean,
    event?: React.SyntheticEvent
  ) => void;
  selectedLinkCallback: (link: Partial<Link>) => void;
  onClickSiteCallback: (
    action: SiteListItemActions,
    value: Partial<Site> | string | boolean,
    event?: React.SyntheticEvent
  ) => void;
  selectedSiteCallback: (link: Partial<Site>) => void;
  addItemToPlaylist: (
    app: AvailableAppInstanceFragment,
    action: AppTabPaneItemActions
  ) => void;
  shouldRefetchingSidebar?: boolean;
  onRefetchingFinish?: () => void;
}

class SearchTabPane extends React.Component<
  SearchTabPaneProps & ApolloProps,
  {}
> {
  constructor(props: SearchTabPaneProps & ApolloProps) {
    super(props);
  }

  public render() {
    return (
      <AllSearchList
        searchTerms={this.props.query}
        sidebarData={this.props.data}
        sidebarDataLoading={this.props.loading}
        sidebarRefetch={this.props.sidebarRefetch}
        loadMore={this.props.loadMore}
        selectedMediaFileCallback={this.props.selectedMediaFileCallback}
        onClickMediaFileCallback={this.props.onClickMediaFileCallback}
        onClickMediaFolderCallback={this.props.onClickMediaFolderCallback}
        selectedLinkCallback={this.props.selectedLinkCallback}
        onClickLinkCallback={this.props.onClickLinkCallback}
        selectedSiteCallback={this.props.selectedSiteCallback}
        onClickSiteCallback={this.props.onClickSiteCallback}
        addItemToPlaylist={this.props.addItemToPlaylist}
      />
    );
  }
}

export default withData(
  SearchTabPane
) as React.ComponentType<SearchTabPaneProps>;
