import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import { ThemePreviewProps } from "./index";

export const Styled = styled.div<ThemePreviewProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${(props: ThemePreviewProps) => props.primaryColor};
  width: 100%;
  height: 100%;
  padding-left: calc(2.5vw);
  padding-right: calc(1vw);
  font-size: calc(0.05 * 100vw);

  .title {
    span {
      font-family: ${(props: ThemePreviewProps) => props.headingFont?.family};
    }
    font-family: ${(props: ThemePreviewProps) => props.bodyFont?.family};
    margin-bottom: 3px;
    color: ${(props: ThemePreviewProps) => props.titleColor};
    font-size: 100%;
  }
  .wrapper {
    max-width: fit-content;
  }
  .body {
    span {
      font-family: ${(props: ThemePreviewProps) => props.bodyFont?.family};
    }
    font-family: ${(props: ThemePreviewProps) => props.bodyFont?.family};
    margin-bottom: 10px;
    color: ${(props: ThemePreviewProps) => props.bodyColor};
    font-size: 45%;
  }
  .horizontal-bar {
    width: 100%;
    height: calc(0.7vw);
    border-radius: 5px;
    background-color: ${(props: ThemePreviewProps) => props.secondaryColor};
  }
  .thumbnail {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: calc(9vw);
    margin: 8px;
    max-height: 80px;
  }
  @media (max-width: ${Theme.break.small}) {
    .thumbnail {
      margin: 8px;
      max-height: 70px;
    }
  }
  @media (min-width: ${Theme.break.medium}) {
    .title {
      font-size: 36px;
    }
    .body {
      font-size: 18px;
    }
    .thumbnail {
      max-height: 50px;
      max-width: calc(8vw);
    }
  }
  @media (max-width: ${Theme.break.mediumAlt}) {
    .thumbnail {
      max-height: 100px;
      max-width: calc(9vw);
    }
  }
  @media (min-width: ${Theme.break.large}) {
    .title {
      font-size: 44px;
    }
    .body {
      font-size: 24px;
    }
    .thumbnail {
      max-height: 75px;
    }
  }
`;
