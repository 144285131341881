import {
  DataValue,
  withApollo,
  WithApolloClient,
} from "@apollo/client/react/hoc";
import { MutationResult } from "@apollo/client/react/types/types";
import { useMedialist, UseMediaList } from "../../hooks/useMediaList";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FolderPathItem } from "../../components/Media/mediaBreadCrumb";
import { isRootFolderInCurrentSpace } from "../../helpers/folderHelper";
import { useAppContext } from "../../hooks/useAppContext";
import { isInRootSharedFolderFromOtherSpaces as isInRootSharedFolderFromOtherSpacesFunc } from "../../pages/Media/MediasList/mediaListHelper";
import { useBreadCrumbFolderPathItems } from "../../pages/Media/MediasList/useBreadCrumbFolderPathItems";
import {
  CreateNewFolderVariables,
  useCreateNewFolderMutation,
} from "../../pages/Media/MediasList/useCreateNewFolderMutation";
import {
  CreateFileMutationFn,
  CreateFolderMutation,
  FolderByIdQuery,
  FilesOrderBy,
  FolderByIdQueryVariables,
  useCreateFileMutation,
  FoldersOrderBy,
} from "../../types.g";
import { compose } from "../../utils/compose";
import { useAddAppsToPlaylistsMutation } from "../../pages/Apps/AppInstances/hooks/useAddAppsToPlaylist";

export interface MediaTabPaneDataProps
  extends RouteComponentProps<any>,
    WithApolloClient<any>,
    UseMediaList {
  query: string;
  folderId: string;
  createFile: CreateFileMutationFn;
  shouldShowSharedFolder: boolean;
  isSearch: boolean;
  folderPathItems: FolderPathItem[];
  folder?: DataValue<FolderByIdQuery, FolderByIdQueryVariables>;
  createNewFolder: (
    params: CreateNewFolderVariables
  ) => MutationResult<CreateFolderMutation>;
  showAddToPlaylists: boolean;
}

export const withMediaTabPaneData = compose(
  withRouter,
  withApollo,
  (Component) => (props: MediaTabPaneDataProps) => {
    const context = useAppContext();
    const { folderId, isSearch, query } = props;
    const isInRootSharedFolderFromOtherSpaces = isInRootSharedFolderFromOtherSpacesFunc(
      folderId
    );
    const isInRootFolder = isRootFolderInCurrentSpace({ folderId, context });
    const mediaListProps = useMedialist({
      query,
      isInRootSharedFolderFromOtherSpaces,
      isSearchMode: isSearch,
      isInRootFolder,
      folderId,
      filesOrderBy: [FilesOrderBy.NameAsc],
      foldersOrderBy: [FoldersOrderBy.NameAsc],
    });
    const folderPathItems = useBreadCrumbFolderPathItems({
      folderId,
      isSearch,
      isInRootFolder,
      isInRootSharedFolderFromOtherSpaces,
      isInSharedFolderFromOtherSpaces:
        mediaListProps.isInSharedFolderFromOtherSpaces,
    });
    const [createFile] = useCreateFileMutation();
    return (
      <Component
        {...props}
        createFile={createFile}
        folderPathItems={folderPathItems}
        {...mediaListProps}
      />
    );
  },
  (Component) => (props: MediaTabPaneDataProps) => {
    const { createNewFolder } = useCreateNewFolderMutation();
    return <Component {...props} createNewFolder={createNewFolder} />;
  },
  (Component) => (props: MediaTabPaneDataProps) => {
    const showAddToPlaylists = useAddAppsToPlaylistsMutation();
    return <Component {...props} showAddToPlaylists={showAddToPlaylists} />;
  }
);
