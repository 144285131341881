import * as React from "react";
import { PopupProps } from "semantic-ui-react";
import { DashboardPopoverHeader, DashboardPopoverContent } from "./styles";
import { Popover } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";

export interface DashboardPopoverProps extends PopupProps {
  handleDismiss?: () => void;
}

class DashboardPopover extends React.Component<DashboardPopoverProps, {}> {
  constructor(props: DashboardPopoverProps) {
    super(props);
  }

  getoffset = () => {
    if (typeof this.props.offset === "number") return this.props.offset;
    else return 0;
  };

  public render() {
    return (
      <Popover
        inverted
        wide="very"
        basic={false}
        offset={this.getoffset()}
        position={this.props.position}
        handleDismiss={this.props.handleDismiss}
        open={this.props.open}
        context={this.props.context}
        header={
          <DashboardPopoverHeader>
            <h3>{this.props.header}</h3>
          </DashboardPopoverHeader>
        }
        content={
          <DashboardPopoverContent>
            {this.props.content}
            {this.props.handleDismiss && (
              <div className="dismiss">
                <a onClick={this.props.handleDismiss}>
                  <FormattedMessage
                    id="common.text.dismiss"
                    defaultMessage="Dismiss"
                  />
                </a>
              </div>
            )}
          </DashboardPopoverContent>
        }
      />
    );
  }
}

export default DashboardPopover;
