import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

import { getContrastYIQ, WhiteLabelStyles } from "../../../helpers/whiteLabel";

interface StyledProps {
  isMultipleOrgs: boolean | undefined;
}

export const Styled = styled.header<StyledProps & WhiteLabelStyles>`
  align-items: center;
  background: ${(props) => props.bgColor ?? `var(--color-gray-950)`};
  display: flex;
  justify-content: center;
  height: ${Theme.admin.header.desktopHeight};

  .back,
  .who {
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    max-width: 240px;
  }

  .back {
    max-width: none;
    margin-right: auto;
    flex: 1;
    justify-content: flex-start;
    min-width: auto;

    .signage {
      align-items: center;
      color: ${(props) =>
        props.bgColor
          ? getContrastYIQ(props.bgColor)
          : `var(--color-white-100)`};
      display: flex;
      height: 100%;
      padding-left: ${Theme.admin.desktopHorizontalPadding};
      padding-right: ${Theme.admin.desktopHorizontalPadding};
      width: 180px;

      i {
        background-color: ${(props) =>
          props.bgColor
            ? getContrastYIQ(props.bgColor)
            : `var(--color-white-100)`};
        height: 22px;
        width: 22px;
      }

      > span {
        font-weight: bold;
        margin-left: 10px;
        user-select: none;
      }
    }
    .signage:hover {
      background-color: ${(props) => props.activeItemColor ?? "rgba(0,0,0,.5)"};
      color: ${(props) => props.activeTextColor ?? Theme.color.white};

      > i {
        background-color: ${(props) =>
          props.activeTextColor ?? Theme.color.white};
      }
    }
  }

  .orgs {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    cursor: ${(props) => (props.isMultipleOrgs ? "pointer" : "default")};
    background: ${(props) =>
      props.bgColor ? `var(--color-black-10)` : `var(--color-black-50)`};
    ${Theme.default.borderRadius};
    width: 300px;
    padding: 10px 15px;
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    i {
      right: 10px;
      position: absolute;
      opacity: 0.5;
    }

    ${(props) =>
      props.isMultipleOrgs &&
      `
      border: 1px solid var(--color-white-20);

      :hover {
        background: ${
          props.bgColor ? `var(--color-black-20)` : `var(--color-black-80)`
        };
        border: 1px solid var(--color-white-50);
        i {
          opacity: 1;
        }
      }
    `}
    .org-core {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      user-select: none;
      .label {
        color: ${(props) =>
          props.textColor
            ? `var(--color-primary-foreground-active)`
            : `var(--color-white-100)`};
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 16px;
        text-transform: uppercase;
        opacity: 0.5;
      }
      .selected-org {
        text-align: center;
        color: ${(props) =>
          props.textColor
            ? `var(--color-primary-foreground-active)`
            : `var(--color-white-100)`};
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        width: calc(100% - 42px);
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .right-section {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-left: auto;
    .who {
      color: ${(props) =>
        props.bgColor
          ? getContrastYIQ(props.bgColor)
          : `var(--color-white-100)`};
      .dropdown {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 20px;

        > .menu {
          right: 10px;
        }
      }
      :hover {
        background-color: ${(props) =>
          props.activeItemColor ?? "rgba(0,0,0,.5)"};
        color: ${(props) => props.activeTextColor ?? `var(--color-white-100)`};
      }
    }
    .user {
      align-items: center;
      display: flex;
      font-weight: bold;
      justify-content: flex-end;
      width: 100%;
      user-select: none;

      > div {
        flex-grow: 1;
        margin-right: 8px;
        max-width: calc(100% - 40px);
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
        white-space: nowrap;
      }

      .avatar {
        max-width: none;
        text-align: center;
        flex-shrink: 0;
        margin-right: 0;
      }
    }
  }

  .upgrade {
    button {
      min-width: unset;
    }
  }

  //
  // Responsive
  //
  @media (max-width: ${Theme.break.mediumAlt}) {
    .orgs {
      margin: 0;
    }
    .back,
    .who {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-start;
      width: 80px;
    }
    .upgrade {
      display: none;
    }

    .back {
      .signage {
        width: 80px;
        i {
          margin: 0 auto;
        }
        > span {
          display: none;
        }
      }
    }

    .right-section {
      .who {
        .user > div {
          margin: 0;

          &:first-of-type {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: ${Theme.break.small}) {
    height: 50px;

    .orgs {
      border-radius: 0;
      border: none;
      width: calc(100% - 96px);
      max-width: 300px;

      :hover {
        border: none;
      }
    }

    .back,
    .who {
      height: 100%;
      width: 48px;

      > .ui.dropdown {
        .menu {
          margin: 0;
          border-radius: 0;
          border: 0;
          right: 0;
          width: 100vw;
        }
      }
    }

    .who {
      .ui.dropdown {
        padding: 0;
        width: 100%;

        .user {
          height: 100%;
          justify-content: center;

          > div {
            flex-grow: 0;
          }
        }
      }
    }

    .back {
      .signage {
        padding: 0;
        width: 48px;
      }
    }
  }
`;
