import { Button, Dropdown, Icon } from "@screencloud/screencloud-ui-components";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { SiteListType } from "../../pages/Sites/list";
import { LinkListHeaderActions } from "../LinkListHeader";
import { Styled } from "./styles";

export enum SiteListHeaderActions {
  SORT_BY_DATE = "SORT_BY_DATE",
  SORT_BY_NAME = "SORT_BY_NAME",
  SORT_BY_STARRED_ASC = "SORT_BY_STARRED_ASC",
  SORT_BY_STARRED_DESC = "SORT_BY_STARRED_DESC",
}

export interface Props {
  viewType: string;
  sortBy: string;
  showSortButton: boolean;
  listType: SiteListType;
  showSecureSiteColumns: boolean;
  callback: (action: SiteListHeaderActions) => void;
}

export interface State {}

const sortingDropdown = [
  {
    key: LinkListHeaderActions.SORT_BY_NAME,
    text: (
      <FormattedMessage
        id="ui_component.common.label.name"
        defaultMessage="Name"
      />
    ),
    value: LinkListHeaderActions.SORT_BY_NAME,
  },
  {
    key: LinkListHeaderActions.SORT_BY_DATE,
    text: (
      <FormattedMessage
        id="ui_component.common.label.latest"
        defaultMessage="Latest"
      />
    ),
    value: LinkListHeaderActions.SORT_BY_DATE,
  },
];

class SiteListHeader extends Component<Props, State> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public handleSortingDropdown = (event, data) => {
    this.props.callback(data.value);
  };

  public getHeaderTitle() {
    let title: string = "Sites";
    switch (this.props.listType) {
      case SiteListType.FEATURED:
        title = "Featured Sites";
        break;
      case SiteListType.SHARED:
        title = "Shared Sites";
        break;
      case SiteListType.SPACE:
        title = "Sites";
        break;
    }

    return (
      <div className="title-wrapper">
        <div className="column-title">
          <h2 data-cy="header-title" data-testid="header-title">
            <FormattedMessage id="sites.main_menu" defaultMessage={title} />
          </h2>
        </div>
      </div>
    );
  }

  public handleColumnStarredClick = () => {
    // Not implemented yet...
  };

  public render(): JSX.Element {
    return (
      <Styled showSecureSiteColumns={this.props.showSecureSiteColumns}>
        <div className="list-container">
          <div className="site-core">
            <div className="site-name">
              <Dropdown
                inline
                options={sortingDropdown}
                defaultValue={sortingDropdown[0].key}
                onChange={this.handleSortingDropdown}
              />
            </div>
            {this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.STARRING) && (
              <div className="site-starred">
                <Button
                  transparent
                  onClick={() => this.handleColumnStarredClick()}
                >
                  <FormattedMessage
                    id="ui_component.common.label.starred"
                    defaultMessage="Starred"
                  />
                  {this.props.sortBy ===
                    SiteListHeaderActions.SORT_BY_STARRED_ASC && (
                    <Icon name="caret-down" />
                  )}
                  {this.props.sortBy ===
                    SiteListHeaderActions.SORT_BY_STARRED_DESC && (
                    <Icon name="caret-up" />
                  )}
                </Button>
              </div>
            )}
            {this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) && (
              <div className="site-casting">
                <FormattedMessage
                  id="ui_component.common.label.casting"
                  defaultMessage="Casting"
                />
              </div>
            )}
            <div className="site-type">
              <FormattedMessage
                id="ui_component.common.label.type"
                defaultMessage="Type"
              />
            </div>
            <div className="site-compute">
              <FormattedMessage
                id="ui_component.common.label.compute"
                defaultMessage="Compute"
              />
            </div>
            <div className="site-tags">
              <FormattedMessage
                id="ui_component.common.label.tags"
                defaultMessage="Tags"
              />
            </div>
            <div className="site-share">
              <FormattedMessage
                id="ui_component.common.label.share"
                defaultMessage="Share"
              />
            </div>
          </div>
        </div>
      </Styled>
    );
  }
}

export default SiteListHeader;
