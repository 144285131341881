import { QueryResult } from "@apollo/client/react/types/types";
import { orderBy } from "lodash";
import { ssm } from "../../state/session/ssm";
import {
  Cast,
  CastsBySpaceIdQuery,
  CastsBySpaceIdQueryVariables,
  CastStopMutationFn,
  useCastsBySpaceIdQuery,
  useCastStopMutation,
} from "../../types.g";
import { compose } from "../../utils/compose";

export interface ApolloProps {
  data: QueryResult<CastsBySpaceIdQuery, CastsBySpaceIdQueryVariables>;
  castStop: CastStopMutationFn;
  casts: Cast[];
}

export const withData = compose((Component) => (props: ApolloProps) => {
  const [castStop] = useCastStopMutation();
  const castBySpaceId = useCastsBySpaceIdQuery({
    variables: { spaceId: ssm.current.settings.spaceId },
    fetchPolicy: "cache-and-network",
  });

  const casts = (castBySpaceId.data?.spaceById?.castsBySpace?.nodes ??
    []) as Cast[];
  const orderedCasts = orderBy(casts, ["createdAt"], ["desc"]);

  return <Component {...props} casts={orderedCasts} castStop={castStop} />;
});
