import { useState } from "react";
import {
  MAXIMUM_FETCH_MORE_ITEMS,
  MINIMUM_FETCH_MORE_ITEMS,
} from "src/constants/constants";

interface UseLoadMore {
  loadMore: () => void;
  isLoading: boolean;
}

export function useLoadMore(callback, endCursor: string): UseLoadMore {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);

  const loadMore = () => {
    if (!isLoading) {
      setIsLoading(true);
      callback({
        variables: {
          first:
            fetchCount > 1
              ? MAXIMUM_FETCH_MORE_ITEMS
              : MINIMUM_FETCH_MORE_ITEMS,
          endCursor,
        },
      }).then(() => {
        setIsLoading(false);
        setFetchCount(fetchCount + 1);
      });
    }
  };

  return { loadMore, isLoading };
}
