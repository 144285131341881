import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import { Form } from "../../form";

export const Styled = styled(Form)`
  padding: 0px;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  .form {
    flex-basis: 0;
    flex-grow: 1;
  }

  .theme-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
  }
  .theme-row:not(:first-child) {
    margin-top: 28px;
  }
  .theme-column {
    display: flex;
    flex-direction: column;
    .desc {
      color: #4c4d52;
      font-size: 14px;
    }
    h5.logo-title {
      display: flex;
      align-items: center;
      i {
        margin-left: 5px;
        background-color: ${Theme.color.darkGrey};
      }
    }
  }
  .theme-column:last-child {
    flex: 0.55;
  }

  .theme-text-column {
    padding-right: 20px;
    flex: 0.45;
  }

  .buttons {
    text-align: right;
    padding-left: 30px;
    padding-right: 30px;
  }
  .image {
    align-items: center;
    display: flex;
    &-thumb {
      width: 50px;
      height: 50px;
      position: relative;
    }
    &-remove {
      margin-left: 10px;
      font-size: 14px;
      flex-grow: 1;
      span {
        color: ${Theme.color.blue};
        cursor: pointer;
      }
    }
  }
  .picker {
    background: ${Theme.color.white};
    ${Theme.default.borderRadius};
    align-items: center;
    display: flex;
    .swatch {
      flex-basis: 0;
      flex-grow: 1;
      box-shadow: none;
      display: block;
      .color {
        width: 35px;
        height: 35px;
        border: solid 1px ${Theme.color.lightGrey};
      }
    }
    .hex {
      flex-grow: 2;
      padding-left: 12px;
      font-size: 16px;
      color: ${Theme.color.darkGrey};
      display: block;
    }
    div {
      width: 100%;
    }
    input {
      width: 100% !important;
    }
  }
  .colors {
    display: flex;
    flex-direction: column;
    > div {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
  .fonts {
    > .dropdown {
      margin-bottom: 24px;
      .menu > .item {
        border: none;
        &.custom-divider {
          border-top: 1px solid ${Theme.color.silver};
        }
      }
    }
    > .input {
      margin-bottom: 10px;
    }
  }
  .label {
    margin-bottom: 10px;
  }
  .colors,
  .fonts {
    .label {
      font-size: 14px;
      font-weight: 400;
      color: ${Theme.color.grey};
    }
  }
  .colour {
    margin-bottom: 24px;
  }
  .color-label-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .tooltip {
    display: inline-block;
    flex-direction: column;
    font-style: italic;
    a {
      cursor: pointer;
      color: ${Theme.color.grey};
      font-size: 14px;
    }
  }
  .popover-icon {
    align-items: center;
    margin-left: 5px;
    vertical-align: middle;
  }

  //
  // Responsive
  //
  @media (max-width: ${Theme.break.largeHD}) {
    .theme-row {
      flex-wrap: wrap;
      flex-direction: column;

      .theme-column {
        width: 100%;
        flex: 1;
      }
    }
    .fonts > .dropdown {
      margin-bottom: 16px;
    }
    .colour {
      margin-bottom: 16px;
    }
    .theme-row:not(:first-child) {
      margin-top: 40px;
    }
    .theme-row:last-child {
      margin-top: 24px;
    }
    .desc {
      margin-bottom: 16px;
    }
    .colors,
    .fonts {
      .label {
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: ${Theme.break.medium}) {
    .theme-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex: 1;
    }
  }

  @media (max-width: ${Theme.break.small}) {
    .theme-row {
      flex-wrap: wrap;
      flex-direction: column;

      .theme-column {
        width: 100%;
        flex: 1;
      }
    }
  }

  @media (max-width: ${Theme.break.tiny}) {
    .colors {
      flex-direction: column;
      > div {
        margin-left: 0 !important;
        margin-right: 0 !important;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
`;
