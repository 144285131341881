import { isUuid, UUID } from "@screencloud/uuid";
import { FilesOrderBy, FoldersOrderBy } from "../../../types.g";
import { useFolderById } from "./useFolderById";
import { useFolderByIdFilterByMimeType } from "./useFolderByIdFilterByMimeType";
import {
  getSortOption,
  SortingEntityType,
  SortOption,
} from "src/helpers/sortingHelper";
interface Props {
  folderId: string;
  isSearch: boolean;
  allowMediaMimeType: string[] | undefined;
  filesOrderBy?: FilesOrderBy[];
  foldersOrderBy?: FoldersOrderBy[];
  spaceId: UUID;
}

export function useFolderByIdForMediaList(props: Props) {
  const {
    folderId,
    isSearch,
    allowMediaMimeType,
    filesOrderBy,
    foldersOrderBy,
    spaceId,
  } = props;
  const isFilteredByMimeTypes = (allowMediaMimeType ?? []).length > 0;
  const mediaOrderBy = getSortOption({
    id: folderId,
    entityType: SortingEntityType.Media,
  }) ?? [SortOption.RECENT];
  const folderByIdProps = useFolderById({
    folderId,
    skip: !isUuid(folderId) || isSearch || isFilteredByMimeTypes,
    filesOrderBy: (mediaOrderBy as FilesOrderBy[]) ?? filesOrderBy,
    foldersOrderBy: (mediaOrderBy as FoldersOrderBy[]) ?? foldersOrderBy,
    spaceId,
  });
  const folderByIdFilterByMimeProps = useFolderByIdFilterByMimeType({
    folderId,
    allowMediaMimeType,
    skip: !isUuid(folderId) || isSearch || !isFilteredByMimeTypes,
    filesOrderBy: (mediaOrderBy as FilesOrderBy[]) ?? filesOrderBy,
    foldersOrderBy: (mediaOrderBy as FoldersOrderBy[]) ?? foldersOrderBy,
  });
  return isFilteredByMimeTypes ? folderByIdFilterByMimeProps : folderByIdProps;
}
