import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
  InlineInput,
  Popover,
} from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { Site, SiteFragment, SiteType } from "../../types.g";
import { Styled } from "./style";
import { getShareButtonContent, isShared } from "src/helpers/shareableHelper";
import { renderCastingStatus } from "src/helpers/castingHelper";
import { useAppContext } from "src/hooks/useAppContext";
import { AppContextState } from "src/AppContextProvider";

export interface SiteCardProps {
  id: string;
  thumbnail: string | null | undefined;
  name: string;
  canDelete: boolean;
  canCast: boolean;
  canShare: boolean;
  canUpdate: boolean;
  site: SiteFragment;
  castSiteCallback: () => void;
  shareSiteCallback: (site: Site) => void;
  siteType: SiteType;
  deleteSiteCallback: () => void;
  onClickCallback: () => void;
  onSaveSiteNameCallback: (name: string) => void;
  onUpdateSiteClick: (site: Site) => void;
}

export const SiteCard = (props: SiteCardProps) => {
  const context = useAppContext();
  const isSharedSite = isShared(props.site);
  const casts = props.site.castedScreenBySiteId?.nodes ?? [];

  return (
    <Styled onClick={props.onClickCallback}>
      <div className="site-thumbnail">
        {props.thumbnail ? (
          <img src={props.thumbnail} alt="Site thumbnail" />
        ) : (
          <Icon name="app" className="site-icon" />
        )}
      </div>
      <main className="site-info">
        <div className="site-title">
          <h3>
            <InlineInput
              value={props.name}
              onSaved={(_, value) => props.onSaveSiteNameCallback(value)}
              placeholder={props.name}
              showIcon
              disabled={!props.canUpdate}
            />
          </h3>
        </div>
        <div className={"site-icons"}>
          <div className="site-casting">
            {renderCastingStatus({
              castScreenData: casts,
              callBack: props.castSiteCallback,
              context,
              hideScreencount: true,
            })}
          </div>
          {isSharedSite && (
            <div data-testid="site-shared-icon" className="site-share">
              <Popover
                trigger={
                  <Button
                    onClick={() => {
                      props.shareSiteCallback(props.site as Site);
                    }}
                    icon
                    borderless
                    mini
                    className="share-icon"
                  >
                    <Icon name="users" />
                  </Button>
                }
                content={getShareButtonContent({
                  context: context as AppContextState,
                  shareable: props.site,
                })}
                position="top center"
                inverted
              />
            </div>
          )}
        </div>
        <div className="site-options">
          <Dropdown
            checkEmpty
            icon={<Icon name="dots" />}
            className="media-dropdown"
          >
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigator.clipboard.writeText(props.site.id);
                }}
              >
                <Icon name="clipboard" />
                <FormattedMessage
                  id="ui_component.common.label.copy_dashboard_id"
                  defaultMessage="Copy Dashboard ID"
                />
              </DropdownItem>
              {props.site.createdBy === context.currentUser?.id && (
                <DropdownItem
                  onClick={() => {
                    props.onUpdateSiteClick(props.site as Site);
                  }}
                >
                  <Icon name="refresh" />
                  <FormattedMessage
                    id="ui_component.common.label.update_dashboard_journey"
                    defaultMessage="Re-record Journey"
                  />
                </DropdownItem>
              )}
              {props.canCast && (
                <DropdownItem onClick={props.castSiteCallback}>
                  <Icon name="casting" />{" "}
                  <FormattedMessage
                    id="ui_component.common.label.set_to_screen"
                    defaultMessage="Set to Screen"
                  />
                </DropdownItem>
              )}
              {props.canShare && (
                <DropdownItem
                  onClick={() => props.shareSiteCallback(props.site as Site)}
                >
                  <Icon name="users" />{" "}
                  <FormattedMessage
                    id="ui_component.common.label.site"
                    defaultMessage="Share"
                  />
                </DropdownItem>
              )}
              {props.canDelete && <DropdownDivider />}
              {props.canDelete && (
                <DropdownItem
                  onClick={props.deleteSiteCallback}
                  className="danger"
                >
                  <Icon name="trash" />{" "}
                  <FormattedMessage
                    id="ui_component.common.label.delete"
                    defaultMessage="Delete Site"
                  />
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </main>
    </Styled>
  );
};
