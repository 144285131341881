import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  .mr-5px {
    margin-right: 5px;
  }

  &.sp {
    background: ${Theme.color.nearWhite};
    ${Theme.default.borderRadius};
    height: 100%;
    display: flex;
    flex-direction: column;

    .current-item {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      height: 190px;
      justify-content: space-between;

      .title {
        height: 100%;
        display: flex;

        .item-thumbnail {
          width: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid ${Theme.color.lightGrey};

          .thumbnail-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .thumbnail {
              max-width: 100px;
              max-height: 100px;
              min-width: 30px;
              min-height: 30px;
            }
          }
        }

        .item-detail {
          background: ${Theme.color.white};
          width: calc(100% - 140px);
          padding: 10px 60px 10px 10px;

          .favorite {
            height: 45px;
            pointer-events: none;
          }

          .item-name {
            height: calc(100% - 45px);
            padding: 0 10px;

            h2 {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .select-screen-label {
        background: ${Theme.color.darkGrey};
        color: ${Theme.color.white};
        font-size: 1.25em;
        font-weight: bold;
        height: 50px;
        line-height: 50px;
        text-align: center;
      }
    }

    .sp-search {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 24px 8px 16px;
      background: ${Theme.color.white};
      border-bottom: 1px solid ${Theme.color.lightGrey};
      width: 100%;
      gap: 8px;

      .sp-search-box {
        flex: 1 0 0;
      }

      .sp-search-checkbox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      flex: 1 0 0;
      padding: 0 16px;
    }

    .sp-item-header {
      .screen-core {
        width: calc(100% - 52px);
        padding-left: 8px;
        display: flex;

        ${Theme.subheader.styled}

        .screen-alpha {
          width: 50%;
        }

        .screen-group {
          display: none;
        }

        .screen-player {
          width: calc(50% - 120px);
          min-width: auto;
        }

        .screen-tags {
          display: none;
        }

        .screen-state {
          width: 120px;
          text-align: center;
        }
      }
    }

    @media screen and (max-width: ${Theme.break.small}) {
      .sp-item-header {
        .screen-core {
          .screen-alpha {
            width: calc(100% - 50px);
          }

          .screen-player {
            display: none;
          }

          .screen-state {
            width: 56px;
          }
        }
      }
    }

    .list {
      .infinite-scroll-component {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .result-options {
      > div {
        display: inline-flex;
        width: unset;

        &.info-group {
          width: 100%;
          display: flex;
          gap: 8px;

          div.ui.dropdown:not(.inline) {
            width: 180px;
            flex-shrink: 0;

            > .item {
              display: flex;
              align-items: center;
              gap: 8px;

              i {
                background-color: var(--color-grey100);
              }
            }

            div.menu {
              align-items: flex-start;
              display: none;

              > .item {
                width: 100%;
              }
            }
          }

          .picker-content-info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            color: var(--color-gray-800);
            flex-wrap: wrap;

            span {
              font-size: 14px;
            }

            .ui.inline.dropdown {
              font-size: 14px;

              .input {
                font-size: 16px;
              }
            }
          }
        }

        &.selected-screen {
          justify-content: flex-start;

          .button {
            display: inline-flex;
            align-items: center;

            .count {
              width: auto;
              height: 24px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background: ${Theme.color.base};
              border-radius: 100%;
              color: ${Theme.color.white};
              font-size: 0.75em;
              margin-left: 5px;
              min-width: 24px;
            }
          }
        }

        &.selected-actions {
          div.ui.dropdown {
            width: 180px;
            flex-shrink: 0;
            margin-right: 10px;

            > .item {
              display: flex;
              align-items: center;
            }

            div.menu {
              align-items: flex-start;
              display: none;

              > .item {
                width: 100%;

                i {
                  width: 14px;
                  height: 14px;
                }

                :hover {
                  i {
                    background-color: ${Theme.color.white};
                  }
                }
              }
            }
          }

          justify-content: flex-end;
        }
      }
    }

    @media screen and (max-width: ${Theme.break.small}) {
      border-radius: none;
      overflow: auto;

      .md-footer.result-options {
        height: auto !important;
        padding: 16px !important;
        flex-direction: column;
        gap: 8px;

        > div {
          &.selected-actions {
            width: 100%;

            button {
              width: 100%;
            }
          }
        }
      }
    }

    @media screen and (max-width: ${Theme.break.tiny}) {
      .container {
        height: calc(100% - 240px);
      }

      .result-options {
        height: 240px;
        > div {
          &.info-group {
            flex-direction: column;
            div.ui.dropdown:not(.inline) {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &.single-sp {
    .md-footer.result-options {
      justify-content: space-around;
      gap: var(--spacing-sm, 8px);
      display: flex;
      flex-direction: column;
      padding: var(--spacing-md, 12px) var(--spacing-lg, 16px);

      > .actions {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }

      > .help-text {
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
`;
