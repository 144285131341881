interface HasName {
  name: string;
}

export enum SortingEntityType {
  Media = "media",
  AppInstance = "app_instance",
  Link = "link",
}

export enum SortOption {
  ID = "ID_DESC",
  NAME = "NAME_ASC",
  RECENT = "CREATED_AT_DESC",
  FAVORITE = "IS_FAVORITE_DESC",
}

const getSortOptionKey = ({
  id,
  entityType,
  isSearch,
}: {
  id: string;
  entityType: SortingEntityType;
  isSearch?: boolean;
}) => `${entityType}:${id}${isSearch ? ":search" : ""}`;

export function getSortOption({
  id,
  entityType,
  isSearch,
}: {
  id: string;
  entityType: SortingEntityType;
  isSearch?: boolean;
}): string[] | null {
  const sortingOptions = localStorage.getItem(
    getSortOptionKey({ id, entityType, isSearch })
  );
  if (!sortingOptions) {
    return null;
  }
  return JSON.parse(sortingOptions);
}

export function setSortOption({
  id,
  entityType,
  sortOptions,
  isSearch,
}: {
  id: string;
  entityType: SortingEntityType;
  sortOptions: SortOption[];
  isSearch?: boolean;
}): void {
  localStorage.setItem(
    getSortOptionKey({ id, entityType, isSearch }),
    JSON.stringify(sortOptions)
  );
}

export function removeSortOption({
  id,
  entityType,
  isSearch,
  sortOptions,
}: {
  id: string;
  entityType: SortingEntityType;
  isSearch?: boolean;
  sortOptions?: string[];
}) {
  if (sortOptions?.length) {
    const currentSortOptions = getSortOption({ id, entityType, isSearch });
    if (!currentSortOptions) {
      return;
    }
    const newSortOptions = currentSortOptions.filter(
      (option) => !sortOptions.includes(option)
    );
    setSortOption({
      id,
      entityType,
      sortOptions: newSortOptions as SortOption[],
      isSearch,
    });
    return;
  } else {
    localStorage.removeItem(getSortOptionKey({ id, entityType, isSearch }));
  }
}

export function sortedByName<T extends HasName>(entities: T[] | undefined) {
  if (!entities) {
    return [];
  }
  return [...entities].sort((a, b) => a.name.localeCompare(b.name));
}
