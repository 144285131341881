import { withApollo } from "@apollo/client/react/hoc";
import {
  CommonFont,
  Font,
  useAllFontListQuery,
  useCommonFontListQuery,
  useCustomFontListQuery,
} from "../../../../types.g";
import { compose } from "../../../../utils/compose";

export interface ApolloProps {
  allCommonFonts: CommonFont[];
  allCustomFonts: Font[];
  loading: boolean;
}

export const withData = compose(withApollo, (Component) => (props) => {
  const {
    data: customFont,
    loading: customFontLoading,
  } = useCustomFontListQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const commonFont = useCommonFontListQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const { data, loading } = useAllFontListQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const allCommonFontWithoutCustomFont = data?.allFonts?.nodes.filter(
    (font) => font.commonFontId
  );
  const allCommonFonts = commonFont.data?.allCommonFonts?.nodes.map((font) => {
    const installedCommonFont = allCommonFontWithoutCustomFont?.find(
      (x) => x.commonFontId === font.id
    );
    if (installedCommonFont) {
      return {
        ...font,
        id: installedCommonFont.id,
      };
    }
    return font;
  });

  const isFontLoading = loading || customFontLoading;
  return (
    <Component
      {...props}
      allCommonFonts={allCommonFonts ?? []}
      allCustomFonts={customFont?.allFonts?.nodes ?? []}
      loading={isFontLoading}
    />
  );
});
