import { Loader } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { Site } from "../../types.g";
import SiteList from "../SiteList";
import { SiteListItemActions } from "../SiteListItem";
import { ApolloProps, withData } from "./apollo";

export interface SiteTabPaneProps {
  onClickSiteCallback: (
    action: SiteListItemActions,
    value: Site | string | boolean,
    event?: React.SyntheticEvent
  ) => void;
  selectedSiteCallback: (site: Site) => void;
  searchTerms: string;
  onSelectSiteItems?: (siteItems: Partial<Site>[]) => void;
  selectedSiteItems?: Partial<Site>[];
}
class SiteTabPane extends React.Component<SiteTabPaneProps & ApolloProps, {}> {
  public loadMore = () => {
    if (
      this.props.data?.spaceById?.availableSitesBySpaceId.pageInfo!
        .hasNextPage &&
      !this.props.data?.loading
    ) {
      this.props.loadMore();
    }
  };
  public render() {
    if (this.props.data?.spaceById) {
      return (
        <SiteList
          onLoadMoreSiteData={this.loadMore}
          selectedSiteCallback={this.props.selectedSiteCallback}
          onClickSiteCallback={this.props.onClickSiteCallback}
          selectedSiteItems={this.props.selectedSiteItems}
          onSelectSiteItems={this.props.onSelectSiteItems}
          siteData={this.props.data}
          searchTerms={this.props.searchTerms}
        />
      );
    } else {
      return <Loader active={true} />;
    }
  }
}

export default withData(SiteTabPane) as React.ComponentType<SiteTabPaneProps>;
