import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export interface ContainerProps {
  disabled?: boolean;
  expired: boolean;
  readOnly?: boolean;
  isEditableSharedChannel: boolean;
}

export const Styled = styled.div<ContainerProps>`
  opacity: ${(props) => ((props as any).disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${(props) =>
    (props as any).expired &&
    `
    opacity: 0.5;
  `};

  ${(props) =>
    (props as any).isOverlap &&
    `
    border: 1px solid var(--color-red-500);
  `};

  ${({ readOnly }) => readOnly && `cursor: default;`}

  .disabled-action {
    pointer-events: none;
  }

  .hide-action {
    pointer-events: none;
    display: none !important;
    opacity: 0;
    > div {
      display: none !important;
    }
  }

  /* Children width only */
  .content-core {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    border-right: 1px solid
      ${({ isEditableSharedChannel, readOnly }) =>
        isEditableSharedChannel && !readOnly
          ? `var(--color-yellow-500, #FECF00);`
          : `var(--color-gray-50);`};
  }
  .content-alpha {
    padding: 8px;

    .content-title {
    }
    .media-scheduled {
      width: 40px;
    }
    .content-preview {
      position: relative;
      flex-shrink: 0;

      :empty {
        display: none;
      }
    }
  }

  .content-options {
    width: 100%;
    display: flex;
    padding: 8px 0 8px 64px;
    gap: 8px;
    max-width: 640px;

    .content-duration {
      width: 188px;
      margin-right: 52px;
    }

    .content-schedule {
      width: calc(100% - 196px);
      max-width: 328px;
    }

    .content-duration,
    .content-schedule {
      > div {
        width: 100%;
        max-width: 100%;
      }
    }
    .media-playback-mode {
      flex-shrink: 0;
      width: 44px;
      align-items: ${({ readOnly }) => (readOnly ? "flex-start" : "center")};
      &.hidden {
        visibility: hidden;
      }
    }
    .media-pick-to-play {
      width: 188px;
    }
    .play-back-icon {
      background-color: var(--color-foreground, #363639);
    }
  }

  .content-alpha {
    display: flex;
    flex: 0 0 auto;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid
      ${({ isEditableSharedChannel, readOnly }) =>
        isEditableSharedChannel && !readOnly
          ? `var(--color-yellow-500, #FECF00);`
          : `var(--color-gray-50);`};
    ${({ readOnly }) => readOnly && `cursor: default;`}
    .thumbnail-preview {
      width: 48px;
      height: 48px;
      position: relative;
      flex-shrink: 0;

      .thumbnail {
        width: 100%;
        height: 100%;

        .wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          ${Theme.default.borderRadius};
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          i {
            background-color: ${Theme.color.white};
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .content-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 8px;
      flex: 1 0 0;

      h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }

      .document-type {
        font-size: 12px;
        line-height: 16px;
        color: var(--color-gray-400);
      }

      .invalid-app-config {
        font-weight: normal;
        font-size: 0.75em;
        line-height: 1.333;
        color: ${Theme.color.red};
      }

      .content-status {
        display: inline-flex;

        > span {
          text-align: left;
          font-size: 0.75em;
          background: transparent;
          line-height: 1em;
          height: 18px;
          display: inline-flex;
          align-items: center;
          border-radius: 2px;
          color: ${Theme.color.warning};
          white-space: nowrap;
          font-style: italic;
        }

        i {
          width: 12px;
          height: 12px;
          margin-right: 5px;
          background-color: ${Theme.color.warning};
        }

        &.published {
          > span {
            color: ${Theme.color.grey};
          }
        }
      }
    }

    .media-scheduled {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      .scheduled {
        align-items: center;
        ${Theme.default.borderRadius};
        display: inline-flex;
        height: 40px;
        justify-content: center;
        min-width: 40px;

        i {
          background-color: ${Theme.color.warning};
        }

        &.warning {
          i {
            background-color: ${Theme.color.red};
            + i {
              margin-left: 4px;
            }
          }
        }

        :hover {
          cursor: pointer;
        }
      }
      :empty {
        display: none;
      }
    }
  }

  .extra-width-invalid-app {
    width: calc(100% - 45px) !important;
  }

  .content-duration {
    .document-type {
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-gray-400);
      z-index: 1;
    }
  }

  .content-schedule {
    display: inline-flex;
    justify-content: center;
    &.editable-shared-channel {
      .schedule-text {
        background-color: var(--color-white-100);
      }
    }
    .item-group {
      padding: 0 16px 0 0;
    }

    .schedule-text {
      min-width: auto;
    }
  }
  .content-onboarding {
    z-index: unset !important;
  }

  .content-remove {
    position: absolute;
    right: -40px;
    top: 20px;
    opacity: 0;
  }

  .content-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 32px;
    i {
      background: ${({ isEditableSharedChannel, readOnly }) =>
        isEditableSharedChannel &&
        !readOnly &&
        `var(--color-yellow-500, #FECF00)`};
    }
  }

  :hover {
    .content-remove {
      opacity: 1;
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .content-remove {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1319px) {
    .content-core {
      .content-options {
        max-width: 640px;

        .content-schedule {
          width: calc(100% - 168px);
          max-width: 328px;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .content-core {
      .content-options {
        flex-wrap: wrap;

        .content-duration {
          width: 100%;
          padding-right: 16px;
        }

        .content-schedule {
          width: 100%;
        }

        .media-pick-to-play {
          width: calc(100% - 52px);
          padding-right: 16px;
        }
      }
    }
  }
`;
