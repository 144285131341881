import { cloneDeep } from "lodash";
import * as React from "react";
import { ActionMessage, ActionMessageType } from "../reducers";
import { ActionListContainer, EditModeContainer } from "../styles";
import { EditableActionTypes } from "./editableactiontypes";
import {
  ActionListItem,
  ActionListItemProps,
  ActionListItemType,
} from "./listitem";
import {
  LoopAction,
  SiteRecorderAction,
  SiteRecorderActionType,
} from "./models";
import { renderActionEditor } from "./editor";

interface ActionListProps {
  actions: SiteRecorderAction[];
  dispatch: (action: ActionMessage) => void;
  showManageSession: boolean;
  editAction: {
    editorType?: SiteRecorderActionType;
    dispatch: (action: ActionMessage) => void;
    editorAction?: SiteRecorderAction;
  };
}

interface ActionListState {
  items: ActionListItemProps[];
}

export class ActionList extends React.Component<
  ActionListProps,
  ActionListState
> {
  private containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: ActionListProps) {
    super(props);

    this.state = {
      items: [],
    };

    this.containerRef = React.createRef<HTMLDivElement>();
  }

  public componentDidMount() {
    this.updateItems();
  }

  public componentDidUpdate(
    prevProps: ActionListProps,
    prevState: ActionListState
  ) {
    if (this.props.actions !== prevProps.actions) {
      this.updateItems();
    }

    if (this.state.items.length > prevState.items.length) {
      window.requestAnimationFrame(() => {
        this.containerRef.current?.scrollTo({
          top: this.containerRef.current.scrollHeight,
          behavior: "smooth",
        });
      });
    }
  }

  public render() {
    const displayedItems = this.props.showManageSession
      ? this.state.items
      : this.state.items.slice(1);
    return (
      <ActionListContainer ref={this.containerRef}>
        {displayedItems.map((item, index) => {
          const {
            editorType,
            dispatch: editorDispatch,
            editorAction,
          } = this.props.editAction;

          const isEditMode =
            this.props.editAction.editorAction?.id === item.action.id &&
            editorType;

          // Don't put the wrapper in EditMode for the manage session button, as it's editor is shown
          // via a modal instead of inline
          const Wrapper =
            isEditMode &&
            this.props.editAction.editorType !== "session/keep-alive"
              ? EditModeContainer
              : "div";

          return (
            <Wrapper className={item.action.type.replace(/\//g, "-")}>
              <ActionListItem
                key={item.index}
                index={index}
                type={item.type}
                action={item.action}
                dispatch={item.dispatch}
                moveItem={item.moveItem}
                onItemDropped={item.onItemDropped}
                removeItem={item.removeItem}
                connectDragSource={item.connectDragSource}
                connectDropTarget={item.connectDropTarget}
                isDragging={item.isDragging}
                step={item.step}
                selectItem={item.selectItem}
                currentEditorActionId={editorAction?.id}
              />
              {isEditMode &&
                this.props.editAction.editorType !== "session/keep-alive" &&
                renderActionEditor(editorType, editorDispatch, editorAction)}
            </Wrapper>
          );
        })}
      </ActionListContainer>
    );
  }

  private updateItems = () => {
    const { actions, dispatch } = this.props;
    const items: ActionListItemProps[] = [];
    let step = 1;

    for (const action of actions) {
      const item = {
        action,
        dispatch,
        moveItem: this.moveItem,
        onItemDropped: this.onItemDropped,
        removeItem: this.removeItem,
        step: String(step),
        selectItem: this.selectItem,
      };

      if (action instanceof LoopAction) {
        items.push(
          {
            ...item,
            index: items.length,
            type: ActionListItemType.StartLoop,
            currentEditorActionId: this.props.editAction.editorAction?.id,
          },
          ...action.config.actions.map((childAction, index) => ({
            ...item,
            index: items.length + index + 1,
            type: ActionListItemType.ChildAction,
            action: childAction,
            step: `${step}.${index + 1}`,
            currentEditorActionId: this.props.editAction.editorAction?.id,
          })),
          {
            ...item,
            index: items.length + action.config.actions.length + 1,
            type: ActionListItemType.EndLoop,
            step: undefined,
            currentEditorActionId: this.props.editAction.editorAction?.id,
          }
        );
      } else {
        items.push({
          ...item,
          index: items.length,
          type: ActionListItemType.Action,
          currentEditorActionId: this.props.editAction.editorAction?.id,
        });
      }

      ++step;
    }

    this.setState({ items });
  };

  private moveItem = (dragIndex: number, hoverIndex: number) => {
    const items = [...this.state.items];
    const movedItem = items.splice(dragIndex, 1)[0];
    items.splice(hoverIndex, 0, movedItem);

    this.setState({ items });
  };

  private onItemDropped = (originalIndex: number, newIndex: number) => {
    const actions: SiteRecorderAction[] = [];
    let currentLoopAction: LoopAction | undefined;
    let currentLoopActionIndex: number | undefined;

    for (const item of this.state.items) {
      switch (item.type) {
        case ActionListItemType.StartLoop:
          if (currentLoopAction) {
            this.moveItem(newIndex, originalIndex);
            return;
          }

          currentLoopAction = cloneDeep(item.action);
          currentLoopAction.config.actions = [];
          currentLoopActionIndex = actions.length;
          break;
        case ActionListItemType.EndLoop: {
          if (!currentLoopAction) {
            this.moveItem(newIndex, originalIndex);
            return;
          }

          actions.splice(currentLoopActionIndex!, 0, currentLoopAction);
          currentLoopAction = undefined;
          break;
        }
        default:
          if (currentLoopAction) {
            currentLoopAction.config.actions.push(item.action);
          } else {
            actions.push(item.action);
          }
          break;
      }
    }

    this.props.dispatch({
      type: ActionMessageType.SetActions,
      payload: actions,
    });
  };

  private removeItem = (index: number) => {
    const actions: SiteRecorderAction[] = [...this.props.actions];
    const item = this.state.items[index];
    const actionIndex = actions.indexOf(item.action);

    switch (item.type) {
      case ActionListItemType.StartLoop:
      case ActionListItemType.EndLoop: {
        actions.splice(
          actionIndex,
          1,
          ...(item.action as LoopAction).config.actions
        );
        break;
      }
      case ActionListItemType.ChildAction: {
        const loopActionIndex = parseInt(item.step!.split(".")[0]) - 1;
        const loopAction = actions[loopActionIndex] as LoopAction;
        loopAction.config.actions = loopAction.config.actions.filter(
          (childAction) => childAction !== item.action
        );

        break;
      }
      default:
        actions.splice(actionIndex, 1);
        break;
    }

    this.props.dispatch({
      type: ActionMessageType.SetActions,
      payload: actions,
    });
  };

  private selectItem = (index: number) => {
    const displayedItems = this.props.showManageSession
      ? this.state.items
      : this.state.items.slice(1);

    const { action } = displayedItems[index];

    if (action.id === this.props.editAction.editorAction?.id) {
      return this.props.dispatch({
        type: ActionMessageType.CloseActionEditor,
      });
    }

    if (!EditableActionTypes.includes(action.type)) {
      return;
    }

    this.props.dispatch({
      type: ActionMessageType.UpdateActionEditor,
      payload: {
        type: action.type,
        action: cloneDeep(action),
      },
    });
  };
}
