import { View } from "src/constants/constants";
import { fetchStudioTokens } from "./fetchStudioTokens";
import { getNamespacedStore } from "./getNamespacedStore";
import { sessionStore } from "./sessionStore";
import { StudioSessionManager } from "./StudioSessionManager";

export const ssm = new StudioSessionManager({
  fetchStudioTokensFn: fetchStudioTokens,
  store: getNamespacedStore({
    store: sessionStore,
    namespace: "signageSession",
  }),
});
(window as any).ssm = ssm;

/**
 * This make unit test much more easier
 */
export function getCurentOrgId() {
  return ssm.current.claims.orgId;
}

export function getCurrentSpaceId() {
  return ssm.current.settings.spaceId as string;
}

const DEFAULT_VIEW_TYPE = View.Grid;

export const getChannelViewType = (): View => {
  return ssm.current.settings.channelViewType ?? DEFAULT_VIEW_TYPE;
};
