import classNames from "clsx";
import { withApollo } from "@apollo/client/react/hoc";
import {
  Icon,
  LoaderBar,
  Popover,
  TagList,
} from "@screencloud/screencloud-ui-components";
import { find, isUndefined } from "lodash";
import { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { SortingActions, UUID } from "../../constants/constants";
import { Playlist, PlaylistListItemFragment } from "../../types.g";
import { compose } from "../../utils/compose";
import PlaylistItemsPreview from "../Playlist/PlaylistItemsPreview";
import EmptyState from "../EmptyState";
import {
  useSearchPlaylistPicker,
  UseSearchPlaylistPicker,
} from "./hooks/useSearchPlaylistPicker";
export interface ApolloProps extends UseSearchPlaylistPicker {}

interface SearchPlaylistPickerOwnProps {
  multiple?: boolean;
  searchString: string;
  allowSelectAll?: boolean;
  selectedArray: string[];
  selectedData: PlaylistListItemFragment[];
  isSelectAll: boolean;
  onSelectAllChange: () => void;
  toggleSelect: (
    playlistIds: string,
    playlist: Partial<PlaylistListItemFragment>
  ) => void;
  onSearchSelectAll: (
    playlistIds: string[],
    playlist: Partial<PlaylistListItemFragment>[]
  ) => void;
  spaceId?: UUID;
}

const withData = compose(
  withApollo,
  injectIntl,
  (Component) => (props: SearchPlaylistPickerOwnProps) => {
    const searchPlaylist = useSearchPlaylistPicker(
      props.searchString,
      props.spaceId as UUID
    );

    return <Component {...props} {...searchPlaylist} />;
  }
);

export interface PlaylistPickerState {
  playlistCount: number;
  searchString: string;
  sortBy: SortingActions;
  isFetchMore: boolean;
}

export const SearchPlaylistPickerComponent = (
  props: SearchPlaylistPickerOwnProps & ApolloProps
): JSX.Element => {
  const {
    allowSelectAll,
    playlists,
    loading,
    renderFetchMoreButton,
    toggleSelect,
    selectedArray,
    isSelectAll,
  } = props;

  useEffect(() => {
    if (isSelectAll) {
      const ids = (playlists ?? []).map((playlist) => playlist.id);
      props.onSearchSelectAll(ids, playlists);
    }
  }, [isSelectAll]);

  const isItemSelected = (id: UUID): boolean => {
    const result = find(selectedArray, (selectedItemId) => {
      return selectedItemId === id;
    });
    return !isUndefined(result);
  };

  return (
    <>
      {playlists.length > 0 && (
        <>
          {playlists.map((playlist) => {
            const draftPlaylist = playlist?.draft;
            return (
              draftPlaylist !== null && (
                <div
                  className={
                    "media-item readonly " +
                    classNames({
                      selected: isItemSelected(playlist?.id),
                      box: allowSelectAll,
                    })
                  }
                  key={`playlist-${draftPlaylist?.id}`}
                  onClick={() => toggleSelect(playlist?.id, playlist)}
                >
                  <div className="media-core">
                    <div className="media-alpha">
                      <div className="thumbnail-preview">
                        <div
                          className="wrapper"
                          style={{ backgroundColor: playlist?.color! }}
                        >
                          <Icon name="playlist" />
                        </div>
                      </div>
                      <div className="media-title">
                        <h3>{playlist?.name}</h3>
                        {draftPlaylist?.isPublished ? (
                          <span className="pl-status published">
                            <FormattedMessage
                              id="ui_component.common.label.published"
                              defaultMessage="Published"
                            />
                          </span>
                        ) : (
                          <span className="pl-status">
                            <Popover
                              inverted
                              position="top center"
                              content={
                                <FormattedMessage
                                  id="common.text.draft_content_message"
                                  defaultMessage="Changes have been made. Please publish to play the latest changes on your screens."
                                />
                              }
                              trigger={
                                <span>
                                  <Icon name="warning" />
                                  <FormattedMessage
                                    id="common.text.draft_saved"
                                    defaultMessage="Draft Saved. Publish required."
                                  />
                                </span>
                              }
                            />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="media-playlist-preview">
                      <PlaylistItemsPreview
                        playlist={draftPlaylist as Playlist}
                        isInPicker={true}
                        onPreviewClick={() => ""}
                      />
                    </div>
                    <div className="media-starred">
                      <Icon name={playlist?.isFavorite ? "star" : ""} />
                    </div>

                    <div className="media-tags">
                      <TagList taglist={playlist?.tags as string[]} />
                    </div>
                  </div>
                  {allowSelectAll ? (
                    <div className="playlist-check">
                      {isItemSelected(playlist?.id) ? (
                        <Icon name="checkbox-checked" />
                      ) : (
                        <Icon name="checkbox-empty" />
                      )}
                    </div>
                  ) : (
                    <div className="media-checked">
                      <Icon name="checked-circle" />
                    </div>
                  )}
                </div>
              )
            );
          })}
          {renderFetchMoreButton}
        </>
      )}
      {playlists.length === 0 && !loading && (
        <EmptyState section="search-playlists" className="empty">
          <h3>
            <FormattedMessage
              id="common.search.no_results"
              defaultMessage="We couldn’t find any matches"
            />
          </h3>
          <p>
            <FormattedMessage
              id="common.search.no_results_description"
              defaultMessage="Try adjusting or using different search terms."
            />
          </p>
        </EmptyState>
      )}
      {loading && <LoaderBar />}
    </>
  );
};

export default withData(SearchPlaylistPickerComponent);
