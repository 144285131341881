import { Loader } from "@screencloud/screencloud-ui-components";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { StyledButton } from "./styles";

interface Props {
  isLoading: boolean;
  itemCount: number;
  onClick: () => void;
}

const LoadMoreButton: FunctionComponent<Props> = ({
  isLoading,
  itemCount,
  onClick,
}) => {
  const fetchMoreText = isLoading ? (
    <Loader inline active={true} size="small">
      <FormattedMessage id="common.text.loading" defaultMessage="Loading..." />
    </Loader>
  ) : (
    <FormattedMessage
      id="common.text.load_more_item_count"
      defaultMessage="Load more ({itemCount})"
      values={{ itemCount }}
    />
  );

  return (
    <StyledButton fullWidth disabled={isLoading} onClick={onClick}>
      {fetchMoreText}
    </StyledButton>
  );
};

export default LoadMoreButton;
