import {
  Button,
  Icon,
  Input,
  Popover,
  TextArea,
} from "@screencloud/screencloud-ui-components";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../helpers/whiteLabel";
import { useAppContext } from "../../hooks/useAppContext";
import { isEmail } from "../../utils/validation";
import { Styled } from "./styles";

export interface FeedbackData {
  firstName: string;
  lastName: string;
  email: string;
  feedback: string;
}

interface FeedbackModalProps {
  onSubmit: (data: FeedbackData) => void;
}

export const FeedbackModal = (props: FeedbackModalProps) => {
  const context = useAppContext();
  const [firstName, setFirstName] = useState<string>(
    context.currentUser?.givenName ?? ""
  );
  const [lastName, setLastName] = useState<string>(
    context.currentUser?.familyName ?? ""
  );
  const [email, setEmail] = useState<string>(context.currentUser?.email ?? "");
  const [feedback, setFeedback] = useState<string>("");

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(event.target.value);
  };

  const getErrorMessage = (): string => {
    if (!email) {
      return "Email is required";
    } else if (!firstName) {
      return "Firstname is required";
    } else if (!lastName) {
      return "Lastname is required";
    } else if (!feedback) {
      return "Feedback is required";
    } else if (email && !isEmail(email)) {
      return "Please provide a valid email";
    }
    return "";
  };

  return (
    <Styled>
      <div className="container">
        <div className="icons">
          <Icon name="feedback" />
          <Icon name="feedback" />
        </div>
        <div className="header-section">
          <h2>
            <FormattedMessage
              id="feedback.title"
              defaultMessage="We love to hear your ideas!"
            />
          </h2>
        </div>
        <div className="field-section">
          <div className="name">
            <Input
              data-testid="first-name-input"
              required
              className="field"
              name="firstname"
              placeholder={context.intl.formatMessage({
                defaultMessage: "First Name",
                id: "feedback.first_name",
              })}
              onChange={onFirstNameChange}
              value={firstName}
            />

            <Input
              data-testid="last-name-input"
              required
              className="field"
              name="lastname"
              placeholder={context.intl.formatMessage({
                defaultMessage: "Last Name",
                id: "feedback.last_name",
              })}
              onChange={onLastNameChange}
              value={lastName}
            />
          </div>

          <Input
            data-testid="email-input"
            required
            className="field"
            name="email"
            placeholder={context.intl.formatMessage({
              defaultMessage: "Email",
              id: "feedback.email",
            })}
            onChange={onEmailChange}
            value={email}
          />

          <div className="answer">
            <p>
              <FormattedMessage
                id="feedback.sub_title"
                defaultMessage="What would you like that would make us better?"
              />
            </p>

            <TextArea
              value={feedback}
              placeholder={context.intl.formatMessage({
                defaultMessage:
                  "What do you need and how would this help your...",
                id: "feedback.message",
              })}
              required
              className="field feedback"
              name="feedback"
              onChange={onFeedbackChange}
            />
          </div>
          <div className="btn">
            {getErrorMessage().length === 0 ? (
              <PrimaryButton
                onClick={() =>
                  props.onSubmit({ firstName, lastName, email, feedback })
                }
                className="btn-submit"
              >
                <FormattedMessage
                  id="common.text.submit"
                  defaultMessage="Submit"
                />
              </PrimaryButton>
            ) : (
              <Popover
                trigger={
                  <Button disabled={!!getErrorMessage()} className="btn-submit">
                    <FormattedMessage
                      id="common.text.submit"
                      defaultMessage="Submit"
                    />
                  </Button>
                }
                content={getErrorMessage()}
                position="top center"
                inverted
              />
            )}
          </div>
        </div>
      </div>
    </Styled>
  );
};
