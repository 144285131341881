import { UUID } from "@screencloud/uuid";
import {
  LinkListItemFragment,
  SearchLinkPickerQueryVariables,
  useSearchLinkPickerQuery,
} from "src/types.g";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { shouldUseSpecificSpaceId } from "src/utils/shouldUseSpecificSpaceId";
import { useLoadMore } from "src/hooks/useLoadMore";

export interface UseSearchLinkPicker {
  links: LinkListItemFragment[];
  loading: boolean;
  renderFetchMorePickerButton?: React.ReactNode;
}

export function useSearchLinkPicker(
  query: string,
  spaceId?: UUID
): UseSearchLinkPicker {
  const variables: SearchLinkPickerQueryVariables = {
    query,
    spaceId: shouldUseSpecificSpaceId(spaceId),
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
  };

  const { data, fetchMore, loading } = useSearchLinkPickerQuery({
    variables,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !query,
  });

  const linkNodes = data?.searchLink?.nodes ?? [];
  const linkTotalCount = data?.searchLink?.totalCount;
  const isQueryHasNextPage = data?.searchLink?.pageInfo.hasNextPage;
  const endCursor = data?.searchLink?.pageInfo.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(linkNodes?.length),
    Number(linkTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) && linkNodes.length! < linkTotalCount!,
    loadMore
  );

  return {
    links: linkNodes,
    loading,
    renderFetchMorePickerButton: fetchMoreButton,
  };
}
