import { Theme } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import styled, { StyledComponent } from "styled-components";
import { CoverColor, CoverImage } from "../CoverThumbnail";
import { State } from "./colorizePanel";
import image from "../MediaUpload/images/empty-file-upload-landing.png";
export interface StyledChangeCoverProps {
  size: string;
  type: string;
  color: CoverColor;
  image: CoverImage;
}

type PickerPanelStyledProps = State;

export const PickerPanel: StyledComponent<
  React.ComponentType<PickerPanelStyledProps>,
  any
> = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .color-picker {
    max-width: 320px;
    margin: 0 auto;
    width: 100%;
    padding: 20px;
    transition: ${(props: PickerPanelStyledProps) =>
        props.editColorActive ? ".2s" : ".2s"}
      ease-in-out;
    background: ${Theme.color.white};
    z-index: ${(props: PickerPanelStyledProps) => props.editColorActive && "1"};
    transform: translateX(
      ${(props: PickerPanelStyledProps) =>
        props.editColorActive ? "0" : "-100%"}
    );
  }

  .glyph-picker {
    width: 100%;
    padding: 20px;
    transition: 0.2s ease-in-out;
    background: ${Theme.color.white};
    z-index: 1;
    transform: translateX(0);
  }

  .edit-glyph-btn {
    justify-content: flex-end;
    display: inline-flex;
    align-items: flex-end;
    width: 100%;
  }

  .edit-glyph-btn,
  .edit-color-btn {
    margin-bottom: 10px;

    .ui.button {
      display: inline-flex;
      align-items: center;

      i {
        margin: 0;
        width: 8px;
      }

      span + i {
        margin-left: 8px;
      }
    }
  }
`;

export const StyledCover: StyledComponent<
  any,
  any,
  Pick<StyledChangeCoverProps, any>
> = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
  border-radius: inherit;

  .column-left,
  .column-right {
    background: ${Theme.color.white};
    width: 50%;
    display: flex;
    justify-content: center;
    border-radius: inherit;
  }

  .column-left {
    background-color: ${Theme.color.silver};
    align-items: center;
  }

  .column-right {
    .cover-type-picker {
      border-left: 1px solid ${Theme.color.borderSection};
      height: 100%;
      overflow: hidden;
      width: 100%;
      padding: 0;
      border-radius: inherit;

      .secondary.pointing {
        font-size: smaller;

        .item {
          &:hover {
            color: ${Theme.color.base};
          }
        }

        .active.item {
          color: ${Theme.color.base};
        }
      }

      .attached.tabular {
        border-style: none;
        display: flex;
        justify-content: center;
        height: 85px;
        font-weight: bold;
        font-size: 1em;
        margin: 0 auto;
        padding: 20px 35px;

        .item {
          color: ${Theme.color.darkGrey};
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 0;
          }

          &:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 4px;
          }

          &:hover {
            background: ${Theme.color.base};
            border-color: transparent;
            color: ${Theme.color.white};
          }
        }

        .item.active {
          background: ${Theme.color.darkGrey};
          border-color: ${Theme.color.darkGrey};
          color: ${Theme.color.white};
          margin-bottom: 0;

          &:hover {
            background: ${Theme.color.base};
            border-color: transparent;
            color: ${Theme.color.white};
          }
        }
      }

      .tab.active {
        height: calc(100% - 85px);
        overflow: auto;
        border-top: 1px solid ${Theme.color.lightGrey};
      }

      .panel-container {
        max-height: 420px;
      }

      .image-container {
        display: flex;
        flex-wrap: wrap;
        height: 100%;

        .droppane-wrapper {
          margin: 0px auto;
          width: 100%;
          height: 100%;
        }

        .thumbnail-box {
          ${Theme.default.borderRadius};
          background-size: cover;
          cursor: pointer;
          margin: 5px;
          padding-bottom: calc(33.33% - 10px);
          position: relative;
          text-align: center;
          width: calc(33.33% - 10px);
        }

        #droppane {
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0 auto;
          overflow: hidden;

          .fsp-picker--inline {
            width: 100%;
            min-height: 280px;
            min-width: auto;

            .fsp-picker {
              margin: 0 auto;
              font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
            }
          }

          .fsp-modal {
            border-bottom: 0px solid transparent;
            .fsp-modal__sidebar {
              .fsp-source-list {
                .fsp-source-list__item {
                  &.fsp-source-list__item--active {
                    background-color: ${Theme.color.nearWhite};
                  }

                  .fsp-icon--unsplash {
                    background: ${Theme.color.white}
                      url(https://static.filestackapi.com/picker/1.20.1/assets/images/unsplashlogo.svg);
                    background-size: 80%;
                    background-position: center;
                    border-radius: 100%;
                  }

                  :focus {
                    box-shadow: none;
                  }
                }
              }
            }
            .fsp-modal__body {
              background-color: ${Theme.color.nearWhite};
              padding: 0;

              .fsp-header {
                display: none;
              }
              .fsp-content {
                height: 100%;

                .fsp-unsplash-source {
                  height: 100%;
                }

                .fsp-source-auth__wrapper {
                  padding: 20px;
                }

                .fsp-url-source__form,
                .fsp-unsplash-source__form {
                  width: 100%;
                  max-width: 400px;
                }
              }
            }
          }
          .fsp-drop-area-container {
            background-color: ${Theme.color.nearWhite};
            padding: 20px;
          }
          .fsp-header-icon {
            width: 0px;
            height: 0px;
          }
          .fsp-drop-area {
            background-color: ${Theme.color.nearWhite};
            border: 3px dashed ${Theme.color.silver};
            ${Theme.default.borderRadius};
            cursor: pointer;
            height: 100%;

            :focus,
            :hover {
              background-color: ${Theme.color.silver};
              box-shadow: none;
              border-color: ${Theme.color.lightGrey};
            }
          }
          .fsp-select-labels {
            width: 200px;
            padding-top: 10px;
            background-size: 150px;
            background-image: url(${image});
            &:after {
              display: inline-block;
              content: "Browse";
              width: auto;
              background: #5383ec;
              color: ${Theme.color.white};
              height: 45px;
              line-height: 45px;
              padding: 0 20px;
              ${Theme.default.borderRadius};
              font-weight: bold;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .menu {
    padding-right: 0;
    width: 100%;
    text-align: center;
  }

  .menu a {
    border: 1px solid ${Theme.color.lightGrey};
    background: none;
  }

  .menu a:first-child {
    border-right: 0px solid;
  }

  .menu a:last-child {
    border-left: 0px solid;
  }

  .ui.pointing.secondary.menu {
    padding-top: 0;

    .item {
      letter-spacing: 0;
      width: 140px;
      line-height: 29px;
      height: 30px;
      border: 1px solid ${Theme.color.lightGrey};
    }
  }

  .menu .item {
    min-width: 100px;
    line-height: 0.8;
  }

  .tab.active,
  .tab.open {
    padding: 0;
  }

  .custom-color {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

    h3 {
      font-weight: 400;
      margin-top: 10px;
    }

    .column {
      h3.custom-header {
        display: inline-block;
        margin-right: 15px;
      }

      div.custom-content {
        display: inline-block;
      }
    }
  }

  .color-container {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    justify-content: center;
    margin: 0;
    transition: 0.3s;

    .color-palette {
      height: 0;
      padding-bottom: calc(100% - 20px);
      position: relative;
      width: calc(100% - 20px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
      cursor: pointer;
      border-radius: 100%;

      i {
        position: absolute;
        top: calc(50% - 13px);
        left: calc(50% - 13px);
      }

      .color-active {
        background: none;
        display: block;
        width: 30px;
        border-radius: 100%;
        height: 30px;
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);

        &.selected {
          background-color: ${Theme.color.white};
        }
      }

      :hover {
        .color-active {
          background-color: ${Theme.color.white};
        }
      }
    }
  }

  .glyph-selected {
    color: ${Theme.color.silver};
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .column-right {
      .cover-type-picker {
        .panel-container,
        .image-container {
          /* max-height: 340px; */
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .column-left,
    .column-right {
      min-height: 356px;
    }

    .column-right {
      .cover-type-picker {
        .attached.tabular {
          padding: 0 20px;
        }

        .panel-container,
        .image-container {
          padding: 20px 0;

          .droppane-wrapper {
            width: 100%;
            padding: 20px;
          }

          .color-container {
            grid-template-columns: repeat(4, 25%);
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    flex-direction: column;
    width: 100%;

    .column-left,
    .column-right {
      width: 100%;
    }

    .column-left {
      min-height: 220px;
    }

    .column-right {
      width: 100%;
      height: calc(100% - 200px);
      min-height: auto;

      .cover-type-picker {
        .panel-container,
        .image-container {
          height: 100%;
          .droppane-wrapper {
            width: 100%;
            padding: 0 20px;
          }
        }
      }
    }
  }
`;

Object.assign(StyledCover, {
  displayName: "StyledCover",
});
