import { CANVAS_ROUTE } from "src/constants/constants";
import { AppInstance } from "../../src/types.g";

export function isLandscape(canvas: AppInstance) {
  return canvas?.config?.canvasHeight < canvas?.config?.canvasWidth;
}

export function isPortrait(canvas: AppInstance) {
  return canvas?.config?.canvasHeight > canvas?.config?.canvasWidth;
}

export function isSquare(canvas: AppInstance) {
  return canvas?.config?.canvasHeight === canvas?.config?.canvasWidth;
}

export function getOrientation(canvas: AppInstance) {
  return isLandscape(canvas)
    ? "Landscape"
    : isPortrait(canvas)
    ? "Portrait"
    : "Square";
}

export function isTemplateSection(pathname: string) {
  return pathname.includes(CANVAS_ROUTE.CANVAS_TEMPLATE);
}

export function isGallerySection(pathname: string) {
  return pathname.includes(CANVAS_ROUTE.CANVAS_TEMPLATE_GALLERY);
}
