import { Button, Theme } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import EmptyState from "../../../components/EmptyState";
import PlaylistContentItem, {
  PlaylistContentItemActions,
} from "../../../components/Playlist/PlaylistContentItem";
import { Scalars } from "../../../types.g";
import { ActivePlaylistItem } from "./index";

const getDropStyle = (isDraggingOver) => ({
  background: isDraggingOver ? Theme.color.silver : Theme.color.nearWhite,
});

const getDragStyle = (isDragging, draggableStyle) => {
  return {
    boxShadow: isDragging ? "0 2px 15px 0 rgba(0,0,0,0.15)" : null,
    ...draggableStyle,
  };
};

interface PlaylistContentListProps {
  isDragDisabled?: boolean;
  isEditable: boolean;
  playlists: ActivePlaylistItem[];
  onActionChange: (
    listId: string,
    contentId: string,
    action: PlaylistContentItemActions,
    contentIndex: number | undefined
  ) => void;
  onDurationChange: (duration: number, listId: string) => void;
  onDurationFocus: (isFocus: boolean) => void;
  onContentPick: () => void;
  shouldScrollToItem?: Scalars["UUID"];
  onFinishScrollTo?: () => void;
  customDuration: Record<string, number>;
}

interface PlaylistContentListState {}

class PlaylistContentList extends React.PureComponent<
  PlaylistContentListProps,
  PlaylistContentListState
> {
  constructor(props: PlaylistContentListProps) {
    super(props);
  }

  public componentDidUpdate() {
    if (this.props.shouldScrollToItem) {
      const playlistContentList = document.getElementById(
        "playlist-content-list"
      );
      if (playlistContentList) {
        playlistContentList.scrollTop = playlistContentList.scrollHeight;
      }
      if (this.props.onFinishScrollTo) {
        this.props.onFinishScrollTo();
      }
    }
  }

  public render() {
    const playlistFilter = this.props.playlists.filter((item) => item);
    return (
      <div className="playlist-content-list" id="playlist-content-list">
        {playlistFilter.length === 0 && (
          <EmptyState section="playlist-detail">
            <h3 data-testid="empty-state-title">
              <FormattedMessage
                id="playlists.playlists_content_empty.empty_message"
                defaultMessage="This playlist needs feeding"
              />
            </h3>
            <p data-testid="empty-state-desc">
              <FormattedMessage
                id="playlists.playlists_content_empty.help_message"
                defaultMessage="Drag or add content from your library in the right sidebar. You can move things up or down in order and change the duration."
              />
            </p>
            <Button
              onClick={this.props.onContentPick}
              data-testid="empty-state-button"
            >
              <FormattedMessage
                id="channels.channel_content_empty.add_content"
                defaultMessage="Add Content"
              />
            </Button>
          </EmptyState>
        )}
        <Droppable droppableId={"playlistContentDropable"}>
          {(
            droppableProvided: DroppableProvided,
            droppableSnapshot: DroppableStateSnapshot
          ) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              style={getDropStyle(droppableSnapshot.isDraggingOver)}
              className="drop-zone"
            >
              {playlistFilter.map((item: ActivePlaylistItem, itemIndex) => {
                const customDuration = this.props.customDuration[item.list_id];
                return (
                  <Draggable
                    isDragDisabled={this.props.isDragDisabled}
                    key={item.dragableId}
                    draggableId={item.dragableId}
                    index={itemIndex}
                  >
                    {(
                      draggableProvided: DraggableProvided,
                      draggableSnapshot: DraggableStateSnapshot
                    ) => (
                      <div
                        ref={(element) => {
                          draggableProvided.innerRef(element);
                          if (
                            element &&
                            this.props.shouldScrollToItem &&
                            this.props.shouldScrollToItem === item.list_id
                          ) {
                            element.classList.add("fade-item");
                          }
                        }}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={getDragStyle(
                          draggableSnapshot.isDragging,
                          draggableProvided.draggableProps.style
                        )}
                      >
                        <PlaylistContentItem
                          key={`playlist-item-${item.list_id}`}
                          callBack={this.props.onActionChange}
                          isEditable={this.props.isEditable}
                          onDurationChange={this.props.onDurationChange}
                          onDurationFocus={this.props.onDurationFocus}
                          contentId={item.id}
                          listId={item.list_id}
                          title={item.name}
                          isExpired={item.isExpired}
                          contentType={item.mimeType as string}
                          type={item.refType}
                          imageSet={item.imageSet}
                          duration={customDuration}
                          defaultDuration={item.duration}
                          selected={false}
                          contentIndex={itemIndex}
                          isFixedDuration={item.isFixedDuration}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
        <Button onClick={this.props.onContentPick} className="btn-add-content">
          <FormattedMessage
            id="channels.channel_content_empty.add_content"
            defaultMessage="Add Content"
          />
        </Button>
      </div>
    );
  }
}

export default PlaylistContentList as React.ComponentType<PlaylistContentListProps>;
