export interface Compute {
  cpu: number;
  memory: number;
}

export enum ComputeType {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
}

export enum CustomComputeActionType {
  SET_MEMORY,
  SET_CPU,
}

export enum AdvancedSettingsActionType {
  SET_ZOOM,
  SET_SCROLL_FROM,
  SET_SCROLL_TO,
}
