import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

interface StyledAdminNavProps {
  isCustomTabs?: boolean;
}

export const Styled = styled.nav<StyledAdminNavProps>`
  background: var(--color-white-100);
  border-bottom: solid 1px var(--color-gray-100);
  display: flex;
  height: 50px;
  min-height: 50px;
  justify-content: ${(props) => (props.isCustomTabs ? "flex-start" : "center")};
  overflow: hidden;
  position: relative;
  padding: 0 40px;

  .wrapper {
    height: 200%;
    overflow: hidden;
    padding: 10px 0 0;
  }

  .items {
    list-style: none;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    &-item {
      display: inline-block;
      height: 40px;
      white-space: nowrap;
    }

    &-link {
      border-bottom: 4px solid transparent;
      color: var(--color-subtle);
      cursor: pointer;
      display: inline-block;
      font-size: 0.75em;
      font-weight: 900;
      height: 40px;
      letter-spacing: 0.1em;
      line-height: 40px;
      margin-bottom: -1px;
      padding: 0 16px;
      text-transform: uppercase;
      transition: all 0.2s linear;

      &:hover,
      &.active {
        border-color: var(--color-primary-background);
        color: var(--color-foreground-active);
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    padding: 0 20px;
  }
`;
