import { ApolloCache } from "@apollo/client";
import { UUID } from "@screencloud/uuid";
import { isAfter, parseISO } from "date-fns";
import { get, keyBy, orderBy } from "lodash";
import { AppContextState } from "../AppContextProvider/type";
import {
  DEFAULT_GLOBAL_DURATION,
  DefaultDuration,
  ListContentItem,
} from "../constants/constants";
import {
  AppInstance,
  AppInstanceForContentListWithConfigFragment,
  CreatePlaylistMutation,
  DuplicatePlaylistMutation,
  File,
  Link,
  Playlist,
  PlaylistByIdForListingFragment,
  PlaylistByIdForPlaylistListingFragment,
  PlaylistListItemFragment,
  PlaylistListItemFragmentDoc,
  Site,
  UpdatePlaylistNameMutation,
} from "../types.g";
import { appInstanceIconUrlForPlaylist } from "./appHelper";
import {
  generateImgixThumbnail,
  getFileOutputImageSet,
  isDocument,
} from "./mediaHelper";
import { isShareableOwner } from "./shareableHelper";
import { isNotRelatedCacheToModify } from "./generalHelper";

export function isFileExpired(
  file: PlaylistByIdForListingFragment["filesByPlaylistId"]["nodes"][0]
): boolean {
  const { expireAt } = file;
  return expireAt && isAfter(new Date(), parseISO(expireAt));
}

export interface IContentListPreviewItem {
  content: any;
  contentTitle: string;
  contentType: string;
  duration: number;
  id: UUID;
  url: string;
}

export function getTotalNumberOfPlaylistContentItems(
  playlist: Playlist,
  allActiveContentIds: string[]
) {
  if (!playlist || !playlist.content || !playlist.content.list) {
    return 0;
  }
  const allContentIds = playlist.content.list.map(
    (item) => item.content._ref.id
  );
  const currentContent = allContentIds.filter((id) =>
    allActiveContentIds.includes(id)
  );
  return currentContent.length;
}

export function mapPlaylistDraftFileItem(
  item: any,
  files: PlaylistByIdForListingFragment["filesByPlaylistId"],
  defaultDuration: DefaultDuration,
  secureMediaPolicy: string | undefined
): IContentListPreviewItem | undefined {
  const file = files
    ? files.nodes.find((f) => f!.id === item.content._ref.id)
    : undefined;
  if (!file) {
    return undefined;
  }

  const videoFileOutput = file.fileOutputsByFileId.nodes.find((fileOutput) =>
    fileOutput!.mimetype!.startsWith("video")
  );
  const pdfFileOutput = file.mimetype!.includes("application/pdf");

  const isExpired = isFileExpired(file);

  if (videoFileOutput) {
    return {
      content: file,
      contentTitle: file.name,
      contentType: file.mimetype!,
      duration: isExpired ? 0 : videoFileOutput.metadata.duration,
      id: item.content._ref.id,
      url: generateImgixThumbnail(file, secureMediaPolicy, false),
    };
  } else {
    const _defaultDuration = pdfFileOutput
      ? defaultDuration.document
      : defaultDuration.image;

    const durationPerItem =
      item.content.props.duration ||
      _defaultDuration ||
      DEFAULT_GLOBAL_DURATION;

    const imageSet = getFileOutputImageSet(file);
    const duration =
      isDocument(file) && imageSet
        ? durationPerItem * imageSet.content.urls.length
        : durationPerItem;

    return {
      content: file,
      contentTitle: file.name,
      contentType: file.mimetype!,
      duration: isExpired ? 0 : duration,
      id: item.content._ref.id,
      url: generateImgixThumbnail(file, secureMediaPolicy, false),
    };
  }
}

export function mapPlaylistDraftAppItem(
  item: any,
  apps:
    | PlaylistByIdForListingFragment["appInstancesByPlaylistId"]
    | PlaylistByIdForPlaylistListingFragment["appInstancesByPlaylistId"],
  defaultDuration: DefaultDuration
): IContentListPreviewItem | undefined {
  const appByPlaylist = apps
    ? apps.nodes.find((app) => app!.id === item.content._ref.id)
    : undefined;

  if (!appByPlaylist) {
    return undefined;
  }
  const getAppDuration = (appByPlaylist as AppInstanceForContentListWithConfigFragment)
    .config
    ? (appByPlaylist as AppInstanceForContentListWithConfigFragment).config
        ?.appInstanceDurationInSeconds
    : 0;
  const duration =
    getAppDuration * 1000 || // appInstanceDurationInSeconds is in seconds, must be converted to milliseconds
    item.content.props.duration ||
    defaultDuration.app ||
    DEFAULT_GLOBAL_DURATION;
  const iconUrl = appInstanceIconUrlForPlaylist(appByPlaylist);
  return {
    content: appByPlaylist,
    contentTitle: appByPlaylist.name!,
    contentType: appByPlaylist.__typename!,
    duration,
    id: item.content._ref.id,
    url: iconUrl || "",
  };
}

export function mapPlaylistDraftLinkItem(
  item: any,
  links: PlaylistByIdForListingFragment["linksByPlaylistId"],
  defaultDuration: DefaultDuration,
  secureMediaPolicy: string | undefined
): IContentListPreviewItem | undefined {
  const linkByPlaylist = links
    ? links.nodes.find((link) => link!.id === item.content._ref.id)
    : undefined;

  if (!linkByPlaylist) {
    return undefined;
  }

  const duration =
    item.content.props.duration ||
    defaultDuration.link ||
    DEFAULT_GLOBAL_DURATION;

  return {
    content: linkByPlaylist,
    contentTitle: linkByPlaylist.name,
    contentType: linkByPlaylist.__typename!,
    duration,
    id: item.content._ref.id,
    url:
      (linkByPlaylist.fileByFileId &&
        generateImgixThumbnail(
          linkByPlaylist.fileByFileId,
          secureMediaPolicy,
          false
        )) ||
      "",
  };
}

export function mapPlaylistDraftSiteItem(
  item: any,
  sites: PlaylistByIdForListingFragment["sitesByPlaylistId"],
  defaultDuration: DefaultDuration,
  secureMediaPolicy: string | undefined
): IContentListPreviewItem | undefined {
  const siteByPlaylist = sites
    ? sites.nodes.find((site) => site!.id === item.content._ref.id)
    : undefined;

  if (!siteByPlaylist) {
    return undefined;
  }

  const duration =
    item.content.props.duration ||
    defaultDuration.site ||
    DEFAULT_GLOBAL_DURATION;

  return {
    content: siteByPlaylist,
    contentTitle: siteByPlaylist.name,
    contentType: siteByPlaylist.__typename!,
    duration,
    id: item.content._ref.id,
    url: siteByPlaylist.fileByThumbnailId
      ? generateImgixThumbnail(
          siteByPlaylist.fileByThumbnailId,
          secureMediaPolicy,
          false
        )
      : "",
  };
}

export const mapPlaylistToContentListPreview = (
  listContentItems: ListContentItem[],
  playlist: Playlist,
  secureMediaPolicy: string | undefined
) => {
  if (!playlist || !playlist.content || !playlist.content.list) {
    return [];
  }
  return listContentItems
    .map((item) => {
      const defaultDurations =
        playlist.content &&
        playlist.content.props &&
        playlist.content.props.default_durations
          ? playlist.content.props.default_durations
          : DEFAULT_GLOBAL_DURATION;
      if (item.content._ref.type === "file" && playlist.filesByPlaylistId) {
        return mapPlaylistDraftFileItem(
          item,
          playlist.filesByPlaylistId,
          defaultDurations,
          secureMediaPolicy
        );
      } else if (
        item.content._ref.type === "link" &&
        playlist.linksByPlaylistId
      ) {
        return mapPlaylistDraftLinkItem(
          item,
          playlist.linksByPlaylistId,
          defaultDurations,
          secureMediaPolicy
        );
      } else if (
        item.content._ref.type === "site" &&
        playlist.sitesByPlaylistId
      ) {
        return mapPlaylistDraftSiteItem(
          item,
          playlist.sitesByPlaylistId,
          defaultDurations,
          secureMediaPolicy
        );
      } else if (
        item.content._ref.type === "app" &&
        playlist.appInstancesByPlaylistId
      ) {
        return mapPlaylistDraftAppItem(
          item,
          playlist.appInstancesByPlaylistId,
          defaultDurations
        );
      } else {
        return null;
      }
    })
    .filter((item) => !!item);
};

export interface IPlayListMap {
  fileMap: { [id: string]: File };
  linkMap: { [id: string]: Link };
  siteMap: { [id: string]: Site };
  appMap: { [id: string]: AppInstance };
}

export function getMapFromPlaylist(playList: Playlist): IPlayListMap {
  const files: File[] = get(playList, ["filesByPlaylistId", "nodes"], []);
  const links: Link[] = get(playList, ["linksByPlaylistId", "nodes"], []);
  const sites: Site[] = get(playList, ["sitesByPlaylistId", "nodes"], []);
  const apps: AppInstance[] = get(
    playList,
    ["appInstancesByPlaylistId", "nodes"],
    []
  );

  return {
    appMap: keyBy(apps, "id"),
    fileMap: keyBy(files, "id"),
    linkMap: keyBy(links, "id"),
    siteMap: keyBy(sites, "id"),
  };
}

/**
 *
 * @param param0 Can toggle share playlist
 */
export function canToggleShare({
  playlist,
  context,
}: {
  playlist: Pick<Playlist, "id" | "spaceId">;
  context: AppContextState;
}) {
  return (
    { playlist, context } &&
    context.currentPermissions.validateCurrentSpace("playlist", "share")
  );
}

/**
 * If user has permission to delete but cant delete playlist because it is belong to other space we may inform them
 */
export function shouldInformUnableToDeleteOtherSpacePlaylist({
  playlist,
  context,
}: {
  playlist: Pick<Playlist, "id" | "spaceId">;
  context: AppContextState;
}) {
  return (
    !isShareableOwner({ shareable: playlist, context }) &&
    context.currentPermissions.validateCurrentSpace("playlist", "delete")
  );
}

/**
 * If user has permission to duplicate but cant duplicate playlist because it is belong to other space we may inform them
 */
export function shouldInformUnableToDuplicateOtherSpacePlaylist({
  playlist,
  context,
}: {
  playlist: Pick<Playlist, "id" | "spaceId">;
  context: AppContextState;
}) {
  return (
    !isShareableOwner({ shareable: playlist, context }) &&
    context.currentPermissions.validateCurrentSpace("playlist", "create")
  );
}

export function canBeDuplicated({
  playlist,
  context,
}: {
  playlist: Pick<Playlist, "id" | "spaceId">;
  context: AppContextState;
}) {
  return (
    isShareableOwner({ shareable: playlist, context }) &&
    context.currentPermissions.validateCurrentSpace("playlist", "create")
  );
}

export function canBePublished({
  playlist,
  context,
}: {
  playlist: Pick<Playlist, "id" | "spaceId">;
  context: AppContextState;
}) {
  return (
    isShareableOwner({ shareable: playlist, context }) &&
    context.currentPermissions.validateCurrentSpace("playlist", "publish")
  );
}

export function shouldDisplayAsPublished({
  playlist,
  context,
}: {
  playlist: Pick<PlaylistListItemFragment, "id" | "spaceId" | "draft">;
  context: AppContextState;
}) {
  return (
    !canBePublished({ context, playlist }) ||
    Boolean(playlist?.draft?.isPublished)
  );
}

export function updateNewPlaylistToList(
  context: AppContextState,
  cache: ApolloCache<CreatePlaylistMutation | DuplicatePlaylistMutation>,
  newPlaylist: PlaylistListItemFragment
) {
  cache.modify({
    id: cache.identify(context.currentSpace!),
    fields: {
      publishedPlaylistsBySpaceId(publishPl, options) {
        if (isNotRelatedCacheToModify(context, options)) {
          return publishPl;
        }

        const newPlaylistRef = cache.writeFragment({
          data: newPlaylist,
          fragment: PlaylistListItemFragmentDoc,
          fragmentName: "PlaylistListItem",
        });

        if (
          // Already exist
          publishPl.nodes.some(
            (ref) => options.readField("id", ref) === newPlaylist?.id
          )
        ) {
          return publishPl;
        }

        const reOrder = orderBy(
          [...publishPl.nodes, newPlaylistRef].map((node) => {
            const name = options.readField("name", node) as string;
            return {
              name,
              node,
            };
          }),
          [(node) => node?.name?.toLowerCase()],
          ["asc"]
        );
        const nodes = reOrder.map((re) => re.node);
        const newPlaylistlist = {
          ...publishPl,
          nodes,
          totalCount: nodes.length,
        };
        return newPlaylistlist;
      },
    },
  });
}
export function updatePlaylistListOrder(
  context: AppContextState,
  cache: ApolloCache<UpdatePlaylistNameMutation>
) {
  cache.modify({
    id: cache.identify(context.currentSpace!),
    fields: {
      publishedPlaylistsBySpaceId(publishPl, options) {
        if (
          options.storeFieldName.includes('"equalTo":') ||
          !options.storeFieldName.includes('"filter":')
        ) {
          const reOrder = orderBy(
            publishPl.nodes.map((node) => {
              const name = options.readField("name", node) as string;
              return {
                name,
                node,
              };
            }),
            [(node) => node?.name?.toLowerCase()],
            ["asc"]
          );
          const newPlaylistlist = {
            ...publishPl,
            nodes: reOrder.map((re) => re.node),
            totalCount: publishPl.totalCount,
          };
          return newPlaylistlist;
        } else {
          return publishPl;
        }
      },
    },
  });
}
