import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;

  .space-list {
    display: flex;
    overflow: auto;
    padding: var(--spacing-lg, 16px);
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--color-gray-50, #f6f6f7);
    border-radius: 0 0 8px 8px;

    > .share-item {
      display: flex;
      justify-content: space-between;
    }

    .divider {
      margin: 0 -16px;
    }
  }

  .subheader,
  .share-all,
  .share-to-space {
    align-items: center;
    display: flex;

    &.publish-container,
    &.share-to-space-container {
      .publish-template-text-wrapper {
        display: flex;
        flex-direction: column;
        color: var(--color-foreground, #404145);
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        span:last-child {
          color: var(--color-gray-400, #7d7e84);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .subheader {
    height: 40px;

    span {
      color: ${Theme.color.grey};
      font-size: 0.75em;
      font-weight: bold;
      letter-spacing: 0.1em;
      position: relative;
      text-transform: uppercase;
      z-index: 1;
    }
  }

  .shared-space-list {
    display: flex;
    overflow: auto;
    padding: var(--spacing-lg, 16px);
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--color-gray-50, #f6f6f7);
    border-radius: 0 0 8px 8px;

    .ui.dropdown {
      width: 280px;
    }
  }

  .share-all {
    display: flex;
    padding-bottom: var(--spacing-md, 12px);
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    gap: var(--spacing-sm, 8px);
    .title {
      font-weight: var(--font-weight-bold, 700);
    }
    .description {
      color: var(--color-gray-400, #7d7e84);
      font-size: var(--font-size-sm, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: var(--font-line-height-sm, 20px);
    }
    .column {
      display: flex;
      max-width: 380px;
      align-items: flex-start;
      flex-direction: column;
      flex: 1 0 0;

      h3 {
        margin: 0;
      }

      p {
        color: var(--color-gray-400, #7d7e84);
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }

    + .divider {
      display: flex;
      padding: var(--spacing-sm, 8px) 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      :after {
        content: "";
        display: block;
        height: 1px;
        align-self: stretch;
        border-radius: var(--radius-sm, 4px);
        background: var(--color-gray-100, #e2e3e5);
      }
    }
  }

  .specific-spaces-subheader {
    color: var(--color-gray-300, #a1a2a7);
    font-size: var(--font-size-xs, 12px);
    font-weight: 900;
    line-height: var(--font-line-height-xs, 16px);
    letter-spacing: 1.2px;
    text-transform: uppercase;
    align-items: center;
    cursor: default;
    height: 40px;
    min-height: 40px;
    display: flex;
  }

  .share-to-space-container {
    padding-top: 8px;
  }

  .custom {
    display: flex;
    padding: var(--spacing-md, 12px) 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  .reset-permissions {
    display: flex;
    padding-bottom: var(--spacing-lg, 16px);
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .column {
      display: flex;
      align-items: flex-start;
      flex: 1 0 0;

      &.hidden {
        display: none;
      }

      :last-child {
        width: 280px;
        flex-direction: column;
        align-items: flex-end;
        flex: 0;
      }
    }
  }

  .search-space {
    display: flex;
    padding: 0 0 var(--spacing-md, 12px) 0px;
    align-items: flex-start;
    gap: var(--spacing-sm, 8px);
    align-self: stretch;
  }

  .share-footer {
    background: ${Theme.color.white};
    border-radius: 0 0 ${Theme.default.borderRadiusValue}
      ${Theme.default.borderRadiusValue};
    border-top: 1px solid ${Theme.color.borderSection};
    position: relative;
    min-height: 100px;
    padding: 5px 20px 10px;
    height: 105px;
    flex-shrink: 0;
    align-self: stretch;

    .option {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      h3 {
        margin: 0;
        white-space: nowrap;
      }

      .middot {
        margin: 0 10px;
        -webkit-text-security: disc;
        font-size: 12px;
      }
    }

    .ui.dropdown {
      width: auto;
      .menu .item {
        white-space: pre-wrap;
      }
      .text {
        font-weight: normal;
      }

      &.disabled {
        background-color: transparent;
      }
    }

    .copy-embed {
      display: flex;

      .ui.input {
        width: 100%;

        input {
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;

          :hover {
            border-color: ${Theme.color.borderInput};
          }

          &[disabled] {
            background-color: ${Theme.color.silver};
            -webkit-text-fill-color: ${Theme.color.grey};
          }
        }

        .ui.button {
          background: transparent;
          width: 110px;
          padding: 10px;
          box-shadow: none;
          border-bottom: 2px solid ${Theme.color.borderInput};

          :hover {
            border-color: ${Theme.color.darkGrey};
          }

          &.disabled {
            background-color: ${Theme.color.silver};
            color: ${Theme.color.grey};

            :hover {
              border-color: ${Theme.color.borderInput};
            }
          }
        }

        &.disabled {
          opacity: 1;
        }
      }

      .btn-embed {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .share-footer {
      height: 130px;
      padding-top: 10px;

      .option {
        flex-direction: column;
        align-items: flex-start;

        .middot {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 639px) {
    .shared-space-list {
      .share-all {
        flex-direction: column;

        .column {
          max-width: 100%;
        }
      }

      .custom {
        .reset-permissions {
          flex-direction: column;

          .column {
            align-items: flex-start;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .share-footer {
      .option {
        max-width: 100%;

        .text {
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .copy-embed {
        .ui.input {
          width: calc(50% - 5px);

          input {
            display: none;
          }

          .ui.button {
            ${Theme.default.borderRadius};
            width: 100%;
          }
        }

        .ui.button {
          width: calc(50% - 5px);
        }
      }

      :only-child {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .copy-embed {
          flex-direction: column;

          .ui.input,
          > .ui.button {
            width: 100%;
            margin: 0;
          }

          > .ui.button {
            margin: 10px 0 0;
          }
        }
      }
    }
  }
`;

export const StyleItem = styled.div`
  display: flex;
  padding: var(--spacing-sm, 8px) 0px;
  align-items: flex-start;
  gap: var(--spacing-lg, 16px);
  align-self: stretch;
  border-top: 1px solid var(--color-gray-100, #e2e3e5);

  .item {
    align-items: center;
    justify-content: space-between;
  }

  :last-child {
    border-bottom: 0;
  }

  .space-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    color: var(--color-foreground, #404145);
    font-size: var(--font-size-md, 16px);
    font-weight: 400;
    line-height: var(--font-line-height-md, 24px);
  }

  .options {
    display: flex;
    align-items: center;
  }

  p {
    color: ${Theme.color.grey};
    font-size: 0.875em;
    font-style: italic;
    margin-bottom: 0;
    margin-left: auto;

    + .ui.checkbox {
      margin-left: 40px;
    }
  }

  @media screen and (max-width: 639px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledOption = styled.div`
  .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: var(--color-grey100);
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-grey100);
  }
  &.text.option {
    .title {
      font-weight: 700;
    }
    .description {
      visibility: visible;
    }
  }
  &.option {
    .title {
      font-weight: 400;
    }
    .description {
      visibility: hidden;
    }
  }
`;
