import { isUuid, UUID } from "@screencloud/uuid";
import React, { useEffect, useState } from "react";
import { FIRST_FETCH_ITEMS, MediaOrderBy } from "src/constants/constants";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useLoadMore } from "src/hooks/useLoadMore";
import {
  File,
  FilesOrderBy,
  Folder,
  FoldersOrderBy,
  useFolderByIdQuery,
  useMediaFilePickerForOrgWideQuery,
  useMediaFolderPickerForOrgWideQuery,
} from "src/types.g";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { useSearchMediaPickerForOrgWide } from "./useSearchMediaPickerForOrgWide";

interface MediaPicker {
  mediaFiles: File[];
  mediaFolders: Folder[];
  isLoading: boolean;
  renderFetchMorePickerButton: React.ReactNode;
  spaceId?: UUID;
}

interface Props {
  query: string | undefined;
  isSearchMode: boolean;
  isInRootFolder: boolean;
  folderId: string;
  isInRootSharedFolderFromOtherSpaces?: boolean | undefined;
  allowMediaMimeType?: string[] | undefined;
  filesOrderBy?: FilesOrderBy[];
  foldersOrderBy?: FoldersOrderBy[];
}

function useFetchChildFoldersFromRootFolders(
  isInRootFolder: boolean,
  isSearchMode: boolean
) {
  const { data, loading, fetchMore } = useMediaFolderPickerForOrgWideQuery({
    fetchPolicy: "cache-first",
    variables: {
      first: FIRST_FETCH_ITEMS,
      endCursor: null,
    },
    skip: !isInRootFolder || isSearchMode,
  });

  return {
    childFoldersData: data,
    childFolders: (data?.allChildFoldersFromAllRootFolders?.nodes ??
      []) as Folder[],
    childFoldersTotalCount: data?.allChildFoldersFromAllRootFolders?.totalCount,
    childFoldersEndCursor:
      data?.allChildFoldersFromAllRootFolders?.pageInfo.endCursor,
    childFoldersFetchMore: fetchMore,
    isChildFoldersHasNextPage:
      data?.allChildFoldersFromAllRootFolders?.pageInfo.hasNextPage,
    isChildFoldersLoading: loading,
  };
}

function useFetchFilesFromRootFolders(
  isChildFoldersHasNextPage: boolean,
  isInRootFolder: boolean,
  isSearchMode: boolean
) {
  const { data, loading, fetchMore } = useMediaFilePickerForOrgWideQuery({
    fetchPolicy: "cache-first",
    variables: {
      first: FIRST_FETCH_ITEMS,
      endCursor: null,
    },
    skip: !isInRootFolder || isSearchMode,
  });

  return {
    rootFilesData: data,
    rootFiles: isChildFoldersHasNextPage
      ? ([] as File[])
      : ((data?.allFilesFromAllRootFolders?.nodes ?? []) as File[]),
    rootFilesTotalCount: data?.allFilesFromAllRootFolders?.totalCount,
    rootFilesEndCursor: data?.allFilesFromAllRootFolders?.pageInfo.endCursor,
    rootFilesFetchMore: fetchMore,
    isRootFilesHasNextPage:
      data?.allFilesFromAllRootFolders?.pageInfo.hasNextPage,
    isRootFilesLoading: loading,
  };
}

function useFetchMediaFromNestedFolders(
  folderId: string,
  isInRootFolder: boolean,
  isSearchMode: boolean
) {
  const { data, loading, fetchMore } = useFolderByIdQuery({
    variables: {
      fileOrderBy: [MediaOrderBy.FILE_CREATED_AT_DESC],
      folderId,
      folderOrderBy: [MediaOrderBy.FOLDER_CREATED_AT_DESC],
      endCursor: null,
    },
    fetchPolicy: "cache-first",
    skip: !isUuid(folderId) || isSearchMode || isInRootFolder,
  });

  return {
    nestedMediaData: data,
    nestedFolders: (data?.folderById?.foldersByParentId.nodes ??
      []) as Folder[],
    nestedFiles: (data?.folderById?.filesByFolderId.nodes ?? []) as File[],
    nestedFilesTotalCount: data?.folderById?.filesByFolderId.totalCount,
    nestedFilesEndCursor: data?.folderById?.filesByFolderId.pageInfo.endCursor,
    nestedMediaFetchMore: fetchMore,
    isNestedFilesHasNextPage:
      data?.folderById?.filesByFolderId.pageInfo.hasNextPage,
    isNestedMediaLoading: loading,
  };
}

export function useMediaPickerForOrgWide({
  query,
  isSearchMode,
  isInRootFolder,
  folderId,
}: Props): MediaPicker {
  const isOrgWide = isOrgWidePath();
  const [mediaFolders, setMediaFolders] = useState([] as Folder[]);
  const [mediaFiles, setMediaFiles] = useState([] as File[]);

  const {
    childFoldersData,
    childFolders,
    childFoldersTotalCount,
    childFoldersEndCursor,
    childFoldersFetchMore,
    isChildFoldersHasNextPage,
    isChildFoldersLoading,
  } = useFetchChildFoldersFromRootFolders(isInRootFolder, isSearchMode);

  const {
    rootFilesData,
    rootFiles,
    rootFilesTotalCount,
    rootFilesEndCursor,
    rootFilesFetchMore,
    isRootFilesHasNextPage,
    isRootFilesLoading,
  } = useFetchFilesFromRootFolders(
    Boolean(isChildFoldersHasNextPage),
    isInRootFolder,
    isSearchMode
  );

  const {
    nestedMediaData,
    nestedFolders,
    nestedFiles,
    nestedFilesTotalCount,
    nestedFilesEndCursor,
    nestedMediaFetchMore,
    isNestedFilesHasNextPage,
    isNestedMediaLoading,
  } = useFetchMediaFromNestedFolders(folderId, isInRootFolder, isSearchMode);

  const {
    mediaFilesSearchResults,
    mediaFoldersSearchResults,
    isSearchMediaLoading,
    renderFetchMoreButton: searchFetchMoreButton,
  } = useSearchMediaPickerForOrgWide(query);

  let loadMore: () => void;
  let isLoading: boolean;
  if (isInRootFolder) {
    if (isChildFoldersHasNextPage) {
      ({ isLoading, loadMore } = useLoadMore(
        childFoldersFetchMore,
        childFoldersEndCursor
      ));
    } else {
      ({ isLoading, loadMore } = useLoadMore(
        rootFilesFetchMore,
        rootFilesEndCursor
      ));
    }
  } else {
    ({ isLoading, loadMore } = useLoadMore(
      nestedMediaFetchMore,
      nestedFilesEndCursor
    ));
  }

  const pickerFetchMoreButton = renderFetchMorePickerButton(
    isInRootFolder
      ? Number(childFolders.length + rootFiles.length)
      : Number(mediaFiles.length),
    isInRootFolder
      ? Number(childFoldersTotalCount! + rootFilesTotalCount!)
      : Number(nestedFilesTotalCount),
    isLoading,
    isInRootFolder
      ? Boolean(isChildFoldersHasNextPage || isRootFilesHasNextPage)
      : Boolean(isNestedFilesHasNextPage),
    loadMore
  );

  useEffect(() => {
    if (isSearchMode) {
      setMediaFolders(mediaFoldersSearchResults);
      setMediaFiles(mediaFilesSearchResults);
    } else {
      setMediaFolders(isInRootFolder ? childFolders : nestedFolders);
      setMediaFiles(isInRootFolder && isOrgWide ? rootFiles : nestedFiles);
    }
  }, [
    childFoldersData,
    isChildFoldersLoading,
    rootFilesData,
    isRootFilesLoading,
    nestedMediaData,
    isNestedMediaLoading,
    isSearchMediaLoading,
  ]);

  return {
    mediaFolders,
    mediaFiles,
    isLoading:
      isChildFoldersLoading ||
      isRootFilesLoading ||
      isNestedMediaLoading ||
      isSearchMediaLoading,
    renderFetchMorePickerButton: isSearchMode
      ? searchFetchMoreButton
      : pickerFetchMoreButton,
  };
}
