import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  ${Theme.default.borderRadius};
  background: ${Theme.color.white};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-out;
  margin: 0 10px 20px;
  width: calc(16.66% - 20px);
  position: relative;
  // cursor: pointer;
  // TODO: restore when site recorder can show existing sites

  .site-thumbnail {
    border-radius: ${Theme.default.borderRadiusValue}
      ${Theme.default.borderRadiusValue} 0 0;
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    background-image: linear-gradient(
      119deg,
      #83838a,
      ${Theme.color.darkGrey} 97%
    );
    box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.05);

    > img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }

    > i {
      object-fit: cover;
      position: absolute;
      top: 12.5%;
      left: 12.5%;
      height: 75%;
      width: 75%;
    }
  }

  .site-info {
    align-items: center;
    display: block;
    padding: 15px;

    .site-subtext {
      align-items: center;
      color: ${Theme.color.base};
      display: flex;
      width: calc(100% - 40px);

      h3 {
        color: ${Theme.color.grey};
        font-size: 0.875em;
        font-weight: normal;
        white-space: nowrap;
        display: inline-flex;
        line-height: 1.4285em;
      }
    }

    .site-title {
      align-items: center;
      color: ${Theme.color.base};
      display: flex;
      margin: 0;
      flex-grow: 1;

      h3 {
        color: ${Theme.color.darkGrey};
        font-size: 1em;
        margin: 0;
        white-space: nowrap;
        width: 90%;
        display: inline-flex;
        align-items: center;

        > span {
          font-size: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          line-height: 1.4285em;
        }

        i {
          background-color: ${Theme.color.lightGrey};
          margin-left: 5px;
          line-height: inherit;
          -webkit-mask-size: 14px;
          mask-size: 14px;
          visibility: hidden;
          flex-shrink: 0;

          :hover {
            background-color: ${Theme.color.base};
          }
        }

        :hover {
          color: ${Theme.color.base};

          i {
            visibility: visible;
          }
        }
      }

      .inline-input {
        .ui.input {
          input {
            font-size: 1em;
            font-weight: bold;
            line-height: 1.3125em;
          }
        }
      }
    }
  }

  .site-options {
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;

    :empty {
      display: none;
    }

    .media-dropdown {
      align-items: center;
      background: rgba(0, 0, 0, 0.2);
      ${Theme.default.borderRadius};
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      width: 100%;
      transition: all 0.1s ease-out;

      i {
        background-color: ${Theme.color.white};
      }

      &.active,
      :hover {
        opacity: 1;
        background: rgba(0, 0, 0, 0.4);
        i {
          background-color: var(--color-white-100);
        }
      }

      .menu {
        right: 0;
        left: auto;
        .item {
          .delete {
            color: ${Theme.color.red};
          }

          i {
            background: ${Theme.color.darkGrey};
          }

          &.disabled {
            display: flex;
            flex-direction: row;
            width: 150px;

            i {
              background: ${Theme.color.grey};
            }

            span.option-disabled {
              line-height: 1.357;
              width: 120px;
              font-weight: normal;
              font-size: 0.875em;
              margin-top: 10px;
              color: ${Theme.color.base};
              white-space: normal;

              span {
                color: ${Theme.color.base};
              }
            }
          }
        }
      }
    }
  }

  .site-icons {
    display: flex;
    position: absolute;
    top: 8px;
    left: 8px;
    background: var(--color-white-100);
    box-shadow: inset 0 0 0 1px var(--color-gray-100);
    border-radius: var(--radius-md);

    .site-share {
      :empty {
        display: none;
      }
    }

    .site-casting {
      order: 2;
      justify-content: flex-start;
    }
  }

  :hover {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.2);
    .site-options {
      .media-dropdown {
        opacity: 1;
      }
    }
  }

  @media (max-width: ${Theme.break.largeFHD}) {
    width: calc(20% - 20px);
  }
  @media (max-width: ${Theme.break.largeXHD}) {
    width: calc(25% - 20px);
  }
  @media (max-width: ${Theme.break.largeHD}) {
    width: calc(25% - 20px);

    .site-info {
      .site-options {
        .media-dropdown {
          opacity: 1;
        }
      }
    }
  }
  @media (max-width: ${Theme.break.mediumAlt}) {
    width: calc(33.333% - 20px);

    .site-info {
      .site-title {
        h3 {
          font-size: 1em;
          max-height: 44px;

          .inline-input {
            .ui.input {
              input {
                font-size: 1em;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${Theme.break.small}) {
    width: calc(50% - 20px);
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    width: calc(100% - 20px);
    padding: 0 10px 0 0;
    display: inline-flex;
    height: 60px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .site-thumbnail {
      ${Theme.default.borderRadius};
      margin-right: 10px;
      padding-bottom: 60px;
      width: 106px;
    }

    .site-info {
      padding: 0;
      width: calc(100% - 106px);
      align-items: center;
    }
  }
`;
