import { Loader, Theme } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import EmptyState from "../../components/EmptyState";
import { AvailableAppInstanceFragment } from "../../types.g";
import { ApolloProps, AppInstanceQueryInputProps, withData } from "./apollo";
import AppTabPaneItem, {
  AppTabPaneItemActionEnum,
  AppTabPaneItemActions,
} from "./appItem";
import { Styled } from "./styles";
export interface AppTabPaneProps extends AppInstanceQueryInputProps {
  addItemToPlaylist: (
    app: AvailableAppInstanceFragment,
    action: AppTabPaneItemActions
  ) => void;
  iconUrl?: string | null;
}

class AppTabPaneList extends React.Component<
  AppTabPaneProps & ApolloProps,
  {}
> {
  public static contextType = AppContext;
  public context: AppContextType;

  public onShowAppconfigure = async (app: AvailableAppInstanceFragment) => {
    if (app) {
      const { success } = await this.context.modal.openAppConfigure(
        app.id,
        undefined,
        undefined,
        undefined,
        <FormattedMessage id="apps.configure" defaultMessage="Configure" />
      );
      if (success && this.props.addItemToPlaylist) {
        this.props.addItemToPlaylist(app, AppTabPaneItemActionEnum.PREVIEW);
      }
    }
  };

  public getFilterAppBySearch = () => {
    if (
      this.props.searchTerms.length > 0 &&
      this.props.searchAppInstance?.appInstances
    ) {
      return this.props.searchAppInstance.appInstances;
    } else if (this.props.appInstances) {
      return this.props.appInstances;
    } else {
      return [];
    }
  };

  public render() {
    const { searchTerms, loading, searchAppInstance } = this.props;
    const hasSearchTerm = searchTerms.length;
    const appInstances: any = this.getFilterAppBySearch();
    const isLoading = !!(
      loading ||
      !this.props.appInstances ||
      (hasSearchTerm && !this.props.searchAppInstance?.appInstances)
    );
    return (
      <Styled>
        {isLoading ? (
          <div className="app-loader">
            <Loader inline size="small" />
          </div>
        ) : (
          <>
            {appInstances.length
              ? appInstances.map((appNode, idx) => {
                  return (
                    <Droppable
                      isDropDisabled={true}
                      droppableId={`app-${appNode.id}`}
                      key={appNode.id}
                    >
                      {(
                        droppableProvided: DroppableProvided,
                        droppableSnapshot: DroppableStateSnapshot
                      ) => (
                        <div
                          className="column app-item"
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                          style={{
                            backgroundColor: droppableSnapshot.isDraggingOver
                              ? Theme.color.silver
                              : "transparent",
                          }}
                        >
                          <AppTabPaneItem
                            iconUrl={this.props.iconUrl}
                            addItemToPlaylist={this.props.addItemToPlaylist}
                            onShowAppConfigure={this.onShowAppconfigure}
                            index={idx}
                            app={appNode}
                          />
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                })
              : null}
            {hasSearchTerm
              ? this.props.searchAppInstance.renderFetchMoreButton
              : this.props.renderFetchMoreButton}
            {hasSearchTerm &&
            !loading &&
            !searchAppInstance.loading &&
            appInstances.length === 0 ? (
              <EmptyState section="app-sidebar" cover={false} className="empty">
                <p>
                  <FormattedMessage
                    id="common.text.no_result_found"
                    defaultMessage="No results found"
                  />
                </p>
              </EmptyState>
            ) : null}
            {!hasSearchTerm &&
            !loading &&
            !loading &&
            appInstances.length === 0 ? (
              <EmptyState section="app-sidebar" cover={false} className="empty">
                <h3>No apps instance yet</h3>
                <p>Create your first App instance</p>
              </EmptyState>
            ) : null}
          </>
        )}
      </Styled>
    );
  }
}

export default withData(AppTabPaneList) as React.ComponentType<AppTabPaneProps>;
