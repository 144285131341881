import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import { CoverColor, CoverImage } from "../../../components/CoverThumbnail";

export interface StyledChangeCoverProps {
  type: string;
  color: CoverColor;
  image: CoverImage;
}

export const StyledOnboarding = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  min-height: 320px;
  height: 100%;

  .make-emojis-large {
    font-size: 70px;
    line-height: 1;
  }

  h1 {
    font-style: italic;
    font-weight: 900;
    margin: 10px 0 20px;
  }

  .buttons {
    margin-top: 20px;
  }

  a {
    color: ${Theme.color.base};
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .buttons {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;

      .button {
        width: 100%;
        margin: 5px 0px;
      }
    }
  }
`;

export const Styled = styled.div<{ isEditableSharedChannel: boolean }>`
  .player-preview {
    > .preview {
      > .playback-bar {
        > .playback-buttons {
          width: calc(100% - 80px);
          position: absolute;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  &.channel-detail {
    height: 100%;

    .column-title {
      h1 {
        line-height: initial;
      }
    }

    .btn-content-onboarding {
      background-color: ${Theme.color.white} !important;
    }

    > div {
      height: 100%;
    }

    .header {
      height: 90px;
      flex-shrink: 0;

      .wrapper {
        &.detail {
          .column-options {
            > .ui.button {
              min-width: var(--size-xl, 44px);

              &.share-button,
              &.button-casting {
                padding: 0;
              }
            }
          }
        }
      }
    }

    .container {
      padding: 0;
      display: flex;
      flex-direction: column;

      .channel-content-options {
        display: flex;
        justify-content: space-between;
        padding: var(--spacing-lg, 16px) var(--spacing-xxl, 40px)
          var(--spacing-lg, 16px) var(--spacing-xxxl, 56px);
        gap: var(--spacing-sm, 8px);
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 7;
        background-color: var(--color-gray-50);

        :empty {
          display: none;

          + .channel-content-list {
            height: calc(100% - 20px);
          }
        }

        #zone-dropdown {
          height: var(--size-xl, 44px);
          user-select: none;
          width: 240px;

          > .text {
            font-weight: bold;
          }

          .menu {
            max-height: 410px;
            overflow: auto;
            max-width: 240px;
            width: 100%;

            .item {
              flex-shrink: 0;
              width: 100%;
              height: 40px;
              position: relative;
              padding: 0 15px;
              display: flex;
              font-size: 1em;
              align-items: center;
              justify-content: space-between;

              .text {
                color: ${Theme.color.darkGrey};
                font-size: 0.875em;
              }

              .zone {
                border: none;

                &.zone-landscape {
                  width: 40px;
                  height: 22.5px;
                }

                &.zone-portrait {
                  height: 40px;
                  width: 22.5px;
                }

                &:last-child {
                  margin-left: 20px;
                }
              }

              .zone-title {
                font-size: 1em;
                display: inline-block;
                font-weight: normal;
              }

              .zone-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .fit-fill {
          margin-right: auto;
          min-width: 142px;
        }
      }

      .channel-content-list {
        width: 100%;

        > div {
          padding: 0 0 var(--spacing-xxxl, 56px) var(--spacing-xxxl, 56px);
        }

        .empty-channel-detail {
          height: 100%;
          padding: 0;

          + div:empty {
            display: none;
          }
        }

        div[draggable="false"] {
          background: ${({ isEditableSharedChannel }) =>
            isEditableSharedChannel
              ? `var(--color-yellow-50)`
              : `var(--color-background)`};
          border: 1px solid
            ${({ isEditableSharedChannel }) =>
              isEditableSharedChannel
                ? `var(--color-yellow-500, #FECF00);`
                : `var(--color-gray-100)`};
          border-top: 1px solid transparent;
          margin-right: 40px;
          position: relative;

          &:hover {
            z-index: 3;
          }
        }

        div[draggable="false"]:first-child {
          border-radius: ${Theme.default.borderRadiusValue}
            ${Theme.default.borderRadiusValue} 0 0;
          border: 1px solid
            ${({ isEditableSharedChannel }) =>
              isEditableSharedChannel
                ? `var(--color-yellow-500, #FECF00);`
                : `var(--color-gray-100)`};
        }

        div[draggable="false"]:last-child {
          border-radius: 0 0 ${Theme.default.borderRadiusValue}
            ${Theme.default.borderRadiusValue};
        }

        div[draggable="false"]:only-child {
          ${Theme.default.borderRadius};
        }

        .cta {
          padding: 0 20px 20px;
          display: none;
        }

        .read-only {
          width: 100%;
          background: var(--color-background);
          border: 1px solid var(--color-gray-100);
          border-top: 1px solid transparent;
          position: relative;
          &.first-child {
            border-radius: ${Theme.default.borderRadiusValue}
              ${Theme.default.borderRadiusValue} 0 0;
            border: 1px solid var(--color-gray-100);
          }

          :last-child {
            border-radius: 0 0 ${Theme.default.borderRadiusValue}
              ${Theme.default.borderRadiusValue};
          }
          :only-child {
            ${Theme.default.borderRadius};
          }
        }
      }
    }

    .sidebar-channel {
      .ui.menu.secondary {
        .item {
          max-width: none;
        }
      }

      .wrapper {
        display: flex;
        flex-direction: row;
        padding: 20px;
        justify-content: center;
        flex-wrap: wrap;

        .panel {
          width: 100%;
          height: 220px;
          border: 1px solid ${Theme.color.lightGrey};
          ${Theme.default.borderRadius};
          background-color: ${Theme.color.nearWhite};
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .zone-picker {
          display: flex;
          justify-content: space-between;

          > div {
            width: calc(50% - 10px);

            .ui.checkbox {
              min-height: 0;
              margin: 0 0 10px;
              width: 100%;
              display: inline-flex;
              justify-content: center;
              label {
                font-size: 0.875em;
                padding-left: 25px;
              }
            }

            .zone-picker-layout {
              width: 100%;
              padding-bottom: 100%;
              height: 0;
              position: relative;

              > .button {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                .zone-landscape {
                  width: 90px;
                  height: 50.625px;
                }

                .zone-portrait {
                  height: 90px;
                  width: 50.625px;
                }
              }
            }
          }
        }

        .option {
          padding: 10px 0;
          display: flex;
          width: 100%;
          justify-content: space-between;

          h3 {
            font-weight: normal;
            margin: 0;
            display: flex;
            gap: 5px;
            align-items: center;

            > i {
              background-color: var(--color-grey100);
              width: 16px;
              height: 16px;
            }
          }

          .ui.selection.dropdown {
            .trigger {
              text-transform: capitalize;
            }
          }
        }

        .custom-transition-pad {
          display: flex;
          flex-direction: column;
          background: rgb(248, 248, 248);
          border: 1px solid rgb(238, 238, 238);
          ${Theme.default.borderRadius};
          padding: 10px;
          gap: 10px;

          .row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .color-picker {
              .popover {
                .sketch-picker {
                  margin-left: -72px;
                }
              }
            }

            .ui.input.duration-input {
              /* Chrome, Safari, Edge, Opera */
              -webkit-outer-spin-button,
              -webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              /* Firefox */
              -moz-appearance: textfield;
              width: 148px;

              .ui.label {
                font-size: 20px;
                line-height: 43px;
                padding: 0;
              }
            }

            .ui.selection.dropdown,
            > div > div.swatch {
              width: 148px;
              margin: 0;
            }
          }
        }

        .subparagraph {
          color: ${Theme.color.midGrey};
          font-size: 0.875em;
          line-height: 1.357;
          margin-top: 5px;
        }
      }
    }

    @media screen and (max-width: ${Theme.break.largeHD}) {
      .container {
        .channel-content-options {
          padding: var(--spacing-lg, 16px) var(--spacing-xxl, 40px);
        }

        .channel-content-list {
          > div {
            padding: 0 0 var(--spacing-xxl, 40px) var(--spacing-xxl, 40px);
          }
        }
      }
      .header {
        .wrapper.detail {
          .column-options {
            > .ui.button {
              &.btn-edit {
                width: 90px;
                padding: 0;
                span {
                  width: 45px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: ${Theme.break.mediumAlt}) {
      .header {
        .wrapper {
          .column-options {
            justify-content: flex-end;

            > .ui.button {
              max-width: 120px;
              &.btn-edit {
                margin-left: auto;
              }

              &.btn-preview {
                margin-left: auto;
              }
            }
          }
        }
      }

      .player-preview {
        height: calc(100% - 136px);
      }

      .container {
        width: 100%;
        flex-shrink: 0;
        height: auto;

        .channel-content-list {
          .empty-channel-detail {
            padding: 0 20px;
          }
        }
      }

      .sidebar-channel {
        height: calc(100% + 65px);

        .tabs {
          height: 100%;
        }

        .menu {
          width: 100%;
          z-index: 0;
        }

        .wrapper {
          height: auto;
          overflow: auto;
        }
      }
    }

    @media screen and (max-width: ${Theme.break.small}) {
      .container {
        .channel-content-options {
          padding: var(--spacing-lg, 16px) var(--spacing-lg, 16px);
          width: 100%;

          .btn-add-content {
            padding: 0;
            width: 45px;
            flex-shrink: 0;
            min-width: inherit;

            span {
              display: none;
            }
          }
        }

        .channel-content-list {
          > div {
            padding: 0 0 var(--spacing-xxl, 40px) var(--spacing-lg, 16px);
          }
        }
      }

      .sidebar-channel {
        padding-bottom: 65px;
        height: auto;
      }

      .header {
        .wrapper {
          &.detail {
            .column-options {
              justify-content: flex-end;

              > .ui.button,
              .button-publish {
                .button-group {
                  width: 100%;

                  .button-dropdown {
                    .menu {
                      ${Theme.default.borderRadius};
                      top: auto;
                      right: 0;
                    }
                  }

                  .button-action {
                    width: calc(100% - 32px);
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }

      .player-preview {
        height: calc(100% - 110px);

        .preview {
          .available-space {
            height: calc(100% - 190px);
          }
        }
      }
    }

    @media screen and (max-width: ${Theme.break.tiny}) {
      .header {
        .wrapper.detail {
          .column-title {
            max-width: calc(100% - 110px);
          }

          .column-options {
            .on-screen,
            .button-duplicate {
              display: none;
            }

            .button-publish {
              width: calc(100% - 100px);
            }
          }
        }
      }

      .player-preview {
        height: calc(100% - 135px);
      }

      .container {
        .channel-content-options {
          #zone-dropdown {
            min-width: 100px;

            .menu {
              .item {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
