import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { shouldRedirectToRootFolderId } from "../../pages/Media/MediasList/mediaListHelper";
import { getRootFolderId } from "../../helpers/folderHelper";

interface Params {
  updateFolderIdCallback?: () => any;
}

/**
 * Default to rootFolderId
 */
export function useStateFolderId(params?: Params) {
  const context = useAppContext();
  const rootFolderId = getRootFolderId(context);
  const [folderId, setFolderId] = useState(rootFolderId);
  const isFirstTimeRenderRef = useRef(true);
  useEffect(() => {
    if (isFirstTimeRenderRef.current) {
      isFirstTimeRenderRef.current = false;
    } else {
      params?.updateFolderIdCallback && params.updateFolderIdCallback();
    }
  }, [folderId]);

  const updateFolderId = (folderIdToUpdate: string) => {
    if (shouldRedirectToRootFolderId(folderIdToUpdate)) {
      setFolderId(getRootFolderId(context));
    } else {
      setFolderId(folderIdToUpdate);
    }
  };

  return { folderId, updateFolderId };
}
