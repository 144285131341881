import { Button, Icon } from "@screencloud/screencloud-ui-components";
import emptyDashboard from "../img/empty-dashboard.svg";
import { FormattedMessage } from "react-intl";
import {
  ButtonContainer,
  Container,
  DashboardContainer,
  Description,
  HelpButton,
  Title,
} from "./styles";
import {
  PERMISSION_GROUPS,
  PERMISSION_KEYS,
  hasSitePermissionByContext,
} from "src/pages/Sites/helpers/permissions";
import { useAppContext } from "src/hooks/useAppContext";
import { Constants } from "src/billinglatest/constants";
import { PrimaryButton } from "src/helpers/whiteLabel";

export const EmptyDashboardSection = (): JSX.Element => {
  const context = useAppContext();
  const firstName = context.currentUser?.givenName;
  const lastName = context.currentUser?.familyName;
  const email = context.currentUser?.email;
  const jobTitle = context.currentUser?.preferences.userInfo?.job?.title;
  const jobFunction =
    context.currentUser?.preferences.userInfo?.job?.jobFunction;
  const orgName = context.currentOrg?.name;
  const country =
    Constants.Countries[context.currentOrg?.preferences.settings?.country_code];

  const generateDemoRequestUrl = () => {
    let url = "https://screencloud.com/demo-request?";

    const params = {
      firstname: firstName,
      lastname: lastName,
      email,
      company: orgName,
      jobtitle: jobTitle,
      job_function: jobFunction,
      country,
    };

    Object.keys(params).forEach((key, index) => {
      if (params[key]) {
        url += `${index !== 0 ? "&" : ""}${key}=${encodeURIComponent(
          params[key]
        )}`;
      }
    });

    return url;
  };
  const onBookDemoClick = () => {
    window.open(generateDemoRequestUrl(), "_blank");
  };

  const onReadDocumentationClick = () => {
    window.open(
      "https://screencloud.com/learn/screencloud-dashboards-guide",
      "_blank"
    );
  };
  return (
    <Container>
      <img className="scos-device-img" src={emptyDashboard} />

      <DashboardContainer>
        <Title>
          {" "}
          <FormattedMessage
            id="ui_component.dashboard_empty.title"
            defaultMessage="No dashboard added yet"
          />
        </Title>
        <Description>
          <FormattedMessage
            id="ui_component.dashboard_empty.description"
            defaultMessage="Setup your first dashboard or check out the documentation for more information."
          />
        </Description>
        <ButtonContainer>
          {hasSitePermissionByContext(
            context,
            PERMISSION_GROUPS.Site,
            PERMISSION_KEYS.Create
          ) && (
            <PrimaryButton
              className="dashboard-button"
              onClick={() => context.modal.openNewSiteModal()}
            >
              <FormattedMessage
                id="ui_component.dashboard_empty.setup_first_dashboard"
                defaultMessage="Setup your first dashboard"
              />{" "}
            </PrimaryButton>
          )}
          <Button
            className="dashboard-button"
            onClick={onReadDocumentationClick}
          >
            <FormattedMessage
              id="ui_component.dashboard_empty.read_documentation"
              defaultMessage="Read documentation"
            />{" "}
            <Icon name="guide" />
          </Button>
        </ButtonContainer>
        <HelpButton className="help-button" inline onClick={onBookDemoClick}>
          <FormattedMessage
            id="ui_component.dashboard_empty.book_demo"
            defaultMessage="Need help? Book a demo with us"
          />{" "}
          <Icon name="arrow-up-right-circle" />
        </HelpButton>
      </DashboardContainer>
    </Container>
  );
};
