import { useState } from "react";
import { useScreenListForScreenPicker } from "src/hooks/useScreenListForScreenPicker";
import { useSearchScreen } from "src/hooks/useSearchScreen";
import { useSelectAll } from "src/hooks/useSelectAll";
import { useStateSearch } from "src/hooks/useStateSearch";
import { ScreensByScreenGroupIdAndOrgIdFragment } from "src/types.g";
import { compose } from "src/utils/compose";
import { SingleScreenPickerProps } from "./SingleScreenPicker";

export const withData = compose(
  (Component: any) => (props: SingleScreenPickerProps) => {
    return <Component {...props} {...useStateSearch()} />;
  },
  (Component: any) => (props: SingleScreenPickerProps) => {
    const { query, isSearch } = props;
    const screenListProps = useScreenListForScreenPicker();
    const searchScreenProps = useSearchScreen({ query });

    const screenProps = isSearch ? searchScreenProps : screenListProps;

    return <Component {...props} {...screenProps} />;
  },
  (Component: any) => (props: SingleScreenPickerProps) => {
    const { screens, totalCount } = props;
    const [selectedScreens, setSetlectedScreens] = useState<
      ScreensByScreenGroupIdAndOrgIdFragment[]
    >([]);
    const {
      isSelectAll,
      isSelectAllIndeterminate,
      updateSelectedItems,
      toggleSelectedAll,
      clearSelectedItems,
    } = useSelectAll({
      allItems: screens,
      allItemTotalCount: totalCount,
      setSelectedItems: setSetlectedScreens,
    });
    return (
      <Component
        {...props}
        selectedScreens={selectedScreens}
        isSelectAll={isSelectAll}
        isSelectAllIndeterminate={isSelectAllIndeterminate}
        updateSelectedScreen={updateSelectedItems}
        toggleSelectedAll={toggleSelectedAll}
        clearSelectedItems={clearSelectedItems}
      />
    );
  }
);
