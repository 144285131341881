import { Maybe, useCurrentUserQuery, User } from "src/types.g";

export interface WithCachedCurrentUser {
  currentUser?: Maybe<User>;
}

const withCachedCurrentUser = <T extends {}>(
  Component: React.ComponentType<T>
) => (props: T) => {
  const { data: currentUserQuery } = useCurrentUserQuery({
    fetchPolicy: "cache-only",
  });

  return <Component {...props} currentUser={currentUserQuery?.currentUser} />;
};

export default withCachedCurrentUser;
