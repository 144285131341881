import { Button, Icon, Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledContainer = styled.div<{
  isFullWidth: boolean;
  isTrialEnd?: boolean;
}>`
  display: flex;
  background-color: ${(props) =>
    props.isTrialEnd ? Theme.color.red100 : Theme.color.yellow50};
  color: ${(props) =>
    props.isTrialEnd ? Theme.color.white : Theme.color.grey100};
  padding: ${Theme.default.verticalPadding} ${Theme.default.horizontalPadding};
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${Theme.break.small}) {
    align-items: flex-start;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${Theme.break.small}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledIcon = styled(Icon)<{ isTrialEnd?: boolean }>`
  background-color: ${(props) =>
    props.isTrialEnd ? Theme.color.white : Theme.color.grey100};
  min-width: 24px;
`;

export const StyledMessageContainer = styled.span`
  margin: 0 10px;
`;

export const StyledButton = styled(Button)<{ isTrialEnd?: boolean }>`
  &.button {
    background-color: ${(props) =>
      props.isTrialEnd
        ? `var(--color-primary-background)`
        : `var(--color-yellow-200)`};
    border: none;
  }

  @media screen and (max-width: ${Theme.break.small}) {
    margin: 5px 10px;
  }
`;
