import styled from "styled-components";

export const Styled = styled.div`
  border-bottom: 1px solid #f8f8f8;
  position: relative;
  display: flex;
  height: 40px;
  padding: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #f8f8f8;
  }

  .job-title {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 0.75em;
    line-height: 1;
  }

  i {
    background-color: black;
    width: 50px;
    height: 40px;
    margin: 0;
    mask-size: 20px;
  }
`;
