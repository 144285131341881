import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const ChannelPlayingScreenStyled = styled.div`
  display: inline-block;
  min-width: 280px;
  margin: 0 -10px;

  .casting {
    height: 40px;
    background-color: transparent;
    box-shadow: none;

    i {
      margin-bottom: -1px;
    }

    .screen-count {
      color: ${Theme.color.darkGrey};
    }

    :hover,
    :active,
    :focus {
      background-color: ${Theme.color.silver};
      box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
      height: 40px;

      .screen-count {
        color: ${Theme.color.base};
      }
    }
  }
  .channel-play-on {
    width: calc(100% - 20px);
    margin: 10px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    justify-content: center;
    align-items: center;

    i {
      background-color: ${Theme.color.base};
    }
  }
  .cast-container {
    overflow: hidden;
    max-height: 300px;
    min-height: 50px;
    overflow-y: auto;
    padding: 0 10px;
  }
  .loadmore-button {
    text-align: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    background: ${Theme.color.base};
    ${Theme.default.borderRadius};
    margin-top: 10px;
    user-select: none;
  }
  .header {
    background: ${Theme.color.base};
    border-radius: ${Theme.default.borderRadiusValue}
      ${Theme.default.borderRadiusValue} 0 0;
    margin: -6px -2px 0;
    padding: 12px;
    width: calc(100% + 4px);

    span {
      font-weight: bold;
    }
  }

  .screen-item {
    font-size: 16px;
    height: 60px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${Theme.color.darkGreyHover};
    width: 260px;

    .screen-alpha {
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        cursor: pointer;
        font-size: 1em;
        font-weight: normal;
      }

      span {
        color: ${Theme.color.grey};
        font-size: 0.875em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .screen-pending {
      .loader {
        background-color: ${Theme.color.red};
        border-radius: 100%;
        padding: 0;
        left: 0;
        width: 28px;
        height: 28px;

        :before {
          border-color: ${Theme.color.white};
          opacity: 0.3;
          right: 6px;
          top: 6px;
        }

        :after {
          border-color: ${Theme.color.white} transparent transparent;
          right: 6px;
          top: 6px;
        }
      }
    }

    .screen-stop {
      border: 1px solid ${Theme.color.grey};
      border-radius: 100%;
      width: 28px;
      height: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        width: 20px;
        height: 20px;
        background-color: ${Theme.color.grey};
      }

      :hover {
        border-color: ${Theme.color.red};
        background: ${Theme.color.red};

        i {
          background-color: ${Theme.color.white};
        }
      }
    }

    :last-child {
      border-bottom: none;
    }
  }
`;
