import { Checkbox } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { StyleItem } from "../styles";

export interface ShareItemProps {
  id: string;
  name?: string;
  isDisabled?: boolean;
  isShared?: boolean;
  isOwner: boolean;
  onItemChange?: (spaceId: string, shared: boolean) => void;
}

const ShareItem = (props: ShareItemProps) => {
  const handleChange = (event: React.SyntheticEvent<any>, data: any) => {
    if (props.onItemChange) {
      props.onItemChange(data.value, data.checked);
    }
  };
  if (props.isOwner) {
    return null;
  }

  return (
    <StyleItem className={props.isShared ? "active" : ""}>
      <div className="space-name">
        <span>{props.name}</span>
      </div>
      <div className="options">
        <Checkbox
          toggle
          value={props.id}
          disabled={props.isDisabled}
          checked={props.isShared}
          onChange={handleChange}
          data-testid={`${props.name}-checkbox`}
        />
      </div>
    </StyleItem>
  );
};

export default ShareItem;
