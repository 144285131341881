import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { compose } from "../../utils/compose";
import { UUID } from "@screencloud/uuid";
import { CastByChannelByIdProps } from "../../types.g";
import { useChannelPicker, UseChannelPicker } from "./hooks/useChannelPicker";
import { UseStateSearch, useStateSearch } from "../../hooks/useStateSearch";
export interface ApolloProps extends UseStateSearch, WithApolloClient<{}> {
  channelListData: UseChannelPicker;
}

export interface ChannelPickerItemApolloProps extends CastByChannelByIdProps {
  id: UUID;
}

export const withData = compose(withApollo, (Component) => (props: any) => {
  return (
    <Component
      {...props}
      {...useStateSearch()}
      channelListData={useChannelPicker(props.spaceId)}
    />
  );
});
