import { ApolloQueryResult } from "@apollo/client";
import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import {
  Accordion,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
  LoaderBar,
  Theme,
} from "@screencloud/screencloud-ui-components";
import {
  ListContentItem,
  RefType,
  StandaloneMediaContent,
} from "@screencloud/signage-graphql-client";
import {
  IListContentItemExtended,
  IScreen,
  IStandaloneMediaContent,
  IZoneListReport,
  IZonePlaybackStartingPoint,
  IZonePlaybackState,
  LIST_ITEM_PARENT_ENTITY_TYPE,
  screenStub,
  ZoneRenderType,
} from "@screencloud/signage-player";
import {
  ActiveItemChangeHandler,
  ActiveItemChangeMessageType,
  ContentListReportItem,
  ContentListReportUpdateHandler,
  ContentListReportUpdateMessageType,
  ContentListUpdateReport,
  renderStudioPlayer,
  StudioPlayer,
} from "@screencloud/studio-player-sdk";
import classNames from "clsx";
import { cloneDeep, debounce, flow, get } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import {
  DEBOUNCE_TIMEOUT_MS,
  Device,
  MediaType,
  NEW_PLAYER_PREVIEW_WHITELIST,
  Orientation,
  PreviewDeviceList,
  UUID,
} from "../../constants/constants";
import deviceResolutions from "../../constants/deviceResolutions";
import {
  CHANNEL_PLAYER_ZONE_PREFIX,
  convertToChannelPlayerZone,
  countZone,
  getZoneOptions,
} from "../../helpers/channelHelper";
import {
  getDevice,
  getDeviceKeyWidthAndHeight,
} from "../../helpers/deviceHelper";
import { getMediaType } from "../../helpers/mediaHelper";
import {
  mapEnvToPlayerSdk,
  mapRegionToPlayerSdk,
} from "../../helpers/playerSdkHelper";
import { PrimaryButton } from "../../helpers/whiteLabel";
import {
  Channel,
  ChannelByIdForPlayerPreviewDocument,
  ChannelByIdForPlayerPreviewQuery,
  ChannelByIdForPlayerPreviewQueryVariables,
  Maybe,
  Playlist,
  PlaylistByIdForPlayerPreviewDocument,
  PlaylistByIdForPlayerPreviewQuery,
  PlaylistByIdForPlayerPreviewQueryVariables,
  Scalars,
} from "../../types.g";
import { getRequestFullscreenFunc, getStudioPlayerUrl } from "../../utils";
import { compose } from "../../utils/compose";
import RuleIcons, { hasDisplayingRules } from "./RuleIcons/RuleIcons";
import { Styled } from "./styles";
import {
  ContentItemViewModel,
  getContenItemViewModelsByChannel,
  getContenItemViewModelsByPlaylist,
  ItemInScheduleMap,
  PlayerPreviewItemViewModel,
  PlayListContentItemViewModel,
} from "./viewModel";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";

export const PLAYER_ROOT_ZONE_ID = "Root";

function convertStudioPlayerZoneIdToOldFormat(zoneId?: string): string {
  if (zoneId) {
    return convertToChannelPlayerZone(zoneId);
  } else {
    return PLAYER_ROOT_ZONE_ID;
  }
}

function convertOldZoneIdToNewStudioPlayerFormat(
  zoneId: string
): string | undefined {
  return zoneId.startsWith(CHANNEL_PLAYER_ZONE_PREFIX)
    ? zoneId.replace(CHANNEL_PLAYER_ZONE_PREFIX, "")
    : undefined;
}

export interface ZonePlaybackState {
  id: string;
  itemContent: {
    list_id: string;
    content: IStandaloneMediaContent;
    parentEntityListId?: string;
  };
}

interface ApolloProps extends WithApolloClient<any> {}

export interface Props {
  id: UUID;
  previewType: PreviewType;
  orientation?: Orientation;
  presetWidth?: number | null;
  presetHeight?: number | null;
  /**
   * page mode is currently used on
   */
  isPageMode?: boolean;
  onEditButonClick?: () => void;
  onFullScreenClick?: () => void;
  showExitFullScreenButton?: boolean;
  showFullScreenButton?: boolean;
  showToggleControlButton?: boolean;
  onExitFullScreenClicked?: () => void;
  defaultSelectedZone?: string;
  onZoneChange?: (zone: string) => void;
  setRefreshMethod?: (refresh: () => void) => void;
  showPlayerOnly?: boolean;
  isOwner?: boolean;
}

export interface State {
  currentOrientation: Orientation;
  isFullscreenMode: boolean;
  isMaxPlayerWidthReached: boolean;
  playbackState: ZonePlaybackState[] | null;
  playerAspectRatio: number;
  playerWidth: string;
  previewDeviceKey: string;
  previewResolutionWidth: string;
  previewResolutionHeight: string;
  zonesListReport: IZoneListReport[] | null;
  isPMIClientConnected: boolean;
  selectedZone: string;
  zoneStartingPoints: IZonePlaybackStartingPoint[];
  screenData: (IScreen & { name: string }) | null;
  playlistById: Maybe<Partial<Playlist>> | null;
  channelById: Maybe<Partial<Channel>> | null;
  hideControl: boolean;
  showPlayerOnly?: boolean;
  className?: string;
}

export enum PreviewType {
  CHANNEL = "CHANNEL",
  PLAYLIST = "PLAYLIST",
}

const PREVIEW_MAX_WIDTH_RATIO = 0.9;

export class PlayerPreview extends React.PureComponent<
  Props & ApolloProps,
  State
> {
  public static contextType = AppContext;
  public static defaultProps = {
    showToggleControlButton: true,
  };
  public context: AppContextType;

  public setPlayerWidth = debounce(() => {
    if (this.availableSpaceRef && this.availableSpaceRef.current) {
      const maxWidth =
        this.availableSpaceRef.current.offsetWidth * PREVIEW_MAX_WIDTH_RATIO;
      const maxHeight = this.availableSpaceRef.current.offsetHeight;

      const newWidth = maxHeight * this.state.playerAspectRatio;
      const isMaxPlayerWidthReached = newWidth > maxWidth;

      this.setState({
        isMaxPlayerWidthReached,
        playerWidth: `${isMaxPlayerWidthReached ? maxWidth : newWidth}px`,
      });
    }
  }, DEBOUNCE_TIMEOUT_MS);
  public studioPlayer: StudioPlayer | null = null;
  private availableSpaceRef: React.RefObject<HTMLDivElement>;
  private playerFullscreenWrapperRef: React.RefObject<HTMLDivElement>;
  private defaultDeviceKey: string = Device.FULL_HD_1080P;
  private playerPreviewTarget = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);

    let deviceKey = this.defaultDeviceKey;
    let { width, height } = deviceResolutions[deviceKey];
    const {
      defaultSelectedZone,
      presetWidth,
      presetHeight,
      previewType,
    } = this.props;

    if (presetWidth && presetHeight) {
      width = presetWidth;
      height = presetHeight;

      // find common device from key
      deviceKey = getDeviceKeyWidthAndHeight(width, height);
    }

    const selectedZone = defaultSelectedZone
      ? defaultSelectedZone
      : previewType === PreviewType.CHANNEL
      ? convertToChannelPlayerZone("zone1")
      : previewType === PreviewType.PLAYLIST
      ? PLAYER_ROOT_ZONE_ID
      : "";

    this.state = {
      channelById: null,
      currentOrientation: this.props.orientation || Orientation.Landscape,
      hideControl: false,
      isFullscreenMode: false,
      isMaxPlayerWidthReached: false,
      isPMIClientConnected: false,
      playbackState: [],
      playerAspectRatio: width / height,
      playerWidth: "90%",
      playlistById: null,
      previewDeviceKey: deviceKey,
      previewResolutionHeight: height.toString(),
      previewResolutionWidth: width.toString(),
      screenData: null,
      selectedZone,
      zoneStartingPoints: [],
      zonesListReport: [],
      showPlayerOnly: this.props.showPlayerOnly,
    };
    this.availableSpaceRef = React.createRef();
    this.playerFullscreenWrapperRef = React.createRef();
  }

  public refreshData = async () => {
    const { id } = this.props;
    let newScreenData: (IScreen & { name: string }) | null = null;
    if (this.isPlayList()) {
      const playlistData = await this.getPlaylistData(id);
      if (playlistData && playlistData.data?.playlistById) {
        const playlistById = playlistData.data.playlistById;
        newScreenData = {
          ...screenStub("playlist", playlistById as any),
          name: playlistById.name,
        };
        this.setState({
          playlistById: playlistById as Playlist,
        });

        this.setPlayerWidth();
      }
    } else {
      const channelData = await this.getChannelData(this.props.id);
      if (channelData?.data?.channelById) {
        const channelById = cloneDeep(channelData.data.channelById);
        newScreenData = {
          ...screenStub("channel", channelById as any),
          name: channelById.name,
        };
        this.setState({
          channelById: channelById as Channel,
        });
        this.setPlayerWidth();
      }

      this.setPlayerWidth();
    }
    this.setState({
      screenData: newScreenData,
    });
  };

  public async componentDidMount() {
    const { setRefreshMethod } = this.props;
    window.addEventListener("resize", this.setPlayerWidth);
    if (this.playerFullscreenWrapperRef.current) {
      this.playerFullscreenWrapperRef.current.addEventListener(
        "fullscreenchange",
        this.onFullscreenChange
      );
    }

    if (this.studioPlayer) {
      this.studioPlayer.on(
        ActiveItemChangeMessageType,
        this.onPlaybackStateChangeNew
      );
      this.studioPlayer.on(
        ContentListReportUpdateMessageType,
        this.onZoneListReportChangeNew
      );
    }

    this.refreshData();

    if (setRefreshMethod) {
      setRefreshMethod(this.refreshData);
    }
  }

  public componentDidUpdate(prevProp: Props, prevState: State) {
    if (
      this.state.playerAspectRatio !== prevState.playerAspectRatio ||
      this.state.currentOrientation !== prevState.currentOrientation ||
      this.state.previewResolutionWidth !== prevState.previewResolutionWidth ||
      this.state.previewResolutionHeight !==
        prevState.previewResolutionHeight ||
      this.state.previewDeviceKey !== prevState.previewDeviceKey
    ) {
      this.setPlayerWidth();
    }

    if (!this.studioPlayer && this.playerPreviewTarget.current) {
      const currentRegion = mapRegionToPlayerSdk(appConfig.studioPlayerRegion);
      const currentEnvironment = mapEnvToPlayerSdk(
        appConfig.studioPlayerEnvironment
      );
      const playerDevicePlatform = "studio";
      const playerDeviceModel = `${
        this.isPlayList() ? "playlist" : "channel"
      }-preview`;
      // needs to render studio player here because ref hasn't been yet set up on componentDidMount as component is still loading data.
      if (
        this.playerPreviewTarget.current &&
        currentRegion &&
        currentEnvironment
      ) {
        this.studioPlayer = renderStudioPlayer({
          target: this.playerPreviewTarget.current,
          region: currentRegion, // eu | us
          token: this.context.user.token || "",
          contentPath: `/${this.isPlayList() ? "playlist" : "channel"}/${
            this.props.id
          }`,
          environment: currentEnvironment, // production | staging
          playerUrl: getStudioPlayerUrl(this.context),
          context: {
            userInteractionEnabled: true,
            isAiFeatureEnabled: this.context.shouldShowFeature(
              FEATURE_FLAGS_ENUM.AI_FEATURES
            ),
          },
          spaceId: this.context.user.settings.spaceId,
          features: {
            offlineStorage: false,
            enablePlaybackControlInterface: true,
          },
          device: {
            platform: playerDevicePlatform,
            model: playerDeviceModel,
          },
        });
      }
    }
    if (this.studioPlayer) {
      this.studioPlayer.on(
        ActiveItemChangeMessageType,
        this.onPlaybackStateChangeNew
      );
      this.studioPlayer.on(
        ContentListReportUpdateMessageType,
        this.onZoneListReportChangeNew
      );
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.setPlayerWidth);

    if (this.playerFullscreenWrapperRef.current) {
      this.playerFullscreenWrapperRef.current.removeEventListener(
        "fullscreenchange",
        this.onFullscreenChange
      );
    }

    this.studioPlayer?.destroy(); // teardown the player
  }

  public onFullscreenChange = () => {
    // the fullscreen API doesn't work https://github.com/Microsoft/TypeScript/issues/28681
    // But you can switch the isFullScreenMode state by manual in ReactDevTools to see what happend
    const isFullscreenMode = !!(window.document as any).fullscreenElement;
    if (!isFullscreenMode && this.state.isFullscreenMode) {
      this.setState({ isFullscreenMode: false });
    }
  };

  public getNameByItemContent = (
    content: StandaloneMediaContent
  ): string | null => {
    const { _ref } = content;
    if (content.href) {
      return content.href;
    } else if (_ref && this.isContentLoaded()) {
      const { type, id } = _ref;

      const findItemById = (list) =>
        list.find((item) => item && item.id === id);
      const getName = (item) => (item ? item.name : null);
      const getItemName = flow(findItemById, getName);

      switch (type) {
        case RefType.LINK: {
          const links = this.getLinks();
          return getItemName(links);
        }
        case RefType.SITE: {
          const sites = this.getSites();
          return getItemName(sites);
        }
        case RefType.FILE: {
          const files = this.getMedias();
          return getItemName(files);
        }
        case RefType.APP: {
          const apps = this.getApps();
          return getItemName(apps);
        }
        default: {
          throw new Error(
            `Could not recognise content type. Content: ${JSON.stringify(
              content
            )}`
          );
        }
      }
    } else {
      throw new Error(
        `Could not recognise content type. Content: ${JSON.stringify(content)}`
      );
    }
  };

  public onPlaybackStateChangeNew: ActiveItemChangeHandler = (
    activeItem,
    zoneId
  ): void => {
    if (activeItem.type !== "void") {
      this.onPlaybackStateChangeCb({
        id: convertStudioPlayerZoneIdToOldFormat(zoneId),
        itemContent: {
          list_id: activeItem.listId,
          content: {
            _ref: {
              id: activeItem.id,
              type: activeItem.type,
            },
          } as IStandaloneMediaContent,
          parentEntityListId: activeItem.parentEntityListId,
        },
      });
    } else {
      this.onPlaybackStateDelete(zoneId);
    }
  };

  public onPlaybackStateChange = (update: IZonePlaybackState) => {
    const updatedPlaybackState: ZonePlaybackState = update as any;
    this.onPlaybackStateChangeCb(updatedPlaybackState);
  };

  public onPlaybackStateChangeCb = (
    updatedPlaybackState: ZonePlaybackState
  ) => {
    this.setState((prevState) => {
      const existingItemIndex = prevState.playbackState
        ? prevState.playbackState.findIndex(
            (playback) => playback.id === updatedPlaybackState.id
          )
        : -1;
      const updatedData: ZonePlaybackState[] = prevState.playbackState
        ? prevState.playbackState.slice()
        : [];
      if (existingItemIndex > -1 && updatedData.length > existingItemIndex) {
        updatedData.splice(existingItemIndex, 1, updatedPlaybackState);
        return {
          playbackState: updatedData,
        };
      } else {
        return {
          playbackState: [...updatedData, updatedPlaybackState],
        };
      }
    });
  };

  public onPlaybackStateDelete = async (zoneId: string) => {
    if (this.state.playbackState) {
      const existingItemIndex = this.state.playbackState.findIndex(
        (stateItem) => stateItem.id === zoneId
      );
      if (existingItemIndex >= 0) {
        const newState = this.state.playbackState.slice();
        newState.splice(existingItemIndex, 1);
        this.setState({
          playbackState: newState,
        });
      }
    }
  };

  public onStartingPointChange = (
    startingPoint: IZonePlaybackStartingPoint
  ) => {
    const updatedStartingPoint: IZonePlaybackStartingPoint = {
      ...startingPoint,
      timestamp: new Date().getTime(),
    };

    const updatedData = this.state.zoneStartingPoints
      ? this.state.zoneStartingPoints.slice()
      : [];
    const existingItemIndex = this.state.zoneStartingPoints
      ? this.state.zoneStartingPoints.findIndex(
          (stateItem) => stateItem.id === updatedStartingPoint.id
        )
      : -1;
    if (existingItemIndex > -1) {
      updatedData.splice(existingItemIndex, 1, updatedStartingPoint);
    } else {
      updatedData.push(updatedStartingPoint);
    }

    const updatedPlaybackState = this.state.playbackState
      ? this.state.playbackState.slice()
      : [];
    const existingPlaybackStateItemIndex = this.state.playbackState
      ? this.state.playbackState.findIndex(
          (item) => item.id === updatedStartingPoint.id
        )
      : -1;
    if (existingPlaybackStateItemIndex > -1) {
      updatedPlaybackState.splice(
        existingPlaybackStateItemIndex,
        1,
        updatedStartingPoint
      );
    } else {
      updatedPlaybackState.push(updatedStartingPoint);
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        playbackState: updatedPlaybackState,
        zoneStartingPoints: updatedData,
      };
    });
  };

  public onZoneListReportChangeNew: ContentListReportUpdateHandler = (
    contentListUpdateReport: ContentListUpdateReport
  ) => {
    console.log("new zone list received", contentListUpdateReport);
    function getLegacyContentType(
      contentListItem: ContentListReportItem
    ):
      | RefType.LINK
      | RefType.SITE
      | RefType.FILE
      | RefType.APP
      | RefType.PLAYLIST {
      switch (contentListItem.type) {
        case "app":
          return RefType.APP;
        case "file":
          return RefType.FILE;
        case "link":
          return RefType.LINK;
        case "site":
          return RefType.SITE;
        default:
          throw new Error(
            `Unknown content list report item type: ${contentListItem.type}`
          );
      }
    }

    const update: IZoneListReport = {
      id: convertStudioPlayerZoneIdToOldFormat(contentListUpdateReport.zoneId),
      report: contentListUpdateReport.items.map<IListContentItemExtended>(
        (item) => {
          return {
            list_id: item.listId,
            parentEntityListId: item.parentEntityListId,
            parentEntityId: item.parentEntityId,
            parentEntityType: item.parentEntityId
              ? LIST_ITEM_PARENT_ENTITY_TYPE.PLAYLIST
              : undefined,
            content: {
              _ref: {
                type: getLegacyContentType(item),
                id: item.id,
              },
            },
            isInvalid: false,
            skippedBySchedule: false,
          };
        }
      ),
    };

    this.onZoneListReportChange(update);
  };

  public onZoneListReportChange = async (
    updatedZoneListReport: IZoneListReport
  ) => {
    this.setState((prevState) => {
      const existingReportIndex = prevState.zonesListReport
        ? prevState.zonesListReport.findIndex(
            (report) => report.id === updatedZoneListReport.id
          )
        : -1;
      const updatedReports: IZoneListReport[] = prevState.zonesListReport
        ? prevState.zonesListReport.slice()
        : [];
      if (
        existingReportIndex > -1 &&
        updatedReports.length > existingReportIndex
      ) {
        updatedReports.splice(existingReportIndex, 1, updatedZoneListReport);
        return {
          zonesListReport: updatedReports,
        };
      } else {
        return {
          zonesListReport: [...updatedReports, updatedZoneListReport].sort(
            (prev, cur) =>
              Number(prev.id.split("_")[2]) - Number(cur.id.split("_")[2])
          ),
        };
      }
    });
  };

  public getContentInScheduleMap = (): ItemInScheduleMap => {
    const { zonesListReport, selectedZone } = this.state;

    if (!(zonesListReport && selectedZone)) {
      return {};
    }

    const zone = zonesListReport.find((a) => a.id === selectedZone);
    if (!zone) {
      return {};
    }
    return zone.report
      .filter((item) => !item.isInvalid && !item.skippedBySchedule)
      .reduce((map, { list_id }) => {
        map[list_id] = true;
        return map;
      }, {});
  };

  public getControlContentItems = () => {
    const { selectedZone, channelById, playlistById } = this.state;
    const itemInScheduleMap = this.getContentInScheduleMap();
    if (channelById && selectedZone) {
      return getContenItemViewModelsByChannel(
        channelById as Channel,
        selectedZone.replace(CHANNEL_PLAYER_ZONE_PREFIX, ""),
        itemInScheduleMap,
        this.context.secureMediaPolicy
      );
    } else if (playlistById) {
      return getContenItemViewModelsByPlaylist(
        playlistById as Playlist,
        itemInScheduleMap,
        this.context.secureMediaPolicy
      );
    }
    return [];
  };

  public renderPlaybackList = () => {
    const controlContentItemViewModels = this.getControlContentItems();
    const isContentEmpty = controlContentItemViewModels.length <= 0;
    return (
      <>
        {isContentEmpty || (
          <h3>
            <FormattedMessage
              id="common.text.now_playing"
              defaultMessage="Now Playing"
            />
          </h3>
        )}
        <div className={"list"}>
          {controlContentItemViewModels.map(this.renderContentItem)}
          {isContentEmpty && this.renderEmptyContent()}
        </div>
      </>
    );
  };

  public renderEmptyContent = () => {
    const { onEditButonClick, isOwner } = this.props;

    const renderEditButton = ({
      emptyMessageId,
      defaultEmptyMessage,
      editButtonMessageId,
      editButtonDefaultMessage,
    }) => {
      let canUpdateContent = this.context.currentPermissions.validateCurrentSpace(
        "channel",
        "update"
      );
      if (emptyMessageId === "playlist.empty_message") {
        canUpdateContent = this.context.currentPermissions.validateCurrentSpace(
          "playlist",
          "update"
        );
      }
      return (
        <div className="edit-content">
          <FormattedMessage
            id={emptyMessageId}
            defaultMessage={defaultEmptyMessage}
          />
          {onEditButonClick && (
            <PrimaryButton
              disabled={!canUpdateContent || !isOwner}
              onClick={onEditButonClick}
            >
              <FormattedMessage
                id={editButtonMessageId}
                defaultMessage={editButtonDefaultMessage}
              />
            </PrimaryButton>
          )}
        </div>
      );
    };

    const channelMessage = {
      defaultEmptyMessage: "This channel is empty",
      editButtonDefaultMessage: "Edit Channel",
      editButtonMessageId: "common.button.edit_channel",
      emptyMessageId: "channels.empty_message",
    };

    const zoneMessage = {
      defaultEmptyMessage: "This zone is empty",
      editButtonDefaultMessage: "Edit zone",
      editButtonMessageId: "channels.zones.edit",
      emptyMessageId: "channels.zones.empty_message",
    };

    const playListMessage = {
      defaultEmptyMessage: "This playlist is empty",
      editButtonDefaultMessage: "Edit Playlist",
      editButtonMessageId: "common.button.edit_playlist",
      emptyMessageId: "playlist.empty_message",
    };

    const message = this.isPlayList()
      ? playListMessage
      : countZone(this.state.channelById as Channel) >= 2
      ? zoneMessage
      : channelMessage;

    return renderEditButton(message);
  };

  public isItemActive = (
    listId: string,
    parentEntityListId?: string
  ): boolean => {
    const isPlaybackStateActive = this.state.playbackState?.find((item) => {
      const itemContent = item.itemContent as IListContentItemExtended;
      return (
        itemContent.list_id === listId &&
        (!parentEntityListId ||
          itemContent.parentEntityListId === parentEntityListId)
      );
    });

    return !!isPlaybackStateActive;
  };

  public isPlaylistActive = (
    targetPlaylistListId: Scalars["UUID"]
  ): boolean => {
    const { playbackState } = this.state;
    return (playbackState ?? []).some(
      (item) =>
        (item.itemContent as IListContentItemExtended).parentEntityListId ===
        targetPlaylistListId
    );
  };

  public renderContentItem = (
    controlContentItem?: PlayerPreviewItemViewModel
  ) => {
    if (!controlContentItem) {
      return null;
    }

    if (controlContentItem.isPlaylist) {
      const {
        color,
        contents,
        listId,
        name,
        rules,
      } = controlContentItem as PlayListContentItemViewModel;
      const isActive = this.isPlaylistActive(listId);
      return (
        <div
          key={listId}
          className={`report-playlist-item${isActive ? " playing" : ""}`}
        >
          <Accordion
            className="playlist-accordion"
            panels={[
              {
                content:
                  contents.length <= 0
                    ? null
                    : {
                        content: (
                          <div className={"playlist-items"}>
                            {contents.map(this.renderContentItem)}
                          </div>
                        ),
                      },
                key: 0,
                title: {
                  content: (
                    <div
                      className={classNames("content-details-container", {
                        "with-rules": hasDisplayingRules(rules ?? []),
                      })}
                    >
                      <div className="main-content-container text-ellipsis">
                        <div
                          className="icon-block"
                          style={{ backgroundColor: color! }}
                        >
                          <Icon color={Theme.color.white} name="playlist" />
                        </div>
                        <div className="content">{name}</div>
                      </div>
                      <RuleIcons rules={rules ?? []} />
                    </div>
                  ),
                },
              },
            ]}
          />
        </div>
      );
    } else {
      const contentItem = controlContentItem as ContentItemViewModel;
      const {
        listId,
        name,
        image,
        isInSchedule,
        duration,
        playListListId,
      } = contentItem;
      const isActive = this.isItemActive(listId, playListListId);

      return (
        <div
          title={
            isInSchedule
              ? ""
              : this.context.intl.formatMessage({
                  defaultMessage: "This item is not in schedule",
                  id: "item_not_available_message",
                })
          }
          className={classNames("report-list-item", {
            playing: isActive,
            disabled: !isInSchedule,
          })}
          key={listId}
          onClick={(e) =>
            isInSchedule
              ? this.handleJumpToContent(this.state.selectedZone, listId)
              : null
          }
        >
          <div
            className={classNames("content-details-container", {
              "with-rules": hasDisplayingRules(contentItem.rules ?? []),
            })}
          >
            <div className="main-content-container text-ellipsis">
              <div className="icon-block">{image && <img src={image} />}</div>
              <div className="content">{name}</div>
            </div>
            <RuleIcons rules={contentItem.rules ?? []} />
          </div>

          <div
            className="item-bg"
            style={{
              transition: `width ${isActive ? duration / 1000 : 0}s linear`,
            }}
          />
        </div>
      );
    }
  };

  public handleJumpToContent = (zoneId: string, listId: string): void => {
    this.studioPlayer?.controls?.previewItem(listId, zoneId);
  };

  public onJumpToContent = async (
    e: any,
    zoneId: string,
    listId: string,
    playListListId?: string
  ) => {
    // todo: find a proper type of event
    if (!this.state.zonesListReport) {
      return;
    }

    const zoneList = this.state.zonesListReport.find(
      (item) => item.id === zoneId
    );
    if (!zoneList) {
      throw new Error(`List report for zone ${zoneId} is not found.`);
    }

    const itemContent = zoneList.report.find(
      (item) =>
        item.list_id === listId &&
        (!item.parentEntityListId || item.parentEntityListId === playListListId)
    );
    if (!itemContent) {
      throw new Error(
        `Content item ${itemContent} is not found in zone list report.`
      );
    }

    const updatedState: IZonePlaybackStartingPoint = {
      id: zoneId,
      itemContent,
      neighbours: [],
      timeOffset: 0,
      timestamp: new Date().getTime(),
    };

    await this.onStartingPointChange(updatedState);
  };

  public onShowFullscreen = (e) => {
    if (this.playerFullscreenWrapperRef.current) {
      const fullscreenFunc = getRequestFullscreenFunc(
        this.playerFullscreenWrapperRef.current
      );
      this.setState((prevState) => {
        if (fullscreenFunc && !prevState.isFullscreenMode) {
          fullscreenFunc();
          return { isFullscreenMode: true };
        } else {
          (document as any).webkitExitFullscreen();
          return { isFullscreenMode: false };
        }
      });
    }
  };

  public isFullscreenAvailable = (): boolean => {
    return (
      !!this.playerFullscreenWrapperRef.current &&
      !!getRequestFullscreenFunc(this.playerFullscreenWrapperRef.current)
    );
  };

  public onZoneChange = (selectedZone: string) => {
    this.setState({
      selectedZone,
    });

    this.props.onZoneChange && this.props.onZoneChange(selectedZone);
  };

  public getNextOrPreviousItemForZone = (
    zoneId: string,
    isPrevious: boolean
  ): IListContentItemExtended | null => {
    if (!zoneId || !this.state.playbackState || !this.state.zonesListReport) {
      return null;
    }

    const zoneCurrentState = this.state.playbackState.find(
      (item) => item.id === zoneId
    );
    const zoneReport = this.state.zonesListReport.find(
      (item) => item.id === zoneId
    );

    if (!zoneCurrentState || !zoneReport) {
      return null;
    }

    const validatedList = zoneReport.report.filter((item) => !item.isInvalid);
    const list = isPrevious ? validatedList : validatedList.slice().reverse();
    // todo: update code to proper list
    // const list = isPrevious ? zoneReport.report.validatedList : zoneReport.report.validatedList.slice().reverse()

    if (list.length === 0) {
      return null;
    }
    if (list.length === 1) {
      return list[0];
    }

    const {
      list_id,
      parentEntityListId,
    } = zoneCurrentState.itemContent as IListContentItemExtended;

    const currentItemIndex =
      list_id &&
      list.findIndex(
        (item) =>
          item.list_id === list_id &&
          (!item.parentEntityListId ||
            item.parentEntityListId === parentEntityListId)
      );

    return currentItemIndex
      ? list[currentItemIndex - 1]
      : list[list.length - 1];
  };

  public handlePreviousItemClick = () => {
    this.onPreviousItemClick();
  };

  public onPreviousItemClick = () => {
    if (this.state.selectedZone && this.studioPlayer?.controls) {
      this.studioPlayer.controls.skipBack(
        convertOldZoneIdToNewStudioPlayerFormat(this.state.selectedZone)
      );
    }
  };

  public onPreviousItemClick__deprecate = async () => {
    if (!this.state.selectedZone) {
      return;
    }
    const previousItem = this.getNextOrPreviousItemForZone(
      this.state.selectedZone,
      true
    );

    if (previousItem) {
      await this.onStartingPointChange({
        id: this.state.selectedZone,
        itemContent: previousItem,
        neighbours: [],
        timeOffset: 0,
        timestamp: new Date().getTime(),
      });
    }
  };

  public handleNextItemClick = () => {
    this.onNextItemClick();
  };

  public onNextItemClick = () => {
    if (this.state.selectedZone && this.studioPlayer?.controls) {
      this.studioPlayer.controls.skipForward(
        convertOldZoneIdToNewStudioPlayerFormat(this.state.selectedZone)
      );
    }
  };

  public onNextItemClick__deprecate = async () => {
    if (!this.state.selectedZone) {
      return;
    }
    const nextItem = this.getNextOrPreviousItemForZone(
      this.state.selectedZone,
      false
    );

    if (nextItem) {
      await this.onStartingPointChange({
        id: this.state.selectedZone,
        itemContent: nextItem,
        neighbours: [],
        timeOffset: 0,
        timestamp: new Date().getTime(),
      });
    }
  };

  public getMedias = () => {
    const { playlistById, channelById } = this.state;
    const files = this.isPlayList()
      ? playlistById!.filesByPlaylistId!.nodes
      : channelById!.filesByChannelId!.nodes;
    return files;
  };

  public getLinks = () => {
    const { playlistById, channelById } = this.state;
    const links = this.isPlayList()
      ? playlistById!.linksByPlaylistId!.nodes
      : channelById!.linksByChannelId!.nodes;
    return links;
  };

  public getSites = () => {
    const { playlistById, channelById } = this.state;
    const sites = this.isPlayList()
      ? playlistById!.sitesByPlaylistId!.nodes
      : channelById!.sitesByChannelId!.nodes;
    return sites;
  };

  public getApps = () => {
    const { playlistById, channelById } = this.state;
    const apps = this.isPlayList()
      ? playlistById!.appInstancesByPlaylistId!.nodes
      : channelById!.appInstancesByChannelId!.nodes;
    return apps;
  };

  public getCurrentZoneContentTypeLabel = (
    contentItem: ZonePlaybackState
  ): string => {
    let textLabel: string = "";
    const refType = get(contentItem, [
      "itemContent",
      "content",
      "_ref",
      "type",
    ]);

    if (refType !== "file") {
      textLabel = refType;
    } else {
      const id = get(contentItem, ["itemContent", "content", "_ref", "id"]);
      const file = this.getMedias().find((item) => !!(item && item.id === id));
      const mediaType = getMediaType(file);
      textLabel = mediaType === MediaType.UNKNOWN ? "invalid" : mediaType;
    }

    return textLabel.toUpperCase();
  };

  public getZonePlaybackState = (zoneId?: string): ZonePlaybackState | null => {
    if (!zoneId || !this.state.playbackState) {
      return null;
    }

    const playbackState = this.state.playbackState.find(
      (item) => item.id === zoneId
    );

    return playbackState ? playbackState : null;
  };

  public getZoneListReport = (
    zoneId: string | null
  ): IZoneListReport | undefined => {
    if (!zoneId || !this.state.zonesListReport) {
      return;
    }

    return this.state.zonesListReport.find((item) => item.id === zoneId);
  };

  public handleChange = (
    e: React.ChangeEvent<HTMLInputElement> & React.KeyboardEvent
  ) => {
    const { name, value } = e.currentTarget;
    this.setState((prevState) => ({ ...prevState, [name]: value }));
  };

  public handleDropdownChange = (
    deviceKey: string,
    width: number,
    height: number
  ) => {
    const { presetWidth, presetHeight } = this.props;
    const isPortrait: boolean = !!(
      presetWidth &&
      presetHeight &&
      presetWidth < presetHeight
    );
    if (deviceKey === Device.CUSTOM && presetWidth && presetHeight) {
      width = presetWidth;
      height = presetHeight;
    }

    if (isPortrait) {
      [width, height] = [height, width];
    }

    this.setState({
      playerAspectRatio: width / height,
      previewDeviceKey: deviceKey,
      previewResolutionHeight: height.toString(),
      previewResolutionWidth: width.toString(),
    });
  };

  public getPreviewScaleRatio = (scale = 1): number => {
    if (this.availableSpaceRef && this.availableSpaceRef.current) {
      const heightScale = this.props.showPlayerOnly
        ? this.availableSpaceRef.current.clientHeight /
          Number(this.state.previewResolutionHeight)
        : this.availableSpaceRef.current.offsetHeight /
          Number(this.state.previewResolutionHeight);
      const widthScale = this.props.showPlayerOnly
        ? this.availableSpaceRef.current.clientWidth /
          Number(this.state.previewResolutionWidth)
        : this.availableSpaceRef.current.offsetWidth /
          Number(this.state.previewResolutionWidth);
      scale = Math.min(heightScale, widthScale);
    }

    if (this.props.showPlayerOnly) {
      return scale > 1 ? 1 : scale * 1;
    }

    return (scale > 1 ? 1 : scale) * 0.9;
  };

  public onSwitchOrientations = () => {
    this.setState((prevState) => {
      const sharedPlayback =
        prevState.playbackState &&
        prevState.playbackState.find((i) =>
          i.id.includes(ZoneRenderType.SHARED)
        );
      const sharedReport =
        prevState.zonesListReport &&
        prevState.zonesListReport.find((i) =>
          i.id.includes(ZoneRenderType.SHARED)
        );

      const hiddenZonePlayback =
        prevState.playbackState &&
        prevState.playbackState.find((i) =>
          i.id.includes(ZoneRenderType.HIDDEN_ZONE_SUFFIX)
        );
      const hiddenZoneReport =
        prevState.zonesListReport &&
        prevState.zonesListReport.find((i) =>
          i.id.includes(ZoneRenderType.HIDDEN_ZONE_SUFFIX)
        );

      return {
        currentOrientation:
          prevState.currentOrientation === Orientation.Landscape
            ? Orientation.Portrait
            : Orientation.Landscape,
        playbackState:
          sharedPlayback || this.isPlayList()
            ? prevState.playbackState
            : hiddenZonePlayback
            ? [hiddenZonePlayback]
            : [],
        previewResolutionHeight: prevState.previewResolutionWidth,
        previewResolutionWidth: prevState.previewResolutionHeight,
        zonesListReport:
          sharedReport || this.isPlayList()
            ? prevState.zonesListReport
            : hiddenZoneReport
            ? [hiddenZoneReport]
            : [],
      };
    });
  };

  public renderZoneSelector = (): React.ReactNode => {
    const zones = getZoneOptions(this.state.channelById as Channel, {
      showHidden: true,
      isForPlayer: true,
    });
    if (zones.length >= 2 && this.isChannel()) {
      return (
        <div data-testid="zone-selector" className="zone-selector">
          <Dropdown
            fluid
            selection
            options={zones}
            value={this.state.selectedZone}
            closeOnChange
            onChange={(_, data: { [key: string]: string }) =>
              this.onZoneChange(data.value)
            }
          />
        </div>
      );
    } else {
      return null;
    }
  };

  public getDropdownResolutions = () => {
    const {
      previewDeviceKey,
      previewResolutionWidth,
      previewResolutionHeight,
    } = this.state;
    const { presetWidth, presetHeight } = this.props;
    const tempDevice = getDevice(previewDeviceKey);

    let deviceList = PreviewDeviceList;
    let isPortrait = false;
    if (presetWidth && presetHeight) {
      isPortrait = presetWidth < presetHeight;
      deviceList =
        getDeviceKeyWidthAndHeight(presetWidth, presetHeight) === Device.CUSTOM
          ? PreviewDeviceList
          : PreviewDeviceList.filter((device) => device.key !== Device.CUSTOM);
    } else {
      deviceList = deviceList.filter((device) => device.key !== Device.CUSTOM);
    }

    return (
      <div className="resolutions-dropdown-container">
        <Dropdown
          selection
          className="resolutions-dropdown"
          direction="right"
          inverted
          text={tempDevice ? tempDevice.text : ""}
        >
          <DropdownMenu>
            {deviceList.map((device) => {
              const { height, width } = deviceResolutions[device.key];
              let dimensionText = !isPortrait
                ? `${width} × ${height}`
                : `${height} × ${width}`;

              if (device.key === Device.CUSTOM) {
                dimensionText = `${presetWidth} × ${presetHeight}`;
              }

              return (
                <DropdownItem
                  onClick={() =>
                    this.handleDropdownChange(device.key, width, height)
                  }
                  selected={device.key === this.state.previewDeviceKey}
                  key={device.key}
                >
                  <div className="device-info">
                    <div className="device-title">{device.text}</div>
                    <div className="device-resolution">{dimensionText}</div>
                  </div>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        <span className="selected-device-resolution">
          {previewResolutionWidth} × {previewResolutionHeight}
        </span>
      </div>
    );
  };

  public isPlayList() {
    return this.props.previewType === PreviewType.PLAYLIST;
  }

  public isChannel() {
    return this.props.previewType === PreviewType.CHANNEL;
  }

  public isContentLoaded() {
    const { playlistById, channelById } = this.state;
    return (
      (this.isPlayList() && playlistById) || (this.isChannel() && channelById)
    );
  }

  public isOrgWhitelistedForNewPlayerPreview = (): boolean => {
    return NEW_PLAYER_PREVIEW_WHITELIST.includes(this.context.currentOrg?.id);
  };
  public render() {
    const {
      onFullScreenClick,
      showExitFullScreenButton,
      showFullScreenButton,
      showToggleControlButton: showToggleControlButton,
      onExitFullScreenClicked,
      showPlayerOnly,
    } = this.props;
    const { hideControl } = this.state;
    const selectedZonePlaybackState = this.getZonePlaybackState(
      this.state.selectedZone
    );
    const scale = this.getPreviewScaleRatio();

    if (!this.state.screenData || !this.isContentLoaded()) {
      return <LoaderBar />;
    }
    return (
      <Styled
        className={classNames("player-preview", {
          "hide-control": hideControl,
        })}
        ref={this.playerFullscreenWrapperRef}
        scale={scale}
        {...this.state}
      >
        <div
          className={`preview ${this.props.className}`}
          style={showPlayerOnly ? { width: "100%" } : undefined}
        >
          {!showPlayerOnly && (
            <div className="header-bar">
              {showFullScreenButton && (
                <Button
                  data-testid="fullscreen-button"
                  className="fullscreen-button"
                  onClick={onFullScreenClick}
                >
                  <Icon name="enter-fullscreen" />
                  <span> Full Screen </span>
                </Button>
              )}
              {showExitFullScreenButton && (
                <Button
                  data-testid="exit-fullscreen-button"
                  className="exit-fullscreen-button"
                  onClick={onExitFullScreenClicked}
                >
                  <Icon name="exit-fullscreen" />
                  <span> Exit Full Screen </span>
                </Button>
              )}
              <div className="right-wrapper">
                {this.isPlayList() && (
                  <div
                    data-testid="orientations-button"
                    className="orientations"
                    onClick={this.onSwitchOrientations}
                  >
                    <div className="toggle-orientation" />
                  </div>
                )}
                {this.getDropdownResolutions()}
              </div>
            </div>
          )}

          {/* TODO secure Media configs need to be updated after imagix issue is reolved for player */}

          <div ref={this.availableSpaceRef} className="available-space">
            <div className="player-container-outer-wrapper">
              <div className="player-container-wrapper">
                <div className="inner-wrapper">
                  <div className="player-container">
                    <div className="wrapper">
                      <div
                        ref={this.playerPreviewTarget}
                        className="sdk-player-container"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!showPlayerOnly && (
            <div className="playback-bar">
              {this.getControlContentItems().length >= 2 && (
                <div className="playback-buttons">
                  <Button
                    icon
                    borderless
                    onClick={this.handlePreviousItemClick}
                  >
                    <Icon name="skip-previous" />
                  </Button>
                  <Button icon borderless onClick={this.handleNextItemClick}>
                    <Icon name="skip-next" />
                  </Button>
                </div>
              )}
              <div className="info">
                {selectedZonePlaybackState && (
                  <>
                    <div className="content-type-label">
                      {this.getCurrentZoneContentTypeLabel(
                        selectedZonePlaybackState
                      )}
                    </div>
                    <br />
                    <div className="content-title">
                      {selectedZonePlaybackState.itemContent.hasOwnProperty(
                        "list_id"
                      )
                        ? this.getNameByItemContent(
                            (selectedZonePlaybackState.itemContent as ListContentItem)
                              .content
                          )
                        : this.getNameByItemContent(
                            selectedZonePlaybackState.itemContent as StandaloneMediaContent
                          )}
                    </div>
                    <br />
                    <div className="playback-time">
                      {/* todo: Implement timer wirth current playbackstate
                    <Timer
                      reference={selectedZonePlaybackState.itemContent}
                      startTime={selectedZonePlaybackState.timeOffset}
                      pause={false}
                    /> */}
                    </div>
                  </>
                )}
              </div>
              <div className="time">
                {/* todo: Implement scehduling feature
              <Icon name="calendar" /> */}
                {/* todo: Implement selected item duration
              <div className="playback-time">
                {selectedZonePlaybackState && getTotalTimeDuration(getItemContentDuration(selectedZonePlaybackState))}
              </div> */}
              </div>
            </div>
          )}
        </div>

        {!showPlayerOnly && (
          <div className="controls">
            {showToggleControlButton && (
              <Button
                data-testid="toggle-control-button"
                className="btn-hide-controls"
                title={
                  hideControl ? "Expand side panel" : "Collapse side panel"
                }
                icon
                onClick={() => this.setState({ hideControl: !hideControl })}
              >
                <Icon name={hideControl ? "arrow-left" : "arrow-right"} />
              </Button>
            )}
            {this.renderZoneSelector()}
            {this.renderPlaybackList()}
          </div>
        )}
      </Styled>
    );
  }

  private getPlaylistData = async (
    id: string
  ): Promise<ApolloQueryResult<PlaylistByIdForPlayerPreviewQuery> | null> => {
    const queryVar: PlaylistByIdForPlayerPreviewQueryVariables = {
      id,
    };
    return this.props.client!.query({
      query: PlaylistByIdForPlayerPreviewDocument,
      variables: queryVar,
    });
  };

  private getChannelData = async (
    id: string
  ): Promise<ApolloQueryResult<ChannelByIdForPlayerPreviewQuery> | null> => {
    const queryVar: ChannelByIdForPlayerPreviewQueryVariables = {
      id,
    };
    return this.props.client!.query({
      query: ChannelByIdForPlayerPreviewDocument,
      variables: queryVar,
    });
  };
}

export default compose(withApollo)(PlayerPreview) as React.ComponentType<Props>;
