import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

type Props = {
  primaryColor?: string;
  secondaryColor?: string;
};

export const Styled = styled.div<Props>`
  .preview {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 55px;
    border-radius: 3.6px;
    align-items: center;
    font-size: 23px;
    justify-content: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .preview-text {
    margin-bottom: 7px;
  }

  .preview-line {
    height: 5px;
    width: 80%;
    border-radius: 5.4px;
    background-color: ${(props) =>
      props.secondaryColor ? props.secondaryColor : Theme.color.yellow};
  }
`;
