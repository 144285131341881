import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const headerHeight = "70px";

export const Styled = styled.div`
  border-bottom: 1px solid ${Theme.color.borderSection};
  background-color: ${Theme.color.white100};
  height: ${headerHeight};
  min-height: ${headerHeight};
  z-index: 10;
  position: relative;

  * {
    margin-bottom: 0;
  }

  .wrapper {
    display: flex;
    align-items: center;
    padding: 0 60px;
    height: 100%;
    gap: var(--spacing-sm);

    .column-back {
      flex: 0 0 auto;
      height: 100%;
      align-items: center;
      display: flex;

      .button.back,
      .button.back:active {
        background: var(--color-white-100);
        border-radius: 0;
        border-right: 1px solid ${Theme.color.borderSection};
        border-bottom: none;
        border-left: none;
        border-top: none;
        box-shadow: none;
        height: 100%;
        padding: 0;
        width: 50px;
        justify-content: center;
        min-width: auto;

        i {
          background-color: ${Theme.color.grey80};
          width: 32px;
          height: 32px;
        }

        :hover {
          background-color: ${Theme.color.grey30};
          border-top: none;
          border-left: none;

          i {
            background-color: ${Theme.color.grey100};
          }
        }
      }
    }

    .column-title {
      flex-grow: 1;
      flex-shrink: 0;
      order: 1;
      min-width: 144px;

      .app-title {
        position: relative;
        top: -1px;
      }

      h1 {
        color: ${Theme.color.grey100};
        font-size: 1.5em;
        font-weight: 900;
        line-height: 1.2;
        display: inline-flex;
        user-select: none;
        vertical-align: bottom;
        white-space: nowrap;
      }

      .count {
        ${Theme.default.borderRadius};
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        color: rgba(31, 32, 39, 1);
        border: 1px solid rgba(31, 32, 39, 0.5);
        padding: 0 6px;
        margin-left: 8px;
      }
    }

    .column-actions {
      flex-grow: 1;
      width: auto;
      display: flex;
      justify-content: flex-end;
      order: 3;
      gap: 10px;

      .button.primary {
        width: 100%;
        max-width: 160px;
        justify-content: center;

        i {
          display: none;
        }
      }

      :empty {
        margin-left: 0;
        display: none;
      }
    }

    .column-search,
    .column-message-placeholder {
      width: 100%;
      order: 2;
    }

    &.detail {
      padding: 0;
      gap: 0;

      .column-title {
        width: 100%;
        flex: 1 0 0;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        gap: 5px;

        h1 {
          display: block;

          .inline-input {
            .truncate {
              overflow: hidden;
              text-overflow: ellipsis;
              width: auto;
            }

            &.disabled {
              .ui.input {
                opacity: 1;
                color: ${Theme.color.grey100};
                -webkit-text-fill-color: ${Theme.color.grey100};

                input {
                  color: ${Theme.color.grey100};
                  -webkit-text-fill-color: ${Theme.color.grey100};
                }
              }
            }
          }

          .ui.input {
            width: 100%;

            input {
              :hover {
                border-bottom-color: var(--color-primary-background);
              }

              :active,
              :focus {
                cursor: text;
                border-color: transparent;
                border-bottom-color: var(--color-primary-background);
              }
            }
          }
        }

        .meta-update {
          font-size: 0.75em;
          padding: 0;
          display: block;
          color: ${Theme.color.grey60};
          white-space: nowrap;

          a {
            font-weight: bold;
            color: ${Theme.color.grey60};
          }

          .draft-state {
            display: none;
          }
        }
      }

      .column-search,
      .column-message-placeholder {
        width: calc(100% - 210px);
        margin: 0 0 0 10px;
      }

      .column-options {
        align-items: center;
        order: 3;
        display: flex;
        justify-content: flex-end;
        max-width: 50%;
        padding-right: 20px;
        height: 100%;
        gap: var(--spacing-sm);

        .draft-state {
          width: auto;
          font-style: italic;

          > div {
            justify-content: flex-end;
          }
        }

        .btn-preview {
          i {
            display: none;
          }
        }

        .ui.button.notification {
          min-width: 44px;
        }
        > .ui.dropdown {
          .ui.button {
            min-width: 44px;
          }

          &.apps-dropdown {
            margin-right: 10px;
          }

          .menu > .item {
            &.disabled {
              display: flex;
              flex-direction: row;

              .deleteChannelMenu {
                display: flex;
                flex-direction: column;
                gap: var(--spacing-xs);

                span {
                  display: block;
                  white-space: unset;
                }
              }

              span.option-disabled {
                width: 120px;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                white-space: normal;
              }
            }
          }
        }

        .button-publish {
          width: 142px;

          .button-group {
            .button-action {
              width: 110px;
            }
          }
        }

        .on-screen {
          i {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
          }

          .screen-count {
            font-weight: bold;
          }
        }

        &.empty {
          display: none;
        }
      }

      &.search {
        padding: 0 60px 0 0;

        .column-back {
          .button.back,
          .button.back:active {
            border-right: 0;
            width: 100%;
          }
        }

        .column-title {
          display: none;
        }

        .column-search {
          width: 100%;
          margin: 0;

          .ui.input {
            right: 0;

            input {
              padding-left: 15px;
              padding-right: 45px;
              border-color: ${Theme.color.grey80};
              background-color: var(--color-white-100);
            }

            i[name="search"] {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .wrapper {
      padding: 0 40px;

      &.detail {
        &.search {
          padding: 0 40px 0 0;

          .column-back {
            width: 40px;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .wrapper {
      &.detail {
        .column-options {
          width: 100%;
          .draft-state {
            display: none;
          }
        }

        .column-title {
          max-width: calc(100% - 60px);

          .meta-update {
            .updated-by {
              display: none;
            }

            .draft-state {
              display: inline-block;
              height: auto;
              position: absolute;
              bottom: 2px;
            }
          }

          .inline-input {
            i {
              visibility: visible;
            }
          }
        }

        .column-options {
          border-top: 1px solid ${Theme.color.borderInput};
          bottom: 0;
          background: ${Theme.color.white100};
          height: 65px;
          max-width: calc(100% - 70px);
          padding: 0 40px;
          position: fixed;
          justify-content: flex-start;

          .button {
            &.button-refresh {
              margin-left: auto;
              max-width: 100%;
              padding: 0 20px;
              width: auto;
            }
          }

          .button-instance {
            max-width: 100%;
            padding: 0 20px;
            width: auto;
          }

          .btn-preview {
            margin-left: auto;
            width: auto;

            i {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    z-index: 9;
    width: 100%;
    min-width: 375px;

    .wrapper {
      padding: 0 20px;
      .column-back {
        .button.back,
        .button.back:active {
          width: 48px;
        }
      }

      .column-title {
        width: auto;
        max-width: 100%;
        flex-grow: 1;

        h1 {
          font-size: 1.25em;
        }

        .count {
          font-size: 0.75em;
        }
      }

      .column-search,
      .column-message-placeholder {
        width: calc(100% - 120px);
        margin: 0;

        .ui.search {
          display: flex;
          justify-content: flex-end;

          .ui.input {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: flex-end;

            * {
              transition: all 0.1s ease-in;
            }

            input {
              opacity: 0;
              width: 45px;
              padding: 0;

              :not([value=""]) {
                opacity: 1;
                width: 100%;
                padding: 9px 45px 9px 15px;

                + .search.icon {
                  display: none;
                }
              }
            }

            i[name="search"] {
              margin: 0;
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              left: auto;
              right: 0;
              top: calc(50% - 10px);
            }
          }

          &.focus {
            .ui.input {
              input {
                opacity: 1;
                width: 100%;
                padding: 9px 45px 9px 15px;
              }

              i[name="search"] {
                right: 12px;
              }
            }

            &.active {
              i[name="search"] {
                display: none;
              }
            }
          }
        }
      }

      .column-actions {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        justify-content: center;
        padding: 10px 20px;
        background: var(--color-white-100);
        border-top: 1px solid var(--color-grey40);
        margin: 0;

        .button {
          flex: 1 0 0;
        }
      }

      &.detail {
        padding: 0;
        flex-wrap: nowrap;

        .column-title {
          flex-grow: 1;

          h1 {
            font-size: 1.25em;

            .ui.input {
              input {
                padding: 5px 0;
              }
            }
          }
        }

        .column-options {
          max-width: 100%;
          padding: 10px 16px;

          :empty {
            display: none;
          }

          .btn-preview,
          .button-casting {
            span {
              display: inline-block;
            }
          }

          > .ui.dropdown {
            &.apps-dropdown {
              .menu {
                ${Theme.default.borderRadius};
              }
            }
          }

          .button-instance {
            max-width: 100%;
            width: calc(100% - 55px);
          }

          .button-duplicate {
            display: none;
          }
        }

        &.search {
          padding: 0 5px 0 0;

          .column-back {
            width: 48px;
          }

          .column-search {
            max-width: calc(100% - 60px);
          }

          .column-actions {
            display: none;
          }
        }
      }

      @supports (padding-bottom: 10px) {
        .column-actions {
          align-items: flex-start;
          --safe-area-inset-bottom: env(safe-area-inset-bottom);
          height: calc(65px + (var(--safe-area-inset-bottom)));
          padding-bottom: max(10px, env(safe-area-inset-bottom));
        }

        &.detail {
          .column-options {
            align-items: center;
            --safe-area-inset-bottom: env(safe-area-inset-bottom);
            height: calc(65px + (var(--safe-area-inset-bottom)));
            padding-bottom: max(10px, env(safe-area-inset-bottom));
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .wrapper {
      .column-title {
        width: auto;
        max-width: 100%;
        flex-grow: 1;
      }

      .column-actions {
        padding: 10px 20px;
        min-width: 375px;
        margin: 0;

        .button.primary {
          padding: 0;
          max-width: 100%;
          font-size: 1em;
        }
      }

      &.detail {
        .column-options {
          > .ui.button {
            &.btn-preview {
              padding: 0;
              width: 45px;
              min-width: 45px;

              i {
                display: block;
              }
              span {
                display: none;
              }
            }
          }

          .button-casting {
            span {
              display: none;
            }
          }
        }

        &.search {
          padding: 0 2px 0 0;
        }
      }
    }
  }
`;
