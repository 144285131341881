import { Tab } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import {
  API_KEY_PROVIDER_URN,
  BASIC_AUTH_PROVIDER_URN,
  OAUTH2_PROVIDER_URN,
} from "../../constants";
import AddCredentialMenu from "./elements/AddCredentialMenu";

interface CredentialPillsProps {
  activeTabIndex: number;
  onTabChange: (index: number) => void;
  filterUrn: string;
  onNewCredentialTypeSelected: (type: string) => void;
}

const API_KEY_TAB_PANE = { menuItem: "API Keys" };
const BASIC_AUTH_TAB_PANE = { menuItem: "Single Logins" };
const OAUTH2_TAB_PANE = { menuItem: "Social Accounts" };

export default class CredentialPills extends React.Component<
  CredentialPillsProps,
  {}
> {
  public render(): React.ReactNode {
    return (
      <div className="credentials-pill-container">
        <div className="credentials-pill-wrapper">
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={this.getPanes()}
            activeIndex={this.props.activeTabIndex}
            onTabChange={this.onTabChange}
          />
        </div>

        <AddCredentialMenu
          filterUrn={this.props.filterUrn}
          onNewCredentialTypeSelected={this.props.onNewCredentialTypeSelected}
        />
      </div>
    );
  }

  private getPanes = () => {
    const { filterUrn } = this.props;

    if (filterUrn.startsWith(API_KEY_PROVIDER_URN)) {
      return [API_KEY_TAB_PANE];
    }

    if (filterUrn.startsWith(BASIC_AUTH_PROVIDER_URN)) {
      return [BASIC_AUTH_TAB_PANE];
    }

    if (filterUrn.startsWith(OAUTH2_PROVIDER_URN)) {
      return [OAUTH2_TAB_PANE];
    }

    return [BASIC_AUTH_TAB_PANE, OAUTH2_TAB_PANE, API_KEY_TAB_PANE];
  };

  private onTabChange = (
    event: React.SyntheticEvent,
    data: { activeIndex: number }
  ) => {
    this.props.onTabChange(data.activeIndex);
  };
}
