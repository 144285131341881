import { FIRST_FETCH_ITEMS } from "src/constants/constants";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useAppContext } from "src/hooks/useAppContext";
import { useLoadMore } from "src/hooks/useLoadMore";
import {
  AllAppInstancesInOrgQueryVariables,
  AppInstance,
  useAllAppInstancesInOrgQuery,
} from "src/types.g";

/**
 *
 * @returns All App Instances in current organization
 */
export function useAllAppInstancesInCurrentOrg({
  appId,
  skip,
}: {
  appId: string;
  skip: boolean;
}) {
  const context = useAppContext();

  const variables: AllAppInstancesInOrgQueryVariables = {
    appId,
    orgId: context?.currentOrg?.id,
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
  };

  const { data, fetchMore, loading } = useAllAppInstancesInOrgQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip,
  });

  const appInstances = (data?.orgById?.appInstancesByOrgId.nodes ??
    []) as AppInstance[];
  const appInstancesTotalCount =
    data?.orgById?.appInstancesByOrgId.totalCount ?? 0;
  const isQueryHasNextPage =
    data?.orgById?.appInstancesByOrgId.pageInfo.hasNextPage;
  const endCursor = data?.orgById?.appInstancesByOrgId.pageInfo.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(appInstances?.length),
    Number(appInstancesTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) &&
      appInstances.length! < appInstancesTotalCount!,
    loadMore
  );

  return {
    appInstances,
    isLoading,
    loading,
    renderFetchMoreButton: fetchMoreButton,
    totalCount: appInstancesTotalCount,
  };
}
