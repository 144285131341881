import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  align-self: center;
  width: 40px;
  flex-shrink: 0;
  background-color: ${Theme.color.blue};
  text-align: center;
  color: ${Theme.color.white};
  line-height: 40px;
  flex-shrink: 0;
  overflow: hidden;
  font-size: 20px;
  font-weight: 900;

  &.size-80 {
    height: 80px;
    width: 80px;
    font-size: 50px;
    line-height: 80px;
  }
  &.size-100 {
    height: 100px;
    width: 100px;
    font-size: 60px;
    line-height: 100px;
  }

  img {
    width: 100%;
    opacity: 0.9;
  }

  @media screen and (max-width: ${Theme.break.small}) {
    height: 32px;
    width: 32px;
    font-size: 18px;
    line-height: 32px;
  }
`;

export const StyledImg = styled.img`
  border-radius: 50%;
  display: inline-block;
  align-self: center;
  width: 40px;
  height: 40px;
  object-fit: cover;
  padding: 4px;

  &.size-100 {
    height: 100px;
    width: 100px;
  }

  &.size-80 {
    height: 80px;
    width: 80px;
  }

  @media screen and (max-width: ${Theme.break.small}) {
    height: 32px;
    width: 32px;
  }
`;
