import styled from "styled-components";

export const StyledStatusNotificationButton = styled.div`
  .ui.button {
    &.button-set-notification-off {
      i {
        background-color: var(--color-gray-200);
      }

      :focus,
      :hover {
        i {
          background-color: var(--color-gray-700);
        }
      }
    }
  }
`;
