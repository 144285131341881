import { withApollo } from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useScreenCastStop } from "../../../../hooks/useStopCasts";
import {
  CastByCastIdFragment,
  CreateCastsByPlaylistIdMutationFn,
  Exact,
  Maybe,
  ScreenCastStopInput,
  SetScreenContentByPlaylistIdMutationFn,
  UpdatePlaylistNameMutationFn,
  useCreateCastsByPlaylistIdMutation,
  useSetScreenContentByPlaylistIdMutation,
  useUpdatePlaylistNameMutation,
} from "../../../../types.g";
import { compose } from "../../../../utils/compose";

export interface ApolloProps extends RouteComponentProps<any> {
  setContentByPlaylistId: SetScreenContentByPlaylistIdMutationFn;
  createCastsByPlaylistId: CreateCastsByPlaylistIdMutationFn;
  updatePlaylistName: UpdatePlaylistNameMutationFn;
  screenCastStop: (
    stopCastsInput: Exact<{
      input: ScreenCastStopInput;
    }>,
    castItem: Maybe<CastByCastIdFragment>
  ) => Promise<any>;
}

export const withData = compose(
  withRouter,
  withApollo,
  (Component) => (props: ApolloProps) => {
    const [createCastsByPlaylistId] = useCreateCastsByPlaylistIdMutation();
    const [setContentByPlaylistId] = useSetScreenContentByPlaylistIdMutation();
    const [updatePlaylistName] = useUpdatePlaylistNameMutation();
    const { screenCastStop } = useScreenCastStop();
    return (
      <Component
        {...props}
        createCastsByPlaylistId={createCastsByPlaylistId}
        screenCastStop={screenCastStop}
        setContentByPlaylistId={setContentByPlaylistId}
        updatePlaylistName={updatePlaylistName}
      />
    );
  }
);
