import {
  ChildDataProps,
  withApollo,
  WithApolloClient,
} from "@apollo/client/react/hoc";
import {
  CreateFontFromCommonMutationFn,
  CreateThemeMutationFn,
  AllFontListQuery,
  RemoveThemeLogoMutationFn,
  UpdateThemeMutationFn,
  useAllFontListQuery,
  useCreateFontFromCommonMutation,
  useCreateThemeMutation,
  useRemoveThemeLogoMutation,
  useUpdateThemeMutation,
} from "../../../../types.g";
import { compose } from "../../../../utils/compose";

export interface ApolloProps
  extends ChildDataProps<{}, {}>,
    WithApolloClient<{}> {
  createTheme: CreateThemeMutationFn;
  updateTheme: UpdateThemeMutationFn;
  removeThemeLogo: RemoveThemeLogoMutationFn;
  createFontFromCommon: CreateFontFromCommonMutationFn;
  allFonts: AllFontListQuery["allFonts"];
}

export const withData = compose(
  withApollo,
  (Component) => (props: ApolloProps) => {
    const [createTheme] = useCreateThemeMutation();
    const [removeThemeLogo] = useRemoveThemeLogoMutation();
    const [updateTheme] = useUpdateThemeMutation();
    const [createFontFromCommon] = useCreateFontFromCommonMutation();
    return (
      <Component
        {...props}
        createTheme={createTheme}
        removeThemeLogo={removeThemeLogo}
        updateTheme={updateTheme}
        createFontFromCommon={createFontFromCommon}
      />
    );
  },
  (Component) => (props) => {
    const { data } = useAllFontListQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
    return <Component {...props} allFonts={data?.allFonts} />;
  }
);
