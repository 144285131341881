import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  height: 100%;

  .container {
    height: 100%;
    padding: 20px;
    overflow: auto;

    h2 {
      color: ${Theme.color.base};
      margin: 40px 0 20px 0;
      font-size: 1em;
    }

    .ui.secondary.pointing.menu {
      margin-top: -20px;
    }

    .tab.active {
      padding: 20px 0;
    }

    .org {
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: ${Theme.color.white};
      ${Theme.default.borderRadius};
      height: 80px;
      width: 100%;
      border: 1px solid ${Theme.color.lightGrey};
      padding: 20px;
      color: ${Theme.color.base};

      &.active {
        cursor: default;
      }

      :last-child {
        margin: 0;
      }

      h3 {
        color: ${Theme.color.base};
      }

      p {
        font-size: 14px;
        color: ${Theme.color.darkGrey};
      }

      i {
        background-color: ${Theme.color.base};
      }

      :hover,
      &.active {
        border: 1px solid ${Theme.color.darkGrey};
      }
    }
  }
`;
