import {
  CloseButtonPosition,
  ModalSize,
  Notification,
} from "@screencloud/screencloud-ui-components";
import { RouteComponentProps } from "react-router-dom";
import { IModalManager, ModalOptions } from "../AppContextProvider/modals/type";
import { AppContextState } from "../AppContextProvider/type";
import {
  createAppThumbnail,
  updateAppInstancesListOrder,
  updateNewCanvasToList,
} from "../helpers/appHelper";
import {
  AllAppInstancesDocument,
  GetAppInstanceTemplatesDocument,
  AppInstance,
  AppVersion,
  CastsBySpaceIdDocument,
  CommonAppInstanceTemplate,
  CreateAppInstanceMutationFn,
  CreateAppInstanceMutationVariables,
  CreateCastsByAppInstanceIdMutationFn,
  CreateCastsByAppInstanceIdMutationVariables,
  DuplicateAppInstanceMutationFn,
  DuplicateAppInstanceMutationVariables,
  Maybe,
  Scalars,
  SetScreenContentByAppInstanceIdMutationVariables,
  UpdateAppInstanceMutationFn,
  UpdateAppInstanceMutationVariables,
} from "../types.g";

import { ScreenPickerActions } from "../components/ScreenPicker";
import queryHelper from "../state/helper/query";
import { modifyScreenByCastId } from "../hooks/useCreateCasts";
import { getDuplicateInstanceName } from "./duplication";
import { DUMMY_APP_INSTANCE_ID } from "src/constants/constants";
import { FormattedMessage } from "react-intl";

interface CreateAppInstanceProps {
  createAppInstance: CreateAppInstanceMutationFn;
  updateAppInstance: UpdateAppInstanceMutationFn;
  history?: RouteComponentProps["history"];
}

interface CastAppInstanceProps {
  createCastsByAppInstanceId: CreateCastsByAppInstanceIdMutationFn;
  setContentByAppInstanceId: Function;
}

interface AppInstanceCreated {
  name: string;
  config: Maybe<Scalars["JSON"]>;
  thumbnail?: string;
  appInstanceId?: string;
}

interface UpdateAppInstanceNameProps {
  updateAppInstance: UpdateAppInstanceMutationFn;
}

interface DuplicateAndModifyProps {
  duplicateAppInstance: DuplicateAppInstanceMutationFn;
  updateAppInstance: UpdateAppInstanceMutationFn;
  canvasAppId: string;
}

/**
 * Create App Instance
 */
export const createBlankInstance = (
  context: AppContextState,
  appInstallId: string | undefined,
  isTemplate?: boolean
): AppInstance => {
  const orgId = context.user.claims.orgId;
  const spaceId = context.user.settings.spaceId;

  const blankAppInstance = {
    appInstallId,
    config: {},
    id: DUMMY_APP_INSTANCE_ID,
    name: "",
    orgId,
    spaceId,
    state: {},
    tags: [],
    version: "1.0.0",
    isTemplate,
  } as any;

  return blankAppInstance;
};

export const onCreateAppInstance = async (
  context: AppContextState,
  props: CreateAppInstanceProps,
  appInstance: AppInstanceCreated,
  appInstallId: string | undefined,
  canvasAppId: string,
  isTemplate?: boolean
): Promise<Scalars["JSON"]> => {
  const createAppInstanceVar: CreateAppInstanceMutationVariables = {
    input: {
      appInstallId,
      config: appInstance.config,
      name: appInstance.name,
      spaceId: context.user.settings.spaceId,
      version: "stable",
      isTemplate,
    },
  };
  const createAppInstanceResult = await props.createAppInstance({
    refetchQueries: [
      {
        query: isTemplate
          ? GetAppInstanceTemplatesDocument
          : AllAppInstancesDocument,
        variables: {
          appId: canvasAppId,
          spaceId: context.user.settings.spaceId,
        },
      },
    ],
    variables: createAppInstanceVar,
    update: (cache, { data }) => {
      if (data?.createAppInstance?.appInstance) {
        updateNewCanvasToList(
          context,
          cache,
          data?.createAppInstance?.appInstance,
          isTemplate || false
        );
      }
    },
  });
  const createdAppInstance =
    createAppInstanceResult?.data?.createAppInstance?.appInstance;
  // create thumbnail and update app
  if (appInstance.thumbnail && createdAppInstance) {
    try {
      const thumbnailId = await createAppThumbnail(
        appInstance.thumbnail,
        `${createdAppInstance.id}`,
        context.user.settings.spaceId
      );
      const updateInstanceVar: UpdateAppInstanceMutationVariables = {
        input: {
          id: createdAppInstance.id,
          spaceId: context.user.settings.spaceId,
          thumbnailFileId: thumbnailId,
        },
      };
      await props.updateAppInstance({
        variables: updateInstanceVar,
        update: (cache, { data }) => {
          if (data?.updateAppInstance?.appInstance) {
            updateAppInstancesListOrder(context, cache);
          }
        },
      });
    } catch (error) {
      console.warn("Could not save app instance thumbnail:", error);
    }
  }
};

export const configureModal = async (
  modalContext: IModalManager,
  appId: string,
  appName: Maybe<string>,
  blankAppInstance: AppInstance | undefined,
  appVersion: AppVersion | undefined,
  appConfigureTitle: JSX.Element,
  appProps: CreateAppInstanceProps,
  closeRoute: string
): Promise<Scalars["JSON"]> => {
  const modalOpts: ModalOptions = {
    className: "app-config-modal",
    closeButtonPosition: CloseButtonPosition.LEFT,
    disableTitle: true,
    size: ModalSize.FULLSCREEN,
  };
  return modalContext.openAppConfigure(
    appId,
    appName,
    blankAppInstance,
    appVersion,
    appConfigureTitle,
    {
      opts: modalOpts,
      onClose: () => {
        appProps.history?.replace(closeRoute);
      },
    }
  );
};

/*
 * Edit App Instance
 */
export const onEditAppInstance = async (
  appInstanceId: string,
  appProps: CreateAppInstanceProps,
  context: AppContextState,
  appConfigureTitle: JSX.Element,
  appInstallId: string | undefined,
  route: string,
  closeRoute: string
) => {
  context.history.push(route + appInstallId + "/" + appInstanceId);
  await configureModal(
    context.modal,
    appInstanceId,
    "",
    undefined,
    undefined,
    appConfigureTitle,
    appProps,
    closeRoute
  );
};

export const onCastInstance = async (
  context: AppContextState,
  props: CastAppInstanceProps,
  app: AppInstance
) => {
  const result = await context.modal.openScreenPicker(app);
  if (result.action === ScreenPickerActions.SET_CONTENT) {
    const setContents = result.data.map((screenId) => {
      const setAppContent: SetScreenContentByAppInstanceIdMutationVariables = {
        input: {
          screenId,
          appInstanceId: app.id,
        },
      };
      return props.setContentByAppInstanceId({
        variables: setAppContent,
      });
    });
    await Promise.all(setContents);
    context.modal.closeModals();
  } else {
    if (result.data.length > 0) {
      const spaceId = context.user.settings.spaceId;
      const castStartVar: CreateCastsByAppInstanceIdMutationVariables = {
        input: {
          screenIds: result.data,
          appInstanceId: app.id,
          expiresAt: result.expiresAt,
        },
      };

      props
        .createCastsByAppInstanceId({
          update: (cache, { data }) => {
            const cast =
              data?.createCastsByAppInstanceId?.casts &&
              data?.createCastsByAppInstanceId.casts[0];
            modifyCastScreenByAppInstance(cache, cast, app);
          },
          variables: castStartVar,
          refetchQueries: [
            {
              query: CastsBySpaceIdDocument,
              variables: { spaceId: context.currentSpace?.id },
            },
          ],
        })
        .then(() => {
          queryHelper.updateCastedScreen(spaceId);
          context.modal.closeModals();
        });
    }
  }
};

export const modifyCastScreenByAppInstance = (cache, cast, app) => {
  modifyScreenByCastId(cache, cast);

  cache.modify({
    id: cache.identify(app),
    fields: {
      castedScreenByAppInstanceId(casts, _options) {
        if (cast) {
          const nodes = [...casts.nodes, ...cast.screensByCastId.nodes];
          return {
            ...casts,
            nodes,
            totalCount: nodes.length,
          };
        }
        return casts;
      },
    },
  });
};

/**
 * Duplicate App Instances
 */
export const onDuplicateInstance = async ({
  appInstance,
  appId,
  duplicateAppInstance,
  context,
  isTemplate = false,
}: {
  appInstance: AppInstance | CommonAppInstanceTemplate;
  appId: string;
  duplicateAppInstance: DuplicateAppInstanceMutationFn;
  context: AppContextState;
  isTemplate?: boolean;
}): Promise<AppInstance | CommonAppInstanceTemplate | undefined> => {
  let appInstanceId = appInstance.id;
  let isAppInstanceTemplate = isTemplate;
  const isCommonAppInstanceTemplate =
    appInstance.__typename === "CommonAppInstanceTemplate";
  if (isCommonAppInstanceTemplate) {
    appInstanceId =
      appInstance.appInstanceByTargetCommonAppInstanceTemplateId?.id;
    isAppInstanceTemplate = true;
  }
  const duplicateAppInstanceInput: DuplicateAppInstanceMutationVariables = {
    input: {
      id: appInstanceId,
      name: getDuplicateInstanceName(appInstance.name),
      spaceId: context.currentSpace?.id,
    },
  };
  try {
    const result = await duplicateAppInstance({
      refetchQueries: [
        {
          query: isAppInstanceTemplate
            ? GetAppInstanceTemplatesDocument
            : AllAppInstancesDocument,
          variables: {
            appId,
            spaceId: context.currentSpace?.id,
          },
        },
      ],
      variables: duplicateAppInstanceInput,
      update: (cache, { data }) => {
        if (data?.duplicateAppInstance?.appInstance) {
          updateNewCanvasToList(
            context,
            cache,
            data?.duplicateAppInstance?.appInstance,
            isAppInstanceTemplate
          );
        }
      },
    });
    if (result.data?.duplicateAppInstance?.appInstance) {
      Notification.success({
        message: isCommonAppInstanceTemplate ? (
          <FormattedMessage
            id="ui_component.common.copy_to_my_templates_success"
            defaultMessage="Copied to My Templates successfully"
          />
        ) : (
          <FormattedMessage
            id="ui_component.common.duplicate_success"
            defaultMessage="Duplicated successfully"
          />
        ),
      });
    }
    return result.data?.duplicateAppInstance?.appInstance as AppInstance;
  } catch (error) {
    console.log(error);
    return;
  }
};

/**
 * Duplicate And Modify App Instances
 */
export const onDuplicateAndModify = async (
  appProps: DuplicateAndModifyProps,
  app: AppInstance,
  isTemplate: boolean,
  context: AppContextState
) => {
  try {
    const duplicateAppInstanceInput: DuplicateAppInstanceMutationVariables = {
      input: {
        id: app.id,
        name: app.name as string,
        spaceId: context.currentSpace?.id,
      },
    };
    // Duplicate App Instance and store result
    const newAppInstance = await appProps.duplicateAppInstance({
      variables: duplicateAppInstanceInput,
      update: (cache, { data }) => {
        if (data?.duplicateAppInstance?.appInstance) {
          updateNewCanvasToList(
            context,
            cache,
            data?.duplicateAppInstance?.appInstance,
            isTemplate
          );
        }
      },
    });

    const newAppInstanceId =
      newAppInstance?.data?.duplicateAppInstance?.appInstance?.id;
    if (!newAppInstanceId) {
      return;
    }

    const updateAppInstanceInput: UpdateAppInstanceMutationVariables = {
      input: {
        id: newAppInstanceId,
        isTemplate,
        spaceId: context.user.settings.spaceId,
      },
    };
    // Update new duplicated app instance depending on isTemplate
    await appProps.updateAppInstance({
      refetchQueries: [
        {
          query: isTemplate
            ? GetAppInstanceTemplatesDocument
            : AllAppInstancesDocument,
          variables: {
            appId: appProps.canvasAppId,
            spaceId: context.user.settings.spaceId,
          },
        },
      ],
      variables: updateAppInstanceInput,
      update: (cache, { data }) => {
        if (data?.updateAppInstance?.appInstance) {
          updateAppInstancesListOrder(context, cache);
        }
      },
    });
    return newAppInstanceId;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Update App Instance Name
 */
export const onUpdateInstanceName = async (
  app: AppInstance,
  appId: string,
  name: string,
  appProps: UpdateAppInstanceNameProps,
  context: AppContextState,
  isTemplate: boolean
) => {
  const updateAppInstanceVar: UpdateAppInstanceMutationVariables = {
    input: {
      id: app.id,
      name,
      spaceId: context.user.settings.spaceId,
    },
  };
  try {
    await appProps.updateAppInstance({
      refetchQueries: [
        {
          query: isTemplate
            ? GetAppInstanceTemplatesDocument
            : AllAppInstancesDocument,
          variables: {
            appId,
            spaceId: context.user.settings.spaceId,
          },
        },
      ],
      variables: updateAppInstanceVar,
      update: (cache, { data }) => {
        if (data?.updateAppInstance?.appInstance) {
          updateAppInstancesListOrder(context, cache);
        }
      },
    });
  } catch (error) {
    console.log(error);
  }
};

interface HasName {
  name?: string | null | undefined;
}

/**
 * Search App Instances
 */
export const getSearchedInstances = <T extends HasName>(
  searchTerms: string,
  instances: T[]
): T[] => {
  if (!searchTerms) {
    return instances;
  }
  return instances.filter(({ name }) =>
    name?.toLowerCase().includes(searchTerms.toLowerCase())
  );
};
