import memoizeOne from "memoize-one";

import { FEATURE_FLAGS_ENUM } from "../constants/featureFlag";
import { AppContextState, AppContextType } from "src/AppContextProvider/type";
import { useAppContext } from "src/hooks/useAppContext";

const ENABLE_FEATURES = "enable_features";
const DISABLE_FEATURES = "disable_features";

const getParameterByName = (name: string, url: string): string | null => {
  const formattedString = name.replace(/[\[\]]/g, "$&");
  const regex = new RegExp("[?&]" + formattedString + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const getEnableFeatureFromUrl = memoizeOne((url: string): string[] => {
  const params = getParameterByName(ENABLE_FEATURES, url);
  params && localStorage.setItem(ENABLE_FEATURES, params);
  return params?.split(",") ?? [];
});

const getDisableFeatureFromUrl = memoizeOne((url: string): string[] => {
  const params = getParameterByName(DISABLE_FEATURES, url);
  params && localStorage.setItem(DISABLE_FEATURES, params);
  return params?.split(",") ?? [];
});

/**
 *
 * @param featureName Given feature flag
 * @param url Current URL
 * @returns true if enable_feature querystring contains given feature flag.
 */
export const isFeatureEnabledByUrl = (
  featureName: FEATURE_FLAGS_ENUM,
  url: string = window.location.href
): boolean => {
  const enableFeatures =
    getEnableFeatureFromUrl(url) ||
    (localStorage.getItem(ENABLE_FEATURES) !== null &&
      localStorage.getItem(ENABLE_FEATURES)?.split(","));
  if (enableFeatures.length === 0) {
    return false;
  }
  return enableFeatures.includes(featureName);
};

/**
 *
 * @param featureName Given feature flag
 * @param url Current URL
 * @returns true if disable_feature querystring contains given feature flag.
 */
export const isFeatureDisabledByUrl = (
  featureName: FEATURE_FLAGS_ENUM,
  url: string = window.location.href
): boolean => {
  const disableFeature =
    getDisableFeatureFromUrl(url) ||
    (localStorage.getItem(DISABLE_FEATURES) !== null &&
      localStorage.getItem(DISABLE_FEATURES)?.split(","));
  if (disableFeature.length === 0) {
    return false;
  }
  return disableFeature.includes(featureName);
};

export const isUnderDevelopment = (
  context: AppContextType | AppContextState
): boolean => {
  return context.shouldShowFeature(FEATURE_FLAGS_ENUM.STUDIO_DEV);
};

interface UnderDevelopmentProps {
  children: React.ReactNode;
}
export const UnderDevelopment: React.FC<UnderDevelopmentProps> = ({
  children,
}) => {
  const context = useAppContext();
  if (isUnderDevelopment(context)) {
    return <>{children}</>;
  }
  return <></>;
};
