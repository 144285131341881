import { provider } from "./provider";
import * as array from "./array";
import * as currency from "./currency";
import * as date from "./date";
import * as errors from "./errors";
import * as log from "./log";
import * as object from "./object";
import * as plan from "./plan";
import * as string from "./string";
import * as url from "./url";

export const util = {
  array,
  currency,
  date,
  errors,
  log,
  object,
  plan,
  provider,
  string,
  url,
};
