import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Icon,
  Loader,
  LoaderBar,
} from "@screencloud/screencloud-ui-components";
import { groupBy, isEmpty, toPairs } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AvailableAppInstanceFragment } from "../../types.g";
import {
  AppInstallProps,
  AppQueryInputProps,
  withAppInstallData,
} from "./apollo";
import { AppTabPaneItemActions } from "./appItem";
import AppTabPaneList from "./applist";
import { StyledAppinstall } from "./styles";

export interface AppTabPaneProps extends AppQueryInputProps {
  addItemToPlaylist: (
    app: AvailableAppInstanceFragment,
    action: AppTabPaneItemActions
  ) => void;
}

export interface AppInstallState {
  appId: string;
}

class AppTabPane extends React.Component<
  AppTabPaneProps & AppInstallProps,
  AppInstallState
> {
  constructor(props: AppTabPaneProps & AppInstallProps) {
    super(props);
    this.state = { appId: "" };
  }

  public handleAddNewApp = () => {
    // to do a real add app instance here
    // but for now just redirect to app page
    this.props.history.push({ pathname: "/apps" });
  };

  public gotoAppInstanceList = (appId: string) => {
    this.setState({ appId });
  };

  public goBackToAppInstall = () => {
    this.setState({ appId: "" });
  };

  public getAppNameById = (appId: string): string | undefined => {
    if (this.props.data?.spaceById?.availableAppInstallsBySpaceId) {
      const appInstalled = this.props.data.spaceById.availableAppInstallsBySpaceId.nodes.find(
        (item) => item.appByAppId?.id === appId
      );
      return (appInstalled && appInstalled!.appByAppId!.name) || undefined;
    } else {
      return undefined;
    }
  };
  public render() {
    const { searchTerms, addItemToPlaylist } = this.props;
    if (
      this.props.data?.spaceById?.availableAppInstallsBySpaceId ||
      searchTerms.length > 0
    ) {
      const appInstallsGroupByAppId = toPairs(
        groupBy(
          this.props.data?.spaceById?.availableAppInstallsBySpaceId?.nodes,
          "appByAppId.id"
        )
      );
      const appInstalls = appInstallsGroupByAppId
        .map(([appId, appValue]) => {
          const app = appValue!;
          const totalApp = app.reduce((result, curr) => {
            const totalCount = isEmpty(result)
              ? curr.availableAppInstancesByAppInstallIdAndSpace.totalCount
              : curr.availableAppInstancesByAppInstallIdAndSpace.totalCount +
                result.availableAppInstancesByAppInstallIdAndSpace.totalCount;
            return {
              ...curr,
              availableAppInstancesByAppInstallIdAndSpace: {
                ...curr.availableAppInstancesByAppInstallIdAndSpace,
                totalCount,
              },
            };
          });
          return totalApp;
        })
        .filter((app) => app.appByAppId?.name !== "Canvas")
        .sort((a, b) =>
          a.appByAppId?.name! > b.appByAppId?.name!
            ? 1
            : b.appByAppId?.name! > a.appByAppId?.name!
            ? -1
            : 0
        );

      return (
        <StyledAppinstall>
          <div className={`layout-list app-section`}>
            {(searchTerms && searchTerms.length === 0) ||
            this.state.appId.length > 0 ? (
              <Breadcrumb>
                <BreadcrumbSection>
                  <Button transparent onClick={this.goBackToAppInstall}>
                    <FormattedMessage id="apps.apps" defaultMessage="Apps" />
                  </Button>
                </BreadcrumbSection>
                <BreadcrumbDivider />
                <BreadcrumbSection active>
                  {this.getAppNameById(this.state.appId)}
                </BreadcrumbSection>
              </Breadcrumb>
            ) : (
              <FormattedMessage id="apps.apps" defaultMessage="Apps" />
            )}
            <Button
              className="add-appinstance"
              mini
              icon
              onClick={this.handleAddNewApp}
              data-testid="add-app-button"
            >
              <Icon name="plus" />
            </Button>
          </div>
          <div className="layout-container" id="scrollableDiv">
            {this.props.data.loading ? (
              <div className="app-loader">
                <Loader inline size="small" />
              </div>
            ) : (
              <div className="app-instance-list">
                {searchTerms.length > 0 || this.state.appId.length > 0 ? (
                  <AppTabPaneList
                    iconUrl={
                      appInstalls.find(
                        (val) => val.appByAppId?.id === this.state.appId
                      )?.appByAppId?.iconUrl
                    }
                    appId={this.state.appId}
                    searchTerms={searchTerms}
                    addItemToPlaylist={addItemToPlaylist}
                  />
                ) : (
                  appInstalls.map((node, idx) => (
                    <div key={`app-install-${idx}`} className="column app-item">
                      <div
                        className="app-instance"
                        onClick={() =>
                          this.gotoAppInstanceList(node.appByAppId?.id)
                        }
                      >
                        <div className="app-instance-thumbnail">
                          <div
                            className="thumbnail"
                            style={{
                              backgroundImage:
                                "url(" + node!.appByAppId!.iconUrl + ")",
                            }}
                          />
                        </div>
                        <div className="app-instance-info">
                          <div className="app-instance--title">
                            {node!.appByAppId!.name}
                          </div>
                          <span className="app-instance-type">
                            {node!.appByAppId!.categories!.join(",")}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </StyledAppinstall>
      );
    } else {
      return <LoaderBar />;
    }
  }
}

export default withAppInstallData(
  AppTabPane
) as React.ComponentType<AppTabPaneProps>;
