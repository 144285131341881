import * as React from "react";
import { Space } from "src/types.g";
import { isSupportEmbededLink, Shareable } from "../../helpers/shareableHelper";
import { Styled } from "./styles";
import {
  getAllSpaces,
  getNonOwnerSpaces,
  isAllowShareAll,
  isCanvasTemplate,
  isChannel,
} from "./helper";
import { useAppContext } from "src/hooks/useAppContext";
import { useEmbededUrl } from "./hooks/useEmbed";
import { ShareFooter } from "./components/ShareFooter";
import { ShareList, SpaceListProps } from "./components/ShareList";
import { ChannelShareList } from "./components/ChannelShareList";

export interface EmbedStatus {
  isEmbedEnabled: boolean;
  isEmbedPublic: boolean;
}

export interface ShareModalProps {
  cover?: React.CSSProperties;
  isDisabled?: boolean;
  subTitle?: string;
  // Avoid fetching shared spaces for the channel section due to potential performance issues with a large number of channels
  // Fetching shared spaces for editable channels can be slow and costly due to GraphQL queries
  // Do fetching inside ChannelShareList instead
  sharedSpaces?: Pick<Space, "id">[];
  onShareToSpaceChanges: (sharedSpaceIds: string[]) => void;
  onShareAllChange?: (isShareAll: boolean) => void;
  onEmbedChange?: (embededStatus: EmbedStatus) => void;
  shareable: Shareable;
  embedShortId?: string;
}

export enum EmbedShareOptions {
  Disabled = "Disabled",
  Public = "Public",
  Org = "Org",
}

export interface ShareModalState {
  isShareAll: boolean;
  isLinkCopied: boolean;
  sharedSpaceIds: string[];
  embedSharedOption: EmbedShareOptions;
  embededUrl: string;
}

const ShareModal = (props: ShareModalProps) => {
  const context = useAppContext();
  const {
    shareable,
    embedShortId,
    isDisabled,
    onShareAllChange,
    sharedSpaces,
  } = props;

  const isChannelEntity = isChannel(shareable);
  const isCanvasTemplateEntity = isCanvasTemplate(shareable);
  const isShareChild = shareable.childOf;

  const [sharedSpaceIds, setSharedSpaceIds] = React.useState(
    shareable.isSharedAll
      ? getNonOwnerSpaces(context, shareable).map((item) => item.id)
      : (sharedSpaces ?? []).map((item) => item.id)
  );
  const embededUrl = useEmbededUrl(shareable, embedShortId);

  const [isShareAll, setIsShareAll] = React.useState(
    Boolean(shareable.isSharedAll)
  );

  const allSpaces = getAllSpaces(context);

  const listProps: SpaceListProps = {
    sharedSpaceIds,
    isDisabled,
    onShareAllChange,
    onShareToSpaceChanges: props.onShareToSpaceChanges,
    shareable,
    setSharedSpaceIds,
    isShareAll,
    setIsShareAll,
  };

  const renderShareList = () => {
    if (isShareChild) {
      return null;
    }

    if (isChannelEntity) {
      return <ChannelShareList {...listProps} />;
    } else {
      return (
        <ShareList
          {...listProps}
          isCanvasTemplateShare={isCanvasTemplateEntity}
          isAllowShareAll={isAllowShareAll(context, shareable)}
        />
      );
    }
  };

  return (
    <Styled>
      {renderShareList()}
      {isSupportEmbededLink({ shareable, context }) && (
        <ShareFooter
          allSpaces={allSpaces}
          isDisabled={isDisabled || false}
          embededUrl={embededUrl}
          shareable={shareable}
          sharedSpaces={sharedSpaceIds}
          onEmbedChange={props.onEmbedChange}
        />
      )}
    </Styled>
  );
};

export default ShareModal;
