import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

import { WhiteLabelStyles } from "../../helpers/whiteLabel";

export const Styled = styled.div<WhiteLabelStyles>`
  max-width: ${Theme.sidebar.maxWidth};
  width: 100%;
  padding: 5px 10px;

  .ui.dropdown {
    border-radius: var(--radius-md, 8px);
    width: 100%;
    height: 100%;

    .account-trigger {
      padding: 8px 10px 8px 12px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: 65px;

      .user {
        display: flex;
        align-items: center;
        width: 100%;

        .user-alpha {
          margin-left: 11px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 40px);
          display: flex;
          flex-direction: column;

          .name {
            font-size: 1.25em;
            font-weight: bold;
            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${(props) =>
              props.textColor
                ? `var(--color-primary-foreground-active)`
                : `var(--color-white-100)`};
          }

          .email {
            color: ${(props) =>
              props.textColor
                ? `var(--color-primary-foreground-active)`
                : `var(--color-white-100)`};
            font-size: 0.875em;
            line-height: 1.357;
            overflow: hidden;
            text-overflow: ellipsis;
            opacity: 0.5;
          }
        }
      }
    }

    &.active,
    &:hover {
      background-color: ${(props) =>
        props.activeItemColor ?? `var(--color-black-80)`};

      .account-trigger {
        .user {
          .user-alpha {
            .name,
            .email {
              color: ${(props) =>
                props.activeTextColor
                  ? `var(--color-primary-foreground)`
                  : `var(--color-white-100)`};
            }
          }
        }
      }

      .item {
        &.danger {
          i {
            background-color: var(--color-foreground-destructive);
          }

          :hover {
            i {
              background-color: var(--color-foreground-destructive-active);
            }
          }
        }
      }
    }

    .dropdown.icon {
      display: none;
    }
  }

  /* force hide the menu dropdown */
  .ui.active.dropdown.visible {
    .menu.visible {
      display: none !important;
    }
  }

  .account-settings {
    position: absolute;
    bottom: 65px;
    background-color: var(--color-white-100);
    width: 100%;
    border-radius: var(--radius-md, 8px);
    padding: var(--spacing-sm, 8px) 0;
    min-width: 200px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px 0px;
    max-height: calc(100vh - 85px);
    overflow: auto;
  }

  .item {
    i {
      width: var(--size-xs);
      height: var(--size-xs);
      flex-shrink: 0;
    }

    .button {
      border-radius: 0;
      font-weight: normal;
      justify-content: unset;
      width: 100%;
    }
  }

  .divider {
    width: 100%;
    border-top: 1px solid var(--color-gray-100, #e2e3e5);
    margin: var(--spacing-sm, 8px) 0px;
    width: 100%;
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    padding: 0;
    bottom: 0;

    .ui.dropdown {
      border-radius: 0;

      .account-trigger {
        width: ${Theme.sidebar.minWidth};
        height: ${Theme.sidebar.minWidth};
        padding: 0;
        justify-content: center;

        .user {
          justify-content: center;

          .user-alpha {
            display: none;
          }
        }
      }
    }

    .account-settings {
      left: 10px;
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .ui.dropdown {
      .account-trigger {
        width: 32px;
        height: 32px;
      }
    }

    .account-settings {
      bottom: auto;
      right: 0;
      top: 50px;
      left: auto;
      width: 100vw;
      border-radius: 0;
      border-bottom: 1px solid var(--color-border);
    }
  }
`;
