/**
 * Retrieve the current host.
 *
 * @remarks
 * This function retrieves the protocol and domain of the current studio without requiring manual input in the .env
 * file. For example, it will return https://studio.eu.screencloud.com.
 */
export function getHost(): string {
  return `${window.location.protocol}//${window.location.host}`;
}

/**
 * Append a path to the host.
 *
 * @param path - The path to be appended to the host.
 *
 * @remarks
 * This method allows you to add a path to the current host, facilitating the construction of a simple URL.
 */
export function getHostWithPath(path: string): string {
  return `${getHost()}${path}`;
}
