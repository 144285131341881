import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  height: 100%;
  background: ${Theme.color.nearWhite};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0 0 ${Theme.default.borderRadiusValue}
    ${Theme.default.borderRadiusValue};

  .site-config {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: auto;
  }

  .site-form {
    width: 50%;
    background: ${Theme.color.white};
    overflow: auto;

    border-right: 1px solid ${Theme.color.borderSection};

    .site-input {
      padding: 20px;

      .info-banner {
        margin-top: 5px;
      }

      .site-error {
        margin-top: 5px;

        span {
          color: ${Theme.color.warning};
          font-size: 0.875em;
        }
      }
    }
    .site-detail {
      border-top: 1px solid ${Theme.color.lightGrey};
      padding: 20px;

      .site-title {
        .ui.input {
          width: 100%;
        }
      }

      .site-tag {
        margin-top: 20px;
      }

      .site-refresh-interval {
        margin-top: 20px;
        .ui.input {
          width: 100%;
        }
      }
    }
  }

  .site-preview {
    width: 50%;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    :empty {
      :before {
        content: "No preview";
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${Theme.color.darkGrey};
      }
    }

    .no-preview {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${Theme.color.darkGrey};
    }

    .instruction-hero {
      border-radius: 5px;
      padding-top: 80px;
      padding-bottom: 80px;
      min-width: 400px;
      background-color: ${Theme.color.lightGrey};
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .toast {
      padding-top: 20px;
      padding-bottom: 20px;
      align-items: flex-start;
      padding-left: 10px;
      padding-right: 10px;
      position: absolute;
      left: 40px;
      width: calc(100% - 80px);
      box-shadow: 0 10px 40px -20px rgba(0, 0, 0, 0.5);
      z-index: 15;
      display: none;

      &-top {
        top: 10px;
      }

      &-bottom {
        bottom: 10px;
      }
    }

    .open {
      display: block;
    }

    .warning {
      border: 1px solid ${Theme.color.warning};
      background-color: #fee8d7;
    }

    .error {
      border: 1px solid ${Theme.color.error};
      background-color: #f6d1d5;
    }

    .rendered {
      background-color: #fef9e7;
    }

    .image-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px;
      position: relative;

      img {
        max-height: 100%;
        max-width: 100%;
        box-shadow: 0 10px 40px -20px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .site-action {
    background: ${Theme.color.white};
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-top: 1px solid ${Theme.color.lightGrey};
    height: 65px;
    overflow: visible;
    border-radius: 0 0 ${Theme.default.borderRadiusValue}
      ${Theme.default.borderRadiusValue};

    .submit {
      width: 200px;
    }
  }

  .title {
    color: ${Theme.color.base};
    font-size: 1.25em;
    line-height: 1.4;
  }

  .core {
    .title {
      color: ${Theme.color.white};
    }
  }

  .info-text {
    color: ${Theme.color.white};
  }

  .description {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.3125;
    color: ${Theme.color.midGrey};
    margin-top: 10px;
  }

  .sales-button {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refresh-interval-units {
    width: 100px !important;
  }
`;
