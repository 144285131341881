import {
  DatetimeFormat,
  Icon,
  LoaderBar,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SortingActions } from "../../constants/constants";
import { appInstanceIconUrl } from "../../helpers/appHelper";
import { updateAndToggleSelectedItems } from "../../helpers/updateAndToggleSelectedItemsHelper";

import { AppInstance } from "../../types.g";
import { compose } from "../../utils/compose";
import DateTime from "../DateTime";
import NoCanvasSearchResult from "../../pages/Canvas/CanvasList/NoCanvasSearchResult";
import { AppPickerItem } from "./styles";
import {
  UseSearchAppInstancePickerByAppId,
  useSearchAppInstancePickerByAppId,
} from "../AppPicker/hooks/useSearchAppInstancePickerByAppId";

const withData = compose((Component) => (props) => {
  const searchCanvas = useSearchAppInstancePickerByAppId(
    props.query,
    props.appId,
    props.spaceId
  );

  return <Component {...props} {...searchCanvas} />;
});

export interface SearchAppContentPickerProps
  extends UseSearchAppInstancePickerByAppId {
  isMultipleSelect: boolean | undefined;
  query: string;
  callback: (ids: string[], selectedApps: AppInstance[]) => void;
  spaceId?: UUID;
}

export interface AppContentPickerState {
  isOrderByAscending: boolean;
  selectedIds: string[];
  selectedApps: AppInstance[];
  sortBy: SortingActions;
  isFetchMore: boolean;
}

class SearchAppPicker extends PureComponent<
  SearchAppContentPickerProps,
  AppContentPickerState
> {
  constructor(props: SearchAppContentPickerProps) {
    super(props);
    this.state = {
      isFetchMore: false,
      isOrderByAscending: true,
      selectedApps: [],
      selectedIds: [],
      sortBy: SortingActions.SORT_BY_NAME,
    };
  }

  public onToggleSelection = (app: any) => {
    if (this.props.appInstances) {
      const appInstances = this.props.appInstances;
      const { selectedArray } = updateAndToggleSelectedItems(
        app.id,
        app,
        this.state.selectedIds,
        this.state.selectedApps,
        this.props.isMultipleSelect
      );
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            selectedIds: selectedArray,
          };
        },
        () => {
          const selectedApps = this.state.selectedIds.reduce(
            (allSelectedApps: AppInstance[], currentId) => {
              const appsId = appInstances.find(
                (appInstance) => appInstance!.id === currentId
              );
              return [...allSelectedApps, appsId];
            },
            []
          );
          this.props.callback(
            this.state.selectedIds,
            selectedApps as AppInstance[]
          );
        }
      );
    }
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public renderAppInstanceItems = (): JSX.Element[] => {
    if (this.props.appInstances) {
      const appInstances = this.props.appInstances;
      return appInstances.map((node) => {
        const app = node!;
        const iconUrl = appInstanceIconUrl((app as unknown) as AppInstance);
        return (
          <AppPickerItem
            className="media-item"
            key={`apps-${app.id}`}
            onClick={() => this.onToggleSelection(app)}
            selected={this.state.selectedIds.includes(app.id)}
          >
            <div className="media-core">
              <div className="media-alpha">
                <div className="thumbnail-preview">
                  <div className="thumbnail">
                    <div className="wrapper">
                      <img src={iconUrl} />
                    </div>
                  </div>
                </div>
                <div className="media-title">
                  <h3>{app.name}</h3>
                  <div>
                    <span className="media-item__date">
                      <FormattedMessage
                        id="common.text.created_on"
                        defaultMessage="Created on"
                      />{" "}
                      <DateTime
                        value={app.createdAt}
                        format={DatetimeFormat.Long}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="media-app-customs">
                {/* add app customs here */}
              </div>

              <div className="media-application">
                <span>{app.appByAppId?.name}</span>
              </div>
            </div>
            <div className="media-checked">
              <Icon name="checked-circle" />
            </div>
          </AppPickerItem>
        );
      });
    } else {
      return [];
    }
  };

  public render() {
    return (
      <>
        {this.renderAppInstanceItems()}
        {this.props.renderFetchMoreButton}
        {this.props.appInstances.length === 0 && !this.props.loading ? (
          <NoCanvasSearchResult isTemplate={false} />
        ) : null}
        {this.props.loading && <LoaderBar />}
      </>
    );
  }
}

export default withData(SearchAppPicker);
