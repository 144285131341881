import { format as toFormat, isValid as isDateValid } from "date-fns";
import {
  File,
  UpdateFileAvailableMutationVariables,
  UpdateFileExpireMutationVariables,
  useUpdateFileAvailableMutation,
  useUpdateFileExpireMutation,
} from "../../../types.g";

export function useSetFileAvailabilityMutation() {
  const [updateFileAvailable] = useUpdateFileAvailableMutation();
  const [updateFileExpire] = useUpdateFileExpireMutation();

  const setFileAvailability = async ({
    file,
    availableTime,
    expireTime,
  }: {
    file: Pick<File, "id">;
    availableTime: string;
    expireTime: string;
  }) => {
    const fileId = file.id;
    const availableAt =
      availableTime !== "" && isDateValid(new Date(availableTime))
        ? availableTime
        : toFormat(new Date(), "YYY-MM-dd'T'HH:mm:00+00:00");
    const expireAt =
      expireTime !== "" && isDateValid(new Date(expireTime))
        ? expireTime
        : undefined;

    const updateFileAvailableVariables: UpdateFileAvailableMutationVariables = {
      input: {
        availableAt,
        fileId,
      },
    };

    const updateFileExpireVariables: UpdateFileExpireMutationVariables = {
      input: {
        expireAt,
        fileId,
      },
    };

    return Promise.all([
      updateFileAvailable({ variables: updateFileAvailableVariables }),
      updateFileExpire({ variables: updateFileExpireVariables }),
    ]);
  };

  return { setFileAvailability };
}
