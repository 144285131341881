import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

import externalIcon from "../../images/external.svg";

export interface StyledProps {
  showSecureSiteColumns: boolean;
}

export const Styled = styled.div<StyledProps>`
  border-top: 1px solid ${Theme.color.silver};

  :first-child {
    border-top: none;
  }

  &.site-item {
    display: flex;
    padding: 10px 0;

    .site-core {
      width: calc(100% - 60px);
      display: flex;
      align-items: center;

      .site-name,
      .site-starred,
      .site-casting,
      .site-type,
      .site-usage,
      .site-tags,
      .site-share {
        text-align: center;

        span {
          color: ${Theme.color.grey};
          font-size: 0.875em;

          span {
            font-size: initial;
          }
        }
      }

      .site-name {
        width: 34%;
        display: flex;
        flex: 0 0 auto;
        // TODO: Restore when clicking into a site has been implemented
        // cursor: pointer;
        ${Theme.default.borderRadius};

        .thumbnail-preview {
          width: 60px;
          height: 60px;
          position: relative;
          margin: 0 10px;

          .thumbnail {
            width: 100%;
            height: 100%;
          }
        }

        .site-title {
          width: calc(100% - 80px);
          display: flex;
          flex-direction: column;
          justify-content: center;

          * {
            text-align: left;
          }

          h3 {
            display: inline-flex;
            height: 25px;
            padding: 0;
            margin-bottom: 0;
            font-weight: bold;
            white-space: nowrap;
            border-bottom: 1px solid transparent;
            overflow: hidden;

            span {
              font-size: 1em;
              color: ${Theme.color.darkGrey};
              font-style: normal;
              line-height: 1.2;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: calc(100% - 18px);

              :hover {
                color: ${Theme.color.base};
              }
            }

            i {
              background-color: ${Theme.color.lightGrey};
              cursor: pointer;
              display: none;
              align-self: center;
              margin-bottom: 2.5px;
              width: 18px;
              mask-size: 13px;
              mask-position: 5px;
              position: relative;
              z-index: 1;
            }
          }

          div {
            display: flex;

            span {
              line-height: 14px;
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .site-item__url {
              display: none;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              position: relative;
              padding-right: 10px;

              :hover {
                color: ${Theme.color.base};

                :after {
                  display: inline-block;
                  content: "";
                  background: url(${externalIcon}) no-repeat center;
                  background-size: 10px;
                  width: 10px;
                  height: 10px;
                  margin-left: 2px;
                  margin-bottom: -1px;
                }
              }
            }
          }

          .inline-input {
            font-size: 1em;
            padding: 0;
            margin: 0;
          }
        }

        :hover {
          .site-title {
            h3 {
              i {
                display: inline-block;
              }
            }
          }
        }
      }

      .site-starred {
        width: 9.4%;

        .toggle.star {
          opacity: 0;

          &.active {
            opacity: 1;
          }
        }
      }

      .site-casting {
        width: 9.4%;
      }

      .site-type {
        width: ${(props) => (props.showSecureSiteColumns ? "9.4%" : "18.8%")};

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }

      .site-compute {
        display: ${(props) => (props.showSecureSiteColumns ? "block" : "none")};
        width: 9.4%;
        text-align: center;

        span {
          text-transform: uppercase;
        }
      }

      .site-usage {
        display: ${(props) => (props.showSecureSiteColumns ? "block" : "none")};
        width: 9.4%;

        span {
          color: ${Theme.color.midGrey};
        }
      }

      .site-tags {
        width: ${(props) => (props.showSecureSiteColumns ? "19%" : "28.4%")};
      }

      .site-share {
        width: ${(props) => (props.showSecureSiteColumns ? "9.4%" : "18.8%")};
        .site-shared-icon {
          width: 100%;
        }
      }
    }

    .site-options {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      .site-dropdown {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        &.active {
          opacity: 1;
          background: ${Theme.color.silver};

          i {
            background-color: ${Theme.color.base};
          }

          .menu > .item {
            i {
              background-color: ${Theme.color.darkGrey};
            }
          }
        }

        :hover {
          background: ${Theme.color.silver};

          i {
            background-color: ${Theme.color.base};
          }
        }

        .menu {
          right: 0;
          left: auto;
        }
      }
    }

    :hover {
      background: rgba(0, 0, 0, 0.01);

      .site-core {
        .site-name {
          .site-title {
            h3 {
              i {
                :hover {
                  background-color: ${Theme.color.base};
                }
              }
            }
          }
        }

        .site-starred {
          .toggle.star {
            opacity: 1;
            cursor: pointer;

            :hover {
              background: ${Theme.color.silver};
            }
          }
        }
      }

      .site-options {
        .site-dropdown {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.large}) {
    &.site-item {
      .site-core {
        .site-name {
          .site-title {
            div {
              .site-item__date {
                .author {
                  display: none;
                }
              }
            }
          }
        }

        .site-starred {
          width: 11%;
        }

        .site-casting {
          width: 11%;
        }

        .site-type {
          width: ${(props) => (props.showSecureSiteColumns ? "11%" : "22%")};
        }

        .site-compute {
          display: ${(props) =>
            props.showSecureSiteColumns ? "block" : "none"};
          width: 11%;
        }

        .site-usage {
          display: ${(props) =>
            props.showSecureSiteColumns ? "block" : "none"};
          width: 11%;
        }

        .site-tags {
          width: ${(props) => (props.showSecureSiteColumns ? "11%" : "22%")};
        }

        .site-share {
          width: ${(props) => (props.showSecureSiteColumns ? "11%" : "22%")};
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    &.site-item {
      .site-core {
        .site-name {
          width: 40%;

          .site-title {
            div {
              .site-item__date {
                display: initial;
              }
              .site-item__url {
                display: none;
              }
            }
          }
        }

        .site-starred {
          width: 15%;
        }

        .site-casting {
          width: 15%;
        }

        .site-type {
          width: 15%;
        }

        .site-compute {
          display: none;
        }

        .site-usage {
          display: none;
        }

        .site-tags {
          width: 15%;
        }
        .site-share {
          width: 15%;
        }
      }

      .site-options {
        .site-dropdown {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    &.site-item {
      .site-core {
        .site-name {
          width: 60%;

          .site-title {
            div {
              .site-item__date {
                display: none;
              }
              .site-item__url {
                display: inline-block;

                :hover {
                  color: ${Theme.color.grey};
                }
              }
            }
          }
        }

        .site-starred {
          width: 20%;
        }

        .site-casting {
          width: 20%;
        }

        .site-type {
          display: none;
        }

        .site-compute {
          display: none;
        }

        .site-usage {
          display: none;
        }

        .site-tags {
          display: none;
        }

        .site-share {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    &.site-item {
      align-items: center;
      height: 60px;
      padding: 0;

      .site-core {
        width: calc(100% - 40px);

        .site-name {
          width: calc(100% - 80px);

          .thumbnail-preview {
            height: 40px;
            margin-left: 0;
            width: 40px;
          }

          .site-title {
            width: calc(100% - 60px);

            h3 {
              font-size: 1em;
              height: auto;
            }

            span {
              max-width: 100%;
            }
          }
        }

        .site-starred {
          width: 80px;
        }

        .site-casting {
          display: none;
        }

        .site-type {
          display: none;
        }

        .site-compute {
          display: none;
        }

        .site-usage {
          display: none;
        }

        .site-tags {
          display: none;
        }

        .site-share {
          display: none;
        }
      }

      .site-options {
        width: 40px;
      }
    }
  }

  &.readonly {
    cursor: pointer;
  }

  &.selected:active,
  &.selected:hover,
  &.selected {
    background-color: ${Theme.color.silver};
    box-shadow: none;
    border-top-color: ${Theme.color.lightGrey};

    .site-checked {
      i {
        opacity: 1;
      }
    }
  }

  &.site-item-small {
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.875em;
    }

    .view-icon {
      opacity: 0;
      width: 15px;
      height: 15px;
      margin: 0 10px 0 0;
    }

    .ui.grid > .row {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .wrapper__image {
      width: 43px !important;
      padding: 10px 0;
    }

    :hover {
      .view-icon {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    &.site-item {
      .site-core {
        .site-name {
          width: 100%;
        }

        .site-starred {
          display: none;
        }
      }
    }
  }
`;
