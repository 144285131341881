import * as React from "react";
import { FormattedMessage } from "react-intl";
import CastItem, { CastItemAction } from "../../components/CastItem";
import { get } from "../../helpers/objectHelper";
import { updateContentCasting } from "../../hooks/useStopCasts";
import { useAppContext } from "../../hooks/useAppContext";
import queryHelper from "../../state/helper/query";
import { Cast, CastStopMutationVariables } from "../../types.g";
import CastingScreens from "../CastingScreens";
import { ApolloProps, withData } from "./apollo";
import { Styled } from "./styles";
import { getCastItems } from "./mapper";
import { CastItemProps } from "./types";

interface Props {
  refetchParent?: () => void;
  router?: any;
}

const CastingList: React.FunctionComponent<Props & ApolloProps> = (props) => {
  const context = useAppContext();

  const getCastById = (castId: string) => {
    const { casts } = props;
    return casts.find((item) => item.id === castId) as Cast;
  };

  const onCastingItemCallBack = async (
    action: CastItemAction,
    itemInfo: { [key: string]: string }
  ) => {
    const spaceId = context.user.settings.spaceId;
    const currentCastItem = getCastById(itemInfo.id);
    const screens = currentCastItem.screensByCastId.nodes.map((screen) => ({
      id: screen!.id,
      spaceId: screen.spaceId,
    }));
    if (action === CastItemAction.Stop) {
      const castStopInput: CastStopMutationVariables = {
        input: {
          castId: itemInfo.id,
          screenIds: screens.map((screen) => screen.id),
        },
      };
      await props.castStop({
        update: (_cache) => {
          Promise.all(
            screens.map((screen) => {
              queryHelper.updateScreenDetail(screen.id);
              queryHelper.updateCastedScreen(screen.spaceId);
            })
          );
          updateContentCasting(currentCastItem);
        },
        variables: castStopInput,
      });

      const castedScreens = await queryHelper.updateCastedScreen(
        spaceId || null
      );

      if (props.refetchParent) {
        props.refetchParent();
      }

      if (
        (get(
          castedScreens,
          "data",
          "spaceById",
          "castsBySpaceId",
          "totalCount"
        ) || 0) === 0
      ) {
        context.modal.closeNavigationControlModal();
      }
    } else if (action === CastItemAction.Navigate) {
      props.router.push({
        component: <CastingScreens castId={itemInfo.id} />,
        props: {},
        title: itemInfo.name,
      });
    }
  };

  const renderCastItemList = React.useCallback(
    (castItems: CastItemProps[]) => {
      return castItems.map((item, index) => {
        return (
          <CastItem
            itemId={item.id}
            contentName={item.name}
            mimeType={item.mimeType}
            duration={item.duration}
            color={item.color}
            coverStyle={item.coverStyle}
            contentImage={item.source}
            screenCount={item.screenCount}
            castDate={item.castedDate}
            onCallback={onCastingItemCallBack}
            key={item.id + "-" + index}
          />
        );
      });
    },
    [onCastingItemCallBack]
  );

  const castItems = getCastItems(props.casts, context.secureMediaPolicy || "");

  return castItems.length === 0 ? null : (
    <Styled className="cast-items">
      <div className="header">
        <div className="media-core">
          <div className="media-alpha">
            <FormattedMessage id="subheader.label.name" defaultMessage="Name" />
          </div>
          <div className="media-duration">
            <FormattedMessage
              id="subheader.label.duration_left"
              defaultMessage="Duration Left"
            />
          </div>
          <div className="media-kind">
            <FormattedMessage id="subheader.label.kind" defaultMessage="Kind" />
          </div>
          <div className="media-screens">
            <FormattedMessage
              id="subheader.label.casting_screens"
              defaultMessage="Casting Screens"
            />
          </div>
        </div>
      </div>
      <div className="list">{renderCastItemList(castItems)}</div>
    </Styled>
  );
};

export default withData(React.memo(CastingList)) as React.ComponentType<Props>;
