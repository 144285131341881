import React, { useEffect, useCallback } from "react";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { BillingSubscription } from "src/domains/billing/types";
import withCachedCurrentUser, {
  WithCachedCurrentUser,
} from "src/hocs/caches/withCachedCurrentUser";
import withCachedBillingPlan, {
  WithBillingPlan,
} from "src/hocs/withCachedBillingPlan";
import withCachedBillingSubscription from "src/hocs/withCachedBillingSubscription";
import { useAppContext } from "src/hooks/useAppContext";
import { GlobalStyle } from "./styles";
import { shouldDisplayLiveChat } from "./utils";

const LIVE_CHAT_WIDGET_ID = "service-force-widget";

declare global {
  interface Window {
    embeddedservice_bootstrap: any;
  }
}

const LiveChat: React.FunctionComponent<
  WithBillingPlan & {
    billingSubscription?: Pick<BillingSubscription, "status">;
  } & WithCachedCurrentUser
> = (props) => {
  const context = useAppContext();
  const shouldDisplay = shouldDisplayLiveChat(
    props.billingPlan?.isCorePlan,
    props.billingSubscription?.status || null,
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.RESELLER)
  );

  const initEmbeddedMessaging = useCallback(() => {
    if (!window.embeddedservice_bootstrap) {
      const script = document.createElement("script") as HTMLScriptElement;
      script.src =
        "https://screencloud.my.site.com/ESWSupportScreenCloud1707996247908/assets/js/bootstrap.min.js";
      script.id = LIVE_CHAT_WIDGET_ID;
      script.onload = () => {
        try {
          window.embeddedservice_bootstrap.settings.language =
            document.documentElement.lang;
          window.embeddedservice_bootstrap.settings.displayHelpButton = true;
          window.embeddedservice_bootstrap.settings.defaultMinimizedText =
            "Chat with an Expert";
          window.embeddedservice_bootstrap.settings.offlineSupportMinimizedText =
            "Contact Us";
          window.embeddedservice_bootstrap.settings.enabledFeatures = [
            "LiveAgent",
          ];
          window.embeddedservice_bootstrap.settings.entryFeature = "LiveAgent";
          window.embeddedservice_bootstrap.settings.prepopulatedPrechatFields = {
            FirstName: props.currentUser?.givenName?.trim() || "",
            LastName: props.currentUser?.familyName?.trim() || "",
            Email: props.currentUser?.email?.trim() || "",
          };
          window.embeddedservice_bootstrap.init(
            "00D4K0000038B8c",
            "Support_ScreenCloud",
            "https://screencloud.my.site.com/ESWSupportScreenCloud1707996247908",
            {
              scrt2URL: "https://screencloud.my.salesforce-scrt.com",
            }
          );
        } catch (err) {
          console.error("Error loading Embedded Messaging: ", err);
        }
      };
      document.body.appendChild(script);
    }
  }, [props.currentUser]);

  useEffect(() => {
    if (shouldDisplay) {
      initEmbeddedMessaging();
    }
  }, [shouldDisplay, props.currentUser]);

  return <GlobalStyle />;
};

export { LiveChat };
export default withCachedBillingSubscription(
  withCachedBillingPlan(withCachedCurrentUser(LiveChat))
);
