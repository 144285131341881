import {
  UpdateFileIsFavoriteMutationVariables,
  useUpdateFileIsFavoriteMutation,
} from "../../../types.g";

export interface UpdateFileIsFavourite {
  updateFileIsFavorite: (mediaId: string, isFavorite?: boolean) => Promise<any>;
}

export function useUpdateFileIsFavourite() {
  const [updateFileIsFavoriteMutation] = useUpdateFileIsFavoriteMutation();
  const updateFileIsFavorite = function (
    mediaId: string,
    isFavorite?: boolean
  ) {
    const updateFileIsFavoriteVariables: UpdateFileIsFavoriteMutationVariables = {
      input: {
        fileIds: [mediaId],
        isFavorite: isFavorite ?? false,
      },
    };
    return updateFileIsFavoriteMutation({
      variables: updateFileIsFavoriteVariables,
    });
  };
  return { updateFileIsFavorite };
}
