import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  InfoBanner,
  Input,
  Loader,
  Popover,
  StatusInput,
  TagInput,
  Theme,
  ValidationStatus,
} from "@screencloud/screencloud-ui-components";

import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Accordion, DropdownItemProps } from "semantic-ui-react";
import { AppContext } from "../../AppContextProvider/AppContext";

import { features } from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { openNewTab } from "../../helpers/adsHelper";
import {
  generateQR,
  getQrcodePositionStyleProps,
  QRcodePosition,
} from "../../helpers/qrcodeHelper";
import { PrimaryButton } from "../../helpers/whiteLabel";
import secureDashboards from "../../images/secure_dashboards.svg";
import { LinkByIdQueryHookResult, Scalars } from "../../types.g";
import LinkAdvanceSettings, {
  LinkAdVanceSettingsPayload,
} from "../LinkAdvanceSettings";
import { CreateLinkStyled } from "./styles";
import { AppBanner } from "../AppBanner";
import { AppContextType } from "src/AppContextProvider/type";

export interface CreateLinkPayload {
  link?: string;
  name?: string;
  thumbUrl?: string;
  fileId?: Scalars["UUID"];
  isInternal?: boolean;
  isCloudRendering?: boolean;
  tags?: string[];
  credential?: string;
  cacheBusting?: boolean;
  autoReload?: boolean;
  autoReloadDurationMs?: number;
  params?: Scalars["JSON"];
  isEdit?: boolean;
  qrcodeEnabled?: boolean;
  qrcodePosition?: QRcodePosition;
}

export enum CreateLinkActions {
  RESET = "RESET",
  LINK_VALIDATION = "LINK_VALIDATION",
  SUBMIT = "SUBMIT",
  RENDER = "RENDER",
  APP = "APP",
  ADD_CREDENTIAL = "ADD_CREDENTIAL",
  REMOVE_CREDENTIAL = "REMOVE_CREDENTIAL",
  RETRY_THUMBNAIL = "RETRY_THUMBNAIL",
  UPDATE = "UPDATE",
}
export interface CreateLinkProps {
  isValidatingLink: boolean;
  isValidateLinkComplete: boolean;
  isValidateLinkSuccess: boolean;
  isShowCloudOption: boolean;
  isShowInternalOption: boolean;
  isSupportIframe: boolean;
  forceInternal: boolean;
  forceCloudRendering: boolean;
  validateLinkMessage: string;
  validatePageTitle: string;
  thumbUrl: string;
  thumbnailError: string;
  appsSuggestion: any[];
  hasCredential: boolean;
  callback?: (action: CreateLinkActions, data: any) => void;
  linkById?: NonNullable<LinkByIdQueryHookResult["data"]>["linkById"];
  isShowBadPageResponse: boolean;
}

export interface CreateLinkState {
  previousLinkInput: string;
  linkInput: string;
  nameInput: string;
  isInternal: boolean;
  isCloudRendering: boolean;
  isLoading: boolean;
  isShowProtocolOption: boolean;
  tags: string[];
  tagOptions: DropdownItemProps[];
  width: number;
  cacheBusting: boolean;
  autoReload: boolean;
  autoReloadDurationMs: number;
  params: Scalars["JSON"];
  activeIndex: number;
  qrcodeEnabled: boolean;
  qrcode: string;
  qrcodePosition: QRcodePosition;
}

const qrcodePositionOptions = [
  {
    key: QRcodePosition.BOTTOM_RIGHT,
    text: "Bottom right",
    value: QRcodePosition.BOTTOM_RIGHT,
  },
  {
    key: QRcodePosition.BOTTOM_LEFT,
    text: "Bottom left",
    value: QRcodePosition.BOTTOM_LEFT,
  },
  {
    key: QRcodePosition.TOP_RIGHT,
    text: "Top right",
    value: QRcodePosition.TOP_RIGHT,
  },
  {
    key: QRcodePosition.TOP_LEFT,
    text: "Top left",
    value: QRcodePosition.TOP_LEFT,
  },
];

class CreateLink extends Component<CreateLinkProps, CreateLinkState> {
  public static contextType = AppContext;
  public static defaultProps: Partial<CreateLinkProps> = {};
  public context: AppContextType;

  constructor(props: CreateLinkProps) {
    super(props);
    this.state = {
      isCloudRendering: this.props.forceCloudRendering,
      isInternal: this.props.forceInternal,
      isLoading: false,
      isShowProtocolOption: false,
      linkInput: this.props.linkById?.url ?? "",
      nameInput: this.props.linkById?.name ?? "",
      previousLinkInput: "",
      tagOptions:
        ((this.props.linkById?.tags ?? []).map((tag) => ({
          text: tag,
          value: tag,
        })) as DropdownItemProps[]) ?? [],
      tags: (this.props.linkById?.tags as string[]) ?? [],
      width: 0,
      cacheBusting: this.props.linkById?.cacheBusting ?? false,
      autoReload: this.props.linkById?.autoReload ?? false,
      autoReloadDurationMs:
        this.props.linkById?.autoReloadDurationMs ?? 3600000,
      params: this.props.linkById?.params ?? {},
      activeIndex: -1,
      qrcodeEnabled: this.props.linkById?.qrcodeEnabled ?? false,
      qrcode: "",
      qrcodePosition:
        this.props.linkById?.qrcodeConfig.prop?.position ??
        QRcodePosition.BOTTOM_RIGHT,
    };
    window.addEventListener("resize", this.updateDimension);
  }

  public async componentDidMount() {
    this.updateDimension();

    if (this.props.linkById?.url) {
      this.triggerCallback(CreateLinkActions.LINK_VALIDATION, {
        isCloudRendering: false,
        link: this.props.linkById.url,
      });
      this.setState({
        previousLinkInput: this.props.linkById.url,
      });
    }
    if (this.props.linkById?.qrcodeEnabled) {
      const qrcode = await generateQR(this.props.linkById.url);
      this.setState({ qrcode });
    }
  }

  public updateDimension = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  public componentDidUpdate(prevProps) {
    if (
      !this.props.linkById &&
      prevProps.validatePageTitle !== this.props.validatePageTitle
    ) {
      this.setState({
        nameInput: this.props.validatePageTitle.substring(0, 120),
      });
    }
    if (prevProps.forceInternal !== this.props.forceInternal) {
      this.setState({
        isInternal: this.props.forceInternal,
      });
    }
    if (prevProps.forceCloudRendering !== this.props.forceCloudRendering) {
      this.setState({
        isCloudRendering: this.props.forceCloudRendering,
      });
    }
  }

  // call from parent component
  public triggerUpdateUrl = (url: string) => {
    if (url && url !== this.state.linkInput) {
      this.setState({
        linkInput: url,
        previousLinkInput: url,
      });
    }
  };

  // call from parent component
  public triggerShowProtocolOption = () => {
    this.setState({
      isShowProtocolOption: true,
    });
  };

  public handleLinkInputChange = (e) => {
    const previousValue = this.state.linkInput;
    this.setState({
      linkInput: e.target.value,
      previousLinkInput: previousValue,
    });
  };

  public handleNameInputChange = (e) => {
    const name = e.target.value;
    this.setState({
      nameInput: name.length > 120 ? name.substring(0, 120) : name,
    });
  };

  public handleInputBlur = () => {
    let link = this.state.linkInput;
    if (link) {
      if (!link.includes("://")) {
        link = "https://" + link;
        this.setState({
          linkInput: link,
        });
      }
      if (this.state.linkInput !== this.state.previousLinkInput) {
        this.triggerCallback(CreateLinkActions.LINK_VALIDATION, {
          isCloudRendering: false,
          link,
        });
        if (!this.props.linkById) {
          this.resetState();
        }
        this.setState({
          previousLinkInput: link,
        });
      }
    }
  };

  public resetState = () => {
    this.setState({
      isCloudRendering: false,
      isShowProtocolOption: false,
      nameInput: "",
      tagOptions: [],
      tags: [],
    });
  };

  public handleInternalLinkChange = (e, data) => {
    this.setState({
      isInternal: data.checked,
    });
  };

  public handleCloudRenderingChange = (e, data) => {
    this.setState({
      isCloudRendering: data.checked,
    });

    const param = {
      isCloudRendering: data.checked,
      link: this.state.linkInput,
    };
    this.triggerCallback(CreateLinkActions.RENDER, param);
  };

  public handleTagChange = (data) => {
    const tagLength = data.length;
    const newTag = data[tagLength - 1];
    const newTagOptions = this.state.tagOptions;
    newTagOptions.push({ text: newTag, value: newTag });
    this.setState({
      tagOptions: newTagOptions,
      tags: data,
    });
  };

  public handleAddCredential = () => {
    this.triggerCallback(CreateLinkActions.ADD_CREDENTIAL, {
      isCloudRendering: this.state.isCloudRendering,
      link: this.state.linkInput,
    });
  };

  public handleRemoveCredential = () => {
    this.triggerCallback(CreateLinkActions.REMOVE_CREDENTIAL, {
      isCloudRendering: this.state.isCloudRendering,
      link: this.state.linkInput,
    });
  };

  public handleRetryThumbnail = () => {
    this.triggerCallback(CreateLinkActions.RETRY_THUMBNAIL, null);
  };

  public handleSubmitLink = () => {
    const createLinkInput: CreateLinkPayload = {
      isCloudRendering: this.state.isCloudRendering,
      isInternal: this.state.isInternal,
      link: this.state.linkInput,
      name: this.state.nameInput,
      tags: this.state.tags,
      cacheBusting: this.state.cacheBusting,
      autoReload: this.state.autoReload,
      autoReloadDurationMs: this.state.autoReloadDurationMs,
      qrcodeEnabled: this.state.qrcodeEnabled,
      qrcodePosition: this.state.qrcodePosition,
      params: this.state.params,
    };
    this.setState({ isLoading: true });
    if (this.props.linkById) {
      this.triggerCallback(CreateLinkActions.UPDATE, {
        ...this.props.linkById,
        ...createLinkInput,
        isEdit: true,
      });
    } else {
      this.triggerCallback(CreateLinkActions.SUBMIT, createLinkInput);
    }
  };

  public triggerCallback = (action: CreateLinkActions, param) => {
    if (this.props.callback) {
      this.props.callback(action, param);
    }
  };

  public linkAdvanceSettingsCallback = (
    payload: LinkAdVanceSettingsPayload
  ) => {
    this.setState({
      params: payload.params ?? {},
      cacheBusting: payload.cacheBusting ?? false,
      autoReload: payload.autoReload ?? false,
      autoReloadDurationMs: payload.autoReloadDurationMs ?? 3600000,
    });
  };

  public handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  public checkIsInputEmpty = () => {
    return !this.state.nameInput || !this.state.linkInput;
  };

  public isValidationFlowComplete = () => {
    const {
      isValidatingLink,
      isValidateLinkComplete,
      isValidateLinkSuccess,
    } = this.props;
    const { isInternal, isCloudRendering } = this.state;

    return (
      !isValidatingLink &&
      isValidateLinkComplete &&
      (isValidateLinkSuccess ||
        (!isValidateLinkSuccess && isInternal) ||
        (!isValidateLinkSuccess && isCloudRendering))
    );
  };

  public handleSwitchProtocol = () => {
    const httpsLink = this.state.linkInput.replace("http://", "https://");
    this.setState(
      {
        isShowProtocolOption: false,
        linkInput: httpsLink,
      },
      this.handleInputBlur
    );
  };

  public shouldDisplayInputForm = () => {
    return this.isValidationFlowComplete();
  };

  public shouldDisableSubmit = () => {
    return !this.isValidationFlowComplete() || this.checkIsInputEmpty();
  };

  public renderAds = (shouldRenderAdsResponsive?: boolean) => {
    return (
      <div
        className={`ads ${shouldRenderAdsResponsive ? "ads-responsive" : ""}`}
      >
        <div className="title">
          {this.context.intl.formatMessage({
            defaultMessage: "Secure Sites",
            id: "ads.link.preview.title",
          })}
        </div>
        <div className="description">
          {this.context.intl.formatMessage({
            defaultMessage:
              "Upgrade to get access to Dashboards, which allows you to display password-protected sites securely.",
            id: "ads.link.preview.description",
          })}
        </div>
        <div className="button">
          <Button
            outline
            onClick={() => {
              openNewTab({
                section: features.DASHBOARDS,
                user: this.context.currentUser,
              });
            }}
          >
            {this.context.intl.formatMessage({
              defaultMessage: "Contact Sales",
              id: "ads.contact_sales.button",
            })}
          </Button>
        </div>
        <img src={secureDashboards} height={256} />
      </div>
    );
  };

  public handleQrcodeEnableChange = async (e, data) => {
    this.setState({ qrcodeEnabled: data.checked });
    const qrcode = await generateQR(this.state.linkInput);
    this.setState({ qrcode });
  };

  public onPositionChange = (e, d) => {
    this.setState({ qrcodePosition: d.value });
  };

  public render() {
    const shouldShowPremiumAds = this.context.shouldShowFeature(
      FEATURE_FLAGS_ENUM.PREMIUM_ADS
    );
    const isEnterpriseCustomer = this.context.shouldShowFeature(
      FEATURE_FLAGS_ENUM.IS_ENTERPRISE_CUSTOMER
    );
    const {
      isValidateLinkComplete,
      isValidatingLink,
      isValidateLinkSuccess,
      isShowCloudOption,
      isShowInternalOption,
      forceCloudRendering,
      forceInternal,
      validateLinkMessage,
      appsSuggestion,
      thumbUrl,
      thumbnailError,
      hasCredential,
      isSupportIframe,
      isShowBadPageResponse,
      linkById,
    } = this.props;
    const {
      isCloudRendering,
      width,
      linkInput,
      activeIndex,
      qrcodeEnabled,
      qrcode,
      qrcodePosition,
    } = this.state;
    const shouldRenderAdsResponsive =
      !isSupportIframe &&
      !isShowCloudOption &&
      width <= +Theme.break.small.split("px")[0];
    const shouldRenderAdsNormalSize =
      !isSupportIframe &&
      !isShowCloudOption &&
      width > +Theme.break.small.split("px")[0];
    const qrcodeStyleProps = qrcodeEnabled
      ? getQrcodePositionStyleProps(qrcodePosition)
      : {};

    return (
      <CreateLinkStyled {...qrcodeStyleProps}>
        <div
          className={`link-config ${
            shouldRenderAdsResponsive &&
            shouldShowPremiumAds &&
            !isEnterpriseCustomer
              ? "ads-responsive"
              : ""
          }`}
        >
          <div>
            <div className="link-input">
              <h3>
                <FormattedMessage
                  id="ui_component.link.input_url_message"
                  defaultMessage="Enter your URL here:"
                />
              </h3>
              <StatusInput
                placeholder="https://"
                onChange={this.handleLinkInputChange}
                onBlur={this.handleInputBlur}
                value={this.state.linkInput}
                isValidating={isValidatingLink}
                isValidateComplete={isValidateLinkComplete}
                validationStatus={
                  !isSupportIframe && !isShowCloudOption
                    ? ValidationStatus.Error
                    : !isValidateLinkSuccess
                    ? ValidationStatus.Warning
                    : ValidationStatus.Success
                }
                spellCheck={false}
                type="url"
              />
              {isValidateLinkComplete && (
                <AppBanner
                  linkInput={this.state.linkInput}
                  formatMessage={this.context.intl.formatMessage}
                  onLinkClicked={() => this.context.modal.closeModals()}
                />
              )}
              {validateLinkMessage && isShowCloudOption && (
                <div className="link-error">
                  <span>{validateLinkMessage}</span>
                </div>
              )}
              {isValidateLinkComplete && isShowCloudOption && (
                <Checkbox
                  checked={isCloudRendering}
                  disabled={forceCloudRendering}
                  label={
                    <>
                      <FormattedMessage
                        id="ui_component.link.use_cloud_rendering"
                        tagName="label"
                        defaultMessage="Use Cloud Rendering"
                      />
                      <Popover
                        inverted
                        trigger={<Icon name="info" />}
                        content={
                          <FormattedMessage
                            id="ui_component.link.cloud_rendering_description"
                            tagName="label"
                            defaultMessage="Have the website render in the cloud and sent to your screen"
                          />
                        }
                        position="top center"
                      />
                    </>
                  }
                  onChange={this.handleCloudRenderingChange}
                />
              )}
              {isValidateLinkComplete && this.state.isShowProtocolOption && (
                <p
                  className="link-switchprotocol"
                  onClick={this.handleSwitchProtocol}
                >
                  {
                    <span>
                      <b>Switch</b> to HTTPS protocol
                    </span>
                  }
                </p>
              )}
              {isValidateLinkComplete && isShowInternalOption && (
                <>
                  <InfoBanner
                    title={this.context.intl.formatMessage({
                      defaultMessage: "Is this an internal link?",
                      id: "ui_component.link.internal_link_header",
                    })}
                    description={this.context.intl.formatMessage({
                      defaultMessage:
                        "We couldn’t access this URL. If you’d like to add it anyway click ‘Internal Link’ to confirm.",
                      id: "ui_component.link.internal_instruction_message",
                    })}
                    color="warning"
                    icon="warning"
                    className="info-banner"
                  />
                  <Checkbox
                    checked={this.state.isInternal}
                    disabled={forceInternal}
                    label={
                      <FormattedMessage
                        id="ui_component.link.use_internal"
                        tagName="label"
                        defaultMessage="Use Internal Link"
                      />
                    }
                    onChange={this.handleInternalLinkChange}
                  />
                </>
              )}
              {isValidateLinkComplete && isShowBadPageResponse && (
                <InfoBanner
                  description={this.context.intl.formatMessage({
                    defaultMessage:
                      "We are unable to generate a preview for this link. However you can still add this link and check to see if it displays correctly on your screen.",
                    id: "ui_component.link.bad_page_response_message",
                  })}
                  color="warning"
                  icon="warning"
                  className="info-banner"
                />
              )}
              {!isSupportIframe && !isShowCloudOption && (
                <InfoBanner
                  title={this.context.intl.formatMessage({
                    defaultMessage: "This website doesn’t support iframes",
                    id: "ui_component.link.iframe_not_supported_header",
                  })}
                  description={this.context.intl.formatMessage({
                    defaultMessage:
                      "We can only display this website using Dashboards. Please contact support to learn more about this feature.",
                    id: "ui_component.link.iframe_not_supported",
                  })}
                  color="error"
                  icon="warning"
                  className="info-banner"
                />
              )}
            </div>
            {this.context.shouldShowFeature(
              FEATURE_FLAGS_ENUM.LINK_CREDENTIAL
            ) &&
              isValidateLinkComplete &&
              isShowCloudOption &&
              isCloudRendering &&
              (hasCredential ? (
                <div className="credential-section">
                  <span style={{ marginRight: "8px" }}>
                    <FormattedMessage
                      id="ui_component.link.account_added"
                      defaultMessage="Account Added"
                    />
                  </span>
                  <Button icon onClick={this.handleRemoveCredential}>
                    <span>
                      <FormattedMessage
                        id="ui_component.link.remove"
                        defaultMessage="remove"
                      />
                    </span>
                  </Button>
                </div>
              ) : (
                <div className="credential-section">
                  <h3>
                    <FormattedMessage
                      id="ui_component.link.credential"
                      defaultMessage="Credential"
                    />
                    <span>
                      {"("}
                      <FormattedMessage
                        id="common.optional"
                        defaultMessage="optional"
                      />
                      {")"}
                    </span>
                    <Popover
                      inverted
                      trigger={<Icon name="info" />}
                      content={
                        <FormattedMessage
                          id="ui_component.link.credential_description"
                          tagName="label"
                          defaultMessage="If the website requires you to login, input your login details here"
                        />
                      }
                      position="top center"
                    />
                  </h3>
                  <Button transparent onClick={this.handleAddCredential}>
                    <Icon name="plus-fill" />
                    <span>
                      <FormattedMessage
                        id="ui_component.link.add_credential"
                        defaultMessage="Add Credential"
                      />
                    </span>
                  </Button>
                </div>
              ))}

            {isValidateLinkComplete && appsSuggestion.length > 0 && (
              <div className="link-app">
                <p>App suggestions</p>
                <div className="app">
                  <i className="app-image" />
                  <div className="app-detail">
                    <div className="text">
                      <h3 className="title">Digital Menu Board</h3>
                      <p className="paragraph">
                        Create your own beatiful Digital Menu boards.
                      </p>
                    </div>
                    <Button>Configure</Button>
                  </div>
                </div>
              </div>
            )}

            <div className="link-detail">
              <div className="input">
                {this.shouldDisplayInputForm() && (
                  <>
                    <div className="link-title">
                      <div>
                        <h3 className="input-name">
                          <FormattedMessage
                            id="ui_component.common.label.name"
                            defaultMessage="Name"
                          />
                        </h3>
                        <span className="max-char">(max 120 characters)</span>
                      </div>
                      <Input
                        onChange={this.handleNameInputChange}
                        value={this.state.nameInput}
                      />
                    </div>

                    <div className="link-tag">
                      <h3>
                        <FormattedMessage
                          id="ui_component.common.label.tags"
                          defaultMessage="Tags"
                        />
                      </h3>
                      <TagInput
                        tagTitle="Tags"
                        onTagsChanged={this.handleTagChange}
                        currentTags={this.state.tags}
                        tagsList={this.state.tagOptions}
                      />
                    </div>
                    <div className="link-qrcode">
                      <FormattedMessage
                        id="ui_component.link.add_qrcode"
                        defaultMessage="Display QR code"
                      />
                      <Checkbox
                        data-testid="qrcode-enable-toggle"
                        toggle
                        checked={qrcodeEnabled}
                        onChange={this.handleQrcodeEnableChange}
                      />
                    </div>

                    {qrcodeEnabled && (
                      <div className="qrcode-position">
                        <FormattedMessage
                          id="ui_component.link.qrcode_position"
                          defaultMessage="QR code position"
                        />
                        <Dropdown
                          options={qrcodePositionOptions}
                          onChange={this.onPositionChange}
                          value={qrcodePosition}
                          data-testid="qrcode-position-dropdown"
                          className="selection"
                          fluid
                          upward={false}
                        />
                      </div>
                    )}
                    <Accordion>
                      <Accordion.Title
                        data-testid="link-advanced-settings-accordion"
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                      >
                        Advanced Settings
                        <Icon name="caret-down-mini" />
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === 0}>
                        <LinkAdvanceSettings
                          url={linkInput}
                          cacheBusting={linkById?.cacheBusting}
                          autoReload={linkById?.autoReload}
                          autoReloadDurationMs={linkById?.autoReloadDurationMs}
                          params={linkById?.params}
                          callBack={this.linkAdvanceSettingsCallback}
                        />
                      </Accordion.Content>
                    </Accordion>
                  </>
                )}
              </div>
            </div>
          </div>
          {shouldShowPremiumAds &&
            !this.context.shouldShowFeature(
              FEATURE_FLAGS_ENUM.SECURE_DASHBOARDS
            ) &&
            shouldRenderAdsResponsive &&
            this.renderAds(shouldRenderAdsResponsive)}
        </div>
        <div
          className={`link-preview ${
            shouldRenderAdsResponsive &&
            shouldShowPremiumAds &&
            !isEnterpriseCustomer
              ? "ads-responsive"
              : ""
          }`}
        >
          <div className="image-wrapper">
            {isValidateLinkComplete && thumbUrl ? (
              <div className="link">
                {qrcodeEnabled && (
                  <div className="qrcode">
                    <img src={qrcode} />
                  </div>
                )}
                <img className="screen" src={thumbUrl} />
              </div>
            ) : shouldRenderAdsNormalSize &&
              shouldShowPremiumAds &&
              !isEnterpriseCustomer ? (
              <>{this.renderAds()}</>
            ) : (
              <>
                {isValidateLinkSuccess &&
                  (!validateLinkMessage || forceCloudRendering) &&
                  !thumbnailError && <Loader size="small" inline active />}
                {thumbnailError && (
                  <div className="unavailable-error">
                    <span>{thumbnailError}</span>
                    <Button onClick={this.handleRetryThumbnail}>
                      <Icon name="refresh" />
                      <FormattedMessage
                        id="ui_component.link.retry"
                        defaultMessage="Retry"
                      />
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="link-action">
          <PrimaryButton
            onClick={this.handleSubmitLink}
            disabled={this.shouldDisableSubmit()}
          >
            {!this.state.isLoading ? (
              this.props.linkById ? (
                <FormattedMessage
                  id="ui_component.link.update_link"
                  defaultMessage="Update Link"
                />
              ) : (
                <FormattedMessage
                  id="ui_component.link.add_link"
                  defaultMessage="Add Link"
                />
              )
            ) : (
              <Loader inline size="small" active>
                <FormattedMessage
                  id="common.text.loading"
                  defaultMessage="Loading"
                />
              </Loader>
            )}
          </PrimaryButton>
        </div>
      </CreateLinkStyled>
    );
  }
}

export default CreateLink;
