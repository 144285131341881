import {
  Button,
  CloseButtonPosition,
  Confirm,
  Input,
  Modal,
  ModalSize,
  NavigationControllerModal,
  NotificationExpandable,
  Theme,
} from "@screencloud/screencloud-ui-components";
import { ChangeEvent, Component, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router-dom";
import { RefType } from "../../constants/constants";
import {
  AppInstance,
  AppVersion,
  File,
  Link,
  LinkByIdQueryHookResult,
  Maybe,
  Playlist,
  Scalars,
  Screen,
  Site,
  SiteFragment,
} from "../../types.g";

import { InputOnChangeData } from "semantic-ui-react";
import AppContainer from "../../components/App/AppContainer";
import CredentialPicker from "../../components/CredentialPicker";
import LinkPicker from "../../components/LinkPicker";
import { CreateLinkPayload } from "../../components/LinkPicker/create";
import LinkPreviewComponent from "../../components/LinkPreview";
import {
  LinkPreviewActions,
  LinkPreviewPayload,
} from "../../components/LinkPreview/preview";
import MediaPickerModal from "../../components/MediaPicker";
import { MediaPickerOptions } from "../../components/MediaPicker/apollo";
import { MediaPickerActionMode } from "../../components/MediaPicker/media";
import MediaPreviewComponent from "../../components/MediaPreview";
import {
  MediaPreviewActions,
  MediaPreviewPayload,
} from "../../components/MediaPreview/preview";
import { MediaUploadOptions } from "../../components/MediaUpload";
import MediaUploadDnD from "../../components/MediaUpload/mediaUploadDnD";
import ScreenItemSearchResult from "../../components/ScreenItemSearchResult";
import ScreenPicker, {
  ScreenPickerActions,
} from "../../components/ScreenPicker";
import BasicSiteModal from "../../components/SitePicker/BasicSite";
import NewSiteModal from "../../components/SitePicker/CreateSite";
import SecureSiteEditorModal from "../../components/SiteRecorderModal";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { getScreenStatus } from "../../helpers/screenHelper";
import {
  getReversedWhiteLabelStyles,
  PrimaryButton,
} from "../../helpers/whiteLabel";
import { AppContext } from "../AppContext";
import { NavigationStyled, SmallModalStyled } from "../styles";
import { AppContextType } from "../type";
import {
  ConfirmOptions,
  Credential, // TODO: This should be defined elsewhere
  IModalManager,
  IModalState,
  MediaParams,
  Message,
  ModalHeader,
  ModalOptions,
  ModalUploadOptions,
  onBeforeCloseFunc,
  onCloseFunc,
  onOpenFunc,
  PromptOptions,
} from "./type";
import { UUID } from "@screencloud/uuid";

/**
 * Selected media from OpenMediaPicker
 */
export type SelectedMedias =
  | File[]
  | Playlist[]
  | Link[]
  | AppInstance[]
  | Site[];
export interface OpenMediaPickerResult {
  mediaId: string[];
  mediaType: RefType;
  data: SelectedMedias;
  screenPickerAction: ScreenPickerActions;
  expiresAt?: Date;
}

export interface ModalsViewProps extends RouteComponentProps<any> {
  onModalReady: (modal: IModalManager) => void;
}

class ModalsView
  extends Component<ModalsViewProps, IModalState>
  implements IModalManager {
  public static contextType = AppContext;
  public context: AppContextType;

  private PromiseResolve: (value: any) => void;
  private resolveMediaUploaderPromise: (value: any) => void;
  private PromiseReject: () => void;
  private rejectMediaUploaderPromise: () => void;
  private promptView: ReactNode;
  private onOpen: undefined | (() => void);
  private onClose: undefined | (() => void);
  private onBeforeClose: onBeforeCloseFunc;
  private onOpenUpload: undefined | (() => void);
  private onCloseUpload: undefined | (() => void);
  private onOpenFullScreen: undefined | (() => void);
  private onCloseFullScreen: undefined | (() => void);
  private onOpenNavigation: undefined | (() => void);
  private onCloseNavigation: undefined | (() => void);

  constructor(props: ModalsViewProps, state: IModalState) {
    super(props);

    this.state = {
      confirmView: undefined,
      currentModalView: undefined,
      currentUploadView: undefined,
      fullscreenModalView: undefined,
      fullscreenOptions: {},
      isConfirmShowing: false,
      isFullscreenModalShowing: false,
      isMediaPickerShowing: false,
      isMediaUploadShowing: false,
      isModalShowing: false,
      isNavigationControlShowing: false,
      isNotificationShowing: false,
      isPromptShowing: false,
      mediaUploadView: undefined,
      modalOptions: {
        size: ModalSize.NORMAL,
        disableTitle: false,
        overflow: false,
        closeOnDimmerClick: true,
        disableCloseButton: false,
        closeButtonId: "close-button",
      },
      modalUploadOptions: {
        size: ModalSize.NORMAL,
        disableTitle: false,
        overflow: false,
        closeOnDimmerClick: true,
        disableCloseButton: false,
        closeButtonId: "close-button",
      },
      navigationControlView: undefined,
      navigationControlTitle: "",
      navigationControlOptions: {
        size: ModalSize.NORMAL,
        disableTitle: false,
        overflow: false,
        closeOnDimmerClick: true,
        disableCloseButton: false,
        closeButtonId: "close-button",
      },
      notificationContent: { content: "", title: "", cancelUpload: () => {} },
      promptView: undefined,
      title: "",
      modalTitle: "",
    };

    props.onModalReady(this as IModalManager);
  }

  public get isModalShowing(): boolean {
    return this.state.isModalShowing;
  }

  public get isFullscreenModalShowing(): boolean {
    return this.state.isFullscreenModalShowing;
  }

  public get isMediaUploadShowing(): boolean {
    return this.state.isMediaUploadShowing;
  }

  public get isNavigationControlShowing(): boolean {
    return this.state.isNavigationControlShowing;
  }

  public get currentModalView(): ReactNode {
    return this.state.currentModalView;
  }

  public closeAllWindows(): void {
    this.setState({
      confirmView: false,
      currentModalView: undefined,
      isConfirmShowing: false,
      isFullscreenModalShowing: false,
      isModalShowing: false,
      isMediaUploadShowing: false,
      title: "",
    });
  }

  public confirm(
    message: Message,
    opts?: ConfirmOptions
  ): Promise<{ confirm: boolean; optionCheck?: boolean }> {
    let PromiseResolve: (value: any) => void;
    const onConfirmClick = (optionCheck?: boolean) => {
      this.closeConfirm();
      PromiseResolve({ confirm: true, optionCheck });
    };
    const onCancelClick = () => {
      this.closeConfirm();
      PromiseResolve({ confirm: false });
    };

    const reversedWhiteLabelStyles =
      this.context?.shouldShowFeature?.(FEATURE_FLAGS_ENUM.WHITE_LABEL) &&
      getReversedWhiteLabelStyles(this.context);

    const view = (
      <Confirm
        header={opts?.header ?? null}
        isAlert={opts?.isAlert ?? false}
        isDanger={opts?.isDanger ?? false}
        cancelButton={opts?.cancel ?? "Cancel"}
        confirmButton={opts?.confirm ?? "OK"}
        confirm={onConfirmClick}
        onCancel={onCancelClick}
        customButton={opts?.customButton ?? null}
        option={opts?.option ?? undefined}
        optionMessage={opts?.optionMessage ?? undefined}
        {...reversedWhiteLabelStyles}
      >
        {message}
      </Confirm>
    );

    this.setState({ isConfirmShowing: true, confirmView: view });
    return new Promise((resolve, reject) => {
      PromiseResolve = resolve;
    });
  }

  public confirmSaveData(): Promise<{
    confirm: boolean;
    optionCheck?: boolean;
  }> {
    return this.confirm(
      <>
        <h2>
          <FormattedMessage
            id="common.text.save_changes_heading"
            defaultMessage="Save changes?"
          />
        </h2>
        <p>
          <FormattedMessage
            id="apps.configure.changes_warning_text"
            defaultMessage="Your changes will be lost if you don't save them."
          />
        </p>
      </>,
      {
        cancel: (
          <FormattedMessage id="common.text.discard" defaultMessage="Discard" />
        ),
        confirm: (
          <FormattedMessage
            id="common.text.save_changes"
            defaultMessage="Save Changes"
          />
        ),
      }
    );
  }

  public closeConfirm(): void {
    this.setState({ isConfirmShowing: false, confirmView: undefined });
  }

  public prompt(
    title: ModalHeader,
    promptMessage: { label: string; placeholder: string },
    options: { opts: ModalOptions; promptOpts?: PromptOptions } = {
      opts: { className: "", disableTitle: false, size: ModalSize.NORMAL },
    }
  ): Promise<{ status: boolean; value: string }> {
    // todo
    const messageValue: { status: boolean; value: string } = {
      status: false,
      value: "",
    };
    let PromiseResolve: (value: any) => void;
    const onMessageChange = (
      e: ChangeEvent<HTMLInputElement>,
      v: InputOnChangeData
    ) => {
      messageValue.value = v.value;
    };
    const handleCancel = () => {
      messageValue.value = "";
      this.closePrompt();
      PromiseResolve(messageValue);
    };
    const handleConfirm = () => {
      this.closePrompt();
      messageValue.status = true;
      PromiseResolve(messageValue);
    };
    const handleKeyDown = (event: React.KeyboardEvent<any>) => {
      const key = event.key;
      if (key === "Enter") {
        this.closePrompt();
        messageValue.status = true;
        PromiseResolve(messageValue);
      }
    };

    this.promptView = (
      <SmallModalStyled>
        <label className="row">
          <h5 className="label">{promptMessage.label}</h5>
          <Input
            fluid
            autoFocus
            onChange={onMessageChange}
            onKeyPress={handleKeyDown}
            placeholder={promptMessage.placeholder}
          />
        </label>

        <div className="actions">
          <Button onClick={handleCancel}>
            {options && options.promptOpts && options.promptOpts.cancel
              ? options.promptOpts.cancel
              : "Cancel"}
          </Button>{" "}
          <PrimaryButton onClick={handleConfirm}>
            {options && options.promptOpts && options.promptOpts.confirm
              ? options.promptOpts.confirm
              : "Create"}
          </PrimaryButton>
        </div>
      </SmallModalStyled>
    );
    this.setState({
      isPromptShowing: true,
      title,
      promptView: this.promptView,
      modalOptions: options.opts,
    });
    return new Promise((resolve, reject) => {
      PromiseResolve = resolve;
    });
  }

  public closePrompt(): void {
    this.promptView = undefined;
    this.setState({ isPromptShowing: false, promptView: undefined });
  }

  public async openScreenPlayingList(
    screens: any,
    title?: ModalHeader,
    options: {
      opts: ModalOptions;
      onOpen?: onOpenFunc;
      onClose?: onCloseFunc;
    } = { opts: { disableTitle: false, size: ModalSize.NORMAL } }
  ) {
    const currentPlayingScreens = screens
      .map((screen: Screen, index: number) => {
        if (
          screen.castByCastId &&
          screen.castByCastId.content &&
          screen.castByCastId.appInstanceByCastId &&
          screen.castByCastId.fileByCastId &&
          screen.castByCastId.linkByCastId &&
          screen.castByCastId.playlistByCastId
        ) {
          return (
            <ScreenItemSearchResult
              className="small"
              url={`/screens/` + screen.id}
              key={`screen-picker-item-key-` + index}
              checked={false}
              id={``}
              name={screen.name}
              model={screen.deviceModel ?? ""}
              platform={screen.devicePlatform ?? ""}
              group={``}
              status={getScreenStatus(screen)}
              tags={screen.tags as string[]}
              castItem={screen.castByCastId}
              isCasting={screen.castId ? true : false}
              isReadOnly={true}
              callBack={() => false}
            />
          );
        } else {
          return null;
        }
      })
      .filter((screen) => screen !== null);

    const playingList = (
      <div
        style={{
          padding: "0 18px",
          backgroundColor: Theme.color.nearWhite,
          height: "100%",
        }}
      >
        <div className="screen-item-header">
          <div className="column-name">
            <FormattedMessage id="subheader.label.name" defaultMessage="Name" />
          </div>
          <div className="column-device" />
          <div className="column-group" />
          <div className="column-tags">
            <FormattedMessage
              id="subheader.label.status"
              defaultMessage="Status"
            />
          </div>
        </div>
        {currentPlayingScreens}
      </div>
    );

    this.openModal(playingList, title, options);
  }

  public async openScreenPicker(
    itemToCast:
      | Partial<Link>
      | Partial<File>
      | Partial<Playlist>
      | Partial<AppInstance>
      | SiteFragment
      | null,
    title?: ModalHeader
  ): Promise<{
    data: string[];
    action: ScreenPickerActions;
    expiresAt?: Date;
  }> {
    let PromiseResolve: (value: {
      data: string[];
      action: ScreenPickerActions;
      expiresAt?: Date;
    }) => void;
    const onScreenPickerCallback = (
      callbackData: string[],
      action: ScreenPickerActions,
      expiresAt?: Date
    ) => {
      if (callbackData) {
        switch (action) {
          case ScreenPickerActions.CASTING:
          case ScreenPickerActions.SET_CONTENT:
            PromiseResolve({ data: callbackData, action, expiresAt });
            break;
          case ScreenPickerActions.ADD_SCREEN:
            this.closeModals();
            this.props.history.push("/screens/add");
            break;
          case ScreenPickerActions.CANCEL:
            this.closeModals();
            break;
          default:
            break;
        }
      } else {
        this.PromiseReject();
      }
    };

    const view = (
      <ScreenPicker
        itemToCast={itemToCast}
        disabledAction={false}
        callback={onScreenPickerCallback}
        title={itemToCast?.name || title}
      />
    );

    this.openModal(
      view,
      this.context.intl.formatMessage({
        defaultMessage: "Select screens to play",
        id: "ui_component.common.label.select_screens_to_play",
      }) +
        " " +
        itemToCast?.name || title,
      {
        opts: {
          className: "md-setscreen",
          size: ModalSize.NORMAL,
          disableTitle: false,
          overflow: true,
        },
      }
    );

    return new Promise(
      (
        resolve: (value: {
          data: string[];
          action: ScreenPickerActions;
          expiresAt?: Date;
        }) => void,
        reject
      ) => {
        PromiseResolve = resolve;
        this.PromiseReject = reject;
      }
    );
  }

  public openMediaPicker(
    action: MediaPickerActionMode,
    title?: ModalHeader,
    opts?: MediaPickerOptions
  ): Promise<OpenMediaPickerResult> {
    let selectedMediaId: string[] = [];
    let selectedMediaType: RefType;
    let selectedData:
      | Partial<File>[]
      | Partial<Playlist>[]
      | Partial<Link>[]
      | Partial<AppInstance>[];
    let expiresAt: Date | undefined;
    const onPickerSelect = (
      mediaId: string[],
      mediaType: RefType,
      data:
        | Partial<File>[]
        | Partial<Playlist>[]
        | Partial<Link>[]
        | Partial<AppInstance>[],
      expires?: Date
    ) => {
      selectedMediaId = mediaId;
      selectedMediaType = mediaType;
      selectedData = data;
      expiresAt = expires;
    };

    const handleSelectedPickerItem = (
      screenPickerAction: ScreenPickerActions
    ) => {
      if (selectedMediaId.length > 0) {
        this.resolveMediaUploaderPromise({
          mediaId: selectedMediaId,
          mediaType: selectedMediaType,
          data: selectedData,
          screenPickerAction,
          expiresAt,
        });
      } else {
        this.rejectMediaUploaderPromise();
      }
      this.closeNavigationControlModal();
    };

    this.openNavigationControlModal(
      <MediaPickerModal
        screenId={opts?.screenId}
        spaceId={opts?.spaceId}
        action={action}
        allowMediaMimeType={opts?.allowMediaMimeType}
        menu={opts?.menu} // desperate menu and multiple out of opts for shallow props to allow only menu on media picker
        multiple={opts?.multiple}
        selected={``}
        section={opts?.section}
        screenPickerAction={
          opts?.screenPickerAction || ScreenPickerActions.SET_CONTENT
        }
        handleSelectedPickerItem={handleSelectedPickerItem}
        callBack={onPickerSelect}
      />,
      title,
      {
        opts: {
          overflow: true,
        },
        onClose: opts?.onClose ?? undefined,
      }
    );
    return new Promise((resolve, reject) => {
      this.resolveMediaUploaderPromise = resolve;
      this.rejectMediaUploaderPromise = reject;
    });
  }

  public openMediaUpload(
    title?: ModalHeader,
    options?: MediaUploadOptions
  ): Promise<File[]> {
    if (this.isNotificationExpandableShowing()) {
      return Promise.resolve([]);
    }

    const onUploadSuccess = (files: File[]) => {
      this.PromiseResolve(files);
    };
    const view = (
      <MediaUploadDnD onSuccess={onUploadSuccess} options={options} />
    );

    return new Promise<File[]>((resolve, reject) => {
      this.PromiseResolve = resolve;
      this.PromiseReject = reject;

      this.openUploadModal(view, title);
    });
  }

  public closeMediaUpload(): void {
    this.setState({
      isMediaUploadShowing: false,
      currentUploadView: undefined,
      modalTitle: "",
    });
  }

  public openMediaDetail({
    fileId,
    title = "",
    params,
    asScreenPicker,
    onClose,
  }: {
    fileId: Scalars["UUID"];
    title?: ModalHeader;
    params?: MediaParams;
    asScreenPicker?: boolean;
    onClose?: () => void;
  }): Promise<{
    mediaId: string;
    action: MediaPreviewActions;
    data: MediaPreviewPayload;
  }> {
    return new Promise(
      (
        resolve: (value: {
          mediaId: string;
          action: MediaPreviewActions;
          data: MediaPreviewPayload;
        }) => void,
        reject
      ) => {
        const view = (
          <MediaPreviewComponent
            fileId={fileId}
            onMediaPreviewCallBack={(
              mediaId: string,
              action: MediaPreviewActions,
              data: MediaPreviewPayload
            ) => {
              if (mediaId !== "") {
                resolve({ mediaId, action, data });
              } else {
                reject();
              }
            }}
            params={params}
            asScreenPicker={asScreenPicker}
          />
        );
        this.openModal(view, title, {
          onClose: () => onClose && onClose(),
          opts: {
            size: ModalSize.NORMAL,
            disableTitle: true,
            className: "media-preview",
          },
        });
      }
    );
  }

  public async openLinkDetail(
    linkId: Scalars["UUID"],
    title?: ModalHeader,
    asScreenPicker?: boolean
  ): Promise<{
    linkId: string;
    action: LinkPreviewActions;
    data: LinkPreviewPayload;
  }> {
    let PromiseResolve: (value: any) => void;
    let PromiseReject: () => void;

    const onLinkPreviewCallBack = (
      id: string,
      action: LinkPreviewActions,
      data: LinkPreviewPayload
    ) => {
      if (id !== "") {
        PromiseResolve({ linkId: id, action, data });
      } else {
        PromiseReject();
      }
    };

    const onClose = () => {
      PromiseResolve(undefined);
    };

    const view = (
      <LinkPreviewComponent
        linkId={linkId}
        onLinkPreviewCallBack={onLinkPreviewCallBack}
        asScreenPicker={asScreenPicker}
      />
    );
    this.openModal(view, title, {
      opts: { size: ModalSize.NORMAL, disableTitle: true },
      onClose,
    });

    return new Promise(
      (
        resolve: (value: {
          linkId: string;
          action: LinkPreviewActions;
          data: LinkPreviewPayload;
        }) => void,
        reject
      ) => {
        PromiseResolve = resolve;
        PromiseReject = reject;
      }
    );
  }

  public async openCredentialPicker(
    title?: ModalHeader,
    filter?: string
  ): Promise<Credential> {
    let PromiseResolve: (value: any) => void;

    const onCredentialSelected = (credential: any) => {
      PromiseResolve(credential);
      this.closeModals();
    };

    const picker = (
      <CredentialPicker
        filterUrn={filter || ""}
        onCredentialSelected={onCredentialSelected}
      />
    );

    this.openModal(picker, title, {
      opts: { className: "md-credential" },
      onClose: () => PromiseResolve(undefined),
    });

    return new Promise((resolve, reject) => {
      PromiseResolve = resolve;
    });
  }

  public openSecureSiteConfigure(
    title?: ModalHeader,
    site?: Maybe<Partial<Site>>,
    options: {
      opts: ModalOptions;
      onOpen?: onOpenFunc;
      onClose?: onCloseFunc;
    } = {
      opts: {
        className: "app-config-modal",
        closeButtonPosition: CloseButtonPosition.LEFT,
        disableTitle: true,
        size: ModalSize.FULLSCREEN,
      },
    }
  ): Promise<void> {
    const view = <SecureSiteEditorModal site={site} />;
    this.setState({
      isFullscreenModalShowing: true,
      title,
      fullscreenModalView: view,
      fullscreenOptions: options.opts,
    });
    return new Promise((resolve, reject) => {
      this.PromiseResolve = resolve;
      this.PromiseReject = reject;
    });
  }

  public onOpenFullscreenModal = () => {
    if (this.onOpenFullScreen) {
      this.onOpenFullScreen();
    }
    this.onOpenFullScreen = undefined;
  };

  public closeFullscreenModal = () => {
    this.promptView = undefined;
    if (this.onCloseFullScreen && this.state.isFullscreenModalShowing) {
      this.onCloseFullScreen();
    }
    this.onCloseFullScreen = undefined;

    this.setState({
      isFullscreenModalShowing: false,
      fullscreenModalView: undefined,
    });
  };

  public openAppConfigure(
    id: string,
    appName?: string,
    appInstance?: AppInstance,
    appVersion?: AppVersion,
    title?: ModalHeader,
    options: {
      opts: ModalOptions;
      onOpen?: onOpenFunc;
      onClose?: onCloseFunc;
    } = {
      opts: {
        className: "app-config-modal",
        closeButtonPosition: CloseButtonPosition.LEFT,
        disableTitle: true,
        size: ModalSize.FULLSCREEN,
      },
    }
  ): Promise<Scalars["JSON"]> {
    const view = (
      <AppContainer
        id={id}
        appName={appName}
        appInstance={appInstance}
        appVersion={appVersion}
        onUpdateSuccess={(success) => {
          this.PromiseResolve({ success });
        }}
        onCreateInstance={(
          name,
          config,
          thumbnail?: string,
          appInstanceId?: UUID
        ) => {
          this.PromiseResolve({ name, config, thumbnail, appInstanceId });
        }}
      />
    );
    if (options?.onOpen) {
      this.onOpenFullScreen = options.onOpen;
    }
    if (options?.onClose) {
      this.onCloseFullScreen = options.onClose;
    }

    this.setState({
      isFullscreenModalShowing: true,
      title,
      fullscreenModalView: view,
      fullscreenOptions: options.opts,
    });
    return new Promise((resolve, reject) => {
      this.PromiseResolve = resolve;
      this.PromiseReject = reject;
    });
  }

  public openLinkPicker(
    title: ModalHeader,
    linkById?: NonNullable<LinkByIdQueryHookResult["data"]>["linkById"],
    opts?: MediaPickerOptions
  ): Promise<CreateLinkPayload> {
    const onCallback = (param: CreateLinkPayload) => {
      this.PromiseResolve(param);
    };

    const view = (
      <LinkPicker
        callback={onCallback}
        linkById={linkById}
        spaceId={opts?.spaceId}
      />
    );

    const options: {
      opts: ModalOptions;
      onOpen?: onOpenFunc;
      onClose?: onCloseFunc;
    } = {
      opts: { disableTitle: false, size: ModalSize.NORMAL },
    };

    this.openModal(view, title, options);
    return new Promise((resolve, reject) => {
      this.PromiseResolve = resolve;
      this.PromiseReject = reject;
    });
  }

  public openNewSiteModal(): Promise<void> {
    this.openModal(<NewSiteModal />, "", { opts: { size: ModalSize.LARGE } });

    return new Promise((resolve, reject) => {
      this.PromiseResolve = resolve;
      this.PromiseReject = reject;
    });
  }

  public openBasicSiteConfigure(site: SiteFragment): Promise<void> {
    this.openModal(<BasicSiteModal site={site} />, "Basic site", {
      opts: { size: ModalSize.LARGE },
    });

    return new Promise((resolve, reject) => {
      this.PromiseResolve = resolve;
      this.PromiseReject = reject;
    });
  }

  public openNotificationExpandable(
    content: string | React.ReactNode,
    title: string | React.ReactNode,
    cancelUpload: () => void
  ) {
    this.setState({
      isNotificationShowing: true,
      notificationContent: { content, title, cancelUpload },
    });
  }

  public isNotificationExpandableShowing(): boolean {
    return this.state.isNotificationShowing;
  }

  public closeNotificationExpandable(): void {
    this.setState({
      isNotificationShowing: false,
    });
  }

  public openNavigationControlModal(
    view: React.ReactNode,
    title: ModalHeader,
    options: {
      opts: ModalOptions;
      onOpen?: onOpenFunc;
      onClose?: onCloseFunc;
    } = {
      opts: {
        className: "",
        disableTitle: false,
        closeOnDimmerClick: true,
        disableCloseButton: false,
        size: ModalSize.NORMAL,
      },
    }
  ): void {
    if (options && options.onOpen) {
      this.onOpenNavigation = options.onOpen;
    }

    if (options && options.onClose) {
      this.onCloseNavigation = options.onClose;
    }

    if (options.opts) {
      this.setState({
        isNavigationControlShowing: true,
        navigationControlView: view,
        navigationControlTitle: title,
        navigationControlOptions: options.opts,
      });
    }
  }

  public openFullscreenModal(
    view: React.ReactNode,
    options: {
      opts: ModalOptions;
      onOpen?: onOpenFunc;
      onClose?: onCloseFunc;
    } = {
      opts: {
        disableTitle: false,
        size: ModalSize.NORMAL,
        isFullscreenModalShowing: false,
      },
    }
  ): void {
    if (options && options.onOpen) {
      this.onOpenFullScreen = options.onOpen;
    }
    if (options && options.onClose) {
      this.onCloseFullScreen = options.onClose;
    }

    this.setState({
      fullscreenModalView: view,
      fullscreenOptions: options.opts,
      isFullscreenModalShowing: true,
    });
  }

  public updateModalView(view: React.ReactNode) {
    this.setState({ currentModalView: view });
  }

  public openModal(
    view: React.ReactNode,
    title: ModalHeader,
    options?: {
      opts?: ModalOptions;
      onOpen?: onOpenFunc;
      onClose?: onCloseFunc;
      onBeforeClose?: onBeforeCloseFunc;
    }
  ): void {
    const opts = options?.opts ?? {
      disableTitle: false,
      size: ModalSize.NORMAL,
      isFullscreenModalShowing: false,
    };

    if (options?.onOpen) {
      this.onOpen = options.onOpen;
    }
    if (options?.onClose) {
      this.onClose = options.onClose;
    }
    if (options?.onBeforeClose) {
      this.onBeforeClose = options.onBeforeClose;
    }

    this.setState({
      currentModalView: view,
      isModalShowing: true,
      modalOptions: opts,
      title,
    });
  }

  public openUploadModal(
    view: React.ReactNode,
    title: ModalHeader,
    options?: {
      opts?: ModalUploadOptions;
      onOpen?: onOpenFunc;
      onClose?: onCloseFunc;
    }
  ): void {
    const opts = options?.opts ?? {
      disableTitle: false,
      size: ModalSize.NORMAL,
      isFullscreenModalShowing: false,
    };

    if (options?.onOpen) {
      this.onOpenUpload = options.onOpen;
    }
    if (options?.onClose) {
      this.onCloseUpload = options.onClose;
    }

    this.setState({
      currentUploadView: view,
      isMediaUploadShowing: true,
      modalUploadOptions: opts,
      modalTitle: title,
    });
  }

  public onOpenUploadModal(): void {
    if (this.onOpenUpload) {
      this.onOpenUpload();
    }
    this.onOpenUpload = undefined;
  }

  public closeUploadModals(): void {
    if (this.onCloseUpload && this.state.isMediaUploadShowing) {
      this.onCloseUpload();
    }

    // Finish the media picker if applicable
    if (this.resolveMediaUploaderPromise) {
      this.resolveMediaUploaderPromise({});
      this.resolveMediaUploaderPromise = () => {};
      this.rejectMediaUploaderPromise = () => {};
    }

    this.onCloseUpload = undefined;

    this.setState({
      currentUploadView: undefined,
      isMediaUploadShowing: false,
      modalTitle: "",
    });
  }

  public onOpenModal(): void {
    if (this.onOpen) {
      this.onOpen();
    }
    this.onOpen = undefined;
  }

  public onOpenNavigationControlModal(): void {
    if (this.onOpenNavigation) {
      this.onOpenNavigation();
    }
    this.onOpenNavigation = undefined;
  }

  public closeNavigationControlModal(): void {
    if (this.onCloseNavigation && this.state.isNavigationControlShowing) {
      this.onCloseNavigation();
    }

    this.onCloseNavigation = undefined;

    this.setState({
      isNavigationControlShowing: false,
      navigationControlView: undefined,
      navigationControlTitle: "",
    });
  }

  public closeMediaUploadModal(): void {
    this.state.isNavigationControlShowing && this.closeNavigationControlModal();
    this.state.isMediaUploadShowing && this.closeUploadModals();
  }

  public closeModals(): void {
    if (this.onClose && this.state.isModalShowing) {
      this.onClose();
    }

    // Finish the media picker if applicable
    if (this.resolveMediaUploaderPromise) {
      this.resolveMediaUploaderPromise({});
      this.resolveMediaUploaderPromise = () => {};
      this.rejectMediaUploaderPromise = () => {};
    }

    this.onClose = undefined;

    this.setState({
      currentModalView: undefined,
      isModalShowing: false,
      title: "",
    });
  }

  public render() {
    const { contentHeight, isInvisible } = this.state.modalOptions;
    return (
      <NavigationStyled
        className={`modal-wrapper ${this.state.modalOptions.className}`}
        contentHeight={contentHeight}
        isInvisible={isInvisible}
      >
        {/* <NavigationStyled className={`modal-wrapper md-${this.state.modalOptions.size}`} contentHeight={contentHeight}></NavigationStyled> */}
        {/* Fullscreen Modal */}
        {this.state.isFullscreenModalShowing && (
          <Modal
            key="modal-fullscreen"
            className={this.state.fullscreenOptions.className}
            closeButtonPosition={
              this.state.fullscreenOptions.closeButtonPosition
            }
            overflow={this.state.fullscreenOptions.overflow}
            size={ModalSize.FULLSCREEN}
            title={``}
            open={this.state.isFullscreenModalShowing}
            onOpen={() => this.onOpenFullscreenModal()}
            onClose={() => this.closeFullscreenModal()}
          >
            {this.state.fullscreenModalView as React.ReactChild}
          </Modal>
        )}

        {/* Normal Modal */}
        {this.state.isModalShowing && (
          <Modal
            key="modal-normal"
            {...this.state.modalOptions}
            title={this.state.title}
            open={this.state.isModalShowing}
            onClose={() => this.closeModals()}
            onOpen={() => this.onOpenModal()}
            onBeforeClose={this.onBeforeClose}
          >
            {this.state.currentModalView as React.ReactChild}
          </Modal>
        )}

        {/*  Upload Modal    */}
        {this.state.isMediaUploadShowing && (
          <Modal
            data-testid="upload-modal-normal"
            data-testcy="upload-modal-normal"
            key="upload-modal-normal"
            {...this.state.modalUploadOptions}
            title={this.state.modalTitle}
            open={this.state.isMediaUploadShowing}
            onClose={() => this.closeUploadModals()}
            onOpen={() => this.onOpenUploadModal()}
          >
            {this.state.currentUploadView as React.ReactChild}
          </Modal>
        )}

        {/* Prompt */}
        {this.state.isPromptShowing && (
          <Modal
            key="prompt"
            overflow={this.state.modalOptions.overflow}
            size={ModalSize.SMALL}
            title={this.state.title}
            open={this.state.isPromptShowing}
            onClose={() => this.closePrompt()}
          >
            {this.state.promptView as React.ReactChild}
          </Modal>
        )}

        {/* Confirmation */}
        {this.state.isConfirmShowing && (
          <Modal
            key="confirmation"
            className="md-confirmation"
            size={ModalSize.SMALL}
            open={this.state.isConfirmShowing}
            overflow={true}
            onClose={() => this.closeConfirm()}
          >
            {this.state.confirmView as React.ReactChild}
          </Modal>
        )}

        {/* Navigation Control Modal */}
        {this.state.isNavigationControlShowing && (
          <NavigationControllerModal
            key="navigation-control"
            className={this.state.navigationControlOptions.className}
            overflow={this.state.navigationControlOptions.overflow}
            size={this.state.navigationControlOptions.size}
            disableTitle={this.state.navigationControlOptions.disableTitle}
            customHeader={this.state.navigationControlOptions.customHeader}
            closeOnDimmerClick={
              this.state.navigationControlOptions.closeOnDimmerClick
            }
            disableCloseButton={
              this.state.navigationControlOptions.disableCloseButton
            }
            title={this.state.navigationControlTitle}
            open={this.state.isNavigationControlShowing}
            onClose={() => this.closeNavigationControlModal()}
            onOpen={() => this.onOpenNavigationControlModal()}
          >
            {this.state.navigationControlView as React.ReactChild}
          </NavigationControllerModal>
        )}

        {/* Notification */}
        {this.state.isNotificationShowing && (
          <NotificationExpandable
            key="notification"
            title={this.state.notificationContent.title}
            content={this.state.notificationContent.content}
            onClose={() => {
              this.state.notificationContent.cancelUpload();
              this.setState({ isNotificationShowing: false });
            }}
            isExpanded={true}
            isVisible={this.state.isNotificationShowing}
          />
        )}
      </NavigationStyled>
    );
  }
}

export default ModalsView;
