import { Theme } from "@screencloud/screencloud-ui-components";
import styled, { StyledComponent } from "styled-components";

export const StyledActiveChannelLayouts: StyledComponent<
  any,
  any,
  Pick<any, any>
> = styled.div`
  text-align: left;
`;

export const StyledRevertLayoutModal: StyledComponent<
  any,
  any,
  Pick<any, any>
> = styled.div`
  .revert-layout-preview {
    display: flex;
    align-items: center;
    transform: scale(0.9);
    transform-origin: center top;
    justify-content: center;
    padding-bottom: 20px;

    .current-layout .zone {
      background-color: ${Theme.color.grey};
    }

    .layout {
      margin: 0 20px;

      span {
        display: none;
      }
    }
  }
`;

export const StyledLayout: StyledComponent<
  any,
  any,
  Pick<any, any>
> = styled.div`
  height: 100%;

  .layout-selector {
    height: calc(100% - 65px);

    .tabs {
      height: 100%;

      .ui.secondary.pointing.menu {
        border-bottom: none;
        display: flex;
        align-items: flex-end;
        background-color: ${Theme.color.white};
        padding: 0 20px;
        border-bottom: 1px solid ${Theme.color.lightGrey};
      }
    }
  }

  .custom-img {
    filter: invert(1);
  }

  .item-layout-preview {
    background: ${Theme.color.lightGrey};
    overflow: hidden;
  }

  .selected {
    background: ${Theme.color.silver} !important;
    border-color: ${Theme.color.darkGrey};
    box-shadow: inset 0 -1px 0 0 ${Theme.color.darkGrey};
  }

  .header-title {
    margin: 0 0 10px 0;
  }

  .zone-list {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20px;
    padding: 20px;
    height: calc(100% - 50px);
    overflow: auto;

    .layout-preview {
      height: 0;
      margin: 5px;
      padding-bottom: calc(25% - 10px);
      position: relative;
      width: calc(25% - 10px);

      &.disabled {
        .button-wrapper {
          opacity: 0.5;
        }

        .custom-layout-actions i {
          cursor: pointer;
        }

        .zone span {
          display: none;
        }
      }

      > .button-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;

        .custom-landscape,
        .custom-portrait {
          align-items: center;
          background-color: ${Theme.color.darkGrey};
          border-radius: 2px;
          display: flex;
          justify-content: center;
        }

        span {
          align-items: center;
          bottom: 8px;
          color: ${Theme.color.darkGrey};
          display: flex;
          font-size: 0.875em;
          font-weight: normal;
          height: 32px;
          line-height: 1.357;
          position: absolute;
          white-space: normal;
        }

        .custom-layout-actions {
          position: absolute;
          top: 5px;
          right: 5px;
          opacity: 0;

          i {
            width: 32px;
            height: 32px;
            mask-size: 14px;
            background-color: ${Theme.color.grey};

            :hover {
              background-color: ${Theme.color.base};
            }
          }
        }
      }

      .layout-info {
        position: absolute;
        bottom: 8px;
        display: flex;
        height: 32px;
        align-items: center;
        flex-direction: column;
        width: 100%;

        > span {
          color: ${Theme.color.darkGrey};
          font-size: 0.875em;
          font-weight: normal;
          display: inline-block;
          line-height: 1.357;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 6px 0;

          .zones-count {
            color: ${Theme.color.grey};
            font-size: 0.75em;
          }
        }
      }

      &.active {
        background-color: ${Theme.color.silver};
        border-color: ${Theme.color.darkGrey};
        box-shadow: inset 0 -1px 0 0 ${Theme.color.darkGrey};

        > .button-wrapper {
          span {
            color: ${Theme.color.base};
          }
          .zone {
            background-color: ${Theme.color.base};
          }
        }
      }

      &.disabled {
        > .button-wrapper {
          span {
            color: ${Theme.color.grey};
          }
        }
      }

      :hover {
        .zone {
          background-color: ${Theme.color.base};
        }
        span {
          color: ${Theme.color.base};
        }
        .custom-layout-actions {
          opacity: 1;
        }
      }

      &.custom-layout-new {
        background: transparent;
        border: 1px dashed ${Theme.color.grey};
        box-shadow: none;

        :hover {
          background-color: ${Theme.color.white};

          .zone {
            background-color: ${Theme.color.grey};
          }
        }

        i {
          position: absolute;
          z-index: 11;
          width: 25px;
          height: 25px;
          background-color: ${Theme.color.white};
        }

        .zone {
          background-color: ${Theme.color.lightGrey};

          span {
            display: none;
          }
        }
      }
    }

    .mobile-message {
      display: none;
    }
  }

  .common-layouts-container {
    width: 100%;

    p {
      margin-left: 6px;
      margin-bottom: 4px;
    }
  }

  .new-common-layouts {
    margin-bottom: 28px;
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .zone-list {
      .button {
        width: calc(33.33% - 10px);
        padding-bottom: calc(33.33% - 10px);

        .button-wrapper {
          .custom-layout-actions {
            opacity: 1;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .zone-list {
      .button {
        width: calc(50% - 10px);
        padding-bottom: calc(50% - 10px);
      }
    }

    .md-footer {
      .button {
        width: calc(50% - 5px);

        :only-child {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .zone-list {
      padding: 15px 10px;

      .custom-layout-new {
        display: none;
      }

      .layout-preview {
        order: 2;

        > .button-wrapper {
          .custom-layout-actions {
            display: none;
          }

          .layout {
            zoom: 0.8;
          }

          .item-layout-preview {
            zoom: 0.8;

            .layout {
              zoom: 1;
            }
          }

          .title {
            display: none;
          }

          .zone {
            margin: 0;
          }
        }

        .layout-info {
          display: none;
        }
      }

      .mobile-message {
        display: block;
        order: 1;
        height: auto;
        text-align: left;
        margin-bottom: 20px;
        font-style: italic;
        color: ${Theme.color.midGrey};

        h3 {
          display: none;
        }
      }

      .mobile-message:nth-child(2) {
        width: 100%;
        margin: 0;
        text-align: center;
        padding: 5px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${Theme.color.base};
        font-style: normal;
        flex-direction: column;

        h3 {
          display: block;
          font-size: 1.5em;
          font-weight: bold;
        }
      }
    }

    .md-footer {
      .btn-customize {
        display: none;
      }

      .button {
        width: 100%;
      }
    }
  }
`;
