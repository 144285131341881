import { Theme } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import styled from "styled-components";

export interface TagTypeProps {
  className?: string;
  children?: React.ReactNode;
  color: string;
}

const Tag = ({ className, children }: TagTypeProps) => (
  <div className={className}>{children}</div>
);

const StyledTag = styled(Tag)`
  background: ${(props: TagTypeProps) => props.color};
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: space-between;
  border-radius: 11px;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.1em;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 900;
  color: ${Theme.color.white};
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
`;
export default StyledTag;
