import { FetchResult } from "@apollo/client";
import { genUuid } from "@screencloud/uuid";
import {
  Playlist,
  UpdatePlaylistMutation,
  UpdatePlaylistMutationVariables,
  useUpdatePlaylistMutation,
} from "src/types.g";
import { ListContentItem, RefType } from "src/constants/constants";

export interface UseAddAppsToPlaylistsMutation {
  addAppsToPlaylists: ({
    appInstanceIds,
    playlists,
  }: {
    appInstanceIds: string[];
    playlists: Playlist[];
  }) => Promise<
    FetchResult<
      UpdatePlaylistMutation,
      Record<string, any>,
      Record<string, any>
    >[]
  >;
}

export const createAppContentRefObject = (
  appInstanceId: string
): ListContentItem => {
  return {
    content: {
      _ref: {
        id: appInstanceId,
        type: RefType.APP,
      },
      props: {
        duration: 0,
      },
    },
    list_id: genUuid(),
  };
};

export const getUpdatePlaylistContentMutationVariables = (
  appInstanceIds: string[],
  playlist: Playlist
): UpdatePlaylistMutationVariables => {
  const newContents = appInstanceIds.map(createAppContentRefObject);
  const currentContent = playlist.draft?.content;
  const list = [...currentContent.list, ...newContents];

  const duration =
    currentContent.props.default_durations[RefType.APP] * newContents.length;
  return {
    input: {
      content: {
        ...currentContent,
        list,
        props: {
          ...currentContent.props,
          total_durations: currentContent.props.total_durations + duration,
          total_items: list.length,
        },
      },
      id: playlist.id,
    },
  };
};
export function useAddAppsToPlaylistsMutation(): UseAddAppsToPlaylistsMutation {
  const [updatePlaylistMutation] = useUpdatePlaylistMutation();
  const addAppsToPlaylist = async ({
    appInstanceIds,
    playlist,
  }: {
    appInstanceIds: string[];
    playlist: Playlist;
  }) => {
    return updatePlaylistMutation({
      variables: getUpdatePlaylistContentMutationVariables(
        appInstanceIds,
        playlist
      ),
    });
  };

  const addAppsToPlaylists = async ({
    appInstanceIds,
    playlists,
  }: {
    appInstanceIds: string[];
    playlists: Playlist[];
  }) => {
    return Promise.all(
      playlists.map((playlist) =>
        addAppsToPlaylist({ appInstanceIds, playlist })
      )
    );
  };

  return { addAppsToPlaylists };
}
