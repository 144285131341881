import { FolderPathItem } from "../../../components/Media/mediaBreadCrumb";
import { isRootFolder } from "../../../helpers/folderHelper";
import { useFolderParentsQuery } from "../../../types.g";
import { SHARE_BY_OTHER_FOLDER_ID } from "./mediaListHelper";

interface BreadCrumbFolderPathItemParams {
  folderId: string;
  isSearch: boolean;
  isInRootFolder: boolean;
  isInRootSharedFolderFromOtherSpaces: boolean | undefined;
  isInSharedFolderFromOtherSpaces: boolean | undefined;
}

export function useBreadCrumbFolderPathItems({
  folderId,
  isSearch,
  isInRootFolder,
  isInRootSharedFolderFromOtherSpaces,
  isInSharedFolderFromOtherSpaces,
}: BreadCrumbFolderPathItemParams) {
  const noBreadCrumb = isSearch || isInRootFolder;
  const noNeedToQueryParents =
    noBreadCrumb || isInRootSharedFolderFromOtherSpaces;

  const { data: folderParentsQuery } = useFolderParentsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      folderid: folderId,
    },
    skip: noNeedToQueryParents,
  });
  let folderPathItems: FolderPathItem[] = [];
  if (isInRootSharedFolderFromOtherSpaces) {
    folderPathItems = [
      {
        id: SHARE_BY_OTHER_FOLDER_ID,
        name: "Shared by Others",
        spaceId: undefined,
      },
    ];
  } else if (noBreadCrumb) {
    folderPathItems = [];
  } else {
    const items = (folderParentsQuery?.folderParents?.nodes ?? [])
      .filter((item) => !isRootFolder(item))
      .map(({ id, name, spaceId }) => ({ id, name, spaceId }))
      .reverse();
    if (isInSharedFolderFromOtherSpaces) {
      items.unshift({
        id: SHARE_BY_OTHER_FOLDER_ID,
        name: "Shared by Others",
        spaceId: undefined,
      });
    }
    folderPathItems = items;
  }

  return folderPathItems;
}
