import * as React from "react";
import { SiteFragment } from "../../../types.g";
import SiteLinkPicker from "../index";
import { Styled } from "./styles";

interface BasicSiteModalProps {
  callback?: () => void;
  site: SiteFragment;
}

const BasicSiteModal: React.FC<BasicSiteModalProps> = ({ callback, site }) => {
  return (
    <Styled>
      <SiteLinkPicker callback={callback} site={site} />
    </Styled>
  );
};

export default BasicSiteModal;
