import {
  UpdateFolderIsFavoriteMutationVariables,
  useUpdateFolderIsFavoriteMutation,
} from "../../../types.g";

export interface UpdateFolderIsFavourite {
  updateFolderIsFavourite: (
    folderId: string,
    isFavorite?: boolean
  ) => Promise<any>;
}

export function useUpdateFolderIsFavourite(): UpdateFolderIsFavourite {
  const [updateFolderIsFavouriteMutation] = useUpdateFolderIsFavoriteMutation();

  const updateFolderIsFavourite = (folderId: string, isFavorite?: boolean) => {
    const updateFolderFavoriteVariables: UpdateFolderIsFavoriteMutationVariables = {
      input: {
        folderIds: [folderId],
        isFavorite,
      },
    };
    return updateFolderIsFavouriteMutation({
      variables: updateFolderFavoriteVariables,
    });
  };

  return { updateFolderIsFavourite };
}
