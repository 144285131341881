import { Styled, StyledImg } from "./styles";
import fallback from "./img/profile.svg";
export interface AvatarProps {
  url?: string;
  size?: number;
  name?: string;
  dataTestid?: string;
}

const Avatar = ({ dataTestid, name, url, size }: AvatarProps) => {
  if (url && url.length > 0) {
    return (
      <StyledImg src={url} className={size ? `size-${size}` : undefined} />
    );
  } else {
    return (
      <Styled
        data-testid={dataTestid}
        className={size ? `avatar size-${size}` : "avatar"}
      >
        {name ? name.slice(0, 1).toUpperCase() : <img src={fallback} />}
      </Styled>
    );
  }
};

export default Avatar;
