export const colorsPreset = [
  {
    bottom: "#9F041B",
    name: "color0",
    top: "#F5515F",
  },
  {
    bottom: "#FF0000",
    name: "color1",
    top: "#FFBB00",
  },
  {
    bottom: "#F12452",
    name: "color2",
    top: "#FB8013",
  },
  {
    bottom: "#C15602",
    name: "color3",
    top: "#F2A603",
  },
  {
    bottom: "#FEA800",
    name: "color4",
    top: "#FECF00",
  },
  {
    bottom: "#429321",
    name: "color5",
    top: "#B4EC51",
  },
  {
    bottom: "#00B500",
    name: "color6",
    top: "#06CC84",
  },
  {
    bottom: "#0088EA",
    name: "color7",
    top: "#01C0D8",
  },
  {
    bottom: "#3023AE",
    name: "color8",
    top: "#53A0FD",
  },
  {
    bottom: "#CC1192",
    name: "color9",
    top: "#FB1EB7",
  },
  {
    bottom: "#4333EB",
    name: "color10",
    top: "#8459FF",
  },
  {
    bottom: "#3123AE",
    name: "color11",
    top: "#C46BD6",
  },
  {
    bottom: "#3C4F73",
    name: "color12",
    top: "#7A8AA1",
  },
  {
    bottom: "#01306A",
    name: "color13",
    top: "#23466C",
  },
  {
    bottom: "#000000",
    name: "color14",
    top: "#A8A8A8",
  },
];
