import { Button, TextArea } from "@screencloud/screencloud-ui-components";
import { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import PlayerPreview, { PreviewType } from "../../components/PlayerPreview/";
import { Orientation } from "../../constants/constants";
import { Styled } from "./styles";

export interface EmbedPreviewProps {
  id: string;
  embededUrl: string;
  previewType: PreviewType;
}

export interface EmbedPreviewState {
  isIframeCopied: boolean;
}

class EmbedPreview extends Component<EmbedPreviewProps, EmbedPreviewState> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: EmbedPreviewProps) {
    super(props);
    this.state = {
      isIframeCopied: false,
    };
  }

  public selectText = () => {
    this.setState({ isIframeCopied: true });
  };

  public render() {
    const { id, embededUrl, previewType } = this.props;
    const { isIframeCopied } = this.state;
    const embedUrl = `<iframe src="${embededUrl}" width="1280" height="720" scrolling="no" />`;

    return (
      <Styled>
        <div className="embed-preview">
          <PlayerPreview
            id={id}
            previewType={previewType}
            orientation={Orientation.Landscape}
            showPlayerOnly
          />
        </div>

        <div className="embed-code">
          <CopyToClipboard text={embedUrl} onCopy={this.selectText}>
            <TextArea defaultValue={embedUrl} />
          </CopyToClipboard>
          <CopyToClipboard text={embedUrl} onCopy={this.selectText}>
            <Button fullWidth>
              {isIframeCopied ? (
                <FormattedMessage
                  id="common.text.copied"
                  defaultMessage="Copied"
                />
              ) : (
                <FormattedMessage id="common.text.copy" defaultMessage="Copy" />
              )}
            </Button>
          </CopyToClipboard>
        </div>
      </Styled>
    );
  }
}

export default EmbedPreview;
