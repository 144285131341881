import { cloneDeep, uniqBy } from "lodash";
import { App, AppInstall, Maybe } from "src/types.g";
import { UUID } from "../../constants/constants";

export const getSortedAppInstalls = (appInstalls: AppInstall[]) => {
  return cloneDeep(appInstalls).sort((a, b) =>
    (a?.appByAppId?.name ?? "").localeCompare(
      b?.appByAppId?.name ?? "",
      undefined,
      { sensitivity: "base" }
    )
  );
};

export const isCanvasApp = (appInstall: AppInstall) =>
  appInstall?.spaceId === null;

/**
 * There might be same apps on different spaces.
 * This will prevent showing the same app more than one time.
 *
 */
export const getDistinctAppsByAppInstalls = (appInstalls: AppInstall[]) => {
  const apps: App[] = appInstalls.map(
    (appInstall) => appInstall.appByAppId as App
  );
  return uniqBy(apps, "id");
};

/**
 * Use Cases: Appinstall can be shared from different spaces
 * For example, clock app can be shared from Space A and Space B
 * Space A - 1 Instance
 * Space B - 1 Instance
 * We would like a result to Show result as
 * App - Clock
 * 2 Instances
 */
export const distinctAppByAppInstalls = <
  T extends { appByAppId: Maybe<{ id: string }> }
>(
  appInstalls: T[]
) => {
  const allApps: App[] = appInstalls.map(
    (appInstall) => appInstall.appByAppId as App
  );

  const dict = allApps.reduce((dict, app) => {
    if (!dict[app?.id]) {
      dict[app?.id] = 0;
    }
    dict[app?.id] = dict[app?.id] + 1;
    return dict;
  }, {});

  return { dict, allApps };
};

export const getAppInstancesTotalCountPerApp = (
  appId: UUID,
  appInstalls: AppInstall[]
): number => {
  const allSmaeApps = appInstalls.filter(
    (appInstall) => appInstall.appByAppId?.id === appId
  );
  const totalCount = getTotalAppInstancesCount(allSmaeApps);
  return totalCount ?? 0;
};

const getTotalAppInstancesCount = (allSmaeApps: AppInstall[]) => {
  const totalCount = allSmaeApps.reduce(
    (sum: number, appInstall: AppInstall) => {
      return (
        sum + appInstall.availableAppInstancesByAppInstallIdAndSpace.totalCount
      );
    },
    0
  );
  return totalCount;
};
