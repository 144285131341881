import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
} from "@screencloud/screencloud-ui-components";
import { canCast, canSetContent } from "../../../src/helpers/screenHelper";
import { ScreenPickerActions } from ".";
import { useAppContext } from "../../hooks/useAppContext";

export interface Props {
  action: ScreenPickerActions;
  onUpdateAction: (screenPickerAction: ScreenPickerActions) => void;
}

export const ScreenPickerActionsDropdownOption = (props: Props) => {
  const context = useAppContext();
  const canCastToScreen = canCast(context);
  const canSetContentToScreen = canSetContent(context);
  const { action, onUpdateAction } = props;

  const { icon, value } = {
    [ScreenPickerActions.SET_CONTENT]: {
      icon: "screen-play",
      value: "Set Content",
    },
    [ScreenPickerActions.CASTING]: {
      icon: "casting",
      value: "Cast",
    },
  }[action];

  return (
    <Dropdown
      data-testid="dropdown-cast-options"
      className="selection"
      trigger={
        <DropdownItem>
          <Icon name={icon} />
          <span>{value}</span>
        </DropdownItem>
      }
      fluid
    >
      <DropdownMenu>
        {canSetContentToScreen && (
          <DropdownItem
            data-testid="dropdown-item-set-content"
            onClick={() => onUpdateAction(ScreenPickerActions.SET_CONTENT)}
            key="set-content"
            value="Set Content"
          >
            <Icon name="screen-play" />
            <span>Set Content</span>
          </DropdownItem>
        )}
        {canCastToScreen && (
          <DropdownItem
            data-testid="dropdown-item-cast"
            onClick={() => onUpdateAction(ScreenPickerActions.CASTING)}
            key="cast"
            value="Cast"
          >
            <Icon name="casting" />
            <span>Cast</span>
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
