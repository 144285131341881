import styled from "styled-components";
import fontGradient from "src/components/OnboardingModal/images/font-gradient.jpg";
import { Theme } from "@screencloud/screencloud-ui-components";

export const Header = styled.div`
  color: var(--color-text-text-primary, #19191b);
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;

  .button {
    width: 200px;
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .button {
      width: 100%;
    }
  }
`;

export const DeviceInformationModalStyled = styled.div`
  display: flex;
  padding: var(--spacing-xl-large, 40px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-large, 24px);
  flex-shrink: 0;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: ${Theme.break.small}) {
    padding: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr auto;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: ${Theme.break.small}) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardStyled = styled.div<{
  backgroundColor: string;
  imageSource: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: var(--border-radius-large, 16px);
  border: ${({ backgroundColor }) => `2px solid ${backgroundColor}`};
  background: ${({ backgroundColor }) =>
    `linear-gradient(180deg, ${backgroundColor} 0%, #FFF 48.96%)`};
  cursor: pointer;
  transition: transform ease-in 0.1s;

  .thumbnail {
    width: 100%;
    height: 140px;
    background: url(${({ imageSource }) => `${imageSource}`}) no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 1;
  }

  .description {
    display: flex;
    padding: 0px 24px 24px 24px;
    flex-direction: column;
    z-index: 1;
    gap: 16px;
    width: 100%;
    flex: 1 0 0;
    height: 100%;

    .top-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-x-small, 4px);
      align-self: stretch;

      .title {
        color: var(--color-text-text-primary, #19191b);
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }

      .subtext {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        align-self: stretch;

        color: var(--color-text-text-secondary, #4d4e54);

        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .button {
      font-size: 14px;
      line-height: 20px;
      margin-top: auto;
      align-self: flex-start;

      i {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.scos {
    background: linear-gradient(91deg, #19191b 0%, #2c2972 100%);
    border: none;
    grid-column: 1 / span 3;
    grid-row: 2;
    width: 100%;
    height: 185px;
    flex-direction: row-reverse;
    overflow: hidden;
    position: relative;

    :before {
      display: block;
      position: absolute;
      content: "";
      width: 484px;
      height: 484px;
      right: -90px;
      bottom: -213px;
      border-radius: 484px;
      background: var(
        --brand-gradient,
        linear-gradient(
          44deg,
          #ffd089 0%,
          #ffe1be 27.6%,
          #f9dfcb 41.67%,
          #f6ddd2 57.29%,
          #ebced2 76.3%,
          #bd8ed2 100%
        ),
        #fff
      );
    }

    .thumbnail {
      width: 50%;
      height: 100%;
      background-size: 321px;
      background-position: 130px -50px;
    }

    .description {
      justify-content: flex-end;

      .title {
        font-size: 24px;
        font-weight: 800;
        line-height: 32px;
        color: #fff;
        background: url(${fontGradient}) no-repeat;
        background-size: 100% 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .subtext {
        color: var(--color-text-text-secondary-invert, #7d7e84);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .button {
        margin-top: 0;
        color: var(--color-white100);
        i {
          background-color: var(--color-white-100);
        }
      }
    }
  }

  :hover {
    transform: scale(1.02);

    .description {
      .button {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .description {
      padding: 16px;
    }

    &.scos {
      height: 200px;

      .thumbnail {
        width: 48%;
        background-position: 100px -40px;
      }

      :before {
        right: -150px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    width: 100%;
    flex-direction: row;

    .thumbnail {
      height: 100px;
      width: 120px;
    }

    .description {
      padding-left: 0;
      gap: 8px;
    }

    &.scos {
      grid-column: 2 / span 1;
      height: auto;
      overflow: visible;
      flex-direction: row;

      :before {
        display: none;
      }

      .thumbnail {
        height: 120px;
        width: 120px;
        background-size: 96px;
        background-position: 14px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    flex-direction: column;

    .thumbnail {
      height: 60px;
    }

    .description {
      padding-left: 16px;
    }

    &.scos {
      flex-direction: column;

      .thumbnail {
        height: 60px;
        background-size: 76px;
        background-position: 22px 13px;
      }

      .description {
        .top-section {
          .title {
            font-size: 20px;
          }
        }
      }
    }
  }
`;
