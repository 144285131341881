import memoize from "memoize-one";
import { v4 as uuidv4 } from "uuid";
import {
  DEFAULT_GLOBAL_DURATION,
  ListContentItem,
  ListItemType,
  RefType,
} from "../../../constants/constants";
import {
  AppInstance,
  JobStatus,
  FileListItemForPlaylistFragment,
  PlaylistByIdDataFragment,
  Playlist,
  UpdatePlaylistMutationVariables,
  UpdatePlaylistMutation,
  PlaylistByIdForListCachingQuery,
} from "../../../types.g";
import { getListItemType, removeEmptyFromArray } from "../../../utils";
import { PlaylistItem, ActivePlaylistItem } from "./index";
import { appInstanceIconUrl } from "../../../helpers/appHelper";
import {
  generateImgixThumbnail,
  getFileOutputImageSet,
} from "../../../helpers/mediaHelper";
import { isExpired } from "../../../helpers/scheduleableHelper";

export interface DefaultDuration {
  app: number;
  link: number;
  site: number;
  image: number;
  document: number;
}

export const fileToPlaylistContentMapping = (
  files: FileListItemForPlaylistFragment[],
  duration: number,
  currentItem?: ListContentItem
): ActivePlaylistItem[] => {
  const customDuration = currentItem?.content?.props?.duration ?? 0;
  return files.reduce((acc, cur) => {
    const videoFileOutput = cur.fileOutputsByFileId.nodes.find((fileOutput) =>
      fileOutput?.mimetype?.startsWith("video")
    );
    const audioFileoutput = cur.fileOutputsByFileId.nodes.find((fileOutput) =>
      fileOutput?.mimetype?.startsWith("audio")
    );

    const fileDuration = videoFileOutput?.metadata?.duration
      ? videoFileOutput?.metadata?.duration
      : audioFileoutput?.metadata?.duration
      ? audioFileoutput?.metadata?.duration
      : customDuration
      ? customDuration
      : duration;

    const newObj = {
      id: cur.id,
      dragableId: cur.id + Math.random(),
      list_id: currentItem?.list_id ?? uuidv4(),
      name: cur.name,
      refType: RefType.FILE,
      customDuration,
      duration: fileDuration,
      mimeType: cur.mimetype,
      imageSet: getFileOutputImageSet(cur) ?? undefined,
      isFixedDuration: videoFileOutput || audioFileoutput ? true : false,
      isExpired: isExpired(cur),
    };
    return cur.fileProcessingStatus === JobStatus.Failed
      ? acc
      : [...acc, newObj];
  }, []);
};

export const linkToPlaylistContentMapping = (
  links: any,
  duration: number,
  currentItem?: ListContentItem
): ActivePlaylistItem[] => {
  const customDuration = currentItem?.content?.props?.duration ?? 0;
  return links.reduce((acc, cur) => {
    const newObj = {
      id: cur.id,
      list_id: currentItem?.list_id ?? uuidv4(),
      dragableId: cur.id + Math.random(),
      name: cur.name,
      refType: RefType.LINK,
      customDuration,
      duration,
      mimeType: cur.__typename,
    };
    return [...acc, newObj];
  }, []);
};

export const siteToPlaylistContentMapping = (
  sites: any,
  duration: number,
  currentItem?: ListContentItem
): ActivePlaylistItem[] => {
  const customDuration = currentItem?.content?.props?.duration ?? 0;
  return sites.reduce((acc, cur) => {
    const newObj = {
      id: cur.id,
      list_id: currentItem?.list_id ?? uuidv4(),
      dragableId: cur.id + Math.random(),
      name: cur.name,
      refType: RefType.SITE,
      customDuration,
      duration,
      mimeType: cur.__typename,
    };
    return [...acc, newObj];
  }, []);
};

export const appToPlaylistContentMapping = (
  apps: any,
  defaultDuration: number,
  currentItem?: ListContentItem,
  iconUrl?: string,
  ramdomDragId?: string
): ActivePlaylistItem[] => {
  const customDuration = currentItem?.content?.props?.duration ?? 0;
  return apps.reduce((acc, cur) => {
    const newObj = {
      id: cur.id,
      list_id: currentItem?.list_id ?? uuidv4(),
      dragableId: ramdomDragId ?? cur.id + Math.random(),
      name: cur.name,
      refType: RefType.APP,
      customDuration,
      duration: defaultDuration,
      mimeType: cur.__typename,
      isFixedDuration: !!(
        cur.config && cur.config.appInstanceDurationInSeconds
      ),
      isExpired: isExpired(cur),
    };
    return [...acc, newObj];
  }, []);
};

export const createLinkContentData = (
  links: any,
  linkDuration,
  secureMediaPolicy: string | undefined,
  list?: ListContentItem
): ActivePlaylistItem[] => {
  const customDuration =
    list && list.content?.props.duration ? list.content?.props.duration : 0;
  return links.reduce((acc, cur) => {
    const newObj = {
      contentId: cur.id,
      customDuration,
      duration: linkDuration,
      id: cur.id + Math.random(),
      list_id: list ? list.list_id : uuidv4(),
      metadata: cur.linkType,
      mimeType: cur.__typename,
      name: cur.name,
      refType: RefType.LINK,
      thumbnail:
        (cur.fileByFileId &&
          generateImgixThumbnail(cur.fileByFileId, secureMediaPolicy, true)) ||
        "",
    };
    return [...acc, newObj];
  }, []);
};

export const createSiteContentData = (
  sites: any,
  defaultDuration: number,
  secureMediaPolicy: string | undefined,
  list?: ListContentItem
): ActivePlaylistItem[] => {
  const customDuration =
    list && list.content?.props.duration ? list.content?.props.duration : 0;
  return sites.reduce((acc, cur) => {
    const newObj = {
      contentId: cur.id,
      customDuration,
      duration: defaultDuration,
      id: cur.id + Math.random(),
      list_id: list ? list.list_id : uuidv4(),
      metadata: cur.type,
      mimeType: cur.__typename,
      name: cur.name,
      refType: RefType.SITE,
      thumbnail:
        (cur.fileByThumbnailId &&
          generateImgixThumbnail(
            cur.fileByThumbnailId,
            secureMediaPolicy,
            true
          )) ||
        "",
    };
    return [...acc, newObj];
  }, []);
};

export const createAppContentData = (
  apps: any,
  defaultDuration: number,
  list?: ListContentItem,
  iconUrl?: string
): PlaylistItem[] => {
  const customDuration =
    list && list.content?.props.duration ? list.content?.props.duration : 0;
  return apps.reduce((acc, cur) => {
    const _iconUrl = appInstanceIconUrl((cur as unknown) as AppInstance);
    const newObj = {
      contentId: cur.id,
      customDuration,
      duration: defaultDuration,
      id: cur.id + Math.random(),
      isFixedDuration: !!(
        cur.config && cur.config.appInstanceDurationInSeconds
      ),
      list_id: list ? list.list_id : uuidv4(),
      metadata: {},
      mimeType: cur.__typename,
      name: cur.name,
      refType: RefType.APP,
      thumbnail: _iconUrl || iconUrl,
    };
    return [...acc, newObj];
  }, []);
};

export const getPlaylistItemsDuration = (list: ActivePlaylistItem[]) => {
  list = removeEmptyFromArray(list).filter((item) => !item.isExpired);
  const itemsDuration = list.reduce((sum, item) => {
    let duration = item.customDuration ? item.customDuration : item.duration;
    duration =
      getListItemType(item.mimeType as string) === ListItemType.DOCUMENT &&
      item.imageSet
        ? duration * item.imageSet.content.urls.length
        : duration;
    return sum + duration;
  }, 0);
  return itemsDuration;
};

export const getDefaultDuration = (
  defaultDuration: DefaultDuration,
  currentItem: ListContentItem,
  isPdfFile: boolean
) => {
  let duration = DEFAULT_GLOBAL_DURATION;

  if (currentItem.content._ref.type === RefType.FILE) {
    duration = isPdfFile ? defaultDuration.document : defaultDuration.image;
  } else if (defaultDuration[currentItem.content._ref.type]) {
    duration = defaultDuration[currentItem.content._ref.type];
  }

  return duration;
};

export const getPdfFileIds = (
  data: NonNullable<PlaylistByIdDataFragment["draft"]>
): string[] => {
  let pdfFileIds: string[] = [];

  if (data.filesByPlaylistId?.nodes.length) {
    pdfFileIds = data.filesByPlaylistId.nodes
      .filter((file) => file.mimetype === "application/pdf")
      .map((file) => file.id);
  }
  return pdfFileIds;
};

export const isPdfFile = (
  pdfFileIds: string[],
  playlistItem: ListContentItem
): boolean => {
  if (!playlistItem.content?._ref) {
    return false;
  }
  return pdfFileIds.includes(playlistItem.content._ref.id);
};

/**
 * Maps the active playlist items from the given data object.
 *
 * @param data The data object representing the playlist.
 * @returns An array of active playlist items.
 */

export const mappingActivePlaylistItems = memoize(
  (
    data: NonNullable<PlaylistByIdDataFragment["draft"]>
  ): ActivePlaylistItem[] => {
    // Check if the data contains any playlist items
    if (data.content.list.length === 0) {
      return [];
    }

    // Initialize an array to store the active playlist items
    const activePlaylistItems: ActivePlaylistItem[] = [];

    // Get the PDF file IDs
    const pdfFileIds: string[] = getPdfFileIds(data);

    // Retrieve the content list items
    const contentListItems = data.content.list as ListContentItem[];
    for (const currentItem of contentListItems) {
      // Perform different actions based on the type of the current item's content reference
      if (
        currentItem.content?._ref &&
        currentItem.content._ref.type &&
        currentItem.content._ref.id
      ) {
        let defaultDuration = getDefaultDuration(
          data.content.props.default_durations,
          currentItem,
          isPdfFile(pdfFileIds, currentItem)
        );
        switch (currentItem.content._ref.type) {
          case RefType.FILE:
            const fileByRefId =
              data.filesByPlaylistId &&
              data.filesByPlaylistId.nodes.find(
                (file) => file.id === currentItem.content._ref.id
              );
            if (fileByRefId) {
              const fileContentData = fileToPlaylistContentMapping(
                [fileByRefId],
                defaultDuration,
                currentItem
              );
              fileContentData.length &&
                activePlaylistItems.push(fileContentData[0]);
            }
            break;
          case RefType.LINK:
            const linkByRefId =
              data.linksByPlaylistId &&
              data.linksByPlaylistId.nodes.find(
                (link) => link!.id === currentItem.content._ref.id
              );

            if (linkByRefId) {
              const linkContentData = linkToPlaylistContentMapping(
                [linkByRefId],
                defaultDuration,
                currentItem
              );
              linkContentData.length &&
                activePlaylistItems.push(linkContentData[0]);
            }
            break;
          case RefType.SITE:
            const sitesByRefId =
              data.sitesByPlaylistId &&
              data.sitesByPlaylistId.nodes.find(
                (site) => site!.id === currentItem.content._ref.id
              );

            if (sitesByRefId) {
              const siteContentData = siteToPlaylistContentMapping(
                [sitesByRefId],
                defaultDuration,
                currentItem
              );
              siteContentData.length &&
                activePlaylistItems.push(siteContentData[0]);
            }
            break;
          case RefType.APP:
            const appByRefId =
              data.appInstancesByPlaylistId &&
              data.appInstancesByPlaylistId.nodes.find(
                (app) => app!.id === currentItem.content._ref.id
              );

            if (appByRefId) {
              if (appByRefId.config?.appInstanceDurationInSeconds) {
                defaultDuration =
                  (appByRefId.config?.appInstanceDurationInSeconds ?? 1) * 1000;
              }
              const appContentData = appToPlaylistContentMapping(
                [appByRefId],
                defaultDuration,
                currentItem
              );
              appContentData.length &&
                activePlaylistItems.push(appContentData[0]);
            }
            break;
        }
      }
    }
    return activePlaylistItems;
  }
);

export const getValidPlaylistItems = (items: ActivePlaylistItem[]) =>
  items.filter((item) => item && !item.isExpired);

export const doesContainExpiredPlaylistItems = (items: ActivePlaylistItem[]) =>
  items.some((item) => item && item.isExpired);

export const getFormattedPlaylistDataForCacheListing = ({
  playlistByIdForListCaching,
  playlistInput,
  updatedPlaylistData,
}: {
  playlistByIdForListCaching: PlaylistByIdForListCachingQuery["playlistById"];
  playlistInput: UpdatePlaylistMutationVariables;
  updatedPlaylistData: UpdatePlaylistMutation;
}) => {
  let formattedData = {
    ...playlistByIdForListCaching,
    ...playlistInput.input,
    draft: {
      ...playlistByIdForListCaching?.draft,
      ...updatedPlaylistData?.updatePlaylist?.playlist?.draft,
      ...playlistInput.input,
    },
  };
  if (updatedPlaylistData?.updatePlaylist?.playlist?.draft?.isPublished) {
    formattedData = {
      ...formattedData,
      published: {
        ...playlistByIdForListCaching?.published,
        ...updatedPlaylistData?.updatePlaylist?.playlist?.draft,
        ...playlistInput.input,
      } as Playlist,
    };
  }

  return formattedData;
};

export const getInitialDurations = (
  list: ListContentItem[]
): Record<string, number> => {
  return list.reduce((acc: Record<string, number>, item: ListContentItem) => {
    acc[item.list_id] = item.content.props.duration;
    return acc;
  }, {});
};
