import { fieldsPolicyType } from "./apolloClient";

export const allSpacesField: fieldsPolicyType = {
  allSpaces: {
    keyArgs: ["filter"],
    merge(existing, incoming, options) {
      const incomingNodes = incoming?.nodes ?? [];
      return {
        ...options.mergeObjects(existing, incoming),
        incomingNodes,
        pageInfo: incoming.pageInfo,
      };
    },
    read(existing, _options) {
      const existingNodes = existing?.nodes ?? [];
      return (
        existing && {
          ...existing,
          nodes: Object.values(existingNodes),
          pageInfo: existing.pageInfo,
        }
      );
    },
  },
};
