import { getRefetchScreenCountQuery } from "src/hooks/entities/castedScreen/refetchScreenCount";

/**
 * Castable are Channel, Playlist, Link, AppInstances
 * This is a relatively cheap operation.
 * We can just refetch this everytime we delete some entity.
 * Imagine if we perform delete all on something with multiple pagination
 * We do not want to check each entity if it is currently being casted to a screen or not
 * We can optimize this later if we found out that this is slow
 * According to apollo documentation,
 * Perform refetchqueries is preferred over calling query manually
 */
export const getOnCastableDeletedRefetchQueries = () => {
  return [getRefetchScreenCountQuery()];
};
