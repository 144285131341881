import {
  File,
  FolderByIdDocument,
  FolderByIdQuery,
  FolderByIdQueryVariables,
  UpdateFileByIdMutation,
  UpdateFileByIdMutationVariables,
  useUpdateFileByIdMutation,
} from "../../../types.g";
import { cloneDeep } from "lodash";
import { MediaOrderBy, Typenames } from "../../../constants/constants";
import { DataValue } from "@apollo/client/react/hoc";

interface RenameFileParams {
  file: File;
  updatedName: string;
}

export interface RenameFile {
  renameFile: (params: RenameFileParams) => Promise<any>;
}

export function useRenameFile() {
  const [updateFileByIdMutation] = useUpdateFileByIdMutation();
  const renameFile = ({ file, updatedName }: RenameFileParams) => {
    const updateFile: UpdateFileByIdMutationVariables = {
      input: {
        fileId: file.id,
        folderId: file.folderId,
        name: updatedName,
      },
    };
    return updateFileByIdMutation({
      optimisticResponse: {
        __typename: Typenames.Mutation,
        updateFileById: {
          __typename: Typenames.UpdateFileByIdPayload,
          file: {
            ...file,
            __typename: Typenames.File,
            name: updatedName,
          },
        },
      },
      update: (proxy, data: { data: UpdateFileByIdMutation }) => {
        const queryVar: FolderByIdQueryVariables = {
          folderId: file.folderId,
          fileOrderBy: [MediaOrderBy.FILE_CREATED_AT_DESC],
          folderOrderBy: [MediaOrderBy.FOLDER_CREATED_AT_DESC],
          endCursor: null,
        };

        const cacheData = proxy.readQuery({
          query: FolderByIdDocument,
          variables: queryVar,
        }) as DataValue<FolderByIdQuery>;
        const cloneCacheData = cloneDeep(cacheData);
        if (cloneCacheData?.folderById) {
          cloneCacheData.folderById.filesByFolderId.nodes = cloneCacheData.folderById.filesByFolderId.nodes.map(
            (fileItem) => {
              return fileItem.id !== file.id
                ? fileItem
                : { ...fileItem, name: updatedName };
            }
          );
          proxy.writeQuery({
            data: cloneCacheData,
            query: FolderByIdDocument,
            variables: queryVar,
          });
        }
      },
      variables: updateFile,
    });
  };

  return { renameFile };
}
