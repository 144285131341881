import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledDroppableContainer = styled.div``;

export const Styled = styled.div`
  height: 100%;

  .ui.button {
    &.add-link {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 20px;
      padding: 0;
      top: 3px;

      i {
        background-color: ${Theme.color.white};
        width: 14px;
      }

      :hover,
      :active {
        i {
          background-color: ${Theme.color.white};
        }
      }
    }
  }

  .empty-link > p {
    font-size: 0.875em;
    color: rgba(31, 32, 39, 0.5);
  }

  .layout-container {
    position: relative;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
  }

  .link-section {
    align-items: center;
    display: flex;
    font-weight: 700;
    height: 46px;
    margin: 0 !important;
    padding: 0 20px 10px;
  }

  .link-list {
    list-style: none;
    padding-left: 0;
    padding-bottom: 55px;
    margin-top: 0;
    margin-bottom: 0;
    .link-item {
      padding: 0 20px;
    }
  }
`;
