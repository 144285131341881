import { getOnCastableDeletedRefetchQueries } from "src/domains/castableEntity/events/onCastableEntityDeleted";
import { useOnChannelDeleted } from "src/domains/channel/events/useOnChannelDeleted";
import {
  DeleteChannelMutationVariables,
  useDeleteChannelMutation,
} from "../types.g";

export interface UseDeleteChannel {
  deleteChannelById: (channelId: string) => Promise<any>;
}

export function useDeleteChannel() {
  const [deleteChannelMutation] = useDeleteChannelMutation();
  const { onChannelDeleted } = useOnChannelDeleted();
  const deleteChannelById = async (channelId: string) => {
    const deleteFileByIdMutationVariables: DeleteChannelMutationVariables = {
      input: {
        id: channelId,
      },
    };

    const deletedResult = deleteChannelMutation({
      update: (_, { data }) => {
        const channel = data?.deleteChannel?.channel;
        if (channel) {
          onChannelDeleted({ channel });
        }
      },
      variables: deleteFileByIdMutationVariables,
      refetchQueries: getOnCastableDeletedRefetchQueries(),
    });
    return deletedResult;
  };
  return { deleteChannelById };
}
