import {
  File,
  AllTagsDocument,
  AllTagsQuery,
  UpdateFileByIdMutationVariables,
  useUpdateFileByIdMutation,
} from "../../../types.g";
import { cloneDeep, uniq } from "lodash";
import { appendNewTags } from "../../../helpers/tagHelper";
import { useAppContext } from "../../../hooks/useAppContext";

export type FileForAddTagsToFile = Pick<File, "id" | "tags" | "folderId">;

interface AddTagProps {
  file: FileForAddTagsToFile;
  tagsToAdd: string[];
}

/**
 * This can only be use to add tag and not delete tag
 */
export function useAddTagsToFileMutation() {
  const context = useAppContext();
  const [updateFileByIdMutation] = useUpdateFileByIdMutation();

  const addTags = ({ file, tagsToAdd }: AddTagProps) => {
    const newTags = uniq([...(file.tags ?? []), ...tagsToAdd]).sort();

    const updateMediaFileVariable: UpdateFileByIdMutationVariables = {
      input: {
        fileId: file.id,
        tags: newTags,
      },
    };

    return updateFileByIdMutation({
      variables: updateMediaFileVariable,
      update: (cache, { data }) => {
        // Read the data from our cache for this query.
        const cacheAllTags = cloneDeep(
          cache.readQuery<AllTagsQuery>({ query: AllTagsDocument })
        );

        if (data?.updateFileById?.file?.tags && cacheAllTags?.allTags) {
          const updatedFileTags = cloneDeep(data.updateFileById.file.tags);
          const existingTags = cacheAllTags.allTags.nodes;
          const updatedCacheAllTags = appendNewTags(
            context.currentOrg?.id,
            context.currentSpace?.id,
            updatedFileTags,
            existingTags
          );
          cacheAllTags.allTags.nodes = (updatedCacheAllTags ?? []).map(
            (tag) => {
              return {
                ...tag,
                __typename: "Tag" as "Tag",
                orgId: tag!.orgId!,
                name: tag!.name!,
              };
            }
          );
          cache.writeQuery({ query: AllTagsDocument, data: cacheAllTags });

          cache.modify({
            id: cache.identify(data?.updateFileById?.file),
            fields: {
              tags() {
                return newTags;
              },
            },
          });
        }
      },
    });
  };

  return { addTags };
}
