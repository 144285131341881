import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import gradientBG from "src/images/img_gradient_txt.png";
import scosDevice from "src/images/store/station-p1-pro.png";
import scosTop from "src/images/store/station-p1-top.jpg";
import scosSide from "src/images/store/station-p1-side.jpg";
import inBox1 from "src/images/store/whats-included-1.svg";
import inBox2 from "src/images/store/whats-included-2.svg";
import inBox3 from "src/images/store/whats-included-3.svg";
import inBox4 from "src/images/store/whats-included-4.svg";
import inBox5 from "src/images/store/whats-included-5.svg";
import scosLogo from "src/images/store/scos-logo.png";

export const Styled = styled.div`
  width: 100%;
  background: var(--color-white-100);
  color: var(--color-white-100);
  height: 100%;
  overflow: auto;
  min-width: 375px;
  border-radius: 8px;
  position: relative;

  .button.custom-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }
  @keyframes gradient {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @keyframes device {
    0% {
      background-position: 300px center;
      opacity: 0;
      background-size: 180px;
    }
    75% {
      background-position: center;
      opacity: 1;
      background-size: 380px;
    }
    90% {
      background-position: center;
      opacity: 1;
      background-size: 370px;
    }
    100% {
      background-position: center;
      opacity: 1;
      background-size: 360px;
    }
  }

  @keyframes device-sm {
    0% {
      background-position: 40px top;
      opacity: 0;
      background-size: 80px;
    }
    75% {
      background-position: 60px 40px;
      opacity: 1;
      background-size: 260px;
    }
    90% {
      background-position: 60px 40px;
      opacity: 1;
      background-size: 250px;
    }
    100% {
      background-position: 60px 40px;
      opacity: 1;
      background-size: 240px;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: Sora, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  figure {
    margin: 0;
  }

  span {
    font-family: inherit;
  }

  h1 {
    font-size: 45px;
    font-weight: bold;
    line-height: 52px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-grey60);
  }

  .section {
    background: var(--color-grey100);
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 60px;
    gap: 60px;

    .column {
      display: flex;
      gap: 20px;
      flex-direction: column;
      width: 100%;
      order: 2;
      flex: 4;
      z-index: 1;

      &.device {
        background: url(${scosDevice}) no-repeat;
        margin: 0;
        height: 360px;
        min-height: 240px;
        order: 3;
        flex: 3;
        animation: device 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1 normal
          forwards;
      }
    }

    .button {
      align-self: start;
    }

    &.hero {
      color: var(--color-grey100);
      padding: 0;
      gap: 0;
      align-items: center;
      overflow: hidden;
      position: relative;

      :before {
        content: "";
        position: absolute;
        width: 190%;
        height: 190%;
        background: url(${gradientBG}) no-repeat;
        filter: blur(12px);
        background-size: cover;
        animation: gradient 32s infinite linear;
        z-index: 0;
      }

      .column {
        padding: 60px;
      }

      p {
        color: var(--color-grey80);
      }
    }

    &.feature {
      flex-direction: column;
    }

    &.banner {
      .column {
        padding: 40px;
        background: var(--color-grey90);
        width: 100%;
        gap: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(185px, max-content));
      }

      figure[class^="device"] {
        width: 185px;
        height: 130px;
      }

      .device-1 {
        background: url(${scosTop}) no-repeat;
        background-size: 100%;
      }

      .device-2 {
        background: url(${scosSide}) no-repeat;
        background-size: 100%;
      }

      .tagline {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .heading {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &.information {
      flex-direction: column;

      .column {
        gap: 10px;

        &.content-indent {
          .table,
          p {
            padding-left: 40px;
          }
        }
      }

      .box {
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      }

      .card {
        display: flex;
        gap: 10px;
        flex-direction: column;
        text-align: center;

        figure {
          &[class^="inbox"] {
            background-size: contain;
            background-position: center;
            height: 180px;
          }
        }

        p {
          font-family: Sora, sans-serif;
          font-size: 14px;
          color: var(--color-white-100);
        }

        .inbox1 {
          background: #1f1f21 url(${inBox1}) no-repeat;
        }

        .inbox2 {
          background: #1f1f21 url(${inBox2}) no-repeat;
        }

        .inbox3 {
          background: #1f1f21 url(${inBox3}) no-repeat;
        }

        .inbox4 {
          background: #1f1f21 url(${inBox4}) no-repeat;
        }

        .inbox5 {
          background: #1f1f21 url(${inBox5}) no-repeat;
        }
      }
      .table {
        .row {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
          border-bottom: 1px solid var(--color-white10);
          padding: 30px 0;
          gap: 20px;

          .label {
            font-family: Sora, sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: var(--color-white-100);
            max-width: 240px;
          }

          .value {
            span {
              color: var(--color-grey50);
            }

            ul,
            ol {
              padding-left: 20px;
              margin: 0;
            }

            > ul {
              list-style: none;
              padding: 0;

              li::marker {
                color: var(--color-grey50);
              }

              ol {
                list-style-type: lower-alpha;
              }
            }
          }
        }
      }
    }

    &.footer {
      .scos-logo {
        background: url(${scosLogo}) no-repeat;
        background-size: 100%;
        width: 320px;
        height: 160px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .section {
      padding: 40px;

      &.hero {
        padding: 0;
        flex-direction: column;

        .column {
          padding: 0 40px 40px;
        }

        .device {
          animation: device-sm 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1
            normal forwards;
          height: 200px;
          order: 1;
        }
      }

      &.information {
        .column.content-indent {
          .table,
          p {
            padding-left: 20px;
          }
        }

        .table {
          .row {
            gap: 10px;
          }
        }
      }

      &.banner {
        padding: 40px 0;

        .column {
          gap: 20px;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    h1 {
      font-size: 32px;
      line-height: 40px;
    }

    h2 {
      font-size: 28px;
      line-height: 36px;
    }

    h3 {
      font-size: 22px;
      line-height: 30px;
    }

    h4 {
      font-size: 18px;
      line-height: 26px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }

    .section {
      padding: 40px 20px;
      gap: 40px;

      &.hero {
        padding: 0;

        .column {
          padding: 20px;
        }
      }

      &.information {
        .table {
          .row {
            gap: 10px;
            padding: 20px 0;
          }
        }
      }
    }
  }
`;
