import { useSearchAllSiteList } from "src/pages/Sites/hooks/useSearchSites";
import { ssm } from "../../state/session/ssm";
import { compose } from "../../utils/compose";
import { UseAllSites, useAllSites } from "src/pages/Sites/hooks/useAllSites";
import { SitePickerComponentProps } from ".";
import { UseStateSearch, useStateSearch } from "src/hooks/useStateSearch";

export interface ApolloProps extends UseAllSites, UseStateSearch {}

export const withData = compose(
  (Component) => (props: SitePickerComponentProps) => {
    const searchState = useStateSearch();
    return <Component {...props} {...searchState} />;
  },
  (Component) => (props: SitePickerComponentProps & UseStateSearch) => {
    const sites = useAllSites({
      spaceId: props.spaceId ?? ssm.current.settings.spaceId,
      filter: {},
      skip: !!props.searchString,
    });
    return <Component {...props} {...sites} />;
  },
  (Component) => (props: SitePickerComponentProps & UseStateSearch) => {
    const sites = useSearchAllSiteList(props.query);
    if (props.searchString) {
      return <Component {...props} {...sites} />;
    } else {
      return <Component {...props} />;
    }
  }
);
