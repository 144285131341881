import { Colors, DEFAULT_FONT } from "../../../constants/constants";
import { ThemeConfig } from "../../../pages/Admin/Themes";
import { Styled } from "./styles";

const ThemePreview = ({
  primaryColor,
  secondaryColor,
  textOnPrimary,
  textOnSecondary,
  headingFont,
  bodyFont,
}: ThemeConfig) => {
  return (
    <Styled>
      <div className="preview" style={{ backgroundColor: primaryColor[500] }}>
        <div className="preview-text">
          <span
            style={{
              color: textOnPrimary[500] || Colors.WHITE,
              fontFamily: headingFont?.family || DEFAULT_FONT.family,
            }}
          >
            A{" "}
          </span>
          <span
            style={{
              color: textOnSecondary[500] || Colors.WHITE,
              fontFamily: bodyFont?.family || DEFAULT_FONT.family,
            }}
          >
            a
          </span>
        </div>
        <div
          className="preview-line"
          style={{ backgroundColor: secondaryColor[500] }}
        />
      </div>
    </Styled>
  );
};

export default ThemePreview;
