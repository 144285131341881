import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { getScreenStatus } from "../../helpers/screenHelper";
import {
  CastByCastIdFragment,
  Maybe,
  Screen,
  ScreenCastStopMutationVariables,
} from "../../types.g";
import ScreenItemList, { ScreenItemActions } from "../ScreenItemList";
import { ApolloProps, withData } from "./apollo";
import { Styled } from "./styles";
import { isAllowClearCacheByDevicePlatform } from "../../domains/screens/helpers/actions";
export interface CastingScreensProps {
  castId: string;
  children?: React.ReactNode;
}

class CastingScreens extends React.Component<
  CastingScreensProps & ApolloProps,
  {}
> {
  public static contextType = AppContext;
  public context: AppContextType;

  public handleScreenActions = async (
    screenId: string,
    action: ScreenItemActions,
    castItem?: Maybe<CastByCastIdFragment>
  ) => {
    switch (action) {
      case ScreenItemActions.CastStop:
        const castStopVar: ScreenCastStopMutationVariables = {
          input: {
            screenId,
          },
        };
        await this.props.screenCastStop(castStopVar, castItem);
        break;
      case ScreenItemActions.View:
        this.context.history.push("/screens/" + screenId);
        this.context.modal.closeNavigationControlModal();
        break;
      default:
        break;
    }
  };

  public render() {
    const castedScreens =
      this.props.castById &&
      this.props.castById.castById &&
      this.props.castById.castById.screensByCastId.nodes.map(
        (screen, index) => {
          const isAllowClearCache = isAllowClearCacheByDevicePlatform(screen);

          const isLastScreenIndex =
            index ===
            (this.props.castById.castById?.screensByCastId.nodes ?? []).length -
              1;
          return (
            <ScreenItemList
              isVisible={true}
              isSelected={false}
              className="screen"
              callback={this.handleScreenActions}
              key={`screenlist-${screen.id}`}
              url={`/screens/${screen.id}`}
              name={screen.name}
              disabled={true}
              model={screen.deviceModel ?? ""}
              platform={screen.devicePlatform ?? ""}
              player_width={screen.playerWidth ?? undefined}
              player_height={screen.playerHeight ?? undefined}
              playerTimezone={screen.playerTimezone}
              overrideTimezone={screen.timezoneOverride}
              additionalPlayerSettings={screen.additionalPlayerSettings}
              status={getScreenStatus(
                screen,
                this.context.subscription?.status
              )}
              screenId={screen.id}
              currentChannelId={""}
              preview={screen.preview}
              tags={screen.tags ? (screen.tags as string[]) : []}
              castByCastId={screen.castByCastId}
              isAllowClearCache={isAllowClearCache}
              screen={screen as Screen}
              shouldShowOptionUpward={isLastScreenIndex ?? false}
            />
          );
        }
      );

    return (
      <Styled>
        <div className="screen-item-header">
          <div className="column-name">
            <FormattedMessage id="subheader.label.name" defaultMessage="Name" />
          </div>
          <div className="column-status">
            <FormattedMessage
              id="subheader.label.status"
              defaultMessage="Status"
            />
          </div>
        </div>
        {castedScreens}
      </Styled>
    );
  }
}

export default withData(
  CastingScreens
) as React.ComponentType<CastingScreensProps>;
