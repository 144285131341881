import { DataValue } from "@apollo/client/react/hoc";
import { RefType } from "../../constants/constants";
import {
  AppInstanceByIdForContentQuery,
  AppInstanceByIdForContentQueryVariables,
  FileByIdForContentProps,
  FileByIdForContentQueryVariables,
  LinkByIdForContentQuery,
  LinkByIdForContentQueryVariables,
  SiteByIdForContentQuery,
  SiteByIdForContentQueryVariables,
  PlaylistByIdForContentQuery,
  PlaylistByIdForContentQueryVariables,
  withAppInstanceByIdForContent,
  withFileByIdForContent,
  withLinkByIdForContent,
  withSiteByIdForContent,
  withPlaylistByIdForContent,
} from "../../types.g";
import { compose } from "../../utils/compose";

export interface ChannelContentItemProps extends FileByIdForContentProps {
  id: string;
  type: string;
  playlistById?: DataValue<
    PlaylistByIdForContentQuery,
    PlaylistByIdForContentQueryVariables
  >;
  linkById?: DataValue<
    LinkByIdForContentQuery,
    LinkByIdForContentQueryVariables
  >;
  siteById?: DataValue<
    SiteByIdForContentQuery,
    SiteByIdForContentQueryVariables
  >;
  appInstanceById?: DataValue<
    AppInstanceByIdForContentQuery,
    AppInstanceByIdForContentQueryVariables
  >;
}

export const withData = compose(
  withFileByIdForContent({
    options: (props: ChannelContentItemProps) => {
      const variables: FileByIdForContentQueryVariables = {
        id: props.id,
      };
      return {
        fetchPolicy: "cache-first",
        variables,
      };
    },
    skip: ({ type }: ChannelContentItemProps) => type !== RefType.FILE,
  }),
  withPlaylistByIdForContent({
    name: "playlistById",
    options: (props: ChannelContentItemProps) => {
      const variables: PlaylistByIdForContentQueryVariables = {
        id: props.id,
      };
      return {
        fetchPolicy: "cache-first",
        variables,
      };
    },
    skip: ({ type }: ChannelContentItemProps) => type !== RefType.PLAYLIST,
  }),
  withLinkByIdForContent({
    name: "linkById",
    options: (props: ChannelContentItemProps) => {
      const variables: LinkByIdForContentQueryVariables = {
        id: props.id,
      };
      return {
        fetchPolicy: "cache-first",
        variables,
      };
    },
    skip: ({ type }: ChannelContentItemProps) => type !== RefType.LINK,
  }),
  withSiteByIdForContent({
    name: "siteById",
    options: (props: ChannelContentItemProps) => {
      const variables: SiteByIdForContentQueryVariables = {
        id: props.id,
      };
      return {
        fetchPolicy: "cache-first",
        variables,
      };
    },
    skip: ({ type }: ChannelContentItemProps) => type !== RefType.SITE,
  }),
  withAppInstanceByIdForContent({
    name: "appInstanceById",
    options: (props: ChannelContentItemProps) => {
      const variables: AppInstanceByIdForContentQueryVariables = {
        id: props.id,
      };
      return {
        fetchPolicy: "cache-first",
        variables,
      };
    },
    skip: ({ type }: ChannelContentItemProps) => type !== RefType.APP,
  })
);
