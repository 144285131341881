import { LoaderBar, Button } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import EmptyState from "src/components/EmptyState";

interface ScreenPickerEmptyStateProps {
  isSearch: boolean;
  loading: boolean;
  clearSearch: () => void;
  onAddScreen: () => void;
}

export function ScreenPickerEmptyState({
  isSearch,
  loading,
  clearSearch,
  onAddScreen,
}: ScreenPickerEmptyStateProps) {
  if (loading) {
    return <LoaderBar />;
  }

  if (isSearch) {
    return (
      <EmptyState section="screen" cover={false}>
        <p>
          <FormattedMessage
            id="common.search.no_results"
            defaultMessage="We couldn't find anything matching your search."
          />
        </p>
        <Button onClick={clearSearch}>
          <FormattedMessage
            id="common.search.clear"
            defaultMessage="Clear Search"
          />
        </Button>
      </EmptyState>
    );
  }

  return (
    <EmptyState section="screen-picker" cover={false}>
      <h3>
        <FormattedMessage
          id="screens.pairing.no_screens_message"
          defaultMessage="No screens paired yet"
        />
      </h3>
      <p>
        <FormattedMessage
          id="screens.pairing.help_message"
          defaultMessage="Why not pair a screen so you can begin playing content?"
        />
      </p>
      <Button onClick={onAddScreen}>
        <FormattedMessage
          id="screens.button.add_new_screen"
          defaultMessage="Add New Screen"
        />
      </Button>
    </EmptyState>
  );
}
