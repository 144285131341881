import { ThemeListQuery, useThemeListQuery } from "../../types.g";
import { compose } from "../../utils/compose";
export interface ApolloProps {
  allThemes: ThemeListQuery["allThemes"];
  loading: boolean;
}

export const withData = compose((Component) => (props: ApolloProps) => {
  const themeList = useThemeListQuery({
    fetchPolicy: "cache-and-network",
  });
  return (
    <Component
      {...props}
      loading={themeList.loading}
      allThemes={themeList.data?.allThemes}
    />
  );
});
