import { FormattedMessage } from "react-intl";
import {
  Channel,
  DuplicateJobType,
  GetImportJobsDocument,
  MutationCreateDuplicateJobsArgs,
  SnapshotType,
  useCreateDuplicateJobsMutation,
} from "src/types.g";
import { useAppContext } from "src/hooks/useAppContext";
import { getDuplicateChannelName } from "./utils";
import { useOpenDuplicationModal } from "./useOpenDuplicationModal";
import DuplicateContentModal from "src/components/Duplication/DuplicateContentModal";
import { useEffect, useState } from "react";

export interface UseDuplicateChannel {
  duplicateChannel: () => Promise<void>;
}

export function useDuplicateChannel(
  channel: Pick<Channel, "id" | "name">
): UseDuplicateChannel {
  const context = useAppContext();
  const [selectedSpaceIds, setSelectedSpaceIds] = useState<string[]>([]);
  const [confirmForm, setConfirmForm] = useState<boolean>(false);
  const [createDuplicateJobs] = useCreateDuplicateJobsMutation();
  const { openInprogressModalDetail } = useOpenDuplicationModal(
    SnapshotType.Channel
  );
  const onSpaceSelected = (targetSpacesId: string[]) =>
    setSelectedSpaceIds(targetSpacesId);
  const selectableSpaces = context.allSpaces.filter(
    (space) => space.id !== context.currentSpace?.id
  );

  const duplicateChannel = async () => {
    const { confirm } = await context.modal.confirm(
      <DuplicateContentModal
        spaces={selectableSpaces}
        contentName={channel.name}
        onSpaceSelected={onSpaceSelected}
        snapshotType={SnapshotType.Channel}
      />,
      {
        confirm: (
          <FormattedMessage
            id="ui_component.common.label.continue"
            defaultMessage="Continue"
          />
        ),
        cancel: (
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        ),
      }
    );
    setConfirmForm(confirm);
  };

  useEffect(() => {
    if (confirmForm) {
      const snapshotInput: MutationCreateDuplicateJobsArgs = {
        input: {
          jobType: DuplicateJobType.SnapshotAndImport,
          sourceSpaceId: context.currentSpace?.id,
          sourceContentId: channel.id,
          snapshotType: SnapshotType.Channel,
          isPublicSnapshot: false,
          targetSpaceIds: selectedSpaceIds,
          targetNewName: getDuplicateChannelName(channel.name),
        },
      };
      createDuplicateJobs({
        variables: snapshotInput,
        refetchQueries: [
          {
            query: GetImportJobsDocument,
            variables: {
              snapshotType: SnapshotType.Channel,
              sourceSpaceId: context.currentSpace?.id,
            },
          },
        ],
      });
      openInprogressModalDetail();
      context.modal.closeNavigationControlModal();
      setConfirmForm(false);
    }
  }, [confirmForm]);

  return { duplicateChannel };
}
