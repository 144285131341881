import {
  DatetimeFormat,
  Icon,
  LoaderBar,
  Search,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { SortingActions } from "../../constants/constants";
import { appInstanceIconUrl } from "../../helpers/appHelper";
import { updateAndToggleSelectedItems } from "../../helpers/updateAndToggleSelectedItemsHelper";
import { AppInstance } from "../../types.g";
import DateTime from "../DateTime";
import EmptyState from "../EmptyState";
import { CanvasAppProps, withData } from "./apollo";
import SearchAppPicker from "./search";
import { AppPickerItem, Styled } from "./styles";

export interface CanvasContentPickerProps {
  isMultipleSelect: boolean | undefined;
  spaceId?: UUID;
  callback: (ids: string[], selectedApps: AppInstance[]) => void;
}
export interface AppContentPickerState {
  isOrderByAscending: boolean;
  selectedIds: string[];
  selectedApps: AppInstance[];
  sortBy: SortingActions;
  isFetchMore: boolean;
}

class CanvasPicker extends React.PureComponent<
  CanvasContentPickerProps & CanvasAppProps,
  AppContentPickerState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: CanvasContentPickerProps & CanvasAppProps) {
    super(props);
    this.state = {
      isFetchMore: false,
      isOrderByAscending: true,
      selectedApps: [],
      selectedIds: [],
      sortBy: SortingActions.SORT_BY_NAME,
    };
  }

  public onToggleSelection = (app: any) => {
    if (this.props.data.appInstances) {
      const appInstances = this.props.data.appInstances;
      const { selectedArray } = updateAndToggleSelectedItems(
        app.id,
        app,
        this.state.selectedIds,
        this.state.selectedApps,
        this.props.isMultipleSelect
      );
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            selectedIds: selectedArray,
          };
        },
        () => {
          const selectedApps = this.state.selectedIds.reduce(
            (allSelectedApps: AppInstance[], currentId) => {
              const appsId = appInstances.find(
                (appInstance) => appInstance.id === currentId
              );
              return [...allSelectedApps, appsId];
            },
            []
          );
          this.props.callback(
            this.state.selectedIds,
            selectedApps as AppInstance[]
          );
        }
      );
    }
  };

  public toggleSortAndOrderMediaItem = (action: SortingActions) => {
    if (this.state.sortBy === action) {
      this.setState((prevState) => ({
        ...prevState,
        isOrderByAscending: !prevState.isOrderByAscending,
        sortBy: action,
      }));
    } else {
      this.setState({ isOrderByAscending: true, sortBy: action });
    }
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public renderAppInstanceItems = (): JSX.Element[] => {
    if (this.props.data.appInstances) {
      const appItemFiltered = this.props.data.appInstances;
      return appItemFiltered.map((node) => {
        const app = node!;
        const iconUrl = appInstanceIconUrl((app as unknown) as AppInstance);
        return (
          <AppPickerItem
            className="media-item"
            key={`apps-${app.id}`}
            onClick={() => this.onToggleSelection(app)}
            selected={this.state.selectedIds.includes(app.id)}
          >
            <div className="media-core">
              <div className="media-alpha">
                <div className="thumbnail-preview">
                  <div className="thumbnail">
                    <div className="wrapper">
                      <img src={iconUrl} />
                    </div>
                  </div>
                </div>
                <div className="media-title">
                  <h3>{app.name}</h3>
                  <div>
                    <span className="media-item__date">
                      <FormattedMessage
                        id="common.text.created_on"
                        defaultMessage="Created on"
                      />{" "}
                      <DateTime
                        value={app.createdAt}
                        format={DatetimeFormat.Long}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="media-app-customs">
                {/* add app customs here */}
              </div>

              <div className="media-application">
                <span>{app.appByAppId!.name}</span>
              </div>
            </div>
            <div className="media-checked">
              <Icon name="checked-circle" />
            </div>
          </AppPickerItem>
        );
      });
    } else {
      return [];
    }
  };

  public render() {
    const data = this.props.data;
    const canvasses = data.appInstances ?? [];
    const { searchString, updateSearchString, clearSearch, query } = this.props;
    return (
      <Styled className="media-content apps">
        <div className="content-header">
          <Search
            className="search"
            onClear={clearSearch}
            onChange={(_, data) => updateSearchString(data.value)}
            placeholder="Search Canvas"
            showNoResults={false}
            value={searchString}
          />
        </div>

        <div className="container">
          <div className="media-item-header">
            <div className="media-core">
              <div
                className="media-alpha"
                onClick={() =>
                  this.toggleSortAndOrderMediaItem(SortingActions.SORT_BY_NAME)
                }
              >
                <FormattedMessage
                  id="subheader.label.instance_name"
                  defaultMessage="Instance Name"
                />
                {this.state.sortBy === SortingActions.SORT_BY_NAME &&
                  this.renderOrderCaretIcon()}
              </div>
              <div className="media-app-customs">
                <FormattedMessage
                  id="subheader.label.customs"
                  defaultMessage="Customs"
                />
              </div>
              <div className="media-application">
                <FormattedMessage
                  id="subheader.label.app"
                  defaultMessage="Apps"
                />
              </div>
            </div>
          </div>
          <div className="layout-list" id="scrollableDiv">
            {searchString.length > 0 ? (
              <SearchAppPicker
                isMultipleSelect={this.props.isMultipleSelect}
                callback={this.props.callback}
                query={query}
                appId={this.context.canvasAppId}
                spaceId={this.props.spaceId}
              />
            ) : (
              <>
                {this.renderAppInstanceItems()}
                {this.props.data.renderFetchMoreButton}
              </>
            )}
          </div>
          {canvasses.length === 0 && !data.loading ? (
            <EmptyState section="app-picker" cover={false} className="empty">
              <h3>
                <FormattedMessage
                  id="canvas.empty.no_content"
                  defaultMessage="No Canvas added yet"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="canvas.empty.add_content"
                  defaultMessage="Make a Canvas to be able to add this content"
                />
              </p>
            </EmptyState>
          ) : null}
        </div>
        {data.loading && <LoaderBar />}
      </Styled>
    );
  }
}

export default withData(CanvasPicker);
