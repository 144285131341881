import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Dropdown, ModalSize } from "@screencloud/screencloud-ui-components";
import { Space } from "src/types.g";
import {
  isSupportEmbededLink,
  Shareable,
} from "../../../helpers/shareableHelper";
import { useAppContext } from "src/hooks/useAppContext";
import { EmbedShareOptions } from "../index";
import { PreviewType } from "src/components/PlayerPreview";
import EmbedPreview from "src/components/EmbedPreview";
import { CopyEmbed } from "./EmbedLink";
import { getEmbedStatus } from "../helper";
import { useInitialEmbedShareOption } from "../hooks/useEmbed";

export interface ShareFooterProps {
  allSpaces: Pick<Space, "id" | "name" | "preferences" | "billingCustomerId">[];
  isDisabled: boolean;
  embededUrl: string;
  shareable: Shareable;
  sharedSpaces: Space[];
  onShareAllChange?: (isShareAll: boolean) => void;
  onEmbedChange?: (embededStatus: {
    isEmbedEnabled: boolean;
    isEmbedPublic: boolean;
  }) => void;
  embedShortId?: string;
}

const ShareFooter = ({
  allSpaces,
  isDisabled,
  embededUrl,
  shareable,
  sharedSpaces,
  onShareAllChange,
  onEmbedChange,
}: ShareFooterProps) => {
  const context = useAppContext();
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const { embedSharedOption } = useInitialEmbedShareOption(shareable);

  const [selectedEmbed, setSelectedEmbed] = useState<EmbedShareOptions>(
    embedSharedOption
  );

  const handleEmbedShareDropdownChange = (
    e,
    d: { value: EmbedShareOptions }
  ) => {
    const { value: embedSharedOption } = d;

    setIsLinkCopied(false);
    setSelectedEmbed(d.value);
    onEmbedChange?.(getEmbedStatus(embedSharedOption));
  };

  const openEmbedSharedChannel = () => {
    const previewType =
      shareable.__typename === "Channel"
        ? PreviewType.CHANNEL
        : shareable.__typename === "Playlist"
        ? PreviewType.PLAYLIST
        : "";

    if (!previewType) {
      console.error("Invalid preview type");
      return;
    }

    if (embededUrl !== "") {
      context.modal.openNavigationControlModal(
        <EmbedPreview
          id={shareable.id}
          previewType={previewType}
          embededUrl={embededUrl}
        />,
        "Embed Preview",
        {
          opts: {
            className: "md-embed-player",
            size: ModalSize.SMALL,
            disableTitle: false,
            overflow: true,
            closeOnDimmerClick: false,
          },
        }
      );
    }
  };

  return (
    <>
      {isSupportEmbededLink({ shareable, context }) && (
        <div
          className="share-footer"
          style={{
            minHeight: (allSpaces.length ?? 0) > 1 ? "auto" : "120px",
          }}
        >
          <div className="option">
            {(allSpaces?.length ?? 0) > 1 && (
              <h3>
                <FormattedMessage
                  id="common.text.share_with_others"
                  defaultMessage="Share with others"
                />
                <span className="middot">·</span>
              </h3>
            )}

            <Dropdown
              fluid
              inline
              onChange={handleEmbedShareDropdownChange}
              disabled={isDisabled}
              value={selectedEmbed}
              options={[
                {
                  key: EmbedShareOptions.Public,
                  text: `Anyone with the link can view`,
                  value: EmbedShareOptions.Public,
                },
                {
                  key: EmbedShareOptions.Org,
                  text: (
                    <>
                      Anyone in your <strong>{context.currentOrg?.name}</strong>{" "}
                      account can view
                    </>
                  ),
                  value: EmbedShareOptions.Org,
                },
                {
                  key: EmbedShareOptions.Disabled,
                  text: (
                    <FormattedMessage
                      id="common.text.disable_link"
                      defaultMessage="Disable Link"
                    />
                  ),
                  value: EmbedShareOptions.Disabled,
                },
              ]}
            />
          </div>

          <CopyEmbed
            embedSharedOption={selectedEmbed}
            embededUrl={embededUrl}
            openEmbedSharedChannel={openEmbedSharedChannel}
            isLinkCopied={isLinkCopied}
            setIsLinkCopied={setIsLinkCopied}
          />
        </div>
      )}
    </>
  );
};

export { ShareFooter };
