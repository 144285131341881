import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

type Props = {
  primaryColor?: string;
  secondaryColor?: string;
};

export const Styled = styled.div<Props>`
  .preview {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 98px;
    align-items: center;
    font-size: 42px;
    justify-content: center;
    position: relative;

    border-radius: var(--radius-md);
    border: 1px solid var(--color-gray-100, #e2e3e5);
    box-shadow: 0px 0px 0px 4px #fff inset,
      0px 0px 4px 4px rgba(0, 0, 0, 0.25) inset,
      0px 2px 4px 0px var(--color-black-10, rgba(0, 0, 0, 0.1));
  }

  .preview-text {
    padding: 20px;
    margin-top: -10px;
  }

  .preview-line {
    position: absolute;
    bottom: 20px;
    flex-shrink: 0;
    height: 4px;
    width: 85%;
    border-radius: var(--radius-sm, 4px);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: ${(props) =>
      props.secondaryColor ? props.secondaryColor : Theme.color.yellow};
  }
`;
