import { Icon, Popover } from "@screencloud/screencloud-ui-components";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import spacetime, { Spacetime } from "spacetime";
import soft from "timezone-soft";

import { OffHourNoticeInfoStyled, SleepStatusStyled } from "./styles";

export const SleepStatus: React.FC = () => {
  return (
    <Popover
      data-testid="pro-tip"
      className="tips"
      trigger={
        <SleepStatusStyled className="sleep-pill">
          <Icon name="sleep" color="white" />
          <FormattedMessage
            defaultMessage="Sleep mode"
            id="ui_component.off_hour.sleep_mode"
          />
        </SleepStatusStyled>
      }
      content={
        <FormattedMessage
          defaultMessage="Operating Hours is enabled and the screen is in sleep mode."
          id="common.text.sleep.definition"
        />
      }
      inverted
    />
  );
};

export const OffHourNoticeInfo: React.FC = () => {
  return (
    <OffHourNoticeInfoStyled>
      <Icon name="info" color="white" />
      <FormattedMessage
        defaultMessage="Sleep mode"
        id="ui_component.off_hour.notice_info"
      />
    </OffHourNoticeInfoStyled>
  );
};

/**
 * * NOTE:
 * Most of the below util functions and types
 * are directly duplicated from `studio-player`
 */
export interface Operating {
  alwaysOn?: boolean;
  enable: boolean;
  day?: OperatingDay;
}

type WeekDay = "sun" | "mon" | "tue" | "wed" | "thu" | "fri" | "sat";

const WEEK_DAYS: WeekDay[] = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

interface ParsedDate {
  timestamp: number;
  hour: number;
  minute: number;
  minutesFromDayStart: number;
  secondFromDayStart: number;
  millisecondFromDayStart: number;
  weekDay: WeekDay;
}

interface TimeOptions {
  timezoneOverride?: string; // timezone value assigned to this player by the user
  timeOffset: number; // offset between device's time and screencloud time server in ms
  timelineControlOffset: number; // time offset in ms, used for providing playback controls functionality
}

interface OperatingTimeRange {
  start: number;
  end: number;
  enable: boolean;
}
interface OperatingDay {
  sun?: OperatingTimeRange;
  mon?: OperatingTimeRange;
  tue?: OperatingTimeRange;
  wed?: OperatingTimeRange;
  thu?: OperatingTimeRange;
  fri?: OperatingTimeRange;
  sat?: OperatingTimeRange;
}

const HOUR_DURATION_MS = 1000 * 60 * 60;

export const useIsInOperatingHour = (
  timeOptions: TimeOptions,
  screenOperating: Operating | undefined,
  spaceOperating: Operating | undefined
): boolean => {
  const _timeOptions: TimeOptions = {
    ...timeOptions,
    // * NOTE: `spacetime` accepts only formal timezone reference name called 'iana', so this 'soft' package will standardize unformal tz eg. 'CET' -> 'Europe/Berlin'
    timezoneOverride: timeOptions?.timezoneOverride
      ? soft(timeOptions.timezoneOverride)[0]?.iana
      : undefined,
  };
  const [isOperating, setIsOperating] = useState<boolean>(
    isInOperatingHours(_timeOptions, undefined, screenOperating, spaceOperating)
  );

  // Set timeout to check and update `isOperating` state according to current day's setting or at the end of
  // current day
  useEffect(() => {
    const targetOperatingDayRules: OperatingDay | null = getScreenActiveOperatingRules(
      screenOperating,
      spaceOperating
    );

    let operatingHoursTimeout: number | null = null;

    const setOperatingTimeout = (): void => {
      if (targetOperatingDayRules === null) {
        // screen is always on and no need to update operating state
        return;
      }

      const nowDate = parseDateInLocalTime(
        _timeOptions,
        playbackNowTimestamp(_timeOptions)
      );
      const endOfDay = getEndOfDay(_timeOptions);
      const startOfDay = getStartOfDay(_timeOptions);

      const timeoutVariants: number[] = [endOfDay];

      // below we're checking if operating hours have end and start setting and then figuring out what is the next
      //  closest point in time to check if screen should be active

      const targetDayStart = targetOperatingDayRules?.[nowDate.weekDay]?.start;
      if (targetDayStart !== undefined) {
        const targetHoursStartTimestamp = startOfDay + targetDayStart * 1000;
        if (targetHoursStartTimestamp > nowDate.timestamp) {
          timeoutVariants.push(targetHoursStartTimestamp);
        }
      }

      const targetDayEnd = targetOperatingDayRules?.[nowDate.weekDay]?.end;
      if (targetDayEnd !== undefined) {
        const targetHoursEndTimestamp = startOfDay + targetDayEnd * 1000;
        if (targetHoursEndTimestamp > nowDate.timestamp) {
          timeoutVariants.push(targetHoursEndTimestamp);
        }
      }

      const timeoutValue = Math.min(...timeoutVariants) - nowDate.timestamp;

      operatingHoursTimeout = window.setTimeout(() => {
        const newIsOperating = isInOperatingHours(
          _timeOptions,
          undefined,
          screenOperating,
          spaceOperating
        );
        if (newIsOperating !== isOperating) {
          setIsOperating(newIsOperating);
        } else {
          setOperatingTimeout();
        }
      }, timeoutValue);
    };

    setOperatingTimeout();

    return (): void => {
      if (operatingHoursTimeout !== null) {
        window.clearTimeout(operatingHoursTimeout);
      }
    };
  }, [screenOperating, spaceOperating, isOperating, _timeOptions]);

  useEffect(() => {
    const newIsOperating = isInOperatingHours(
      _timeOptions,
      undefined,
      screenOperating,
      spaceOperating
    );
    if (newIsOperating !== isOperating) {
      setIsOperating(newIsOperating);
    }
  }, [_timeOptions, screenOperating, spaceOperating, isOperating]);

  return isOperating;
};

function getUtcTime(timeOffset: number): number {
  return spacetime.now().epoch + (timeOffset || 0);
}

function combinedTimeOffset(options: TimeOptions): number {
  return options.timeOffset + options.timelineControlOffset;
}
/**
 * "Now" timestamp with time synchronization and playback control offsets.
 * Represents what player should think is "now" according to all the time and playback settings.
 * If you're working on playback related functionality, you should use this method any time you want to
 * know the "now" timestamp. Maker sure the `options` object is complete and up to date data from redux state.
 */
function playbackNowTimestamp(options: TimeOptions): number {
  return getUtcTime(combinedTimeOffset(options));
}

function getTimeZone(options: TimeOptions): string {
  const now = spacetime(playbackNowTimestamp(options));

  if (options && options.timezoneOverride) {
    const timeZoneTimestamp = now.goto(options.timezoneOverride);
    return timeZoneTimestamp.timezone().name;
  } else {
    return now.timezone().name;
  }
}

export function parseDateInLocalTime(
  options: TimeOptions,
  timestamp: number
): ParsedDate {
  const timeZone = getTimeZone(options);
  const spacetimeNow = spacetime(timestamp);
  const s = spacetimeNow.goto(timeZone);

  const millisecondFromDayStart =
    s.hour() * HOUR_DURATION_MS +
    s.minute() * 60000 +
    s.second() * 1000 +
    s.millisecond();
  const secondFromDayStart = Math.floor(millisecondFromDayStart / 1000);
  const minutesFromDayStart = Math.floor(secondFromDayStart / 60);

  return {
    hour: s.hour(),
    minute: s.minute(),
    minutesFromDayStart,
    secondFromDayStart,
    millisecondFromDayStart,
    timestamp,
    weekDay: WEEK_DAYS[s.day()],
  };
}

/**
 * Get a set of week days operating rules to be applied for a screen.
 * If null is returned - screen is always on
 */
export const getScreenActiveOperatingRules = (
  screenOperating: Operating | undefined,
  spaceOperating: Operating | undefined
): OperatingDay | null => {
  if (screenOperating && screenOperating.alwaysOn) {
    return null;
  } else if (
    screenOperating &&
    !screenOperating.alwaysOn &&
    screenOperating.enable &&
    screenOperating.day
  ) {
    return screenOperating.day;
  } else if (spaceOperating && spaceOperating.alwaysOn) {
    return null;
  } else if (
    spaceOperating &&
    !spaceOperating.alwaysOn &&
    spaceOperating.enable &&
    spaceOperating.day
  ) {
    return spaceOperating.day;
  } else {
    return null;
  }
};

/**
 * Figures out if the screen is operating at the targetTimestamp.
 */
const isInOperatingHours = (
  timeOptions: TimeOptions,
  targetTimestamp: number | undefined,
  screenOperating: Operating | undefined,
  spaceOperating: Operating | undefined
): boolean => {
  const now = targetTimestamp ?? playbackNowTimestamp(timeOptions);
  const nowDate = parseDateInLocalTime(timeOptions, now);

  const applyRules = (targetOperatingHours: OperatingDay): boolean => {
    const targetRule = targetOperatingHours[nowDate.weekDay];
    if (targetRule && !targetRule.enable) {
      return false;
    }

    const targetStartSecond = targetRule?.start;
    const targetEndSecond = targetRule?.end;

    return (
      (targetStartSecond === undefined ||
        targetStartSecond <= nowDate.secondFromDayStart) &&
      (targetEndSecond === undefined ||
        targetEndSecond > nowDate.secondFromDayStart)
    );
  };

  const activeOperatingRules = getScreenActiveOperatingRules(
    screenOperating,
    spaceOperating
  );

  if (activeOperatingRules === null) {
    return true;
  } else {
    return applyRules(activeOperatingRules);
  }
};

/**
 * Returns spacetime object with applied TimeOptions
 *
 * @param options
 * @param targetDate - strings should not contain timezone info, otherwise it will override timezoneOverride in options
 */
function targetSpacetime(
  options: TimeOptions,
  targetDate?: string | number
): Spacetime {
  if (!targetDate) {
    return spacetime(playbackNowTimestamp(options)).goto(getTimeZone(options));
  } else if (typeof targetDate === "number") {
    return spacetime(targetDate as number, getTimeZone(options));
  } else {
    return spacetime(targetDate as string, getTimeZone(options));
  }
}

function getEndOfDay(options: TimeOptions, targetDateInput?: string): number {
  return targetSpacetime(options, targetDateInput).endOf("day").epoch;
}

function getStartOfDay(options: TimeOptions, targetDateInput?: string): number {
  return targetSpacetime(options, targetDateInput).startOf("day").epoch;
}
