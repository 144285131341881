import { CastedScreensCountQuery } from "src/types.g";

export function getCastedScreenCount(
  castedScreenCount: CastedScreensCountQuery | undefined
): number {
  const castedNodes = castedScreenCount?.spaceById?.castsBySpaceId.nodes ?? [];
  const currentCastingScreens = castedNodes.reduce(
    (result, item) => result + (item?.screensByCastId?.totalCount ?? 0),
    0
  );
  return currentCastingScreens;
}
