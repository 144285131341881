import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import { WhiteLabelStyles } from "../../helpers/whiteLabel";

export const Styled = styled.div<WhiteLabelStyles>`
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 0px;
  box-shadow: none;
  display: flex;
  color: ${(props) => props.textColor ?? `var(--color-gray-300)`};
  font-size: 1em;
  font-weight: normal;
  padding: 0 var(--spacing-sm) 0 30px;
  position: relative;
  height: 40px;
  flex-shrink: 0;

  i {
    width: 22px;
    height: 22px;
    background-color: ${(props) => props.textColor ?? `var(--color-gray-300)`};
  }

  .HW_badge_cont {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: inline-flex;
    align-items: center;
    padding-left: 71px;
    gap: 8px;

    :before {
      content: "What's New";
    }

    .HW_badge {
      border-radius: 10px;
      background: var(--color-red-500, #c50707);
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      top: unset;
      left: unset;
      color: var(--white-100, var(--color-white-100, #fff));
      font-size: 12px;
      line-height: 14px;
      flex-shrink: 0;
    }

    .HW_softHidden {
      display: none;
    }
  }

  :hover,
  :focus {
    background-color: ${(props) =>
      props.activeItemColor ?? `var(--color-black-100)`};

    .HW_badge_cont {
      :before {
        color: ${(props) => props.activeTextColor ?? `var(--color-white-100)`};
      }
    }

    .icon {
      background-color: ${(props) =>
        props.activeTextColor ?? `var(--color-white-100)`};
    }

    .HW_badge_cont {
      color: var(--color-white-100);
    }
  }
  @media screen and (max-width: ${Theme.break.largeHD}) {
    padding-left: var(--spacing-xl, 24px);

    .HW_badge_cont {
      :before {
        display: none;
      }

      .HW_badge {
        position: absolute;
        right: 36px;
        top: 4px;
      }
    }
  }
`;
