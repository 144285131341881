import { Button, Icon } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import useShareConfirmAction from "../hooks/useShareConfirmAction";

interface ResetPermissionsProps {
  canResetPermissions: boolean;
  onResetPermissions: () => void;
  defaultShareOption: string;
  isDisabled?: boolean;
}

function ResetPermissions({
  canResetPermissions,
  onResetPermissions,
  defaultShareOption,
  isDisabled,
}: ResetPermissionsProps) {
  const { confirmAction } = useShareConfirmAction(defaultShareOption);

  const handleResetPermissions = async () => {
    confirmAction(onResetPermissions);
  };

  return (
    <div className="row reset-permissions">
      <div className="column">
        <p>
          <FormattedMessage
            id="ui_component.common.label.set_specific_sharing_permissions"
            defaultMessage="Set specific sharing permissions to individual spaces below"
          />
        </p>
      </div>
      <div
        data-testid="reset-permissions-button"
        className={`column ${
          !canResetPermissions || isDisabled ? "hidden" : ""
        }`}
      >
        <Button
          inline
          onClick={handleResetPermissions}
          disabled={!canResetPermissions}
        >
          <FormattedMessage
            id="ui_component.common.label.reset_permissions"
            defaultMessage="Reset Permissions"
          />
          <Icon name="restore" />
        </Button>
      </div>
    </div>
  );
}

export { ResetPermissions };
