/**
 * Convert any string type to normal case. E.g. convert snakeCase, camelCase etc.
 *
 * @param str - String to convert
 *
 * @remarks
 * Normal case means human readable (word and spaces essentially)
 */
export function normalCase(str: string): string {
  let result = str.replace(/[-_]/g, " ");
  result = result.replace(/([a-z])([A-Z])/g, "$1 $2");
  return result.replace(/\b\w/g, (match) => match.toUpperCase());
}

/**
 * Pluralises a word based on the count.
 *
 * @param word - The word to be pluralised.
 * @param count - The count used to determine the pluralisation.
 * @param inclusive - Prefix the count to the returned string or not.
 *
 * @remarks
 * This function provides basic pluralisation rules that cover many common cases, but it may not handle all possible
 * variations of pluralisation.
 */
export function pluralise(word: string, count: number, inclusive = true): string {
  const singularForm = word;
  let pluralForm = word + "s"; // Default plural form
  const pluralRules: [RegExp, string][] = [
    [/(s|sh|ch|x|z)$/i, "es"], // Words ending in s, sh, ch, x, or z
    [/([^aeiou])y$/i, "ies"], // Words ending in a consonant + y
  ];
  for (const [regex, replacement] of pluralRules) {
    if (regex.test(word)) {
      pluralForm = word.replace(regex, replacement);
      break;
    }
  }
  const result = inclusive ? `${count} ${count === 1 ? singularForm : pluralForm}` : pluralForm;
  return result.trim();
}
