import { Notification } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { useShareChannelToSpaceMutation } from "src/types.g";
import { SharingMode } from "../helper";

export const useShareChannelToSpace = () => {
  const [shareToSpace] = useShareChannelToSpaceMutation({
    refetchQueries: ["sharedSpacesByChannelId"],
  });

  const shareChannelToSpace = (
    channelId: string,
    selectedSpaceId: string,
    sharedStatus: string
  ) => {
    return shareToSpace({
      variables: {
        input: {
          channelId,
          sharingMode: sharedStatus,
          spaceId: selectedSpaceId,
        },
      },
      onCompleted: (data) => {
        if (sharedStatus === SharingMode.Disabled) {
          Notification.success({
            message: (
              <FormattedMessage
                id="common.text.unshared_success"
                defaultMessage="Unshared successfully"
              />
            ),
          });
        } else {
          Notification.success({
            message: (
              <FormattedMessage
                id="common.text.shared_success"
                defaultMessage="Shared successfully"
              />
            ),
          });
        }
      },
    }).catch((err) => {
      Notification.error({
        message: "Share to space failed",
      });
      return false;
    });
  };

  return shareChannelToSpace;
};
