import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export interface ScreenListItemStyleProps {
  showfeature?: boolean;
  disabled?: boolean;
  selected: boolean;
}

export const ScreenItem = styled.div<{ canUpdate: boolean }>`
  height: 72px;
  display: flex;
  border-radius: var(--radius-md, 8px) var(--radius-md, 8px) 0px 0px;
  padding: var(--spacing-sm, 8px);
  ${(props) => (props.canUpdate ? `cursor: pointer;` : `cursor: initial;`)}
`;

export const ScreenItemFooterContainer = styled.div`
  padding: 4px 12px;
  border-top: 1px solid ${Theme.color.grey30};
  border-radius: 0px 0px var(--radius-md, 8px) var(--radius-md, 8px);
  cursor: pointer;
  line-height: 20px;

  display: flex;
  justify-content: space-between;
`;

export const ScreenItemTag = styled.div`
  flex: 3;
`;

export const ScreenShortNote = styled.div`
  max-width: 30%;
  padding: 1px 10px;

  border-radius: 15px;
  border: 1px solid var(--color-yellow-400, #ffdf0d);
  background: var(--color-yellow-100, #ffffc1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  font-size: 12px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: var(--color-yellow-200, #ffffe1);
  }
`;

const StyledScreenItemListDiv = styled.div<ScreenListItemStyleProps>`
  background: var(--color-background);
  border-radius: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  border: 1px solid var(--color-gray-100);

  /* Children width only */
  .screen-core {
    width: calc(100% - 32px);
    align-items: center;
    display: flex;

    [class^="screen-"] {
      text-align: center;
    }

    .screen-alpha {
      width: 480px;
      align-items: center;
      flex-shrink: 0;
      display: flex;
      gap: 10px;
    }

    .screen-player {
      width: 360px;
      flex-shrink: 0;
    }

    .screen-status {
      width: calc(100% - 840px);
      display: flex;
      justify-content: flex-end;
      align-items: center;

      [class^="screen-"] {
        flex-shrink: 0;
      }

      .screen-state {
        display: inline-flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        flex-shrink: 1;
      }
    }

    .screen-muted {
      width: 32px;
      i {
        background-color: var(--color-grey100);
      }
    }

    .screen-notification {
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      display: flex;

      .button {
        border: none;
        box-shadow: none;
        width: var(--size-md);
        height: var(--size-md);

        i {
          width: 20px;
          height: 20px;
        }

        :hover {
          border: none;
        }
      }
    }

    .screen-location {
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .screen-output {
      width: 32px;
      i {
        background-color: var(--color-grey100);
      }
    }

    .screen-tags {
      display: none;
    }
  }

  @media screen and (max-width: ${Theme.break.largeXHD}) {
    .screen-core {
      .screen-alpha {
        width: 360px;
      }
      .screen-player {
        width: 300px;
      }
      .screen-status {
        width: calc(100% - 660px);
      }
    }
  }
  @media screen and (max-width: ${Theme.break.large}) {
    .screen-core {
      .screen-alpha {
      }
      .screen-player {
        width: 240px;
      }
      .screen-status {
        width: calc(100% - 600px);
      }
    }
  }
  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .screen-core {
      .screen-player {
        display: none;
      }
      .screen-status {
        width: calc(100% - 360px);
      }
    }
  }
  @media screen and (max-width: ${Theme.break.small}) {
    .screen-core {
      .screen-alpha {
        width: calc(100% - 112px);
      }

      .screen-player {
        display: none;
      }

      .screen-status {
        width: auto;
        .screen-state {
          width: 48px;
          justify-content: flex-start;
        }

        .screen-output,
        .screen-notification,
        .popover-notification-badges {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: ${Theme.break.tiny}) {
    .screen-core {
      .screen-alpha {
        width: calc(100% - 48px);
      }
      .screen-status {
        .screen-muted,
        .screen-location,
        .popover-notification-badges {
          display: none;
        }
      }
    }
  }

  .screen-core {
    .screen-alpha {
      .thumbnail-preview {
        cursor: pointer;
        width: 56px;
        height: 56px;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        ${Theme.default.borderRadius};

        a {
          display: block;
          height: 56px;
        }

        :hover {
          background: var(--color-grey30);
        }

        i {
          background-color: var(--color-grey100);
          mask-size: 36px;
          min-width: auto;
          min-height: auto;
        }
      }

      .screen-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 10px;
        width: calc(100% - 80px);
        gap: 4px;

        h3 {
          color: var(--color-grey80);
          font-size: 1.25em;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          cursor: pointer;

          .inline-input {
            text-align: left;

            &.disabled {
              cursor: pointer;
              .ui.input {
                opacity: 1;
                cursor: unset;
                input {
                  color: var(--color-grey100);
                }
              }
            }

            .ui.input {
              input {
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        > span {
          color: var(--color-grey80);
          display: none;
          font-size: 0.75em;
          line-height: 1.33;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .playing {
          i {
            display: none;
          }
        }
      }
    }

    .screen-player {
      .inline {
        .picker-container {
          .ui.dropdown {
            &.active {
              border: 1px solid var(--color-border);
              background-color: var(--color-white-50);
            }
          }
        }
      }
    }

    .screen-state {
      > div {
        margin: 2px;
      }
    }
  }

  .screen-options {
    width: 32x;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    .ui.floating.dropdown > .menu {
      top: auto;
    }

    :empty {
      display: none;
    }

    button.checkbox {
      i {
        background-color: var(--color-grey40);
      }
    }

    .screen-dropdown {
      align-items: center;
      display: flex;
      height: 32px;
      justify-content: center;
      opacity: 0;
      width: 32px;

      &.active {
        opacity: 1;

        i {
          background-color: var(--color-grey100);
        }
      }

      :hover {
        i {
          background-color: var(--color-grey100);
        }
      }

      .menu {
        right: 0;
        left: auto;

        .item {
          i {
          }
        }
      }
    }
  }

  :hover {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.2);

    .screen-core {
      .screen-starred {
        .toggle.star {
          opacity: 1;
          cursor: pointer;

          :hover {
            background: var(--color-grey30);
          }
        }
      }

      .screen-player {
        .inline {
          .picker-container {
            .ui.dropdown {
              border: 1px solid var(--color-border);

              i.dropdown.icon {
                visibility: visible;
              }

              .dropdown-trigger {
                width: calc(100% - 56px);
              }

              &.disabled {
                border-color: transparent;

                .dropdown-trigger {
                  width: 100%;
                }
              }
            }

            .screen-picker {
              visibility: visible;
            }
          }
        }
      }
      .screen-player {
        .inline {
          .content-nav {
            .dropdown-channels {
              ${(props: ScreenListItemStyleProps) =>
                props.showfeature
                  ? `
                    width: calc(100% - 55px);
                  `
                  : `
                    width: 100%;
                  `}

              border-radius: 4px 0 0 4px;

              .ui.dropdown {
                border-radius: 4px 0 0 4px;
                border-color: var(--color-grey40);

                i {
                  display: block;
                  top: auto !important;
                }

                .menu {
                  width: calc(100% + 55px);
                }
              }
            }

            .btn-cast {
              display: block;
              border-color: var(--color-grey40);
              border-left: none;
              ${(props: ScreenListItemStyleProps) =>
                props.disabled &&
                `
                  :hover {
                    border-color: var(--color-yellow-500);
                    background: var(--color-yellow-500);
                    border-radius: 0 4px 4px 0;
                    box-shadow: none;
                    border-bottom-color: rgba(0,0,0,0.1);
                    border-bottom-width: 2px;

                    i {
                      transform: none;
                    }
                  }
              `}
            }
          }
        }
      }
    }

    .screen-options {
      .screen-dropdown {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .screen-core {
      .screen-player {
        .inline {
          .content-nav {
            .dropdown-channels {
              ${(props: ScreenListItemStyleProps) =>
                props.showfeature
                  ? `
                    width: calc(100% - 55px);
                  `
                  : `
                    width: 100%;
                  `}
              border-radius: 4px 0 0 4px;

              .ui.dropdown {
                border-radius: 4px 0 0 4px;
                border-color: var(--color-grey40);

                i {
                  display: block;
                  top: auto;
                }

                .menu {
                  width: calc(100% + 55px);
                }
              }
            }

            .btn-cast {
              display: block;
              border-color: var(--color-grey40);
              border-left: none;
            }
          }
        }
      }
    }

    .screen-options {
      .screen-dropdown {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .screen-core {
      flex-wrap: nowrap;

      .screen-alpha {
        .screen-title {
          h3 {
            font-size: 1em;
          }
          .casting {
            color: var(--color-red100);
            font-size: 0.8em;
          }
          > span {
            display: block;
            text-align: left;
          }
        }
      }

      .screen-player {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    height: fit-content;
    min-height: 60px;

    .screen-core {
      .screen-alpha {
        .thumbnail-preview {
          width: 40px;
          height: 40px;

          i {
            mask-size: 30px;
          }
        }

        .screen-title {
          width: 100%;
          h3 {
            font-size: 1em;

            + i {
              display: inline-block;
              height: 12px;
              width: 12px;

              .label {
                display: none;
              }
            }
          }

          .playing {
            i {
              display: inline-block;
              width: 9px;
              margin-right: 2px;
              background-color: var(--color-grey60);
              height: 14px;
            }

            .label {
              display: none;
            }
          }
        }
      }
    }
  }

  ${(props: ScreenListItemStyleProps) =>
    props.selected &&
    `
        box-shadow: inset 0 0 0 4px var(--color-primary-background);

        :hover {
          box-shadow: inset 0 0 0 4px var(--color-primary-background);
        }
        .screen-options {
          button.checkbox {
            i {
              background-color: var(--color-grey100);
            }
            :hover {
              i {
                background-color: var(--color-grey100);
              }
            }
          }
        }
      `}
`;

export const Styled = styled(StyledScreenItemListDiv)``;
