import { Button, Icon } from "@screencloud/screencloud-ui-components";
import { Styled } from "./styles";
import { useAppContext } from "src/hooks/useAppContext";
import { getWhiteLabelStyles } from "src/helpers/whiteLabel";
import { useState } from "react";
import { updateUserOnboarding } from "src/helpers/onboardingHelper";
import { OnBoardingRefType } from "src/constants/constants";
import { shouldHideGetStartedKCBot } from "./utils";
import { FormattedMessage } from "react-intl";

export const GetStartedKCBot = (): JSX.Element | null => {
  const [hideGetStarted, setHideGetStarted] = useState(false);

  const context = useAppContext();
  const whiteLabelStyles = getWhiteLabelStyles(context);

  // Hide when user is reseller || user hide get started || kc bot feature flag is off || org created more than 120 days
  const shouldHideGetStarted =
    hideGetStarted || shouldHideGetStartedKCBot(context);

  const handleOnCloseClick = async () => {
    setHideGetStarted(true);
    await updateUserOnboarding(OnBoardingRefType.KC_BOT_HIDE, true);
  };

  if (shouldHideGetStarted) {
    return null;
  }

  return (
    <Styled {...whiteLabelStyles} data-testid="get-started-kc-bot">
      <div className="container" id="kcb-widget">
        <Icon name="rocket" />
        <div className="label">
          <p className="title">
            <FormattedMessage
              id="ui_component.main_navigation.get_started_kc_bot"
              defaultMessage="Get Started"
            />
          </p>
          <p className="description">
            <FormattedMessage
              id="ui_component.label.get_started_description"
              defaultMessage="Learn and discover Studio features"
            />
          </p>
        </div>
        <Button icon mini onClick={() => handleOnCloseClick()}>
          <Icon name="close-small" />
        </Button>
      </div>
    </Styled>
  );
};
