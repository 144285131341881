import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Icon,
  LoaderBar,
  Search,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "clsx";
import { AppContext } from "../../AppContextProvider/AppContext";
import { SortingActions, UUID } from "../../constants/constants";
import { AppInstance } from "../../types.g";
import { withAppPicker, WithAppPicker } from "./withAppPicker";
import { AppInstances } from "./appInstances";
import SearchAppPicker from "./search";
import { AppPickerItem, Styled } from "./styles";
import EmptyState from "../EmptyState";
import { AppContextType } from "src/AppContextProvider/type";
export interface AppContentPickerProps {
  isMultipleSelect: boolean | undefined;
  spaceId?: UUID;
  callback: (ids: string[], selectedApps: AppInstance[]) => void;
}
export interface AppContentPickerState {
  customContainer: React.ReactNode | null;
  isOrderByAscending: boolean;
  selectedIds: string[];
  selectedApps: AppInstance[];
  sortBy: SortingActions;
  appId: UUID;
}
class AppPicker extends React.PureComponent<
  AppContentPickerProps & WithAppPicker,
  AppContentPickerState
> {
  public static contextType = AppContext;
  public context: AppContextType;
  constructor(props: AppContentPickerProps & WithAppPicker) {
    super(props);
    this.state = {
      customContainer: null,
      isOrderByAscending: true,
      selectedApps: [],
      selectedIds: [],
      sortBy: SortingActions.SORT_BY_NAME,
      appId: "",
    };
  }

  public toggleSortAndOrderMediaItem = (action: SortingActions) => {
    if (this.state.sortBy === action) {
      this.setState((prevState) => ({
        ...prevState,
        isOrderByAscending: !prevState.isOrderByAscending,
        sortBy: action,
      }));
    } else {
      this.setState({ isOrderByAscending: true, sortBy: action });
    }
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public goBackToAppInstall = () => {
    this.setState({ appId: "" });
  };

  public gotoAppInstanceList = (appId: UUID) => {
    this.setState({ appId });
  };

  public getAppNameById = (appId: string): string | undefined => {
    const { apps } = this.props;
    return apps.find((item) => item.id === appId)?.name;
  };

  public render() {
    const {
      searchString,
      query,
      clearSearch,
      updateSearchString,
      loading,
      apps,
    } = this.props;

    return (
      <Styled
        className={classNames("media-content apps", {
          "apps-search": searchString.length,
        })}
      >
        {loading && <LoaderBar />}
        <div className="content-header">
          <Search
            className="search"
            onClear={clearSearch}
            onChange={(e, { value }) => updateSearchString(value)}
            placeholder="Search Apps"
            showNoResults={false}
            value={searchString}
          />
        </div>
        <div className="container">
          {apps.length && !searchString ? (
            <Breadcrumb>
              <BreadcrumbSection>
                <Button transparent onClick={this.goBackToAppInstall}>
                  <FormattedMessage id="apps.apps" defaultMessage="Apps" />
                </Button>
              </BreadcrumbSection>
              {this.state.appId && <BreadcrumbDivider />}
              <BreadcrumbSection active>
                {this.getAppNameById(this.state.appId)}
              </BreadcrumbSection>
            </Breadcrumb>
          ) : null}
          <div
            className="layout-list"
            id="scrollableDivAppInstance"
            ref={(el) => {
              if (el && !this.state.customContainer) {
                this.setState({ customContainer: el });
              }
            }}
          >
            {searchString.length > 0 ? (
              <SearchAppPicker
                isMultipleSelect={this.props.isMultipleSelect}
                callback={this.props.callback}
                query={query}
                customContainer={this.state.customContainer}
                appId={this.state.appId}
                spaceId={this.props.spaceId}
              />
            ) : apps.length ? (
              <>
                {this.state.appId === "" ? (
                  apps.map((app) => {
                    return (
                      <AppPickerItem
                        className="media-item"
                        key={`apps-${app.id}`}
                        onClick={() => this.gotoAppInstanceList(app?.id)}
                      >
                        <div className="media-core">
                          <div className="media-alpha">
                            <div className="thumbnail-preview">
                              <div className="thumbnail">
                                <div className="wrapper">
                                  <img src={app!!.iconUrl!} />
                                </div>
                              </div>
                            </div>
                            <div className="media-title">
                              <h3>{app?.name}</h3>
                              <div>
                                <span className="media-item__date">
                                  {app?.categories?.join(",")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </AppPickerItem>
                    );
                  })
                ) : (
                  <AppInstances
                    appId={this.state.appId}
                    selectedIds={this.state.selectedIds}
                    searchString={this.props.searchString}
                    isMultipleSelect={this.props.isMultipleSelect}
                    callback={this.props.callback}
                  />
                )}
              </>
            ) : (
              <EmptyState section="apps" className="empty">
                <h3>
                  <FormattedMessage
                    id="apps.get_started"
                    defaultMessage="This is where your apps will live"
                  />
                </h3>
                <p>
                  <FormattedMessage
                    id="apps.no_apps_blurb"
                    defaultMessage="Use the App Store to create screen-ready content from sources such as Twitter, CNN, Slack and OneDrive."
                  />
                </p>
              </EmptyState>
            )}
          </div>
        </div>
      </Styled>
    );
  }
}

export default withAppPicker(AppPicker);
