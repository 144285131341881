import { useOnCurrentUserSettingLastAccessSpaceUpdated } from "src/applications/currentUserSetting/events/useOnUserSettingLastAccessSpaceUpdated";
import { Space, useUpdateUserSettingsMutation } from "src/types.g";

export interface UseUpdateCurrentUserSetting {
  updateCurrentSpace: (
    space: Pick<Space, "id" | "name">,
    isSpaceNewlyCreated?: boolean
  ) => Promise<any>;
}

export function useUpdateCurrentUserSetting(): UseUpdateCurrentUserSetting {
  const [updateUserSettingMutation] = useUpdateUserSettingsMutation();
  const {
    onCurrentUserSettingLastAccessSpaceUpdated: onCurrentSpaceUpdated,
  } = useOnCurrentUserSettingLastAccessSpaceUpdated();

  const updateCurrentSpace = (
    space: Pick<Space, "id" | "name">,
    isSpaceNewlyCreated?: boolean
  ) => {
    return updateUserSettingMutation({
      variables: {
        input: {
          settingsData: {
            lastAccessedSpaceId: space.id,
            lastAccessedSpaceName: space.name,
          },
        },
      },
      update: () => {
        onCurrentSpaceUpdated({
          lastAccessSpace: space,
          isSpaceNewlyCreated: Boolean(isSpaceNewlyCreated),
        });
      },
    });
  };

  return { updateCurrentSpace };
}
