import { Notification } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import {
  ShareChannelToAllSpacesMutation,
  useShareChannelToAllSpacesMutation,
} from "src/types.g";
import { SharingMode } from "../helper";

export const useShareChannelToAllSpaces = () => {
  const [shareToSpace, { loading }] = useShareChannelToAllSpacesMutation();

  const shareChannelToAllSpaces = (channelId: string, sharedStatus: string) => {
    return shareToSpace({
      variables: {
        input: {
          channelId,
          sharingMode: sharedStatus,
        },
      },
      onCompleted: (data: ShareChannelToAllSpacesMutation) => {
        sharedStatus !== SharingMode.Disabled &&
          Notification.success({
            message: (
              <FormattedMessage
                id="common.text.shared_success"
                defaultMessage="Shared successfully"
              />
            ),
          });
      },
    }).catch((err) => {
      Notification.error({
        message: "Share to space failed",
      });
      return false;
    });
  };

  return { shareChannelToAllSpaces, loading };
};
