import { ModalSize } from "@screencloud/screencloud-ui-components";
import { useCallback } from "react";
import { useAppContext } from "src/hooks/useAppContext";
import { ScreenDetailQuery } from "src/types.g";
import { Note } from "../Note";

export const useNoteModal = () => {
  const context = useAppContext();

  const openModal = useCallback((screen: ScreenDetailQuery["screenById"]) => {
    context.modal.openModal(<Note screen={screen} isModal />, null, {
      opts: {
        disableTitle: true,
        overflow: false,
        size: ModalSize.SMALL,
      },
    });
  }, []);

  return { openModal };
};
