import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export interface PlaylistContentItemStyleProps {
  expired: boolean;
}

export const Styled = styled.div<PlaylistContentItemStyleProps>`
  ${(props: PlaylistContentItemStyleProps) =>
    props.expired &&
    `
    opacity: 0.5;
    background-color: #F7F8F7;
  `}

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px 0;

  .hide-action {
    pointer-events: none;
    display: none !important;
    opacity: 0;
    > div {
      display: none !important;
    }
  }

  .invalid-app-config {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 1.333;
    color: ${Theme.color.red};
  }

  .content-alpha {
    width: 39%;
    display: flex;
    flex: 0 0 auto;
    cursor: pointer;
    padding: 0 10px;

    .thumbnail-preview {
      width: 60px;
      height: 60px;
      position: relative;
    }

    .content-title {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 15px;

      .document-type {
        color: ${Theme.color.lightGrey};
        font-size: 0.875em;
      }
      h3 {
        font-size: 1em;
        line-height: 1.3125;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid transparent;
        margin: 0;
      }
      .expired-item {
        color: ${Theme.color.red};
        font-size: 0.75em;
      }
    }
  }
  .extra-width-invalid-app {
    width: calc(100% - 45px);
  }
  .media-scheduled {
    display: inline-flex;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
    width: 7%;
    .scheduled {
      i {
        background-color: ${Theme.color.warning};
      }

      &.warning {
        i {
          background-color: ${Theme.color.red};
          + i {
            margin-left: 4px;
          }
        }
      }

      :hover {
        cursor: pointer;
      }
    }
  }

  .content-duration {
    width: 26%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .document-type {
      font-size: 0.875em;
      margin-top: -7px;
      z-index: 1;
    }
  }

  .content-type {
    width: calc(28% - 32px);
    display: inline-flex;
    justify-content: center;
    height: 60px;
    align-items: center;

    span {
      font-size: 0.875em;
      color: ${Theme.color.grey};
    }
  }

  .content-remove {
    position: absolute;
    right: -40px;
    top: 20px;
    opacity: 0;
  }

  .content-actions {
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    i {
      background-color: ${Theme.color.lightGrey};
    }
  }

  :hover {
    .content-remove {
      opacity: 1;
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .content-remove {
      opacity: 1;
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .content-alpha {
      width: 40%;
    }

    .content-duration {
      width: 23%;
    }

    .content-type {
      width: calc(30% - 40px);
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .content-alpha {
      width: calc(100% - 150px);
      order: 1;
    }

    .media-scheduled {
      display: none;
    }

    .content-duration {
      order: 2;
      width: 100px;
    }

    .content-type {
      display: none;
    }

    .content-actions {
      order: 3;
    }
  }
`;
