import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  border-top: 1px solid ${Theme.color.silver};

  &.cast-item {
    display: flex;
    padding: 10px 0;

    .media-core {
      width: calc(100% - 80px);
      display: flex;
      align-items: center;
      cursor: pointer;

      [class^="media-"] {
        text-align: center;

        span {
          color: ${Theme.color.grey};
          font-size: 0.875em;
          white-space: nowrap;
          font-style: italic;

          > span {
            font-size: 1em;
          }
        }
      }

      .media-alpha {
        width: 40%;
        display: flex;
        flex: 0 0 auto;

        .thumbnail-preview {
          width: 60px;
          height: 60px;
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px;
          background-size: contain;
          ${Theme.default.borderRadius};

          .thumbnail {
            width: 100%;
            height: 100%;

            .wrapper {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              ${Theme.default.borderRadius};
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;

              i {
                background-color: ${Theme.color.white};
                width: 30px;
                height: 30px;
              }
            }
          }
        }

        .media-title {
          width: calc(100% - 80px);
          display: flex;
          flex-direction: column;
          justify-content: center;

          * {
            text-align: left;
          }

          h3 {
            font-size: 1em;
            display: inline-flex;
            padding: 0;
            margin-bottom: 0;
            font-weight: normal;
            white-space: nowrap;
            border-bottom: 1px solid transparent;
            overflow: hidden;

            span {
              font-size: 1em;
              color: ${Theme.color.darkGrey};
              font-style: normal;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .meta-responsive {
            display: none;
          }
        }
      }

      .media-screens {
        width: 20%;

        > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 0.875em;

          i {
            margin-right: 5px;
            background-color: ${Theme.color.darkGrey};
          }

          span {
            font-style: normal;
            color: ${Theme.color.darkGrey};
          }
        }
      }

      .media-kind,
      .media-duration {
        width: 20%;
      }
    }

    .media-action {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      .screen-pending {
        .loader {
          background-color: ${Theme.color.red};
          border-radius: 100%;
          padding: 0;
          left: 0;
          width: 28px;
          height: 28px;

          :before {
            border-color: ${Theme.color.white};
            opacity: 0.3;
            right: 6px;
            top: 6px;
          }

          :after {
            border-color: ${Theme.color.white} transparent transparent;
            right: 6px;
            top: 6px;
          }
        }
      }

      .screen-stop {
        border: 1px solid ${Theme.color.darkGrey};
        border-radius: 100%;
        width: var(--size-md);
        height: var(--size-md);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
          width: 20px;
          height: 20px;
          background-color: ${Theme.color.darkGrey};
        }

        :hover {
          border-color: ${Theme.color.red};
          background: ${Theme.color.red};

          i {
            background-color: ${Theme.color.white};
          }
        }
      }
    }

    :hover {
      background: ${Theme.color.silver};
    }

    @media screen and (max-width: ${Theme.break.mediumAlt}) {
      .media-core {
        .media-alpha {
          width: calc(100% - 360px);

          .media-title {
            > span {
              display: none;
            }
          }
        }

        .media-duration,
        .media-kind,
        .media-screens {
          width: 120px;
        }
      }
    }

    @media screen and (max-width: ${Theme.break.small}) {
      .media-core {
        .media-alpha {
          width: calc(100% - 120px);

          .thumbnail-preview {
            width: 40px;
            height: 40px;

            .thumbnail {
              .wrapper {
                i {
                  width: 16px;
                  height: 16px;
                }

                img {
                  max-width: 40px;
                  max-height: 40px;
                }
              }
            }
          }

          .media-title {
            .meta-responsive {
              display: inline-flex;
            }
          }
        }

        .media-duration,
        .media-kind {
          display: none;
        }

        .media-screens {
          width: 120px;
        }
      }
    }
  }
`;
