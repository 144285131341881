import { useMemo, useState } from "react";
import {
  FIRST_FETCH_ITEMS,
  MAXIMUM_FETCH_MORE_ITEMS,
  MINIMUM_FETCH_MORE_ITEMS,
} from "src/constants/constants";

import { renderFetchMoreButton } from "src/helpers/generalHelper";
import { useAppContext } from "src/hooks/useAppContext";
import {
  Exact,
  InputMaybe,
  SearchSiteQueryVariables,
  SiteFilter,
  SiteFragment,
  SitesOrderBy,
  useSearchSiteQuery,
} from "src/types.g";

export interface UseSearchSite {
  sites: SiteFragment[];
  hasNextPage: boolean;
  loadMore: () => void;
  loading: boolean;
  isLoading: boolean;
  refetch: (
    variables?: Partial<
      Exact<{
        query: InputMaybe<string>;
        spaceId: any;
        endCursor: any;
        orderBy: InputMaybe<SitesOrderBy | SitesOrderBy[]>;
        first: InputMaybe<number>;
        filter: InputMaybe<SiteFilter>;
      }>
    >
  ) => void;
  isFirstTimeAlreadyLoaded: boolean;
  totalCount: number;
  renderFetchMoreButton: React.ReactNode;
}

interface Prop {
  query: string;
  siteFilter: SiteFilter;
}

const useSearchSiteSection = ({ query, siteFilter }: Prop): UseSearchSite => {
  const context = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);

  const variables: SearchSiteQueryVariables = {
    query,
    spaceId: context.currentSpace?.id,
    first: FIRST_FETCH_ITEMS,
    filter: siteFilter,
    endCursor: null,
    orderBy: [SitesOrderBy.NameAsc, SitesOrderBy.IdDesc],
  };

  const {
    data: searchSiteQuery,
    fetchMore,
    refetch,
    loading,
  } = useSearchSiteQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !query,
  });

  const loadMore = () => {
    if (isLoading) return;
    setIsLoading(true);
    fetchMore({
      variables: {
        first:
          fetchCount > 1 ? MAXIMUM_FETCH_MORE_ITEMS : MINIMUM_FETCH_MORE_ITEMS,
        endCursor: searchSiteQuery?.searchSite?.pageInfo.endCursor,
      },
    }).then(() => {
      setIsLoading(false);
      setFetchCount(fetchCount + 1);
    });
  };
  const currentItemsCount = searchSiteQuery?.searchSite?.nodes.length ?? 0;

  const totalCount = useMemo(
    () => searchSiteQuery?.searchSite?.totalCount ?? 0,
    [searchSiteQuery?.searchSite?.totalCount]
  );

  const hasNextPage = useMemo(
    () =>
      Boolean(searchSiteQuery?.searchSite?.pageInfo.hasNextPage) &&
      searchSiteQuery?.searchSite?.nodes.length! <
        searchSiteQuery?.searchSite?.totalCount!,
    [
      searchSiteQuery?.searchSite?.pageInfo!.hasNextPage,
      searchSiteQuery?.searchSite?.totalCount,
    ]
  );

  const isFirstTimeAlreadyLoaded = useMemo(
    () => Boolean(searchSiteQuery?.searchSite),
    [searchSiteQuery?.searchSite]
  );

  return {
    sites: searchSiteQuery?.searchSite?.nodes
      ? searchSiteQuery?.searchSite?.nodes
      : [],
    refetch,
    loading,
    isLoading,
    loadMore,
    hasNextPage,
    isFirstTimeAlreadyLoaded,
    totalCount,
    renderFetchMoreButton: renderFetchMoreButton(
      currentItemsCount,
      totalCount,
      isLoading,
      hasNextPage,
      loadMore
    ),
  };
};

export const useSearchSiteList = (query: string) => {
  const context = useAppContext();
  const siteFilter: SiteFilter = {
    spaceId: {
      equalTo: context?.currentSpace?.id,
    },
  };
  return useSearchSiteSection({ query, siteFilter });
};

export const useSearchSharedSiteList = (query: string) => {
  const context = useAppContext();
  const siteFilter: SiteFilter = {
    spaceId: {
      notEqualTo: context?.currentSpace?.id,
    },
  };
  return useSearchSiteSection({ query, siteFilter });
};

export const useSearchAllSiteList = (query: string) => {
  return useSearchSiteSection({ query, siteFilter: {} });
};
