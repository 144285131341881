import {
  isAllScreensInOrgSelected,
  isAllScreensInSpaceSelected,
  isSomeScreensSelected,
} from "src/helpers/screensManagerHelper";

export const getMutationInput = (
  isAllScreensSelected: boolean,
  spaceId: string | undefined,
  selectedScreens: string[]
) => {
  let mutationInput = {};

  if (isAllScreensInOrgSelected(isAllScreensSelected, spaceId)) {
    mutationInput = { isUpdateByOrgId: true };
  } else if (isAllScreensInSpaceSelected(isAllScreensSelected, spaceId)) {
    mutationInput = { spaceId };
  } else if (isSomeScreensSelected(selectedScreens)) {
    mutationInput = { screenIds: selectedScreens };
  }

  return mutationInput;
};
