import { Button, Icon } from "@screencloud/screencloud-ui-components";
import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import ChromeLogo from "./images/chrome-logo.svg";

const UnsupportedBrowser = (): ReactElement<{}> => {
  return (
    <>
      <div className="content">
        <h2>
          <FormattedMessage
            id="ui_component.site.new_site_modal.install_browser.title"
            defaultMessage="Dashboards are only supported on Chrome"
          />
        </h2>
        <p>
          <FormattedMessage
            id="ui_component.site.new_site_modal.install_browser.instructions"
            defaultMessage="You will need the ScreenCloud Dashboard Recorder extension installed in your browser to be able to create dashboards."
          />
        </p>
        <div className="browser-links">
          <div className="browser-info">
            <img className="browser-logo" src={ChromeLogo} alt="Chrome" />
            <Button
              className="browser-download"
              fullWidth
              onClick={() => window.open("https://www.google.com/chrome")}
            >
              <Icon name="external" />
              <FormattedMessage
                id="ui_component.site.new_site_modal.install_browser.download_chrome"
                defaultMessage="Download Chrome"
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnsupportedBrowser;
