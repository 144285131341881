import { ReactElement } from "react";
import SecureSiteChecks from "./SecureSiteChecks";
import { Styled } from "./styles";

const CreateSiteModal = (): ReactElement<{}> => {
  return (
    <Styled>
      <SecureSiteChecks />
    </Styled>
  );
};

export default CreateSiteModal;
