import { UUID } from "../constants/constants";
import { updateNewPlaylistToList } from "../helpers/playlistHelper";
import {
  CreatePlaylistMutationVariables,
  useCreatePlaylistMutation,
} from "../types.g";
import { useAppContext } from "./useAppContext";

export function useCreatePlaylist() {
  const context = useAppContext();
  const [createPlaylistMutation] = useCreatePlaylistMutation();
  const createPlaylist = (
    createPlaylistInput: CreatePlaylistMutationVariables,
    currentSpaceId: UUID
  ) => {
    return createPlaylistMutation({
      variables: createPlaylistInput,
      update: (cache, { data }) => {
        if (data?.createPlaylist?.playlist) {
          updateNewPlaylistToList(
            context,
            cache,
            data?.createPlaylist?.playlist
          );
        }
      },
    });
  };
  return { createPlaylist };
}
