import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  height: 100%;

  .create {
    height: 100%;

    .row {
      padding: 20px;
      min-height: 135px;
      height: calc(100% - 65px);

      .heading {
        margin-bottom: 10px;
      }
    }
  }

  .md-footer {
    border-radius: 0 0 ${Theme.default.borderRadiusValue}
      ${Theme.default.borderRadiusValue};
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .create {
      .row {
        height: calc(100% - 66px);
      }
      .md-footer {
        .btn-create {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .create {
      .row {
        padding: 20px 15px;
      }
    }
  }
`;
