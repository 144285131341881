import React from "react";
import {
  Button,
  Icon,
  Popover,
  Search,
} from "@screencloud/screencloud-ui-components";
import { ScreensByScreenGroupIdAndOrgIdFragment } from "src/types.g";

interface ScreenPickerSearchProps {
  searchString: string;
  clearSearch: () => void;
  onSearchInputChange: (
    event: React.SyntheticEvent<any>,
    data: { [key: string]: any }
  ) => void;
  isSelectAll?: boolean;
  isSelectAllIndeterminate?: boolean;
  selectedScreens: ScreensByScreenGroupIdAndOrgIdFragment[];
  toggleSelectedAll?: () => void;
  isSingleSelect?: boolean;
}

export const ScreenPickerSearch = (props: ScreenPickerSearchProps) => {
  const {
    isSelectAll,
    clearSearch,
    onSearchInputChange,
    searchString,
    isSingleSelect,
    isSelectAllIndeterminate,
    toggleSelectedAll,
    selectedScreens,
  } = props;

  return (
    <>
      <div className="sp-search">
        <div className="sp-search-box">
          <Search
            onClear={clearSearch}
            onChange={onSearchInputChange}
            placeholder="Search Screens"
            showNoResults={false}
            value={searchString}
          />
        </div>
        {!isSingleSelect && (
          <div className="sp-search-checkbox">
            <Popover
              trigger={
                <Button icon borderless onClick={toggleSelectedAll}>
                  {isSelectAll ? (
                    <Icon name="checkbox-checked" />
                  ) : isSelectAllIndeterminate ? (
                    <Icon name="checkbox-deselect" />
                  ) : (
                    <Icon name="checkbox-empty" />
                  )}
                </Button>
              }
              content={
                selectedScreens.length === 0 ? "Select All" : "Deselect All"
              }
              className="checkbox"
              position="top center"
              inverted
            />
          </div>
        )}
      </div>
    </>
  );
};
