import { withApollo } from "@apollo/client/react/hoc";
import {
  UpdatePlaylistEmbedMutationFn,
  UpdateSharePlaylistToAllSpacesMutationFn,
  UpdateSharePlaylistToSpacesMutationFn,
  useUpdatePlaylistEmbedMutation,
  useUpdateSharePlaylistToAllSpacesMutation,
  useUpdateSharePlaylistToSpacesMutation,
} from "../../../types.g";
import { compose } from "../../../utils/compose";

export interface ApolloProps {
  updateSharePlaylistToAllSpaces: UpdateSharePlaylistToAllSpacesMutationFn;
  updatePlaylistEmbed: UpdatePlaylistEmbedMutationFn;
  updateSharePlaylistToSpaces: UpdateSharePlaylistToSpacesMutationFn;
}

export const withData = compose(withApollo, (Component) => (props) => {
  const [updatePlaylistEmbed] = useUpdatePlaylistEmbedMutation();
  const [
    updateSharePlaylistToAllSpaces,
  ] = useUpdateSharePlaylistToAllSpacesMutation();
  const [
    updateSharePlaylistToSpaces,
  ] = useUpdateSharePlaylistToSpacesMutation();

  return (
    <Component
      {...props}
      updatePlaylistEmbed={updatePlaylistEmbed}
      updateSharePlaylistToAllSpaces={updateSharePlaylistToAllSpaces}
      updateSharePlaylistToSpaces={updateSharePlaylistToSpaces}
    />
  );
});
