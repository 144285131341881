import React from "react";
import { FormattedMessage } from "react-intl";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Input, Button } from "@screencloud/screencloud-ui-components";
import { EmbedShareOptions } from "..";

interface CopyEmbedProps {
  embedSharedOption: EmbedShareOptions;
  isLinkCopied: boolean;
  setIsLinkCopied: (isLinkCopied: boolean) => void;
  embededUrl: string;
  openEmbedSharedChannel: () => void;
}

const CopyEmbed: React.FC<CopyEmbedProps> = ({
  embedSharedOption,
  isLinkCopied,
  setIsLinkCopied,
  embededUrl,
  openEmbedSharedChannel,
}) => {
  const handleCopy = () => {
    if (embedSharedOption !== EmbedShareOptions.Disabled) {
      setIsLinkCopied(true);
    }
  };

  return (
    <div className="copy-embed">
      <Input
        action={
          <CopyToClipboard onCopy={handleCopy} text={embededUrl}>
            <Button disabled={embedSharedOption === EmbedShareOptions.Disabled}>
              {isLinkCopied ? (
                <FormattedMessage
                  id="common.text.copied"
                  defaultMessage="Copied"
                />
              ) : (
                <FormattedMessage id="common.text.copy" defaultMessage="Copy" />
              )}
            </Button>
          </CopyToClipboard>
        }
        value={embededUrl}
        disabled={embedSharedOption === EmbedShareOptions.Disabled}
      />
      <Button
        className="btn-embed"
        disabled={embedSharedOption === EmbedShareOptions.Disabled}
        onClick={openEmbedSharedChannel}
      >
        <FormattedMessage id="common.text.embed" defaultMessage="Embed" />
      </Button>
    </div>
  );
};

export { CopyEmbed };
