import {
  GetImportJobsDocument,
  useDeleteDuplicateJobMutation,
} from "../../types.g";

export interface UseDeleteDuplicateJob {
  deleteDuplicateJob: (jobId: string) => void;
}

export const useDeleteDuplicateJob = (): UseDeleteDuplicateJob => {
  const [deleteDuplicateJobMutation] = useDeleteDuplicateJobMutation();

  const deleteDuplicateJob = (jobId: string) => {
    deleteDuplicateJobMutation({
      variables: { input: { jobId } },
      refetchQueries: [GetImportJobsDocument],
    });
  };
  return { deleteDuplicateJob };
};
