import { useEffect } from "react";
import { Styled } from "./styles";
import { Icon } from "@screencloud/screencloud-ui-components";
import { useAppContext } from "src/hooks/useAppContext";
import { getWhiteLabelStyles } from "src/helpers/whiteLabel";

declare global {
  interface Window {
    Headway: {
      init: (any) => void;
    };
  }
}

const ChangeLogs = () => {
  const context = useAppContext();
  const whiteLabelStyles = getWhiteLabelStyles(context);

  useEffect(() => {
    const HW_config = {
      selector: ".changelogs", // CSS selector where to inject the badge
      account: "JRpdXx",
      translations: {
        footer: " ",
      },
    };

    // Create a script element and set its attributes
    const script = document.createElement("script");
    script.src = "https://cdn.headwayapp.co/widget.js";
    script.async = true;

    // Append the script to the document's head
    document.head.appendChild(script);

    // Initialize Headway after the script is loaded
    script.onload = () => {
      window.Headway.init(HW_config);
    };

    // Cleanup by removing the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Styled
      activeItemColor={whiteLabelStyles?.activeItemColor}
      activeTextColor={whiteLabelStyles?.activeTextColor}
      textColor={whiteLabelStyles?.textColor}
      data-testid="changelogs"
      className="changelogs"
    >
      <Icon name="news" className="icon" />
    </Styled>
  );
};

export default ChangeLogs;
