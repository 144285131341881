import { isUuid } from "@screencloud/uuid";
import { Roles } from "../../state/session/StudioSession";

export function isSelfServeMigrationPath(pathName: string): boolean {
  const pathSections = pathName
    .trim()
    .toLowerCase()
    .replaceAll(/^(\/+)|(\/+$)|(\?.+$)/g, "")
    .split("/");
  return (
    Array.isArray(pathSections) &&
    pathSections.length >= 2 &&
    pathSections[0] === "migrate" &&
    isUuid(pathSections[1])
  );
}

export function isSelfServeMigrationSession(
  pathName: string,
  claimRole: Roles
): boolean {
  return isSelfServeMigrationPath(pathName) && claimRole === Roles.GUEST;
}
