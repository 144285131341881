import { Icon, Popover } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { Styled } from "./styles";

interface Props {
  showDescription?: boolean;
}

export const ChannelEditCoverTitle = (props: Props) => {
  const { showDescription } = props;
  return (
    <Styled showDescription={showDescription}>
      <FormattedMessage
        id="channels.change_cover"
        defaultMessage="Change Cover"
      />
      {showDescription ? (
        <div className="description">
          <FormattedMessage
            id="channels.change_cover_description"
            defaultMessage="Recommended cover size is 450 × 600."
          />
        </div>
      ) : (
        <Popover
          data-testid="cover-dimension-info"
          inverted
          position="top center"
          content={
            <FormattedMessage
              id="channels.change_cover_demensions"
              defaultMessage="Dimensions 450 x 600"
            />
          }
          trigger={<Icon name="info" />}
        />
      )}
    </Styled>
  );
};
