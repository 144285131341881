import {
  Button,
  Icon,
  ModalSize,
  Tab,
  ZoneLayout,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { sortLayoutsByZone } from "src/domains/channel/layouts/sortLayoutsByZone";
import { AppContext } from "../../AppContextProvider/AppContext";
import {
  BLANK_ZONES_LAYOUT_ID,
  UUID,
  VanguardZoneLayoutNames,
  ZoneLayoutNames,
} from "../../constants/constants";
import { PrimaryButton } from "../../helpers/whiteLabel";
import client from "../../state/apolloClient";
import {
  AllChannelsByLayoutIdDocument,
  AllChannelsByLayoutIdQuery,
  AllChannelsByLayoutIdQueryVariables,
  AvailableLayoutsForSizeDocument,
  Layout,
  LayoutBySpaceIdQueryDocument,
  Maybe,
} from "../../types.g";
import { ApolloProps, withData } from "./apollo";
import CustomLayoutConfig, { CustomLayoutData } from "./CustomLayoutConfig";
import {
  StyledActiveChannelLayouts,
  StyledLayout,
  StyledRevertLayoutModal,
} from "./styles";

export interface LayoutPickerProps extends ApolloProps {
  channelId: string;
  onSelectLayout: (
    id: string,
    zones: number,
    isRevert?: { isRevertToSingleZone: boolean },
    isCloseModel?: boolean
  ) => void;
  selectedId: string;
  router?: any;
  height: number;
  width: number;
  isParentChannel: boolean;
}

export interface LayoutPickerState {
  activeTabIndex: number;
  customLayout: null | {
    id: string;
    type: CustomLayoutActions;
  };
  selectedLayout: { id: string; zones: number; type: LayoutType } | null;
  defaultLayout: { id: string; zones: number; type: LayoutType } | null;
  disableSelectButton: boolean;
}

export enum LayoutType {
  CUSTOM = "CUSTOM",
  COMMON = "COMMON",
}

export enum CustomLayoutActions {
  UPDATE_LAYOUT = "UPDATE_LAYOUT",
  CREATE_LAYOUT = "CREATE_LAYOUT",
}

class LayoutPicker extends React.PureComponent<
  LayoutPickerProps,
  LayoutPickerState
> {
  public static contextType = AppContext;
  public static getDerivedStateFromProps(
    nextProps: LayoutPickerProps,
    prevState: LayoutPickerState
  ) {
    return null;
  }
  public context: AppContextType;

  constructor(props) {
    super(props);
    let defaultLayout;
    const singleZoneLayout =
      ZoneLayoutNames[`common_layout_landscape_1_zone_type_a`];
    if (props.layoutsData.layoutsBySpaceId) {
      defaultLayout = props.layoutsData.layoutsBySpaceId.nodes.find(
        (layout) => !!layout && layout.name === singleZoneLayout.common_name
      );
    }

    this.state = {
      activeTabIndex: 0,
      customLayout: null,
      selectedLayout: props.layoutsData.layoutsBySpaceId.nodes.find(
        (layout) => layout!.id === props.selectedId
      ),
      disableSelectButton: false,
      defaultLayout: {
        id: defaultLayout.id,
        zones: defaultLayout.zones,
        type: LayoutType.COMMON,
      },
    };
  }

  public componentDidMount() {
    const { availableLayoutsData, layoutsData, selectedId } = this.props;
    if (availableLayoutsData && availableLayoutsData.availableLayoutsForSize) {
      const { customs } = this.separateLayoutsByType(
        layoutsData.layoutsBySpaceId?.nodes as Maybe<Layout>[]
      );
      const isCustomLayoutActive = customs.some(
        (layout) => layout!.id === selectedId
      );

      if (isCustomLayoutActive) {
        this.setState({
          activeTabIndex: 1,
        });
      }
    }

    if (layoutsData && layoutsData.layoutsBySpaceId) {
      const { customs } = this.separateLayoutsByType(
        layoutsData.layoutsBySpaceId?.nodes as Maybe<Layout>[]
      );
      const isCustomLayoutActive = customs.some(
        (layout) => layout!.id === selectedId
      );

      if (isCustomLayoutActive) {
        this.setState({
          activeTabIndex: 1,
        });
      }
    }
  }

  public componentDidUpdate(
    prevProps: LayoutPickerProps,
    prevState: LayoutPickerState
  ) {
    const { selectedLayout, activeTabIndex } = this.state;

    if (
      (!selectedLayout || selectedLayout?.id !== prevProps.selectedId) &&
      activeTabIndex === prevState.activeTabIndex
    ) {
      this.setState({ disableSelectButton: false });
    } else {
      this.setState({ disableSelectButton: true });
    }
  }

  public separateLayoutsByType = (allLayouts: Maybe<Layout>[]) => {
    const customs = (allLayouts.filter(
      (layout) =>
        !!layout &&
        !ZoneLayoutNames[layout.name] &&
        !VanguardZoneLayoutNames[layout.name]
    ) || []) as Maybe<Layout>[];
    const commons = (allLayouts.filter(
      (layout) => !!layout && ZoneLayoutNames[layout.name]
    ) || []) as Maybe<Layout>[];
    const vanguardCommons = (allLayouts.filter(
      (layout) => !!layout && VanguardZoneLayoutNames[layout.name]
    ) || []) as Maybe<Layout>[];

    return { commons, vanguardCommons, customs };
  };

  public onSetSelectLayout(id: string, zones: number, type: LayoutType) {
    this.setState({
      selectedLayout: {
        id,
        type,
        zones,
      },
    });
  }

  public onSaveCustomLayout = async (
    customLayout: CustomLayoutData,
    action: CustomLayoutActions,
    id: string
  ) => {
    const config = JSON.parse(customLayout.configString);
    const input = {
      config: {
        css: customLayout.cssString,
        height: config.height,
        is_flexible: config.is_flexible,
        is_scalable: config.is_scalable,
        name: customLayout.name,
        width: config.width,
        zones: config.zones,
      },
      spaceId: this.context.user.settings.spaceId,
    };

    switch (action) {
      case CustomLayoutActions.CREATE_LAYOUT:
        await this.props
          .createLayout({
            refetchQueries: [
              {
                query: LayoutBySpaceIdQueryDocument,
                variables: { spaceId: this.context.user.settings.spaceId },
              },
              {
                query: AvailableLayoutsForSizeDocument,
                variables: {
                  spaceId: this.context.user.settings.spaceId,
                  width: this.props.width,
                  height: this.props.height,
                },
              },
            ],
            variables: {
              input,
            },
          })
          .then((data) => {
            this.context.modal.closeFullscreenModal();
            if (
              data &&
              data.data &&
              data.data.createLayout &&
              data.data.createLayout.layout
            ) {
              this.setState({ activeTabIndex: 1 });
            }
          });
        break;
      case CustomLayoutActions.UPDATE_LAYOUT:
        this.props.updateLayout({
          refetchQueries: [
            {
              query: LayoutBySpaceIdQueryDocument,
              variables: { spaceId: this.context.user.settings.spaceId },
            },
            {
              query: AvailableLayoutsForSizeDocument,
              variables: {
                spaceId: this.context.user.settings.spaceId,
                width: this.props.width,
                height: this.props.height,
              },
            },
          ],
          variables: {
            input: {
              ...input,
              id,
            },
          },
        });
        this.context.modal.closeFullscreenModal();
        break;
      default:
    }
  };

  public openCustomLayoutConfig = (
    event: React.MouseEvent,
    action: CustomLayoutActions,
    id: string
  ) => {
    event.stopPropagation();
    const { layoutsBySpaceId } = this.props.layoutsData;
    const originalLayout = layoutsBySpaceId?.nodes.find(
      (layout) => !!layout && layout.id === id
    );
    const defaultLayoutConfig = {
      css: `<style>#zone1 { width: 50%; height: 50%; top: 0; left: 0; z-index: 10; position: absolute; } #zone2 { width: 50%; height: 50%; top: 0; left: 50%; z-index: 10; position: absolute; } #zone3 { width: 50%; height: 50%; top: 50%; left: 0; z-index: 10; position: absolute; } #zone4 { width: 50%; height: 50%; top: 50%; left: 50%; z-index: 10; position: absolute; } </style>`,
      name: "Custom Layout",
      width: 1920,
      height: 1080,
      zones: [
        { id: "zone1", name: "Top Left" },
        { id: "zone2", name: "Top Right" },
        { id: "zone3", name: "Bottom Left" },
        { id: "zone4", name: "Bottom Right" },
      ],
      is_flexible: true,
      is_scalable: true,
    };
    const layoutName = originalLayout
      ? originalLayout.config.name
      : "Custom Layout";
    const layoutConfig = originalLayout
      ? originalLayout.config
      : defaultLayoutConfig;
    this.context.modal.openFullscreenModal(
      <>
        <CustomLayoutConfig
          name={layoutName}
          config={layoutConfig}
          width={this.props.width}
          height={this.props.height}
          onSave={(layout) => this.onSaveCustomLayout(layout, action, id)}
          onClose={this.closeLayoutConfig}
        />
      </>,
      {
        opts: {
          className: "layout-editor-modal app-config-modal",
          size: ModalSize.FULLSCREEN,
        },
      }
    );
  };

  public closeLayoutConfig = () => {
    this.context.modal.closeFullscreenModal();
  };

  public onContinue = async (currentActiveLayout: Layout) => {
    const mainLayout = this.props.layoutsData.layoutsBySpaceId?.nodes.find(
      (layout) => layout!.name === "common_layout_landscape_1_zone_type_a"
    );
    const { selectedLayout } = this.state;
    if (this.state.selectedLayout!.id === mainLayout!.id) {
      const confirmRevertZone = await this.context.modal.confirm(
        <StyledRevertLayoutModal>
          <div className="revert-layout-preview">
            <div className="current-layout">
              <ZoneLayout height={this.props.height} width={this.props.width} />
            </div>
            <div>
              <Icon name="arrow-right" />
            </div>
            <div>
              <ZoneLayout
                config={currentActiveLayout?.config}
                height={this.props.height}
                width={this.props.width}
              />
            </div>
          </div>
          <h3>
            <FormattedMessage
              id="channels.layout.revert_to_single_zone"
              defaultMessage="Reverting to Single Zone?"
            />
          </h3>
          <p>
            {this.props.isParentChannel ? (
              <FormattedMessage
                id="channels.layout.parent_channel_revert_zone_help_message"
                defaultMessage="We will keep your existing playing content in the main zone. This channel is shared across multiple spaces, so updating the layout will also change the appearance of this channel in other spaces."
              />
            ) : (
              <FormattedMessage
                id="channels.layout.revert_zone_help_message"
                defaultMessage="We will keep your existing playing content in the main zone and are you sure you want to continue? This action cannot be undone."
              />
            )}
          </p>
        </StyledRevertLayoutModal>,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.confirm"
              defaultMessage="Confirm"
            />
          ),
        }
      );

      if (confirmRevertZone.confirm) {
        this.onRevert();
        this.context.modal.closeFullscreenModal();
      }
    } else if (currentActiveLayout.zones > this.state.selectedLayout!.zones) {
      const selectedLayoutConfig = this.props.layoutsData.layoutsBySpaceId?.nodes.find(
        (layout) => layout!.id === selectedLayout!.id
      );
      const confirmZoneChange = await this.context.modal.confirm(
        <StyledRevertLayoutModal>
          <div className="revert-layout-preview">
            <div className="current-layout">
              <ZoneLayout
                config={currentActiveLayout.config}
                height={this.props.height}
                width={this.props.width}
              />
            </div>
            <div>
              <Icon name="arrow-right" />
            </div>
            <div>
              <ZoneLayout
                config={
                  selectedLayoutConfig ? selectedLayoutConfig.config : null
                }
                height={this.props.height}
                width={this.props.width}
              />
            </div>
          </div>
          <h3>
            <FormattedMessage
              id="common.text.warning"
              defaultMessage="Warning!"
            />
          </h3>
          <p>
            {this.props.isParentChannel ? (
              <FormattedMessage
                id="channels.layout.parent_channel_revert_layout_warning_message"
                defaultMessage="The selected layout has fewer zones than your current setup. Content in some zones may be lost. This channel is shared across multiple spaces, so updating the layout will also change the appearance of this channel in other spaces."
              />
            ) : (
              <FormattedMessage
                id="channels.layout.revert_layout_warning_message"
                defaultMessage="The selected layout has fewer zones than your current setup. Content in some zones may be lost. Are you sure you want to proceed? "
              />
            )}
          </p>
        </StyledRevertLayoutModal>,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.confirm"
              defaultMessage="Confirm"
            />
          ),
        }
      );

      if (confirmZoneChange.confirm) {
        if (selectedLayout && selectedLayout.id !== this.props.selectedId) {
          this.props.onSelectLayout(selectedLayout.id, selectedLayout.zones, {
            isRevertToSingleZone: false,
          });
        }
      }
    } else if (this.props.isParentChannel) {
      const { confirm } = await this.context.modal.confirm(
        <>
          <h2>
            <FormattedMessage
              id="channels.layout.parent_zones_change_heading"
              defaultMessage="Update layout?"
            />
          </h2>
          <p>
            <FormattedMessage
              id="channels.layout.parent_zones_change"
              defaultMessage="This channel is shared with other spaces. Updating the layout will change the channel appearance in other spaces as well."
            />
          </p>
        </>,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.confirm"
              defaultMessage="Confirm"
            />
          ),
        }
      );
      if (confirm) {
        if (selectedLayout && selectedLayout.id !== this.props.selectedId) {
          this.props.onSelectLayout(selectedLayout.id, selectedLayout.zones);
          this.context.modal.closeModals();
        }
      }
    } else {
      if (selectedLayout) {
        this.props.onSelectLayout(selectedLayout.id, selectedLayout.zones);
        this.context.modal.closeModals();
      }
    }
  };

  public onRevert = (closeModal = true) => {
    const { onSelectLayout } = this.props;
    const { defaultLayout } = this.state;
    onSelectLayout(
      defaultLayout!.id,
      defaultLayout!.zones,
      { isRevertToSingleZone: true },
      closeModal
    );
  };

  public onRemoveCustomLayout = async (
    id: string,
    isCurrentLayout: boolean
  ) => {
    const { deleteLayout } = this.props;
    const { defaultLayout } = this.state;
    await deleteLayout({
      refetchQueries: [
        {
          query: LayoutBySpaceIdQueryDocument,
          variables: { spaceId: this.context.user.settings.spaceId },
        },
        {
          query: AvailableLayoutsForSizeDocument,
          variables: { spaceId: this.context.user.settings.spaceId },
        },
      ],
      variables: {
        input: {
          id,
          spaceId: this.context.user.settings.spaceId,
        },
      },
    });
    if (isCurrentLayout) {
      this.onRevert(false);
      this.onSetSelectLayout(
        defaultLayout!.id,
        defaultLayout!.zones,
        LayoutType.COMMON
      );
      this.setState({ activeTabIndex: 0 });
    }
  };

  public channelByLayout = async (layoutId: UUID) => {
    const { data } = await client.query<
      AllChannelsByLayoutIdQuery,
      AllChannelsByLayoutIdQueryVariables
    >({
      fetchPolicy: "network-only",
      query: AllChannelsByLayoutIdDocument,
      variables: {
        layoutId,
      },
    });
    return data && data.allChannels && data.allChannels.nodes;
  };

  public onConfirmDeleteLayout = async (
    event: React.MouseEvent,
    layout: Layout
  ) => {
    event.stopPropagation();

    const currentLayout = this.props.layoutsData.layoutsBySpaceId?.nodes.find(
      (layoutData) => layoutData!.id === this.props.selectedId
    );
    const isCurrentLayout = layout.id === currentLayout?.id;
    const channelByLayout = await this.channelByLayout(layout.id);
    const isCurrentLayoutUseByOnlyCurrentChannel =
      isCurrentLayout &&
      channelByLayout?.length === 1 &&
      channelByLayout[0].id === this.props.channelId;
    const uniqeChannelByLayout = Array.from(
      new Set(channelByLayout?.map((ch) => ch.name))
    );

    if (
      channelByLayout &&
      channelByLayout.length > 0 &&
      !isCurrentLayoutUseByOnlyCurrentChannel
    ) {
      const confirmRevertLayout = await this.context.modal.confirm(
        <StyledActiveChannelLayouts>
          <strong>{layout.name}</strong>{" "}
          <FormattedMessage
            id="channels.layout_used_by_channel"
            defaultMessage="is currently being used by these channels"
          />
          :
          <ul>
            {uniqeChannelByLayout.map((channel, index) => {
              if (channel) {
                return <li key={`channel-${index}`}>{channel}</li>;
              }
              return;
            })}
          </ul>
          <FormattedMessage
            id="channels.delete_layout_warning"
            defaultMessage="These channels will lose access to this layout and revert to using the Single Zone layout."
          />
        </StyledActiveChannelLayouts>,
        {
          confirm: (
            <FormattedMessage
              id="channels.delete_this_layout"
              defaultMessage="I understand, delete this layout"
            />
          ),
          header: (
            <FormattedMessage
              id="common.text.are_you_sure"
              defaultMessage="Are you sure?"
            />
          ),
          isDanger: true,
        }
      );
      if (confirmRevertLayout.confirm) {
        await this.onRemoveCustomLayout(layout.id, isCurrentLayout);
      }
    } else {
      const { confirm } = await this.context.modal.confirm(
        <>
          <h2>
            <FormattedMessage
              id="ui_component.confirm.delete_heading_name"
              defaultMessage="Delete {name}?"
              values={{ name: layout.name }}
            />
          </h2>
          <p>
            <FormattedMessage
              id="ui_component.confirm.delete_message_name"
              defaultMessage="{name} will be deleted permanently and this action cannot be undone."
              values={{ name: <strong>{layout.name}</strong> }}
            />
          </p>
        </>,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.delete"
              defaultMessage="Delete"
            />
          ),
          isDanger: true,
        }
      );
      if (confirm) {
        await this.onRemoveCustomLayout(layout.id, isCurrentLayout);
      }
    }
  };

  public renderCustomLayoutList = (layouts: Maybe<Layout>[]) => {
    const { height, width } = this.props; // channel dimension
    const { selectedLayout } = this.state;
    const sortedLayouts = layouts.sort((a, b) => a!.zones - b!.zones);

    const fitPreviewScale = 160 / (width > height ? width : height);
    const channelPreviewWidth = Math.ceil(width * fitPreviewScale);
    const channelPreviewHeight = Math.ceil(height * fitPreviewScale);

    const canUpdateLayout = this.context.currentPermissions.validateCurrentSpace(
      "layout",
      "update"
    );
    const canDeleteLayout = this.context.currentPermissions.validateCurrentSpace(
      "layout",
      "delete"
    );

    return sortedLayouts.map(
      (layout, idx) =>
        layout && (
          <Button
            disabled={!canUpdateLayout}
            onClick={() =>
              this.onSetSelectLayout(layout.id, layout.zones, LayoutType.CUSTOM)
            }
            className={`layout-preview ${
              selectedLayout && selectedLayout.id === layout.id
                ? "selected"
                : ""
            }`}
            key={"layout-" + idx}
          >
            <div className="button-wrapper">
              <div className="custom-layout-actions">
                {canUpdateLayout && (
                  <Icon
                    onClick={(event) =>
                      this.openCustomLayoutConfig(
                        event,
                        CustomLayoutActions.UPDATE_LAYOUT,
                        layout.id
                      )
                    }
                    name="edit"
                    className="edit-btn"
                  />
                )}
                {canDeleteLayout && (
                  <Icon
                    className="remove-btn"
                    onClick={(event) =>
                      this.onConfirmDeleteLayout(event, layout)
                    }
                    name="trash"
                  />
                )}
              </div>
              <div
                className="item-layout-preview"
                style={{
                  width: channelPreviewWidth,
                  height: channelPreviewHeight,
                }}
              >
                <ZoneLayout
                  config={layout.config}
                  name={layout.name}
                  activeZone=""
                  width={
                    layout.config.is_flexible ? width : layout.config.width
                  }
                  height={
                    layout.config.is_flexible ? height : layout.config.height
                  }
                  maxWidth={channelPreviewWidth}
                  maxHeight={channelPreviewHeight}
                  channelHeight={height}
                  channelWidth={width}
                  isFlexible={layout.config.is_flexible}
                  isScalable={layout.config.is_scalable}
                />
              </div>
            </div>
            <div className="layout-info">
              <span className="title">{layout.name}</span>
            </div>
          </Button>
        )
    );
  };

  public renderCommonLayoutList = (layouts: Maybe<Layout>[]) => {
    const { selectedLayout } = this.state;
    const { height, width } = this.props;
    const canUpdateLayout = this.context.currentPermissions.validateCurrentSpace(
      "layout",
      "update"
    );
    return layouts.map(
      (layout, idx) =>
        layout && (
          <Button
            data-testid={layout.name}
            disabled={!canUpdateLayout}
            onClick={() =>
              this.onSetSelectLayout(layout.id, layout.zones, LayoutType.COMMON)
            }
            className={`layout-preview ${
              selectedLayout && selectedLayout.id === layout.id
                ? "selected"
                : ""
            }`}
            key={"layout-" + idx}
          >
            <div className="button-wrapper">
              <ZoneLayout
                name={layout.name}
                config={layout.config}
                activeZone=""
                width={width}
                height={height}
              />
              <span className="title">{layout.config.name}</span>
            </div>
          </Button>
        )
    );
  };

  public renderCommonLayouts = (
    layouts: Maybe<Layout>[],
    vanguardLayouts: Maybe<Layout>[]
  ) => {
    const sortedVanguardLayouts = sortLayoutsByZone(vanguardLayouts);
    const sortedLayouts = sortLayoutsByZone(layouts);

    return (
      <>
        <div className="common-layouts-container new-common-layouts">
          <p>
            <strong>New</strong>
          </p>
          {this.renderCommonLayoutList(sortedVanguardLayouts)}
        </div>
        <div className="common-layouts-container">
          <p>
            <strong>More Layouts</strong>
          </p>
          {this.renderCommonLayoutList(sortedLayouts)}
        </div>
      </>
    );
  };

  public handleTabChange = (
    event: React.SyntheticEvent,
    activeIndex: number
  ) => {
    this.setState({
      activeTabIndex: activeIndex,
      disableSelectButton: true,
    });
  };

  public render() {
    const { activeTabIndex, selectedLayout, disableSelectButton } = this.state;
    const {
      availableLayoutsData,
      layoutsData,
      height,
      width,
      selectedId,
    } = this.props;
    const currentActiveLayout = layoutsData.layoutsBySpaceId?.nodes.find(
      (layout) => layout!.id === selectedId
    );
    const canUpdateLayout = this.context.currentPermissions.validateCurrentSpace(
      "layout",
      "update"
    );
    const canCreateLayout = this.context.currentPermissions.validateCurrentSpace(
      "layout",
      "create"
    );

    if (
      availableLayoutsData &&
      availableLayoutsData.availableLayoutsForSize &&
      layoutsData &&
      layoutsData.layoutsBySpaceId
    ) {
      const { commons, vanguardCommons } = this.separateLayoutsByType(
        availableLayoutsData.availableLayoutsForSize.nodes as Maybe<Layout>[]
      );
      const { customs } = this.separateLayoutsByType(
        layoutsData.layoutsBySpaceId.nodes as Maybe<Layout>[]
      );
      return (
        <StyledLayout>
          <div className="layout-selector">
            <Tab
              className="tabs"
              activeIndex={this.state.activeTabIndex}
              menu={{ secondary: true, pointing: true }}
              onTabChange={(event, data) =>
                this.handleTabChange(event, data.activeIndex as number)
              }
              panes={[
                {
                  menuItem: "All Layouts",
                  render: () => (
                    <div className="zone-list">
                      {this.renderCommonLayouts(commons, vanguardCommons)}
                    </div>
                  ),
                },
                {
                  menuItem: "Custom Layouts",
                  render: () => (
                    <div className="zone-list">
                      {this.renderCustomLayoutList(customs)}
                      {canCreateLayout && (
                        <>
                          <Button
                            className="layout-preview custom-layout-new"
                            onClick={(event) =>
                              this.openCustomLayoutConfig(
                                event,
                                CustomLayoutActions.CREATE_LAYOUT,
                                BLANK_ZONES_LAYOUT_ID
                              )
                            }
                          >
                            <div className="button-wrapper">
                              <Icon name="plus" />
                              <ZoneLayout width={width} height={height} />
                              <span className="title">New Layout</span>
                            </div>
                          </Button>
                          <div className="mobile-message">
                            <h3>Whoops!</h3>
                            <p>
                              Adding or editing custom layouts is not currently
                              supported on mobile
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div className="md-footer">
            {activeTabIndex === 0 && (
              <Button
                onClick={(event) =>
                  selectedLayout &&
                  this.openCustomLayoutConfig(
                    event,
                    CustomLayoutActions.CREATE_LAYOUT,
                    selectedLayout.id
                  )
                }
                disabled={!canCreateLayout}
                className="btn-customize"
              >
                <FormattedMessage
                  id="common.text.customize"
                  defaultMessage="Customize"
                />
              </Button>
            )}
            <PrimaryButton
              disabled={!canUpdateLayout || disableSelectButton}
              onClick={() => this.onContinue(currentActiveLayout as Layout)}
            >
              <FormattedMessage
                id="common.text.select"
                defaultMessage="Select"
              />
            </PrimaryButton>
          </div>
        </StyledLayout>
      );
    } else {
      return null;
    }
  }
}

export default withData(LayoutPicker);
