import { PureComponent } from "react";
import {
  ConnectDropTarget,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from "react-dnd";
import { MediaDropResult, MediaItemType } from "../../constants/mediaTypes";

export interface MediaFolderDragProps {
  connectDropTarget?: ConnectDropTarget;
  isDragging?: boolean;
  isOver?: boolean;
}

export interface MediaBreaCrumbItemProps extends MediaFolderDragProps {
  folderId: string;
  onDrop?: (dropResult: MediaDropResult) => void;
}

const dropTarget = {
  drop(props: MediaBreaCrumbItemProps, monitor: DropTargetMonitor) {
    const data = {
      destination: {
        mediaId: props.folderId || "",
        type: MediaItemType.MEDIA_FOLDER,
      },
      source: monitor.getItem(),
    };
    if (props.onDrop) {
      props.onDrop(data);
    }
  },
};

const dropCollect = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor
) => ({
  canDrop: monitor.canDrop(),
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

class MediaBreadCrumbItem extends PureComponent<MediaBreaCrumbItemProps, {}> {
  public render() {
    const { connectDropTarget, isOver } = this.props;
    return (
      connectDropTarget &&
      connectDropTarget(
        <div className={`droparea ${isOver ? "active" : ""}`}>
          {this.props.children}
        </div>
      )
    );
  }
}

export default DropTarget(
  MediaItemType.MEDIA,
  dropTarget,
  dropCollect
)(MediaBreadCrumbItem);
