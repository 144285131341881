export enum SortingType {
  Name,
}

export const SortingOption = {
  [SortingType.Name]: "name",
};

export const DISCOVER_APPS_TAG = "Discover Apps";
export const SPOTLIGHT_APPS_TAG = "Spotlight App";
export const POPULAR_APPS_TAG = "Popular Pick App";
export const ADDITIONAL_INFO_PRO = "Included with Pro & Enterprise plans";

export const APP_STORE_DISCOVER_PATH_NANE = "/apps-store/category/discover/";
export const DISCOVER_APPS_CATEGORY_ID = "discover";
export const ALL_APPS_CATEGORY_ID = "all apps";
export const PREMIUM_APPS_CATEGORY_ID = "premium";
