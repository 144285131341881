import { AppInstance, File } from "src/types.g";
import {
  getCurrentDateWithoutTimeZone,
  getDateWithoutTimeZoneFromUtcDate,
} from "./datetimeHelper";

export type Schedulable = Pick<File, "expireAt" | "availableAt"> &
  Pick<AppInstance, "expireAt" | "availableAt">;

export enum AvailabilityStatus {
  IS_PLAYING = "is-playing",
  IS_NOT_AVAILABLE = "is-not-available",
  IS_EXPIRED = "is-expired",
}

/**
 *
 * @param expiredAt
 * @returns DateWithoutTimezone, This implied that we should never take GMT into account at all.
 */
export const getExpiredDate = (schedulable: Pick<Schedulable, "expireAt">) =>
  getDateWithoutTimeZoneFromUtcDate(schedulable.expireAt);

/**
 *
 * @param availableAt
 * @returns DateWithoutTimezone, This implied that we should never take GMT into account at all.
 */
export const getAvailableDate = (
  schedulable: Pick<Schedulable, "availableAt">
) => getDateWithoutTimeZoneFromUtcDate(schedulable.availableAt);

/**
 * availableAt here is from database which is in GMT
 * We would like to conver it in NON-GMT
 */
export const isAvailable = (schedulable: Pick<Schedulable, "availableAt">) => {
  if (!schedulable?.availableAt) {
    return false;
  }

  const availableDate = getAvailableDate(schedulable);
  const currentDate = getCurrentDateWithoutTimeZone();

  // This is current date is greater than expired date
  return currentDate.compare(availableDate) === 1;
};

/**
 * expire at here is from database which is in GMT
 * We would like to conver it in NON-GMT
 */
export const isExpired = (schedulable: Pick<Schedulable, "expireAt">) => {
  if (!schedulable?.expireAt) {
    return false;
  }

  const expireDate = getExpiredDate(schedulable);
  const currentDate = getCurrentDateWithoutTimeZone();
  // This is current date is greater than expired date
  return currentDate.compare(expireDate) === 1;
};

/**
 * A function to return whether the content is expired or not.
 *
 * @param schedulable Scheduleable content with expireAt property
 * @param offsetMs Offset millisecond from current time to consider the content expired.
 * @returns true if content is expired, false if not.
 */
export const isExpiredWithOffset = (
  schedulable: Pick<Schedulable, "expireAt">,
  offsetMs: number
): boolean => {
  if (!schedulable?.expireAt) {
    return false;
  }

  const expireDate = getExpiredDate(schedulable);
  const currentDate = getCurrentDateWithoutTimeZone();

  return currentDate.diffMs(expireDate) >= offsetMs;
};

export const getAvailabilityStatus = (
  schedulable: Pick<Schedulable, "availableAt" | "expireAt">
): AvailabilityStatus => {
  if (isExpired(schedulable)) {
    return AvailabilityStatus.IS_EXPIRED;
  } else if (isAvailable(schedulable)) {
    return AvailabilityStatus.IS_PLAYING;
  }
  return AvailabilityStatus.IS_NOT_AVAILABLE;
};

export const hasSchedule = (
  schedulable: Pick<Schedulable, "availableAt" | "expireAt">
): boolean => {
  return schedulable.availableAt || schedulable.expireAt;
};
