import { Icon } from "@screencloud/screencloud-ui-components";
import { Component, TouchEvent } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { getOrientation } from "src/helpers/canvasHelper";
import { appInstanceIconUrl } from "../../helpers/appHelper";
import { AppInstance, AvailableAppInstanceFragment } from "../../types.g";
import { StyledDraggableWrapper } from "./styles";
import { hasSchedule } from "src/helpers/scheduleableHelper";
import AvailabilityStatusIcon from "../AvailabilityStatusIcon";
import Thumbnail from "../MediaThumbnail";
import { MediaInput } from "src/helpers/mediaHelper";

export enum AppTabPaneItemActionEnum {
  CLICK = "click",
  DRAG = "drag",
  PREVIEW = "preview",
}

export type AppTabPaneItemActions =
  | AppTabPaneItemActionEnum.CLICK
  | AppTabPaneItemActionEnum.DRAG
  | AppTabPaneItemActionEnum.PREVIEW;

export interface AppTabPaneItemProps {
  addItemToPlaylist: (
    app: AvailableAppInstanceFragment,
    action: AppTabPaneItemActions,
    iconUrl?: string | null
  ) => void;
  onShowAppConfigure: (app: AvailableAppInstanceFragment) => void;
  app: AvailableAppInstanceFragment;
  index: number;
  iconUrl?: string | null;
  isCanvas?: boolean;
}

const getItemStyle = (
  snapshot: DraggableStateSnapshot,
  draggableStyle: object | undefined
) => {
  const { isDragging } = snapshot;
  return {
    ...draggableStyle,
    backgroundColor: "#FFFFFF",
    boxShadow: isDragging ? "0 2px 15px 0 rgba(0,0,0,0.15)" : undefined,
  };
};

class AppTabPaneItem extends Component<AppTabPaneItemProps, {}> {
  public addItemToPlaylist = () => {
    this.props.addItemToPlaylist(
      this.props.app,
      AppTabPaneItemActionEnum.CLICK,
      this.props.iconUrl
    );
  };

  public handleDragAppItem = (app: AvailableAppInstanceFragment) => {
    this.props.addItemToPlaylist(app, AppTabPaneItemActionEnum.DRAG);
  };

  public onShowAppConfigure = () => {
    this.props.onShowAppConfigure(this.props.app);
  };

  public renderAvailabilityExpiryIcon(contentById) {
    return (
      hasSchedule(contentById) && (
        <AvailabilityStatusIcon
          data-testid="channel-media-scheduled"
          availableAt={contentById.availableAt}
          expireAt={contentById.expireAt}
          callback={this.onShowAppConfigure}
        />
      )
    );
  }

  public render() {
    const { app, isCanvas, index } = this.props;
    const showAppType = !isCanvas;
    const showCanvasOrientation = Boolean(isCanvas);
    const iconUrl = appInstanceIconUrl(app);
    const appItem = (
      <div key={`app-instance-list-${app.id}`} className="app-instance">
        <div
          className="app-instance-thumbnail"
          onClick={this.addItemToPlaylist}
        >
          {isCanvas ? (
            app.fileByThumbnailFileId ? (
              <Thumbnail
                className="medium"
                media={app.fileByThumbnailFileId as MediaInput}
                width={60}
                height={60}
                secureMediaPolicy={this.context.secureMediaPolicy}
              />
            ) : (
              <div
                className="thumbnail"
                style={{
                  backgroundImage: `url(${this.props.iconUrl || iconUrl})`,
                }}
              />
            )
          ) : (
            <div
              className="thumbnail"
              style={{
                backgroundImage: `url(${this.props.iconUrl || iconUrl})`,
              }}
            />
          )}
          <div className="overlay">
            <Icon name="plus" color={"#fff"} className="icon" />
          </div>
        </div>
        <div className="app-instance-info" onClick={this.onShowAppConfigure}>
          <div className="info">
            <div className="app-instance-title">{app.name}</div>
            {showAppType && (
              <span className="app-instance-type">
                App/{app?.appByAppId?.name}
              </span>
            )}
            {showCanvasOrientation && (
              <span className="canvas-orientation">
                {getOrientation(app as AppInstance)}
              </span>
            )}
          </div>
        </div>
        <div className="schedule">{this.renderAvailabilityExpiryIcon(app)}</div>
      </div>
    );
    return (
      <StyledDraggableWrapper key={app.id} className="row">
        <Draggable draggableId={app.id} index={index} isDragDisabled={false}>
          {(
            draggableProvided: DraggableProvided,
            draggableSnapshot: DraggableStateSnapshot
          ): JSX.Element => {
            const onMouseDown = () => {
              this.handleDragAppItem(this.props.app);
            };

            const onTouchStart = (() => {
              if (!draggableProvided.dragHandleProps) {
                // @ts-ignore
                return onTouchStart;
              }
              return (event: TouchEvent) => {
                if (draggableProvided.dragHandleProps) {
                  draggableProvided.dragHandleProps.onTouchStart(event);
                  this.handleDragAppItem(this.props.app);
                }
              };
            })();

            return (
              <>
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  onMouseDown={onMouseDown}
                  onTouchStart={onTouchStart}
                  style={getItemStyle(
                    draggableSnapshot,
                    draggableProvided.draggableProps.style
                  )}
                >
                  {appItem}
                </div>
                <div style={{ opacity: 0.2 }}>
                  {draggableSnapshot.isDragging && appItem}
                </div>
              </>
            );
          }}
        </Draggable>
      </StyledDraggableWrapper>
    );
  }
}

export default AppTabPaneItem;
