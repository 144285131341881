import { UUID } from "@screencloud/uuid";
import { AppContextState } from "../AppContextProvider/type";
import {
  CastedScreenInfo,
  ScreenDetailProps,
  CastedScreenInfoActions,
} from "../components/CastedScreenInfo";
import { FEATURE_FLAGS_ENUM } from "../constants/featureFlag";

export interface CastedScreen {
  id: UUID;
  name: string;
  deviceId?: string;
  deviceModel: string | null;
  spaceId?: UUID;
}

export const renderCastingStatus = ({
  castScreenData,
  callBack,
  context,
  totalConut,
  hideScreencount,
}: {
  castScreenData: CastedScreen[];
  callBack?: (data: string, action: CastedScreenInfoActions) => void;
  totalConut?: number;
  context: AppContextState;
  hideScreencount?: boolean;
}): JSX.Element | null => {
  if (castScreenData) {
    if (!shouldShowCastingStatus({ castScreenData, context })) {
      return null;
    }
    const screenDetailList: ScreenDetailProps[] = getScreenDetailList(
      castScreenData,
      context
    );
    return (
      <CastedScreenInfo
        callBack={callBack}
        screens={screenDetailList}
        totalCount={totalConut}
        hideScreenCount={hideScreencount}
      />
    );
  }
  return null;
};

export const isInCurrentSpace = ({
  cast,
  context,
}: {
  cast: CastedScreen;
  context: AppContextState;
}) => {
  return cast.spaceId ? cast.spaceId === context.currentSpace?.id : true;
};

export const shouldShowCastingStatus = ({
  castScreenData,
  context,
}: {
  castScreenData: CastedScreen[];
  context: AppContextState;
}) => {
  return (
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) &&
    castScreenData.length > 0
  );
};

export const getScreenDetailList = (
  castScreenData: CastedScreen[],
  context: AppContextState
): ScreenDetailProps[] => {
  const canCast = context.currentPermissions.validateCurrentSpace(
    "screen",
    "cast"
  );
  const screenDetailList: ScreenDetailProps[] = castScreenData
    .filter((cast) => isInCurrentSpace({ cast, context }))
    .map((screen) => ({
      deviceId: screen.deviceId ?? "",
      deviceModel: screen.deviceModel ?? "",
      screenId: screen.id,
      screenName: screen.name ?? "",
      disabled: !canCast,
    }));
  return screenDetailList;
};
