import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  ${Theme.default.borderRadius};
  background-color: ${Theme.color.white};
  padding: ${Theme.default.verticalPadding} ${Theme.default.horizontalPadding};
  margin: ${Theme.default.verticalMargin} 0;
  .app-banner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .app-logo-container {
    width: 60px;
    height: 60px;
    ${Theme.default.borderRadius};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .app-info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    padding: ${Theme.default.verticalPadding} ${Theme.default.horizontalPadding};

    .app-name {
      font-size: 1em;
      color: ${Theme.color.grey100};
    }
    .app-category {
      font-size: 0.75em;
      color: ${Theme.color.grey70};
    }
  }
  .app-link {
    align-self: center;
    font-size: 1em;
  }
  .app-link-icon {
    margin-left: 5px;
  }
`;
