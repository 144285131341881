import {
  DatetimeFormat,
  Icon,
  LoaderBar,
} from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { appInstanceIconUrl } from "../../helpers/appHelper";
import { WithAppInstances, withAppInstances } from "./withAppPicker";
import { AppPickerItem } from "./styles";
import DateTime from "../DateTime";
import { useEffect, useState } from "react";
import EmptyState from "../EmptyState";
import { updateAndToggleSelectedItems } from "../../helpers/updateAndToggleSelectedItemsHelper";
import { AppInstance, AvailableAppInstanceFragment } from "../../types.g";
import { useAppContext } from "../../hooks/useAppContext";

interface Props {
  selectedIds: string[];
  isMultipleSelect: boolean;
  callback: (ids: string[], selectedApps: AppInstance[]) => void;
}

export const AppInstancesPresentation = (props: Props & WithAppInstances) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedAppInstancesData, setSelectedAppInstancesData] = useState<
    (AvailableAppInstanceFragment | undefined)[]
  >([]);
  const {
    appInstances,
    loading,
    renderFetchMoreButton,
    isMultipleSelect,
  } = props;

  useEffect(() => {
    props?.callback(selectedIds, selectedAppInstancesData as AppInstance[]);
  }, [selectedIds, selectedAppInstancesData]);

  const onToggleSelection = (app: any) => {
    const { selectedArray } = updateAndToggleSelectedItems(
      app.id,
      app,
      selectedIds,
      selectedAppInstancesData,
      isMultipleSelect
    );
    const selectedApps = selectedArray.reduce(
      (allSelectedApps: AppInstance[], currentId) => {
        const appsId = appInstances.find(
          (appInstance) => appInstance.id === currentId
        );
        return [...allSelectedApps, appsId];
      },
      []
    );
    setSelectedIds(selectedArray);
    setSelectedAppInstancesData(selectedApps);
  };

  if (appInstances.length > 0 && !loading) {
    return (
      <>
        {appInstances.map((app) => {
          const iconUrl = appInstanceIconUrl(app);
          return (
            <AppPickerItem
              className="media-item"
              key={`apps-${app.id}`}
              onClick={() => onToggleSelection(app)}
              selected={selectedIds.includes(app.id)}
            >
              <div className="media-core">
                <div className="media-alpha">
                  <div className="thumbnail-preview">
                    <div className="thumbnail">
                      <div className="wrapper">
                        <img src={iconUrl} />
                      </div>
                    </div>
                  </div>
                  <div className="media-title">
                    <h3>{app.name}</h3>
                    <div>
                      <span className="media-item__date">
                        <FormattedMessage
                          id="common.text.created_on"
                          defaultMessage="Created on"
                        />{" "}
                        <DateTime
                          value={app.createdAt}
                          format={DatetimeFormat.Long}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="media-checked">
                <Icon name="checked-circle" />
              </div>
            </AppPickerItem>
          );
        })}
        <div>{renderFetchMoreButton}</div>
      </>
    );
  } else if (appInstances && !loading) {
    const context = useAppContext();
    const canCreateAppInstance = context.currentPermissions.validateCurrentSpace(
      "app_instance",
      "create"
    );

    return (
      <EmptyState section="apps">
        <h3>
          <FormattedMessage
            id={
              canCreateAppInstance
                ? `apps.empty_message`
                : `apps.empty_message_no_edit`
            }
            defaultMessage="You haven't used this app yet"
          />
        </h3>
        <p>
          <FormattedMessage
            id={
              canCreateAppInstance
                ? `apps.help_message`
                : `apps.help_message_no_edit`
            }
            defaultMessage="Create your first app instance"
          />
        </p>
      </EmptyState>
    );
  }
  return loading && <LoaderBar />;
};

export const AppInstances = withAppInstances(AppInstancesPresentation);
