import { Icon } from "@screencloud/screencloud-ui-components";
import { EntityType } from "@screencloud/signage-firestore-client";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { generateImgixThumbnail } from "../../helpers/mediaHelper";
import { subscribeToDocumentUpdates } from "../../state/liveUpdateManager";
import { SiteFragment, SiteType } from "../../types.g";
import DateTime from "../DateTime";

export interface SiteContentPickerItemProps {
  callBack: () => void;
  site: SiteFragment;
  secureMediaPolicy: string | undefined;
}

export interface SiteContentPickerItemState {}

export class SiteContentPickerItem extends Component<
  SiteContentPickerItemProps,
  SiteContentPickerItemState
> {
  private unsubscribeLiveUpdateFn?: () => void;

  constructor(props: SiteContentPickerItemProps) {
    super(props);
  }

  public componentDidMount() {
    this.subscribeToLiveUpdate();
  }

  public componentDidUpdate(props, state) {
    this.subscribeToLiveUpdate();
  }

  public componentWillUnmount() {
    this.unsubscribeFromLiveUpdate();
  }

  public subscribeToLiveUpdate() {
    this.unsubscribeFromLiveUpdate();

    if (
      this.props.site &&
      this.props.site.fileByThumbnailId &&
      this.props.site.fileByThumbnailId.id
    ) {
      this.unsubscribeLiveUpdateFn = subscribeToDocumentUpdates(
        this.props.site.fileByThumbnailId.orgId || "",
        EntityType.FILE,
        this.props.site.fileByThumbnailId.id,
        () => {
          this.props.callBack();
        },
        true
      );
    }
  }

  public unsubscribeFromLiveUpdate() {
    if (this.unsubscribeLiveUpdateFn) {
      this.unsubscribeLiveUpdateFn();
      this.unsubscribeLiveUpdateFn = undefined;
    }
  }

  public render(): JSX.Element | any {
    return (
      <>
        <div className="media-core">
          <div className="media-alpha">
            <div className="thumbnail-preview">
              <div className="thumbnail">
                <div className="wrapper">
                  <img
                    src={
                      (this.props.site &&
                        this.props.site.fileByThumbnailId &&
                        generateImgixThumbnail(
                          this.props.site.fileByThumbnailId,
                          this.props.secureMediaPolicy,
                          true
                        )) ||
                      ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="media-title">
              <h3 title={this.props.site?.name}>{this.props.site?.name}</h3>
              <div>
                <span className="media-item__date">
                  <FormattedMessage
                    id="common.text.created_on"
                    defaultMessage="Created on"
                  />{" "}
                  <DateTime
                    value={this.props.site && this.props.site.createdAt}
                  />
                </span>
                <span className="media-item__url">
                  {this.props.site && this.props.site.name}
                </span>
              </div>
            </div>
          </div>

          <div className="media-starred">
            <Icon
              name={this.props.site && this.props.site.isFavorite ? "star" : ""}
            />
          </div>

          <div className="media-type">
            {this.props.site && this.props.site.type === SiteType.Basic && (
              <FormattedMessage
                id="ui_component.site.type_internal_website"
                defaultMessage="Basic"
              />
            )}
            {this.props.site && this.props.site.type === SiteType.Cloud && (
              <FormattedMessage
                id="ui_component.site.type_cloud_render"
                defaultMessage="Cloud"
              />
            )}
            {this.props.site && this.props.site.type === SiteType.Secure && (
              <FormattedMessage
                id="ui_component.site.type_website"
                defaultMessage="Website"
              />
            )}
          </div>

          <div className="media-tags">{/* add tags here */}</div>
        </div>
        <div className="media-checked">
          <Icon name="checked-circle" />
        </div>
      </>
    );
  }
}

export default SiteContentPickerItem;
