import { datadogLogs } from "@datadog/browser-logs";
import { useAppContext } from "src/hooks/useAppContext";

export const initDatadog = () => {
  datadogLogs.init({
    clientToken: "pub755dd34ffe305ffe53d02f6197427cbf",
    service: "signage-next-frontend",
    env: import.meta.env.VITE_REACT_APP_ENV,

    // Just not send any logs by default
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
  });
};

export const useDatadog = () => {
  const context = useAppContext();
  datadogLogs.setGlobalContextProperty("orgId", context.currentOrg?.id);
  datadogLogs.setGlobalContextProperty("spaceId", context.currentSpace?.id);
  datadogLogs.setGlobalContextProperty("userId", context.currentUser?.id);
};

export const DatadogContext = () => {
  useDatadog();
  return <></>;
};
