import * as React from "react";
import { Styled } from "./styles";

export interface SideBarType {
  className?: string;
  children?: React.ReactNode;
  small?: boolean;
  id?: string;
}

const SidebarRight = ({ className, children, small, id }: SideBarType) => {
  return (
    <Styled className={className} small={small} id={id}>
      {children}
    </Styled>
  );
};

export default SidebarRight;
