import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
} from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { useAppContext } from "src/hooks/useAppContext";
import { useGetScreenSize } from "src/hooks/useGetScreenSize";
import { DuplicateDropdownItems } from "src/pages/Playlists/PlaylistDetail/PlaylistDetailHeader//DuplicateDropdownItems";

interface PlaylistDropdownProps {
  shouldDisplayCleanupButton?: boolean;
  onDeletePlaylist: () => void;
  onCleanupPlaylist: () => void;
  onDuplicatePlayList: () => void;
  duplicateToAnotherSpace: () => Promise<void>;
}

export const PlaylistDropdown = (props: PlaylistDropdownProps) => {
  const context = useAppContext();
  const { isMobileView } = useGetScreenSize();
  const {
    shouldDisplayCleanupButton,
    onDeletePlaylist,
    onCleanupPlaylist,
    onDuplicatePlayList,
    duplicateToAnotherSpace,
  } = props;
  const canDeletePlaylist = context.currentPermissions.validateCurrentSpace(
    "playlist",
    "delete"
  );

  const canUpdatePlaylist = context.currentPermissions.validateCurrentSpace(
    "playlist",
    "update"
  );
  const canCreatePlaylist = context.currentPermissions.validateCurrentSpace(
    "playlist",
    "create"
  );

  if ((!canCreatePlaylist || !canUpdatePlaylist) && !canDeletePlaylist) {
    return null;
  }

  const shouldShowDuplicateItems =
    isMobileView && (canUpdatePlaylist || canCreatePlaylist);

  return (
    <Dropdown
      direction="left"
      className="dropdown-options"
      icon={
        <Button icon>
          <Icon name="dots" />
        </Button>
      }
    >
      <DropdownMenu>
        {shouldShowDuplicateItems && (
          <DuplicateDropdownItems
            onDuplicatePlayList={onDuplicatePlayList}
            duplicateToAnotherSpace={duplicateToAnotherSpace}
          />
        )}
        {shouldDisplayCleanupButton && (
          <DropdownItem
            data-testid="cleanup-item-button"
            data-cy="cleanup-item-button"
            onClick={onCleanupPlaylist}
          >
            <Icon name="broom" />
            <FormattedMessage
              id="ui_component.common.label.cleanup"
              defaultMessage="Clear Expired Content"
            />
          </DropdownItem>
        )}

        {(shouldShowDuplicateItems || shouldDisplayCleanupButton) &&
          canDeletePlaylist && <DropdownDivider data-testid="divider" />}

        {canDeletePlaylist && (
          <DropdownItem
            data-testid="delete-item-button"
            onClick={onDeletePlaylist}
            className="danger"
          >
            <Icon name="trash" />
            <FormattedMessage
              id="ui_component.common.label.delete_playlist"
              defaultMessage="Delete Playlist"
            />
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
