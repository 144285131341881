import { DuplicateJobStatus, GetImportJobsPayload } from "src/types.g";

export type ImportJob = GetImportJobsPayload;

export function isProcessing(importJob: Pick<ImportJob, "status">) {
  return (
    importJob.status === DuplicateJobStatus.Processing ||
    importJob.status === DuplicateJobStatus.NotStarted ||
    importJob.status === DuplicateJobStatus.Queue
  );
}

export function isFailed(importJob: ImportJob) {
  return importJob.status === DuplicateJobStatus.Failed;
}

export function isCompleted(importJob: ImportJob) {
  return importJob.status === DuplicateJobStatus.Completed;
}

/**
 * In this just an alias in case you found this name make more sense to use it to display.
 * Also it come with blank default value.
 */
export function getName(importJob: ImportJob) {
  return importJob.newName ?? "";
}
