import * as React from "react";
import { Styled } from "./styles";

interface Props {
  children: React.ReactNode;
  isPreview?: boolean;
}

const FullScreenModalWrapper = (props: Props) => (
  <Styled className={props.isPreview ? "preview-enabled" : ""}>
    {props.children}
  </Styled>
);

export default FullScreenModalWrapper;
