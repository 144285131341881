import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
  InlineInput,
  Popover,
  TagInlineLabel,
  TagList,
  ToggleStar,
} from "@screencloud/screencloud-ui-components";
import { EntityType } from "@screencloud/signage-firestore-client";
import classNames from "clsx";
import filesize from "filesize";
import { isEqual } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../AppContextProvider/AppContext";
import { MediaParams } from "../../AppContextProvider/modals/type";
import {
  NameMaxLengthAllow,
  TOTAL_MEDIA_COLUMN,
} from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import {
  getFileAudioDuration,
  getFileDuration,
  getFileOutputVideo,
  getMediaTypeTextFormatted,
  is4KAvailable,
  isAudio,
  isVideo,
} from "../../helpers/mediaHelper";
import { canBeDeleted, canBeUpdated } from "../../helpers/shareableHelper";
import { subscribeToDocumentUpdates } from "../../state/liveUpdateManager";
import { File, JobStatus } from "../../types.g";
import DateTime from "../DateTime";
import Thumbnail from "../MediaThumbnail";

import { getDisplayName } from "../../helpers/userHelper";
import { StyledMediaItem } from "./styleMediaItem";
import { renderCastingStatus } from "../../helpers/castingHelper";
import { CastedScreenInfoActions } from "../CastedScreenInfo";
import { isUuid } from "@screencloud/uuid";
import { CheckBoxStyle } from "../MediaPicker/media";
import { hasSchedule } from "../../helpers/scheduleableHelper";
import AvailabilityStatusIcon from "../AvailabilityStatusIcon";
import { AppContextType } from "src/AppContextProvider/type";
import { shouldShowFindAndReplaceButton } from "src/domains/findAndReplace/visibility";
import { withOpenReplaceModal } from "src/pages/Media/MediasList/apollo";
import { UseReplaceContent } from "src/hooks/useReplaceContent";

export enum MediaFileActions {
  CAST = "CAST",
  DELETE = "DELETE",
  MOVE = "MOVE",
  RENAME = "RENAME",
  SHARE = "SHARE",
  SELECTED = "SELECTED",
  FAVORITE = "FAVORITE",
  CHECKED = "CHECKED",
  VIEW = "VIEW",
  ADD = "ADD",
  PROCESSING = "PROCESSING",
  REFETCH = "REFETCH",
  ERROR = "ERROR",
  ADD_TO_PLAYLIST = "ADD_TO_PLAYLIST",
  ONE_CLICK_DELETE = "ONE_CLICK_DELETE",
}

export interface MediaFileProps extends UseReplaceContent {
  callBack: (
    id: string,
    action: MediaFileActions,
    value: string | boolean,
    event?: React.SyntheticEvent,
    index?: number,
    params?: MediaParams
  ) => void;
  castedScreensCallback?: (
    data: string,
    action: CastedScreenInfoActions
  ) => void;
  className?: string;
  index?: number;
  media: File;
  duration?: number;
  isCompactLayout?: boolean;
  isGridMode?: boolean;
  isReadonly?: boolean;
  isSelected?: boolean;
  isSelectionActive?: boolean;
  canAdd?: boolean;
  canDelete?: boolean;
  canSelect?: boolean;
  canPreview?: boolean;
  showFavorite?: boolean;
  showTags?: boolean;
  showActionMenu?: boolean;
  showAddToPlaylists: boolean;
  shouldHighlighted?: boolean;
  isVisible?: boolean;
  checkboxStyle?: CheckBoxStyle;
}

export interface MediaFileState {
  isRenameState: boolean;
  media: File;
}

export class MediaFile extends React.PureComponent<
  MediaFileProps,
  MediaFileState
> {
  public static contextType = AppContext;
  public static getDerivedStateFromProps(
    props: MediaFileProps,
    state: MediaFileState
  ) {
    if (!isEqual(props.media, state.media)) {
      return {
        media: props.media,
      };
    }

    return null;
  }

  public context: AppContextType;
  private unsubscribeLiveUpdateFn?: () => void;
  private _isMounted: boolean = false;
  constructor(props: MediaFileProps) {
    super(props);
    this.state = {
      isRenameState: false,
      media: props.media,
    };
  }

  public getClassName = (): string => {
    const {
      isReadonly,
      canSelect,
      isSelected,
      isCompactLayout,
      className,
      shouldHighlighted,
    } = this.props;
    return classNames(
      "media-item",
      "media-file",
      {
        "media-item-small": isCompactLayout,
        "new-folder-hilight": shouldHighlighted,
        readonly: isReadonly,
        selectable: canSelect,
        selected: isSelected,
      },
      className
    );
  };

  public timeDuration(seconds: number) {
    const date = new Date(0);
    date.setSeconds(seconds);
    const duration = date.toISOString().substr(11, 8);
    return duration;
  }

  public handleItemClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      !this.state.isRenameState &&
      this.props.canSelect &&
      this.props.isSelectionActive
    ) {
      this.props.callBack(
        this.props.media!.id,
        MediaFileActions.SELECTED,
        "",
        event,
        this.props.index
      );
    } else {
      this.props.callBack(
        this.props.media!.id,
        MediaFileActions.VIEW,
        "",
        event,
        this.props.index
      );
    }
  };

  public handleCast = (event: React.SyntheticEvent) => {
    this.props.callBack(
      this.props.media.id,
      MediaFileActions.CAST,
      "",
      event,
      this.props.index
    );
  };

  public handleRename = (event: React.KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!event.shiftKey && !event.metaKey) {
      this.setState({ isRenameState: true });
    }
  };

  public handleMove = (event: React.SyntheticEvent) => {
    this.props.callBack(
      this.props.media.id,
      MediaFileActions.MOVE,
      "",
      event,
      this.props.index
    );
  };

  public handleAddToPlaylist = (event: React.SyntheticEvent) => {
    this.props.callBack(
      this.props.media.id,
      MediaFileActions.ADD_TO_PLAYLIST,
      "",
      event,
      this.props.index
    );
  };

  public handleDelete = (event: React.SyntheticEvent) => {
    this.props.callBack(
      this.props.media.id,
      MediaFileActions.DELETE,
      "",
      event,
      this.props.index
    );
  };

  public handleOneClickDelete = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    this.props.callBack(
      this.props.media.id,
      MediaFileActions.ONE_CLICK_DELETE,
      "",
      event,
      this.props.index
    );
  };

  public handleFavorite = (event: React.KeyboardEvent<any>) => {
    event.stopPropagation();
    if (!event.shiftKey && !event.metaKey) {
      this.props.callBack(
        this.props.media.id,
        MediaFileActions.FAVORITE,
        !this.props.media.isFavorite,
        event,
        this.props.index
      );
    }
  };

  public handleViewSchedule = async (event: React.MouseEvent) => {
    this.props.callBack(
      this.props.media.id,
      MediaFileActions.VIEW,
      "",
      event,
      this.props.index,
      { defaultTabIndex: 1 }
    );
  };

  public handleView = async (event: React.MouseEvent) => {
    if (!this.props.isReadonly) {
      event.stopPropagation();
      if (!event.shiftKey && !event.metaKey && !event.ctrlKey) {
        if (this.props.media.fileProcessingStatus === JobStatus.Failed) {
          this.props.callBack(
            this.props.media.id,
            MediaFileActions.ERROR,
            "",
            event,
            this.props.index
          );
        } else if (
          getFileOutputVideo(this.props.media) ||
          (!isVideo(this.props.media) &&
            this.props.media.fileProcessingStatus === JobStatus.Completed)
        ) {
          this.props.callBack(
            this.props.media.id,
            MediaFileActions.VIEW,
            "",
            event,
            this.props.index
          );
        } else {
          this.props.callBack(
            this.props.media.id,
            MediaFileActions.PROCESSING,
            "",
            event,
            this.props.index
          );
        }
      } else {
        this.props.callBack(
          this.props.media.id,
          MediaFileActions.SELECTED,
          "",
          event,
          this.props.index
        );
      }
    }
  };

  public handleAdd = (event: React.MouseEvent) => {
    if (!this.props.isReadonly) {
      event.stopPropagation();
      if (!event.shiftKey && !event.metaKey && !event.ctrlKey) {
        this.props.callBack(
          this.props.media.id,
          MediaFileActions.ADD,
          "",
          event,
          this.props.index
        );
      }
    }
  };

  public handleNameSave = async (
    event: React.SyntheticEvent<any>,
    value: string
  ) => {
    await this.setState({ isRenameState: false });

    // check if name is still the same nothing to do here
    if (this.props.media.name !== value) {
      this.props.callBack(
        this.props.media.id,
        MediaFileActions.RENAME,
        value,
        event,
        this.props.index
      );
    }
  };

  public renderThumbnail = () => {
    const { media } = this.state;
    let width: number = 320;
    let height: number = 180;
    if (this.props.isGridMode === false) {
      width = height = 60;
    }

    if (this.props.canAdd) {
      return (
        <div className="thumbnail-preview" onClick={this.handleAdd}>
          <Thumbnail
            className="medium"
            media={media}
            width={width}
            height={height}
            overlay={<Icon name="plus" className="icon" />}
            secureMediaPolicy={this.context.secureMediaPolicy}
          />
        </div>
      );
    }

    return (
      <div className="thumbnail-preview" onClick={this.handleView}>
        <Thumbnail
          className="medium"
          media={media}
          width={width}
          height={height}
          secureMediaPolicy={this.context.secureMediaPolicy}
        />
      </div>
    );
  };

  public renderTitle = () => {
    const { media } = this.props;

    if (this.state.isRenameState) {
      return (
        <InlineInput
          maxLength={NameMaxLengthAllow}
          value={this.props.media.name}
          onSaved={this.handleNameSave}
          editmode={true}
          spellCheck={false}
        />
      );
    }

    return (
      <h3 title={media.name}>
        <span onClick={this.handleView}>{media.name}</span>
        {canBeUpdated({ context: this.context, shareable: media }) && (
          <Icon
            name="edit"
            data-testid="title"
            onClick={this.handleRename}
            title="Rename"
          />
        )}
      </h3>
    );
  };

  public renderSubtitle = (duration: string, updatedBy: string) => {
    if (this.props.isCompactLayout) {
      return (
        <span className="media-type">
          {getMediaTypeTextFormatted(this.props.media)}
        </span>
      );
    }

    let mediaDateInfo = (
      <span className="media-item__date">
        <FormattedMessage
          id="common.text.uploaded_on"
          defaultMessage="Uploaded on"
        />{" "}
        <DateTime value={this.props.media.createdAt} />
        <span className="author">
          {updatedBy !== "" ? ` by  ${updatedBy}` : null}
        </span>
      </span>
    );

    if (this.props.media.updatedAt) {
      mediaDateInfo = (
        <span className="media-item__date">
          <FormattedMessage
            id="common.text.updated_on"
            defaultMessage="Updated on"
          />{" "}
          <DateTime value={this.props.media.updatedAt} />
          <span className="author">
            {updatedBy !== "" ? ` by ${updatedBy}` : null}
          </span>
        </span>
      );
    }

    return (
      <div>
        {mediaDateInfo}
        <span className="media-item__meta">
          {getMediaTypeTextFormatted(this.props.media)}
          {duration && duration !== "" ? `· ${duration}` : null}
        </span>
      </div>
    );
  };

  public componentDidMount() {
    this._isMounted = true;
    this.subscribeToLiveUpdate();
  }

  public componentWillUnmount() {
    this._isMounted = false;
    this.unsubscribeFromLiveUpdate();
  }

  public subscribeToLiveUpdate() {
    this.unsubscribeFromLiveUpdate();
    if (isUuid(this.props.media.id) && isUuid(this.props.media.orgId)) {
      this.unsubscribeLiveUpdateFn = subscribeToDocumentUpdates(
        this.props.media.orgId || "",
        EntityType.FILE,
        this.props.media.id,
        () => {
          if (this._isMounted) {
            this.setState({
              media: {
                ...this.props.media,
                fileProcessingStatus: JobStatus.Completed,
              },
            });
            this.props.callBack(
              this.props.media.id,
              MediaFileActions.REFETCH,
              true,
              undefined,
              this.props.index
            );
          }
        },
        true
      );
    }
  }

  public unsubscribeFromLiveUpdate() {
    if (this.unsubscribeLiveUpdateFn) {
      this.unsubscribeLiveUpdateFn();
      this.unsubscribeLiveUpdateFn = undefined;
    }
  }

  public getStatusFromFileStatus = (media: Partial<File>) => {
    let fileTypeAndStatus;
    switch (media.fileProcessingStatus) {
      case JobStatus.Completed:
        fileTypeAndStatus = getMediaTypeTextFormatted(media);
        break;
      case JobStatus.Failed:
        fileTypeAndStatus = <span className="failed-msg">Failed</span>;
        break;
      case JobStatus.NotStarted:
      case JobStatus.Pending:
        fileTypeAndStatus = "Processing";
        break;
      default:
        fileTypeAndStatus = "Unknown";
        break;
    }
    return fileTypeAndStatus;
  };

  public handleReplace = (event: React.SyntheticEvent) => {
    this.props.openReplaceContentModal({
      originalContent: this.state.media,
      associations: this.state.media.associationsByFileAndSpaceId.nodes || [],
    });
  };

  public render() {
    const { media } = this.state;
    const {
      isVisible,
      showActionMenu,
      isReadonly,
      canDelete = true,
    } = this.props;
    const { castedScreenByFileId } = this.props.media;

    const calMediaSize =
      media.size && media.size !== 0
        ? filesize(media.size, { round: 0 })
        : null;
    const calMediaDuration = isVideo(media)
      ? this.timeDuration(getFileDuration(media, 0) / 1000)
      : isAudio(media)
      ? this.timeDuration(getFileAudioDuration(media, 0) / 1000)
      : "";
    const canCastMedia =
      this.context.currentPermissions.validateCurrentSpace("screen", "cast") &&
      this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING);
    const canDeleteMedia =
      canBeDeleted({
        context: this.context,
        shareable: media,
      }) && canDelete;
    const canUpdateMedia = canBeUpdated({
      context: this.context,
      shareable: media,
    });
    const canPerformAction = canCastMedia || canUpdateMedia || canDeleteMedia;
    const showOptions = showActionMenu && canPerformAction;

    const shouldShowFindAndReplace = shouldShowFindAndReplaceButton(
      media?.associationsByFileAndSpaceId?.nodes
    );

    let updatedBy = "";
    if (media.userByCreatedBy) {
      updatedBy = `${getDisplayName(media.userByCreatedBy)}`!;
    }

    let totalDescriptionCols = TOTAL_MEDIA_COLUMN;
    if (!this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING)) {
      totalDescriptionCols = totalDescriptionCols - 1;
    }
    if (!this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.STARRING)) {
      totalDescriptionCols = totalDescriptionCols - 1;
    }

    return (
      <StyledMediaItem
        data-cy={this.props.isGridMode ? "media-item-grid" : "media-item"}
        totalDescriptionCols={totalDescriptionCols}
        className={this.getClassName()}
        onClick={this.handleItemClick}
        title={this.props.media.name}
      >
        {isVisible && (
          <>
            <div className="media-core">
              <div className="media-alpha">
                {this.renderThumbnail()}
                <div className="media-title">
                  {this.renderTitle()}
                  {this.renderSubtitle(calMediaDuration.toString(), updatedBy)}
                </div>
              </div>
              {this.props.isGridMode && media.tags.length > 0 ? (
                <div data-testid="media-tags" className="media-tags">
                  <TagInlineLabel
                    tags={media.tags as string[]}
                    placeholder=""
                  />
                </div>
              ) : null}
              <div className="media-scheduled">
                {hasSchedule(media as File) && (
                  <AvailabilityStatusIcon
                    data-testid="media-schedule"
                    callback={() => this.handleViewSchedule}
                    availableAt={media.availableAt}
                    expireAt={media.expireAt}
                  />
                )}
              </div>
              <div
                onClick={(event) => event.stopPropagation()}
                className="media-casting"
              >
                {renderCastingStatus({
                  castScreenData: castedScreenByFileId?.nodes ?? [],
                  callBack: this.props.castedScreensCallback!,
                  context: this.context,
                })}
              </div>

              {this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.STARRING) &&
                this.props.showFavorite && (
                  <div className="media-starred">
                    <ToggleStar
                      active={media.isFavorite!}
                      onClick={this.handleFavorite}
                      data-testid="button-toggle-star"
                    />
                  </div>
                )}

              <div className="media-duration">
                <span>{calMediaDuration}</span>
              </div>

              <div className="media-kind" data-testid="media-kind">
                <span>
                  {this.getStatusFromFileStatus(media)}
                  {is4KAvailable(this.props.media) && <Icon name="4k" />}
                </span>
              </div>

              <div className="media-size">
                <span>{calMediaSize}</span>
              </div>

              {this.props.showTags && (
                <div data-testid="media-tags" className="media-tags">
                  {!this.props.isGridMode ? (
                    <TagList taglist={media.tags as string[]} />
                  ) : null}
                </div>
              )}
            </div>

            {canDeleteMedia &&
              this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.RECYCLE_BIN) &&
              !isReadonly && (
                <Popover
                  data-testid="media-delete"
                  inverted
                  content={
                    <FormattedMessage
                      id="ui_component.common.label.move_to_trash"
                      defaultMessage="Move to Trash"
                    />
                  }
                  position="top center"
                  trigger={
                    <div className="media-delete">
                      <Button
                        icon
                        borderless
                        mini
                        danger
                        onClick={this.handleOneClickDelete}
                      >
                        <Icon data-cy="media-delete" name="trash" />
                      </Button>
                    </div>
                  }
                />
              )}

            <div className="media-options">
              {this.props.isSelectionActive ? (
                this.props.checkboxStyle === CheckBoxStyle.CIRCLE ? (
                  <div className="media-checked">
                    <Icon name="checked-circle" />
                  </div>
                ) : (
                  <Button icon borderless mini>
                    {this.props.isSelected ? (
                      <Icon name="checkbox-checked" className="checked" />
                    ) : (
                      <Icon name="checkbox-empty" />
                    )}
                  </Button>
                )
              ) : (
                showOptions && (
                  <Dropdown
                    checkEmpty
                    icon={
                      <Button icon borderless mini>
                        <Icon name="dots" />
                      </Button>
                    }
                    className="media-dropdown"
                  >
                    <DropdownMenu>
                      {canCastMedia && (
                        <DropdownItem
                          onClick={this.handleCast}
                          data-testid="item-action-cast"
                        >
                          <Icon name="screen-play" />{" "}
                          <FormattedMessage
                            id="ui_component.common.label.set_to_screen"
                            defaultMessage="Set to Screen"
                          />
                        </DropdownItem>
                      )}
                      {canCastMedia && (canUpdateMedia || canDeleteMedia) && (
                        <DropdownDivider />
                      )}
                      {this.props.showAddToPlaylists && (
                        <DropdownItem
                          onClick={this.handleAddToPlaylist}
                          data-testid="item-action-add-to-playlist"
                        >
                          <Icon name="playlist-create" />{" "}
                          <FormattedMessage
                            id="media.add_to_playlists"
                            defaultMessage="Add to Playlists"
                          />
                        </DropdownItem>
                      )}
                      {canUpdateMedia && (
                        <DropdownItem
                          onClick={this.handleMove}
                          data-testid="item-action-move"
                        >
                          <Icon name="folder-move" />{" "}
                          <FormattedMessage
                            id="ui_component.media_item.move_to"
                            defaultMessage="Move to"
                          />
                        </DropdownItem>
                      )}
                      {shouldShowFindAndReplace && (
                        <DropdownItem
                          onClick={this.handleReplace}
                          data-testid="item-action-add-to-find-and-replace"
                        >
                          <Icon name="swap" />
                          <FormattedMessage
                            id="ui_component.media_item.find_replace"
                            defaultMessage="Find & Replace"
                          />
                        </DropdownItem>
                      )}
                      {canDeleteMedia && <DropdownDivider />}
                      {canDeleteMedia && (
                        <DropdownItem
                          onClick={this.handleDelete}
                          className="danger"
                          data-testid="item-action-delete"
                        >
                          <Icon name="trash" />{" "}
                          <FormattedMessage
                            id="ui_component.common.label.move_to_trash"
                            defaultMessage="Move to Trash"
                          />
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                )
              )}
            </div>
          </>
        )}
      </StyledMediaItem>
    );
  }
}

export default withOpenReplaceModal(MediaFile);
