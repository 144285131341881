import { Styled } from "./style";
import {
  DropdownAddition,
  Icon,
  ModalSize,
} from "@screencloud/screencloud-ui-components";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "src/hooks/useAppContext";
import { FormattedMessage } from "react-intl";
import CreateSpace from "../Admin/CreateSpace";
import { Space } from "src/types.g";
import { useDuplicateSpace } from "src/hooks/duplication/useDuplicateSpace";
import { OptionsProps } from "@screencloud/screencloud-ui-components/dist/esm/components/dropdown-addition";
import queryHelper from "src/state/helper/query";
import { sortedByName } from "src/helpers/sortingHelper";
import { getWhiteLabelStyles } from "src/helpers/whiteLabel";

enum SpaceOptionAction {
  MANAGE_SPACE = "Manage Space",
  DUPLICATE_CURRENT_SPACE = "Duplicate Current Space",
  NEW_SPACE = "New Space",
}

enum SpaceOptionActionValue {
  MANAGE_SPACE = 1,
  DUPLICATE_CURRENT_SPACE = 2,
  NEW_SPACE = 3,
}

export interface SpaceDropDownProps {
  onItemClick?: (action) => void;
  className?: string;
}

export interface WithAllSpacesProps {
  allSpaces: Space[] | null;
}
const withAllSpaces = (Component) => (props: WithAllSpacesProps) => {
  const [allSpaces, setAllSpaces] = useState<Space[] | null>(null);

  useEffect(() => {
    const getAllSpaces = async () => {
      const spaces = await queryHelper.getAllSpaceIdsAndNames();
      if (spaces) {
        setAllSpaces(sortedByName(spaces));
      }
    };
    getAllSpaces();
  }, []);
  return <Component {...props} allSpaces={allSpaces} />;
};

export const MultiSpaceSearch = (
  props: SpaceDropDownProps & WithAllSpacesProps
): JSX.Element | null => {
  const context = useAppContext();
  const currentSpaceName = context.currentSpace?.name ?? "";
  const { duplicateSpace } = useDuplicateSpace();
  const whiteLabelStyles = getWhiteLabelStyles(context);

  const hideSearchInput = (props.allSpaces ?? []).length <= 5;
  const canCreateSpace = context.currentPermissions.validateCurrentSpace(
    "space",
    "create"
  );
  const canUpdateSpace = context.currentPermissions.validateCurrentSpace(
    "space",
    "update"
  );
  const canReadSpace = context.currentPermissions.validateCurrentSpace(
    "space",
    "read"
  );

  const optionList = [
    ...(props.allSpaces ?? []).map((v) => {
      return {
        key: v.id,
        value: v.name,
        text: v.name,
      };
    }),
  ];

  const manageSpaceOption = [
    canReadSpace && (canCreateSpace || canUpdateSpace)
      ? {
          key: SpaceOptionAction.MANAGE_SPACE,
          value: SpaceOptionActionValue.MANAGE_SPACE,
          text: (
            <>
              <Icon name="space" />
              <span data-testid="manage-space-option">
                {SpaceOptionAction.MANAGE_SPACE}
              </span>
            </>
          ),
          className: "custom-divider space-action",
          preventAction: true,
        }
      : null,
    canReadSpace && canCreateSpace
      ? {
          key: SpaceOptionAction.DUPLICATE_CURRENT_SPACE,
          value: SpaceOptionActionValue.DUPLICATE_CURRENT_SPACE,
          text: (
            <>
              <Icon name="duplicate" />
              <span data-testid="duplicate-space-option">
                {SpaceOptionAction.DUPLICATE_CURRENT_SPACE}
              </span>
            </>
          ),
          className: "space-action",
          preventAction: true,
        }
      : null,
    canCreateSpace
      ? {
          key: SpaceOptionAction.NEW_SPACE,
          value: SpaceOptionActionValue.NEW_SPACE,
          text: (
            <>
              <Icon name="plus-circle" />
              <span data-testid="create-space-option">
                {SpaceOptionAction.NEW_SPACE}
              </span>
            </>
          ),
          className: "space-action",
          preventAction: true,
        }
      : null,
  ].filter(Boolean);

  const handleSpaceChange = (spaceId: any) => {
    if (props.onItemClick) {
      props.onItemClick(spaceId);
    }
  };

  const handleCreateNewSpace = async () => {
    const title = (
      <FormattedMessage
        id="admin.space.form_create.title"
        defaultMessage="Create New Space"
      />
    );
    const content = (
      <CreateSpace
        onSpaceCreated={(space: Space) => {
          handleSpaceChange({
            type: "space",
            id: space.id,
            name: space.name,
            created: true,
          });
        }}
      />
    );

    await context.modal.openNavigationControlModal(content, title, {
      opts: {
        disableTitle: false,
        overflow: true,
        size: ModalSize.SMALL,
      },
    });
  };

  const handleDuplicateSpace = async () => {
    const callback = () => context.history.push("/spaces");
    await duplicateSpace(context.currentSpace as Space, callback);
  };

  const handleManageSpace = () => {
    context.history.push("/spaces");
  };

  const handleOnChange = useCallback(
    (event: React.SyntheticEvent<any>, data) => {
      const { value } = data;
      if (value) {
        if (value === SpaceOptionActionValue.NEW_SPACE) {
          handleCreateNewSpace();
        } else if (value === SpaceOptionActionValue.DUPLICATE_CURRENT_SPACE) {
          handleDuplicateSpace();
        } else if (value === SpaceOptionActionValue.MANAGE_SPACE) {
          handleManageSpace();
        } else {
          const { key } = data.options.find((o) => o.value === value);
          handleSpaceChange({
            type: "space",
            id: key,
            name: value,
          });
        }
      }
    },
    [context.modal]
  );

  if (!optionList.length) {
    return null;
  }
  return (
    <Styled
      className="select-space-dropdown"
      data-testid="select-space-dropdown"
      hideSearchInput={hideSearchInput}
      textColor={whiteLabelStyles?.textColor}
      bgColor={whiteLabelStyles?.bgColor}
    >
      <DropdownAddition
        options={[...optionList, ...manageSpaceOption] as OptionsProps[]}
        placeholder="Search space"
        value={currentSpaceName}
        upward={false}
        disableAddition
        onChange={handleOnChange}
        hideSearchInput={hideSearchInput}
        showSearchInputIcon
        subheaderCurrentGroup="Space"
      />
    </Styled>
  );
};

export default withAllSpaces(
  MultiSpaceSearch
) as React.ComponentType<SpaceDropDownProps>;
