import { useState } from "react";
import differenceInDays from "date-fns/differenceInDays";
import * as api from "@screencloud/billing-client-api";

import { util } from "src/billinglatest/util";
import { Constants } from "src/billinglatest/constants";
import { getClient } from "src/billinglatest/clients/service.client";
import { useAppContext } from "src/hooks/useAppContext";

import { HookProps, Subscription } from "src/billinglatest/types";

import { appConfig } from "src/appConfig";

const mapStatusFromApi = (status: api.Subscriptions.Me.Get.Response["status"]): Subscription["status"] => {
  switch (status) {
    case "active":
      return "active";
    case "cancelled":
      return "cancelled";
    case "trial":
      return "in_trial";
    case "paused":
      return "paused";
    default:
      return "in_trial";
  }
};

/**
 * This mapper function maps the API subscription object to the UI subscription object.
 *
 * @param Subscription - Subscription object from the API
 */
export const mapFromApi = (subscription: api.Subscriptions.Me.Get.Response): Subscription => {
  return {
    ...subscription,
    billingTerm: subscription.billingTerm || 1,
    billingTermUnit: subscription.billingTermUnit || "month",
    trialEnd: subscription.trialEnd ? new Date(subscription.trialEnd).getTime() / 1000 : undefined,
    nextBillingAt: subscription.nextBillingAt ? new Date(subscription.nextBillingAt).getTime() / 1000 : undefined,
    planId: subscription.planId || "",
    createdAt: subscription.createdAt ? new Date(subscription.createdAt).getTime() / 1000 : 0,
    licenses: subscription.licenses || 0,
    totalAmount: subscription.totalAmount || 0,
    status: mapStatusFromApi(subscription.status),
    trialStart: subscription.trialStart ? new Date(subscription.trialStart).getTime() / 1000 : undefined,
    activatedAt: subscription.activatedAt ? new Date(subscription.activatedAt).getTime() / 1000 : undefined,
  };
};

export interface UseSubscription {
  isInTrial: () => boolean;
  isActive: () => boolean;
  isCancelled: () => boolean;
  isPaused: () => boolean;
  isManagedCustomer: () => boolean;
  allowDirectDebit: (currencyCode: string) => boolean;
  nextBillingDate: () => Date | undefined;
  trialEndDate: () => Date | null;
  remainingTrialDays: () => number | null;
  price: (currencyCode: string) => string | undefined;
  priceTotal: (currencyCode: string) => string | undefined;
  set: (input: Subscription) => void;
  get: () => Subscription;
  fetch: () => Promise<void>;
  refetch: () => Promise<void>;
  isFetched: boolean;
}

export function useSubscription(props?: HookProps): UseSubscription {
  const context = useAppContext();
  const [_get, _set] = useState({} as Subscription);

  /**
   * Determines if the subscription is currently in a trial period.
   */
  const isInTrial = (): boolean => {
    return get().status === "in_trial";
  };

  /**
   * Checks whether the subscription is currently active.
   */
  const isActive = (): boolean => {
    return get().status === "active";
  };

  /**
   * Checks whether the subscription is currently cancelled.
   */
  const isCancelled = (): boolean => {
    return get().status === "cancelled";
  };

  /**
   * Checks whether the subscription is currently paused.
   */
  const isPaused = (): boolean => {
    return get().status === "paused";
  };

  /**
   * Determines if the customer is classified as a managed customer.
   *
   * @remarks
   * Managed customers are those whose Annual Recurring Revenue (ARR) exceeds the threshold defined in the
   * config (e.g., $12,000). These customers may follow a distinct process compared to non-managed customers
   * in certain scenarios.
   */
  const isManagedCustomer = (): boolean => {
    const arr = (get().mrr || 0) * 12;
    return arr >= appConfig.billingArrThreshold;
  };

  /**
   * Is this subscription eligible for Direct Debit?
   */
  const allowDirectDebit = (currencyCode: string): boolean => {
    return Constants.DirectDebitCurrencies.includes(currencyCode);
  };

  /**
   * Retrieves the upcoming billing date, which marks the start of the next subscription term.
   *
   * @remarks
   * It is worth noting that in some cases, Chargebee may not provide a value for nextBillingAt if the subscription is
   * in a trial period. Therefore, it is essential to handle this scenario and select the appropriate date accordingly.
   */
  const nextBillingDate = (): Date | undefined => {
    const date = get().nextBillingAt || get().trialEnd;
    if (date) {
      return util.date.fromUnix(date);
    }
    return undefined;
  };

  /**
   * Retrieves the trial end date.
   *
   * @remarks
   * If the trial end date is not available (which is probable for certain plans), the function returns null. It is
   * recommended to handle this scenario in your code and avoid assuming the presence of a date.
   */
  const trialEndDate = (): Date | null => {
    const trialEnd = get().trialEnd;
    if (!isInTrial() || !trialEnd) {
      return null;
    }

    return util.date.fromUnix(trialEnd);
  };

  /**
   * Retrieves the days remaining in trial.
   *
   * @remarks
   * If the trial end date is not available (which is probable for certain plans), the function returns null. It is
   * recommended to handle this scenario in your code and avoid assuming the presence of a date.
   */
  const remainingTrialDays = () => {
    const endDate = trialEndDate();
    if (!endDate) {
      return null;
    }
    return differenceInDays(endDate, new Date(Date.now()));
  };

  /**
   * Cost for each individual license.
   *
   * @remarks
   * It is essential to emphasise that Chargebee enables us to customise the cost per license for each customer.
   * Therefore, the planUnitPrice within the subscription (returned here) should be considered as the accurate value,
   * rather than relying on the price from the plan object. It is also important to mention that changing the unit
   * price for specific customers is an extremely rare occurrence.
   */
  const price = (currencyCode): string | undefined => {
    const price = get().unitAmount;
    if (price) {
      return util.currency.formatted(currencyCode, price);
    }
    return undefined;
  };

  /**
   * Compute the sub-total cost of the subscription excluding any additions or discounts.
   *
   * @remarks
   * This cost represents the total number of licenses the customer possesses, without factoring in VAT, discounts,
   * addons, and other elements. In essence, it is calculated as the number of licenses multiplied by the cost per
   * license.
   */
  const priceTotal = (currencyCode: string): string | undefined => {
    let price = get().totalAmount || 0;
    if (!get().totalAmount) {
      const unitPrice = get().unitAmount;
      if (!unitPrice) {
        return undefined;
      }
      price = unitPrice * (get().licenses || 0);
    }
    return util.currency.formatted(currencyCode, price);
  };

  /**
   * Essential Methods.
   *
   * This section includes essential methods that form the core of the hook's functionality. These methods are crucial
   * and are unlikely to require any updates in the future.
   */

  /**
   * Set the subscription.
   */
  const set = (input: Subscription): void => {
    return _set(input || {});
  };

  /**
   * Return the subscription.
   */
  const get = (): Subscription => {
    return _get;
  };

  /**
   * Fetches the subscription from the billing service.
   *
   * @remarks
   * This function retrieves the dsubscription from the billing service and stores it within the hook.
   */
  const fetch = async (): Promise<void> => {
    const subscription = await getClient()?.subscriptions.getSubscription(context.currentSpace?.id);
    set(mapFromApi(subscription || ({} as api.Subscriptions.Me.Get.Response)));
  };

  /**
   * A shortcut for the `fetch` function.
   *
   * @remarks
   * This function acts as a convenient alternative to the fetch function. Calling refetch() produces the same outcome
   * as calling fetch()
   */
  const refetch = async (): Promise<void> => {
    return fetch();
  };

  /**
   * Has the subscription been fetched yet?
   *
   * @remarks
   * Simple notification to let us know if the fetch has been made yet.
   */
  const isFetched = Object.values(get()).length > 0;

  /**
   * Return the hook
   */
  return {
    isInTrial,
    isActive,
    isCancelled,
    isPaused,
    isManagedCustomer,
    allowDirectDebit,
    nextBillingDate,
    trialEndDate,
    remainingTrialDays,
    price,
    priceTotal,
    set,
    get,
    fetch,
    refetch,
    isFetched,
  };
}
