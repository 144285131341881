import { SiteRecorderActionType } from "./models";

export const EditableActionTypes = [
  SiteRecorderActionType.Click,
  SiteRecorderActionType.DelayDuration,
  SiteRecorderActionType.DelayElement,
  SiteRecorderActionType.EnterPassword,
  SiteRecorderActionType.EnterText,
  SiteRecorderActionType.EnterUsername,
  SiteRecorderActionType.Loop,
  SiteRecorderActionType.EnterOneTimeCode,
  SiteRecorderActionType.RenderDuration,
  SiteRecorderActionType.RenderSingle,
  SiteRecorderActionType.Scroll,
  SiteRecorderActionType.SessionKeepAlive,
];
