import { ThemeListQuery, useThemeListQuery } from "../../types.g";
import { compose } from "../../utils/compose";

export interface ApolloProps {
  allThemes: ThemeListQuery["allThemes"];
}

export const withData = compose((Component) => (props: ApolloProps) => {
  const themeList = useThemeListQuery({
    fetchPolicy: "cache-and-network",
  });
  return <Component {...props} allThemes={themeList.data?.allThemes} />;
});
