import { RouteComponentProps, withRouter } from "react-router-dom";
import { CreateFileMutationFn, withCreateFile } from "../../types.g";
import { compose } from "../../utils/compose";

export interface MediaProps extends RouteComponentProps<any> {
  createFile: CreateFileMutationFn;
}

export const withData = compose(
  withRouter,
  withCreateFile({
    name: "createFile",
  })
);
