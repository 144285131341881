import {
  Button,
  Loader,
  ModalSize,
  ScreenPreview,
} from "@screencloud/screencloud-ui-components";
import {
  renderStudioPlayer,
  StudioPlayer,
  AppFile,
} from "@screencloud/studio-player-sdk"; // TODO: export StudioPlayer type
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { appConfig } from "../../../appConfig";
import { AppContext } from "../../../AppContextProvider/AppContext";
import FullScreenModalContent from "../../../components/FullScreenModal/FullScreenModalContent";
import FullScreenModalContentMain from "../../../components/FullScreenModal/FullScreenModalContentMain";
import FullScreenModalHeader from "../../../components/FullScreenModal/FullScreenModalHeader";
import FullScreenModalWrapper from "../../../components/FullScreenModal/FullScreenModalWrapper";
import { FEATURE_FLAGS_ENUM } from "../../../constants/featureFlag";
import { renderCastingStatus } from "../../../helpers/castingHelper";
import { isBlank } from "../../../helpers/objectHelper";
import {
  mapEnvToPlayerSdk,
  mapRegionToPlayerSdk,
} from "../../../helpers/playerSdkHelper";
import apolloClient from "../../../state/apolloClient";
import queryHelper from "../../../state/helper/query";
import { ssm } from "../../../state/session/ssm";
import {
  AppInstance,
  AppInstanceByIdDocument,
  AppVersion,
  AvailableAppInstanceFragment,
  CastsBySpaceIdDocument,
  CreateCastsByAppInstanceIdDocument,
  CreateCastsByAppInstanceIdMutationVariables,
  Scalars,
  ScreenCastStopDocument,
  ScreenCastStopMutation,
  ScreenCastStopMutationVariables,
  SetScreenContentByAppInstanceIdDocument,
  SetScreenContentByAppInstanceIdMutationVariables,
  UpdateAppInstanceAvailableMutationFn,
  UpdateAppInstanceAvailableMutationVariables,
  UpdateAppInstanceExpireMutationFn,
  UpdateAppInstanceExpireMutationVariables,
  UpdateShareAppInstanceToAllSpacesMutationFn,
  UpdateShareAppInstanceToAllSpacesMutationVariables,
  UpdateShareAppInstanceToSpacesMutationFn,
  UpdateShareAppInstanceToSpacesMutationVariables,
  UpdateAppInstanceTagsDocument,
  UpdateAppInstanceTagsMutationVariables,
  TagsByAppIdAndSpaceIdDocument,
  Playlist,
  DuplicateAppInstanceMutationFn,
  UpdateAppInstanceMutationFn,
} from "../../../types.g";
import {
  modifyCastScreenByAppInstance,
  onDuplicateAndModify,
  onDuplicateInstance,
} from "../../../utils/appInstances";
import { CastedScreenInfoActions } from "../../CastedScreenInfo";
import { ScreenPickerActions } from "../../ScreenPicker";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import { canBeShared } from "../../../helpers/shareableHelper";
import ShareModal from "../../ShareModal";
import AvailabilityModal, {
  AvailabilityActions,
} from "../../AvailabilityModal";
import {
  canAppBeDeleted,
  canAppBeUpdated,
  getDeleteAppInstanceConfirmMsg,
  onEditAppInstance,
  shouldAppShowShareButton,
} from "../../../helpers/appHelper";
import { AppInstanceAction } from "../AppContainer";
import { CANVAS_ROUTE } from "src/constants/constants";
import { AppContextType } from "src/AppContextProvider/type";
import { UseDeleteAppInstance } from "src/hooks/entities/appInstance/useDeleteAppInstance";
import AddToPlaylistModal from "src/components/AddToPlaylistModal";
import { UseAddAppsToPlaylistsMutation } from "src/pages/Apps/AppInstances/hooks/useAddAppsToPlaylist";
import { getStudioPlayerUrl } from "src/utils";
import { shouldShowFindAndReplaceButton } from "src/domains/findAndReplace/visibility";

export interface AppEditorProps extends UseDeleteAppInstance {
  appId: string;
  appName: string;
  appInstance: Partial<AppInstance>;
  instanceName: string | undefined;
  appVersion: Partial<AppVersion>;
  onSave: (
    instanceName: string | undefined,
    config: any,
    thumbnail?: string | File,
    appInstanceId?: string
  ) => Promise<void>;
  openMediaPicker: (payload: {
    mimeTypes: string | string[];
    multiSelect: boolean;
  }) => Promise<{ media: AppFile[] }>;
  disabled?: boolean;
  updateAppInstanceAvailable: UpdateAppInstanceAvailableMutationFn;
  updateAppInstanceExpire: UpdateAppInstanceExpireMutationFn;
  updateShareAppInstanceToSpaces: UpdateShareAppInstanceToSpacesMutationFn;
  updateShareAppInstanceToAllSpaces: UpdateShareAppInstanceToAllSpacesMutationFn;
  addAppIntoPlaylists: UseAddAppsToPlaylistsMutation["addAppsToPlaylists"];
  duplicateAppInstance: DuplicateAppInstanceMutationFn;
  availableTags: string[];
  isMobileView: boolean;
  isAvailableTagsLoading: boolean;
  openReplaceContentModal: ({ originalContent, associations }: any) => void;
  updateAppInstance: UpdateAppInstanceMutationFn;
}
export interface AppEditorState {
  appInstance: Partial<AppInstance>;
  appVersion: Partial<AppVersion>;
  instanceName: string | undefined;
  editorConfig: Scalars["JSON"] | undefined;
  hasUnsavedChanges: boolean;
  isSaving: boolean;
  isPreview: boolean;
  isRename: boolean;
  isBlankConfig: boolean;
}
class AppEditor extends React.Component<AppEditorProps, AppEditorState> {
  public static contextType = AppContext;
  public context: AppContextType;

  private playerPreviewTarget = React.createRef<HTMLDivElement>();
  private playerEditorTarget = React.createRef<HTMLDivElement>();

  private studioPlayerEditor: StudioPlayer | null = null;
  private studioPlayerPreview: StudioPlayer | null = null;

  constructor(props: AppEditorProps) {
    super(props);

    this.state = {
      appInstance: props.appInstance,
      appVersion: props.appVersion,
      editorConfig: props.appInstance ? props.appInstance.config : null,
      hasUnsavedChanges: false,
      instanceName: props.instanceName ? props.instanceName : undefined,
      isSaving: false,
      isPreview: false,
      isRename: true,
      isBlankConfig: isBlank(props.appInstance?.config),
    };
  }

  public componentDidMount() {
    const currentRegion = mapRegionToPlayerSdk(appConfig.studioPlayerRegion);
    const currentEnvironment = mapEnvToPlayerSdk(
      appConfig.studioPlayerEnvironment
    );
    const playerDevicePlatform = "studio";
    const playerDeviceModel = "app-editor-preview";

    if (this.props.isMobileView) {
      this.handlePreview();
    }
    // Render studio player instance for editor preview window
    if (
      this.playerPreviewTarget.current &&
      this.state.appInstance &&
      this.state.appVersion.viewerUrl &&
      currentRegion &&
      currentEnvironment
    ) {
      const permissionsByCurrentSpace =
        this.context.currentUser?.permissionsBySpaceIds[
          this.context.currentSpace?.id
        ] || [];
      const userPermissions = this.context.currentUser?.permissions || [];
      const appUserPermissions = [
        ...userPermissions,
        ...permissionsByCurrentSpace,
      ];
      const applicationId = this.state.appVersion.appId;
      const instanceId = this.state.appInstance.id;

      this.studioPlayerPreview = renderStudioPlayer({
        target: this.playerPreviewTarget.current,
        region: currentRegion, // eu | us
        token: this.context.user.token || "",
        contentPath: `/appId/${applicationId}/${instanceId}/preview`,
        environment: currentEnvironment, // production | staging
        playerUrl: getStudioPlayerUrl(this.context),
        spaceId: this.context.user.settings.spaceId,
        overrideAppInitialize: {
          appId: applicationId,
          appInstanceId: instanceId,
          config: this.state.appInstance.config,
          filesByAppInstanceId: this.state.appInstance.filesByAppInstanceId,
          orgId: this.context.currentUser?.orgId,
          spaceId: this.context.user.settings.spaceId,
          userPermissions: appUserPermissions,
          viewerUrl: this.state.appVersion.viewerUrl || "",
          device: {
            platform: playerDevicePlatform,
            model: playerDeviceModel,
          },
        },
        context: {
          userInteractionEnabled: true,
          isAiFeatureEnabled: this.context.shouldShowFeature(
            FEATURE_FLAGS_ENUM.AI_FEATURES
          ),
        },
        features: {
          offlineStorage: false,
          simulatedDimensions: {
            width: 1920, // Simulate HD screen values
            height: 1080,
          },
        },
      });
      this.studioPlayerPreview?.on(
        "requestAuthToken",
        this.handleRequestAuthToken
      );
    }

    // Render studio player instance for editor app window
    if (
      this.playerEditorTarget.current &&
      this.state.appInstance &&
      this.state.appVersion.viewerUrl &&
      currentRegion &&
      currentEnvironment
    ) {
      const permissionsByCurrentSpace =
        this.context.currentUser?.permissionsBySpaceIds[
          this.context.currentSpace?.id
        ] || [];
      const userPermissions = this.context.currentUser?.permissions || [];
      const appUserPermissions = [
        ...userPermissions,
        ...permissionsByCurrentSpace,
      ];
      const applicationId = this.state.appVersion.appId;
      const instanceId = this.state.appInstance.id;
      this.studioPlayerEditor = renderStudioPlayer({
        target: this.playerEditorTarget.current,
        region: currentRegion, // eu | us
        token: this.context.user.token || "",
        contentPath: `/appId/${applicationId}/${instanceId}/editor`,
        environment: currentEnvironment, // production | staging
        playerUrl: getStudioPlayerUrl(this.context),
        spaceId: this.context.user.settings.spaceId,
        overrideAppInitialize: {
          appId: applicationId,
          appInstanceId: instanceId,
          config: this.state.appInstance.config,
          filesByAppInstanceId: this.state.appInstance.filesByAppInstanceId,
          orgId: this.context.currentUser?.orgId,
          spaceId: this.context.user.settings.spaceId,
          userPermissions: appUserPermissions,
          viewerUrl: this.state.appVersion.editorUrl || "",
        },
        context: {
          userInteractionEnabled: true,
          isAiFeatureEnabled: this.context.shouldShowFeature(
            FEATURE_FLAGS_ENUM.AI_FEATURES
          ),
        },
        features: { offlineStorage: false },
      });

      // Setup callbacks to studio player to handle communication from apps
      this.studioPlayerEditor?.on(
        "configUpdateAvailable",
        this.handleConfigUpdate
      );
      this.studioPlayerEditor?.on("requestFiles", this.props.openMediaPicker);
      this.studioPlayerEditor?.on(
        "requestAuthToken",
        this.handleRequestAuthToken
      );
    }
  }
  public componentWillUnmount() {
    this.studioPlayerEditor?.destroy();
    this.studioPlayerPreview?.destroy();
  }
  public handleAppNameSaved = (
    event: React.SyntheticEvent<any>,
    value: string
  ) => {
    this.setState({
      hasUnsavedChanges:
        (this.state.instanceName && this.state.instanceName !== value) ||
        this.state.hasUnsavedChanges,
      instanceName: value,
      isRename: false,
    });
  };

  public handleConfigUpdate = async () => {
    if (this.studioPlayerEditor) {
      // Studio Player Communication
      const response = await this.studioPlayerEditor.requestConfigUpdate({
        type: "preview",
      });
      this.setState({
        hasUnsavedChanges: true,
        isBlankConfig: isBlank(response.config),
      });
    }
  };

  public handleRequestAuthToken = () => {
    return new Promise(
      (
        resolve: (value: { authToken: string; expiresAt: number }) => void,
        reject
      ) => {
        resolve({ authToken: ssm.current.token || "", expiresAt: 0 });
      }
    );
  };

  public handleRequestCredential = () => {
    return new Promise(
      (
        resolve: (value: {
          credential: {
            urn: string;
            metadata: { name: string; username: string; provider: string };
          };
        }) => void,
        reject
      ) => {
        resolve({
          credential: {
            urn: "",
            metadata: { name: "", username: "", provider: "" },
          },
        });
      }
    );
  };

  public onScreenPickerCallback = async (data: string[], expiresAt?: Date) => {
    if (this.props.appInstance) {
      const spaceId = this.context.user.settings.spaceId;
      const castStartVar: CreateCastsByAppInstanceIdMutationVariables = {
        input: {
          screenIds: data,
          appInstanceId: this.props.appInstance.id,
          expiresAt,
        },
      };

      await apolloClient.mutate({
        mutation: CreateCastsByAppInstanceIdDocument,
        update: (cache, { data }) => {
          const cast =
            data?.createCastsByAppInstanceId?.casts &&
            data?.createCastsByAppInstanceId.casts[0];
          modifyCastScreenByAppInstance(cache, cast, this.props.appInstance);
        },
        variables: castStartVar,
      });
      queryHelper.updateCastedScreen(spaceId);
      this.context.modal.closeModals();
    }
  };

  public openScreenPicker = async () => {
    if (this.props.appInstance) {
      const result = await this.context.modal.openScreenPicker(
        this.props.appInstance
      );
      if (result.action === ScreenPickerActions.SET_CONTENT) {
        const setContents = result.data.map((screenId) => {
          const setAppContent: SetScreenContentByAppInstanceIdMutationVariables = {
            input: {
              screenId,
              appInstanceId: this.props.appInstance.id,
            },
          };
          return apolloClient.mutate({
            mutation: SetScreenContentByAppInstanceIdDocument,
            variables: setAppContent,
          });
        });
        await Promise.all(setContents);
        this.context.modal.closeModals();
      } else {
        this.onScreenPickerCallback(result.data, result.expiresAt);
      }
    }
  };

  public onCastedScreensCallback = async (
    data: string,
    action: CastedScreenInfoActions
  ) => {
    switch (action) {
      case CastedScreenInfoActions.STOP_CAST:
        const spaceId = this.context.user.settings.spaceId;
        const castStopVar: ScreenCastStopMutationVariables = {
          input: {
            screenId: data,
          },
        };
        await apolloClient.mutate<
          ScreenCastStopMutation,
          ScreenCastStopMutationVariables
        >({
          mutation: ScreenCastStopDocument,
          refetchQueries: [
            {
              query: AppInstanceByIdDocument,
              variables: {
                id: this.props.appInstance.id,
              },
            },
            {
              query: CastsBySpaceIdDocument,
              variables: {
                spaceId,
              },
            },
          ],
          variables: castStopVar,
        });

        queryHelper.updateCastedScreen(spaceId);

        break;
      case CastedScreenInfoActions.NAVIGATE_SCREEN:
        this.context.history.push("/screens/" + data);
        break;
      default:
    }
  };

  public handleShare = (selectedApp: AppInstance, sharedSpaceIds: string[]) => {
    const shareToSpaceInput: UpdateShareAppInstanceToSpacesMutationVariables = {
      input: {
        appInstanceId: selectedApp.id,
        spaces: sharedSpaceIds,
      },
    };

    this.props.updateShareAppInstanceToSpaces({
      variables: shareToSpaceInput,
    });
  };

  public handleShareAll = (selectedApp: AppInstance, value: boolean) => {
    const sharedAllInput: UpdateShareAppInstanceToAllSpacesMutationVariables = {
      input: {
        appInstanceId: selectedApp.id,
        isSharedAll: value,
      },
    };

    this.props.updateShareAppInstanceToAllSpaces({
      variables: sharedAllInput,
    });
  };

  public showShareModal = () => {
    const selectedApp = this.props.appInstance as AppInstance;
    let title = (
      <FormattedMessage
        id="common.label.share_app_instance"
        defaultMessage="Share App Instance"
      />
    );
    if (this.props.appInstance.isTemplate) {
      title = (
        <FormattedMessage
          id="common.label.publish_and_share"
          defaultMessage="Publish and Share"
        />
      );
    }
    this.context.modal.openModal(
      <ShareModal
        shareable={selectedApp}
        isDisabled={
          !canBeShared({ shareable: selectedApp, context: this.context })
        }
        sharedSpaces={selectedApp.sharedSpacesByAppInstanceId.nodes}
        onShareToSpaceChanges={(sharedSpaceIds) => {
          this.handleShare(selectedApp, sharedSpaceIds);
        }}
        onShareAllChange={(value) => this.handleShareAll(selectedApp, value)}
      />,
      <>
        {(this.context.allSpaces?.length ?? 0) > 1 ? (
          <>
            {title}: {selectedApp.name}
          </>
        ) : (
          <>Share {selectedApp.name} with others</>
        )}
      </>,
      {
        opts: {
          size:
            (this.context.allSpaces ?? []).length > 1
              ? ModalSize.MEDIUM
              : ModalSize.SMALL,
          disableTitle: true,
        },
      }
    );
  };

  public updateAppInstanceAvailableTime = (availableTime: string | null) => {
    const variables: UpdateAppInstanceAvailableMutationVariables = {
      input: {
        appInstanceId: this.props.appInstance.id,
        availableAt: availableTime,
      },
    };

    this.props.updateAppInstanceAvailable({
      variables,
    });
  };

  public updateAppInstnaceExpireTime = (expireTime: string | null) => {
    const variables: UpdateAppInstanceExpireMutationVariables = {
      input: {
        appInstanceId: this.props.appInstance.id,
        expireAt: expireTime,
      },
    };

    this.props.updateAppInstanceExpire({
      variables,
    });
  };

  public onAvailabilityAppInstanceCallBack = async (
    action: AvailabilityActions,
    data: {
      availableTime?: string | null;
      expireTime?: string | null;
    }
  ) => {
    switch (action) {
      case AvailabilityActions.ADD_AVAILABLE_DATE:
        if (data.availableTime) {
          this.updateAppInstanceAvailableTime(data.availableTime);
        }
        break;
      case AvailabilityActions.REMOVE_AVAILABLE_DATE:
        await this.updateAppInstanceAvailableTime(null);
        break;
      case AvailabilityActions.ADD_EXPIRY_DATE:
        if (data && data.expireTime) {
          await this.updateAppInstnaceExpireTime(data.expireTime);
        }
        break;
      case AvailabilityActions.REMOVE_EXPIRY_DATE:
        await this.updateAppInstnaceExpireTime(null);
        break;
      case AvailabilityActions.RESET_PUBLICATION_DATES:
        await this.updateAppInstanceAvailableTime(null);
        await this.updateAppInstnaceExpireTime(null);
        break;
      default:
        break;
    }
  };

  public showAvailabilityModal = () => {
    const { appInstance } = this.props;
    const canUpdate = canAppBeUpdated({
      context: this.context,
      appInstance: appInstance as AvailableAppInstanceFragment,
    });

    this.context.modal.openModal(
      <AvailabilityModal
        availableDate={appInstance.availableAt}
        expiryDate={appInstance.expireAt}
        callback={this.onAvailabilityAppInstanceCallBack}
        disabled={!canUpdate}
      />,
      <FormattedMessage
        id="ui_component.common.label.set_availability"
        defaultMessage="Set Availability"
      />,
      {
        opts: {
          closeOnDimmerClick: true,
          size: ModalSize.SMALL,
        },
      }
    );
  };

  public onDelete = async () => {
    const { appInstance } = this.props;
    if (appInstance) {
      try {
        const message = await getDeleteAppInstanceConfirmMsg(
          appInstance as AppInstance,
          appInstance!.castedScreenByAppInstanceId!.totalCount,
          appInstance!.castedScreenByAppInstanceId!.nodes
        );
        const { confirm } = await this.context.modal.confirm(message, {
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.delete"
              defaultMessage="Delete"
            />
          ),
          isDanger: true,
        });
        if (confirm) {
          this.props.deleteAppInstance({ appInstanceId: appInstance.id });
          this.context.modal.closeFullscreenModal();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  public onAddToPlaylists = (appInstanceIds: string[]) => {
    this.context.modal.openModal(
      <AddToPlaylistModal
        onAddToPlaylistClick={async (playlists: Playlist[]) => {
          try {
            await this.props.addAppIntoPlaylists({ appInstanceIds, playlists });
            this.context.modal.closeModals();
          } catch (error) {
            this.context.modal.closeModals();
            console.error(error);
          }
        }}
      />,
      "Select playlists",
      {
        opts: {
          closeOnDimmerClick: true,
          size: ModalSize.LARGE,
        },
      }
    );
  };

  public onDuplicateItem = async () => {
    try {
      const newAppInsatnce = await onDuplicateInstance({
        appId: this.props.appId,
        appInstance: this.props.appInstance as AppInstance,
        duplicateAppInstance: this.props.duplicateAppInstance,
        context: this.context,
        isTemplate: this.props.appInstance.isTemplate,
      });
      if (newAppInsatnce?.id) {
        onEditAppInstance(this.context, newAppInsatnce as AppInstance);
      }
    } catch (err) {
      console.error(err);
    }
  };

  public handleFindAndReplace = () => {
    this.props.openReplaceContentModal({
      originalContent: this.props.appInstance,
      associations: this.props.appInstance.associationsByAppInstanceIdAndSpaceId
        ?.nodes,
    });
  };

  public handleSaveAsTemplate = async () => {
    const props = {
      duplicateAppInstance: this.props.duplicateAppInstance,
      updateAppInstance: this.props.updateAppInstance,
      canvasAppId: this.context.canvasAppId,
    };
    try {
      const newAppInstanceId = await onDuplicateAndModify(
        props,
        this.props.appInstance as AppInstance,
        true,
        this.context
      );
      if (!newAppInstanceId) {
        return;
      }
      this.context.modal.closeFullscreenModal();
      this.context.history.push(CANVAS_ROUTE.CANVAS_TEMPLATE);
    } catch (err) {
      console.error(err);
    }
  };

  public onAppActionCallback = (action: AppInstanceAction) => {
    switch (action) {
      case AppInstanceAction.SET_AVAILABILITY:
        this.showAvailabilityModal();
        break;
      case AppInstanceAction.SHARE:
        this.showShareModal();
        break;
      case AppInstanceAction.SET_TO_SCREEN:
        this.openScreenPicker();
        break;
      case AppInstanceAction.DELETE:
        this.onDelete();
        break;
      case AppInstanceAction.ADD_TO_PLAYLISTS:
        this.onAddToPlaylists([this.props.appInstance?.id]);
        break;
      case AppInstanceAction.DUPLICATE:
        this.onDuplicateItem();
        break;
      case AppInstanceAction.FIND_AND_REPLACE:
        this.handleFindAndReplace();
        break;
      case AppInstanceAction.SAVE_AS_TEMPLATE:
        this.handleSaveAsTemplate();
        break;
      default:
        break;
    }
  };

  public onUpdateTagCallback = async (tags: string[]) => {
    const updateTagsInput: UpdateAppInstanceTagsMutationVariables = {
      input: {
        id: this.props.appInstance.id,
        tags,
      },
    };

    apolloClient.mutate({
      mutation: UpdateAppInstanceTagsDocument,
      variables: updateTagsInput,
      refetchQueries: [
        {
          query: TagsByAppIdAndSpaceIdDocument,
          variables: {
            appId: this.props.appVersion.appId,
            spaceId: this.context.currentSpace?.id,
          },
        },
      ],
    });
  };

  public render() {
    const { appInstance, isMobileView } = this.props;
    const { isBlankConfig } = this.state;
    const isNewInstance = location.search === "?new=true";

    const isTemplate = appInstance.isTemplate;

    const canUpdateAppInstance = canAppBeUpdated({
      context: this.context,
      appInstance: appInstance as AppInstance,
    });

    const canDeleteAppInstance = canAppBeDeleted({
      context: this.context,
      appInstance: appInstance as AppInstance,
    });

    const canCreateAppInstane = this.context.currentPermissions.validateCurrentSpace(
      "app_instance",
      "create"
    );

    const isAppInstanceOwner =
      this.context.currentSpace?.id === this.props.appInstance?.spaceId;
    const isPreview = !canUpdateAppInstance || this.state.isPreview;

    const previewStyle = {
      display: isPreview ? "flex" : "none",
      justityContent: "center",
      width: "100%",
    };
    const appConfigStyle = {
      display: isPreview ? "none" : "flex",
      justityContent: "center",
      width: "100%",
      height: "100%",
    };
    const castedScreens = appInstance.castedScreenByAppInstanceId?.nodes ?? [];

    const shouldShowSetToScreenOption =
      !isNewInstance &&
      this.context.currentPermissions.validateCurrentSpace("screen", "cast") &&
      this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) &&
      !isTemplate;

    const shouldShowShareOption = shouldAppShowShareButton({
      appInstance: this.props.appInstance as AvailableAppInstanceFragment,
      context: this.context,
    });

    const shouldShowSaveAndCloseOption = !!(
      !isNewInstance &&
      (canUpdateAppInstance || canCreateAppInstane) &&
      this.state.hasUnsavedChanges &&
      !isBlankConfig
    );

    const shouldShowAddToPlaylistOption =
      this.context.currentPermissions.validateCurrentSpace(
        "playlist",
        "update"
      ) && !isTemplate;

    const shouldShowDuplicateOption = this.context.currentPermissions.validateCurrentSpace(
      "app_instance",
      "create"
    );

    const shouldShowFindAndReplace = shouldShowFindAndReplaceButton(
      appInstance?.associationsByAppInstanceIdAndSpaceId?.nodes
    );

    const shoudShowSetAvailabilityOption =
      !isNewInstance && canUpdateAppInstance && !isTemplate;

    const shoudShowSaveAsTemplateOption =
      !isNewInstance && canCreateAppInstane && !isTemplate;

    const pathname = location.pathname;
    const isCanvasTemplate = pathname.includes(CANVAS_ROUTE.CANVAS_TEMPLATE);
    return (
      <FullScreenModalWrapper isPreview={isPreview}>
        <FullScreenModalHeader
          handleClose={this.handleClose}
          appIcon={this.props.appVersion?.appByAppId?.iconUrl ?? ""}
          title={this.props.appName}
          instanceName={this.state.instanceName}
          isEdit={isNewInstance}
          handleNameSaved={this.handleAppNameSaved}
          textPlaceholder={this.context.intl.formatMessage({
            defaultMessage: "Instance Name",
            id: "ui_component.apps.instance_name",
          })}
          disabled={!canUpdateAppInstance}
          className="app"
          isTemplate={this.props.appInstance.isTemplate}
          onAppActionCallback={this.onAppActionCallback}
          shoudShowSaveAndCloseOption={shouldShowSaveAndCloseOption}
          shouldShowSetToScreenOption={shouldShowSetToScreenOption}
          shouldShowShareOption={!isNewInstance && shouldShowShareOption}
          shoudShowSetAvailabilityOption={shoudShowSetAvailabilityOption}
          shouldShowDeleteOption={!isNewInstance && canDeleteAppInstance}
          shouldShowAddToPlaylistOption={
            !isNewInstance && shouldShowAddToPlaylistOption
          }
          shouldShowDuplicateOption={
            !isNewInstance && shouldShowDuplicateOption
          }
          shoudShowSaveAsTemplateOption={shoudShowSaveAsTemplateOption}
          shouldShowFindAndReplace={shouldShowFindAndReplace}
          expireAt={appInstance.expireAt}
          availableAt={appInstance.availableAt}
          onUpdateTagCallback={this.onUpdateTagCallback}
          allowTags={
            !isNewInstance && !isCanvasTemplate && canUpdateAppInstance
          }
          availableTags={this.props.availableTags}
          tags={this.props.appInstance.tags as string[]}
          isAvailableTagsLoading={this.props.isAvailableTagsLoading}
        >
          {renderCastingStatus({
            castScreenData: castedScreens,
            callBack: this.onCastedScreensCallback,
            context: this.context,
          })}

          {!isMobileView && canUpdateAppInstance && isAppInstanceOwner && (
            <>
              <Button onClick={this.handlePreview} disabled={isBlankConfig}>
                {isPreview ? (
                  <FormattedMessage
                    id="ui_component.common.label.edit"
                    defaultMessage="Edit"
                  />
                ) : (
                  <FormattedMessage
                    id="player_preview.preview"
                    defaultMessage="Preview"
                  />
                )}
              </Button>
              <PrimaryButton
                className="button-save"
                onClick={() => this.handleSaveAndClose()}
                disabled={!this.state.hasUnsavedChanges || isBlankConfig}
              >
                {this.state.isSaving ? (
                  <FormattedMessage
                    id="common.text.saving"
                    defaultMessage="Saving..."
                  />
                ) : (
                  <FormattedMessage
                    id="common.text.save_and_close"
                    defaultMessage="Save & Close"
                  />
                )}
              </PrimaryButton>
            </>
          )}
        </FullScreenModalHeader>

        <FullScreenModalContent disabled={!canUpdateAppInstance}>
          <div style={previewStyle}>
            <FullScreenModalContentMain>
              <ScreenPreview width={1920} height={1080}>
                <div
                  style={{ height: "100%", width: "100%" }}
                  ref={this.playerPreviewTarget}
                />
              </ScreenPreview>
            </FullScreenModalContentMain>
          </div>
          <div style={appConfigStyle}>
            <div
              style={{ height: "100%", width: "100%" }}
              ref={this.playerEditorTarget}
            />
          </div>
        </FullScreenModalContent>

        {/* todo: show loading status when saving */}
        <Loader active={false} />
      </FullScreenModalWrapper>
    );
  }

  private handleClose = async () => {
    // TODO: Remove once fully migrated to new studio player
    if (this.state.hasUnsavedChanges) {
      const { confirm } = await this.context.modal.confirmSaveData();
      if (this.studioPlayerEditor && confirm) {
        // New Studio Player Communication
        const response = await this.studioPlayerEditor.requestConfigUpdate({
          type: "save",
        });
        if (response.config) {
          this.setState({
            editorConfig: response.config,
            hasUnsavedChanges: false,
            isSaving: true,
          });
          await this.props.onSave(
            this.state.instanceName,
            this.state.editorConfig,
            response.thumbnail
          );
        }
      }
    }
    this.context.modal.closeFullscreenModal();
  };

  private handlePreview = async () => {
    // TODO: Remove once fully migrated to new studio player
    const newAppInstance = { ...this.props.appInstance };
    if (this.studioPlayerEditor) {
      // New Studio Player Communication
      const response = await this.studioPlayerEditor.requestConfigUpdate({
        type: "preview",
      });
      newAppInstance.config = response.config;
      this.studioPlayerPreview?.setOverrideAppInitialize({
        config: newAppInstance.config,
        filesByAppInstanceId: newAppInstance.filesByAppInstanceId,
      });
    }
    await this.setState({
      appInstance: newAppInstance,
      isPreview: !this.state.isPreview,
    });
    // Rescale player to fit preview dimensions
    this.studioPlayerPreview?.resetPlayerScaling();
  };

  private handleSaveAndClose = async () => {
    if (this.studioPlayerEditor) {
      const response = await this.studioPlayerEditor.requestConfigUpdate({
        type: "save",
      });
      this.setState({
        editorConfig: response.config,
        hasUnsavedChanges: false,
        isSaving: true,
      });

      await this.props.onSave(
        this.state.instanceName,
        response.config,
        response.thumbnail,
        response.appInstanceId
      );
      this.setState({
        isSaving: false,
      });
      this.context.modal.closeFullscreenModal();
    }
  };
}

export default AppEditor as React.ComponentType<AppEditorProps>;
