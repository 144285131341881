import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export interface ScreenContentNavStyleProps {
  showfeature?: boolean;
  disabled?: boolean;
}

export const Styled = styled.div<ScreenContentNavStyleProps>`
  padding: 0 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-width: 880px;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: ${Theme.break.small}) {
    padding: 0 20px;
  }

  .picker-container {
    width: 100%;
    height: 80px;
    position: relative;

    .content-nav {
      position: relative;
      width: 100%;
      height: 100%;

      .casting-trigger {
        width: 100%;
        margin-top: 15px;
        float: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: calc(100% - 80px);
        padding-top: 0px;
        align-items: center;
        i {
          width: var(-size-sm);
          height: var(-size-sm);
          background-color: var(--color-foreground);
        }
        span {
          margin-left: 10px;
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: calc(100% - 30px);
          text-align: left;
        }
      }

      .ui.dropdown {
        width: 100%;
        height: 100%;
        float: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        ${Theme.default.borderRadius};
        border: 1px solid var(--color-border);
        background-color: var(--color-white-50);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

        i.dropdown.icon {
          visibility: visible;
          position: absolute;
          right: 80px;
          height: 100%;
          display: flex;
          align-items: center;
        }

        &.disabled {
          i.dropdown.icon {
            display: none;
          }
        }

        .header {
          margin: 0;
          text-align: left;
          font-size: 0.75em;
          font-weight: 900;
          text-transform: uppercase;
          letter-spacing: 0.1em;
          height: 30px;
          padding: 10px 15px 0;
          display: inline-flex;
          align-items: center;
          line-height: 1.334;
          width: 100%;
          color: var(--color-grey60);
        }

        .menu {
          min-width: 100%;
          width: 100%;
          left: auto;

          .item {
            &.stop-playing {
              align-items: center;

              span {
                font-weight: 400;
                font-style: italic;
                overflow: hidden;
                text-overflow: ellipsis;
                color: var(--color-foreground-destructive);
              }

              i {
                background-color: var(--color-foreground-destructive);
              }

              :hover,
              :active {
                background-color: var(--color-background-destructive-active);
                span {
                  color: var(--color-foreground-destructive-active);
                }
                i {
                  background-color: var(--color-foreground-destructive-active);
                }
              }
            }
          }
        }
        .item {
          align-items: center;

          span {
            font-weight: bold;
            line-height: 1.2;

            + i[name="warning"] {
              background-color: ${Theme.color.warning};
            }
          }
        }
        .dropdown-trigger {
          width: calc(100% - 80px);
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 16px;
          padding-top: 12px;
          gap: 2px;

          i {
            width: var(--size-xs);
            height: var(--size-xs);
            background-color: var(--color-foreground);
            mask-position: initial;
            flex-shrink: 0;
          }

          .trigger-text {
            color: var(--color-foreground);
            font-size: 1em;
            line-height: 20px;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            i[name="warning"] {
              background-color: ${Theme.color.warning};
              vertical-align: middle;
              margin-left: 4px;
            }
          }

          span.default-text {
            font-weight: 400;
            color: ${Theme.color.lightGrey};
            margin-left: 2px;
          }
        }

        :hover,
        &.active {
          .i.dropdown.icon {
            visibility: visible;
          }
        }
      }

      .status {
        position: absolute;
        left: 16px;
        top: 8px;
        z-index: 2;

        &.playing {
          pointer-events: none;
        }

        span {
          display: inline-flex;
          font-weight: bold;
          color: rgba(31, 32, 39, 0.5);
          ${Theme.default.borderRadius};
          align-items: center;
          font-size: 0.675em;
          text-transform: uppercase;
          font-weight: 900;
          letter-spacing: 1px;
        }

        &.casting-label {
          span {
            color: ${Theme.color.red};
          }
        }
      }
    }

    .screen-picker {
      width: 60px;
      height: 60px;
      top: 10px;
      right: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: var(--color-primary-background);
      border-radius: 4px;
      flex-shrink: 0;
      position: absolute;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

      i {
        width: var(--size-sm, 24px);
        height: var(--size-sm, 24px);
        background-color: var(--color-primary-foreground);
      }

      :hover {
        background: var(--color-primary-background-active);

        i {
          background-color: var(--color-primary-foreground-active);
        }
      }
    }

    .screen-stop {
      border: 1px solid var(--color-border, #c5c6ca);
      background: var(--color-white-100, #fff);
      border-radius: 100%;
      width: 48px;
      height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      top: calc(50% - 25px);
      right: 15px;

      i {
        width: var(--size-sm, 24px);
        height: var(--size-sm, 24px);
        background-color: var(--color-foreground);
      }

      :hover {
        border-radius: 24px;
        background: var(--color-primary-background-destructive-active);
        border-color: transparent;

        i {
          background-color: var(--color-white-100);
        }
      }
    }

    &.screen-casting {
      .screen-stop {
        background: var(--color-primary-background-destructive);
        border-color: transparent;

        i {
          background: var(--color-white-100);
        }

        :hover {
          background: var(--color-primary-background-destructive-active);
        }
      }
    }
  }

  // Screen List Item Content Nav
  &.inline {
    padding: 0px;
    height: 56px;
    ${(props: ScreenContentNavStyleProps) =>
      props.disabled &&
      `
        border: none;
        pointer-events: none;

        .ui.dropdown {
          opacity: 1;
        }
  
        .dropdown.icon {
          display: none;
        }

        &.active {
          border: 1px solid ${Theme.color.lightGrey};

          i.dropdown.icon {
            visibility: visible;
            right: 15px;
          }

          :hover {
            i.dropdown.icon {
              right: 56px;
            }
          }
        }
      `}
    .picker-container {
      height: 100%;

      .screen-stop {
        width: 40px;
        height: 40px;
        top: calc(50% - 20px);
        right: 8px;

        i {
          width: 20px;
          height: 20px;
        }
      }

      .screen-picker {
        visibility: hidden;
        width: 48px;
        height: 48px;
        right: 4px;
        top: 4px;
      }

      .content-nav {
        .casting-trigger {
          width: calc(100% - 30px);
          padding-top: 5px;

          i {
            width: 14px;
            height: 14px;
          }

          span {
            margin-left: 8px;
            font-size: 14px;
            width: calc(100% - 16px);
          }
        }
        .ui.dropdown {
          border-color: transparent;
          box-shadow: none;

          span {
            i {
              display: block;
            }
          }

          i.dropdown.icon {
            right: 56px;
            visibility: hidden;
          }

          .dropdown-trigger {
            width: 100%;
            padding: 24px 12px 6px;

            .trigger-text {
              font-size: 14px;
            }
          }

          &.active,
          :hover {
            .screen-picker {
              visibility: visible;
            }
          }
        }

        .status {
          line-height: 14px;
          left: 12px;
          top: 6px;

          span {
            padding: 0;
            background: transparent;
            color: var(--color-subtle);
            font-size: 10px;
            font-weight: 900;
            line-height: 14px;
            letter-spacing: 1.2px;
            text-transform: uppercase;
          }
          &.casting-label {
            span {
              color: var(--color-red-600);
            }
          }
        }
      }

      &.screen-casting {
        .content-nav {
          .ui.dropdown {
            background: var(--color-gray-50);

            .dropdown-trigger {
              width: calc(100% - 56px);
            }

            &.disabled {
              border-color: transparent;

              .dropdown-trigger {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
