import {
  DeleteAppInstanceMutation,
  DeleteAppInstanceMutationVariables,
  TagsByAppIdAndSpaceIdDocument,
  useDeleteAppInstanceMutation,
} from "../../../types.g";
import {
  getOnAppInstanceDeletedRefetchQueries,
  useOnAppInstanceDeleted,
} from "src/domains/app/events/onAppInstanceDeleted";
import { useAppContext } from "src/hooks/useAppContext";
import { getCurrentSpaceId } from "src/state/session/ssm";
import { FetchResult } from "@apollo/client";

export interface UseDeleteAppInstance {
  deleteAppInstance: (props: {
    spaceId?: string;
    appInstanceId: string;
    isCanvasApp?: boolean;
    appId?: string;
    isTemplate?: boolean;
    isShared?: boolean;
  }) => Promise<
    FetchResult<
      DeleteAppInstanceMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
}

export function useDeleteAppInstance(): UseDeleteAppInstance {
  const { onAppInstanceDeleted } = useOnAppInstanceDeleted();
  const [deleteAppInstanceMutation] = useDeleteAppInstanceMutation();
  const context = useAppContext();
  const canvasAppId = context.canvasAppId;

  const deleteAppInstance = ({
    appInstanceId,
    isCanvasApp,
    spaceId = getCurrentSpaceId(),
    appId,
    isTemplate,
    isShared,
  }: {
    appInstanceId: string;
    spaceId?: string;
    isCanvasApp?: boolean;
    appId?: string;
    isTemplate?: boolean;
    isShared?: boolean;
  }) => {
    const variables: DeleteAppInstanceMutationVariables = {
      input: {
        spaceId,
        id: appInstanceId,
      },
    };

    return deleteAppInstanceMutation({
      variables,
      update: (_, { data }) => {
        const appInstance = data?.deleteAppInstance?.appInstance;
        if (appInstance) {
          onAppInstanceDeleted({
            appInstance,
          });
        }
      },
      refetchQueries: [
        {
          query: TagsByAppIdAndSpaceIdDocument,
          variables: { spaceId, appId, isTemplate, isShared },
        },
        ...getOnAppInstanceDeletedRefetchQueries({
          isCanvasApp: Boolean(isCanvasApp),
          canvasAppId,
        }),
      ],
    });
  };

  return { deleteAppInstance };
}
