import { appConfig } from "src/appConfig";

export interface FileConfig {
  mimetype: string;
  name: string;
  source: string;
}

export interface FilesConfig {
  [key: string]: FileConfig;
}

export enum Media {
  BACKGROUND_100 = "Background 100",
  BACKGROUND_103 = "Background 103",
  BACKGROUND_104 = "Background 104",
  BACKGROUND_107 = "Background 107",
  BACKGROUND_30 = "Background 30",
  MOTION_BACKGROUND_10 = "Motion Background 10",
  MOTION_BACKGROUND_1 = "Motion Background 1",
  MOTION_BACKGROUND_2 = "Motion Background 2",
  MOTION_BACKGROUND_3 = "Motion Background 3",
  MOTION_BACKGROUND_4 = "Motion Background 4",
  MOTION_BACKGROUND_5 = "Motion Background 5",
  MOTION_BACKGROUND_6 = "Motion Background 6",
  MOTION_BACKGROUND_7 = "Motion Background 7",
  MOTION_BACKGROUND_8 = "Motion Background 8",
  MOTION_BACKGROUND_9 = "Motion Background 9",
  CANVAS_BLACK_CHEVRONS_BG = "Canvas Black Chevrons Bg",
  CANVAS_BLUE_SKY_CELEBRATE_BG = "Canvas Blue Sky Celebrate Bg",
  CANVAS_EMPLOYEE_CELEBRATION = "Canvas Employee Celebration",
  CANVAS_EMPLOYEE = "Canvas Employee",
  CANVAS_GREEN_FOREST_BG = "Canvas Green Forest Bg",
  CANVAS_SCAN_QR = "Canvas Scan QR",
  CANVAS_THUMBNAIL_BANNER = "banner",
  CANVAS_THUMBNAIL_DEFAULT = "default",
  CANVAS_THUMBNAIL_QRCODE = "qrcode",
  CHANNEL_COVER_1 = "Channel Cover 1",
}

export const BackgroundImages: FilesConfig = {
  [Media.BACKGROUND_100]: {
    mimetype: "image/jpeg",
    name: "Background 100",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 100.jpg`,
  },
  [Media.BACKGROUND_103]: {
    mimetype: "image/jpeg",
    name: "Background 103",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 103.jpg`,
  },
  [Media.BACKGROUND_104]: {
    mimetype: "image/jpeg",
    name: "Background 104",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 104.jpg`,
  },
  [Media.BACKGROUND_107]: {
    mimetype: "image/jpeg",
    name: "Background 107",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 107.jpg`,
  },
  [Media.BACKGROUND_30]: {
    mimetype: "image/jpeg",
    name: "Background 30",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 30.jpg`,
  },
  [Media.MOTION_BACKGROUND_10]: {
    mimetype: "video/mp4",
    name: "Motion Background 10",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 10.mp4`,
  },
  [Media.MOTION_BACKGROUND_1]: {
    mimetype: "video/mp4",
    name: "Motion Background 1",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 1.mp4`,
  },
  [Media.MOTION_BACKGROUND_2]: {
    mimetype: "video/mp4",
    name: "Motion Background 2",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 2.mp4`,
  },
  [Media.MOTION_BACKGROUND_3]: {
    mimetype: "video/mp4",
    name: "Motion Background 3",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 3.mp4`,
  },
  [Media.MOTION_BACKGROUND_4]: {
    mimetype: "video/mp4",
    name: "Motion Background 4",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 4.mp4`,
  },
  [Media.MOTION_BACKGROUND_5]: {
    mimetype: "video/mp4",
    name: "Motion Background 5",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 5.mp4`,
  },
  [Media.MOTION_BACKGROUND_6]: {
    mimetype: "video/mp4",
    name: "Motion Background 6",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 6.mp4`,
  },
  [Media.MOTION_BACKGROUND_7]: {
    mimetype: "video/mp4",
    name: "Motion Background 7",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 7.mp4`,
  },
  [Media.MOTION_BACKGROUND_8]: {
    mimetype: "video/mp4",
    name: "Motion Background 8",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 8.mp4`,
  },
  [Media.MOTION_BACKGROUND_9]: {
    mimetype: "video/mp4",
    name: "Motion Background 9",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 9.mp4`,
  },
};

export const CanvasImages: FilesConfig = {
  [Media.CANVAS_BLACK_CHEVRONS_BG]: {
    mimetype: "image/png",
    name: "Black Chevrons Background",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/black-chevrons-bg.png`,
  },
  [Media.CANVAS_BLUE_SKY_CELEBRATE_BG]: {
    mimetype: "image/jpeg",
    name: "Blue Sky Celebrate Background",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/blue-sky-celebrate-bg.jpg`,
  },
  [Media.CANVAS_EMPLOYEE_CELEBRATION]: {
    mimetype: "image/jpeg",
    name: "Employee Celebration",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/employee-celebration.jpg`,
  },
  [Media.CANVAS_EMPLOYEE]: {
    mimetype: "image/jpeg",
    name: "Employee",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/employee.jpg`,
  },
  [Media.CANVAS_GREEN_FOREST_BG]: {
    mimetype: "image/jpeg",
    name: "Green Forest Background",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/green-forest.bg.jpg`,
  },
  [Media.CANVAS_SCAN_QR]: {
    mimetype: "image/png",
    name: "Scan QR",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/scan-qr.png`,
  },
};

export const CanvasThumbnailImages: FilesConfig = {
  [Media.CANVAS_THUMBNAIL_BANNER]: {
    mimetype: "image/png",
    name: "banner",
    source: `s3://${appConfig.altUploadBucket}/canvas-thumbnail/banner.png`,
  },
  [Media.CANVAS_THUMBNAIL_DEFAULT]: {
    mimetype: "image/png",
    name: "default",
    source: `s3://${appConfig.altUploadBucket}/canvas-thumbnail/default.png`,
  },
  [Media.CANVAS_THUMBNAIL_QRCODE]: {
    mimetype: "image/png",
    name: "qrcode",
    source: `s3://${appConfig.altUploadBucket}/canvas-thumbnail/qrcode.png`,
  },
};

export const ChannelCoverImages: FilesConfig = {
  [Media.CHANNEL_COVER_1]: {
    mimetype: "image/png",
    name: "Cover 1",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/channel-cover/Cover 1.png`,
  },
};
