import { Theme } from "@screencloud/screencloud-ui-components";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    .embeddedServiceHelpButton .helpButton {
      @media screen and (max-width: ${Theme.break.small}) {
        display: none;
      }
    }
    .embeddedServiceHelpButton .helpButton .uiButton {
      background-color: #222222;
      font-family: "Arial", sans-serif;
    }
    .embeddedServiceHelpButton .helpButton .uiButton:focus {
      outline: 1px solid #222222;
    }

    @media only screen and (max-width: ${Theme.break.mediumAlt}) {
      .embeddedServiceHelpButton .helpButton {
        width: 30px;
      }
      .embeddedServiceHelpButton .uiButton .helpButtonLabel {
        display: none;
      }
    }
`;
