import { FormattedMessage } from "react-intl";
import { StyledDeleteWarning } from "./style";

interface Place {
  position: string;
  name: string;
}

interface DeleteWarningProps {
  name: string;
  places: Place[];
  isSoftDelete?: boolean;
}

const DeleteWarning = (props: DeleteWarningProps) => {
  const { places, name, isSoftDelete } = props;
  return (
    <StyledDeleteWarning>
      <h2>
        {isSoftDelete ? (
          <FormattedMessage
            id="ui_component.label.move_to_trash_question"
            defaultMessage="Move to Trash?"
          />
        ) : (
          `Delete ${name}?`
        )}
      </h2>
      <p>
        {isSoftDelete ? (
          <FormattedMessage
            id="ui_component.confirm.move_to_trash_and_it_use_else_where"
            defaultMessage="{name} is being used elsewhere and will be moved to trash."
            values={{ name: <strong>{name}</strong> }}
          />
        ) : (
          <FormattedMessage
            id="ui_component.confirm.delete_and_it_use_else_where"
            defaultMessage="{name} is being used elsewhere and will be removed when deleted. Please check before deleting."
            values={{ name: <strong>{name}</strong> }}
          />
        )}
      </p>
      <ul className="places-list">
        {places.map((place, index) => {
          return (
            <li key={index}>
              <b>{place.position}</b>:{" "}
              <span className="place-name">{place.name}</span>
            </li>
          );
        })}
      </ul>
    </StyledDeleteWarning>
  );
};

export default DeleteWarning;
