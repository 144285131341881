import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
export const Styled = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  height: 100%;

  .config-wrapper {
    padding: 20px;
  }

  .custom-layout-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .custom-layout-size {
    text-align: center;
    position: absolute;
    top: 10%;

    h3 {
      margin-bottom: 0;
    }

    span {
      color: ${Theme.color.grey};
    }
  }

  .layout {
    .zone {
      background-color: ${Theme.color.darkGrey};
      color: ${Theme.color.white};
    }
  }

  .custom-layout-preview {
    background-color: #4b4b51;
  }

  .custom-layout-editor {
    display: flex;
    height: calc(100% - 65px);

    > div {
      width: 50%;
    }

    .code-editor {
      padding: 20px 10px 20px 20px;

      textarea {
        height: 100% !important; /* textarea need be updated again on unexpected height */
        resize: none;
      }
    }

    .editor-custom-form {
      padding: 20px 20px 20px 10px;
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    flex-direction: row;
    flex-wrap: wrap;
    height: calc(100% - 50px);

    .custom-layout-editor {
      > div {
        width: 100%;
        height: 50%;
      }
    }
  }
`;
