import { useState, useEffect } from "react";
import {
  renderFetchMoreButton,
  renderFetchMorePickerButton,
} from "src/helpers/generalHelper";
import {
  LinksOrderBy,
  useLinkListQuery,
  Exact,
  Maybe,
  LinkListQueryHookResult,
  PageInfo,
} from "../types.g";
import { useAppContext } from "./useAppContext";
export interface UseLinkList {
  links: NonNullable<
    NonNullable<LinkListQueryHookResult["data"]>["spaceById"]
  >["linksBySpaceId"]["nodes"];
  hasNextPage: boolean;
  pageInfo?: Pick<PageInfo, "endCursor" | "hasNextPage">;
  loadMore: () => void;
  loading: boolean;
  isLoading: boolean;
  refetch: (
    variables?: Partial<
      Exact<{ spaceId: any; endCursor: any; orderBy: Maybe<LinksOrderBy[]> }>
    >
  ) => void;
  isFirstTimeAlreadyLoaded: boolean;
  totalCount: number;
  renderFetchMoreButton: React.ReactNode;
  renderFetchMorePickerButton?: React.ReactNode;
}

export function useLinkList(linksOrderBy?: LinksOrderBy[]): UseLinkList {
  const context = useAppContext();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);

  const variables = {
    orderBy: linksOrderBy ?? [LinksOrderBy.CreatedAtDesc, LinksOrderBy.IdDesc],
    spaceId: context.currentSpace?.id,
    endCursor: null,
  };

  const { data: linklistQuery, fetchMore, refetch, loading } = useLinkListQuery(
    {
      variables,
      fetchPolicy: "cache-first",
    }
  );

  const loadMore = () => {
    if (!isLoading) {
      setIsLoading(true);
      const endCursor =
        linklistQuery?.spaceById?.linksBySpaceId.pageInfo.endCursor;

      fetchMore({
        variables: {
          endCursor,
        },
      }).then(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    setHasNextPage(
      Boolean(linklistQuery?.spaceById?.linksBySpaceId.pageInfo.hasNextPage) &&
        linklistQuery?.spaceById?.linksBySpaceId?.nodes!.length! <
          linklistQuery?.spaceById?.linksBySpaceId?.totalCount!
    );
  }, [linklistQuery?.spaceById?.linksBySpaceId.pageInfo!.hasNextPage]);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(Boolean(linklistQuery?.spaceById));
  }, [linklistQuery?.spaceById]);

  const totalCount = linklistQuery?.spaceById?.linksBySpaceId?.totalCount ?? 0;
  const currentItemsLength =
    linklistQuery?.spaceById?.linksBySpaceId.nodes.length ?? 0;

  return {
    links: linklistQuery?.spaceById?.linksBySpaceId?.nodes ?? [],
    hasNextPage,
    pageInfo: linklistQuery?.spaceById?.linksBySpaceId.pageInfo,
    loadMore,
    refetch,
    loading,
    isLoading,
    isFirstTimeAlreadyLoaded,
    totalCount,
    renderFetchMoreButton: renderFetchMoreButton(
      currentItemsLength,
      totalCount,
      isLoading,
      hasNextPage,
      loadMore
    ),
    renderFetchMorePickerButton: renderFetchMorePickerButton(
      currentItemsLength,
      totalCount,
      isLoading,
      hasNextPage,
      loadMore
    ),
  };
}
