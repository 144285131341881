import { Button, Icon } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import {
  ConnectDropTarget,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from "react-dnd";
import { useHistory } from "react-router";
import { MediaDropResult, MediaItemType } from "src/constants/mediaTypes";

export interface MediaBreaCrumbItemProps {
  index: string | number;
  folderId: string;
  folderName: string | React.ReactNode;
  url: string;
  action: boolean;
  onClick: (folderId: string) => void;
  onDrop?: (dropResult: MediaDropResult) => void;
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
}

const dropTarget = {
  drop(props: MediaBreaCrumbItemProps, monitor: DropTargetMonitor) {
    const data = {
      destination: {
        mediaId: props.folderId || "",
        type: MediaItemType.MEDIA_FOLDER,
      },
      source: monitor.getItem(),
    };
    if (props.onDrop) {
      props.onDrop(data);
    }
  },
};

const dropCollect = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor
) => ({
  canDrop: monitor.canDrop(),
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

const MediaBreadCrumbItem = (props: MediaBreaCrumbItemProps) => {
  const {
    connectDropTarget,
    isOver,
    index,
    folderId,
    folderName,
    url,
    action,
    onClick,
  } = props;
  const history = useHistory();
  return connectDropTarget ? (
    connectDropTarget(
      <div
        className={isOver ? "item active" : "item"}
        key={`dd-list-item-${index}`}
      >
        <Button
          transparent
          onClick={() => {
            if (action === true) {
              history.replace(url);
            }
            onClick(folderId);
          }}
          title={props.folderName}
        >
          <Icon name="folder" color="#96D0FC" />
          <span>{folderName}</span>
        </Button>
      </div>
    )
  ) : (
    <div />
  );
};

export default DropTarget(
  MediaItemType.MEDIA,
  dropTarget,
  dropCollect
)(MediaBreadCrumbItem);
