import { UUID } from "@screencloud/uuid";
import { useAppContext } from "../hooks/useAppContext";

export const shouldUseSpecificSpaceId = (spaceId: UUID | undefined) => {
  const context = useAppContext();
  if (spaceId) {
    // If the path is not org wide, and we have a spaceId, we want to pass that spaceId
    return spaceId;
  } else {
    // If the path is not org wide, and we don't have a spaceId, we want to pass the current spaceId
    return context.currentSpace?.id;
  }
};
