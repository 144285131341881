import { debounce as d } from "lodash";

export function debounce(milliseconds: number = 0, options = {}) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const { value } = descriptor;
    descriptor.value = d(value, milliseconds, options);
    return descriptor;
  };
}
