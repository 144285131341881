import { useCacheInvalidation } from "src/hooks/useCacheInvalidation";
import { Folder } from "src/types.g";

interface OnFoldersDeletedProps {
  folders: Pick<Folder, "__typename" | "id">[];
}

export const useOnFoldersDeleted = () => {
  const {
    invalidateEntityCache,
    invalidateScreenGroupCache,
  } = useCacheInvalidation();

  const onFoldersDeleted = ({ folders }: OnFoldersDeletedProps) => {
    folders.forEach(invalidateEntityCache);
    invalidateScreenGroupCache();
  };

  return {
    onFoldersDeleted,
  };
};
