import * as React from "react";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../../AppContextProvider/AppContext";
import ShareModal, { EmbedStatus } from "../../../components/ShareModal";
import { canBeShared } from "../../../helpers/shareableHelper";
import {
  Playlist,
  UpdatePlaylistEmbedMutationVariables,
  UpdateSharePlaylistToAllSpacesMutationVariables,
  UpdateSharePlaylistToSpacesMutationVariables,
} from "../../../types.g";
import { ApolloProps, withData } from "./apollo";

interface Props {
  playList: Playlist;
}

interface State {}

export class PlaylistSharedModal extends React.Component<
  Props & ApolloProps,
  State
> {
  public static contextType = AppContext;
  public context: AppContextType;

  public handleShare = (sharedSpaceIds: string[]) => {
    const { playList } = this.props;
    const updatePlaylistInput: UpdateSharePlaylistToSpacesMutationVariables = {
      input: {
        playlistId: playList.id,
        spaces: sharedSpaceIds,
      },
    };
    this.props.updateSharePlaylistToSpaces({
      variables: updatePlaylistInput,
    });
  };

  public handleShareAll = (value: boolean) => {
    const { playList } = this.props;

    const sharedAllInput: UpdateSharePlaylistToAllSpacesMutationVariables = {
      input: {
        isSharedAll: value,
        playlistId: playList.id,
      },
    };

    this.props.updateSharePlaylistToAllSpaces({
      variables: sharedAllInput,
    });
  };

  public handleEmbedShare = ({
    isEmbedEnabled,
    isEmbedPublic,
  }: EmbedStatus) => {
    const { playList } = this.props;
    const playlistInput: UpdatePlaylistEmbedMutationVariables = {
      input: {
        playlistId: playList?.id,
        isEmbedEnabled,
        isEmbedPublic,
      },
    };

    this.props.updatePlaylistEmbed({
      variables: playlistInput,
    });
  };

  public render() {
    const { playList } = this.props;
    const embedShortId = playList.embedsByPlaylistId?.nodes?.[0]?.shortId;

    return (
      <ShareModal
        embedShortId={embedShortId}
        shareable={playList}
        sharedSpaces={playList.sharedSpacesByPlaylistId.nodes}
        isDisabled={
          !canBeShared({ shareable: playList, context: this.context })
        }
        onShareToSpaceChanges={this.handleShare}
        onShareAllChange={this.handleShareAll}
        onEmbedChange={this.handleEmbedShare}
      />
    );
  }
}

export default withData(PlaylistSharedModal) as React.ComponentClass<Props>;
