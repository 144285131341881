import { Provider as Providers } from "../../billinglatest/types";
import { BILLING_PROVIDER_STORAGE_KEY } from "src/constants/constants";

class Provider {
  private readonly key = BILLING_PROVIDER_STORAGE_KEY;

  /**
   * Set the sessionStorage value for provider.
   *
   * @param value - The provider value to store.
   */
  set(value: Providers): void {
    sessionStorage.setItem(this.key, value);
  }

  /**
   * Get the sessionStorage value for provider.
   *
   * @remarks
   * To maintain data integrity, we will reload the page if the value doesn't exist, prompting the app to automatically
   * refetch this data. The alternative would involve adding multiple asynchronous functions to the numerous hooks that
   * rely on this value.
   */
  get(): Providers {
    const current = sessionStorage.getItem(this.key) as Providers;
    if (!current) {
      window.location.reload();
    }
    return current;
  }

  /**
   * Convenient method to ascertain that this customer is provided by Chargebee.
   */
  isChargebee(): boolean {
    return this.get() === "chargebee";
  }

  /**
   * Convenient method to ascertain that this customer is not provided by Chargebee.
   *
   * @remarks
   * This confirms that the customer is not categorised as a default customer and is instead served by an alternate provider like Azure.
   */
  isNotChargebee(): boolean {
    return !this.isChargebee();
  }
}

export const provider = new Provider();
