import * as React from "react";
import { Styled } from "./styles";

export interface HeaderProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  ["data-testid"]?: string;
}

const Header = (props: HeaderProps) => {
  const { id, className, children } = props;
  return (
    <Styled data-testid={props["data-testid"]} className={className} id={id}>
      {children}
    </Styled>
  );
};

export default Header;
