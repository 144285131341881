import throttle from "lodash.throttle";
import { useState, useEffect, ReactNode } from "react";
import { Styled } from "./styles";
export type TrackVisibilityProps = {
  // /**
  // * Define if the visibility need to be tracked once
  // */
  children?: ReactNode;
  once?: boolean;

  // /**
  // * Tweak the throttle interval
  // * Check https://css-tricks.com/debouncing-throttling-explained-examples/ for more details
  // */
  throttleInterval?: number;

  // /**
  // * Additional style to apply
  // */
  style?: object;

  // /**
  // * Additional className to apply
  // */
  className?: string;

  // /**
  // * Define an offset. Can be useful for lazy loading
  // */
  offset?: number;

  // /**
  // * Update the visibility state as soon as a part of the tracked component is visible
  // */
  partialVisibility?: boolean;
  // /**
  // * Reference container for listeners
  // */
  customNodeRef?: any;
  container?: string;
  index?: number;
  eventListernerForRefresh?: string;
  forceVisible?: boolean; // for unit test
};

export interface State {
  isVisible: boolean;
}

const TrackVisibility = ({
  once = false,
  throttleInterval = 250,
  offset = 0,
  partialVisibility = false,
  container = "trackvisibility",
  className,
  style,
  children,
  customNodeRef,
  eventListernerForRefresh,
  forceVisible = false,
}: TrackVisibilityProps) => {
  const [visible, setVisible] = useState(true);
  let domRef = customNodeRef;

  const customContainer = document.getElementById(container);

  const isComponentVisible = () => {
    setTimeout(() => {
      // isComponentVisible might be called from componentDidMount, before component ref is assigned
      if (!domRef?.getBoundingClientRect) {
        return;
      }

      const html = document.documentElement;
      const boundingClientRect = domRef?.getBoundingClientRect();
      const windowWidth = window.innerWidth || html.clientWidth;
      const windowHeight = window.innerHeight || html.clientHeight;
      const currentVisible = isVisible(
        boundingClientRect,
        windowWidth,
        windowHeight
      );
      if (currentVisible && once) {
        removeListener();
      }
      setVisible(currentVisible);
    }, 0);
  };

  const throttleCb = throttle(isComponentVisible, throttleInterval);

  useEffect(() => {
    attachListener();
    isComponentVisible();
    return () => {
      removeListener();
    };
  }, [domRef, customContainer]);

  function attachListener() {
    if (customContainer) {
      customContainer.addEventListener("scroll", throttleCb);
      customContainer.addEventListener("resize", throttleCb);
      eventListernerForRefresh &&
        window.addEventListener(eventListernerForRefresh, throttleCb);
    }
  }

  function removeListener() {
    if (customContainer) {
      customContainer.removeEventListener("scroll", throttleCb);
      customContainer.removeEventListener("resize", throttleCb);
      eventListernerForRefresh &&
        window.removeEventListener(eventListernerForRefresh, throttleCb);
    }
  }

  const isVisible = (
    { top, left, bottom, right, width, height },
    windowWidth,
    windowHeight
  ) => {
    if (top + right + bottom + left === 0) {
      return false;
    }

    const topThreshold = 0 - (offset || 0);
    const leftThreshold = 0 - (offset || 0);
    const widthCheck = windowWidth + offset;
    const heightCheck = windowHeight + offset;

    return partialVisibility
      ? top + height >= topThreshold &&
          left + width >= leftThreshold &&
          bottom - height <= heightCheck &&
          right - width <= widthCheck
      : top >= topThreshold &&
          left >= leftThreshold &&
          bottom <= heightCheck &&
          right <= widthCheck;
  };

  const customProps = {
    ...(className && { className }),
    ...(style && { style }),
  };

  const TrackVisibilityElement = (
    <Styled
      {...customProps}
      ref={(el) => {
        if (el) {
          domRef = el;
        }
      }}
    >
      {visible || forceVisible ? children : null}
    </Styled>
  );
  return TrackVisibilityElement;
};

export default TrackVisibility;
