// Player SDK expects region of type eu || us
export const mapRegionToPlayerSdk = (
  region: string | undefined
): "eu" | "us" | undefined => {
  if (region !== undefined) {
    if (region === "eu" || region === "us") {
      return region;
    }
  }
  return undefined;
};

// Player SDK expects environment of type production || staging
export const mapEnvToPlayerSdk = (
  env: string | undefined
): "edge" | "staging" | "production" | "development" | undefined => {
  if (env !== undefined) {
    if (
      env === "edge" ||
      env === "staging" ||
      env === "production" ||
      env === "development"
    ) {
      return env;
    }
  }
  return undefined;
};
