import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  &.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    > .search {
      padding: 0 20px;
    }

    > .media-type {
      padding: 0 20px;
      display: inline-flex;
      gap: var(--spacing-xs);

      .button-group {
        width: 100%;

        .button {
          flex: 1 1 0px;
          margin-left: 0;
          box-shadow: none;
          border-right-width: 0;
          &:nth-child(4) {
            display: inline-block;
            text-overflow: ellipsis;
            border-right-width: 1px;
            span {
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .note-mobile {
      display: none;
    }

    &.readonly {
      pointer-events: none;
      user-select: none !important;
      opacity: 0.45;
    }

    @media screen and (max-width: ${Theme.break.mediumAlt}) {
      > div {
        display: none;
      }

      .note-mobile {
        display: block;
        margin: 0 auto;
        max-width: 600px;
        text-align: center;
        padding: 40px 0;

        span {
          color: ${Theme.color.grey};
          font-style: italic;
        }
      }
    }
  }
`;

export const StyledTabContainer = styled.div`
  height: 100%;
  padding: 0;
  position: relative;
  width: 100%;

  & > div {
    .ui.secondary.pointing.menu {
      padding-left: 10px;
    }
  }
`;
