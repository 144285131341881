import { FormattedMessage } from "react-intl";
import { ScheduleOptions } from "./scheduleOption";
import { ScreenPickerActionsDropdownOption } from "./screenPickerActionsDropdownOption";
import { ScreenPickerActions } from ".";

export interface Props {
  defaultScreenPickerAction?: ScreenPickerActions;
  onExpireDateChange: (expirationDate: Date | undefined) => void;
  action: ScreenPickerActions;
  setAction: (action: ScreenPickerActions) => void;
}

export const ContentSettingGroup = (props: Props) => {
  const { onExpireDateChange, action, setAction } = props;
  const showCasting = action === ScreenPickerActions.CASTING;
  const showContent = action === ScreenPickerActions.SET_CONTENT;

  return (
    <div className="info-group">
      <ScreenPickerActionsDropdownOption
        action={action}
        onUpdateAction={(dropdownOptionTrigger) => {
          setAction(dropdownOptionTrigger);
        }}
      ></ScreenPickerActionsDropdownOption>
      <div className="picker-content-info">
        {showCasting && (
          <>
            <span className="mr-5px">
              <FormattedMessage
                id="content_setting_group.casting"
                defaultMessage="Selected content will take over your screen"
              />
            </span>
            <ScheduleOptions
              onExpireDateChange={onExpireDateChange}
            ></ScheduleOptions>
          </>
        )}
        {showContent && (
          <span>
            <FormattedMessage
              id="content_setting_group.set_content"
              defaultMessage="Content will always be playing"
            />
          </span>
        )}
      </div>
    </div>
  );
};
