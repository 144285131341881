import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  &.recent {
    .container {
      .media-item-header {
        .media-core {
          width: calc(100% - 60px);

          .media-alpha {
            width: 70%;
          }

          .media-kind {
            width: 30%;
          }
        }
      }

      .layout-list {
        .media-item {
          border-top: 1px solid ${Theme.color.silver};
          cursor: pointer;
          display: flex;
          padding: 10px 0;

          .media-core {
            width: calc(100% - 60px);
            display: flex;
            align-items: center;

            [class^="media-"] {
              text-align: center;

              span {
                color: ${Theme.color.grey};
                font-size: 0.875em;
                font-style: italic;

                span {
                  font-size: 1em;
                }
              }
            }

            .media-alpha {
              width: 70%;
              align-items: center;
              ${Theme.default.borderRadius};
              cursor: pointer;
              display: flex;
              flex: 0 0 auto;

              .media-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: calc(100% - 80px);

                * {
                  text-align: left;
                }

                h3 {
                  padding: 0;
                  margin-bottom: 0;
                  font-weight: normal;
                  white-space: nowrap;
                  border-bottom: 1px solid transparent;
                  overflow: hidden;
                  color: ${Theme.color.base};
                  text-overflow: ellipsis;

                  span {
                    font-size: 1em;
                    color: inherit;
                    font-style: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  }
                }

                div {
                  display: flex;

                  span {
                    line-height: 1.357;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .recent-item__date {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    i[name="warning"] {
                      background-color: ${Theme.color.warning};
                      width: 12px;
                      height: 12px;
                      margin-right: 5px;

                      + span {
                        color: ${Theme.color.warning};
                      }
                    }
                  }

                  .recent-item__kind {
                    display: none;
                  }
                }
              }

              .thumbnail-preview {
                width: 60px;
                height: 60px;
                position: relative;
                margin: 0 10px;

                .playlist-thumbnail-preview,
                .app-thumbnail-preview,
                .channel-thumbnail-preview {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  ${Theme.default.borderRadius};

                  i {
                    width: 30px;
                    height: 30px;
                    background-color: ${Theme.color.white};
                  }

                  img {
                    width: 100%;
                  }
                }

                .channel-thumbnail-preview {
                  height: 100%;
                  width: 75%;
                  margin: 0 auto;
                  background-size: contain;
                }
              }
            }

            .media-kind {
              width: 30%;
            }
          }

          .media-checked {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            i {
              opacity: 0;
              background-color: ${Theme.color.darkGrey};
              z-index: 2;
            }

            :after {
              background: ${Theme.color.silver};
              border-radius: 100%;
              content: "";
              display: block;
              height: 16px;
              position: absolute;
              width: 16px;
              z-index: 1;
            }
          }

          &.selected {
            background-color: ${Theme.color.silver};
            border-top-color: ${Theme.color.lightGrey};

            .media-checked {
              i {
                opacity: 1;
              }
            }

            :hover {
              background-color: ${Theme.color.silver};
              .media-checked {
                i {
                  opacity: 1;
                }
              }
            }
          }

          :hover {
            background-color: rgba(0, 0, 0, 0.01);

            .media-core {
              .media-alpha {
                .media-title {
                  h3 {
                    span {
                      color: ${Theme.color.base};
                    }
                  }
                }
              }
            }

            .media-checked {
              i {
                opacity: 0.2;
              }
            }
          }
        }
      }

      @media screen and (max-width: ${Theme.break.mediumAlt}) {
        .media-item-header {
          .media-core {
            .media-kind {
              display: block;
            }
          }
        }
      }

      @media screen and (max-width: ${Theme.break.small}) {
        .media-item-header {
          .media-core {
            .media-alpha {
              width: 100%;
            }

            .media-kind {
              display: none;
            }
          }
        }

        .layout-list {
          .media-item {
            align-items: center;
            height: 60px;
            padding: 0;

            .media-core {
              .media-alpha {
                width: 100%;

                .thumbnail-preview {
                  height: 40px;
                  margin: 0 10px 0 20px;
                  width: 40px;

                  .playlist-thumbnail-preview {
                    i {
                      width: 16px;
                      height: 16px;
                    }
                  }
                }

                .media-title {
                  div {
                    .recent-item__date {
                      display: none;
                    }
                    .recent-item__kind {
                      display: block;
                    }
                  }
                }
              }

              .media-kind {
                display: none;
              }
            }
          }
        }
      }

      .recent-empty-state {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
