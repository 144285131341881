import { Checkbox } from "@screencloud/screencloud-ui-components";
import { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import {
  clearRemindSetting,
  getRemindSetting,
  openExtensionSettings,
  setRemindSetting,
} from "./chromeExtensionUtils";
import StepOne from "./images/step-one.svg";
import StepTwo from "./images/step-two.svg";

interface IncognitoModeExplainerProps {
  onContinue: () => void;
}

const IncognitoModeExplainer = (
  props: IncognitoModeExplainerProps
): ReactElement<IncognitoModeExplainerProps> => {
  const [doNotRemind, setDoNotRemind] = useState(getRemindSetting());
  const { onContinue } = props;

  useEffect(() => {
    const savedSetting = getRemindSetting();
    setDoNotRemind(savedSetting);
    if (savedSetting) {
      onContinue();
    }
  }, []);

  return (
    <>
      <div className="incognito-warning">
        <h2>
          <FormattedMessage
            id="ui_component.site.new_site_modal.extension_incognito_warning.title"
            defaultMessage="Allowing the Dashboard Recorder Extension to run in incognito mode will improve your experience"
          />
        </h2>
        <p>
          <FormattedMessage
            id="ui_component.site.new_site_modal.extension_incognito_warning.subtitle"
            defaultMessage="We recommend updating the Dashboard Recorder settings to allow incognito mode. This will prevent any existing sessions or cookies from interfering with your setup."
          />
        </p>
        <div className="steps">
          <div className="step">
            <img className="step-image" src={StepOne} alt="Step One" />
            <span className="step-title">
              <FormattedMessage
                id="ui_component.site.new_site_modal.extension_incognito_warning.step_one"
                defaultMessage="Step One"
              />
            </span>
            <span className="step-text">
              <FormattedMessage
                id="ui_component.site.new_site_modal.extension_incognito_warning.step_one_text"
                values={{
                  link: (
                    <a
                      onClick={openExtensionSettings}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      'ScreenCloud Dashboard Recorder'
                    </a>
                  ),
                }}
                defaultMessage="Navigate to ScreenCloud Dashboard Recorder extension settings."
              />
            </span>
          </div>
          <div className="step">
            <img className="step-image" src={StepTwo} alt="Step Two" />
            <span className="step-title">
              <FormattedMessage
                id="ui_component.site.new_site_modal.extension_incognito_warning.step_two"
                defaultMessage="Step Two"
              />
            </span>
            <span className="step-text">
              <FormattedMessage
                id="ui_component.site.new_site_modal.extension_incognito_warning.step_two_text"
                defaultMessage="Enable 'Allow in incognito'."
              />
            </span>
          </div>
        </div>
        <Checkbox
          className="reminder-check-box"
          label="Don't show this again"
          onChange={(_, data) => {
            setDoNotRemind(!!data.checked);
            if (data.checked) {
              setRemindSetting();
            } else {
              clearRemindSetting();
            }
          }}
          checked={doNotRemind}
        />
      </div>
      <div className="footer">
        <PrimaryButton className="action-button" onClick={onContinue}>
          <FormattedMessage
            id="ui_component.site.new_site_modal.install_extension.continue"
            defaultMessage="Continue"
          />
        </PrimaryButton>
      </div>
    </>
  );
};

export default IncognitoModeExplainer;
