import { StrictDropdownItemProps } from "semantic-ui-react";

/**
 * Converts a simple key-value object into an array of objects suitable for the <Dropdown> component in the
 * screencloud-ui-components library.
 *
 * @param obj - The object to convert.
 *
 * @remarks
 * This function takes a simple key-value object and transforms it into an array of objects that can be used with the
 * <Dropdown> component in the screencloud-ui-components library.
 */
export function toDropdown(obj: Record<string, boolean | number | string>): StrictDropdownItemProps[] {
  return Object.entries(obj).map((values) => ({
    key: values[0],
    text: values[1],
    value: values[0],
  }));
}
