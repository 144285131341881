import { orderBy } from "lodash";
import { Typenames } from "../../../constants/constants";
import {
  FileListItemFragment,
  UpdateFileByIdMutation,
  UpdateFileByIdMutationVariables,
  useUpdateFileByIdMutation,
} from "../../../types.g";
import { ApolloCache } from "@apollo/client";

interface MoveFilesParams {
  file: FileListItemFragment;
  newFolderId: string;
}

export interface MoveFile {
  moveFile: (params: MoveFilesParams) => Promise<any>;
}

export function useMoveFile(): MoveFile {
  const [updateFileByIdMutation] = useUpdateFileByIdMutation();
  const moveFile = async ({ file, newFolderId }: MoveFilesParams) => {
    const updateFile: UpdateFileByIdMutationVariables = {
      input: {
        fileId: file.id,
        folderId: newFolderId,
        name: file.name,
      },
    };

    return updateFileByIdMutation({
      optimisticResponse: {
        __typename: Typenames.Mutation,
        updateFileById: {
          __typename: Typenames.UpdateFileByIdPayload,
          file: {
            ...file,
            folderId: newFolderId,
          },
        },
      },
      update: (cache, { data }) => {
        if (data?.updateFileById?.file) {
          // remove file from cache first
          updateMediaListByFolderId(cache, file, file.folderId, true);
          // add the file to other folder
          updateMediaListByFolderId(
            cache,
            data?.updateFileById?.file,
            newFolderId,
            true
          );
        }
      },
      variables: updateFile,
    });
  };
  return { moveFile };
}

function updateMediaListByFolderId(
  cache: ApolloCache<UpdateFileByIdMutation>,
  file: FileListItemFragment,
  folderId: string,
  isRemove: boolean
) {
  cache.modify({
    id: `Folder:${folderId}`,
    fields: {
      filesByFolderId(files, options) {
        const fileRef = options.toReference(file);
        const nodes = !isRemove
          ? [...files.nodes, fileRef]
          : files.nodes.filter(
              (fr) => options.readField("id", fr) !== file?.id
            );
        const reOrder = orderBy(
          nodes.map((node) => {
            const created = options.readField("createdAt", node);
            return {
              created,
              node,
            };
          }),
          ["created"],
          ["desc"]
        );
        const newFileList = {
          ...files,
          nodes: reOrder.map((re) => re.node),
          totalCount: files.totalCount + 1,
        };
        return newFileList;
      },
    },
  });
}
