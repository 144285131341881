import { FormattedMessage } from "react-intl";
import { BoundLabelStyled } from "./style";

type BoundLabelProps = {
  instanceName: string;
  spaceName: string;
  direction?: "row" | "column";
  tiny?: boolean;
  light?: boolean;
};

export const BoundLabel = (props: BoundLabelProps) => {
  const { instanceName, spaceName, direction, tiny, light } = props;
  return (
    <BoundLabelStyled direction={direction} tiny={tiny} light={light}>
      <FormattedMessage
        id="channels.shared_content_bound_to"
        defaultMessage="Shared content bound to"
      />
      <div className="highlight">
        {instanceName} Channel, {spaceName} space
      </div>
    </BoundLabelStyled>
  );
};
