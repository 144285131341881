import { FetchResult } from "@apollo/client";
import { getOnCastableDeletedRefetchQueries } from "src/domains/castableEntity/events/onCastableEntityDeleted";
import { useOnPlaylistDeleted } from "src/domains/playlist/events/useOnPlaylistDeleted";
import {
  DeletePlaylistMutation,
  DeletePlaylistMutationVariables,
  useDeletePlaylistMutation,
} from "src/types.g";

export interface UseDeletePlaylist {
  deletePlaylist: ({
    playlistId,
  }: {
    playlistId: string;
  }) => Promise<FetchResult<DeletePlaylistMutation>>;
}

export const useDeletePlaylist = (): UseDeletePlaylist => {
  const [deletePlaylistMutation] = useDeletePlaylistMutation();
  const { onPlaylistDeleted } = useOnPlaylistDeleted();

  const deletePlaylist = async ({ playlistId }: { playlistId: string }) => {
    const variables: DeletePlaylistMutationVariables = {
      input: {
        id: playlistId,
      },
    };

    return deletePlaylistMutation({
      variables,
      update: (_, result) => {
        const playlist = result?.data?.deletePlaylist?.playlist;
        if (playlist) {
          onPlaylistDeleted({ playlist });
        }
      },
      refetchQueries: getOnCastableDeletedRefetchQueries(),
    });
  };

  return { deletePlaylist };
};
