import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  padding-left: 50px;
  position: relative;
  padding-bottom: 30px;

  .label {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 390px;
    height: 24px;

    /* Shade/Grey-100 */
    color: ${Theme.color.grey100};
  }

  .user-search-input {
    padding-bottom: 10px;
  }

  .people-list {
    width: 100%;
    > .people-list-item:first-child {
      border-top: none;
    }

    > .loader {
      position: relative;
      margin: auto;
      top: 20px;
    }

    @media (max-width: ${Theme.break.small}) {
      width: 100%;
    }
  }
`;
