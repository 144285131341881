import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  .loading-message {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .ui.loader {
      margin-right: 5px;
    }
  }
  &.playlist {
    background: ${Theme.color.nearWhite};
    .content-header {
      background: ${Theme.color.white};
      border-bottom: 1px solid ${Theme.color.lightGrey};
      padding: 10px 20px;
    }
    .container {
      .media-item-header {
        .media-core {
          .media-starred,
          .media-playlist-duration,
          .media-tags {
            display: block;
            width: 13.33%;
          }

          .media-playlist-preview {
            width: 20%;
          }

          @media screen and (max-width: ${Theme.break.mediumAlt}) {
            .media-alpha {
              width: 60%;
            }

            .media-tags {
              display: none;
            }
          }

          @media screen and (max-width: ${Theme.break.small}) {
            .media-alpha {
              width: calc(100% - 80px);
            }

            .media-playlist-duration {
              display: none;
            }

            .media-starred {
              width: 80px;
            }
          }
        }
      }

      .layout-list {
        .media-item {
          border-top: 1px solid ${Theme.color.silver};
          cursor: pointer;
          display: flex;
          padding: 10px 0;
          align-items: center;

          &.box {
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            transition: all 0.1s ease-out;
            margin-bottom: 10px;
            ${Theme.default.borderRadius};
            border: 1px solid ${Theme.color.silver};
            background: var(--color-white-100);

            &:hover {
              background: var(--color-white-100);
              box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.2);
            }

            &.selected:active,
            &.selected:hover,
            &.selected {
              box-shadow: inset 0 0 0 4px var(--color-primary-background);
              border-color: var(--color-primary-background);
              background: var(--color-white-100);
              .playlist-check {
                i {
                  background: var(--color-foreground-active);
                }
              }
            }
          }

          .media-core {
            width: calc(100% - 60px);
            display: flex;
            align-items: center;

            [class^="media-"] {
              text-align: center;

              span {
                color: ${Theme.color.grey};
                font-size: 0.875em;
                font-style: italic;

                span {
                  font-size: initial;
                }
              }
            }

            .media-alpha {
              width: 40%;
              display: flex;
              flex: 0 0 auto;
              cursor: pointer;

              :hover {
                background: transparent;
              }

              .thumbnail-preview {
                width: 60px;
                height: 60px;
                position: relative;
                margin: 0 10px;

                .wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  ${Theme.default.borderRadius};
                  position: absolute;
                  top: 0;
                  width: 100%;
                  height: 100%;

                  i {
                    width: 30px;
                    height: 30px;
                    background-color: ${Theme.color.white};
                  }
                }

                :hover {
                  background-color: transparent;
                }

                .thumbnail {
                  width: 100%;
                  height: 100%;
                }
              }

              .media-title {
                width: calc(100% - 80px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                * {
                  text-align: left;
                }

                h3 {
                  padding: 0;
                  margin-bottom: 0;
                  font-weight: normal;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  border-bottom: 1px solid transparent;

                  :hover {
                    background: transparent;
                  }
                }

                div {
                  display: flex;

                  span {
                    line-height: 14px;
                    font-size: 14px;
                    white-space: nowrap;
                  }

                  .media-item__url {
                    display: none;
                  }
                }

                .pl-status {
                  display: inline-flex;

                  > span {
                    text-align: left;
                    font-size: 1em;
                    background: transparent;
                    line-height: 1;
                    height: 18px;
                    display: inline-flex;
                    align-items: center;
                    border-radius: 2px;
                    color: ${Theme.color.warning};
                    white-space: nowrap;
                    font-style: italic;

                    > span {
                      font-size: 1em;
                      color: ${Theme.color.warning};
                    }
                  }

                  i {
                    width: 12px;
                    height: 12px;
                    margin-right: 5px;
                    background-color: ${Theme.color.warning};
                  }

                  &.published {
                    > span {
                      color: ${Theme.color.grey};
                    }
                  }
                }
              }

              :hover {
                background: transparent;
              }
            }

            .media-starred {
              i {
                opacity: 0;
                background-color: ${Theme.color.darkGrey};
                z-index: 2;
              }
            }
            .media-playlist-preview {
              position: relative;
              width: 20%;
            }
            .media-starred,
            .media-playlist-duration,
            .media-tags {
              display: block;
              width: 13.33%;
            }
          }

          .playlist-check {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .media-checked {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            i {
              opacity: 0;
              background-color: ${Theme.color.darkGrey};
              z-index: 2;
            }

            :after {
              background: ${Theme.color.silver};
              border-radius: 100%;
              content: "";
              display: block;
              height: 16px;
              position: absolute;
              width: 16px;
              z-index: 1;
            }
          }

          :hover {
            background-color: rgba(0, 0, 0, 0.01);

            .media-checked {
              i {
                opacity: 0.2;
              }
            }
          }

          &.selected:active,
          &.selected:hover,
          &.selected {
            background-color: ${Theme.color.silver};
            box-shadow: none;
            border-top-color: ${Theme.color.lightGrey};

            .media-checked {
              i {
                opacity: 1;
              }
            }
          }
        }

        @media screen and (max-width: ${Theme.break.mediumAlt}) {
          .media-item {
            .media-core {
              .media-alpha {
                width: 50%;
              }

              .media-playlist-preview {
                width: 25%;
              }

              .media-starred,
              .media-playlist-duration {
                width: 12.5%;
              }

              .media-tags {
                display: none;
              }
            }
          }
        }

        @media screen and (max-width: ${Theme.break.small}) {
          .media-item {
            align-items: center;
            height: 60px;
            padding: 0;

            .media-core {
              .media-alpha {
                width: calc(100% - 40px);

                .thumbnail-preview {
                  height: 40px;
                  margin: 0 10px 0 20px;
                  width: 40px;

                  .wrapper {
                    i {
                      width: 16px;
                      height: 16px;
                    }
                  }
                }
              }

              .media-playlist-duration,
              .media-playlist-preview {
                display: none;
              }

              .media-starred {
                width: 40px;
              }
            }
          }
        }
      }
    }
  }
`;
