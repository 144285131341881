import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

type Props = {
  small?: boolean;
};

export const Styled = styled.div<Props>`
  position: relative;
  top: 0;
  height: 100%;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding: 0;
  overflow: auto;
  width: 380px;
  margin-top: 0px;
  bottom: 0px;
  left: auto;
  right: 0px;
  background: var(--color-white-100);
  border-left: 1px solid var(--color-grey40);
  z-index: 8;

  ${(props: Props) =>
    props.small &&
    `width: ${Theme.sidebarRight.smallMaxWidth} !important`} .ui.secondary.pointing.menu {
    background: var(--color-white-100);
  }

  .wrapper.content {
    padding: 20px 0;
  }
  .sidebar-tab {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    gap: 20px;

    .component {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      max-width: 600px;

      .helper {
        font-size: 14px;
        line-height: 20px;
        color: var(--color-grey70);

        a {
          color: var(--color-grey100);
          font-weight: bold;
          text-decoration: underline;
        }
      }

      h3 {
        font-weight: normal;
        margin: 0;
        display: flex;
        gap: 5px;
        align-items: center;

        > i {
          background-color: var(--color-grey100);
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    width: 100%;
    height: 400px;
    position: relative;
    border: none;
    overflow-y: visible;
    border-top: 4px solid rgba(32, 31, 39, 0.2);

    .wrapper {
      &.content {
        height: auto;
        padding: 20px;
      }
      .component {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
  }
`;
