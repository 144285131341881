import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Styled } from "../GenericNotFound/styles";

import errorPlaceholder from "../../images/door.svg";
interface Props {
  fullWidth?: boolean;
}
type State = {};
class Forbidden extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <Styled data-testid="forbidden">
        <div>
          <img src={errorPlaceholder} />
          <h1>
            <FormattedMessage
              id="ui_component.label.403_page_header"
              defaultMessage="Access Denied"
            />
          </h1>
          <div className="message">
            <FormattedMessage
              id="ui_component.label.403_page_description"
              defaultMessage="You don’t have permission to access this page. If you think this is an error, please contact your organization’s admin."
            />
          </div>
        </div>
      </Styled>
    );
  }
}

export default Forbidden as React.ComponentType<Props>;
