import { Component } from "react";
import {
  ConnectDropTarget,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { File, Maybe } from "../../types.g";
import MediaUpload, { MediaUploadOptions } from "./index";

export interface MediaUploadWithDropProps {
  connectDropTarget?: ConnectDropTarget;
  onSuccess: (files: Maybe<Partial<File>>[]) => void;
  options?: MediaUploadOptions;
  className?: string;
}

const mediaFileDropCollect = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor
) => ({
  connectDropTarget: connect.dropTarget(),
});

const mediaFileDropTarget = {
  drop(props: MediaUploadWithDropProps, monitor: DropTargetMonitor) {
    //
  },
};

// Media upload connects with DropTarget
// Fixed Filestack drag and drop conflicts with React DnD
class MediaUploadWithDrop extends Component<MediaUploadWithDropProps, {}> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: MediaUploadWithDropProps) {
    super(props);
  }

  public render() {
    const { connectDropTarget, onSuccess, options } = this.props;
    return (
      connectDropTarget &&
      connectDropTarget(
        <div
          style={{ height: "100%", width: options?.fullwidth ? "100%" : "" }}
        >
          <MediaUpload onSuccess={onSuccess} options={this.props.options} />
        </div>
      )
    );
  }
}

export default DropTarget(
  NativeTypes.FILE,
  mediaFileDropTarget,
  mediaFileDropCollect
)(MediaUploadWithDrop);
