import styled from "styled-components";

export const StyledDroppableContainer = styled.div``;

export const Styled = styled.div`
  height: 100%;
  .add-site {
    width: 18px;
    position: absolute;
    top: 9px;
    right: 22px;
    height: 18px;
    padding: 0;

    i {
      background-color: #fff;
      width: 10px;
    }

    :hover,
    :active {
      height: 18px;

      i {
        background-color: #fff;
      }
    }
  }

  .empty-site > p {
    font-size: 0.875em;
    color: rgba(31, 32, 39, 0.5);
  }

  .layout-container {
    position: relative;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
  }

  .site-section {
    align-items: center;
    display: flex;
    font-weight: 700;
    height: 40px;
    margin: 0 !important;
    padding: 0 20px;
  }

  .site-list {
    list-style: none;
    padding-left: 0;
    padding-bottom: 55px;
    margin-top: 0;
    margin-bottom: 0;
    .site-item {
      padding: 0 20px;
    }
  }
`;
