import queryHelper from "../state/helper/query";
import {
  CastByCastIdFragment,
  Maybe,
  ScreenCastStopMutationVariables,
  useScreenCastStopMutation,
} from "../types.g";

export function getCastItem(castByCastId) {
  if (castByCastId?.appInstanceByCastId) {
    return {
      appInstanceByCastId: {
        id: castByCastId.appInstanceByCastId.id,
      },
    } as CastByCastIdFragment;
  }

  if (castByCastId?.channelByCastId) {
    return {
      channelByCastId: {
        id: castByCastId.channelByCastId.id,
      },
    } as CastByCastIdFragment;
  }

  if (castByCastId?.fileByCastId) {
    return {
      fileByCastId: {
        id: castByCastId.fileByCastId.id,
      },
    } as CastByCastIdFragment;
  }

  if (castByCastId?.linkByCastId) {
    return {
      linkByCastId: {
        id: castByCastId.linkByCastId.id,
      },
    } as CastByCastIdFragment;
  }

  if (castByCastId?.playlistByCastId) {
    return {
      playlistByCastId: {
        id: castByCastId.playlistByCastId.id,
      },
    } as CastByCastIdFragment;
  }

  if (castByCastId?.siteByCastId) {
    return {
      siteByCastId: {
        id: castByCastId.siteByCastId.id,
      },
    } as CastByCastIdFragment;
  }
  return null;
}

export function updateContentCasting(castItem: Maybe<CastByCastIdFragment>) {
  if (castItem?.playlistByCastId) {
    queryHelper.updatePlaylistDetail(castItem?.playlistByCastId.id);
  }

  if (castItem?.linkByCastId) {
    queryHelper.updateLinkDetail(castItem?.linkByCastId.id);
  }

  if (castItem?.channelByCastId) {
    queryHelper.updateChannelDetail(castItem?.channelByCastId.id);
  }

  if (castItem?.fileByCastId) {
    queryHelper.updateFileDetail(castItem?.fileByCastId.id);
  }

  if (castItem?.appInstanceByCastId) {
    queryHelper.updateAppInstanceDetail(castItem?.appInstanceByCastId.id);
  }

  if (castItem?.siteByCastId) {
    queryHelper.updateSiteDetail(castItem?.siteByCastId.id);
  }
}

export function useScreenCastStop() {
  const [stopCastsMutation] = useScreenCastStopMutation();
  const screenCastStop = (
    stopCastsInput: ScreenCastStopMutationVariables,
    castItem: Maybe<CastByCastIdFragment>
  ) => {
    return stopCastsMutation({
      variables: stopCastsInput,
      update: (_cache, { data }) => {
        if (data?.screenCastStop?.screen) {
          updateContentCasting(castItem);
          queryHelper.updateCastedScreen(data.screenCastStop.screen.spaceId);
        }
      },
    });
  };
  return { screenCastStop };
}
