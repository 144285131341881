import styled from "styled-components";
import { Theme } from "@screencloud/screencloud-ui-components";

export const TagSelectorModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TagSelectorModalContent = styled.div`
  background-color: ${Theme.color.grey20};
  padding: 20px 20px 60px 20px;
  min-height: 285px;
`;

export const TagSelectorModalFooter = styled.div`
  box-shadow: inset 0px 1px 0px ${Theme.color.grey50};
  border-radius: 0px 0px 8px 8px;
  padding: 10px 20px;
  text-align: right;
`;

export const SubTitle = styled.div`
  text-align: left;
  margin: 5px 0;
  color: var(--grey-70, var(--grey-70, #757576));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Content = styled.div`
  text-align: left;
  color: ${Theme.color.grey70};
  font-size: 14px;
  margin-top: 5px;
`;
