import { UUID } from "@screencloud/uuid";
import { Component } from "react";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import { LinkValidationErrorCode } from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import {
  getImageDimensions,
  trimToLessThan80Char,
} from "../../helpers/mediaHelper";
import client, {
  filestackApiURL,
  resolveFileKey,
} from "../../helpers/filestackHelper";
import {
  CreateFileMutationVariables,
  CreateSiteMutationVariables,
  Maybe,
  Site,
  SiteListDocument,
  SiteType,
  UpdateSiteByIdMutationVariables,
} from "../../types.g";
import { ApolloProps, withData } from "./apollo";
import SitePickerView, {
  SitePickerViewActionMode,
  SitePickerViewMode,
} from "./SitePickerView";
import { AppContextType } from "src/AppContextProvider/type";
const validationServiceUrl = appConfig.link.validationServiceUrl;

export interface ISiteLinkPickerProps extends ApolloProps {
  callback?: () => void;
  site: Maybe<Partial<Site>>;
}

interface IState {
  // Site Data
  id?: UUID;
  url: string;
  name: string;
  siteType?: SiteType;
  refreshInterval: string;
  thumbnailId?: UUID;
  tags: string[];

  // Utility data
  nameError?: boolean;
  refreshIntervalError?: boolean;
  refreshIntervalUnit: string;
  tagOptions: { text: string; value: string }[];
  thumbnailData?: string;
  thumbnailUrl?: string;
  thumbnailError?: string;
  previousUrl: string;
  viewMode: SitePickerViewMode;
  actionMode: SitePickerViewActionMode;
  isSaving: boolean;
  isDirty: boolean;

  // Advanced Settings
  scrollFrom: number;
  scrollTo: number;
  zoom: number;
}

class SitePicker extends Component<ISiteLinkPickerProps, IState> {
  public static contextType = AppContext;

  public static defaultProps: Partial<ISiteLinkPickerProps> = {};
  public context: AppContextType;

  protected targetScreenshotId = 0;

  constructor(props: ISiteLinkPickerProps) {
    super(props);
    this.state = this.getInitialState(props);
  }

  public componentDidMount() {
    if (this.props.site) {
      this.loadAndSetThumbnailAsBase64(
        this.props.site?.fileByThumbnailId?.source || undefined,
        0
      );
    }
  }

  public render() {
    return (
      <SitePickerView
        url={this.state.url}
        onUrlChange={this.handleUrlChange}
        onValidateUrl={this.handleValidateUrl}
        name={this.state.name}
        nameError={this.state.nameError}
        onNameChange={this.handleNameChange}
        onValidateName={this.handleValidateName}
        refreshInterval={this.state.refreshInterval}
        onRefreshIntervalChange={this.handleRefreshIntervalChange}
        refreshIntervalUnit={this.state.refreshIntervalUnit}
        onRefreshIntervalUnitChange={this.handleRefreshIntervalUnitChange}
        tags={this.state.tags}
        tagOptions={this.state.tagOptions}
        onTagsChange={this.handleTagChange}
        thumbnailData={this.state.thumbnailData}
        thumbnailError={this.state.thumbnailError}
        viewMode={this.state.viewMode}
        actionMode={this.state.actionMode}
        onSubmit={this.handleSubmit}
        isSaving={this.state.isSaving}
        isDirty={this.state.isDirty}
        scrollFrom={this.state.scrollFrom}
        scrollTo={this.state.scrollTo}
        zoom={this.state.zoom}
        onScrollFromChange={this.onScrollFromChange}
        onScrollToChange={this.onScrollToChange}
        onZoomChange={this.onZoomChange}
      />
    );
  }

  private onScrollFromChange = (value: number) => {
    this.setState({
      scrollFrom: value,
    });
  };

  private onScrollToChange = (value: number) => {
    this.setState({
      scrollTo: value,
    });
  };

  private onZoomChange = (value: number) => {
    this.setState({
      zoom: value,
    });
  };

  private handleTagChange = (data) => {
    const tagLength = data.length;
    const newTag = data[tagLength - 1];
    const newTagOptions = this.state.tagOptions;
    newTagOptions.push({ text: newTag, value: newTag });
    this.setState({
      tagOptions: newTagOptions,
      tags: data,
      isDirty: true,
    });
  };

  private checkUrl = async (url: string): Promise<any> => {
    const response = await fetch(validationServiceUrl, {
      body: JSON.stringify({ url }),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    });

    return response.json();
  };

  private isSecureUrl = (url: string): boolean => {
    return url.trim().indexOf("https://") === 0;
  };

  private isCloudRenderingAvailable = (): boolean => {
    return (
      this.context.shouldShowFeature(
        FEATURE_FLAGS_ENUM.IS_ENTERPRISE_CUSTOMER
      ) ||
      this.context.shouldShowFeature(
        FEATURE_FLAGS_ENUM.IS_SECURE_SITES_CUSTOMER
      )
    );
  };

  private handleValidateUrlResponse = async (json: any) => {
    const currentScreenshotId = ++this.targetScreenshotId;
    const title = (json.data && json.data.title) || "";

    const { errCode, url } = json;

    if (!errCode) {
      let viewMode: SitePickerViewMode = SitePickerViewMode.ValidBasic;
      let siteType: SiteType | undefined = SiteType.Basic;

      if (!this.isSecureUrl(url)) {
        // Automatically try and upgrade the http site to https

        const secureUrl = url.replace("http://", "https://");
        const secureResponse = await this.checkUrl(secureUrl);
        const secureTitle =
          (secureResponse.data && secureResponse.data.title) || "";

        // If the site doesnt work, just fall back the original url
        if (!secureResponse.errCode) {
          this.getSiteThumbnail(secureUrl, currentScreenshotId);
          this.setState({
            viewMode: SitePickerViewMode.ValidBasic,
            name: secureTitle,
            url: secureUrl,
            siteType: SiteType.Basic,
            previousUrl: secureUrl,
          });
          return;
        }

        if (this.isCloudRenderingAvailable()) {
          viewMode = SitePickerViewMode.ValidCloud;
          siteType = SiteType.Cloud;
        } else {
          viewMode = SitePickerViewMode.CloudRenderUpgradeRequired;
          siteType = undefined;
        }
      }

      this.getSiteThumbnail(url, currentScreenshotId);
      this.setState({ viewMode, name: title, siteType });
    } else {
      // No title is returned if its cloud rendering - therefore we default the URL as title
      const newTitle = title !== "" ? title : url;
      switch (json.errCode) {
        case LinkValidationErrorCode.IFRAME_DENIED:
          if (this.isCloudRenderingAvailable()) {
            this.getSiteThumbnail(url, currentScreenshotId);
            this.setState({
              viewMode: SitePickerViewMode.ValidCloud,
              name: newTitle,
              siteType: SiteType.Cloud,
            });
          } else {
            this.setState({
              viewMode: SitePickerViewMode.CloudRenderUpgradeRequired,
              name: title,
            });
          }
          break;

        case LinkValidationErrorCode.URL_UNAVAILABLE:
          this.setState({
            viewMode: SitePickerViewMode.InvalidBadUrl,
            name: newTitle,
            siteType: SiteType.BasicInternal,
          });
          break;

        case LinkValidationErrorCode.INVALID_URL:
        case LinkValidationErrorCode.BAD_PAGE_RESPONSE:
        case LinkValidationErrorCode.INTERNAL_ERR:
        default:
          this.setState({
            viewMode: SitePickerViewMode.InvalidError,
            name: newTitle,
          });
          break;
      }
    }
  };

  private getSiteThumbnail = (url: string, currentScreenshotId?: number) => {
    if (url) {
      try {
        const screenshotUrl = `${filestackApiURL}/urlscreenshot=mode:window,height:768,width:1366/${url}`;
        this.loadAndSetThumbnailAsBase64(screenshotUrl, currentScreenshotId);
      } catch (e) {
        this.setState({ thumbnailError: e.message });
      }
    }
  };

  private loadAndSetThumbnailAsBase64 = async (
    thumbnailUrl?: string,
    currentScreenshotId?: number
  ): Promise<void> => {
    if (!thumbnailUrl) {
      thumbnailUrl = this.state.thumbnailUrl;
      currentScreenshotId = ++this.targetScreenshotId;
    } else if (currentScreenshotId !== this.targetScreenshotId) {
      return;
    } else {
      this.setState({ thumbnailUrl });
    }

    if (thumbnailUrl) {
      try {
        const response = await fetch(thumbnailUrl);
        const blob = await response.blob();

        const base64 = await this.readFileAsync(blob);
        const baseString = base64.toString();

        this.setState({
          thumbnailData: baseString,
          thumbnailError: undefined,
        });
      } catch (ex) {
        if (currentScreenshotId !== this.targetScreenshotId) {
          return;
        }

        this.setState({
          thumbnailData: undefined,
          thumbnailError: "Could not load thumbnail.",
        });
      }
    }
  };

  private readFileAsync(blob: Blob): Promise<any> {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException("Problem parsing input file"));
      };

      fileReader.onload = () => {
        const result = (fileReader && fileReader.result) || {};
        resolve(result);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  private handleSubmit = async () => {
    if (this.state.viewMode === SitePickerViewMode.ValidCloud) {
      this.context.modal.closeModals();
      await this.context.modal.openSecureSiteConfigure(null, {
        url: this.state.url,
        name: this.state.name,
      });
      return;
    }
    this.setState({ isSaving: true });

    const { actionMode } = this.state;
    const spaceId = this.context.user.settings.spaceId;

    const site: Site | undefined =
      actionMode === SitePickerViewActionMode.Create
        ? await this.createSite(spaceId)
        : await this.updateSite(this.state.id, this.state.siteType);

    if (this.props.callback) {
      this.props.callback();
    } else {
      this.context.modal.closeModals();
    }

    if (site && this.state.thumbnailData) {
      const uploadedFile = await client.upload(
        this.state.thumbnailData as string,
        {},
        {
          access: "public",
          container: appConfig.uploadsBucket,
          location: appConfig.uploadsLocation,
          path: "",
          region: appConfig.s3Region,
        }
      );

      let metadata = await resolveFileKey({
        ...uploadedFile,
        name: this.state.name,
      });

      if (uploadedFile.mimetype!.startsWith("image")) {
        const dimensions = await getImageDimensions(uploadedFile.handle);
        const { width, height } = await dimensions.json();
        metadata = { ...metadata, height, width };
      }

      const fileName = trimToLessThan80Char(this.state.name!);
      const createFileVariables: CreateFileMutationVariables = {
        input: {
          metadata,
          mimetype: uploadedFile.mimetype,
          name: fileName,
          size: uploadedFile.size,
          source: `https://${appConfig.uploadsBaseUrl}/${
            appConfig.uploadsBucket
          }/${escape(metadata.key!)}`,
          spaceId,
          tags: [],
        },
      };

      const {
        data: {
          createFile: {
            file: { id },
          },
        },
      } = (await this.props.createFile({
        variables: createFileVariables,
      })) as any;

      await this.updateSite(site.id, site.type, { thumbnailId: id });
    }
  };

  private handleRefreshIntervalUnitChange = (e, { value }) => {
    this.setState({
      refreshIntervalUnit: value,
    });
  };

  private getInitialState(props: ISiteLinkPickerProps) {
    let viewMode = SitePickerViewMode.Initial;

    if (props.site) {
      switch (props.site.type) {
        case SiteType.Basic:
          viewMode = SitePickerViewMode.ValidBasic;
          break;
        case SiteType.BasicInternal:
          viewMode = SitePickerViewMode.InvalidBadUrl;
          break;
        case SiteType.Cloud:
        case SiteType.CloudInternal:
          viewMode = this.isCloudRenderingAvailable()
            ? SitePickerViewMode.ValidCloud
            : SitePickerViewMode.CloudRenderUpgradeRequired;
          break;
        default:
          viewMode = SitePickerViewMode.InvalidError;
      }
    }

    let refreshInterval = "";
    let refreshIntervalUnit = "m";

    if (
      props.site?.config?.refreshInterval &&
      props.site?.config?.refreshInterval !== ""
    ) {
      refreshInterval = props.site.config.refreshInterval.slice(
        0,
        props.site.config.refreshInterval.length - 1
      );
      refreshIntervalUnit = props.site.config.refreshInterval.slice(
        props.site.config.refreshInterval.length - 1
      );
    }

    return {
      // Site Data
      id: props.site?.id,
      url: props.site?.url || "",
      name: props.site?.name || "",
      siteType: props.site?.type,
      refreshInterval,
      refreshIntervalUnit,
      thumbnailId: props.site?.thumbnailId,
      tags: props.site?.tags ? (props.site.tags as string[]) : [],

      // Utility data
      nameError: undefined,
      refreshIntervalError: undefined,
      tagOptions: props.site?.tags
        ? props.site.tags.map((tag: string) => ({ text: tag, value: tag }))
        : [],
      thumbnailData: undefined,
      thumbnailUrl: props.site?.fileByThumbnailId?.source || undefined,
      thumbnailError: undefined,
      previousUrl: "",
      viewMode,
      actionMode: !props.site
        ? SitePickerViewActionMode.Create
        : SitePickerViewActionMode.Update,
      isSaving: false,
      isDirty: false,

      // Advanced Settings
      scrollFrom: this.props.site?.config?.scrollFrom
        ? this.props.site?.config?.scrollFrom
        : 0,
      scrollTo: this.props.site?.config?.scrollTo
        ? this.props.site?.config?.scrollTo
        : 0,
      zoom: this.props.site?.config?.zoom ? this.props.site?.config?.zoom : 0,
    };
  }

  private validateUrl = async (url: string): Promise<void> => {
    try {
      this.clearExistingThumbnail();
      this.setState({ viewMode: SitePickerViewMode.Validating });

      const urlStatus = await this.checkUrl(url);
      this.handleValidateUrlResponse(urlStatus);
    } catch (ex) {
      this.setState({ viewMode: SitePickerViewMode.InvalidError });
    }
  };

  private clearExistingThumbnail = () => {
    this.setState({
      thumbnailId: undefined,
      thumbnailData: undefined,
      thumbnailError: undefined,
      thumbnailUrl: undefined,
    });
  };

  private handleUrlChange = (e) => {
    // Dont allow edits to change the URL - there are way too many things we cant support
    // such as changing the thumbnail if it goes from Basic -> Internal sites.
    if (this.state.actionMode === SitePickerViewActionMode.Create) {
      const previousValue = this.state.url;
      this.setState({
        url: e.target.value,
        previousUrl: previousValue,
        name: "",
        siteType: undefined,
        refreshInterval: "",
        refreshIntervalUnit: "m",
        thumbnailId: undefined,
        tags: [],
        nameError: undefined,
        refreshIntervalError: undefined,
        tagOptions: [],
        thumbnailData: undefined,
        thumbnailUrl: undefined,
        thumbnailError: undefined,
        viewMode: SitePickerViewMode.Initial,
        isSaving: false,
        isDirty: false,
      });
    }
  };

  private handleValidateUrl = () => {
    let { url } = this.state;
    if (url !== (this.props.site?.url || "")) {
      if (!url.includes("://")) {
        url = "https://" + url;
        this.setState({ url });
      }

      if (url !== this.state.previousUrl) {
        this.validateUrl(url);
        this.setState({ previousUrl: url, isDirty: true });
      }
    }
  };

  private handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
      isDirty: true,
    });
  };

  private handleValidateName = () => {
    if (this.state.name.trim() === "") {
      this.setState({ nameError: true });
    } else {
      this.setState({ nameError: false });
    }
  };

  private handleRefreshIntervalChange = (e) => {
    const val = e.target.value !== "" ? parseInt(e.target.value, 10) : "";
    this.setState({
      refreshInterval: `${val}`,
      isDirty: true,
    });
  };

  private getRefetchQueries = () => {
    return {
      query: SiteListDocument,
      variables: {
        spaceId: this.context.user.settings.spaceId,
      },
    };
  };

  private createSite = async (spaceId: string): Promise<Site | undefined> => {
    const {
      url,
      name,
      siteType,
      tags,
      scrollFrom,
      scrollTo,
      zoom,
    } = this.state;

    const config: any = {
      refreshInterval: this.getRefreshInterval(),
    };
    if (scrollFrom) {
      config.scrollFrom = scrollFrom;
    }
    if (scrollTo) {
      config.scrollTo = scrollTo;
    }
    if (zoom) {
      config.zoom = zoom;
    }

    const createSiteVariables: CreateSiteMutationVariables = {
      input: {
        spaceId,
        url,
        name,
        type: siteType!,
        config,
        tags,
      },
    };

    const result = await this.props.createSite({
      refetchQueries: [this.getRefetchQueries()],
      variables: createSiteVariables,
    });

    return (result.data?.createSite?.site as Site) || undefined;
  };

  private getRefreshInterval = (): string | undefined => {
    const { refreshInterval, refreshIntervalUnit } = this.state;
    return refreshInterval.trim() !== ""
      ? `${refreshInterval}${refreshIntervalUnit}`
      : undefined;
  };

  private updateSite = async (
    id,
    type,
    fields?: any
  ): Promise<Site | undefined> => {
    const {
      url,
      name,
      thumbnailId,
      tags,
      scrollFrom,
      scrollTo,
      zoom,
    } = this.state;
    const config: any = {
      refreshInterval: this.getRefreshInterval(),
    };
    if (scrollFrom) {
      config.scrollFrom = scrollFrom;
    }
    if (scrollTo) {
      config.scrollTo = scrollTo;
    }
    if (zoom) {
      config.zoom = zoom;
    }
    const updateSite: UpdateSiteByIdMutationVariables = fields
      ? { input: { id, type, config, ...fields } }
      : { input: { id, type, name, url, config, thumbnailId, tags } };

    const result = await this.props.updateSiteById({
      refetchQueries: [this.getRefetchQueries()],
      variables: updateSite,
    });

    return (result.data?.updateSite?.site as Site) || undefined;
  };
}

export default withData(SitePicker);
