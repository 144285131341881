import { UUID } from "@screencloud/uuid";
import { useGetAllActiveContentQuery } from "src/types.g";

export function useGetAllContentIdsInSpace(spaceId: UUID): UUID[] {
  const { data } = useGetAllActiveContentQuery({
    variables: { spaceId },
    fetchPolicy: "cache-and-network",
  });
  return [
    ...(data?.spaceById?.appInstancesBySpaceId.nodes
      .map((item) => item.id)
      .filter(Boolean) ?? []),
    ...(data?.spaceById?.filesBySpaceId.nodes
      .map((item) => item.id)
      .filter(Boolean) ?? []),
    ...(data?.spaceById?.linksBySpaceId.nodes
      .map((item) => item.id)
      .filter(Boolean) ?? []),
    ...(data?.spaceById?.sitesBySpaceId.nodes
      .map((item) => item.id)
      .filter(Boolean) ?? []),
  ];
}
