import { Styled } from "./styles";
import { ButtonCheckbox } from "@screencloud/screencloud-ui-components";
import Avatar from "../Admin/Avatar";

interface Props {
  name: string | null;
  email: string | null;
  imgUrl: string | null;
  onToggle: (flag: boolean) => void;
  isSubscribed: boolean;
  disabled?: boolean;
}

export default function PeopleListItem(props: Props) {
  const handleSubscribe = (checked: boolean, _indeterminate: boolean) => {
    props.onToggle(checked);
  };
  const { disabled } = props;
  const disabledAttr = disabled ? "disabled" : "";

  return (
    <Styled
      className={`people-list-item ${disabledAttr}`}
      data-testid={`list-item-${props.email}`}
    >
      {props?.imgUrl ? (
        <div
          className={`avatar ${disabledAttr}`}
          style={{ backgroundImage: "url(" + props.imgUrl + ")" }}
        ></div>
      ) : (
        <Avatar name={props.name as string} />
      )}

      <div className={`info ${disabled ? "disabled" : ""}`}>
        <span className="name"> {props.name || props.email}</span>
        {props.name && <span className="email"> {props.email}</span>}
      </div>
      <div className="actions">
        <ButtonCheckbox
          data-testid="toggle"
          onChange={handleSubscribe}
          checked={props.isSubscribed}
          disabled={props.disabled}
        />
      </div>
    </Styled>
  );
}
