import { Colors, DEFAULT_FONT } from "../../../constants/constants";
import { useRegisterFont } from "../../../hooks/useRegisterFont";
import { ThemeConfig } from "../../../pages/Admin/Themes";
import { Styled } from "./styles";
export interface ThemePreviewType {
  config: ThemeConfig;
}

const ThemePreview = ({ config }: ThemePreviewType) => {
  useRegisterFont({
    headingFont: config.headingFont,
    bodyFont: config.bodyFont,
  });

  return (
    <Styled>
      <div
        data-testid="theme-preview"
        className="preview"
        style={{ backgroundColor: config.primaryColor[500] }}
      >
        <div className="preview-text">
          <span
            style={{
              color: config.textOnPrimary[500] || Colors.WHITE,
              fontFamily: config.headingFont?.family || DEFAULT_FONT.family,
            }}
          >
            A{" "}
          </span>
          <span
            style={{
              color: config.textOnSecondary[500] || Colors.WHITE,
              fontFamily: config.bodyFont?.family || DEFAULT_FONT.family,
            }}
          >
            a
          </span>
        </div>
        <div
          className="preview-line"
          style={{ backgroundColor: config.secondaryColor[500] }}
        />
      </div>
    </Styled>
  );
};

export default ThemePreview;
