import {
  DatetimeFormat,
  Icon,
  LoaderBar,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { RefType, SortingActions } from "../../constants/constants";
import { appInstanceIconUrl } from "../../helpers/appHelper";
import { getChannelCover } from "../../helpers/channelHelper";
import {
  AppInstance,
  Channel,
  File,
  Link,
  Maybe,
  Playlist,
  Site,
} from "../../types.g";
import DateTime from "../DateTime";
import Thumbnail from "../MediaThumbnail";
import { ApolloProps, withData } from "./apollo";
import { Styled } from "./styles";

export interface RecentPickerProps {
  screenId?: UUID;
  callback?: (
    id: string,
    refType: RefType,
    data:
      | Maybe<Partial<Channel>>
      | Maybe<Partial<AppInstance>>
      | Maybe<Partial<File>>
      | Maybe<Partial<Playlist>>
      | Maybe<Partial<Link>>
      | Maybe<Partial<Site>>
  ) => void;
}

export interface RecentPickerState {
  isOrderByAscending: boolean;
  sortBy: SortingActions;
  selected: UUID;
  selectedData:
    | Maybe<Partial<Channel>>
    | Maybe<Partial<AppInstance>>
    | Maybe<Partial<File>>
    | Maybe<Partial<Playlist>>
    | Maybe<Partial<Link>>
    | Maybe<Partial<Site>>;
}
export class RecentPickerComponent extends React.PureComponent<
  RecentPickerProps & ApolloProps,
  RecentPickerState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: RecentPickerProps & ApolloProps) {
    super(props);
    this.state = {
      isOrderByAscending: true,
      selected: "",
      selectedData: null,
      sortBy: SortingActions.SORT_BY_NAME,
    };
  }

  public toggleSelect = (
    id: UUID,
    type: RefType,
    contentData:
      | Maybe<Partial<Channel>>
      | Maybe<Partial<AppInstance>>
      | Maybe<Partial<File>>
      | Maybe<Partial<Playlist>>
      | Maybe<Partial<Link>>
      | Maybe<Partial<Site>>
  ) => {
    if (this.props.callback) {
      this.props.callback && this.props.callback(id, type, contentData);
    }

    this.setState({
      selected: id,
      selectedData: contentData,
    });
  };

  public getContentItemByType = (type: RefType, contentData) => {
    let itemType = "";
    let thumbnail;
    switch (type) {
      case RefType.CHANNEL:
        itemType = "Channel";
        const cover = getChannelCover(
          contentData,
          this.context.secureMediaPolicy
        );
        const coverStyle =
          cover.indexOf("http") !== -1
            ? { backgroundImage: "url(" + cover + ")" }
            : { backgroundImage: cover };
        thumbnail = (
          <div className="channel-thumbnail-preview" style={coverStyle} />
        );
        break;
      case RefType.PLAYLIST:
        thumbnail = (
          <div
            className="playlist-thumbnail-preview"
            style={{ backgroundColor: contentData.color! }}
          >
            <Icon name="playlist" />
          </div>
        );
        itemType = "Playlist";
        break;
      case RefType.APP:
      case RefType.APP_INSTANCE:
        itemType = "App";
        thumbnail = (
          <div className="app-thumbnail-preview">
            <img
              src={appInstanceIconUrl((contentData as unknown) as AppInstance)}
            />
          </div>
        );
        break;
      case RefType.LINK:
        itemType = "Link";
        thumbnail = (
          <Thumbnail
            className="medium"
            media={contentData.fileByFileId}
            width={320}
            height={180}
            secureMediaPolicy={this.context.secureMediaPolicy}
          />
        );
        break;
      case RefType.SITE:
        itemType = "Site";
        thumbnail = (
          <Thumbnail
            className="medium"
            media={contentData.fileByThumbnailId}
            width={320}
            height={180}
            secureMediaPolicy={this.context.secureMediaPolicy}
          />
        );
        break;
      case RefType.FILE:
        const fileType = contentData?.mimetype.split("/")[0];
        thumbnail = (
          <Thumbnail
            className="medium"
            media={contentData}
            width={320}
            height={180}
            secureMediaPolicy={this.context.secureMediaPolicy}
          />
        );
        switch (fileType) {
          case "image":
            itemType = "Image";
            break;
          case "video":
            itemType = "Video";
            break;
          case "application":
            itemType = "Document";
            break;
        }
        break;
      default:
        break;
    }
    return {
      kind: itemType,
      thumbnail,
    };
  };

  public renderRecentListItem = () => {
    if (this.props.data?.screenById) {
      return this.props.data.screenById.screenContentHistoriesByScreenId.nodes
        .filter((contentHistory) => {
          return (
            contentHistory?.fileByFileIdAndOrgId ||
            contentHistory?.linkByLinkIdAndOrgId ||
            contentHistory?.siteBySiteIdAndOrgId ||
            contentHistory?.appInstanceByAppInstanceIdAndOrgId ||
            contentHistory?.playlistByPlaylistIdAndOrgId ||
            contentHistory?.channelByChannelIdAndOrgId
          );
        })
        .map((contentHistory, idx) => {
          const contentData =
            contentHistory?.fileByFileIdAndOrgId ||
            contentHistory?.linkByLinkIdAndOrgId ||
            contentHistory?.siteBySiteIdAndOrgId ||
            contentHistory?.appInstanceByAppInstanceIdAndOrgId ||
            contentHistory?.playlistByPlaylistIdAndOrgId ||
            contentHistory?.channelByChannelIdAndOrgId;
          const contentItem = this.getContentItemByType(
            contentHistory.content?._ref.type,
            contentData
          );
          return (
            <div
              key={`recent-content-item-${contentHistory.content?._ref.id}`}
              data-testid={`recent-content-item-${idx}`}
              className={`media-item ${
                this.state.selected === contentHistory.content?._ref.id
                  ? "selected"
                  : ""
              }`}
              onClick={() => {
                this.toggleSelect(
                  contentHistory.content?._ref.id,
                  contentHistory.content?._ref.type,
                  contentData as File
                );
              }}
            >
              <div className="media-core">
                <div className="media-alpha">
                  <div className="thumbnail-preview">
                    {contentItem.thumbnail}
                  </div>
                  <div className="media-title">
                    <h3>
                      <span>{contentData?.name}</span>
                    </h3>
                    <div>
                      <span className="recent-item__date">
                        {contentHistory.content?._ref.type ===
                          RefType.CHANNEL ||
                        contentHistory.content?._ref.type ===
                          RefType.PLAYLIST ? (
                          (contentData as Channel | Playlist)?.draft
                            ?.isPublished ? (
                            <FormattedMessage
                              id="ui_component.common.label.published"
                              defaultMessage="Published"
                            />
                          ) : (
                            <>
                              <Icon name="warning" />
                              <FormattedMessage
                                id="common.text.draft_saved"
                                defaultMessage="Draft Saved. Publish required."
                              />
                            </>
                          )
                        ) : (
                          <>
                            <FormattedMessage
                              id="common.text.created_on"
                              defaultMessage="Created on"
                            />{" "}
                            <DateTime
                              value={contentData?.createdAt}
                              format={DatetimeFormat.Short}
                            />
                          </>
                        )}
                      </span>
                      <span className="recent-item__kind">
                        <span>{contentItem.kind}</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="media-kind">
                  <span>{contentItem.kind}</span>
                </div>
              </div>

              <div className="media-checked">
                <Icon name="checked-circle" />
              </div>
            </div>
          );
        });
    } else {
      return [];
    }
  };

  public render() {
    const { data } = this.props;
    const renderRecentList = this.renderRecentListItem();
    return (
      <Styled className={`media-content recent`}>
        <div className="container">
          {renderRecentList.length > 0 ? (
            <>
              <div className="media-item-header">
                <div className="media-core">
                  <div className="media-alpha">
                    <FormattedMessage
                      id="subheader.label.name"
                      defaultMessage="Name"
                    />
                  </div>
                  <div className="media-kind">
                    <FormattedMessage
                      id="subheader.label.name"
                      defaultMessage="Kind"
                    />
                  </div>
                </div>
              </div>
              <div className="layout-list" id="scrollableDiv">
                {renderRecentList}
              </div>
            </>
          ) : (
            <div className="recent-empty-state">No recent history</div>
          )}
          {data?.loading && renderRecentList.length === 0 && <LoaderBar />}
        </div>
      </Styled>
    );
  }
}

export default withData(
  RecentPickerComponent
) as React.ComponentType<RecentPickerProps>;
