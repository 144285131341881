import styled from "styled-components";

export const ContentAccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-sm, 8px);
  align-items: center;
  align-self: stretch;
  margin-right: 40px;
  border-radius: var(--radius-lg, 16px);
  border: 1px solid var(--color-gray-200, #c5c6ca);
  background: var(--color-white-100, #fff);
  .banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: var(--spacing-sm, 8px);
    &.with-items {
      margin-bottom: var(--spacing-sm, 8px);
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  width: var(--size-xl, 56px);
  height: var(--size-xl, 56px);
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--color-gray-100, #e2e3e5);
  background: var(--color-gray-50, #f6f6f7);
  i {
    background-color: var(--color-gray-800, #363639);
  }
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xxxs, 2px);
  flex: 1 0 0;
  .title {
    color: var(--color-gray-950, #19191b);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .description {
    color: var(--color-gray-300, #a1a2a7);
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
  }
`;
