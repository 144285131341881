import { FormattedMessage } from "react-intl";
import {
  DuplicateJobType,
  GetImportJobsDocument,
  MutationCreateDuplicateJobsArgs,
  Playlist,
  SnapshotType,
  useCreateDuplicateJobsMutation,
} from "src/types.g";
import { useAppContext } from "src/hooks/useAppContext";
import { getDuplicatePlaylistName } from "./utils";
import { useOpenDuplicationModal } from "./useOpenDuplicationModal";
import DuplicateContentModal from "src/components/Duplication/DuplicateContentModal";
import { useEffect, useState } from "react";
import { isNotCurrentSpace } from "../../domains/spaces/isNotCurrentSpace";

export interface UseDuplicatePlaylist {
  duplicatePlaylist: () => Promise<void>;
}

export function useDuplicatePlaylist(
  playlist: Pick<Playlist, "id" | "name">
): UseDuplicatePlaylist {
  const context = useAppContext();
  const [selectedSpaceIds, setSelectedSpaceIds] = useState<string[]>([]);
  const [confirmForm, setConfirmForm] = useState<boolean>(false);
  const [createDuplicateJobs] = useCreateDuplicateJobsMutation();
  const { openInprogressModalDetail } = useOpenDuplicationModal(
    SnapshotType.Playlist
  );
  const onSpaceSelected = (targetSpacesId: string[]) =>
    setSelectedSpaceIds(targetSpacesId);
  const selectableSpaces = context.allSpaces.filter((space) =>
    isNotCurrentSpace(space, context.currentSpace?.id)
  );

  const duplicatePlaylist = async () => {
    const { confirm } = await context.modal.confirm(
      <DuplicateContentModal
        spaces={selectableSpaces}
        contentName={playlist.name}
        onSpaceSelected={onSpaceSelected}
        snapshotType={SnapshotType.Playlist}
      />,
      {
        confirm: (
          <FormattedMessage
            id="ui_component.common.label.continue"
            defaultMessage="Continue"
          />
        ),
        cancel: (
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        ),
      }
    );
    setConfirmForm(confirm);
  };

  useEffect(() => {
    if (confirmForm) {
      const snapshotInput: MutationCreateDuplicateJobsArgs = {
        input: {
          jobType: DuplicateJobType.SnapshotAndImport,
          sourceSpaceId: context.currentSpace?.id,
          sourceContentId: playlist.id,
          snapshotType: SnapshotType.Playlist,
          isPublicSnapshot: false,
          targetSpaceIds: selectedSpaceIds,
          targetNewName: getDuplicatePlaylistName(playlist.name),
        },
      };
      createDuplicateJobs({
        variables: snapshotInput,
        refetchQueries: [
          {
            query: GetImportJobsDocument,
            variables: {
              snapshotType: SnapshotType.Playlist,
              sourceSpaceId: context.currentSpace?.id,
            },
          },
        ],
      });
      openInprogressModalDetail();
      context.modal.closeNavigationControlModal();
      setConfirmForm(false);
    }
  }, [confirmForm]);

  return { duplicatePlaylist };
}
