import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Icon,
  InlineInput,
  TagList,
  Thumbnail,
  ToggleStar,
} from "@screencloud/screencloud-ui-components";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { File, LinkType } from "../../types.g";
import DateTime from "../DateTime";
import { Styled } from "./styles";

import { EntityType } from "@screencloud/signage-firestore-client";
import { generateImgixThumbnail } from "../../helpers/mediaHelper";
import { subscribeToDocumentUpdates } from "../../state/liveUpdateManager";
import { CastedScreen, renderCastingStatus } from "../../helpers/castingHelper";
import { CastedScreenInfoActions } from "../CastedScreenInfo";
import { TrackVisibilityV2 } from "../TrackVisibility";
import { useAppContext } from "../../hooks/useAppContext";
import { MediaType } from "../../constants/constants";

export enum LinkListItemActions {
  CAST = "CAST",
  DELETE = "DELETE",
  MOVE = "MOVE",
  RENAME = "RENAME",
  PREVIEW = "PREVIEW",
  CLOSE_PREVIEW = "CLOSE_PREVIEW",
  SHARE = "SHARE",
  SELECTED = "SELECTED",
  FAVORITE = "FAVORITE",
  CHECKED = "CHECKED",
  VIEW = "VIEW",
  ADD = "ADD",
  REFETCH = "REFETCH",
}

export enum LinkListFilters {
  ALL = "ALL",
  STARRED = "STARRED",
}

export interface LinkListItemPayload {
  nodeId: string;
  event: React.SyntheticEvent<any>;
  data: any;
  action: LinkListItemActions;
}

export type LinkListItemCallBack = (
  linkId: string,
  action: LinkListItemActions,
  value?: string | boolean
) => void;

export interface LinkListItemProps {
  callBack: LinkListItemCallBack;
  castedScreensCallback?: (
    data: string,
    action: CastedScreenInfoActions
  ) => void;
  media?: Partial<File> | null;
  id: string;
  name: string;
  url: string;
  favorite: boolean;
  addedAt: string;
  addedBy: string;
  actions: boolean;
  tags?: string[];
  className?: string;
  isSelected?: boolean;
  forceVisible?: boolean;
  preview?: React.ReactNode;
  previewOpen: boolean;
  isCompactLayout?: boolean;
  isSelectedActive?: boolean;
  linkType?: string;
  castedScreens?: number;
  castedScreensData?: CastedScreen[];
  targetContainerId?: string;
  index: number;
}

export interface LinkListItemState {
  isRenameState: boolean;
  isDeleteConfirmOpen: boolean;
  isPreviewOpen: boolean;
  linkListItemPayload: LinkListItemPayload | null;
}

const LinkListItem = (props: LinkListItemProps) => {
  const {
    id,
    media,
    url,
    favorite,
    actions,
    isSelected,
    isSelectedActive,
    isCompactLayout,
    name,
    addedAt,
    addedBy,
    linkType,
    castedScreensData,
    tags,
    index,
    castedScreensCallback,
    callBack,
  } = props;
  const context = useAppContext();

  let unsubscribeLiveUpdateFn: (() => void) | undefined;
  const [isRenameState, setIsRenameState] = useState<
    LinkListItemState["isRenameState"]
  >(false);
  const thumbnail = media
    ? generateImgixThumbnail(
        media as File,
        context.secureMediaPolicy,
        true,
        MediaType.IMAGE
      )
    : "";
  const linkThumbnail = <Thumbnail src={thumbnail} size="medium" />;
  const canCastToScreen = context.currentPermissions.validateCurrentSpace(
    "screen",
    "cast"
  );
  const canDeleteLink = context.currentPermissions.validateCurrentSpace(
    "link",
    "delete"
  );

  let linkItemClassname = "media-item";

  if (!actions) {
    linkItemClassname += " readonly";
  }

  if (isSelected) {
    linkItemClassname += " selected";
  }

  if (isCompactLayout) {
    linkItemClassname += ` link-item-small`;
  }

  useEffect(() => {
    subscribeToLiveUpdate();

    return () => {
      unsubscribeFromLiveUpdate();
    };
  });

  const subscribeToLiveUpdate = () => {
    unsubscribeFromLiveUpdate();
    if (media && media.id) {
      unsubscribeLiveUpdateFn = subscribeToDocumentUpdates(
        media.orgId || "",
        EntityType.FILE,
        media.id,
        () => {
          callBack(id, LinkListItemActions.REFETCH);
        },
        true
      );
    }
  };

  const unsubscribeFromLiveUpdate = () => {
    if (unsubscribeLiveUpdateFn) {
      unsubscribeLiveUpdateFn();
      unsubscribeLiveUpdateFn = undefined;
    }
  };

  const handleItemClick = (e: React.SyntheticEvent<any>) => {
    callBack(id, LinkListItemActions.SELECTED);
  };

  const handleActionClick = (
    linkId: string,
    action: LinkListItemActions,
    value?: string | boolean
  ) => {
    switch (action) {
      case LinkListItemActions.RENAME:
        setIsRenameState(true);
        break;
      default:
        callBack(linkId, action, value);
    }
  };

  const handleUrlClick = () => {
    window.open(url);
  };

  const handleFavorite = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (callBack) {
      callBack(id, LinkListItemActions.FAVORITE, !favorite);
    }
  };

  const handleView = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (callBack && isRenameState === false) {
      callBack(id, LinkListItemActions.VIEW);
    }
  };

  const onNameSave = (e: React.SyntheticEvent<any>, value: string) => {
    callBack(id, LinkListItemActions.RENAME, value);
    setIsRenameState(false);
  };

  const renderTitle = () => {
    if (isRenameState) {
      return (
        <InlineInput
          value={name}
          onSaved={onNameSave}
          editmode={true}
          spellCheck={false}
        />
      );
    } else {
      return (
        <h3 data-testid="link-title" title={name}>
          <span onClick={handleView}>{name}</span>
          {context.currentPermissions.validateCurrentSpace(
            "link",
            "update"
          ) && (
            <Icon
              name="edit"
              onClick={() => handleActionClick(id, LinkListItemActions.RENAME)}
              title="Rename"
            />
          )}
        </h3>
      );
    }
  };

  return (
    <Styled
      data-testid={`link-list-item-${index}`}
      className={linkItemClassname}
      onClick={isSelectedActive ? handleItemClick : () => false}
    >
      <TrackVisibilityV2
        forceVisible={props.forceVisible}
        key={`trackvisibility-${id}`}
        partialVisibility={true}
        offset={500}
        container={props.targetContainerId}
      >
        <div className="media-core">
          <div className="media-alpha">
            <div className="thumbnail-preview" onClick={handleView}>
              {linkThumbnail}
            </div>
            <div className="media-title">
              {renderTitle()}
              <div>
                <span className="media-item__date">
                  <FormattedMessage
                    id="common.text.created_on"
                    defaultMessage="Created on"
                  />{" "}
                  <DateTime value={addedAt} />{" "}
                  <FormattedMessage id="common.text.by" defaultMessage="by" />{" "}
                  {addedBy}
                </span>
                <span className="media-item__url">{url}</span>
              </div>
            </div>
          </div>

          {context.shouldShowFeature(FEATURE_FLAGS_ENUM.STARRING) && (
            <div className="media-starred">
              <ToggleStar active={favorite} onClick={handleFavorite} />
            </div>
          )}
          <div className="media-casting">
            {renderCastingStatus({
              castScreenData: castedScreensData!,
              callBack: castedScreensCallback!,
              context,
            })}
          </div>

          <div className="media-type">
            {linkType === LinkType.Internal && (
              <FormattedMessage
                id="ui_component.link.type_internal_website"
                defaultMessage="Internal Website"
              />
            )}
            {linkType === LinkType.Cloud && (
              <FormattedMessage
                id="ui_component.link.type_cloud_render"
                defaultMessage="Cloud Render"
              />
            )}
            {linkType === LinkType.Standard && (
              <FormattedMessage
                id="ui_component.link.type_website"
                defaultMessage="Website"
              />
            )}
          </div>

          <div className="media-url">
            <span onClick={handleUrlClick}>{url}</span>
          </div>

          <div className="media-tags">{tags && <TagList taglist={tags} />}</div>
        </div>

        <div className="media-options">
          {isSelectedActive ? (
            <Button data-testid="select-checkbox" icon borderless mini>
              {isSelected ? (
                <Icon name="checkbox-checked" />
              ) : (
                <Icon name="checkbox-empty" />
              )}
            </Button>
          ) : (
            <Dropdown
              data-testid="action-dropdown"
              checkEmpty
              icon={
                <Button icon borderless mini>
                  <Icon name="dots" />
                </Button>
              }
              className="media-dropdown"
            >
              <DropdownMenu>
                {context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) &&
                  canCastToScreen && (
                    <DropdownItem
                      data-testid="set-link-to-screen"
                      onClick={() =>
                        handleActionClick(id, LinkListItemActions.CAST)
                      }
                    >
                      <Icon name="screen-play" />
                      <FormattedMessage
                        id="ui_component.common.label.set_to_screen"
                        defaultMessage="Set to Screen"
                      />
                    </DropdownItem>
                  )}
                {context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) &&
                  canCastToScreen &&
                  canDeleteLink && <DropdownDivider />}
                {canDeleteLink && (
                  <DropdownItem
                    data-testid="delete-link"
                    onClick={() =>
                      handleActionClick(id, LinkListItemActions.DELETE, name)
                    }
                    className="danger"
                  >
                    <Icon name="trash" />{" "}
                    <FormattedMessage
                      id="ui_component.common.label.delete_link"
                      defaultMessage="Delete Link"
                    />
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </TrackVisibilityV2>
    </Styled>
  );
};

export default LinkListItem;
