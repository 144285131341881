import { UUID } from "@screencloud/uuid";

export enum EntityType {
  Playlist = "Playlist",
  Channel = "Channel",
}

export type Content = {
  id: UUID;
  type: string;
  name: string;
};

export type Entity = {
  id: UUID;
  type: EntityType;
  name: string;
};

export type UsageEntities = {
  playlists: (Entity & { color: string })[];
  channels: (Entity & {
    coverData: { color: { bottom: string; name: string; top: string } };
    coverImageSource: string;
  })[];
};
