import { useUserById } from "../../hooks/useUserById";
import { getDisplayName } from "../../helpers/userHelper";
import { Styled } from "./style";

export interface UserNameProps {
  id: string;
  className?: string;
}

export default ({ id, className }: UserNameProps) => {
  const { user } = useUserById({ id });
  if (!user) {
    return <></>;
  }
  return <Styled className={className}>{getDisplayName(user)}</Styled>;
};
