import ShareModal from "../../../components/ShareModal";
import { canBeShared } from "../../../helpers/shareableHelper";
import { useAppContext } from "../../../hooks/useAppContext";
import {
  Folder,
  FolderByIdRefetchAfterShareDocument,
  UpdateShareFolderToAllSpacesMutationVariables,
  UpdateShareFolderToSpacesMutationVariables,
  useUpdateShareFolderToAllSpacesMutation,
  useUpdateShareFolderToSpacesMutation,
} from "../../../types.g";

interface Props {
  folder: Pick<
    Folder,
    "id" | "spaceId" | "isSharedAll" | "sharedSpacesByFolderId" | "__typename"
  >;
}

export function FolderShareModal(props: Props) {
  const context = useAppContext();
  const { folder } = props;
  const [updateShareFolderToSpaces] = useUpdateShareFolderToSpacesMutation();
  const [
    updateShareFolderToAllSpaces,
  ] = useUpdateShareFolderToAllSpacesMutation();

  const handleShare = (sharedSpaceIds: string[]) => {
    const updateShareFolderToSpacesInput: UpdateShareFolderToSpacesMutationVariables = {
      input: {
        folderId: folder.id,
        spaces: sharedSpaceIds,
      },
    };
    updateShareFolderToSpaces({
      variables: updateShareFolderToSpacesInput,
      refetchQueries: [
        {
          query: FolderByIdRefetchAfterShareDocument,
          variables: { folderId: folder.id },
        },
      ],
    });
  };

  const handleShareAll = (value: boolean) => {
    const sharedAllInput: UpdateShareFolderToAllSpacesMutationVariables = {
      input: {
        isSharedAll: value,
        folderId: folder.id,
      },
    };
    updateShareFolderToAllSpaces({
      variables: sharedAllInput,
      refetchQueries: [
        {
          query: FolderByIdRefetchAfterShareDocument,
          variables: { folderId: folder.id },
        },
      ],
    });
  };

  return (
    <ShareModal
      shareable={folder}
      sharedSpaces={folder.sharedSpacesByFolderId.nodes}
      isDisabled={!canBeShared({ context, shareable: folder })}
      onShareToSpaceChanges={handleShare}
      onShareAllChange={handleShareAll}
    />
  );
}
