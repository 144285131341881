import { FetchResult } from "@apollo/client";
import {
  CoverBackgroundType,
  CoverColor,
  CoverImage,
} from "@screencloud/screencloud-ui-components";
import { PickerFileMetadata } from "filestack-js/build/main/lib/picker";
import { get } from "lodash";
import {
  generateImgixURL,
  trimToLessThan80Char,
} from "../../helpers/mediaHelper";
import { resolveFileKey } from "../../helpers/filestackHelper";

import ChannelCover from "../../components/ChannelCover";

import * as React from "react";
import { appConfig } from "../../appConfig";
import {
  CreateFileMutation,
  CreateFileMutationVariables,
  UpdateChannelCoverMutationVariables,
} from "../../types.g";
import { ApolloProps, withData } from "./apollo";
import { ChannelListById } from "../../pages/Channels/ChannelsList";
import { useAppContext } from "../../hooks/useAppContext";

interface IChannelCoverModalProps {
  channel: ChannelListById;
  onBeforeChangingCover?: () => void;
  onCoverImageUpdated?: (id: any) => void;
}

function UpdateChannelCoverContainer(
  props: ApolloProps & IChannelCoverModalProps
) {
  const {
    channel,
    createFile,
    onBeforeChangingCover,
    updateChannelCover,
    onCoverImageUpdated,
  } = props;
  const context = useAppContext();

  const cover = channel.coverData;
  const { fileByCoverImageId } = channel;

  const onChangeCoverHandler = (
    backgroundType: CoverBackgroundType,
    color: CoverColor,
    image: CoverImage
  ) => {
    onBeforeChangingCover && onBeforeChangingCover();
    const channelCoverSettings = {
      ...channel.coverData,
      color,
    };
    // TODO: Add uploaded image uuid to be save from the popup
    const channelCoverInput: UpdateChannelCoverMutationVariables = {
      input: {
        coverData: channelCoverSettings,
        id: channel.id,
      },
    };
    updateChannelCover({
      variables: channelCoverInput,
    });
  };

  const onUploadSuccess = async (res: PickerFileMetadata[]) => {
    onBeforeChangingCover && onBeforeChangingCover();
    const filesUploaded = res;
    const fileList = filesUploaded.map(
      async (
        file
      ): Promise<
        FetchResult<
          CreateFileMutation,
          Record<string, any>,
          Record<string, any>
        >
      > => {
        const fileName = trimToLessThan80Char(file.filename);
        const metadata = await resolveFileKey({ ...file });

        const spaceId = context.user.settings.spaceId;
        const fileInput: CreateFileMutationVariables = {
          input: {
            metadata,
            mimetype: file.mimetype,
            name: fileName,
            size: file.size,
            source: `https://${appConfig.uploadsBaseUrl}/${
              appConfig.uploadsBucket
            }/${escape(metadata.key!)}`,
            spaceId,
            tags: [],
          },
        };
        return createFile({ variables: fileInput });
      }
    );

    const results = await Promise.all(fileList);
    const files = results.map((item) => item?.data?.createFile!.file!);
    updateChannelCoverImage(files[0]);
  };

  const updateChannelCoverImage = async (
    file: NonNullable<CreateFileMutation["createFile"]>["file"]
  ) => {
    if (channel && file) {
      const channelCoverInput: UpdateChannelCoverMutationVariables = {
        input: {
          coverData: {},
          coverImageId: file.id,
          id: channel.id,
        },
      };
      await updateChannelCover({
        variables: channelCoverInput,
      });

      if (file) {
        onCoverImageUpdated && onCoverImageUpdated(file.id);
      }

      context.modal.closeModals();
    }
  };

  return (
    <ChannelCover
      selectedColor={{
        bottom: get(cover, ["color", "bottom"], ""),
        name: get(cover, ["color", "name"], ""),
        top: get(cover, ["color", "top"], ""),
      }}
      selectedImage={{
        id: get(fileByCoverImageId, ["id"], ""),
        name: get(fileByCoverImageId, ["name"], ""),
        url: fileByCoverImageId
          ? generateImgixURL(
              fileByCoverImageId,
              220,
              220,
              context.secureMediaPolicy
            )
          : "",
      }}
      selectedBackgroundType={
        channel.coverImageId && channel.fileByCoverImageId
          ? CoverBackgroundType.TYPE_IMAGE
          : CoverBackgroundType.TYPE_COLOR
      }
      onChangeCover={onChangeCoverHandler}
      onUploadSuccess={onUploadSuccess}
    />
  );
}

export default withData(
  UpdateChannelCoverContainer
) as React.ComponentType<IChannelCoverModalProps>;
