const devicesResolutions: Record<string, { height: number; width: number }> = {
  UHD_4K: {
    height: 2160,
    width: 3840,
  },

  QHD: {
    height: 1440,
    width: 2560,
  },

  FULL_HD_1080P: {
    height: 1080,
    width: 1920,
  },

  HD_720P: {
    height: 720,
    width: 1280,
  },

  SD_480P: {
    height: 480,
    width: 640,
  },

  IPAD: {
    height: 768,
    width: 1024,
  },

  IPHONE_X: {
    height: 375,
    width: 812,
  },

  CUSTOM: {
    height: 0, // get width & height from channel
    width: 0,
  },
};

export default devicesResolutions;
