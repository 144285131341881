import * as React from "react";
import scLogo from "src/images/sc-hd-logo.png";
import { Styled } from "./styles";

const Maintenance = () => {
  return (
    <Styled>
      <div className="content">
        <img width={"40%"} src={scLogo} />
        <div className="wrapper">
          <div className="title">Maintenance in progress...</div>
          <div className="description">
            Studio is currently undergoing maintenance. For further assistance
            please contact{" "}
            <span
              className="support-link"
              onClick={() => {
                window.open("https://screencloud.com/contact", "_blank");
              }}
            >
              our support
            </span>
            .
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Maintenance as React.ComponentType<{}>;
