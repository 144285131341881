import googleDataStudioIcon from "../../../images/dashboard/google-data-studio.svg";
import grafanaIcon from "../../../images/dashboard/grafana.svg";
import powerbiIcon from "../../../images/dashboard/power-bi.svg";
import salesForceIcon from "../../../images/dashboard/sales-force.svg";
import tableauIcon from "../../../images/dashboard/tableau.svg";

export const SAMPLE_APPS = [
  {
    name: "Grafana",
    icon: grafanaIcon,
    url: "https://screencloud.com/dashboards/grafana",
  },
  {
    name: "Tableau",
    icon: tableauIcon,
    url: "https://screencloud.com/dashboards/tableau",
  },
  {
    name: "Google Data Studio",
    icon: googleDataStudioIcon,
    url: "https://screencloud.com/dashboards/googledatastudio",
  },
  {
    name: "Salesforce",
    icon: salesForceIcon,
    url: "https://screencloud.com/dashboards/salesforce",
  },
  {
    name: "Power BI",
    icon: powerbiIcon,
    url: "https://screencloud.com/dashboards/powerbi",
  },
];

export const SCREENCLOUD_URL = "https://screencloud.com/";
export const DEMO_REQUETST_URL = "https://screencloud.com/demo-request";
export const DASHBOARD_URL = "https://screencloud.com/dashboards";
