import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  display: flex;
  height: 40px;

  .button-invisible {
    display: inline-block;
    height: 14px;
  }

  .more-preview {
    align-items: center;
    background-color: ${Theme.color.darkGrey};
    border: 2px solid ${Theme.color.white};
    ${Theme.default.borderRadius};
    color: ${Theme.color.white};
    display: flex;
    font-size: 13px;
    font-weight: bold;
    height: 40px;
    justify-content: center;
    padding-left: 12px;
    z-index: 0;
    width: 48px;
    margin-left: -16px;
  }

  .trigger-preview {
    cursor: pointer;
    width: 60px;
    margin-left: -40px;
    position: relative;
    &.preview-read-only {
      cursor: default;
    }

    :first-child {
      margin-left: 0;
    }

    img {
      ${Theme.default.borderRadius};
      background-color: ${Theme.color.silver};
      border: 2px solid var(--color-white-100);
      height: 40px;
      object-fit: cover;
      width: 60px;
      display: block;
    }

    :hover {
      bottom: 8px;
    }
  }
`;
export const PopoverStyled = styled.div`
  img {
    max-width: 160px;
    max-height: 90px;
    padding: 5px;
    position: relative;
    z-index: 2;
  }
`;
