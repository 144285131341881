import { FormattedMessage } from "react-intl";
import EmptyState from "../../../components/EmptyState";

export default function NoAppSearchResult(props: { isTemplate: boolean }) {
  const { isTemplate } = props;
  return (
    <EmptyState section="search-canvas">
      <h3>
        {isTemplate ? (
          <FormattedMessage
            id="common.search.no_canvas_template_results"
            defaultMessage="We couldn’t find any matching templates"
          />
        ) : (
          <FormattedMessage
            id="common.search.no_canvas_results"
            defaultMessage="We couldn’t find any matching canvases"
          />
        )}
      </h3>
      <p>
        <FormattedMessage
          id="common.search.no_results_description"
          defaultMessage="Try adjusting or using different search terms."
        />
      </p>
    </EmptyState>
  );
}
