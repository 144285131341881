import { useCallback } from "react";
import {
  CreateScreenNoteInput,
  ScreenDetailQuery,
  ScreenNoteFragmentDoc,
  useCreateScreenNoteMutation,
  useDeleteScreenNoteByIdMutation,
} from "src/types.g";

export const useModifiedCacheScreenNoteMutation = () => {
  const [createScreenNoteMutation] = useCreateScreenNoteMutation();
  const [deleteScreenNoteByIdMutation] = useDeleteScreenNoteByIdMutation();

  const deleteScreenNote = useCallback(
    async (screen: ScreenDetailQuery["screenById"], screenNoteId: string) => {
      return deleteScreenNoteByIdMutation({
        variables: {
          input: {
            screenNoteId,
          },
        },
        update: (cache, { data }) => {
          if (!screen) return;
          const cacheId = cache.identify(screen);
          cache.modify({
            id: cacheId,
            fields: {
              screenNotesByScreenId(
                existingScreenNotesByScreenIdRefs,
                { readField }
              ) {
                return existingScreenNotesByScreenIdRefs.nodes.filter(
                  (ref) =>
                    data?.deleteScreenNoteById?.screenNote?.id !==
                    readField("id", ref)
                );
              },
            },
          });
        },
      });
    },
    []
  );

  const createScreenNote = useCallback(
    async (screen: ScreenDetailQuery["screenById"], spaceId: string) => {
      const input: CreateScreenNoteInput = {
        screenId: screen?.id,
        note: "",
        spaceId,
      };

      return createScreenNoteMutation({
        variables: { input },
        update: (cache, { data }) => {
          if (!screen) return;
          const cacheId = cache.identify(screen);
          cache.modify({
            id: cacheId,
            fields: {
              screenNotesByScreenId(
                existingScreenNotesByScreenIdRefs,
                { readField }
              ) {
                const newScreeNote = data?.createScreenNote?.screenNote;
                const newScreenNoteRef = cache.writeFragment({
                  data: newScreeNote,
                  fragment: ScreenNoteFragmentDoc,
                });

                const currentRefNodes =
                  existingScreenNotesByScreenIdRefs?.nodes || [];
                // Quick safety check - if the new note is already
                // present in the cache, we don't need to add it again.
                if (
                  currentRefNodes.some(
                    (ref) => readField("id", ref) === newScreeNote?.id
                  )
                ) {
                  return existingScreenNotesByScreenIdRefs;
                } else {
                  return {
                    ...existingScreenNotesByScreenIdRefs,
                    nodes: currentRefNodes.concat(newScreenNoteRef),
                  };
                }
              },
            },
          });
        },
      });
    },
    []
  );

  return {
    deleteScreenNote,
    createScreenNote,
  };
};
