import { Loader } from "@screencloud/screencloud-ui-components";
import { useRef } from "react";
import { Maybe, useCurrentUserQuery, User } from "../../src/types.g";

export interface WithCurrentUserProps {
  // We might not have current user if we are on migration
  currentUser: Maybe<User>;
}
/**
 * This is HOC to provide currentUser prop
 */
export const withCurrentUser = (Component: React.ComponentType<any>) => (
  props: any
) => {
  // need to use useRef to avoid start time being reset
  const start = useRef(Date.now());
  const { data: currentUserQuery, loading } = useCurrentUserQuery({
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loader active style={{ top: "50%", left: "50%" }} />;
  }

  /**
   * Actually this is CurrentUserQuery["currentUser"]
   * but use exact type will complicate thing and not worth it
   * so we just cast it to User
   * */
  const currentUser = currentUserQuery?.currentUser as Maybe<User>;
  console.log(
    `withCurrentUser: useCurrentUserQuery() took`,
    Date.now() - start.current
  );

  const result: WithCurrentUserProps = {
    currentUser,
  };

  return <Component {...props} {...result} />;
};
