import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${Theme.default.borderRadius};
  overflow: hidden;

  .success-icon {
    font-size: 72px;
    width: 72px;
    height: 72px;
    background-color: ${Theme.color.black};
    margin: 24px auto;
  }

  .content,
  .incognito-warning {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 60px 60px 0 60px;
    background-color: ${Theme.color.nearWhite};
    text-align: center;
  }

  .incognito-warning {
    align-items: center;
    padding: 100px 100px 0 100px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: ${Theme.color.white};
    border-top: 1px solid ${Theme.color.borderSection};
  }

  .extension-name {
    font-weight: bold;
    margin-top: 50px;
  }

  .action-button {
    width: 200px;
  }

  .install-button {
    align-self: center;
    width: 200px;
    margin-top: 20px;
  }

  h2 {
    margin-bottom: 8px;
  }

  .type-selector {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
  }

  .browser-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
  }

  .browser-info {
    display: flex;
    flex-direction: column;

    .browser-logo {
      align-self: center;
      width: 45px;
      height: 45px;
    }

    .browser-download {
      width: 250px;
      margin: 20px;
      background-color: ${Theme.color.white};
    }
  }

  .reminder-check-box {
    margin-top: auto;
    margin-bottom: 20px;
  }

  .steps {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .step {
      display: flex;
      width: 50%;
      flex-direction: column;

      .step-image {
        align-self: center;
        width: 45px;
        height: 45px;
      }

      .step-title {
        font-weight: bold;
        padding: 20px 0 10px 0;
      }

      .step-text {
        width: 70%;
        align-self: center;

        a {
          text-decoration: underline;
          cursor: pointer;
          color: ${Theme.color.darkGrey};
        }
      }
    }
  }
`;

export interface StyledUsageInfoProps {
  hidden?: boolean;
}

export const StyledUsageInfo = styled.div<StyledUsageInfoProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 10px;
  padding: 10px;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};

  background-color: ${Theme.color.silver};
  border: 1px solid ${Theme.color.lightGrey};
  ${Theme.default.borderRadius};

  .info-icon {
    width: 16px;
    height: 16px;
    background-color: ${Theme.color.darkGrey};
    margin-right: 8px;
  }
`;

export interface StyledSiteCardProps {
  selected?: boolean;
}

export const StyledSiteCard = styled.div<StyledSiteCardProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  padding: 40px 40px 20px 40px;

  background-color: ${(props) =>
    props.selected ? Theme.color.base : Theme.color.white};
  ${Theme.default.borderRadius};
  border: 1px solid
    ${(props) => (props.selected ? Theme.color.base : Theme.color.lightGrey)};
  cursor: pointer;
  text-align: left;

  .card-icon {
    width: 50px;
    height: 50px;
    background-color: ${(props) =>
      props.selected ? Theme.color.white : Theme.color.darkGrey};
  }

  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
    font-weight: bold;
    color: ${(props) =>
      props.selected ? Theme.color.white : Theme.color.base};
  }

  .card-subheader {
    color: ${(props) =>
      props.selected ? Theme.color.lightGrey : Theme.color.darkGrey};
  }

  .selected-icon {
    width: 20px;
    height: 20px;
    background-color: ${(props) =>
      props.selected ? Theme.color.white : Theme.color.lightGrey};
    align-self: center;
    margin-top: auto;
  }

  .title-label {
    margin-left: 5px !important;
  }

  ul {
    color: ${Theme.color.midGrey};
  }
`;
