import {
  ChannelListContentItem,
  DEFAULT_GLOBAL_DURATIONS,
  RefType,
  ScheduleRules,
} from "../../constants/constants";
import {
  appInstanceIconUrl,
  getAppInstanceDuration,
} from "../../helpers/appHelper";
import {
  getMapFromChannel,
  getZoneContentBySelectedZone,
  IChannelMap,
} from "../../helpers/channelHelper";
import {
  generateImgixURL,
  isDocument,
  isVideo,
} from "../../helpers/mediaHelper";
import { getMapFromPlaylist, IPlayListMap } from "../../helpers/playlistHelper";
import { AppInstance, Channel, Playlist } from "../../types.g";
export interface ItemInScheduleMap {
  [listId: string]: boolean;
}
export interface PlayerPreviewItemViewModel {
  id: string;
  name: string;
  isPlaylist?: boolean;
  listId: string;
  playListListId?: string;
  rules?: ScheduleRules[];
}

export interface ContentItemViewModel extends PlayerPreviewItemViewModel {
  image: string;
  icon: string;
  isInSchedule: boolean;
  duration: number;
}

export interface DefaultDurations {
  app: number;
  link: number;
  site: number;
  image: number;
}

export interface PlayListContentItemViewModel
  extends PlayerPreviewItemViewModel {
  color: string;
  duration: number;
  contents: ContentItemViewModel[];
}

const getIconNameByRefType = (refType: string): string =>
  refType === RefType.FILE ? "image" : refType;

export function PlayerPreviewItemViewModelMapper(
  defaultDurations: DefaultDurations,
  map: any,
  itemInScheduleMap: ItemInScheduleMap,
  item: ChannelListContentItem,
  secureMediaPolicy: string | undefined,
  playListListId?: string
): ContentItemViewModel | PlayListContentItemViewModel | null {
  const listId: string = item.list_id;
  const id = item?.content?._ref?.id;
  const generateImageUrl = (file) =>
    file ? generateImgixURL(file, 40, 40, secureMediaPolicy) : null;
  const { fileMap, playlistMap, linkMap, siteMap, appMap } = map;
  const isInSchedule = itemInScheduleMap[listId];

  const listRefType =
    item.content._ref!.type === RefType.FILE
      ? "image"
      : item.content._ref!.type;
  const defaultDurationOfType =
    defaultDurations && defaultDurations[listRefType]
      ? defaultDurations[listRefType]
      : item.content._ref!.type === RefType.PLAYLIST
      ? 0
      : DEFAULT_GLOBAL_DURATIONS[listRefType];

  const duration = item.content.props?.duration || defaultDurationOfType;
  if (id) {
    const type = item?.content?._ref?.type;

    switch (type) {
      case RefType.FILE:
        const file = fileMap[id];
        if (file) {
          const { name } = file;
          let fileDuration = duration;

          if (isVideo(file) && file.fileOutputsByFileId) {
            const videoFileOutput = file.fileOutputsByFileId.nodes.find(
              (fileOutput) => isVideo(fileOutput)
            );
            fileDuration = videoFileOutput
              ? videoFileOutput.metadata.duration
              : duration;
          } else if (
            isDocument(file) &&
            file.fileOutputsByFileId?.nodes[0]?.content?.keys?.length
          ) {
            fileDuration =
              fileDuration *
              file.fileOutputsByFileId.nodes[0].content.keys.length;
          }
          return {
            icon: getIconNameByRefType(type),
            id,
            image: generateImageUrl(file),
            isInSchedule,
            duration: fileDuration,
            listId,
            name,
            playListListId,
            rules: item.rules ?? [],
          } as ContentItemViewModel;
        }
        break;
      case RefType.PLAYLIST:
        const playlist = playlistMap[id];
        if (playlist) {
          // Each playlist has its own default_durations
          const {
            name,
            color,
            content: {
              list,
              props: { default_durations },
            },
          } = playlist;
          return {
            color,
            contents:
              list?.map((contenItem: ChannelListContentItem) =>
                PlayerPreviewItemViewModelMapper(
                  default_durations,
                  map,
                  itemInScheduleMap,
                  contenItem,
                  secureMediaPolicy,
                  listId
                )
              ) ?? [],
            id,
            isPlaylist: true,
            listId,
            duration: playlist.content.props.total_durations || duration,
            name,
            rules: item.rules ?? [],
          } as PlayListContentItemViewModel;
        }
        break;
      case RefType.LINK:
        const link = linkMap[id];
        if (link) {
          const { name } = link;
          return {
            icon: getIconNameByRefType(type),
            id,
            image: generateImageUrl(link.fileByFileId),
            isInSchedule,
            listId,
            duration,
            name,
            playListListId,
            rules: item.rules ?? [],
          } as ContentItemViewModel;
        }
        break;
      case RefType.SITE:
        const site = siteMap[id];
        if (site) {
          const { name } = site;
          return {
            icon: getIconNameByRefType(type),
            id,
            image: generateImageUrl(site.fileByThumbnailId),
            isInSchedule,
            listId,
            duration,
            name,
            playListListId,
            rules: item.rules ?? [],
          } as ContentItemViewModel;
        }
        break;
      case RefType.APP:
        const appByRefId = appMap[id];
        if (appByRefId) {
          const { name } = appByRefId;
          const iconUrl = appInstanceIconUrl(
            (appByRefId as unknown) as AppInstance
          );
          return {
            icon: getIconNameByRefType(type),
            id,
            image: iconUrl,
            isInSchedule,
            duration: getAppInstanceDuration(appByRefId) ?? duration,
            listId,
            name,
            playListListId,
            rules: item.rules ?? [],
          } as ContentItemViewModel;
        }
        break;
    }
  }

  return null;
}

const hasRef = (item: ChannelListContentItem) =>
  item &&
  item.content &&
  item.content._ref &&
  item.content._ref.id &&
  item.content._ref.type;

const getContentItemViewModels = (
  content: ChannelListContentItem[],
  map: IChannelMap | IPlayListMap,
  itemInScheduleMap: ItemInScheduleMap,
  defaultDurations: DefaultDurations,
  secureMediaPolicy: string | undefined
) => {
  return content
    .filter(hasRef)
    .map((item) =>
      PlayerPreviewItemViewModelMapper(
        defaultDurations,
        map,
        itemInScheduleMap,
        item,
        secureMediaPolicy
      )
    )
    .filter((item) => !!item) as PlayerPreviewItemViewModel[];
};

export const getContenItemViewModelsByChannel = (
  channel: Channel,
  selectedZone: string,
  itemInScheduleMap: ItemInScheduleMap,
  secureMediaPolicy: string | undefined
): PlayerPreviewItemViewModel[] => {
  const zoneContent = getZoneContentBySelectedZone(selectedZone, channel);
  if (!(zoneContent && zoneContent.list)) {
    return [];
  }
  const channelMap = getMapFromChannel(channel);
  const defaultDurations =
    channel.content?.props?.default_durations || DEFAULT_GLOBAL_DURATIONS;
  return getContentItemViewModels(
    zoneContent.list,
    channelMap,
    itemInScheduleMap,
    defaultDurations,
    secureMediaPolicy
  );
};

export const getContenItemViewModelsByPlaylist = (
  playlist: Playlist,
  itemInScheduleMap: ItemInScheduleMap,
  secureMediaPolicy: string | undefined
): PlayerPreviewItemViewModel[] => {
  if (!(playlist && playlist.content && playlist.content.list)) {
    return [];
  }
  const defaultDurations =
    playlist.content?.props?.default_durations || DEFAULT_GLOBAL_DURATIONS;
  const playlistMap = getMapFromPlaylist(playlist);
  return getContentItemViewModels(
    playlist.content.list,
    playlistMap,
    itemInScheduleMap,
    defaultDurations,
    secureMediaPolicy
  );
};
