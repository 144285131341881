import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  height: 100%;
  background: ${Theme.color.nearWhite};

  .app-config-header {
    width: 100%;
    left: 0;
    top: 0;
    height: 90px;

    .wrapper.detail {
      padding: 0 20px;
      .column-title {
        display: inline-flex;
        flex-direction: row;
        max-width: 100%;
        height: 100%;
        align-items: center;

        .app-title {
          text-align: center;
          height: 100%;
          display: inline-flex;
          align-items: center;
          white-space: nowrap;
          padding: 0;
          margin-left: 0;
          img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 16px;
          }

          &.themes {
            background-color: ${Theme.color.blueHover};
            justify-content: center;
            width: 55px;
            min-width: 55px;
            height: 55px;
            border-radius: ${Theme.default.borderRadiusValue};
            img {
              width: 32px;
              height: 32px;
            }
          }

          &.layout {
            display: none;
          }
        }

        .app-instance-title {
          width: auto;
          flex-grow: 1;
          display: flex;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .title {
            width: 100%;
            font-size: 1.25em;
            font-weight: bold;
            .inline-input {
              height: 36px;
              width: 100%;
              font-size: 1em;
              .input {
                width: 100%;
              }
              .truncate {
                height: 100%;
                line-height: 36px;
                max-width: calc(100% - 20px);
              }
            }
          }

          .tag-input {
            width: 100%;
          }
        }
      }

      .column-options {
        width: auto;
        max-width: 480px;
        order: 2;
        padding: 0 16px;
      }

      .column-availbility {
        margin-right: 5px;
      }

      .column-tag {
        display: flex;
        justify-content: flex-end;
        margin-right: 16px;
      }

      .column-back {
        border-left: 1px solid ${Theme.color.lightGrey};
        padding: 0 0 0 16px;
        order: 3;
        button {
          height: 45px;
          width: 45px;
          min-width: auto;
        }
      }
    }
  }

  .panel-wrapper {
    background: ${Theme.color.nearWhite};
    display: flex;
    height: calc(100% - 90px);

    .panel-config {
      background: ${Theme.color.white};
      border-right: 1px solid ${Theme.color.lightGrey};
      padding: 40px;
      width: 640px;
      overflow: auto;
      transition: all 0s;
      height: auto;
    }

    .panel-preview {
      padding: 60px 120px;
      width: calc(100% - 640px);
      height: 100%;
      display: flex;
      overflow: auto;

      > .screen-preview-wrapper {
        align-items: center;
        display: flex;
        width: 100%;

        > div {
          > div {
            height: 100%;
            position: absolute;
            width: 100%;
          }
        }
      }
    }
  }

  &.preview-enabled {
    .panel-wrapper {
      .panel-config {
        width: 0px;
        min-width: 0px;
        padding: 0px;
        margin: 0px;
        border: none;
      }

      .panel-preview {
        width: 100%;
        padding: 60px 240px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeXHD}) {
    .panel-wrapper {
      .panel-preview {
        padding: 60px;
      }
    }

    &.preview-enabled {
      .panel-wrapper {
        .panel-preview {
          padding: 60px;
          align-items: flex-start;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.large}) {
    .panel-wrapper {
      .panel-preview {
        min-height: auto;
        padding: 60px 40px;
      }
    }

    &.preview-enabled {
      .panel-wrapper {
        .panel-preview {
          padding: 60px 40px;
          align-items: center;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .panel-wrapper {
      .panel-preview {
        padding: 0 40px;
        width: calc(100% - 400px);
      }

      .panel-config {
        padding: 40px 20px;
        width: 400px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .app-config-header {
      .wrapper.detail {
        .column-options {
          bottom: 0;
          background: ${Theme.color.white};
          border-top: 1px solid ${Theme.color.borderSection};
          left: 0;
          justify-content: flex-end;
          max-width: 100%;
          padding: 10px 20px;
          position: fixed;
          width: 100%;

          .button {
            max-width: 100%;
            padding: 0 20px;
            width: auto;
          }
        }
      }
    }

    .panel-wrapper {
      flex-direction: column;
      height: calc(100% - 136px);
      top: 0;
      padding-top: 0;

      .panel-config {
        width: 100%;
        max-width: 100%;
        order: 2;
        border-right: none;
        border-top: 1px solid ${Theme.color.borderSection};
        margin-bottom: 65px;
        padding: 40px 20px;
        margin: 0;
        height: calc(100% - 480px);
      }

      .panel-preview {
        width: 100%;
        order: 1;
        height: 480px;
        padding: 0 120px;
        flex: 0 0 auto;
      }
    }

    &.preview-enabled {
      .panel-wrapper {
        .panel-preview {
          height: 100%;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .app-config-header {
      .wrapper.detail {
        .column-title {
          .app-title {
            &.themes {
              width: 66px;
              min-width: 66px;
            }
          }

          .app-instance-title {
            width: calc(100% - 132px);
          }
        }

        .column-back {
          order: 3;
        }
      }
    }

    .panel-wrapper {
      height: calc(100% - 90px);
      overflow: auto;

      &.no-footer {
        height: calc(100% - 66px);
      }

      .panel-config {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
        margin: 0;
        height: calc(100% - 380px);
      }

      .panel-preview {
        flex: 0 0 auto;
        height: 380px;
        padding: 20px 60px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .app-config-header {
      .wrapper.detail {
        .column-title {
          .app-title {
            &.app {
              display: none;
            }
          }
        }
        .column-options {
          justify-content: flex-end;
          padding: 10px 15px;

          .button {
            width: 100%;
          }
        }
      }
    }

    .panel-wrapper {
      .panel-config {
        padding: 20px 15px;
        height: 100%;
        padding-bottom: 15%;
      }

      .panel-preview {
        height: 280px;
        padding: 0 15px;
      }
    }
  }

  @media screen and (min-width: ${Theme.break.largeHD}) and (max-height: ${Theme
      .height.medium}) {
    .panel-wrapper {
      .panel-preview {
        align-items: flex-start;
      }
    }
  }
`;
