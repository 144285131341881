import { useState, useEffect } from "react";
import { UUID } from "@screencloud/uuid";
import {
  ChannelListItemFragment,
  SearchChannelPickerQueryVariables,
  useSearchChannelPickerQuery,
} from "src/types.g";
import { shouldUseSpecificSpaceId } from "src/utils/shouldUseSpecificSpaceId";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useLoadMore } from "src/hooks/useLoadMore";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";

export interface UseSearchChannelPicker {
  channels: ChannelListItemFragment[];
  isFirstTimeAlreadyLoaded: boolean;
  loading: boolean;
  renderFetchMoreButton: React.ReactNode;
}

export function useSearchChannelPicker(query: string, spaceId?: UUID) {
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] = useState(
    false
  );

  const variables: SearchChannelPickerQueryVariables = {
    query,
    spaceId: shouldUseSpecificSpaceId(spaceId),
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
  };

  const { data, fetchMore, loading } = useSearchChannelPickerQuery({
    variables,
    fetchPolicy: "cache-and-network",
  });

  const channelNodes = data?.searchChannel?.nodes ?? [];
  const channelTotalCount = data?.searchChannel?.totalCount;
  const isQueryHasNextPage = data?.searchChannel?.pageInfo.hasNextPage;
  const endCursor = data?.searchChannel?.pageInfo.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(Boolean(data?.searchChannel));
  }, [data?.searchChannel]);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(channelNodes?.length),
    Number(channelTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) && channelNodes.length! < channelTotalCount!,
    loadMore
  );

  return {
    channels: data?.searchChannel?.nodes ?? [],
    loading,
    isFirstTimeAlreadyLoaded,
    renderFetchMoreButton: fetchMoreButton,
  };
}
