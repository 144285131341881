import { cloneDeep, uniqBy } from "lodash";
import { removeAppByAppId } from "src/domains/app/removeAppByAppId";
import { useAppContext } from "src/hooks/useAppContext";
import {
  useAllAppInstallsInOrgQuery,
  AllAppInstallsInOrgQueryVariables,
  AppInstall,
} from "src/types.g";

export function useAllAppInstallsInCurrentOrg({ skip }: { skip: boolean }) {
  const context = useAppContext();
  const variables: AllAppInstallsInOrgQueryVariables = {
    orgId: context?.currentOrg?.id,
  };

  const { data, loading } = useAllAppInstallsInOrgQuery({
    fetchPolicy: "cache-and-network",
    variables,
    skip,
  });

  const uniqueApps = uniqBy(
    data?.orgById?.appInstallsByOrgId.nodes ?? [],
    (app) => {
      return app.appByAppId?.id;
    }
  );
  const filteredApps = removeAppByAppId(uniqueApps, context.canvasAppId);
  const sortedApps = cloneDeep(filteredApps).sort((a, b) =>
    (a?.appByAppId?.name ?? "").localeCompare(
      b?.appByAppId?.name ?? "",
      undefined,
      { sensitivity: "base" }
    )
  );

  return { appInstalls: sortedApps as AppInstall[], loading };
}
