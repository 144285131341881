import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Icon,
  InlineInput,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import {
  ConnectDropTarget,
  DragLayer,
  DragLayerMonitor,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from "react-dnd";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { MediaDropResult } from "../../constants/mediaTypes";
import { canBeUpdated } from "../../helpers/shareableHelper";
import { RenameFolderParams } from "../../pages/Media/hooks/useRenameFolder";
import { Folder } from "../../types.g";
import { compose } from "../../utils/compose";
import MediaBreadCrumbDropdown from "./mediaBreadCrumbDropdown";
import MediaBreadCrumbDropdownItem from "./mediaBreadCrumbDropdownItem";
import MediaBreadCrumbItem from "./mediaBreadCrumbItem";
import { StyledEditNameIcon, StyledFolderButton } from "./styles";

export interface FolderPathItem {
  id: string;
  name: string;
  spaceId?: string | null;
}

export interface MediaBreadCrumbState {
  isRenameState: boolean;
}

export interface MediaBreadCrumbProps {
  libraryName: string | React.ReactNode;
  action: boolean;
  connectDropTarget?: ConnectDropTarget;
  folderPathItems: FolderPathItem[];
  maxFolderPath?: number;
  isDragging?: boolean;
  isOver?: boolean;
  canCreateFolder?: boolean;
  onUpdate?: (folderId: string) => void;
  onDropMediaItems?: (dropResult: MediaDropResult) => void;
  onClickAddNewFolder?: () => void;
  /**
   * in case that canAction is true, we use url here
   */
  getFolderUrl?: (folderId: string) => string;
  renamable?: boolean;
  onRename?: (params: RenameFolderParams) => Promise<any>;
}

const dropTarget = {
  drop(props: MediaBreadCrumbProps, monitor: DropTargetMonitor) {},
};

const dragCollect = (monitor: DragLayerMonitor) => ({
  isDragging: monitor.isDragging(),
});

const dropCollect = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor
) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});
class MediaBreadCrumb extends React.PureComponent<
  MediaBreadCrumbProps,
  MediaBreadCrumbState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  public static defaultProps: Partial<MediaBreadCrumbProps> = {
    maxFolderPath: 2,
  };

  constructor(props: MediaBreadCrumbProps) {
    super(props);
    this.state = {
      isRenameState: false,
    };
  }

  public onClick = (folderId: string) => {
    if (this.props.onUpdate) {
      this.props.onUpdate(folderId);
    }
    if (this.props.action === true) {
      if (this.props.getFolderUrl) {
        this.context.history.replace(`${this.props.getFolderUrl(folderId)}`);
      }
    }
  };

  public onNameChange = (_event, newName, currentFolderId, parentFolderId) => {
    const folderContent = {
      id: currentFolderId,
      parentId: parentFolderId,
    } as Folder;
    if (this.props.onRename) {
      this.props.onRename({ folder: folderContent, updatedName: newName });
    }
    this.setState({ isRenameState: false });
  };

  public handleRename = (event: React.SyntheticEvent) => {
    const keyboardEvent = event as React.KeyboardEvent<any>;
    if (!keyboardEvent.shiftKey && !keyboardEvent.metaKey) {
      this.setState({ isRenameState: true });
    }
  };

  public render() {
    const {
      getFolderUrl,
      folderPathItems,
      libraryName,
      renamable,
    } = this.props;
    const urlListFolder: any[] = [];
    const breadcrumbComponent: any[] = [];
    const maxFolderPath = this.props.maxFolderPath as number;
    let urlListBreadcrumb: JSX.Element | null = null;

    if (folderPathItems) {
      folderPathItems.forEach((folder, idx) => {
        const isActive = folderPathItems.length === idx + 1;
        const parentFolder = this.props.folderPathItems[
          this.props.folderPathItems.length - 2
        ];
        const parentFolderId =
          this.props.folderPathItems.length === 1
            ? this.context.currentSpace?.rootFolderId
            : parentFolder.id;
        const canUpdateMedia = canBeUpdated({
          context: this.context,
          shareable: { __typename: "Folder", spaceId: folder.spaceId! },
        });
        if (
          idx < folderPathItems.length - maxFolderPath &&
          folderPathItems.length > maxFolderPath
        ) {
          urlListFolder.push(
            <MediaBreadCrumbDropdownItem
              key={folder.id}
              index={idx}
              folderId={folder.id}
              folderName={folder.name}
              url={(getFolderUrl && getFolderUrl(folder.id)) ?? ""}
              action={this.props.action}
              onClick={this.onClick}
              onDrop={this.props.onDropMediaItems}
            />
          );
        } else {
          breadcrumbComponent.push(
            <React.Fragment key={`breadcrumb-fragment-${idx}`}>
              <BreadcrumbDivider key={`breadcrumb-divider-${idx}`} />
              <BreadcrumbSection
                key={`breadcrumb-section-${idx}`}
                active={isActive}
                title={folder.name}
              >
                {isActive !== true ? (
                  <MediaBreadCrumbItem
                    folderId={folder.id}
                    onDrop={this.props.onDropMediaItems}
                    isDragging={this.props.isDragging}
                  >
                    <StyledFolderButton
                      transparent
                      onClick={() => this.onClick(folder.id)}
                    >
                      {folder.name}
                    </StyledFolderButton>
                  </MediaBreadCrumbItem>
                ) : renamable && folder.spaceId ? (
                  <>
                    {this.state.isRenameState === true ? (
                      <InlineInput
                        data-testid="input-group-name"
                        value={folder.name}
                        onSaved={(e, value) =>
                          this.onNameChange(e, value, folder.id, parentFolderId)
                        }
                        placeholder={folder.name}
                        editmode
                        disabled={!canUpdateMedia}
                      />
                    ) : (
                      <StyledEditNameIcon>
                        <div>{folder.name}</div>
                        {canUpdateMedia && (
                          <Icon
                            name="edit"
                            onClick={this.handleRename}
                            title="Rename"
                          />
                        )}
                      </StyledEditNameIcon>
                    )}
                  </>
                ) : (
                  folder.name
                )}
              </BreadcrumbSection>
            </React.Fragment>
          );
        }
      });
      urlListBreadcrumb =
        urlListFolder.length > 0 ? (
          <MediaBreadCrumbDropdown
            icon={<Icon name="folder" color="#96D0FC" />}
          >
            <MediaBreadCrumbDropdownItem
              index={"libray"}
              folderId={""}
              folderName={libraryName}
              url={"/media"}
              action={this.props.action}
              onClick={this.onClick}
              onDrop={this.props.onDropMediaItems}
            />
            {urlListFolder}
          </MediaBreadCrumbDropdown>
        ) : null;
    }

    return (
      this.props.connectDropTarget &&
      this.props.connectDropTarget(
        <span>
          <Breadcrumb
            className={this.props.isDragging ? "dnd-dragging-active" : ""}
          >
            {urlListBreadcrumb}
            {urlListBreadcrumb === null && (
              <BreadcrumbSection
                className={this.props.isOver ? "dnd-dragging-over" : ""}
                active={breadcrumbComponent.length === 0 ? true : false}
              >
                {breadcrumbComponent.length > 0 ? (
                  <MediaBreadCrumbItem
                    folderId={""}
                    onDrop={this.props.onDropMediaItems}
                  >
                    <Button
                      transparent
                      onClick={() => {
                        this.onClick("");
                        if (this.props.action === true) {
                          this.context.history.replace("/media");
                        }
                      }}
                    >
                      {libraryName}
                    </Button>
                  </MediaBreadCrumbItem>
                ) : (
                  this.props.libraryName
                )}
              </BreadcrumbSection>
            )}
            {breadcrumbComponent}
            {this.props.canCreateFolder && this.props.maxFolderPath === 1 && (
              <Button mini icon onClick={this.props.onClickAddNewFolder}>
                <Icon name="add-folder" />
              </Button>
            )}
          </Breadcrumb>
        </span>
      )
    );
  }
}

export default (compose(
  DropTarget("MEDIA", dropTarget, dropCollect),
  DragLayer<MediaBreadCrumbProps>(dragCollect)
)(
  MediaBreadCrumb
) as React.ComponentType<MediaBreadCrumbProps>) as React.ComponentType<MediaBreadCrumbProps>;
