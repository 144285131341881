import { RefType } from "src/constants/constants";
import { CastByCastIdFragment, Maybe } from "src/types.g";

export const getCastContentItem = (
  castItem: Maybe<CastByCastIdFragment>
): { castItemType: string; castItemName: string } => {
  let castItemType: string = "";
  let castItemName: string = "";

  if (castItem && castItem.content._ref.type === RefType.PLAYLIST) {
    castItemType = "playlist";
    if (
      castItem.playlistByCastId &&
      castItem.playlistByCastId.__typename === "Playlist"
    ) {
      castItemName = castItem.playlistByCastId.name;
    } else {
      castItemName = "Playlist";
    }
  } else if (castItem && castItem.content._ref.type === RefType.LINK) {
    castItemType = "link";
    if (castItem.linkByCastId && castItem.linkByCastId.__typename === "Link") {
      castItemName = castItem.linkByCastId.name;
    } else {
      castItemName = "Link";
    }
  } else if (
    castItem &&
    castItem.siteByCastId &&
    castItem.siteByCastId.__typename === "Site"
  ) {
    castItemType = "dashboard";
    castItemName = castItem.siteByCastId.name;
  } else if (castItem && castItem.content._ref.type === RefType.FILE) {
    if (
      castItem.fileByCastId &&
      castItem.fileByCastId.mimetype &&
      castItem.fileByCastId.__typename === "File"
    ) {
      const fileType = castItem.fileByCastId.mimetype.split("/")[0];
      castItemName = castItem.fileByCastId.name;
      switch (fileType) {
        case "image":
          castItemType = "image";
          break;
        case "video":
          castItemType = "video";
          break;
        case "audio":
          castItemType = "audio";
          break;
        case "application":
          castItemType = "document";
          break;
      }
    } else {
      castItemType = "document";
      castItemName = "File";
    }
  } else if (castItem && castItem.content._ref.type === RefType.APP) {
    castItemType = RefType.APP;
    if (
      castItem.appInstanceByCastId &&
      castItem.appInstanceByCastId.__typename === "AppInstance"
    ) {
      castItemName = castItem.appInstanceByCastId.name!;
    } else {
      castItemName = "App";
    }
  } else if (castItem && castItem.content._ref.type === RefType.CHANNEL) {
    castItemType = RefType.CHANNEL;
    if (
      castItem.channelByCastId &&
      castItem.channelByCastId.__typename === "Channel"
    ) {
      castItemName = castItem.channelByCastId.name!;
    } else {
      castItemName = "Channel";
    }
  }

  return { castItemType, castItemName };
};
