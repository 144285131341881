import { Button, Icon, Popover } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import classNames from "clsx";
import { AvailabilityStatus } from "../AvailabilityModal";
import DateTime from "../DateTime";
import { getAvailabilityStatus } from "../../helpers/scheduleableHelper";
import { FormattedMessage } from "react-intl";

interface AvailabilityStatusIconProps {
  availableAt?: string;
  expireAt?: string;
  callback?: () => void;
  ["data-testid"]?: string;
}

export const StyledAvailabilityIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--radius-md);

  .button {
    gap: 2px;
  }

  &.is-not-available i {
    background-color: var(--color-orange-500);
  }

  &.is-playing i {
    background-color: var(--color-green-500);
  }

  &.is-expired i {
    background-color: var(--color-red-500);
    width: 16px;
  }

  i {
    width: 20px;
    height: 20px;
  }

  i[name="warning"] {
    width: 12px;
    height: 12px;
    right: 0;
  }

  :hover {
    background: var(--color-gray-100);
  }
`;

export const getAvailabilityTimeMessage = ({
  availableAt,
  expireAt,
}: {
  availableAt?: string;
  expireAt?: string;
}) => {
  const availableStatus = availableAt
    ? getAvailabilityStatus({
        expireAt: expireAt ?? "",
        availableAt,
      })
    : undefined;
  const isExpired = availableStatus === AvailabilityStatus.IS_EXPIRED;

  return (
    <>
      <span data-testid="available-on" className="available-date">
        Available from&nbsp;
        <DateTime
          value={availableAt ?? ""}
          locale="en-GB"
          ignoreTimeZone={true}
        />
      </span>
      {expireAt ? (
        <span data-testid="expires-on">
          Expires on&nbsp;
          <DateTime
            value={expireAt ?? ""}
            locale="en-GB"
            ignoreTimeZone={true}
          />
        </span>
      ) : (
        <span className="italic">No expiry set</span>
      )}
      {isExpired && (
        <FormattedMessage
          id="ui_component.common.label.content_wont_display"
          defaultMessage="Content won't be displayed on your screen."
        />
      )}
    </>
  );
};

const AvailabilityStatusIcon = (props: AvailabilityStatusIconProps) => {
  const { availableAt, expireAt } = props;
  const availableStatus = availableAt
    ? getAvailabilityStatus({
        expireAt: expireAt ?? "",
        availableAt,
      })
    : undefined;
  const isExpired = availableStatus === AvailabilityStatus.IS_EXPIRED;
  return (
    <Popover
      className={classNames("schedule-popup wide", {
        "warning-inverted": isExpired,
      })}
      inverted
      wide
      content={getAvailabilityTimeMessage({ expireAt, availableAt })}
      position="top center"
      trigger={
        <StyledAvailabilityIcon
          className={"app-availability " + availableStatus}
          data-testid={props["data-testid"]}
          onClick={props.callback && props.callback}
        >
          <Button icon borderless mini>
            <Icon data-testid="calendar-icon" name="calendar" />
            {isExpired && <Icon data-testid="warning-icon" name="warning" />}
          </Button>
        </StyledAvailabilityIcon>
      }
    />
  );
};

export default AvailabilityStatusIcon;
