import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  useDeletePlaylist,
  UseDeletePlaylist,
} from "src/hooks/useDeletePlaylist";
import {
  AllTagsQuery,
  DuplicatePlaylistMutationFn,
  PlaylistByIdForListCachingQuery,
  PlaylistByIdQueryVariables,
  PublishDraftPlaylistMutationFn,
  RevertDraftPlaylistMutationFn,
  SetScreenContentByPlaylistIdMutationFn,
  UpdatePlaylistColorMutationFn,
  UpdatePlaylistEmbedMutationFn,
  UpdatePlaylistMutationFn,
  UpdatePlaylistTagsMutationFn,
  UpdateSharePlaylistToAllSpacesMutationFn,
  UpdateSharePlaylistToSpacesMutationFn,
  useAllTagsQuery,
  useDuplicatePlaylistMutation,
  usePlaylistByIdForListCachingQuery,
  usePlaylistByIdQuery,
  usePublishDraftPlaylistMutation,
  useRevertDraftPlaylistMutation,
  useSetScreenContentByPlaylistIdMutation,
  useUpdatePlaylistColorMutation,
  useUpdatePlaylistEmbedMutation,
  useUpdatePlaylistMutation,
  useUpdatePlaylistTagsMutation,
  useUpdateSharePlaylistToAllSpacesMutation,
  useUpdateSharePlaylistToSpacesMutation,
} from "../../../types.g";
import { compose } from "../../../utils/compose";

export interface PlaylistQueryInputProps {}

export interface ApolloProps
  extends RouteComponentProps<any>,
    WithApolloClient<{}>,
    UseDeletePlaylist {
  allTags: AllTagsQuery["allTags"];
  playlistByIdForListCaching: PlaylistByIdForListCachingQuery["playlistById"];
  duplicatePlaylist: DuplicatePlaylistMutationFn;
  updatePlaylist: UpdatePlaylistMutationFn;
  updatePlaylistTags: UpdatePlaylistTagsMutationFn;
  updatePlaylistColor: UpdatePlaylistColorMutationFn;
  updateSharePlaylistToAllSpaces: UpdateSharePlaylistToAllSpacesMutationFn;
  updateSharePlaylistToSpaces: UpdateSharePlaylistToSpacesMutationFn;
  updatePlaylistEmbed: UpdatePlaylistEmbedMutationFn;
  publishDraftPlaylist: PublishDraftPlaylistMutationFn;
  revertDraftPlaylist: RevertDraftPlaylistMutationFn;
  setContentByPlaylistId: SetScreenContentByPlaylistIdMutationFn;
}

export const withData = compose(
  withRouter,
  withApollo,
  (Component) => (props: ApolloProps) => {
    const queryVar: PlaylistByIdQueryVariables = {
      id: props.match.params.playlistId,
    };
    const { data } = usePlaylistByIdQuery({
      variables: queryVar,
      fetchPolicy: "cache-and-network",
      skip: !props?.match?.params?.playlistId,
    });

    const result = usePlaylistByIdForListCachingQuery({
      variables: queryVar,
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-only",
      skip: !props?.match?.params?.playlistId,
    });

    return (
      <Component
        {...props}
        playlistById={data?.playlistById}
        playlistByIdForListCaching={result.data?.playlistById}
      />
    );
  },
  (Component) => (props: ApolloProps) => {
    const { data } = useAllTagsQuery();
    return <Component {...props} allTags={data?.allTags} />;
  },
  (Component) => (props: ApolloProps) => {
    const { deletePlaylist } = useDeletePlaylist();
    const [duplicatePlaylist] = useDuplicatePlaylistMutation();
    const [publishDraftPlaylist] = usePublishDraftPlaylistMutation();
    const [revertDraftPlaylist] = useRevertDraftPlaylistMutation();
    const [updatePlaylist] = useUpdatePlaylistMutation();
    const [updatePlaylistColor] = useUpdatePlaylistColorMutation();
    const [updatePlaylistEmbed] = useUpdatePlaylistEmbedMutation();
    const [updatePlaylistTags] = useUpdatePlaylistTagsMutation();
    const [
      updateSharePlaylistToAllSpaces,
    ] = useUpdateSharePlaylistToAllSpacesMutation();
    const [
      updateSharePlaylistToSpaces,
    ] = useUpdateSharePlaylistToSpacesMutation();
    const [setContentByPlaylistId] = useSetScreenContentByPlaylistIdMutation();
    return (
      <Component
        {...props}
        deletePlaylist={deletePlaylist}
        duplicatePlaylist={duplicatePlaylist}
        publishDraftPlaylist={publishDraftPlaylist}
        revertDraftPlaylist={revertDraftPlaylist}
        updatePlaylist={updatePlaylist}
        updatePlaylistColor={updatePlaylistColor}
        updatePlaylistEmbed={updatePlaylistEmbed}
        updatePlaylistTags={updatePlaylistTags}
        updateSharePlaylistToAllSpaces={updateSharePlaylistToAllSpaces}
        updateSharePlaylistToSpaces={updateSharePlaylistToSpaces}
        setContentByPlaylistId={setContentByPlaylistId}
      />
    );
  }
);
