export interface SignagePathParts {
  prefix: string;
  key: string;
  page: string;
  params: string;
  path: string;
}

export function parsePathname(
  pathname: string = window.location.pathname
): SignagePathParts {
  // extract from (/org/(5))(/(screens)/add)(?foo=bar)
  const [, prefix = "", key = "0", path = "/", page = "/", params = ""] =
    pathname.match(/^(\/org\/([0-9]+))?((.*?)(\?.*)?)?$/) || [];
  return {
    key,
    page,
    params,
    path,
    prefix,
  };
}
