import { ScheduleRules } from "src/constants/constants";
import { shouldDisableItem } from "src/helpers/channelHelper";

export const isChannelItemExpired = (
  isExpired: boolean,
  schedule: ScheduleRules[],
  contentData
) => {
  return (
    isExpired ||
    (contentData?.isExpired ?? false) ||
    shouldDisableItem(schedule)
  );
};
