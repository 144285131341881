import styled from "styled-components";

export const Container = styled.div<{ isCorePlan: boolean }>`
  display: flex;
  height: 100%;
  padding: var(--spacing-xxl, 40px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xl, 24px);
  border-radius: 10px;
  background: ${(props) =>
      `linear-gradient(0deg, ${
        props.isCorePlan ? "#fef0c0" : "#c2cbf9"
      } 0%, #fff 48.96%)`},
    var(--color-gray-50, #f6f6f7);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  .divider {
    height: 1px;
    align-self: stretch;
    border-radius: var(--radius-sm, 4px);
    background: var(--color-gray-100, #e2e3e5);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    color: var(--color-gray-950, #19191b);
    text-align: center;
    font-size: var(--font-size-xl, 24px);
    font-style: normal;
    font-weight: 900;
    line-height: var(--font-line-height-xl, 36px);
  }

  .description {
    color: var(--color-foreground, #404145);
    text-align: center;
    font-size: var(--font-size-md, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--font-line-height-md, 24px);
  }
`;

export const CoreContainer = styled.div`
  display: flex;
  padding: var(--spacing-xxl, 40px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-lg, 16px);
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--color-gray-100, #e2e3e5);
  background: var(--color-white-100, #fff);
  box-shadow: 0px 1px 2px 0px var(--color-black-5, rgba(0, 0, 0, 0.05));
  width: 335px;
`;

export const PlanTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: var(--spacing-md, 12px);
  .icon {
    margin-top: var(--spacing-sm, 8px);
  }
  .plan {
    color: var(--color-gray-950, #19191b);
    font-family: Sora;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const PlanDescription = styled.div`
  color: var(--color-foreground, #404145);
  font-family: var(--font-family-studio, Lato);
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-sm, 20px);
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-sm, 8px);
  align-self: stretch;
  .from {
    color: var(--color-gray-950, #19191b);
    font-size: var(--font-size-md, 16px);
    font-style: normal;
    font-weight: 700;
    line-height: var(--font-line-height-md, 24px);
  }
  .price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-sm, 8px);
    align-self: stretch;
    .screen-cost {
      color: var(--color-gray-950, #19191b);
      font-family: Sora;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 55px;
    }
    .per-screen {
      color: var(--color-gray-950, #19191b);
      font-size: var(--font-size-sm, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: var(--font-line-height-sm, 20px);
    }
  }
  .min-screen {
    color: var(--color-subtle, #7d7e84);
    font-size: var(--font-size-sm, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--font-line-height-sm, 20px);
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-sm, 8px);
  color: var(--color-gray-950, #19191b);
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 700;
  line-height: var(--font-line-height-sm, 20px);
  .checkbox.fitted.toggle {
    margin-top: var(--spacing-sm, 8px);
  }
  .toggle.checkbox label:before,
  .toggle.checkbox.fitted label:before,
  .ui.toggle.checkbox input:focus ~ label:before {
    background-color: var(--color-primary-background) !important;
  }
`;

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-sm, 8px);
  align-self: stretch;
  .featuring {
    color: var(--color-gray-950, #19191b);
    font-size: var(--font-size-md, 16px);
    font-style: normal;
    font-weight: 700;
    line-height: var(--font-line-height-md, 24px);
  }
  .container {
    gap: var(--spacing-xxs, 4px);
    .text {
      color: var(--color-subtle, #7d7e84);
      font-size: var(--font-size-sm, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: var(--font-line-height-sm, 20px);
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm, 8px);
  color: var(--color-foreground, #404145);
  font-size: var(--font-size-md, 16px);
  font-style: normal;
  font-weight: 700;
  line-height: var(--font-line-height-md, 24px);
  i {
    width: var(--size-sm, 24px);
    height: var(--size-sm, 24px);
    color: var(--color-foreground, #404145);
  }
  cursor: pointer;
`;
