import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  display: flex;
  gap: 20px;

  .empty-screen-card {
    position: relative;
    overflow: hidden;
    height: 340px;
    padding: 20px;
    border-radius: 28px;
    text-align: left;
    z-index: 1;
    border: 2px solid var(--color-yellow-500);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
    transition: transform ease-in 0.1s;

    :before {
      background: var(--color-yellow-500);
      content: "";
      display: block;
      width: 260px;
      height: 260px;
      position: absolute;
      right: -125px;
      top: -26px;
      border-radius: 100%;
      z-index: -1;
      transition: all 0.2s ease-out;
    }

    i[name="help"] {
      width: 32px;
      height: 32px;
    }

    * {
      margin: 0;
    }

    .card-heading {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
      }

      p {
        font-size: 12px;
        line-height: 1.333;
        color: var(--color-grey70);
      }
    }

    .card-link {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.333;
      display: flex;
      align-items: center;
      gap: 5px;

      i[name="arrow-right-circle"] {
        background-color: var(--color-grey100);
        width: 20px;
        height: 20px;
      }
    }

    &.watch-video-card {
      max-width: 460px;
      background-color: var(--color-yellow-200);
      border: 2px solid var(--color-yellow-500);
      justify-content: flex-start;

      .wistia_embed {
        display: inline-block;
        position: relative;
        width: 400px;
        height: 225px;
        ${Theme.default.borderRadius};
        overflow: hidden;
        box-shadow: 0px 30px 20px -20px rgba(152, 124, 0, 0.4);

        div > div > div:first-child {
          ${Theme.default.borderRadius};
        }
      }

      :hover {
        :before {
          width: 1000px;
          height: 1000px;
        }
      }
    }

    &.help-center-card {
      width: 221px;
      border: 2px solid #e8d5a8;
      background: linear-gradient(
        100.69deg,
        #f7f0e2 0%,
        #faf1dd 34.11%,
        #fdf9ee 69.79%,
        #fcf6eb 100.75%
      );
      cursor: pointer;

      :before {
        background: linear-gradient(
          100.69deg,
          #d5bf8d 0%,
          #efdeb3 34.11%,
          #f3e8cc 69.79%,
          #fff7e6 100.75%
        );
      }

      i[name="help"] {
        background-color: var(--color-yellow120);
      }
    }

    &.scos-card {
      width: 221px;
      border: 2px solid #8570f1;
      background: linear-gradient(44.69deg, #bdcaf7 14.27%, #f1e9ff 78.2%);
      cursor: pointer;

      :before {
        background: linear-gradient(
            44.02deg,
            #ffd089 9.63%,
            #ffe1be 36.7%,
            #f9dfcb 50.49%,
            #f6ddd2 65.82%,
            #ebced2 84.46%,
            #bd8ed2 107.7%
          ),
          #ffffff;
      }

      .scos-device-img {
        transition: all 0.2s ease-out;
        position: absolute;
        right: -81px;
        top: 37px;
        z-index: -1;
        height: 145px;
      }
    }

    &:hover {
      border-color: var(--color-yellow-500);
      transform: scale(1.02);

      :before {
        width: 720px;
        height: 720px;
        background: var(--color-yellow-500);
        right: -355px;
        top: -256px;
      }

      i[name="help"] {
        background-color: var(--color-grey100);
      }
      .card-link {
        text-decoration: underline;
      }

      &.scos-card {
        .scos-device-img {
          width: 250px;
          height: 154.38px;
          right: -56.61px;
          top: 47.25px;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    width: 100%;
    flex-direction: column;
    align-items: center;

    .empty-screen-card {
      width: 100%;
      max-width: 350px;
      border-radius: 16px;
      height: auto;
      overflow: visible;

      .card-heading,
      img,
      i[name="help"],
      :before {
        display: none;
      }

      .card-link {
        font-size: 16px;
        line-height: 1.5;
        justify-content: space-between;

        i {
          width: 22px;
          height: 22px;
        }
      }

      &.watch-video-card {
        background: transparent;
        padding: 0;
        border: none;
        max-width: 350px;

        .wistia_embed {
          width: 100%;
          height: 196.67px;
          border-radius: 16px;
        }
      }

      &.help-center-card,
      &.scos-card {
        padding: 18px;
        width: 100%;

        :hover {
          background: var(--color-yellow-500);

          .card-link {
            text-decoration: none;
          }
        }
      }
    }
  }
`;
