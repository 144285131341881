import { useCacheInvalidation } from "src/hooks/useCacheInvalidation";
import { Playlist } from "src/types.g";

export function useOnPlaylistDeleted() {
  const {
    invalidateScreenGroupCache,
    invalidateEntityCache,
  } = useCacheInvalidation();

  const onPlaylistDeleted = ({
    playlist,
  }: {
    playlist: Pick<Playlist, "__typename" | "id">;
  }) => {
    invalidateEntityCache(playlist);
    invalidateScreenGroupCache();
  };

  return { onPlaylistDeleted };
}
