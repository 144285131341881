import * as React from "react";
import { getEmbededLink } from "../../../helpers/shareableHelper";
import { EmbedShareOptions } from "../index";
import { useAppContext } from "src/hooks/useAppContext";

export const useEmbededUrl = (shareable: any, embedShortId?: string) => {
  const context = useAppContext();
  const [embededUrl, setEmbededUrl] = React.useState("");

  React.useEffect(() => {
    const fetchEmptyEmbededUrl = async () => {
      const embededUrl = await getEmbededLink(context, shareable, embedShortId);
      setEmbededUrl(embededUrl);
    };
    fetchEmptyEmbededUrl();
  }, []);

  return embededUrl;
};

export const useInitialEmbedShareOption = (shareable: any) => {
  const [embedSharedOption, setEmbedSharedOption] = React.useState(() => {
    if (shareable.isEmbedEnabled && shareable.isEmbedPublic) {
      return EmbedShareOptions.Public;
    }
    if (shareable.isEmbedEnabled) {
      return EmbedShareOptions.Org;
    }
    return EmbedShareOptions.Disabled;
  });

  return { embedSharedOption, setEmbedSharedOption };
};
