import * as React from "react";
import { Styled } from "./styles";

export interface ContainerProps {
  sidebar?: boolean;
  smallSidebar?: boolean;
  className?: string;
  id?: string;
  children?: React.ReactNode;
  [key: string]: any;
  noPadding?: boolean;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  (props, ref) => {
    const {
      sidebar,
      className,
      children,
      smallSidebar,
      style,
      id,
      noPadding,
    } = props;
    return (
      <Styled
        ref={ref}
        style={style}
        className={className}
        id={id}
        sidebar={sidebar}
        smallSidebar={smallSidebar}
        noPadding={noPadding}
      >
        {children}
      </Styled>
    );
  }
);

export default Container;
