import styled from "styled-components";

export const Styled = styled.div<{ noBg: boolean }>`
  ${(props) =>
    props.noBg
      ? `background: #fff;`
      : `background: linear-gradient(-135deg, #efe5ff, #fff7d6, #ffffff, #ded0f4);
        background-size: 400% 400%;
        animation: gradient 10s ease infinite;`}

  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: scale;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .ui.loader {
    bottom: 50%;
    top: 50%;
    right: 50%;
    left: 50%;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes scale {
    0% {
      transform: scale(0.1);
      opacity: 0;
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    75% {
      transform: scale(1.2);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
      transform: scale(1);
      opacity: 1;
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
  }
`;
