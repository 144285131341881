import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import { QrcodePositionStyledProps } from "../../helpers/qrcodeHelper";

export const Styled = styled.div<QrcodePositionStyledProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;

  .preview-title {
    height: 70px;
    border-bottom: solid 1px ${Theme.color.lightGrey};
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;

    h1 {
      font-size: 1.25em;
      max-width: calc(100% - 50px);
      height: 26px;
      display: flex;
      gap: 4px;

      .edit-false {
        &:hover {
          border: none !important;
        }
      }
      &.inline-input {
        width: 100%;
      }

      .clickable {
        cursor: pointer;
      }

      .title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      i {
        flex-shrink: 0;
      }
    }
  }

  .link-preview {
    background: ${Theme.color.nearWhite};
    border-radius: ${Theme.default.borderRadiusValue} 0 0
      ${Theme.default.borderRadiusValue};
    height: calc(100% - 70px);
    padding: 0 40px;
    position: relative;
    transition: all 0.2s cubic-bezier(0.7, 0.1, 0.3, 1.2);
    width: calc(100% - 320px);

    .icon-button-tab {
      padding: 10px 0px;
      min-height: 40px;
      display: flex;
      justify-content: flex-end;
      .close-modal {
        position: static;
        padding: 20px;
      }

      .toggle-star {
        display: inline-block;
      }

      .casting {
        padding: 0 15px;
        width: auto;

        :hover {
          background: ${Theme.color.silver};
        }

        i {
          background-color: ${Theme.color.base};
        }
      }
    }

    .preview-spinner {
      left: 50%;
      position: absolute;
      top: 50%;
    }

    .content-preview {
      width: 100%;
      height: calc(100% - 70px);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      position: relative;
      .link-content {
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        .image-wrapper {
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          .link {
            width: 100%;
            height: 100%;
            .qrcode {
              position: absolute;
              width: 100%;
              padding-bottom: 56.25%;
              height: 0;
            }

            .qrcode img {
              position: absolute;
              ${(props) => props.qrcoderight && "right: 1rem;"}
              ${(props) => props.qrcodeleft && "left: 1rem;"}
              ${(props) => props.qrcodebottom && "bottom: 1rem;"}
              ${(props) => props.qrcodetop && "top: 1rem;"}
              width: 14%;
              ${Theme.default.borderRadius};
            }
          }
        }
      }
      img {
        display: block;
      }

      ${Theme.default.borderRadius};
    }

    .link-url {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  .link-info {
    transition: all 0.2s cubic-bezier(0.7, 0.1, 0.3, 1.2);
    width: 320px;
    background: ${Theme.color.white};
    border-left: 1px solid ${Theme.color.borderSection};
    border-radius: 0 6px 6px 0;
    position: relative;
    max-height: 100%;

    .link-info-title {
      height: 60px;
      margin-bottom: 10px;
      overflow: hidden;

      h1 {
        padding: 0 20px;
        font-size: 1.5em;
        word-break: break-word;
      }
    }

    .link-tabs {
      transition: all 0.2s cubic-bezier(0.7, 0.1, 0.3, 1.2);
      height: calc(100% - 66px);

      .ui.secondary.pointing.menu {
        margin-bottom: 0;
      }

      .ui.tab {
        height: calc(100% - 50px);
        overflow: auto;

        .wrapper {
          .url-wrapper {
            display: flex;
            > div {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: calc(100% - 30px);
            }
            i[name="edit"] {
              background-color: ${Theme.color.lightGrey};
              opacity: 0;
              margin-left: 10px;
              cursor: pointer;
              &:hover {
                opacity: 1;
                background-color: ${Theme.color.base};
              }
            }
            .linkurl {
              color: ${Theme.color.darkGrey};
              font-weight: bold;
              width: 100%;
              max-height: 200px;

              span {
                max-width: calc(100% - 15px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
              }

              i {
                background-color: ${Theme.color.grey};
                width: 10px;
                height: 1.3em;
                margin-left: 5px;
              }

              :hover {
                color: ${Theme.color.base};

                i {
                  background-color: ${Theme.color.base};
                }
              }
            }
            :hover {
              i[name="edit"] {
                opacity: 1;
              }
            }
          }

          .table {
            background-color: ${Theme.color.nearWhite};
            border-color: ${Theme.color.silver};
            padding: 20px 20px 10px;
            margin: 0;
            margin-top: 20px;
            tr {
              td {
                border-top: none;
                padding: 0 0 10px;
                font-size: 0.875em;
                color: ${Theme.color.base};
                font-weight: bold;
                width: 50% !important;
                line-height: 1.3;
                vertical-align: baseline;
              }

              td:first-child {
                color: rgba(31, 32, 39, 0.5);
                font-weight: normal;
              }
            }
          }

          h3 {
            margin-bottom: 10px;
            font-weight: normal;
          }

          .tags {
            margin-top: 20px;

            .ui.dropdown {
              .label:last-child {
                margin-bottom: 7px;
              }
            }
          }

          .publication {
            .head {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;

              .text {
                width: calc(100% - 80px);
                margin: 0 20px 0 0;

                h3 {
                  margin: 0;
                }

                h3 + p {
                  margin-top: 2px;
                }
              }
            }

            .expand {
              margin-top: 20px;

              > div {
                margin-top: 20px;

                :first-child {
                  margin: 0;
                }
              }
            }

            .desc {
              font-size: 0.875em;
              color: rgba(31, 32, 39, 0.5);
              margin-top: 5px;
              width: 100%;
            }

            h3 + .desc {
              margin-top: 0;
            }
          }
        }
      }
    }

    .link-options {
      position: absolute;
      width: 100%;
      padding: 10px 20px;
      bottom: 0;
      right: 0;
      border-top: 1px solid ${Theme.color.lightGrey};

      .button {
        margin: 0;
        vertical-align: middle;
      }

      .link-cast {
        float: right;
        width: 45px;
        padding: 0;
      }

      :after {
        display: block;
        content: "";
        clear: both;
      }
    }
  }

  .cast-on {
    display: none;
    opacity: 0;
    height: 0;
  }

  &.casting {
    .link-preview {
      height: 140px;
      width: 140px;
      padding: 20px;

      .link-content {
        margin: 0;
      }
    }

    .link-info {
      width: calc(100% - 140px);
      height: 140px;

      .link-tabs {
        display: none;
      }

      .link-options {
        display: none;
      }
    }

    .cast-on {
      display: block;
      width: 100%;
      height: calc(100% - 140px);
      opacity: 1;
      border-top: 1px solid ${Theme.color.lightGrey};

      .head {
        background: rgba(31, 32, 39, 0.8);
        color: ${Theme.color.white};
        height: 50px;
        font-weight: bold;
        text-align: center;
        font-size: 1.125em;
        line-height: 50px;
      }
    }
  }

  .delete-confirm {
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: ${Theme.color.white};
    transition: all 0.2s ease;

    .delete-confirm-body {
      display: none;
    }
  }

  &.deleting {
    .link-preview {
      .link-content {
        display: none;
      }

      .link-cast {
        display: none;
      }
    }

    .link-info {
      display: none;
    }

    .delete-confirm {
      display: flex;
      width: 100%;
      height: 100%;
      opacity: 1;
      border-top: 1px solid ${Theme.color.lightGrey};
      bottom: 0;
      left: 0;

      .delete-confirm-body {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        .confirm-message {
          white-space: pre-line;
          font-size: 1.25em;

          strong {
            &::before {
              content: " ";
              display: block;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .link-info {
      .link-info-title {
        height: unset;
        h1 {
          font-size: 1.25em;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .link-preview {
      width: 100%;
      height: 320px;
      padding: 10px 40px;
      .content-preview {
        .link-content {
          width: 60%;
          .image-wrapper {
            .link {
              .qrcode {
                padding-bottom: 76.25%;
              }
            }
          }
        }
      }
    }

    .link-info {
      height: calc(100% - 320px);
      width: 100%;
      border-left: none;
      border-right: none;
      border-top: 1px solid ${Theme.color.lightGrey};

      .link-tabs {
        height: calc(100% - 116px);

        .ui.tab {
          height: calc(100% - 114px);
          .wrapper {
            margin: 0 auto;
            max-width: 480px;

            .table {
              display: flex;

              tbody {
                width: 100%;
              }
              tr {
                display: table-row;
                box-shadow: none;
                width: 100%;
                padding: 0;

                td {
                  display: table-cell;
                  padding: 0 0 10px;
                }
              }
            }
          }
        }

        &.no-footer {
          .ui.tab {
            height: calc(100% - 50px);
          }
        }

        .link-options {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .link-info {
      height: calc(100vh - 320px);

      .link-tabs {
        .ui.tab {
          .wrapper {
            flex-flow: column;

            .table {
              width: 100% !important;
            }

            .tags {
              margin-top: 10px;
              margin-left: 0px;
              width: 100%;
            }
          }
        }
      }

      .link-options {
        padding: 10px;
      }
    }

    @supports (padding-bottom: 10px) {
      .link-info {
        .link-options {
          --safe-area-inset-bottom: env(safe-area-inset-bottom);
          height: calc(65px + (var(--safe-area-inset-bottom)));
          padding-bottom: max(10px, env(safe-area-inset-bottom));
        }
      }
    }
  }
`;
