import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
    .link-cache-option-item-description {
      font-size: 14px;
      word-break: break-all;
    }
    .link-cache-option-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      h3 {
        margin: 0;
      }

      button {
        i {
          background-color: ${Theme.color.grey};
        }
      }
      &.align-right {
        justify-content: flex-end;
        span {
          margin-right: 10px;
        }
      }
    }
  }
`;
