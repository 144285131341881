import { ApolloCache } from "@apollo/client";
import { Loader } from "@screencloud/screencloud-ui-components";
import { isValid as isDateValid } from "date-fns";
import { cloneDeep } from "lodash";
import * as React from "react";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { MediaParams } from "../../AppContextProvider/modals/type";
import { Typenames } from "../../constants/constants";
import { appendNewTags } from "../../helpers/tagHelper";
import { modifyScreenByCastId } from "../../hooks/useCreateCasts";
import { updateContentCasting } from "../../hooks/useStopCasts";
import queryHelper from "../../state/helper/query";
import {
  AllTagsDocument,
  AllTagsQuery,
  CastByCastIdFragment,
  CreateCastsByFileIdDocument,
  CreateCastsByFileIdMutationVariables,
  File,
  Maybe,
  ScreenCastStopDocument,
  ScreenCastStopMutationVariables,
  SetScreenContentByFileIdMutationVariables,
  UpdateFileAvailableDocument,
  UpdateFileAvailableMutationVariables,
  UpdateFileByIdDocument,
  UpdateFileByIdMutation,
  UpdateFileByIdMutationVariables,
  UpdateFileExpireDocument,
  UpdateFileExpireMutationVariables,
  UpdateFileIsFavoriteDocument,
  UpdateFileIsFavoriteMutationVariables,
} from "../../types.g";
import { CastedScreenInfoActions } from "../CastedScreenInfo";
import ScreenPicker, { ScreenPickerActions } from "../ScreenPicker";
import { WithMediaProps, withMediaPreview } from "./withMediaPreview";
import { MEDIA_LOGS } from "./mockData";
import MediaPreviewUI, {
  MediaPreviewActions,
  MediaPreviewPayload,
} from "./preview";
export interface MediaPreviewComponentProps {
  screenPickerComponent?: React.ReactNode;
  asScreenPicker?: boolean;
  onMediaPreviewCallBack?: (
    mediaId: string,
    action: MediaPreviewActions,
    data: MediaPreviewPayload
  ) => void;
  params?: MediaParams;
}

export interface MediaPreviewComponentState {
  media: File | undefined;
  showScreenPicker: boolean;
  showDeleteConfirm: boolean;
}

class MediaPreviewComponent extends React.PureComponent<
  MediaPreviewComponentProps & WithMediaProps,
  MediaPreviewComponentState
> {
  public static contextType = AppContext;
  public static getDerivedStateFromProps(
    props: MediaPreviewComponentProps & WithMediaProps,
    state: MediaPreviewComponentState
  ) {
    if (props.data && props.data.fileById) {
      return {
        media: props.data.fileById,
      };
    }
    return null;
  }
  public context: AppContextType;

  constructor(props: MediaPreviewComponentProps & WithMediaProps) {
    super(props);
    this.state = {
      media: undefined,
      showDeleteConfirm: false,
      showScreenPicker: this.props.asScreenPicker || false,
    };
  }

  public refetchAllTagsQueries = () => {
    return {
      query: AllTagsDocument,
    };
  };

  public onCastedScreensCallback = async (
    data: string,
    action: CastedScreenInfoActions
  ) => {
    switch (action) {
      case CastedScreenInfoActions.STOP_CAST:
        const castStopVar: ScreenCastStopMutationVariables = {
          input: {
            screenId: data,
          },
        };
        this.props.client
          .mutate({
            mutation: ScreenCastStopDocument,
            variables: castStopVar,
          })
          .then(() => {
            const castItem = {
              fileByCastId: {
                id: this.props.data.fileById?.id,
              },
            } as CastByCastIdFragment;
            updateContentCasting(castItem);
            queryHelper.updateCastedScreen(this.context.user.settings.spaceId);
          });
        break;
      case CastedScreenInfoActions.NAVIGATE_SCREEN:
        this.props.history.push("/screens/" + data);
        this.context.modal.closeModals();
        break;
      default:
    }
  };

  public onMediaPreviewCallBack = async (
    mediaId: string,
    action: MediaPreviewActions,
    data: MediaPreviewPayload
  ) => {
    const spaceId = this.context.user.settings.spaceId;
    let result: boolean = true;
    if (this.props.onMediaPreviewCallBack) {
      this.props.onMediaPreviewCallBack(mediaId, action, data);
    }
    switch (action) {
      case MediaPreviewActions.DELETE:
        await this.deleteFile(mediaId, this.state.media!);
        queryHelper.updateCastedScreen(this.context.user.settings.spaceId);
        this.context.modal.closeModals();
        break;
      case MediaPreviewActions.DOWNLOAD:
        window.open(data.imageUrl);
        break;
      case MediaPreviewActions.UPDATE_TAGS:
        const media = this.state.media!;
        if (data.tagsList) {
          const tags = data.tagsList
            .map((tag: any) => {
              return tag;
            })
            .sort();
          const fileContent = {
            ...this.state.media,
            tags: tags.sort(),
          } as File;
          this.setState({ media: fileContent });
          const updateMediaFile: UpdateFileByIdMutationVariables = {
            input: {
              fileId: mediaId,
              tags,
            },
          };
          await this.props.client.mutate({
            mutation: UpdateFileByIdDocument,
            optimisticResponse: {
              __typename: Typenames.Mutation,
              updateFileById: {
                __typename: Typenames.UpdateFileByIdPayload,
                file: {
                  __typename: Typenames.File,
                  availableAt: media.availableAt,
                  createdAt: media.createdAt,
                  createdBy: media.createdBy,
                  expireAt: media.expireAt,
                  fileProcessingStatus: media.fileProcessingStatus,
                  folderId: media.folderId,
                  id: mediaId,
                  isFavorite: media.isFavorite,
                  metadata: media.metadata,
                  mimetype: media.mimetype,
                  name: media.name || "",
                  orgId: media.orgId,
                  size: media.size,
                  source: media.source,
                  spaceId,
                  tags,
                  updatedAt: media.updatedAt,
                  updatedBy: media.updatedBy,
                },
              },
              update: (
                proxy: ApolloCache<UpdateFileByIdMutation>,
                updateData: { data: UpdateFileByIdMutation }
              ) => {
                // Read the data from our cache for this query.
                const cacheAllTags = proxy.readQuery<AllTagsQuery>({
                  query: AllTagsDocument,
                });
                const cloneCacheAllTags = cloneDeep(cacheAllTags);
                const newFileData = updateData.data;

                if (
                  newFileData?.updateFileById?.file?.tags &&
                  cloneCacheAllTags?.allTags
                ) {
                  const updatedFileTags = cloneDeep(
                    newFileData.updateFileById.file.tags
                  );
                  const existingTags = cloneCacheAllTags.allTags.nodes;
                  const updatedCacheAllTags = appendNewTags(
                    this.context.user.claims.orgId,
                    this.context.currentSpace?.id,
                    updatedFileTags,
                    existingTags
                  );
                  cloneCacheAllTags.allTags.nodes = (
                    updatedCacheAllTags ?? []
                  ).map((tag) => {
                    return {
                      ...tag,
                      __typename: "Tag" as "Tag",
                      orgId: tag!.orgId!,
                      name: tag!.name!,
                    };
                  });
                  proxy.writeQuery({
                    query: AllTagsDocument,
                    data: cloneCacheAllTags,
                  });
                }
              },
            },
            variables: updateMediaFile,
          });
          this.props.allTags && this.props.allTags.refetch();
        }
        break;
      case MediaPreviewActions.RENAME:
        if (data.title) {
          const updateMediaFile: UpdateFileByIdMutationVariables = {
            input: {
              fileId: mediaId,
              name: data.title,
            },
          };
          const output = await this.props.client.mutate({
            mutation: UpdateFileByIdDocument,
            variables: updateMediaFile,
          });
          result = !!output.data.updateFileById;
        }
        break;
      case MediaPreviewActions.FAVORITE:
        await this.updateFavoriteFile(
          this.state.media as File,
          data.checked ?? false
        );
        break;
      case MediaPreviewActions.SHOW_SCREEN_PICKER:
        this.context.modal.openNavigationControlModal(
          <ScreenPicker
            callback={(selectedScreens, screenPickerAction, expiresAt?: Date) =>
              this.onScreenPickerCallback(
                selectedScreens,
                screenPickerAction,
                expiresAt
              )
            }
            disabledAction={false}
            itemToCast={this.state.media}
          />,
          this.context.intl.formatMessage({
            defaultMessage: "Select screens to play",
            id: "ui_component.common.label.select_screens_to_play",
          }) +
            " " +
            this.state.media?.name,
          { opts: { disableTitle: false, overflow: true } }
        );
        break;
      case MediaPreviewActions.SHOW_DELETE_CONFIRM:
        this.setState({
          showDeleteConfirm: true,
        });
        break;
      case MediaPreviewActions.HIDE_DELETE_CONFIRM:
        this.setState({
          showDeleteConfirm: false,
        });
        break;
      case MediaPreviewActions.ADD_AVAILABLE_DATE:
        if (data && data.availableDate) {
          await this.updateFileAvailable(
            mediaId,
            this.state.media as File,
            data.availableDate
          );
        }
        break;
      case MediaPreviewActions.REMOVE_AVAILABLE_DATE:
        await this.updateFileAvailable(mediaId, this.state.media as File, "");
        break;
      case MediaPreviewActions.ADD_EXPIRY_DATE:
        if (data && data.expiryDate) {
          await this.updateFileExpire(
            mediaId,
            this.state.media as File,
            data.expiryDate
          );
        }
        break;
      case MediaPreviewActions.REMOVE_EXPIRY_DATE:
        await this.updateFileExpire(mediaId, this.state.media as File, "");
        break;
      case MediaPreviewActions.RESET_PUBLICATION_DATES:
        await this.updateFileAvailable(mediaId, this.state.media as File, "");
        await this.updateFileExpire(mediaId, this.state.media as File, "");
        break;
      default:
        break;
    }
    return result;
  };

  public onScreenPickerCallback = async (
    data: string[],
    action: ScreenPickerActions,
    expiresAt?: Date
  ) => {
    const mediaId = this.state.media!.id;
    switch (action) {
      case ScreenPickerActions.SET_CONTENT:
        const setContents = data.map((screenId) => {
          const setFileContent: SetScreenContentByFileIdMutationVariables = {
            input: {
              screenId,
              fileId: mediaId,
            },
          };

          return this.props.setContentByFileId({
            variables: setFileContent,
          });
        });
        await Promise.all(setContents);
        this.context.modal.closeNavigationControlModal();
        break;
      case ScreenPickerActions.CASTING:
        const castStartVar: CreateCastsByFileIdMutationVariables = {
          input: {
            screenIds: data,
            fileId: mediaId,
            expiresAt,
          },
        };
        this.props.client
          .mutate({
            mutation: CreateCastsByFileIdDocument,
            variables: castStartVar,
            update: (cache, { data }) => {
              const cast =
                data?.createCastsByFileId?.casts &&
                data.createCastsByFileId.casts[0];
              modifyScreenByCastId(cache, cast);

              cache.modify({
                id: cache.identify(this.props.data.fileById),
                fields: {
                  castedScreenByFlieId(casts, _options) {
                    if (cast) {
                      const nodes = [
                        ...casts.nodes,
                        ...cast.screensByCastId.nodes,
                      ];
                      return {
                        ...casts,
                        nodes,
                        totalCount: nodes.length,
                      };
                    }
                    return casts;
                  },
                },
              });
            },
          })
          .then(() => {
            queryHelper.updateCastedScreen(this.context.user.settings.spaceId);
            this.context.modal.closeNavigationControlModal();
          });
        break;
      case ScreenPickerActions.CANCEL:
        if (this.props.asScreenPicker) {
          this.context.modal.closeNavigationControlModal();
        } else {
          this.setState({
            showScreenPicker: false,
          });
        }
        break;
      case ScreenPickerActions.ADD_SCREEN:
        this.context.modal.closeNavigationControlModal();
        this.context.modal.closeModals();
        this.props.history.push("/screens/add");
        break;
      default:
    }
  };

  public updateFavoriteFile = async (
    mediaFile: Partial<File>,
    isFavorite: boolean
  ) => {
    const updateFileIsFavoriteVariables: UpdateFileIsFavoriteMutationVariables = {
      input: {
        fileIds: [mediaFile.id],
        isFavorite,
      },
    };
    await this.props.client.mutate({
      mutation: UpdateFileIsFavoriteDocument,
      variables: updateFileIsFavoriteVariables,
    });
    this.setState({ media: { ...mediaFile, isFavorite: true } as File });
  };

  public deleteFile = async (
    mediaId: string,
    mediaFile: Maybe<Partial<File>>
  ) => {
    this.props.deleteFile({ id: mediaId });
  };

  public updateFileAvailable = async (
    mediaId: string,
    mediaFile: File,
    availableDate: string
  ) => {
    const spaceId: string = this.context.user.settings.spaceId;
    if (
      this.props.data &&
      this.props.data.fileById &&
      this.context.user.claims.orgId &&
      this.context.user.claims.userId
    ) {
      let updateFileAvailableVariables: UpdateFileAvailableMutationVariables;
      if (availableDate !== "" && isDateValid(new Date(availableDate))) {
        updateFileAvailableVariables = {
          input: {
            availableAt: availableDate,
            fileId: mediaId,
          },
        };
      } else {
        updateFileAvailableVariables = {
          input: {
            fileId: mediaId,
          },
        };
      }

      await this.props.client.mutate({
        mutation: UpdateFileAvailableDocument,
        optimisticResponse: {
          __typename: Typenames.Mutation,
          updateFileAvailable: {
            __typename: Typenames.UpdateFileAvailablePayload,
            file: {
              __typename: Typenames.File,
              availableAt: availableDate,
              createdAt: mediaFile.createdAt,
              createdBy: mediaFile.createdBy,
              expireAt: mediaFile.expireAt,
              fileProcessingStatus: mediaFile.fileProcessingStatus,
              folderId: mediaFile.folderId,
              id: mediaId,
              isFavorite: mediaFile.isFavorite,
              metadata: mediaFile.metadata,
              mimetype: mediaFile.mimetype,
              name: mediaFile.name,
              orgId: mediaFile.orgId,
              size: mediaFile.size,
              source: mediaFile.source,
              spaceId,
              tags: mediaFile.tags,
              updatedAt: mediaFile.updatedAt,
              updatedBy: mediaFile.updatedBy,
            },
          },
        },
        variables: updateFileAvailableVariables,
      });
    }
  };

  public updateFileExpire = async (
    mediaId: string,
    mediaFile: File,
    expireDate: string
  ) => {
    const spaceId = this.context.user.settings.spaceId;
    if (
      this.props.data &&
      this.props.data.fileById &&
      this.context.user.claims.orgId &&
      this.context.user.claims.userId
    ) {
      let updateFileExpireVariables: UpdateFileExpireMutationVariables;
      if (expireDate !== "" && isDateValid(new Date(expireDate))) {
        updateFileExpireVariables = {
          input: {
            expireAt: expireDate,
            fileId: mediaId,
          },
        };
      } else {
        updateFileExpireVariables = {
          input: {
            fileId: mediaId,
          },
        };
      }

      await this.props.client.mutate({
        mutation: UpdateFileExpireDocument,
        optimisticResponse: {
          __typename: Typenames.Mutation,
          updateFileExpire: {
            __typename: Typenames.UpdateFileExpirePayload,
            file: {
              __typename: Typenames.File,
              availableAt: mediaFile.availableAt,
              createdAt: mediaFile.createdAt,
              createdBy: mediaFile.createdBy,
              expireAt: expireDate,
              fileProcessingStatus: mediaFile.fileProcessingStatus,
              folderId: mediaFile.folderId,
              id: mediaId,
              isFavorite: mediaFile.isFavorite,
              metadata: mediaFile.metadata,
              mimetype: mediaFile.mimetype,
              name: mediaFile.name,
              orgId: mediaFile.orgId,
              size: mediaFile.size,
              source: mediaFile.source,
              spaceId,
              tags: mediaFile.tags,
              updatedAt: mediaFile.updatedAt,
              updatedBy: mediaFile.updatedBy,
            },
          },
        },
        variables: updateFileExpireVariables,
      });
    }
  };

  public timeDuration(seconds: number) {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  public render() {
    const { params } = this.props;
    const { media } = this.state;
    if (media) {
      const allTags = (this.props.allTags.allTags?.nodes ?? []).map((item) => ({
        text: item.name,
        value: item.name,
      }));

      return (
        <MediaPreviewUI
          title={media!.name!}
          media={media}
          tagsList={allTags}
          logs={MEDIA_LOGS}
          callBack={this.onMediaPreviewCallBack}
          showDeleteConfirm={this.state.showDeleteConfirm}
          castedScreensData={media?.castedScreenByFileId?.nodes}
          castedScreensCallback={this.onCastedScreensCallback}
          defaultTabIndex={params && params.defaultTabIndex}
        />
      );
    } else {
      return <Loader className="center" active />;
    }
  }
}

export default withMediaPreview(MediaPreviewComponent);
