import { useEffect, useState } from "react";
import { User, useUserByIdQuery } from "../types.g";

interface Props {
  id: string | undefined | null;
}

export function useUserById({ id }: Props) {
  const [user, setUser] = useState(null as null | undefined | User);
  const { data: userByIdQuery } = useUserByIdQuery({
    fetchPolicy: "cache-first",
    variables: {
      id,
    },
    skip: !id,
  });

  useEffect(() => {
    if (userByIdQuery?.userById) {
      setUser(userByIdQuery?.userById as User);
    }
  }, [userByIdQuery]);

  return {
    user,
  };
}
