import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  .container {
    user-select: none;
    padding: 20px 20px 0 20px;

    .icons {
      padding: 20px 0 10px;
      i {
        background-color: ${Theme.color.grey70};

        :first-child {
          width: 60px;
          height: 60px;
          mask-size: 60px;
        }

        :last-child {
          width: 40px;
          height: 40px;
          mask-size: 40px;
          transform: scaleX(-1);
          margin-bottom: 3px;
          background-color: ${Theme.color.grey80};
        }
      }
    }

    .header-section {
      display: flex;
      flex-direction: column;
      padding: 0 0 20px;
    }

    .field-section {
      display: flex;
      height: 400px;
      flex-direction: column;
      gap: 10px;

      .name {
        display: flex;
        width: 100%;
        gap: 10px;

        .field {
          width: calc(50% - 5px);
        }
      }
      .field {
        width: 100%;
      }
      textarea {
        ::placeholder {
          color: ${Theme.color.grey};
        }
        line-height: 20px;
        min-height: 100px;
      }
    }

    .answer {
      padding: 30px 0 0;
    }

    .btn {
      display: flex;
      justify-content: flex-end;
    }

    @media screen and (max-width: ${Theme.break.tiny}) {
      .field-section {
        .name {
          flex-wrap: wrap;
          .field {
            width: 100%;
          }
        }
    }
  }
`;
