import { ApolloQueryResult } from "@apollo/client";
import {
  CreateCastsBySiteIdMutationFn,
  DeleteSiteByIdMutationFn,
  ScreenCastStopMutationFn,
  SetScreenContentBySiteIdMutationFn,
  SiteListQuery,
  UpdateShareSiteToAllSpacesMutationFn,
  UpdateShareSiteToSpacesMutationFn,
  UpdateSiteByIdMutationFn,
  UpdateSiteIsFavoriteMutationFn,
  useCreateCastsBySiteIdMutation,
  useSetScreenContentBySiteIdMutation,
  useScreenCastStopMutation,
  useUpdateShareSiteToSpacesMutation,
  useUpdateShareSiteToAllSpacesMutation,
  useDeleteSiteByIdMutation,
  useUpdateSiteIsFavoriteMutation,
  useUpdateSiteByIdMutation,
  SitesOrderBy,
  SiteFragment,
  Exact,
  Maybe,
} from "../../types.g";
import { compose } from "../../utils/compose";

import {
  useAllSitesFromOtherSpaces,
  useAllSitesInCurrentSpace,
} from "./hooks/useAllSites";
import { useAppContext } from "src/hooks/useAppContext";
import { UseTabIndex, useTabIndex } from "src/hooks/useTabIndex/useTabIndex";
import {
  UseSearchSite,
  useSearchSharedSiteList,
  useSearchSiteList,
} from "./hooks/useSearchSites";
export interface ApolloProps extends UseTabIndex {
  createCastsBySiteId: CreateCastsBySiteIdMutationFn;
  setScreenContentBySiteId: SetScreenContentBySiteIdMutationFn;
  updateShareSiteToSpaces: UpdateShareSiteToSpacesMutationFn;
  updateShareSiteToAllSpaces: UpdateShareSiteToAllSpacesMutationFn;
  deleteSite: DeleteSiteByIdMutationFn;
  screenCastStop: ScreenCastStopMutationFn;
  updateFavoriteSite: UpdateSiteIsFavoriteMutationFn;
  updateSiteById: UpdateSiteByIdMutationFn;

  inTrial: boolean;
  sites: SiteFragment[];
  isSitesloading: boolean;
  renderSitesFetchMoreButton: React.ReactNode;
  siteTotalCount: number;
  refetchSites: (
    variables?:
      | Partial<
          Exact<{
            spaceId: any;
            first: Maybe<number>;
            endCursor: any;
            orderBy: Maybe<SitesOrderBy[]>;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<SiteListQuery>>;
  sharedSites: SiteFragment[];
  isSharedSitesloading: boolean;
  renderSharedSitesFetchMoreButton: React.ReactNode;
  sharedSiteTotalCount: number;
  hasNextSharedSitePage: boolean;
}

export const withData = compose(
  (Component) => (props: ApolloProps) => {
    const context = useAppContext();
    const {
      sites,
      isLoading,
      renderFetchMoreButton,
      refetch,
      totalCount,
    } = useAllSitesInCurrentSpace({
      spaceId: context.currentSpace?.id,
    });
    return (
      <Component
        {...props}
        sites={sites}
        isSitesloading={isLoading}
        siteTotalCount={totalCount}
        renderSitesFetchMoreButton={renderFetchMoreButton}
        refetchSites={refetch}
      />
    );
  },
  (Component) => (props: ApolloProps) => {
    const context = useAppContext();
    const {
      sites,
      isLoading,
      renderFetchMoreButton,
      totalCount,
      hasNextPage,
    } = useAllSitesFromOtherSpaces({
      spaceId: context.currentSpace?.id,
    });
    return (
      <Component
        {...props}
        sharedSites={sites}
        isSharedSitesloading={isLoading}
        sharedSiteTotalCount={totalCount}
        renderSharedSitesFetchMoreButton={renderFetchMoreButton}
        hasNextSharedSitePage={hasNextPage}
      />
    );
  },
  (Component) => (props: ApolloProps) => {
    const params = {
      totalItemCount: props.siteTotalCount,
      totalSharedItemCount: props.sharedSiteTotalCount,
      isContentLoading: props.isSitesloading,
      isSharedContentLoading: props.isSharedSitesloading,
    };
    return <Component {...props} {...useTabIndex(params)} />;
  },
  (Component) => (props: ApolloProps) => {
    const [createCastsBySiteId] = useCreateCastsBySiteIdMutation();
    const [setScreenContentBySiteId] = useSetScreenContentBySiteIdMutation();
    const [screenCastStop] = useScreenCastStopMutation();
    const [updateShareSiteToSpaces] = useUpdateShareSiteToSpacesMutation();
    const [
      updateShareSiteToAllSpaces,
    ] = useUpdateShareSiteToAllSpacesMutation();
    const [deleteSite] = useDeleteSiteByIdMutation();
    const [updateFavoriteSite] = useUpdateSiteIsFavoriteMutation();
    const [updateSiteById] = useUpdateSiteByIdMutation();
    return (
      <Component
        {...props}
        createCastsBySiteId={createCastsBySiteId}
        setScreenContentBySiteId={setScreenContentBySiteId}
        screenCastStop={screenCastStop}
        updateShareSiteToSpaces={updateShareSiteToSpaces}
        updateShareSiteToAllSpaces={updateShareSiteToAllSpaces}
        deleteSite={deleteSite}
        updateFavoriteSite={updateFavoriteSite}
        updateSiteById={updateSiteById}
      />
    );
  }
);

export interface SearchSiteApolloProps {
  searchSite: UseSearchSite;
  searchSharedSite: UseSearchSite;
}

export const withSearchData = compose((Component) => (props) => {
  const searchSite = useSearchSiteList(props.searchTerms);
  const searchSharedSite = useSearchSharedSiteList(props.searchTerms);

  return (
    <Component
      {...props}
      searchSite={searchSite}
      searchSharedSite={searchSharedSite}
    />
  );
});
