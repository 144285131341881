import styled from "styled-components";

export const Styled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;

  > * {
    animation: content 0.2s ease-in;
  }

  @keyframes content {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
`;
