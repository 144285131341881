import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledButtonsDiv = styled.div`
  .button {
    min-width: 150px;
    &.button-compare-plans {
      margin-left: 10px;
    }
  }
`;
export const Styled = styled.div`
  height: 100%;
  overflow: visible;
  position: relative;

  .container {
    overflow: hidden;
  }

  #sites-scroll-container {
    height: 100%;
    overflow: auto;
    padding: 0px 40px 70px;
  }

  .toggle-container {
    display: flex;
    justify-content: space-between;
  }

  .site-search-results {
    background: ${Theme.color.nearWhite};
  }

  .wrapper {
    background: ${Theme.color.white};
    border-bottom: solid 1px ${Theme.color.lightGrey};
  }

  .sub-wrapper {
    padding-top: 20px;
  }

  .toggle-view {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .title-wrapper {
    padding: 0 0 0 0;
  }
  .items {
    height: 40px;
    list-style: none;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    &-item {
      display: inline-block;
      height: 40px;
      white-space: nowrap;
    }

    &-link {
      border-bottom: 4px solid transparent;
      color: ${Theme.color.midGrey};
      cursor: pointer;
      display: inline-block;
      font-size: 0.75em;
      font-weight: 900;
      height: 40px;
      letter-spacing: 0.1em;
      line-height: 40px;
      margin-bottom: -1px;
      padding: 0 16px;
      text-transform: uppercase;
      transition: all 0.2s linear;

      &:hover,
      &.selected {
        border-color: var(--color-primary-background);
        color: var(--color-primary-foreground);
      }
    }
  }

  no-results {
    text-align: center;
    font-size: 20px;
    color: #999;
    text-shadow: 1px 1px ${Theme.color.white};
  }

  .banner {
    margin: 0 auto 20px;
    display: flex;
    justify-content: center;

    + .layout-list {
      height: auto;
    }

    div.core > div.img {
      transform: scale(1.2);
      margin-left: 10px;
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .banner {
      div.core > div.img {
        transform: scale(0.95);
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .container {
      height: calc(100% - 135px);
      padding: 0 20px 20px;

      &.no-footer {
        height: calc(100% - 66px);
      }
    }
  }
`;

export const StyledUsageInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Theme.color.white};
  border: 1px solid ${Theme.color.silver};
  ${Theme.default.borderRadius};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  padding: 20px;
  margin-bottom: 15px;

  .row {
    display: flex;
    flex-direction: row;
  }

  .heading {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    color: ${Theme.color.base};
  }

  .meter {
    flex: 1;
    display: flex;
    flex-direction: row;

    background-color: ${Theme.color.silver};
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    height: 20px;
    margin: 10px 0px 5px 0px;
    overflow: hidden;

    .available {
      background-color: ${Theme.color.green};
      height: 100%;
    }

    .included {
      background-color: ${Theme.color.blueHover};
      height: 100%;
    }
  }

  .info {
    font-size: 14px;
  }

  .emphasis {
    font-weight: bold;
    color: ${Theme.color.base};
  }

  .plan-units {
    color: ${Theme.color.green};
  }

  .included-units {
    color: ${Theme.color.blueHover};
  }
`;
