import { Button, Checkbox } from "@screencloud/screencloud-ui-components";
import { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import { Styled } from "./style";
import {
  clearOnboardingRemindSetting,
  getOnboardingRemindSetting,
  setOnboardingRemindSetting,
} from "./utils";

interface DashboardsOnboardingModalProps {
  onClose: () => void;
}

const DASHBOARDS_ONBOARDING_VIDEO_URL =
  "https://fast.wistia.net/embed/iframe/30czrrr165";
const DASHBOARDS_ONBOARDING_DOC_URL =
  "https://screen.cloud/learn/screencloud-dashboards-guide";

const DashboardsOnboardingModal = (
  props: DashboardsOnboardingModalProps
): ReactElement<DashboardsOnboardingModalProps> => {
  const { onClose } = props;
  const [doNotRemind, setDoNotRemind] = useState(getOnboardingRemindSetting());
  return (
    <Styled>
      <div className="onboarding-cover">
        <iframe
          allowTransparency
          allowFullScreen
          title="Getting Started with Dashboards"
          frameBorder="0"
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          src={DASHBOARDS_ONBOARDING_VIDEO_URL}
          width="100%"
          height="100%"
        />
      </div>
      <div className="onboarding-content">
        <h1>
          <FormattedMessage
            id="dashboards.onboarding.title"
            defaultMessage="Welcome to ScreenCloud Dashboards"
          />
        </h1>
        <span className="onboarding-copy">
          <FormattedMessage
            id="dashboards.onboarding.subtitle"
            defaultMessage="Watch our 'Get Started' video to help get your Dashboards off the ground."
          />
        </span>
        <div className="onboarding-actions">
          <span>
            <PrimaryButton onClick={onClose}>
              <FormattedMessage
                id="dashboards.onboarding.get_started"
                defaultMessage="Let's Get Started"
              />
            </PrimaryButton>
            <Button
              outline
              onClick={() =>
                window.open(DASHBOARDS_ONBOARDING_DOC_URL, "_blank")
              }
            >
              <FormattedMessage
                id="dashboards.onboarding.view_documentation"
                defaultMessage="View Documentation"
              />
            </Button>
          </span>
          <span className="onboarding-reminder">
            <Checkbox
              className="reminder-check-box"
              label="Don't show this again"
              onChange={(_, data) => {
                setDoNotRemind(!!data.checked);
                if (data.checked) {
                  setOnboardingRemindSetting();
                } else {
                  clearOnboardingRemindSetting();
                }
              }}
              checked={doNotRemind}
            />
          </span>
        </div>
      </div>
    </Styled>
  );
};

export default DashboardsOnboardingModal;
