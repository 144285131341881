import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const SmallModalStyled = styled.div`
  padding: 20px;

  h3 {
    margin: 0 0 10px;
    font-weight: normal;
  }
  .row {
    margin: 0 0 12px;
    display: block;
  }
  .label {
    margin: 0 0 10px;
    font-size: 16px;
  }
  .actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .ui.input {
    > input {
      font-weight: 400;
    }
  }
`;

const getOverrideModalContentHeight = (contentHeight) => {
  return `
    .md-small {
      .md-modal.md-show {
        .md-wrapper {
          .md-content{
            height: ${contentHeight};
            overflow: auto;
          }
        }
      }
    }
  `;
};

export interface NavigationStyledProps {
  contentHeight?: string;
  isInvisible?: boolean;
}

export const NavigationStyled = styled.div<NavigationStyledProps>`
  ${({ contentHeight }) =>
    contentHeight && getOverrideModalContentHeight(contentHeight)}

  .md-modal {
    .md-container {
      .md-modal.md-show {
        .md-wrapper {
          .md-content {
            ${({ isInvisible }) =>
              isInvisible &&
              `
              background-color: transparent;
            `}
          }
        }
      }
      .md-overlay {
      }
    }
  }

  .unset-overflow {
    .md-wrapper {
      overflow: unset;
    }
  }

  .screen-item-header {
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #979a9a;

    .column-name {
      display: flex;
      margin-left: 20px;
      width: 50%;
    }

    .column-status {
      display: flex;
      justify-content: flex-start;
      margin-left: 145px;
      width: 50%;
    }
  }

  @media screen and (max-width: ${Theme.break.largeXHD}) {
    .screen-item-header {
      .column-status {
        justify-content: center;
        margin-right: 37px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .screen-item-header {
      .column-status {
        margin-right: 60px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .screen-item-header {
      .column-status {
        margin-right: 22px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .screen-item-header {
      .column-status {
        margin-right: 60px;
        justify-content: flex-end;
      }
    }
  }
`;
