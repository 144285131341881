import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import { QrcodePositionStyledProps } from "../../helpers/qrcodeHelper";

export const Styled = styled.div`
  height: 100%;

  .modal-content > .ui.grid {
  }
  .md-content {
    padding: 20px;
  }
`;

export const CreateLinkStyled = styled.div<QrcodePositionStyledProps>`
  background: ${Theme.color.nearWhite};
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 0 0 ${Theme.default.borderRadiusValue}
    ${Theme.default.borderRadiusValue};

  .link-config {
    width: 50%;
    background: ${Theme.color.white};
    overflow: auto;
    height: calc(100% - 65px);
    border-right: 1px solid ${Theme.color.borderSection};

    h3 {
      font-size: 1em;
      font-weight: normal;
      margin: 0 0 10px;
    }

    label > i,
    h3 > i {
      display: inline-block;
      vertical-align: middle;
      height: 14px;
      background-color: ${Theme.color.grey};
      padding: 0;
      margin-left: 5px;
      width: 14px;

      :hover {
        background-color: ${Theme.color.darkGrey};
      }
    }

    .link-input {
      padding: 20px;

      .info-banner {
        margin-top: 5px;
      }

      .link-error {
        margin-top: 5px;

        span {
          color: ${Theme.color.warning};
          font-size: 0.875em;
        }
      }

      .link-switchprotocol {
        cursor: pointer;
      }

      .ui.checkbox {
        margin: 10px 0 0 0;

        label {
          font-size: 1em;
          padding-left: 22px;
          display: inline-flex;
          align-items: center;
          line-height: 19px;

          :before {
            top: calc(50% - 8px);
            width: 16px;
            height: 16px;
          }
        }

        i {
          position: relative;
          top: 2px;
          left: 4px;
          height: 14px;
          width: 14px;
          background-color: ${Theme.color.grey};

          :hover {
            background-color: ${Theme.color.darkGrey};
          }
        }
      }
    }

    .credential-section {
      border-top: 1px solid ${Theme.color.lightGrey};
      padding: 20px;

      h3 {
        align-items: center;
        display: flex;

        > span:first-child {
          margin-right: 3px;
        }
      }

      .button {
        display: inline-flex;
        font-size: 0.875em;

        i {
          height: 14px;
          width: 14px;
          background-color: ${Theme.color.grey};
        }

        :hover {
          i {
            background-color: ${Theme.color.darkGrey};
          }
        }
      }
    }

    .link-detail {
      border-top: 1px solid ${Theme.color.lightGrey};
      padding: 20px;
      .accordion.ui {
        margin-top: 20px;
        .title {
          font-weight: 700;
          i {
            margin-left: 5px;
            background-color: ${Theme.color.darkGrey};
            transform: rotate(-90deg);
          }
          &.active i {
            margin-left: 5px;
            background-color: ${Theme.color.darkGrey};
            transform: rotate(0deg);
          }
        }
        .content {
          .link-cache-option {
            margin-top: 0px;
          }
        }
      }

      .link-title {
        > div {
          display: flex;
          align-items: baseline;
          .max-char {
            margin-left: 5px;
            color: ${Theme.color.grey};
            font-size: 0.75em;
          }
        }
        .ui.input {
          width: 100%;
        }
      }

      .link-tag {
        margin-top: 20px;
      }

      .link-qrcode,
      .qrcode-position {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        .selection {
          width: 180px;
        }
      }
    }
  }

  .ads {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.ads-responsive {
      padding: 0 20px;

      img {
        width: 80%;
        margin-bottom: 20px;
      }
    }
    .title {
      color: ${Theme.color.darkGrey};
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }
    .description {
      width: 100%;
      max-width: 35em;
      color: ${Theme.color.midGrey};
      font-size: 1em;
      font-weight: 500;
      text-align: center;
      line-height: 1.3125;
      margin-top: 10px;
    }
    .button {
      margin: 20px;

      button {
        margin: 0;
      }
    }
    img {
      box-shadow: none !important;
      margin-top: 20px;
    }
    @media (max-width: ${Theme.break.small}) {
      .title {
        font-size: 1em;
        line-height: 1.3125;
      }
      .description {
        font-size: 0.875em;
        padding: 0;
      }
      .button {
        button {
          margin: 0;
        }
      }
      img {
        margin-top: 0;
      }
    }
  }
  .link-preview {
    &.ads-responsive {
      display: none;
    }
    width: 50%;
    height: calc(100% - 65px);

    .image-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px;
      position: relative;

      :empty {
        :before {
          content: "No preview";
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${Theme.color.darkGrey};
        }
      }

      img {
        max-height: 100%;
        max-width: 100%;
        box-shadow: 0 10px 40px -20px rgba(0, 0, 0, 0.5);
      }

      .loader {
        top: auto;
        padding: 0;
      }

      .unavailable-error {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;

        > span {
          white-space: nowrap;
          margin-bottom: 10px;
          width: 100%;
        }

        button {
        }
      }
    }

    .link .screen {
      width: 100%;
      height: 100%;
    }

    .qrcode {
      position: absolute;
      width: 100%;
      padding-bottom: 48.25%;
      height: 0;
    }

    .qrcode img {
      position: absolute;
      ${(props) => props.qrcoderight && "right: 5.75rem;"}
      ${(props) => props.qrcodeleft && "left: 1rem;"}
      ${(props) => props.qrcodebottom && "bottom: 1rem;"}
      ${(props) => props.qrcodetop && "top: 1rem;"}

      width: 14%;
      ${Theme.default.borderRadius};
    }

    img {
      display: block;
    }
  }

  .link-action {
    background: ${Theme.color.white};
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${Theme.color.lightGrey};
    height: 65px;
    overflow: hidden;
    border-radius: 0 0 ${Theme.default.borderRadiusValue}
      ${Theme.default.borderRadiusValue};
  }

  @media screen and (max-width: ${Theme.break.small}) {
    overflow-y: auto;
    .link-preview {
      width: 100%;
      height: 440px;
      padding: 10px 40px;
      order: 1;

      .image-wrapper {
        padding: 0;
      }
      .qrcode {
        padding-bottom: 56.25%;
      }

      .qrcode img {
        right: 1rem;
      }
    }

    .link-config {
      &.ads-responsive {
        height: calc(100% - 70px);
      }
      height: calc(100% - 265px);
      width: 100%;
      border-left: none;
      border-right: none;
      border-top: 1px solid ${Theme.color.borderSection};
      order: 2;
    }

    .link-action {
      order: 3;
      position: relative;
      overflow: auto;

      .button {
        width: 100%;
      }
    }
  }
`;
