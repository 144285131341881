import styled from "styled-components";

export const Styled = styled.div<{ showDescription?: boolean }>`
  color: var(--color-foreground, #404145);
  font-size: var(--font-size-md, 16px);
  line-height: var(--font-line-height-md, 24px);
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;

  > i {
    background-color: var(--color-grey100);
    width: 16px;
    height: 16px;
  }
  > .description {
    color: var(--color-subtle, #7d7e84);
    font-size: var(--font-size-sm, 14px);
    line-height: var(--font-line-height-sm, 20px);
  }

  ${(props) =>
    props.showDescription &&
    `display: flex;
    flex-direction: column;
    align-items: flex-start;`}
`;
