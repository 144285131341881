import { useCacheInvalidation } from "src/hooks/useCacheInvalidation";
import { Folder } from "src/types.g";

interface OnFolderDeletedProps {
  folder: Pick<Folder, "__typename" | "id">;
}

export const useOnFolderDeleted = () => {
  const {
    invalidateEntityCache,
    invalidateScreenGroupCache,
  } = useCacheInvalidation();

  const onFolderDeleted = ({ folder }: OnFolderDeletedProps) => {
    invalidateEntityCache(folder);
    invalidateScreenGroupCache();
  };

  return {
    onFolderDeleted,
  };
};
