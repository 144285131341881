import styled from "styled-components";

export const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .panel-preview {
    display: inline-grid !important;

    > .screen-preview-wrapper {
      margin: 0 auto;
      padding-bottom: 8%;
    }
  }

  .preview-content {
    width: 100%;
    height: 100%;
    display: inline-grid;
    align-items: center;
    font-family: Lato;
    color: #ffffff;
  }

  span {
    text-align: center;
  }

  .popupMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 16px;

      span {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .sub-text {
      font-size: 16px;
      color: var(--color-white-50);
    }

    .recording-prompt {
      display: flex;
      font-weight: bold;

      .recording-ready {
        margin: auto;
        background: var(--color-white-50);
      }

      .recording-icon {
        margin: auto;
        background: var(--color-red-500, rgba(255, 45, 45, 1));
      }

      .recording-complete {
        background: var(--color-green-500, rgba(42, 181, 86, 1));
        margin: auto;
      }

      .title-text {
        margin: auto;
        font-size: 24px;
        color: #ffffff;
      }
    }
  }

  .btn {
    button {
      background-color: inherit;
      color: #ffffff;
    }
  }
`;
