import {
  Button,
  Checkbox,
  ColorPicker,
  Dropdown,
  Icon,
  Input,
  Label,
  ModalSize,
  Popover,
  Theme,
  ZoneLayout,
} from "@screencloud/screencloud-ui-components";
import { Direction, TransitionType } from "@screencloud/signage-player";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextState } from "../../../../AppContextProvider";
import { AppContext } from "../../../../AppContextProvider/AppContext";
import { FEATURE_FLAGS_ENUM } from "../../../../constants/featureFlag";
import { Transitions } from "../../../../constants/transitions";
import { Layout } from "../../../../types.g";
import LayoutDimensionDialog from "../LayoutDimensionDialog";
import { LayoutSettingWrapper } from "./styles";

export const DURATION_MIN = 500;
export const DURATION_MAX = 2000;
export const DEFAULT_FADE_COLOR = "#000000";
export interface LayoutSettingsProps {
  onToggleHiddenZone: (e, value) => void;
  onToggleTransition: (e, value) => void;
  onLayoutChange: () => void;
  onZoneClick: (zoneId) => void;
  onDimensionChange: (width: number, height: number, merge: boolean) => void;
  onTransitionChange?: (
    type: TransitionType,
    color: string | undefined,
    duration: number,
    direction: Direction | undefined
  ) => void;
  hiddenZoneValue?: boolean;
  selectedZone?: string;
  zones: number;
  layout: Layout | null;
  channelWidth: number;
  channelHeight: number;
  disabled: boolean;
  zoneTransition?: {
    type?: TransitionType;
    direction?: Direction;
    color?: string;
    duration?: number;
  };
  isEditableSharedChannel: boolean;
}

interface State {
  enableTransition: boolean;
  transitionType: TransitionType;
  fadeColor: string | undefined;
  duration: number;
  transitionDirection: Direction | undefined;
  transitionOptions: { key: string; value: string; text: string }[];
}

class LayoutSettings extends React.Component<LayoutSettingsProps, State> {
  public static contextType = AppContext;
  public context: AppContextState;

  constructor(props: LayoutSettingsProps) {
    super(props);

    const transitionType = props.zoneTransition?.type || TransitionType.None;
    this.state = {
      transitionType,
      transitionOptions: Object.entries(Transitions).map(([key, { text }]) => ({
        key,
        value: key,
        text,
      })),
      enableTransition:
        (props.zoneTransition?.type &&
          props.zoneTransition?.type !== TransitionType.None) ||
        false,
      fadeColor: props.zoneTransition?.color
        ? props.zoneTransition?.color
        : DEFAULT_FADE_COLOR,
      duration: props.zoneTransition?.duration
        ? props.zoneTransition?.duration / 1000
        : DURATION_MIN / 1000,
      transitionDirection: props.zoneTransition?.direction
        ? props.zoneTransition?.direction
        : Transitions[transitionType]?.defaultDirectionValue,
    };
  }
  public componentDidUpdate(prevProps: LayoutSettingsProps, prevState: State) {
    if (prevProps !== this.props) {
      const transitionType =
        this.props.zoneTransition?.type || TransitionType.None;
      this.setState({
        transitionType,
        enableTransition:
          (this.props.zoneTransition?.type &&
            this.props.zoneTransition?.type !== TransitionType.None) ||
          false,
        fadeColor: this.props.zoneTransition?.color
          ? this.props.zoneTransition?.color
          : DEFAULT_FADE_COLOR,
        duration: this.props.zoneTransition?.duration
          ? this.props.zoneTransition?.duration / 1000
          : DURATION_MIN / 1000,
        transitionDirection: this.props.zoneTransition?.direction
          ? this.props.zoneTransition?.direction
          : Transitions[transitionType]?.defaultDirectionValue,
      });
    }
  }

  public handleZoneClick = (zoneId) => {
    this.props.onZoneClick(zoneId);
  };

  public handleTransitionToggle = (
    event: React.SyntheticEvent<any>,
    value: any
  ) => {
    const { duration, transitionType } = this.state;

    const transition =
      transitionType !== undefined && transitionType !== TransitionType.None
        ? transitionType
        : TransitionType.Fade;

    this.setState({
      enableTransition: value.checked,
      fadeColor: value.checked ? DEFAULT_FADE_COLOR : undefined,
      transitionType: value.checked ? transition : transitionType,
    });

    if (this.props.onTransitionChange) {
      this.props.onTransitionChange(
        value.checked ? transition : TransitionType.None,
        value.checked ? DEFAULT_FADE_COLOR : undefined,
        duration * 1000,
        "right"
      );
    }
  };

  public handleDurationChange = (event: React.SyntheticEvent<any>, data) => {
    let duration = data.value;

    if (duration > DURATION_MAX / 1000) {
      duration = DURATION_MAX / 1000;
    }

    this.setState({ duration });
  };

  public handleDurationBlur = (event: React.SyntheticEvent<any>) => {
    const {
      duration,
      fadeColor,
      transitionType,
      transitionDirection,
    } = this.state;
    let finalDuration = duration;

    if (duration > DURATION_MAX / 1000) {
      finalDuration = DURATION_MAX / 1000;
    }

    if (duration < DURATION_MIN / 1000) {
      finalDuration = DURATION_MIN / 1000;
    }

    if (this.props.onTransitionChange) {
      this.props.onTransitionChange(
        transitionType,
        fadeColor,
        finalDuration * 1000,
        transitionDirection
      );
    } else {
      this.setState({ duration: finalDuration });
    }
  };

  public handleTransitionChange = (event: React.SyntheticEvent<any>, data) => {
    const { duration, fadeColor } = this.state;
    const color = data.value === TransitionType.Fade ? fadeColor : undefined;
    const transitionType = data.value;
    const transitionDirection =
      Transitions[transitionType]?.defaultDirectionValue;

    this.setState({ transitionType });

    if (this.props.onTransitionChange) {
      this.props.onTransitionChange(
        transitionType,
        color,
        duration * 1000,
        transitionDirection
      );
    }
  };

  public handleDirectionChange = (event: React.SyntheticEvent<any>, data) => {
    const { duration, transitionType } = this.state;
    const transitionDirection = data.value;

    this.setState({ transitionDirection });

    if (this.props.onTransitionChange) {
      this.props.onTransitionChange(
        transitionType,
        DEFAULT_FADE_COLOR,
        duration * 1000,
        transitionDirection
      );
    }
  };

  public handleColorChange = (color) => {
    const { duration, transitionType, transitionDirection } = this.state;

    this.setState({ fadeColor: color });

    if (this.props.onTransitionChange) {
      this.props.onTransitionChange(
        transitionType,
        color,
        duration * 1000,
        transitionDirection
      );
    }
  };

  public renderCustomConfigTransition = (type: string) => {
    const transtion = Transitions[type];
    return (
      <>
        <div className="row">
          <FormattedMessage
            defaultMessage="Duration"
            id="channels.transition.duration"
          />
          <Input
            className="duration-input"
            onChange={this.handleDurationChange}
            onBlur={this.handleDurationBlur}
            label={{ basic: true, content: "s" }}
            labelPosition="right"
            type="number"
            min={DURATION_MIN / 1000}
            max={DURATION_MAX / 1000}
            step="0.1"
            value={this.state.duration}
            onKeyDown={(event) => event.key === "e" && event.preventDefault()}
          />
        </div>

        {transtion.directions.length > 0 && (
          <div className="row">
            <FormattedMessage
              defaultMessage="Direction"
              id="channels.transition.direction"
            />
            <Dropdown
              className="selection"
              fluid
              options={transtion.directions}
              value={this.state.transitionDirection}
              onChange={this.handleDirectionChange}
            />
          </div>
        )}

        {transtion.isColor && (
          <div className="row">
            <FormattedMessage
              defaultMessage="Color"
              id="channels.transition.color"
            />
            <ColorPicker
              className="color-picker"
              inlineInput
              color={this.state.fadeColor}
              onComplete={this.handleColorChange}
              disabledInlineInput
              upward
            />
          </div>
        )}
      </>
    );
  };

  public render() {
    const {
      onLayoutChange,
      layout,
      selectedZone,
      channelHeight,
      channelWidth,
      disabled,
      zoneTransition,
      isEditableSharedChannel,
    } = this.props;
    const fitPreviewScale =
      200 / (channelWidth > channelHeight ? channelWidth : channelHeight);
    const channelPreviewWidth = Math.ceil(channelWidth * fitPreviewScale);
    const channelPreviewHeight = Math.ceil(channelHeight * fitPreviewScale);
    const selectedZoneIndex =
      parseInt(selectedZone?.replace("zone", "") as string) - 1;
    const { transitionOptions } = this.state;
    const canUpdateChannel = this.context.currentPermissions.validateCurrentSpace(
      "channel",
      "update"
    );
    return (
      <LayoutSettingWrapper>
        {layout ? (
          <>
            <div
              className="layout-config"
              data-testid="advanced-layout-wrapper"
            >
              <div
                className="layout-dimension"
                style={{ width: canUpdateChannel ? "50%" : "100%" }}
              >
                <span className="text-section">
                  <FormattedMessage
                    defaultMessage="Dimension"
                    id="channels.dimension"
                  />
                </span>
                <span className="dimension">
                  {channelWidth} × {channelHeight}
                </span>
              </div>

              {!isEditableSharedChannel && canUpdateChannel && (
                <div className="layout-dimension-settings">
                  <Button
                    data-testid="change-dimension-button"
                    mini
                    onClick={this.onChangeLayout}
                    disabled={disabled}
                  >
                    <span>Change</span>
                  </Button>
                </div>
              )}
              <hr className="divider" />
              <div className="layout-preview">
                <div
                  className="channel-preview"
                  style={{
                    width: channelPreviewWidth,
                    height: channelPreviewHeight,
                  }}
                >
                  <ZoneLayout
                    name={layout.name}
                    config={layout.config}
                    activeZone={selectedZone!}
                    onClick={this.handleZoneClick}
                    width={
                      layout.config.is_flexible
                        ? channelWidth
                        : layout.config.width
                    }
                    height={
                      layout.config.is_flexible
                        ? channelHeight
                        : layout.config.height
                    }
                    maxWidth={channelPreviewWidth}
                    maxHeight={channelPreviewHeight}
                    channelHeight={channelHeight}
                    channelWidth={channelWidth}
                    isFlexible={layout.config.is_flexible}
                    isScalable={layout.config.is_scalable}
                  />
                </div>
                <Label
                  data-testid="background-audio-button"
                  onClick={() => this.handleZoneClick("hidden")}
                  customColor={
                    this.props.selectedZone === "hidden"
                      ? Theme.color.darkGrey
                      : undefined
                  }
                  circular
                  size="mini"
                >
                  <FormattedMessage
                    defaultMessage="BACKGROUND AUDIO"
                    id="channels.background_audio"
                  />
                </Label>
                {!isEditableSharedChannel && canUpdateChannel && (
                  <Button
                    data-testid="edit-layout-button"
                    onClick={onLayoutChange}
                    disabled={disabled}
                  >
                    <FormattedMessage
                      defaultMessage="Edit Layout"
                      id="channels.edit_layout"
                    />
                  </Button>
                )}
              </div>
            </div>
            <hr className="divider" />
          </>
        ) : null}
        <>
          <div className="subheader">
            <FormattedMessage id="subheader.label.zone" defaultMessage="Zone" />
          </div>
          <div className="zone-label">
            {selectedZone === "hidden" ? (
              <h3>
                <div className="icon">
                  <Icon name="audio-on" />
                </div>
                <FormattedMessage
                  id="channels.audio_background"
                  defaultMessage="Background Audio"
                />
              </h3>
            ) : (
              <h3>
                <Label circular>{selectedZone?.replace("zone", "")}</Label>
                <span>{layout?.config?.zones[selectedZoneIndex]?.name}</span>
              </h3>
            )}
          </div>

          {this.context.shouldShowFeature(
            FEATURE_FLAGS_ENUM.CHANNEL_TRANSITION
          ) && selectedZone === "hidden" ? (
            <div className="note">
              <FormattedMessage
                id="channels.audio_background_description"
                defaultMessage="This zone allows for audio playing in the background. Adding video or any content with visuals in this zone, will play the audio-only."
              />
            </div>
          ) : (
            !isEditableSharedChannel && (
              <div className="component">
                <div className="option">
                  <h3>
                    <FormattedMessage
                      defaultMessage="Enable Transition"
                      id="channels.enable_transition"
                    />
                    <Popover
                      inverted
                      trigger={<Icon name="help" />}
                      content={
                        <FormattedMessage
                          defaultMessage="Turn on and manage transitions between content items for your channel"
                          id="channels.transitions.info_message"
                        />
                      }
                      position="top center"
                    />
                  </h3>
                  <Checkbox
                    onChange={this.handleTransitionToggle}
                    checked={
                      !!zoneTransition && zoneTransition?.type !== "none"
                    }
                    toggle
                  />
                </div>
                {!!zoneTransition && zoneTransition?.type !== "none" && (
                  <>
                    <Dropdown
                      selection
                      fluid
                      trigger={
                        <div className="trigger">
                          {/* todo: <Icon name='splash' />*/}{" "}
                          {zoneTransition.type}
                        </div>
                      }
                      options={transitionOptions}
                      value={zoneTransition.type}
                      onChange={this.handleTransitionChange}
                    />
                    <div className="custom-transition-pad">
                      {this.renderCustomConfigTransition(
                        zoneTransition.type as TransitionType
                      )}
                    </div>
                  </>
                )}
              </div>
            )
          )}
        </>
      </LayoutSettingWrapper>
    );
  }

  private onChangeLayout = () => {
    const { channelWidth, channelHeight } = this.props;
    this.context.modal.openModal(
      <LayoutDimensionDialog
        layout={this.props.layout}
        presetWidth={channelWidth}
        presetHeight={channelHeight}
        onSave={(width: number, height: number, merge: boolean) => {
          this.props.onDimensionChange(width, height, merge);
          this.context.modal.closeModals();
        }}
        onCancel={() => this.context.modal.closeModals()}
      />,
      <FormattedMessage
        defaultMessage="Channel Size"
        id="channels.channel_size"
      />,
      {
        opts: {
          disableTitle: false,
          overflow: true,
          size: ModalSize.SMALL,
        },
      }
    );
  };
}

export default LayoutSettings;
