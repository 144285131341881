import { FormattedMessage } from "react-intl";
import { Button, Theme } from "@screencloud/screencloud-ui-components";
import { PrimaryButton } from "src/helpers/whiteLabel";
import { useAppContext } from "src/hooks/useAppContext";
import { ScreenCloudContactUrl } from "../../constants/url";
import { useDeleteDuplicateJob } from "./useDeleteDuplicateJob";
import { ImportJob, isFailed } from "src/domains/importJob/importJob";
import { SnapshotType } from "src/types.g";

export interface UseOpenDuplicationModal {
  openInprogressModalDetail: (callback?: () => void) => void;
  openFailedModalDetail: (jobId: string, snapshotType: string) => void;
  openPendingImportJob: (importJob: ImportJob) => void;
}

const IN_PROGRESS_MESSAGE: Map<SnapshotType, React.ReactElement> = new Map<
  SnapshotType,
  React.ReactElement
>([
  [
    SnapshotType.Space,
    <FormattedMessage
      id="duplication_modal.in_progress_description"
      defaultMessage="This may take a few minutes depending on the size of the space. You can close this pop up and come back again. If you experience any issues, please contact our customer support."
    />,
  ],
  [
    SnapshotType.Channel,
    <FormattedMessage
      id="duplication_modal.channel.in_progress_description"
      defaultMessage="This may take a few minutes depending on the size of the channel. You can close this pop up and come back again. If you experience any issues, please contact our customer support."
    />,
  ],
  [
    SnapshotType.Playlist,
    <FormattedMessage
      id="duplication_modal.playlist.in_progress_description"
      defaultMessage="This may take a few minutes depending on the size of the playlist. You can close this pop up and come back again. If you experience any issues, please contact our customer support."
    />,
  ],
]);

const REMOVE_BUTTON_MESSAGE: Map<SnapshotType, React.ReactElement> = new Map<
  SnapshotType,
  React.ReactElement
>([
  [
    SnapshotType.Space,
    <FormattedMessage
      id="admin.space.duplicate_space_remove"
      defaultMessage="Remove Space"
    />,
  ],
  [
    SnapshotType.Channel,
    <FormattedMessage
      id="channel.duplicate_channel_remove"
      defaultMessage="Remove Channel"
    />,
  ],
  [
    SnapshotType.Playlist,
    <FormattedMessage
      id="playlist.duplicate_playlist_remove"
      defaultMessage="Remove Playlist"
    />,
  ],
]);

export function useOpenDuplicationModal(
  snapshotType: SnapshotType = SnapshotType.Space
): UseOpenDuplicationModal {
  const context = useAppContext();
  const { deleteDuplicateJob } = useDeleteDuplicateJob();
  const handleContactSupport = () => {
    window.open(ScreenCloudContactUrl, "_blank");
    context.modal.closeConfirm();
  };
  const handleRemoveSnapshotJob = (jobId: string) => {
    deleteDuplicateJob(jobId);
    context.modal.closeConfirm();
  };
  const openPendingImportJob = (importJob: ImportJob) => {
    if (isFailed(importJob)) {
      openFailedModalDetail(importJob.jobId!);
    } else {
      openInprogressModalDetail();
    }
  };

  const openFailedModalDetail = (jobId: string) => {
    context.modal.confirm(
      <>
        <h2 style={{ color: Theme.color.red }}>
          <FormattedMessage
            id="duplication_modal.failed_title"
            defaultMessage="Duplication failed."
          />
        </h2>
        <p>
          <FormattedMessage
            id="duplication_modal.failed_description"
            defaultMessage="Something went wrong during the duplication. If you need further assistant, please contact our customer support."
          />
        </p>
      </>,
      {
        customButton: (
          <>
            <Button
              onClick={() => {
                handleContactSupport();
              }}
            >
              <FormattedMessage
                id="duplication_modal.contact_support"
                defaultMessage="Contact Support"
              />
            </Button>
            <Button
              danger
              onClick={() => {
                handleRemoveSnapshotJob(jobId);
              }}
            >
              {REMOVE_BUTTON_MESSAGE.get(snapshotType) || (
                <FormattedMessage
                  id="ui_component.common.label.remove"
                  defaultMessage="Remove"
                />
              )}
            </Button>
          </>
        ),
      }
    );
  };

  const openInprogressModalDetail = (callback?: () => void) => {
    context.modal.confirm(
      <>
        <h2>
          <FormattedMessage
            id="duplication_modal.in_progress"
            defaultMessage="Duplication in progress..."
          />
        </h2>
        <p>{IN_PROGRESS_MESSAGE.get(snapshotType)}</p>
      </>,
      {
        customButton: (
          <PrimaryButton
            onClick={() => {
              context.modal.closeConfirm();
              if (callback) {
                callback();
              }
            }}
          >
            <FormattedMessage
              id="duplication_modal.got_it"
              defaultMessage="Got It"
            />
          </PrimaryButton>
        ),
      }
    );
  };

  return {
    openInprogressModalDetail,
    openFailedModalDetail,
    openPendingImportJob,
  };
}
