import { useGetTotalPlaylistsQuery } from "src/types.g";
import { useAppContext } from "./useAppContext";

export function useAbleToShowAddToPlaylist(skip?: boolean) {
  const context = useAppContext();
  const { data } = useGetTotalPlaylistsQuery({
    variables: {
      spaceId: context.currentSpace?.id,
    },
    fetchPolicy: "cache-first",
    skip,
  });

  const totalPlaylists =
    data?.spaceById?.publishedPlaylistsBySpaceId.totalCount ?? 0;

  return !!(
    totalPlaylists > 0 &&
    context.currentPermissions.validateCurrentSpace("playlist", "update")
  );
}
