import { uniqBy } from "lodash";

import { fieldsPolicyType } from "./apolloClient";

const appInstallPolicy = {
  merge(existing, incoming, options) {
    const existingNodes = existing ? [...existing.nodes] : [];
    const incomingNodes = incoming?.nodes ? [...incoming.nodes] : [];

    const nodes =
      incomingNodes.length < existingNodes.length &&
      existing.totalCount > incoming.totalCount
        ? incomingNodes
        : uniqBy([...existingNodes, ...incomingNodes], "__ref");

    // fix pageInfo for reorder and fetchmore
    const pageInfo =
      !options.variables?.endCursor && existing?.pageInfo
        ? existing.pageInfo
        : incoming?.pageInfo;
    return {
      ...options.mergeObjects(existing, incoming),
      nodes,
      pageInfo,
    };
  },
};

export const appinstallField: fieldsPolicyType = {
  availableAppInstallsBySpaceId: {
    keyArgs: ["spaceId"],
    ...appInstallPolicy,
  },
};

export const appPickerField: fieldsPolicyType = {
  appInstallsByOrgId: {
    keyArgs: ["orgId", "spaceId"],
    ...appInstallPolicy,
  },
};
