import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import { ContainerProps } from "./index";

export const Styled = styled.div<ContainerProps>`
  background-color: var(--color-gray-50);
  max-width: 100%;
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 0 60px 70px;

  @media screen and (max-width: ${Theme.break.largeHD}) {
    padding: 0 40px 70px;
  }

  ${(props: ContainerProps) =>
    props.sidebar &&
    `
      width: calc(100% - ${Theme.sidebarRight.maxWidth});
      max-width: 100%;

      & > div {
        text-align: left;
        margin: 0 auto;
      }
  `}

  ${(props: ContainerProps) =>
    props.smallSidebar &&
    `
    width: calc(100% - ${Theme.sidebarRight.smallMaxWidth});
  `}

  @media screen and (max-width: ${Theme.break.small}) {
    padding: 0 20px;
    width: 100%;
    min-height: calc(100vh - 114px);
    display: flex;
    flex-direction: column;

    &.container {
      height: calc(100vh - 179px);
      min-height: 0;
      overflow: auto;
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
  }

  ${(props) => props.noPadding && `padding: 0;`}
`;
