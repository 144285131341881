import { AppContextState } from "../AppContextProvider";
import { Folder } from "../types.g";
import {
  canBeShared,
  isOwner,
  isShared,
  shouldShowShareIcon,
} from "./shareableHelper";
import iconFolderImg from "../images/media.svg";
import iconSharedFolderImg from "../images/media-shared.svg";
import { isOrgWidePath } from "src/utils/orgWideFeature";

export const iconFolder = iconFolderImg;
export const iconSharedFolder = iconSharedFolderImg;

export function shouldShowCreateNewShareFolderButton(
  context: AppContextState,
  isInRootFolder: boolean
) {
  return (
    isInRootFolder &&
    canBeShared({
      context,
      shareable: { __typename: "Folder", spaceId: context.currentSpace?.id },
    })
  );
}

export function getFolderImage({
  folder,
  context,
}: {
  folder: Pick<Folder, "isShared" | "isSharedAll" | "spaceId">;
  context: AppContextState;
}) {
  if (isOrgWidePath()) {
    return iconFolder;
  }

  return !isOwner({ spaceId: folder.spaceId, context })
    ? iconSharedFolder
    : iconFolder;
}

/**
 * Org -> Space -> Library -> Root Folder -> (Files / Folders)
 * This is refer to a Root folder right under the Library
 * also name of root libratu is _default_folder
 *
 * @param folder
 */
export function isRootFolder(folder: Pick<Folder, "parentId">) {
  return !folder?.parentId;
}

export function getRootFolderId(context: AppContextState): string {
  return context?.currentSpace?.rootFolderId;
}

/**
 * This will check if this is root folder in current space
 */
export function isRootFolderInCurrentSpace({
  folderId,
  context,
}: {
  folderId: string;
  context: AppContextState;
}) {
  return folderId === getRootFolderId(context);
}

/**
 * We does not show root folder in search result
 * search result might fetch root folder from different space
 */
export function isShowInSearchResult(folder: Pick<Folder, "parentId">) {
  return !isRootFolder(folder);
}

/**
 * Check if this folder is the first child of root folder
 * This is useful as we can only share first child of root folder
 * first child is the top most level folder you can see in media list page
 *
 * @param folder
 * @param rootFolderId
 */
export function isFirstChild({
  folder,
  context,
}: {
  folder: Pick<Folder, "parentId">;
  context: AppContextState;
}) {
  return folder.parentId === getRootFolderId(context);
}

export function canFolderBeShared({
  folder,
  context,
}: {
  folder: Pick<Folder, "parentId" | "spaceId" | "__typename">;
  context: AppContextState;
}) {
  return (
    isFirstChild({ folder, context }) &&
    canBeShared({ context, shareable: folder })
  );
}

export function shouldFolderShowShareButton({
  folder,
  context,
}: {
  folder: Pick<Folder, "parentId" | "spaceId" | "__typename">;
  context: AppContextState;
}) {
  return canFolderBeShared({ context, folder });
}

export function shouldShowShareFolderIcon({
  folder,
  context,
}: {
  folder: Pick<
    Folder,
    "isShared" | "isSharedAll" | "parentId" | "spaceId" | "__typename"
  >;
  context: AppContextState;
}) {
  return (
    (isFirstChild({ folder, context }) &&
      shouldShowShareIcon({ context, shareable: folder })) ||
    isShared(folder)
  ); // also show first level of shared from other spaces (only first level can be shared anyway)
}

export function getTotalFolders(folder: Folder) {
  return folder.foldersByParentId?.totalCount ?? 0;
}

export function getTotalFiles(folder: Folder) {
  return folder.filesByFolderId?.totalCount ?? 0;
}

export function getTotalCount(folder: Folder) {
  return getTotalFolders(folder) + getTotalFiles(folder);
}
