import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  .loading-message {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .ui.loader {
      margin-right: 5px;
    }
  }
  &.media-content.link {
    .content-header {
      h3 {
        margin: 0;
        line-height: 36px;
      }
    }

    .container {
      .infinite-scroll-component {
        width: 100%;
      }

      .media-item {
        cursor: pointer;

        .media-core {
          .media-starred {
            width: 10%;
          }
        }
      }

      .media-item-header {
        .media-core {
          .media-url {
            display: none;
          }

          .media-type {
            width: 20%;
          }

          @media screen and (max-width: ${Theme.break.mediumAlt}) {
            .media-alpha {
              width: 60%;
            }

            .media-tags {
              display: none;
            }
          }

          @media screen and (max-width: ${Theme.break.small}) {
            .media-alpha {
              width: calc(100% - 80px);
            }

            .media-type {
              display: none;
            }

            .media-starred {
              width: 80px;
            }
          }
        }
      }
    }
  }
`;

export interface LinkPickerItemProps {
  selected: boolean;
  className?: string;
}

export const LinkPickerItem = styled.div<LinkPickerItemProps>`
  border: 1px solid transparent;
  border-top-color: ${Theme.color.silver};
  display: flex;
  padding: 10px 0;

  .media-core {
    width: calc(100% - 60px);
    display: flex;
    align-items: center;

    [class^="media-"] {
      text-align: center;

      span {
        color: ${Theme.color.grey};
        font-size: 0.875em;
        font-style: italic;

        span {
          font-size: initial;
        }
      }
    }

    .media-alpha {
      width: 40%;
      display: flex;
      flex: 0 0 auto;
      cursor: pointer;
      ${Theme.default.borderRadius};

      .thumbnail-preview {
        width: 60px;
        height: 60px;
        position: relative;
        margin: 0 10px;

        .wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          ${Theme.default.borderRadius};
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;

          img {
            width: auto;
            max-height: 50px;
            max-width: 50px;
            border-radius: 2px;
          }
        }

        :hover {
          background-color: transparent;
        }

        .thumbnail {
          width: 100%;
          height: 100%;
        }
      }

      .media-title {
        width: calc(100% - 80px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        * {
          text-align: left;
        }

        h3 {
          line-height: 19px;
          padding: 0;
          margin-bottom: 5px;
          font-weight: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 1px solid transparent;

          :hover {
            background-color: transparent;
          }
        }

        div {
          display: flex;

          span {
            line-height: 14px;
            font-size: 14px;
            white-space: nowrap;
          }

          .media-item__url {
            display: none;
          }
        }
      }

      :hover {
        background: transparent;
      }
    }

    .media-starred {
      i {
        background-color: var(--color-primary-background);
      }
    }

    .media-url,
    .media-casting {
      display: none;
    }

    .media-starred,
    .media-type,
    .media-tags {
      display: block;
      width: 20%;
    }
  }

  .media-checked {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      opacity: 0;
      background-color: ${Theme.color.darkGrey};
    }
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.01);

    .media-core {
      .media-starred {
        .toggle.star {
          opacity: 0;

          &.active {
            opacity: 1;
          }

          :hover {
            background: transparent;
          }
        }
      }
    }

    .media-checked {
      i {
        opacity: 0.2;
      }
    }
  }

  ${(props: LinkPickerItemProps) =>
    props.selected &&
    `
    background-color: ${Theme.color.silver};
    box-shadow: none;
    border-top-color: ${Theme.color.lightGrey};

    .media-checked {
      i {
        opacity: 1;
      }
    }
  `};

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .media-core {
      .media-alpha {
        width: 60%;
      }
      .media-url,
      .media-tags {
        display: none;
      }

      .media-starred {
        width: 20%;
      }

      .media-type {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .media-core {
      .media-tags {
        display: none;
      }

      .media-alpha {
        width: calc(100% - 80px);
      }

      .media-starred {
        width: 80px;
      }
    }
  }
`;
