export const TAGS_LIST = [
  { text: "Chromecast", value: "chromecast" },
  { text: "Lobby", value: "lobby" },
  { text: "London", value: "london" },
  { text: "Front Desk", value: "front_desk" },
  { text: "Portrait", value: "portrait" },
  { text: "Landscape", value: "landscape" },
];

export const MEDIA_LOGS = [
  {
    action: "is playing",
    content: "",
    message: "nothing",
    sectionname: "Front Desk 1",
    timestamp: 1520233606,
    type: "",
    username: "pooh",
  },
  {
    action: "is clear",
    content: "",
    message: "cached",
    sectionname: "Front Desk 1",
    timestamp: 1520233606,
    type: "",
    username: "pooh",
  },
  {
    action: "is playing",
    content: "123467",
    message: "",
    sectionname: "Front Desk 1",
    timestamp: 1520233606,
    type: "image",
    username: "pooh",
  },
  {
    action: "is playing",
    content: "Playlist 1",
    message: "",
    sectionname: "Front Desk 1",
    timestamp: 1520233606,
    type: "",
    username: "pooh",
  },
  {
    action: "is playing",
    content: "Grand Ballroom",
    message: "",
    sectionname: "Front Desk 1",
    timestamp: 1520233606,
    type: "channel",
    username: "pooh",
  },
  {
    action: "renamed to",
    content: "",
    message: "Front Desk 1",
    sectionname: "New Screen",
    timestamp: 1520233606,
    type: "",
    username: "pooh",
  },
  {
    action: "created",
    content: "",
    message: "",
    sectionname: "New Screen",
    timestamp: 1520233606,
    type: "",
    username: "pooh",
  },
];

export const MEDIA_TAGS = ["chromecast", "lobby"];
