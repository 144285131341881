import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  justify-content: flex-end;

  .rule-icon-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4px;
    direction: rtl;
  }
  .rule-icon {
    height: 27px;
    width: 27px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
    ${Theme.default.borderRadius};
  }
`;
