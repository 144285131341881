import { Theme } from "@screencloud/screencloud-ui-components";
import { WhiteLabelStyles } from "src/helpers/whiteLabel";
import styled from "styled-components";

export const Styled = styled.div<WhiteLabelStyles>`
  display: flex;
  padding: 15px 14px;
  align-items: flex-start;
  align-self: stretch;

  .container {
    position: relative;
    display: flex;
    padding: 12px 6px 12px 16px;
    align-items: flex-start;
    gap: 19px;
    flex: 1 0 0;
    cursor: pointer;
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--color-white-30, rgba(255, 255, 255, 0.3));
    background: var(--color-black-20, rgba(0, 0, 0, 0.2));
    box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.1);
    color: ${(props) => props.textColor ?? Theme.color.white};
    transition: all 0.15s ease-out;
    overflow: hidden;

    .label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
    }

    i[name="rocket"] {
      width: 22px;
      height: 22px;
    }

    :hover {
      box-shadow: 0px 0px 4px 8px rgba(255, 255, 255, 0.05);
      border: 1px solid var(--color-white-40, rgba(255, 255, 255, 0.4));
      i[name="rocket"] {
        @keyframes rocket {
          0% {
            transform: rotate(0);
            top: 0;
            opacity: 1;
          }
          12% {
            transform: rotate(-40deg);
            top: 0;
            opacity: 1;
          }
          60% {
            transform: rotate(-40deg);
            top: 0;
            opacity: 1;
          }
          75% {
            transform: rotate(-40deg) scale(0.5);
            opacity: 0;
            top: -50px;
          }
          76% {
            top: 80px;
          }
          90% {
            transform: rotate(-40deg) scale(1.2);
            top: 0;
            opacity: 1;
            scale: 1;
          }
          100% {
            transform: rotate(-40deg) scale(1);
          }
        }

        animation: rocket 1.8s ease-out 1 normal forwards;
        position: relative;
      }
    }
  }

  .title {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  .description {
    opacity: 0.5;
    font-size: 12px;
    line-height: 16px;
  }

  i {
    background-color: ${(props) => props.textColor ?? Theme.color.white};
  }

  .button {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;

    i {
      background-color: ${(props) => props.textColor ?? Theme.color.white};
      opacity: 0.5;
    }

    :hover,
    :active,
    :focus {
      background: transparent;
      border: none;

      i {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    padding: 15px 10px;

    .container {
      padding: 0;
      height: 50px;
      width: 100%;
      align-items: center;
      justify-content: center;

      .label {
        display: none;
      }
    }

    .button {
      display: none;
    }
  }
`;
