/**
 * Converts a UNIX timestamp to a JavaScript Date
 */
export function fromUnix(timestamp: number): Date {
  return new Date(timestamp * 1000);
}

/**
 * Return a date in a US format
 *
 * @example - May 19, 2022
 */
export function formatted(date: Date): string {
  return `${month(date)} ${date.getDate()}, ${date.getFullYear()}`;
}

/**
 * Return the month string from a date
 *
 * @example - January
 */
export function month(date: Date): string {
  return date.toLocaleString("default", { month: "long" });
}
