import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  overflow: auto;
  height: 100%;
  @media screen and (max-width: ${Theme.break.small}) {
    padding-bottom: 65px;
  }

  &.wrapper {
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    &.wrapper {
      flex-direction: column;
    }
  }
`;
