import { AppContextState } from "src/AppContextProvider";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";

/**
 * allow only internal user (for now)
 * sso feature flag should turn on
 */
export const canAccessSSOTab = (context: AppContextState): boolean => {
  return !!(
    context.currentPermissions.validateOrg("org", "update") &&
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.SSO)
  );
};
