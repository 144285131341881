import styled from "styled-components";

export const StyledMediaList = styled.div`
  .empty {
    display: none;
  }

  height: 100%;
  display: flex;
  flex-direction: column;

  .breadcrumb-sidebar {
    position: relative;
    width: calc(100% - 30px);
  }

  .media-breadcrumb-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
    padding: 0 20px 10px;
    gap: var(--spacing-xs);

    .ui.breadcrumb {
      display: flex;
      align-items: center;
      .ui.dropdown {
        .menu {
          .item {
            font-size: 14px;
          }
        }
      }

      .button {
        margin-left: auto;
      }
    }
  }

  .media-folder {
    > .grid {
      > .row {
        padding: 0 !important;
      }
    }
  }

  .media-file {
    > .grid {
      > .row {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }
  .layout-container {
    position: relative;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 55px;
    flex: 1 0 0;

    .mediatab-loader {
      height: 100%;
      align-items: center;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .layout-list {
    height: 100%;
    padding: 0 20px;
  }
  .ui.layout-list {
    > .row {
      width: calc(100% + 40px) !important;
      margin-left: -20px;
      margin-right: -20px;

      .media-item {
        > .grid {
          margin: 0 !important;

          > .row {
            .media-alpha {
              width: 100%;

              .media-breakdown {
                width: calc(100% - 75px);
              }
            }

            .media-details,
            .media-actions {
              display: none;
            }
          }
        }

        &.selected {
          box-shadow: none;
        }
      }
    }

    .empty-media {
      width: 100%;
      min-height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;

      p {
        font-size: 0.875em;
        color: rgba(31, 32, 39, 0.5);
      }
    }
  }
  .media-item .media-core {
    width: 100%;
    .media-alpha {
      .thumbnail-preview {
        width: 40px;
        height: 40px;
      }
    }
  }
`;
