import { useLinkList } from "./useLinkList";
import { useSearchLink } from "./useSearchLink";
import { Exact, Link, LinksOrderBy, Maybe, PageInfo } from "../types.g";

interface Props {
  query: string;
  linksOrderBy?: LinksOrderBy[];
}

export interface UseLinkListOrSearchLink {
  links: Link[];
  hasNextPage: boolean;
  pageInfo: Pick<PageInfo, "endCursor" | "hasNextPage">;
  loadMore: () => void;
  loading: boolean;
  refetch: (
    variables?: Partial<
      Exact<{ spaceId: any; endCursor: any; orderBy: Maybe<LinksOrderBy[]> }>
    >
  ) => void;
  isFirstTimeAlreadyLoaded: boolean;
  renderFetchMoreButton: React.ReactNode;
}

export function useLinkListOrSearchLink(props: Props) {
  const { query, linksOrderBy } = props;
  const isSearchMode = Boolean(query);
  const linklistProps = useLinkList(linksOrderBy);
  const linkListSearchResultProps = useSearchLink({ query });
  return isSearchMode ? linkListSearchResultProps : linklistProps;
}
