import { DataValue, withApollo } from "@apollo/client/react/hoc";
import {
  AllAppsQuery,
  CreateSpaceMutationFn,
  InstallAppForSpaceMutationFn,
  useCreateSpaceMutation,
} from "../../../types.g";
import { compose } from "../../../utils/compose";

export interface ApolloProps {
  createSpace: CreateSpaceMutationFn;
  allApps: DataValue<AllAppsQuery>;
  installAppForSpace: InstallAppForSpaceMutationFn;
}

export const WithData = compose(withApollo, (Component) => (props) => {
  const [createSpace] = useCreateSpaceMutation();
  return <Component {...props} createSpace={createSpace} />;
});
