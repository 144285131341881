import styled from "styled-components";

interface OrgGlyphStyledProps {
  borderRadius?: number;
  height?: number;
  width?: number;
  removeBackgroundColor?: boolean;
}
export const Styled = styled.div<OrgGlyphStyledProps>((props) => {
  return `
    .org-glyph {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--color-primary-background);
      color: var(--color-primary-foreground);
    }
    .org-glyph,
    .org-glyph img {
      width: ${props.width ?? 72}px;
      height: ${props.height ?? 72}px;

      border-radius: ${props.borderRadius ?? 16}px;
    }
  `;
});
