import TagManager from "react-gtm-module";
import localStore from "store";
import { urls } from "../constants/constants";
import { SubscriptionStatus } from "../billing/types";
import { useAppContext } from "../hooks/useAppContext";
import { Maybe, User } from "../types.g";

const getFeature = (section) => {
  const features = {
    DASHBOARDS: "Dashboards",
    PERMISSIONS: "Permissions",
    AUDIT_LOGS: "Logs",
    CUSTOM_ROLES: "Permissions",
    SSO: "SSO",
    EMBED_CHANNEL: "Embed_Channel",
    SPACE_BILLING: "SpaceBilling",
  };

  return features[section];
};

export const openNewTab = ({
  section,
  user,
  customUrl,
  inTrial,
}: {
  section: string;
  user: Maybe<Partial<User>>;
  customUrl?: string;
  inTrial?: boolean;
}) => {
  const { givenName, email, orgByOrgId } = user!;
  const orgName = orgByOrgId?.name;
  const ordId = orgByOrgId?.id;
  const trialOrActiveSubscription = inTrial ? "Trial" : "Active Subscription";
  const url = `${urls.CONTACT_SALES}?currently_in=${trialOrActiveSubscription}&firstname=${givenName}&email=${email}&company=${orgName}&customer_id__c=${ordId}`;
  const tagManagerData = {
    dataLayer: {
      event: "studio_ad_click",
      clicked_ad: section,
    },
  };
  if (customUrl) {
    window.open(customUrl, "_blank");
  } else {
    if (section === "") {
      tagManagerData.dataLayer.clicked_ad = "upgrade";
      window.open(`${url}#contactform`, "_blank");
    } else {
      window.open(
        `${url}&feature_request__in_app_=${getFeature(
          section.toUpperCase()
        )}#contactform`,
        "_blank"
      );
    }
  }
  TagManager.dataLayer(tagManagerData);
};

export const closeBanner = (section: string) => {
  localStore.set(section, false);
};

export const getBannerStatus = (section: string) => {
  return localStore.get(section, false);
};

export const getContactFormURL = (
  subscriptionStatus: Maybe<string> | undefined,
  section: string
) => {
  const context = useAppContext();
  const status =
    subscriptionStatus === SubscriptionStatus.InTrial
      ? "Trial"
      : subscriptionStatus === SubscriptionStatus.Active
      ? "Active Subscription"
      : "";

  const contactFormUrl = `https://get.screencloud.com/feature-request?currently_in=${status}&email=${context.currentUser?.email}&company=${context.currentUser?.orgByOrgId?.name}&firstname=${context.currentUser?.givenName}&feature_request__in_app_=${section}`;
  return contactFormUrl;
};
