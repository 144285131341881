import {
  getTheUsedPlaceObject,
  UsedPlace,
} from "../../helpers/deleteStuffHelper";
import { FileAssociationsFragment } from "../../types.g";
import { EntityType, UsageEntities } from "./types";

export const getUsageEntities = (
  entityKeys: string[],
  associations: any
): UsageEntities => {
  let playlists = [];
  let channels = [];

  if (entityKeys.includes(EntityType.Playlist)) {
    playlists = associations[EntityType.Playlist].map((i: any) => {
      return {
        id: i.id,
        type: EntityType.Playlist.toLowerCase(),
        name: i.name,
        color: i.color,
      };
    });
  }

  if (entityKeys.includes(EntityType.Channel)) {
    channels = associations[EntityType.Channel].map((i: any) => {
      return {
        id: i.id,
        type: EntityType.Channel.toLowerCase(),
        name: i.name,
        coverData: i.coverData,
        coverImageSource: i.fileByCoverImageId?.source,
      };
    });
  }

  return {
    playlists,
    channels,
  };
};

export const filterAssociations = (
  fileAssociations: FileAssociationsFragment[]
): UsedPlace => {
  const groupByPosition = getTheUsedPlaceObject(fileAssociations);
  return groupByPosition;
};
