import * as _ from "lodash";
import { debounce } from "../../utils/debounce";

export type ChangeSubscriber<T> = (value: T, prevValue?: T) => void;

export interface SubscribableConfig<T> {
  initialPrevValue?: T;
  valueFn: () => T;
}

export class Subscribable<T extends object> {
  protected subscribers: ChangeSubscriber<T>[] = [];
  protected prevValue?: T;
  protected valueFn: () => T;

  constructor({ initialPrevValue, valueFn }: SubscribableConfig<T>) {
    this.prevValue = initialPrevValue;
    this.valueFn = valueFn;
  }

  public subscribe(callback: ChangeSubscriber<T>): number {
    if (!this.subscribers.includes(callback)) {
      this.subscribers.push(callback);
    }
    return this.subscribers.indexOf(callback);
  }

  public unsubscribe(callbackOrHandle: ChangeSubscriber<T> | number): void {
    const indexOf =
      typeof callbackOrHandle === "number"
        ? callbackOrHandle
        : this.subscribers.indexOf(callbackOrHandle);

    if (indexOf >= 0) {
      delete this.subscribers[indexOf];
    }
  }

  protected callSubscribers(value: T, prevValue?: T) {
    this.subscribers.forEach((fn) => {
      try {
        fn(
          { ...value },
          this.prevValue ? ({ ...prevValue } as T) : this.prevValue
        );
      } catch (e) {
        console.error("Subscriber threw an error", e);
      }
    });
  }

  @debounce(1)
  protected trigger() {
    const value = this.valueFn();

    // opt-out if value hasn't changed
    if (_.isEqual(value, this.prevValue)) {
      return;
    }

    this.callSubscribers({ ...value }, this.prevValue);
    this.prevValue = { ...value };
  }
}
