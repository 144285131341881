import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import externalIcon from "../../images/external.svg";

export const Styled = styled.div`
  border-top: 1px solid var(--color-gray-100);
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) 0;

  cursor: pointer;
  :first-child {
    border-top: none;
  }

  &.media-item {
    display: flex;

    .media-core {
      width: calc(100% - 32px);
      display: flex;
      align-items: center;

      [class^="media-"] {
        text-align: center;

        span {
          color: var(--color-gray-300);
          font-size: 0.875em;
          font-style: italic;

          span {
            font-size: initial;
          }
        }
      }

      .media-alpha {
        width: 34%;
        display: flex;
        flex: 0 0 auto;
        border-radius: var(--radius-md);

        .thumbnail-preview {
          width: 56px;
          height: 56px;
          position: relative;

          .thumbnail {
            width: 100%;
            height: 100%;
          }
        }

        .media-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 0 0 8px;
          width: calc(100% - 56px);

          * {
            text-align: left;
          }

          h3 {
            display: inline-flex;
            padding: 0;
            margin-bottom: 0;
            font-weight: normal;
            white-space: nowrap;
            border-bottom: 1px solid transparent;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              font-size: 1em;
              color: var(--color-foreground);
              font-style: normal;
              line-height: 24px;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: calc(100% - 24px);

              :hover {
                color: var(--color-foreground-active);
              }
            }

            i {
              background-color: var(--color-gray-200);
              cursor: pointer;
              display: none;
              align-self: center;
              width: 24px;
              mask-size: 16px;
              mask-position: 5px;
              position: relative;
              z-index: 1;
              flex-shrink: 0;
            }
          }

          div {
            display: flex;

            span {
              line-height: 16px;
              font-size: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .media-item__url {
              display: none;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              position: relative;
              padding-right: 10px;

              :hover {
                color: var(--color-gray-950);

                :after {
                  display: inline-block;
                  content: "";
                  background: url(${externalIcon}) no-repeat center;
                  background-size: 10px;
                  width: 10px;
                  height: 10px;
                  margin-left: 2px;
                  margin-bottom: -1px;
                }
              }
            }
          }

          .inline-input {
            font-size: 1em;
            padding: 0;
            margin: 0 auto;
          }
        }

        :hover {
          .media-title {
            h3 {
              i {
                display: inline-block;
              }
            }
          }
        }
      }

      .media-starred {
        width: 9.4%;

        .toggle.star {
          opacity: 0;

          &.active {
            opacity: 1;
          }
        }
      }

      .media-casting {
        width: 9.4%;
      }

      .media-type {
        width: 9.4%;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }

      .media-url {
        width: 18.8%;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          padding: 0 10px;
          cursor: pointer;
          position: relative;
          max-width: 100%;

          :hover {
            color: var(--color-gray-950);
            padding-left: 20px;

            :after {
              display: inline-block;
              content: "";
              background: url(${externalIcon}) no-repeat center;
              background-size: 10px;
              width: 10px;
              height: 10px;
              margin-left: 2px;
              margin-bottom: -1px;
            }
          }
        }
      }

      .media-tags {
        width: 19%;
      }
    }

    .media-options {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      .media-dropdown {
        opacity: 0;

        &.active {
          opacity: 1;
        }

        .menu {
          right: 0;
          left: auto;
        }
      }
    }

    :hover {
      background: rgba(0, 0, 0, 0.01);

      .media-core {
        .media-alpha {
          .media-title {
            h3 {
              i {
                :hover {
                  background-color: var(--color-gray-950);
                }
              }
            }
          }
        }

        .media-starred {
          .toggle.star {
            opacity: 1;
            cursor: pointer;

            :hover {
              background: var(--color-gray-100);
            }
          }
        }
      }

      .media-options {
        .media-dropdown {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.large}) {
    &.media-item {
      .media-core {
        .media-alpha {
          .media-title {
            div {
              .media-item__date {
                .author {
                  display: none;
                }
              }
            }
          }
        }

        .media-starred {
          width: 11%;
        }

        .media-casting {
          width: 11%;
        }

        .media-type {
          width: 11%;
        }

        .media-url {
          width: 22%;
        }

        .media-tags {
          width: 11%;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    &.media-item {
      .media-core {
        .media-alpha {
          width: 40%;

          .media-title {
            div {
              .media-item__date {
                display: initial;
              }
              .media-item__url {
                display: none;
              }
            }
          }
        }

        .media-starred {
          width: 15%;
        }

        .media-casting {
          width: 15%;
        }

        .media-type {
          display: none;
        }

        .media-url {
          display: 15%;
        }

        .media-tags {
          width: 15%;
        }
      }

      .media-options {
        .media-dropdown {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    &.media-item {
      .media-core {
        .media-alpha {
          width: 60%;

          .media-title {
            div {
              .media-item__date {
                display: none;
              }
              .media-item__url {
                display: inline-block;

                :hover {
                  color: var(--color-gray-300);
                }
              }
            }
          }
        }

        .media-starred {
          width: 20%;
        }

        .media-casting {
          width: 20%;
        }

        .media-type {
          display: none;
        }

        .media-url {
          display: none;
        }

        .media-tags {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    &.media-item {
      align-items: center;

      .media-core {
        width: calc(100% - 40px);

        .media-alpha {
          width: calc(100% - 80px);

          .thumbnail-preview {
            height: 40px;
            margin-left: 0;
            width: 40px;
          }

          .media-title {
            width: calc(100% - 60px);

            h3 {
              font-size: 1em;
              height: auto;
            }

            span {
              max-width: 100%;
            }
          }
        }

        .media-starred {
          width: 80px;
        }

        .media-casting {
          display: none;
        }

        .media-type {
          display: none;
        }

        .media-url {
          display: none;
        }

        .media-tags {
          display: none;
        }
      }

      .media-options {
        width: 40px;
      }
    }
  }

  &.readonly {
    cursor: pointer;
  }

  &.selected:active,
  &.selected:hover,
  &.selected {
    background-color: var(--color-gray-100);
    box-shadow: none;
    border-top-color: var(--color-gray-200);

    .media-checked {
      i {
        opacity: 1;
      }
    }
  }

  &.link-item-small {
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.875em;
    }

    .view-icon {
      opacity: 0;
      width: 15px;
      height: 15px;
      margin: 0 10px 0 0;
    }

    .ui.grid > .row {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .wrapper__image {
      width: 43px !important;
      padding: 10px 0;
    }

    :hover {
      .view-icon {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    &.media-item {
      .media-core {
        .media-alpha {
          width: 100%;
        }

        .media-starred {
          display: none;
        }
      }
    }
  }
`;
