import {
  NotificationRule,
  UserStatus,
  useSearchUserBySpaceIdQuery,
} from "src/types.g";
import { Styled } from "./styles";
import { Loader, Search } from "@screencloud/screencloud-ui-components";
import PeopleListItem from "src/components/PeopleListItem";
import React, { useCallback, useState } from "react";
import { UUID } from "@screencloud/uuid";
import useScreenNotification from "src/hooks/ScreenNotification/useScreenNotification";
import { debounce } from "lodash";
import { useAppContext } from "src/hooks/useAppContext";
import { EXCLUDE_SYSTEM_AND_ARCHIVED_USERS_FOR_SEARCH_QUERY } from "src/constants/user";

interface Props {
  notificationRule: NotificationRule;
  onSubscribe: (userId: UUID[]) => void;
  screenId: UUID;
}

export default function PeopleList(props: Props) {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const DEBOUNCE_TIME = 300;

  const context = useAppContext();

  const {
    deleteSubscription,
    deleteSubscriptionInProgress,
  } = useScreenNotification(props.screenId);

  const notificationSubscriptions =
    props?.notificationRule?.notificationSubscriptionsByNotificationRuleId
      .nodes;

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    debouncedSetQuery(e.currentTarget.value);
    setQuery(e.currentTarget.value);
  };

  const debouncedSetQuery = useCallback(
    debounce(setDebouncedQuery, DEBOUNCE_TIME),
    [setQuery]
  );

  const { data } = useSearchUserBySpaceIdQuery({
    variables: {
      query: `${debouncedQuery}${EXCLUDE_SYSTEM_AND_ARCHIVED_USERS_FOR_SEARCH_QUERY}`,
    },
  });

  const users = data?.searchUser?.nodes
    .filter((user) => user.status === UserStatus.Active)
    .filter((user) => user.id !== context.currentUser?.id);

  const isAlreadySubscribed = (id: string) => {
    if (!props?.notificationRule) {
      return false;
    }

    return Boolean(
      notificationSubscriptions.find(
        (sub) => sub?.userByUserIdAndOrgId?.id === id
      )?.active
    );
  };

  const onUserSubscriptionChange = (isSubscribed: boolean, id: string) => {
    if (!isSubscribed) {
      const subscription = notificationSubscriptions.find(
        (sub) => sub.userByUserIdAndOrgId?.id === id
      );
      deleteSubscription(subscription?.id);
    } else {
      props.onSubscribe([id]);
    }
  };

  const hasMoreThan5Users = () => {
    if (users) {
      return users.length > 5;
    } else {
      return true;
    }
  };

  const clearSearch = () => {
    setQuery("");
    setDebouncedQuery("");
  };

  return (
    <Styled>
      {(hasMoreThan5Users() || debouncedQuery) && (
        <Search
          onClear={clearSearch}
          onChange={handleSearch}
          placeholder="Search member"
          showNoResults={false}
          value={query}
          className="user-search-input"
          data-testid="custom-search-input"
        />
      )}
      <div className="people-list" data-testid="people-list">
        {users &&
          users.map((item) => {
            return (
              <PeopleListItem
                key={item.id}
                imgUrl={item?.metadata?.picture}
                name={item?.givenName || ""}
                email={item?.email || ""}
                isSubscribed={isAlreadySubscribed(item.id)}
                onToggle={(isSubscribed) =>
                  onUserSubscriptionChange(isSubscribed, item.id)
                }
              />
            );
          })}
      </div>
      {deleteSubscriptionInProgress && <Loader />}
    </Styled>
  );
}
