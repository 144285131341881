import { useApolloClient } from "@apollo/client";
import { GetAllMediasDocument, GetAllMediasQuery } from "../../../types.g";

export function useGetAllMediaByFolderId() {
  const client = useApolloClient();

  const getAllMedias = async (folderId: string) => {
    const { data: getAllMediaQuery } = await client.query<GetAllMediasQuery>({
      fetchPolicy: "network-only",
      query: GetAllMediasDocument,
      variables: {
        folderId,
      },
    });
    return getAllMediaQuery;
  };

  const getAllFiles = async (folderId: string) => {
    const allMediaQuery = await getAllMedias(folderId);
    return allMediaQuery?.folderById?.filesByFolderId.nodes ?? [];
  };

  const getAllFilesAndFolders = async (folderId: string) => {
    const allMediaQuery = await getAllMedias(folderId);
    return {
      allFiles: allMediaQuery?.folderById?.filesByFolderId?.nodes ?? [],
      allFolders: allMediaQuery?.folderById?.foldersByParentId?.nodes ?? [],
    };
  };

  return { getAllFiles, getAllFilesAndFolders };
}
