import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  &.channel {
    .container {
      .media-item-header {
        .media-core {
          .media-alpha {
            width: 40%;
          }

          .media-layout {
            width: calc(30% - 30px);
          }

          .media-channel-screens {
            width: calc(30% - 30px);
          }
        }

        @media screen and (max-width: ${Theme.break.small}) {
          .media-core {
            .media-alpha {
              width: calc(70% - 30px);
            }

            .media-channel-screens {
              display: none;
            }
          }
        }
      }
    }
  }
  .fetch-more-container {
    padding: 0 15px;
  }
`;

export interface ChannelItemSearchResultStyleProps {}

export const StyledPickerItem = styled.div<ChannelItemSearchResultStyleProps>`
  border-top: 1px solid ${Theme.color.silver};
  cursor: pointer;
  display: flex;
  padding: 10px 0;

  .media-core {
    width: calc(100% - 60px);
    display: flex;
    align-items: center;

    [class^="media-"] {
      text-align: center;

      span {
        color: ${Theme.color.grey};
        font-size: 0.875em;
        font-style: italic;

        span {
          font-size: initial;
        }
      }
    }

    .media-alpha {
      width: 40%;
      display: flex;
      flex: 0 0 auto;
      cursor: pointer;

      .thumbnail-preview {
        width: 45px;
        height: 60px;
        position: relative;
        margin: 0 17px 0 18px;
        ${Theme.default.borderRadius};
        background-size: contain;

        .wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;

          i {
            width: 30px;
            height: 30px;
            background-color: ${Theme.color.white};
          }
        }

        :hover {
          background-color: transparent;
        }

        .thumbnail {
          width: 100%;
          height: 100%;
        }
      }

      .media-title {
        width: calc(100% - 80px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        * {
          text-align: left;
        }

        h3 {
          padding: 0;
          margin-bottom: 0;
          font-weight: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 1px solid transparent;

          :hover {
            background: transparent;
          }
        }

        div {
          display: flex;

          span {
            line-height: 14px;
            font-size: 14px;
            white-space: nowrap;
          }
        }

        .ch-status {
          display: inline-flex;
          line-height: 1.357;

          > span {
            font-size: 1em;
            display: inline-flex;
            align-items: center;
            color: ${Theme.color.warning};
            white-space: nowrap;

            > span {
              font-size: 1em;
              color: ${Theme.color.warning};
            }
          }

          i {
            width: 12px;
            height: 12px;
            margin-right: 5px;
            background-color: ${Theme.color.warning};
          }

          &.published {
            > span {
              color: ${Theme.color.grey};
            }
          }
        }
      }
    }

    .media-layout {
      align-items: center;
      width: calc(30% - 30px);
      display: flex;
      flex-direction: column;
      text-align: center;

      .orientation {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;

        .orientation-preview {
          display: inline-flex;
          background: ${Theme.color.darkGrey};
          border-radius: 2px;
        }
      }

      .layout-name {
        align-items: center;
        color: ${Theme.color.darkGrey};
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        user-select: none;
        width: 100%;
        font-size: 0.75em;
        line-height: 1.2;
      }
    }

    .media-screen {
      align-items: center;
      display: inline-flex;
      width: calc(30% - 30px);
      justify-content: center;

      i {
        background-color: ${Theme.color.darkGrey};
      }

      span {
        color: ${Theme.color.darkGrey};
      }

      button {
        box-shadow: none;
        background: none;

        &.no-screen {
          cursor: default;
          font-style: italic;
          font-weight: normal;
          user-select: none;

          span {
            color: ${Theme.color.grey};
          }
        }
      }
    }

    .ch-share {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      opacity: 0;
      width: 60px;

      .share-icon {
        i {
          background-color: ${Theme.color.grey};
        }

        :hover {
          i {
            background-color: ${Theme.color.darkGrey};
          }
        }
      }
    }
    .ch-share.isShared {
      opacity: 1;

      .share-icon {
        i {
          background-color: ${Theme.color.darkGrey};
        }
      }
    }
  }

  .media-checked {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      opacity: 0;
      background-color: ${Theme.color.darkGrey};
      z-index: 2;
    }

    :after {
      background: ${Theme.color.silver};
      border-radius: 100%;
      content: "";
      display: block;
      height: 16px;
      position: absolute;
      width: 16px;
      z-index: 1;
    }
  }

  :hover {
    .ch-share {
      opacity: 1;
    }

    .ch-option {
      i {
        opacity: 1;
      }
    }
  }

  &.selected {
    background-color: ${Theme.color.silver};
    border-top-color: ${Theme.color.lightGrey};

    .media-checked {
      i {
        opacity: 1;
      }
    }

    :hover {
      background-color: ${Theme.color.silver};
      .media-checked {
        i {
          opacity: 1;
        }
      }
    }
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.01);

    .media-core {
      .media-alpha {
        .media-title {
          h3 {
            span {
              color: ${Theme.color.base};
            }
          }
        }
      }
    }

    .media-checked {
      i {
        opacity: 0.2;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    align-items: center;
    height: 60px;
    padding: 0;

    .media-core {
      .media-alpha {
        width: calc(70% - 30px);

        .thumbnail-preview {
          height: 40px;
          width: 30px;
          margin: 0 15px 0 25px;
        }
      }

      .media-layout {
        .orientation {
          width: 24px;
          height: 24px;
        }
      }

      .media-screen {
        display: none;
      }
    }
  }
`;
