import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { useAppContext } from "src/hooks/useAppContext";

export interface UseRecycleBinPermission {
  canDelete: boolean;
  canRead: boolean;
  canRestore: boolean;
}

export function useRecyclebinPermission(): UseRecycleBinPermission {
  const context = useAppContext();
  const shouldShowRecycleBinFeature = context.shouldShowFeature(
    FEATURE_FLAGS_ENUM.RECYCLE_BIN
  );

  if (!shouldShowRecycleBinFeature) {
    return {
      canRead: false,
      canDelete: false,
      canRestore: false,
    };
  }

  return {
    canRead: context.currentPermissions.validateCurrentSpace(
      "recycle_bin",
      "read"
    ),
    canDelete: context.currentPermissions.validateCurrentSpace(
      "recycle_bin",
      "delete"
    ),
    canRestore: context.currentPermissions.validateCurrentSpace(
      "recycle_bin",
      "restore"
    ),
  };
}
