/**
 * Log a billing action to the console.
 */
export function action(message: string, color = "#aaa"): void {
  console.log(
    `%c BILLING %c ${message} `,
    "background: #2ab556; color: #fff; padding: 1px 6px; font-weight: bold; font-size: 10px",
    `color: ${color}`
  );
}
