import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  padding: 20px;

  &.cast-items {
    height: 100%;
    overflow: auto !important;
  }

  .header {
    span,
    .button {
      text-transform: uppercase;
      color: ${Theme.color.grey};
      z-index: 1;
      position: relative;
      height: 40px;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      letter-spacing: 0.1em;
      white-space: nowrap;

      i {
        width: 8px;
        margin-left: 5px;
        height: 4px;
        background-color: ${Theme.color.grey};
      }
    }

    .media-core {
      width: calc(100% - 80px);
      display: flex;

      [class^="media-"] {
        text-align: center;
      }

      .media-alpha {
        width: 40%;
        text-align: left;
      }

      .media-screens,
      .media-kind,
      .media-duration {
        width: 20%;
      }
    }

    @media screen and (max-width: ${Theme.break.mediumAlt}) {
      .media-core {
        .media-alpha {
          width: calc(100% - 360px);
        }

        .media-duration,
        .media-kind,
        .media-screens {
          width: 120px;
        }
      }
    }

    @media screen and (max-width: ${Theme.break.small}) {
      .media-core {
        .media-alpha {
          width: calc(100% - 120px);
        }

        .media-duration,
        .media-kind {
          display: none;
        }

        .media-screens {
          width: 120px;
        }
      }
    }
  }
`;
