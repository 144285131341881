import { getOnCastableDeletedRefetchQueries } from "src/domains/castableEntity/events/onCastableEntityDeleted";
import { useOnFolderDeleted } from "src/domains/folder/events/useOnFolderDeleted";
import { Typenames } from "../../../constants/constants";
import {
  DeleteFolderByIdMutationVariables,
  Folder,
  useDeleteFolderByIdMutation,
} from "../../../types.g";

interface DeleteFolderParam {
  folder: Folder;
}

export interface DeleteFolder {
  deleteFolder: (params: DeleteFolderParam) => Promise<any>;
}

export function useDeleteFolder(): DeleteFolder {
  const [deleteFolderByIdMutation] = useDeleteFolderByIdMutation();
  const { onFolderDeleted } = useOnFolderDeleted();

  const deleteFolder = ({ folder }: DeleteFolderParam) => {
    const updateMediaFolder: DeleteFolderByIdMutationVariables = {
      input: {
        folderId: folder.id,
      },
    };
    return deleteFolderByIdMutation({
      optimisticResponse: {
        __typename: Typenames.Mutation,
        deleteFolderById: {
          __typename: Typenames.DeleteFolderByIdPayload,
          folder: {
            __typename: Typenames.Folder,
            createdAt: folder.createdAt,
            createdBy: folder.createdBy,
            id: folder.id,
            isFavorite: folder.isFavorite,
            name: folder.name,
            orgId: folder.orgId,
            parentId: folder.parentId,
            spaceId: folder.spaceId,
            tags: folder.tags,
            updatedAt: folder.updatedAt,
            updatedBy: folder.updatedBy,
            isShared: folder.isShared,
            isSharedAll: folder.isSharedAll,
          },
        },
      },
      update: (_, data) => {
        const folder = data.data?.deleteFolderById?.folder;
        if (folder) {
          onFolderDeleted({ folder });
        }
      },
      variables: updateMediaFolder,
      refetchQueries: getOnCastableDeletedRefetchQueries(),
    });
  };

  return { deleteFolder };
}
