import {
  InlineInput,
  ToggleStar,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { StyledMediaItem } from "./styleMediaItem";
import iconFolder from "../../images/media.svg";

export type MediaNewFolderCallBack = (
  event: React.SyntheticEvent<any>,
  value: string
) => void;

export interface MediaNewFolderProps {
  onEdit?: () => void;
  onSaved: MediaNewFolderCallBack;
  isCreating?: boolean;
  isGridMode?: boolean;
}
export interface MediaItemState {
  value: string;
}
class MediaNewFolder extends React.Component<
  MediaNewFolderProps,
  MediaItemState
> {
  constructor(props: MediaNewFolderProps) {
    super(props);
    this.state = { value: "" };
  }

  public handlerOnSaveValue = (e: React.SyntheticEvent<any>, value: string) => {
    if (this.props.onSaved) {
      this.props.onSaved(e, value);
    }
    this.saveValue(value);
  };
  public saveValue = (value: string) => {
    this.setState({ value });
  };

  public renderListMode = () => {
    // Media Icon "file" or "folder"
    const media = (
      <div className="thumbnail folder">
        <div className="wrapper">
          <img className="folder-img" src={iconFolder} />
        </div>
      </div>
    );
    const { value } = this.state;

    return (
      <div className="column">
        <StyledMediaItem className="media-item media-folder media-new-folder side-bar">
          <div className="media-core">
            <div className="media-alpha">
              <div className="thumbnail-preview">{media}</div>
              <div className="media-title">
                {this.props.isCreating ? (
                  <div className="creating">
                    <FormattedMessage
                      id="ui_component.media.creating_new_folder"
                      defaultMessage="Creating new folder..."
                    />
                  </div>
                ) : (
                  <InlineInput
                    placeholder="New Folder"
                    value={value}
                    onEdit={this.props.onEdit}
                    onSaved={this.handlerOnSaveValue}
                    editmode={true}
                  />
                )}
              </div>
            </div>
          </div>
        </StyledMediaItem>
      </div>
    );
  };

  public renderGridMode = () => {
    const { value } = this.state;

    // Media Icon "file" or "folder"
    const media = (
      <div className="thumbnail folder">
        <div className="wrapper">
          <img className="folder-img" src={iconFolder} />
        </div>
      </div>
    );

    const mediaTitle = (
      <div className="media-title">
        {this.props.isCreating ? (
          <div className="creating">
            <FormattedMessage
              id="ui_component.media.creating_new_folder"
              defaultMessage="Creating new folder..."
            />
          </div>
        ) : (
          <InlineInput
            placeholder="New Folder"
            value={value}
            onEdit={this.props.onEdit}
            onSaved={this.handlerOnSaveValue}
            editmode={true}
          />
        )}
        <div>
          <span className="media-item__count" />
        </div>
      </div>
    );

    return (
      <div className="media-visibility">
        <div className="column">
          <div>
            <StyledMediaItem className="media-item media-folder">
              <div className="media-core">
                <div className="media-alpha">
                  <div className="thumbnail-preview">{media}</div>
                  {mediaTitle}
                </div>
                <div
                  className="media-item__count"
                  style={{ visibility: "hidden" }}
                >
                  -
                </div>
                <div className="media-starred" style={{ visibility: "hidden" }}>
                  <ToggleStar active={false} />
                </div>
              </div>
            </StyledMediaItem>
          </div>
        </div>
      </div>
    );
  };

  public render(): JSX.Element | any {
    const { isGridMode } = this.props;
    return isGridMode ? this.renderGridMode() : this.renderListMode();
  }
}

export default MediaNewFolder;
