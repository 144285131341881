import { FormattedMessage } from "react-intl";

export default function SectionLabel() {
  return (
    <div className="subheader">
      <FormattedMessage
        id="ui_component.screen_notification.modal.section_label.admin_settings"
        defaultMessage="Admin Settings"
      />
    </div>
  );
}
