import { Theme } from "@screencloud/screencloud-ui-components";
import React from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { compose } from "../../utils/compose";

// React-DnD
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from "react-dnd";

import { getEmptyImage } from "react-dnd-html5-backend";
import { Typenames } from "../../constants/constants";
import { MediaDropResult, MediaItemType } from "../../constants/mediaTypes";

import { File, Folder } from "../../types.g";
import { DraggableContainer, SelectionCount } from "../Main/styles";
import { StyledDraggableWrapper, StyledDroppableContainer } from "./styles";
export interface MediaItemDnDProps {
  media: File | Folder;
  index: number;
  selectedMediaItems: (File | Folder)[];
  isDraggable?: boolean;
  isDroppable?: boolean;
  useReactDnD?: boolean;
  selectedMediaFileCallback?: (mediaFile: File | Folder) => void;
  onDrop?: (dropResult: MediaDropResult) => void;

  // Drag connect
  connectDragSource?: ConnectDragSource;
  connectDragPreview?: ConnectDragPreview;
  isDragging?: boolean;
  canDrag?: (props: MediaItemDnDProps, monitor: DragSourceMonitor) => boolean;

  // Drop connect
  connectDropTarget?: ConnectDropTarget;
  canDrop?: (props: MediaItemDnDProps, monitor: DropTargetMonitor) => boolean;
  isOver?: boolean;
}

// const getMediaItemType = (media: File | Folder) => {
const getMediaItemType = (media: any) => {
  let mediaType = MediaItemType.MEDIA;
  if (media.__typename === Typenames.File) {
    mediaType = MediaItemType.MEDIA_FILE;
  }
  if (media.__typename === Typenames.Folder) {
    mediaType = MediaItemType.MEDIA_FOLDER;
  }
  return mediaType;
};

// Drag
const mediaItemDragSource = {
  canDrag(props) {
    return props.isDraggable;
  },
  beginDrag(props: MediaItemDnDProps) {
    return {
      mediaId: props.media.id,
      selectedMediaItems: props.selectedMediaItems,
      type: getMediaItemType(props.media),
    };
  },
};

const mediaItemDragCollect = (
  connect: DragSourceConnector,
  monitor: DragSourceMonitor
) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

// Drop
const mediaItemDropTarget = {
  canDrop(props: MediaItemDnDProps) {
    return !!props.isDroppable;
  },
  drop(props: MediaItemDnDProps, monitor: DropTargetMonitor) {
    if (monitor.didDrop()) {
      return;
    }

    if (props.onDrop) {
      props.onDrop({
        destination: {
          mediaId: props.media.id,
          type: getMediaItemType(props.media),
        },
        source: monitor.getItem(),
      });
    }
  },
};

const mediaItemDropCollect = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor
) => ({
  canDrop: monitor.canDrop(),
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

const getItemStyle = (
  snapshot: DraggableStateSnapshot,
  draggableStyle: object | undefined
) => {
  const { isDragging } = snapshot;
  return {
    ...draggableStyle,
    background: isDragging ? "#FFFFFF" : undefined,
    borderRadius: isDragging ? "6px" : undefined,
    boxShadow: isDragging ? "0 2px 15px 0 rgba(0,0,0,0.15)" : undefined,
  };
};

class MediaItemDnD extends React.Component<MediaItemDnDProps, {}> {
  public componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      // Use empty image as a drag preview so browsers don't draw it
      // and we can draw whatever we want on the custom drag layer instead.
      connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
      });
    }
  }

  public getDragAndDropStyle = (isDragging, isOver) => {
    return {
      backgroundColor: isOver ? Theme.color.silver : "transparent",
      opacity: isDragging ? 0.2 : 1,
    };
  };

  public handleSelectedItem = () => {
    if (this.props.selectedMediaFileCallback) {
      this.props.selectedMediaFileCallback(this.props.media);
    }
  };

  public renderReactDnD = () => {
    const {
      connectDragSource,
      connectDropTarget,
      isOver,
      isDragging,
    } = this.props;

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div
            className="column"
            style={this.getDragAndDropStyle(isDragging, isOver)}
          >
            {this.props.children}
          </div>
        )
      )
    );
  };

  public renderBeautifulDnD = () => {
    const { media, index, isDroppable, isDraggable } = this.props;

    return (
      <Droppable
        isDropDisabled={!isDroppable}
        droppableId={`file-${media.id}`}
        key={media.id}
      >
        {(
          droppableProvided: DroppableProvided,
          droppableSnapshot: DroppableStateSnapshot
        ) => (
          <StyledDroppableContainer
            className="column"
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            style={{
              backgroundColor: droppableSnapshot.isDraggingOver
                ? Theme.color.silver
                : "transparent",
            }}
          >
            <StyledDraggableWrapper key={media.id} className="row">
              <Draggable
                draggableId={media.id}
                index={index}
                isDragDisabled={!isDraggable}
              >
                {(
                  draggableProvided: DraggableProvided,
                  draggableSnapshot: DraggableStateSnapshot
                ) => {
                  // override
                  let onMouseDown: any = null;
                  onMouseDown = () => {
                    this.handleSelectedItem();
                  };

                  let onTouchStart: any = null;
                  onTouchStart = (() => {
                    if (!draggableProvided.dragHandleProps) {
                      return () => false;
                    }
                    return (event) => {
                      if (draggableProvided.dragHandleProps) {
                        draggableProvided.dragHandleProps.onTouchStart(event);
                        this.handleSelectedItem();
                      }
                    };
                  })();

                  return (
                    <>
                      <div
                        className="column"
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        onMouseDown={onMouseDown}
                        onTouchStart={onTouchStart}
                        style={getItemStyle(
                          draggableSnapshot,
                          draggableProvided.draggableProps.style
                        )}
                      >
                        <DraggableContainer>
                          {this.props.children}
                          {draggableSnapshot.isDragging &&
                          this.props.selectedMediaItems.length > 1 ? (
                            <SelectionCount>
                              {this.props.selectedMediaItems.length}
                            </SelectionCount>
                          ) : null}
                        </DraggableContainer>
                      </div>
                      <DraggableContainer>
                        {draggableSnapshot.isDragging && this.props.children}
                      </DraggableContainer>
                    </>
                  );
                }}
              </Draggable>
            </StyledDraggableWrapper>
            {droppableProvided.placeholder}
          </StyledDroppableContainer>
        )}
      </Droppable>
    );
  };

  public render() {
    if (this.props.useReactDnD) {
      return this.renderReactDnD();
    } else {
      return this.renderBeautifulDnD();
    }
  }
}

export default compose(
  DropTarget(MediaItemType.MEDIA, mediaItemDropTarget, mediaItemDropCollect),
  DragSource(MediaItemType.MEDIA, mediaItemDragSource, mediaItemDragCollect)
)(MediaItemDnD) as React.ComponentType<MediaItemDnDProps>;
