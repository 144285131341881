import isPast from "date-fns/isPast";
import {
  isEverydaySelected,
  isRangeOverlap,
  isTimeOver24h,
} from "../datetimeHelper";
import {
  OverlapResultCollection,
  ScheduleRule,
  TimeRange,
  TimeRangeValueCollection,
  ZoneContent,
} from "./types";

const DEFAULT_DATE = "2000-01-01T";

export const generateTimeRangeCollection = (
  zoneContents: ZoneContent[],
  timeRanges: TimeRangeValueCollection = {}
): TimeRangeValueCollection => {
  zoneContents.forEach(getDateTimeRange(timeRanges));
  return timeRanges;
};

/**
 * Get schedule overlap result
 *
 * @param zoneContent
 * ZoneContent response from backend for only selected zone.
 * @returns {OverlapResult[]}
 */
export const getScheduleOverlap = (
  zoneContent: ZoneContent[]
): OverlapResultCollection => {
  const timeRangeValue = generateTimeRangeCollection(zoneContent);
  const result: OverlapResultCollection = {};
  Object.keys(timeRangeValue).forEach((currentKey: string, index: number) => {
    Object.keys(timeRangeValue).forEach(
      (nextKey: string, nextIndex: number) => {
        if (index === nextIndex) return;
        const isDateOverlap = isRangeOverlap(
          timeRangeValue[currentKey].date.start,
          timeRangeValue[currentKey].date.end,
          timeRangeValue[nextKey].date.start,
          timeRangeValue[nextKey].date.end
        );

        const isTimeOverlap = isRangeOverlap(
          timeRangeValue[currentKey].time.start,
          timeRangeValue[currentKey].time.end,
          timeRangeValue[nextKey].time.start,
          timeRangeValue[nextKey].time.end
        );
        const isDateInPast =
          isPast(timeRangeValue[currentKey].date.end) ||
          isPast(timeRangeValue[nextKey].date.end);
        const isValidSchedule =
          !isDateInPast &&
          ((!timeRangeValue[currentKey].isEveryDay &&
            !timeRangeValue[nextKey].isEveryDay) ||
            (timeRangeValue[currentKey].isSpecificDate &&
              timeRangeValue[nextKey].isSpecificDate));

        if (isDateOverlap && isTimeOverlap && isValidSchedule) {
          const { listId, dateIdx, timeIdx } = extractKey(currentKey);
          if (!result[listId]) {
            result[listId] = {
              date_indexes: new Set<number>(),
              time_indexes: new Set<number>(),
            };
          }

          result[listId] = {
            date_indexes: result[listId].date_indexes.add(dateIdx),
            time_indexes: result[listId].time_indexes.add(timeIdx),
          };
        }
      }
    );
  });

  return result;
};

const trimStartTime = (start: string) =>
  start.trim() === "NaN:NaN:NaN" ? "00:00:00" : start.trim();
const trimEndTime = (end: string) =>
  end.trim() === "NaN:NaN:NaN" ? "23:59:59" : end.trim();
const trimTimeRange = (timeRange?: TimeRange) => ({
  start: timeRange ? trimStartTime(timeRange.start) : "00:00:00",
  end: timeRange ? trimEndTime(timeRange.end) : "23:59:59",
});
const getDateFromString = (date: string, time: string): Date =>
  new Date(`${date}T${time}`);
const getCollectionKey = (listId: string, dateIdx: number, timeIdx: number) =>
  `${listId}|${dateIdx}|${timeIdx}`;
const extractKey = (
  key: string
): { listId: string; dateIdx: number; timeIdx: number } => {
  const [listId, dateIdx, timeIdx] = key.split("|");
  return {
    listId,
    dateIdx: parseInt(dateIdx),
    timeIdx: parseInt(timeIdx),
  };
};

const getDateTimeRange = (timeRanges: TimeRangeValueCollection) => (
  zoneContent: ZoneContent
): TimeRangeValueCollection => {
  if (zoneContent.schedule?.length > 0) {
    zoneContent.schedule.forEach(
      ({ date, time, day_of_week, specific_date }: ScheduleRule) => {
        if (date.length === 0 || time.length === 0) return;
        let dateIdx = 0;
        let timeIdx = 0;
        while (dateIdx < date.length) {
          const { start, end } = trimTimeRange(time[timeIdx]);
          const transformStartTime = isTimeOver24h(start)
            ? start.replace("24", "00")
            : start;
          const transformEndTime = isTimeOver24h(end)
            ? end.replace("24", "00")
            : end;
          const startDate = getDateFromString(
            date[dateIdx].start,
            "00:00:00"
          ).getTime();
          const endDate = getDateFromString(
            date[dateIdx].end,
            "23:59:59"
          ).getTime();
          const key = getCollectionKey(zoneContent.list_id, dateIdx, timeIdx);

          timeRanges[key] = {
            date: { start: startDate, end: endDate },
            time: {
              start: new Date(`${DEFAULT_DATE}${transformStartTime}`).getTime(),
              end: new Date(`${DEFAULT_DATE}${transformEndTime}`).getTime(),
            },
            isEveryDay: isEverydaySelected(day_of_week),
            isSpecificDate: specific_date,
          };

          timeIdx++;
          if (timeIdx >= time.length) {
            timeIdx = 0;
            dateIdx++;
          }
        }
      }
    );
  }
  return timeRanges;
};
