import { Icon, Loader } from "@screencloud/screencloud-ui-components";
import { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { appConfig } from "../../../appConfig";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import { isChromeExtensionInstalled } from "./chromeExtensionUtils";

interface InstallExtensionProps {
  onExtensionInstalled: () => void;
}
const InstallExtension = (
  props: InstallExtensionProps
): ReactElement<InstallExtensionProps> => {
  const { onExtensionInstalled } = props;
  const [isWaitingForInstall, setIsWaitingForInstall] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    if (isWaitingForInstall) {
      const interval = setInterval(async () => {
        const isInstalled = await isChromeExtensionInstalled();
        if (isInstalled) {
          clearInterval(interval);
          setIsInstalled(true);
          setIsWaitingForInstall(false);
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            onExtensionInstalled();
          }, 2000);
        }
      }, 2000);

      return () => clearInterval(interval);
    }
    return;
  }, [isWaitingForInstall]);

  return isWaitingForInstall ? (
    <>
      <div className="content">
        <h2>
          <FormattedMessage
            id="ui_component.site.new_site_modal.install_extension.waiting"
            defaultMessage="Waiting for Dashboard Recorder to be installed"
          />
        </h2>
        <p>
          <FormattedMessage
            id="ui_component.site.new_site_modal.install_extension.waiting_subtitle"
            defaultMessage="We will automatically take you to the Dashboard Recorder when the extension has been installed."
          />
        </p>
        <p>
          <Loader inline size="large" />
        </p>
      </div>
    </>
  ) : isInstalled ? (
    <div className="content">
      <h2>
        <FormattedMessage
          id="ui_component.site.new_site_modal.install_extension.success"
          defaultMessage="Success!"
        />
      </h2>
      <Icon name="checked-circle" className="success-icon" />
      <p>
        <FormattedMessage
          id="ui_component.site.new_site_modal.install_extension.success_subtitle"
          defaultMessage="The Dashboard Recorder extension has been successfully installed."
        />
      </p>
      <p>
        <FormattedMessage
          id="ui_component.site.new_site_modal.install_extension.success_subtitle_redirect"
          defaultMessage="Please wait while we redirect you to the
            Dashboard Recorder."
        />
      </p>
    </div>
  ) : (
    <>
      <div className="content">
        <h2>
          <FormattedMessage
            id="ui_component.site.new_site_modal.install_extension.title"
            defaultMessage="First things first, install Dashboard Recorder extension"
          />
        </h2>
        <p>
          <FormattedMessage
            id="ui_component.site.new_site_modal.install_extension.instructions"
            defaultMessage="Please install Dashboard Recorder extension to get started."
          />
        </p>
        <div className="extension-name">
          <FormattedMessage
            id="ui_component.site.new_site_modal.install_extension.extension_name"
            defaultMessage="ScreenCloud Dashboard Recorder"
          />
        </div>
        <PrimaryButton
          className="install-button"
          fullWidth
          onClick={() => {
            window.open(appConfig.secureSiteChromeExtensionUrl);
            setIsWaitingForInstall(true);
          }}
        >
          <Icon name="external" />
          <FormattedMessage
            id="ui_component.site.new_site_modal.install_extension.install"
            defaultMessage="Get Extension"
          />
        </PrimaryButton>
      </div>
    </>
  );
};

export default InstallExtension;
