import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledDeleteWarning = styled.div`
  .places-list {
    cursor: text;
    user-select: text;
    text-align: left;
    margin-top: 10px;
    padding: 0 20px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    .place-name {
      color: ${Theme.color.grey70};
    }
  }
`;
