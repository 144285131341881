import {
  SearchScreenQueryVariables,
  useSearchScreenQuery,
  Screen,
  ScreensByScreenGroupIdAndOrgIdFragment,
} from "../types.g";
import { useAppContext } from "./useAppContext";
import { useEffect, useState } from "react";

export interface UseSearchScreen {
  screens: ScreensByScreenGroupIdAndOrgIdFragment[];
  loading: boolean;
  isFirstTimeAlreadyLoaded: boolean;
  totalCount: number;
  hasNextPage: boolean;
  loadMore: () => void;
}

interface Prop {
  query: string;
}

export function useSearchScreen({ query }: Prop): UseSearchScreen {
  const context = useAppContext();
  const [screens, setScreens] = useState(
    [] as ScreensByScreenGroupIdAndOrgIdFragment[]
  );
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] = useState(
    false
  );

  const variables: SearchScreenQueryVariables = {
    query,
    spaceId: context.currentSpace?.id,
  };

  const { data: searchScreenQuery, loading } = useSearchScreenQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !query,
  });

  useEffect(() => {
    setScreens((searchScreenQuery?.searchScreen?.nodes as Screen[]) ?? []);
  }, [searchScreenQuery?.searchScreen?.nodes]);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(Boolean(searchScreenQuery?.searchScreen));
  }, [searchScreenQuery?.searchScreen]);

  return {
    screens,
    loading,
    isFirstTimeAlreadyLoaded,
    totalCount: screens.length,
    hasNextPage: false,
    loadMore: () => {},
  };
}
