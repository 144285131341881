import { compose } from "../../utils/compose";
import { ApolloClient, useApolloClient } from "@apollo/client";
import { UseStateSearch, useStateSearch } from "../../hooks/useStateSearch";
import {
  usePlaylistPicker,
  UsePlaylistPicker,
} from "./hooks/usePlaylistPicker";

export interface ApolloProps extends UseStateSearch, UsePlaylistPicker {
  playlistData: UsePlaylistPicker;
  client: ApolloClient<object>;
  excludeShare?: boolean;
}

export const withData = compose((Component) => (props: any) => (
  <Component
    {...props}
    client={useApolloClient()}
    {...useStateSearch()}
    playlistData={usePlaylistPicker(props.excludeShare, props.spaceId)}
  />
));
