import * as Sentry from "@sentry/browser";
import * as React from "react";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";

interface State {
  error: null | Error;
}

export interface Props {
  value?: { [key: string]: any };
  options?: {
    showReportDialog?: boolean;
  };
}

export default class ErrorBoundary extends React.Component<Props, State> {
  public readonly state: State = {
    error: null,
  };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (appConfig.sentry) {
      this.setState({ error });
      Sentry.withScope((scope) => {
        [this.props.value || {}, errorInfo].forEach((obj) => {
          Object.keys(obj).forEach((key) => {
            scope.setExtra(key, obj[key]);
          });
        });
        Sentry.captureException(error);
      });
    } else {
      throw error;
    }
  }

  public render() {
    // Everything's working fine!
    if (!this.state.error) {
      return this.props.children;
    }

    // todo: we should show something here
    return this.props.children;
  }
}

ErrorBoundary.contextType = AppContext;
