import {
  Button,
  ButtonGroup,
  Icon,
  Popover,
  Search,
} from "@screencloud/screencloud-ui-components";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStateFolderId } from "../../components/MediaPicker/useStateFolderId";
import { useAppContext } from "../../hooks/useAppContext";
import { useStateSearch } from "../../hooks/useStateSearch";
import {
  AvailableAppInstanceFragment,
  File,
  Folder,
  Link,
  Site,
} from "../../types.g";
import { compose } from "../../utils/compose";
import AppTabPane from "../AppTabPane";
import { AppTabPaneItemActions } from "../AppTabPane/appItem";
import { CanvasAppTabPaneContainer } from "../CanvasAppTabPane";
import { LinkListItemActions } from "../LinkListItem";
import LinkTabPane from "../LinkTabPane";
import { MediaFileActions } from "../Media/mediaFile";
import { MediaFolderActions } from "../Media/mediaFolder";
import MediaTabPane from "../MediaTabPane";
import SearchTabPane from "../SearchTabPane";
import { SiteListItemActions } from "../SiteListItem";
import SiteTabPane from "../SiteTabPane";
import { Styled, StyledTabContainer } from "./styles";

interface Data {
  searchString: string;
  query: string;
  updateSearchString: (searchString: string) => void;
  isSearch: boolean;
  clearSearch: () => void;
  folderId: string;
  updateFolderId: (folderId: string) => void;
}

export const withData = compose(
  (Component) => (props: any) => {
    const {
      query,
      searchString,
      updateSearchString,
      isSearch,
      clearSearch,
    } = useStateSearch();
    return (
      <Component
        {...props}
        query={query}
        searchString={searchString}
        updateSearchString={updateSearchString}
        isSearch={isSearch}
        clearSearch={clearSearch}
      />
    );
  },
  (Component) => (props: { clearSearch: () => void }) => {
    const { clearSearch } = props;
    const { folderId, updateFolderId } = useStateFolderId({
      updateFolderIdCallback: clearSearch,
    });
    return (
      <Component
        {...props}
        folderId={folderId}
        updateFolderId={updateFolderId}
      />
    );
  }
);

export interface MediaSidebarProps {
  isEditable: boolean;
  selectedMediaItems: Partial<File>[];
  onClickLinkCallback: (
    action: LinkListItemActions,
    value: Partial<Link> | string | boolean,
    event?: React.SyntheticEvent
  ) => void;
  onClickSiteCallback: (
    action: SiteListItemActions,
    value: Partial<Site> | string | boolean,
    event?: React.SyntheticEvent
  ) => void;
  selectedMediaFileCallback?: (media: File | Folder) => void;
  onSelectMediaItems?: (mediaItems: (File | Folder)[]) => void;
  onSelectLinkItems?: (linkItems: Partial<Link>[]) => void;
  onSelectSiteItems?: (siteItems: Partial<Site>[]) => void;
  selectedLinkItems?: Partial<Link>[];
  selectedSiteItems?: Partial<Site>[];
  selectedLinkCallback: (link: Partial<Link>) => void;
  selectedSiteCallback: (site: Partial<Site>) => void;
  onClickMediaFileCallback: (
    mediaId: string[],
    action: MediaFileActions,
    value: Partial<File>[] | string | boolean
  ) => void;
  onClickMediaFolderCallback: (
    mediaId: string,
    action: MediaFolderActions,
    value: Partial<File>[]
  ) => void;
  onClickAppInstanceCallback: (
    app: AvailableAppInstanceFragment,
    action: AppTabPaneItemActions
  ) => void;
  shouldRefetchingSidebar?: boolean;
  onRefetchingFinish?: () => void;
  onOpenMediaPicker?: () => void;
  onClickCanvasInstanceCallback: (app: AvailableAppInstanceFragment) => void;
}

enum MediaSideBarActiveViewEnum {
  Recent = "recent",
  Media = "media",
  Apps = "apps",
  Links = "links",
  Sites = "sites",
  Canvas = "canvas",
}

type MediaSideBarActiveView =
  | MediaSideBarActiveViewEnum.Recent
  | MediaSideBarActiveViewEnum.Media
  | MediaSideBarActiveViewEnum.Apps
  | MediaSideBarActiveViewEnum.Links
  | MediaSideBarActiveViewEnum.Sites
  | MediaSideBarActiveViewEnum.Canvas;

export interface MediaSideBarState {
  currentActiveView: MediaSideBarActiveView;
}

export const MediaSidebar = (props: MediaSidebarProps & Data) => {
  const [
    currentActiveView,
    setCurrentActiveView,
  ] = useState<MediaSideBarActiveView>(MediaSideBarActiveViewEnum.Recent);
  const { clearSearch, searchString } = props;
  const context = useAppContext();

  const getCurrentActiveView = (view: MediaSideBarActiveView) => {
    const { query, clearSearch, isSearch, folderId, updateFolderId } = props;
    const {
      onClickMediaFileCallback,
      onClickMediaFolderCallback,
      selectedMediaFileCallback,
      selectedLinkCallback,
      selectedSiteCallback,
    } = props;
    let activeView;
    switch (view) {
      case MediaSideBarActiveViewEnum.Recent:
        activeView = (
          <SearchTabPane
            query={query}
            onClickMediaFileCallback={onClickMediaFileCallback}
            onClickMediaFolderCallback={onSearchTabPaneClickMediaFolderCallback}
            selectedMediaFileCallback={selectedMediaFileCallback}
            selectedLinkCallback={selectedLinkCallback}
            selectedSiteCallback={selectedSiteCallback}
            onClickLinkCallback={props.onClickLinkCallback}
            onClickSiteCallback={props.onClickSiteCallback}
            addItemToPlaylist={props.onClickAppInstanceCallback}
            shouldRefetchingSidebar={props.shouldRefetchingSidebar}
            onRefetchingFinish={props.onRefetchingFinish}
          />
        );
        break;
      case MediaSideBarActiveViewEnum.Media:
        activeView = (
          <MediaTabPane
            folderId={folderId}
            updateFolderId={updateFolderId}
            isSearch={isSearch}
            query={query}
            clearSearch={clearSearch}
            selectedMediaItems={props.selectedMediaItems}
            onSelectMediaItems={props.onSelectMediaItems}
            onClickMediaFileCallback={props.onClickMediaFileCallback}
            onClickMediaFolderCallback={onClickMediaFolderCallback}
            selectedMediaFileCallback={selectedMediaFileCallback}
          />
        );
        break;
      case MediaSideBarActiveViewEnum.Links:
        activeView = (
          <LinkTabPane
            searchTerms={query}
            selectedLinkItems={props.selectedLinkItems}
            onSelectLinkItems={props.onSelectLinkItems}
            selectedLinkCallback={selectedLinkCallback}
            onClickLinkCallback={props.onClickLinkCallback}
          />
        );
        break;
      case MediaSideBarActiveViewEnum.Sites:
        activeView = (
          <SiteTabPane
            searchTerms={query}
            selectedSiteItems={props.selectedSiteItems as Site[]}
            onSelectSiteItems={props.onSelectSiteItems}
            selectedSiteCallback={selectedSiteCallback}
            onClickSiteCallback={props.onClickSiteCallback}
          />
        );
        break;
      case MediaSideBarActiveViewEnum.Apps:
        activeView = (
          <AppTabPane
            addItemToPlaylist={props.onClickAppInstanceCallback}
            searchTerms={query}
          />
        );
        break;
      case MediaSideBarActiveViewEnum.Canvas:
        activeView = (
          <CanvasAppTabPaneContainer
            addItemToPlaylist={props.onClickAppInstanceCallback}
            searchTerms={query}
          />
        );
        break;
    }
    return activeView;
  };
  const onSearchTabPaneClickMediaFolderCallback = (
    mediaId: string,
    action: MediaFolderActions,
    value: Partial<File>[]
  ) => {
    const { onClickMediaFolderCallback, updateFolderId } = props;

    if (action === MediaFolderActions.ENTER_FOLDER) {
      setCurrentActiveView(MediaSideBarActiveViewEnum.Media);
      updateFolderId(mediaId);
    }

    onClickMediaFolderCallback(mediaId, action, value);
  };

  const isAbleToViewMedia = context.currentPermissions.validateCurrentSpace(
    "media",
    "read"
  );
  const isAbleToViewApp = context.currentPermissions.validateCurrentSpace(
    "app_instance",
    "read"
  );

  return (
    <Styled
      className={
        !props.isEditable ? "readonly wrapper content" : "wrapper content"
      }
      data-testid="content-pane"
    >
      <div className="search" data-testid="search-input">
        <Search
          placeholder="Search content"
          onChange={(e, { value }) => props.updateSearchString(value)}
          onClear={clearSearch}
          showNoResults={false}
          value={searchString}
        />
      </div>
      <div className="media-type">
        <ButtonGroup className="button-group">
          <Button
            key="button-group-button-1"
            mini
            active={currentActiveView === MediaSideBarActiveViewEnum.Recent}
            onClick={() =>
              setCurrentActiveView(MediaSideBarActiveViewEnum.Recent)
            }
            data-testid="recent-button"
          >
            <FormattedMessage id="search.recent" defaultMessage="Recent" />
          </Button>

          {isAbleToViewMedia && (
            <Button
              key="button-group-button-2"
              mini
              active={currentActiveView === MediaSideBarActiveViewEnum.Media}
              onClick={() =>
                setCurrentActiveView(MediaSideBarActiveViewEnum.Media)
              }
              data-testid="media-button"
            >
              <FormattedMessage id="media.media" defaultMessage="Media" />
            </Button>
          )}

          <Button
            key="button-group-button-4"
            mini
            active={currentActiveView === MediaSideBarActiveViewEnum.Canvas}
            onClick={() =>
              setCurrentActiveView(MediaSideBarActiveViewEnum.Canvas)
            }
            data-testid="canvas-button"
          >
            <FormattedMessage id="canvas.canvas" defaultMessage="Canvas" />
          </Button>
          {isAbleToViewApp && (
            <Button
              key="button-group-button-5"
              mini
              active={currentActiveView === MediaSideBarActiveViewEnum.Apps}
              onClick={() =>
                setCurrentActiveView(MediaSideBarActiveViewEnum.Apps)
              }
              data-testid="apps-button"
            >
              <FormattedMessage id="apps.apps" defaultMessage="apps" />
            </Button>
          )}
        </ButtonGroup>
        <Popover
          inverted
          position="top right"
          on="hover"
          content={
            <FormattedMessage
              id={`playlists.sidebar.browse`}
              defaultMessage={"Browse all content"}
            />
          }
          trigger={
            <Button
              icon
              className="media-picker-button"
              mini
              onClick={props.onOpenMediaPicker}
              data-testid="media-picker-button"
            >
              <Icon name="external" />
            </Button>
          }
        />
      </div>
      <StyledTabContainer>
        {getCurrentActiveView(currentActiveView)}
      </StyledTabContainer>
      <div className="note-mobile">
        <span>
          Unable to drag and drop media from sidebar on smaller screen, you
          still can add content to playlist by clicking 'Add content' from above
        </span>
      </div>
    </Styled>
  );
};

export default withData(MediaSidebar);
