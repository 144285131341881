import { Theme } from "@screencloud/screencloud-ui-components";
import styled, { StyledComponent } from "styled-components";

export const ZoneSettings: StyledComponent<
  any,
  any,
  Pick<any, any>
> = styled.div`
  padding: 15px 15px 0px 15px;
  margin-bottom: 3px;
  border: 1px solid ${Theme.color.lightGrey};
  background: ${Theme.color.white};
`;

export const LayoutSettingWrapper: StyledComponent<
  any,
  any,
  Pick<any, any>
> = styled.div`
  padding-bottom: 60px;
  width: 100%;
  .subheader {
    align-items: center;
    width: 100%;

    span {
      height: auto;
    }
  }

  .layout-config {
    display: flex;
    flex-wrap: wrap;

    .layout-dimension {
      .text-section {
        display: block;
        font-size: 0.75em;
      }

      .dimension {
        display: block;
        font-weight: bold;
        line-height: 1.3125;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100% - 20px);
      }
    }

    .layout-dimension-settings {
      align-items: center;
      justify-content: flex-end;
      display: flex;
      width: 50%;

      .button {
        font-size: 0.875em;
      }
    }

    .layout-preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .current-zone {
        cursor: pointer;
        padding: 20px;

        .layout {
          margin: 0 auto;
        }

        > .zone {
          margin: auto;
          padding-bottom: 28.125%;
          width: 50%;

          &.zone-PORTRAIT {
            padding-bottom: 50%;
            width: 28.125%;
          }

          .zone {
            background-color: ${Theme.color.darkGrey};
          }
        }

        .custom-zone-layout-LANDSCAPE,
        .custom-zone-layout-PORTRAIT {
          align-items: center;
          background: ${Theme.color.darkGrey};
          border-radius: 2px;
          display: flex;
          justify-content: center;
          margin: 0 auto;
          position: relative;
          padding-bottom: 28.125%;
          width: 50%;

          .custom-img {
            filter: invert(1);
            height: 20px;
            left: calc(50% - 10px);
            position: absolute;
            top: calc(50% - 10px);
            width: 20px;
          }
        }

        .custom-zone-layout-PORTRAIT {
          padding-bottom: 50%;
          width: 28.125%;
        }
      }

      :hover {
        background: ${Theme.color.white};

        .current-zone {
          > .zone {
            .zone {
              background-color: ${Theme.color.base};
            }
          }
        }
      }

      .button {
        width: 100%;
      }
    }

    .channel-preview {
      background-color: ${Theme.color.silver};
      margin: 0 auto;
      overflow: hidden;
    }
    .ui.label.circular {
      justify-content: center;
      margin: 10px 0 20px 0;
      cursor: pointer;
      user-select: none;
      width: 200px;

      :hover {
        background-color: ${Theme.color.darkGrey};
        > span {
          color: ${Theme.color.white};
        }
      }
    }

    .divider + div {
      margin-top: 0;
    }
  }

  .zone-label {
    padding: 10px 0;

    h3 {
      width: 100%;
      display: flex;
      align-items: center;

      .icon {
        background: ${Theme.color.base};
        height: 24px;
        width: 24px;
        border-radius: 50%;
        display: flex;

        i {
          background: ${Theme.color.white};
          height: 13px;
          margin: auto;
          width: 13px;
        }
      }
      .ui.label.circular {
        width: 24px;
        height: 24px;
        padding: 0;
        min-width: inherit;
        color: ${Theme.color.white};
        background: ${Theme.color.darkGrey};
        margin: 0;
        line-height: 23px;
        display: flex;
        justify-content: center;
      }

      span {
        font-weight: bold;
        line-height: 20px;
        margin-left: 5px;
      }
    }
  }

  .note {
    font-size: 0.875em;
    line-height: 1.357;
    color: ${Theme.color.darkGrey};
    background: ${Theme.color.nearWhite};
    border: 1px solid ${Theme.color.borderInput};
    padding: 15px;
  }
`;
