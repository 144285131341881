import { Theme } from "@screencloud/screencloud-ui-components";
import styled, { StyledComponent } from "styled-components";
import { imagePlaceholder } from "../../helpers/mediaHelper";
import { CoverColor, CoverImage } from "./index";

export interface StyledChangeCoverProps {
  type: string;
  color: CoverColor;
  image: CoverImage;
}

export const StyledThumbnail: StyledComponent<
  any,
  any,
  Pick<StyledChangeCoverProps, any>
> = styled.div`
  ${Theme.default.borderRadius};
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  height: 200px;
  padding: 50px 20px;
  width: 150px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  align-items: center;

  .edit {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.2);
    border: 2px solid ${Theme.color.white};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.1s ease-out;

    i {
      width: 20px;
      height: 20px;
      background-color: ${Theme.color.white};
    }
  }

  ${(props: StyledChangeCoverProps) =>
    props.type === "color" &&
    `
        background:linear-gradient(-45deg, ${props.color.top} 0%, ${props.color.bottom} 100%);
    `}

  ${(props: StyledChangeCoverProps) =>
    props.type === "image" &&
    props.image.url &&
    `
        background-color: ${Theme.color.lightGrey};
        background-image: url(${
          props.image.url ? props.image.url : imagePlaceholder
        });
        background-size: ${props.image.url ? "cover" : "70%"};
        background-repeat: no-repeat;
        background-position: center center;
    `}

    .overlay {
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    ${Theme.default.borderRadius};
    bottom: 0;
    display: inline-flex;
    height: 100px;
    opacity: 1;
    padding: 10px;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;

    .box {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
      bottom: 10px;
      content: "";
      display: inline-block;
      height: 8px;
      margin: 5px 0 0;
      position: absolute;
      width: 130px;

      :after,
      :before {
        border-radius: 5px;
        content: "";
        display: block;
        height: 20px;
        position: absolute;
      }

      :after {
        background: rgba(255, 255, 255, 0.2);
        margin: 0 0 5px;
        right: 0;
        top: -25px;
        width: 20px;
      }

      :before {
        background: rgba(255, 255, 255, 0.5);
        margin: 0 5px 0 0;
        top: -25px;
        width: calc(100% - 25px);
      }
    }
  }
  :hover {
    .edit {
      opacity: 1;
    }

    .overlay {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 28%,
        rgba(0, 0, 0, 0.5) 100%
      );
      opacity: 0.2;
    }
  }
`;
