import { LoaderBar } from "@screencloud/screencloud-ui-components";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { DropdownItemProps } from "semantic-ui-react";
import ScreenPicker, {
  ScreenPickerActions,
} from "../../components/ScreenPicker";
import {
  getDeleteLinkConfirmMsg,
  handleCreateLinkOpen,
} from "../../helpers/linkHelper";
import { generateImgixURL } from "../../helpers/mediaHelper";
import { appendNewTags } from "../../helpers/tagHelper";
import { useAppContext } from "../../hooks/useAppContext";
import queryHelper from "../../state/helper/query";

import {
  AllTagsDocument,
  AllTagsQuery,
  CastByCastIdFragment,
  CreateCastsByLinkIdMutationVariables,
  File,
  LinkByIdQueryResult,
  LinkListDocument,
  ScreenCastStopMutationVariables,
  SetScreenContentByLinkIdMutationVariables,
  UpdateLinkByIdMutation,
  UpdateLinkByIdMutationVariables,
  UpdateLinkIsFavoriteMutationVariables,
} from "../../types.g";
import { CastedScreenInfoActions } from "../CastedScreenInfo";
import { LinkProps, withData } from "./apollo";
import LinkPreviewUI, {
  LinkPreviewActions,
  LinkPreviewPayload,
} from "./preview";

export interface LinkPreviewComponentProps extends LinkProps {
  screenPickerComponent?: React.ReactNode;
  asScreenPicker?: boolean;
  onLinkPreviewCallBack?: (
    linkId: string,
    action: LinkPreviewActions,
    data: LinkPreviewPayload
  ) => void;
  router: any;
}

export interface LinkPreviewComponentState {
  link: NonNullable<LinkByIdQueryResult["data"]>["linkById"] | undefined;
  showDeleteConfirm: boolean;
}

const LinkPreviewComponent = (props: LinkPreviewComponentProps) => {
  const context = useAppContext();
  const {
    asScreenPicker,
    history,
    screenCastStop,
    createCastsByLinkId,
    updateLinkById,
    updateLinkIsFavorite,
    deleteLinkById,
    setContentByLinkId,
  } = props;
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<
    LinkPreviewComponentState["showDeleteConfirm"]
  >(false);

  const refetchLinkQueries = () => {
    const variable: { [key: string]: any } = {
      spaceId: context.user.settings.spaceId,
    };

    return {
      query: LinkListDocument,
      variables: variable,
    };
  };

  const onCastedScreensCallback = async (
    data: string,
    action: CastedScreenInfoActions
  ) => {
    switch (action) {
      case CastedScreenInfoActions.STOP_CAST:
        const castStopVar: ScreenCastStopMutationVariables = {
          input: {
            screenId: data,
          },
        };
        const castItem = {
          linkByCastId: {
            id: props.linkById?.id,
          },
        } as CastByCastIdFragment;
        screenCastStop(castStopVar, castItem).then(() => {});
        break;
      case CastedScreenInfoActions.NAVIGATE_SCREEN:
        history.push("/screens/" + data);
        context.modal.closeNavigationControlModal();
        context.modal.closeModals();
        break;
      default:
    }
  };

  const onLinkPreviewCallBack = async (
    linkId: string,
    action: LinkPreviewActions,
    data: LinkPreviewPayload
  ) => {
    switch (action) {
      case LinkPreviewActions.UPDATE_TAGS:
        if (data.tagsList) {
          const tags = data.tagsList
            .map((tag: any) => {
              return tag;
            })
            .sort();

          const updateLink: UpdateLinkByIdMutationVariables = {
            input: {
              id: linkId,
              tags,
            },
          };

          await updateLinkById({
            update: (proxy, updateData: { data: UpdateLinkByIdMutation }) => {
              if (updateData.data.updateLinkById?.link) {
                proxy.modify({
                  id: proxy.identify(updateData.data.updateLinkById?.link),
                  fields: {
                    tags() {
                      return tags.sort();
                    },
                  },
                });
              }
              // Read the data from our cache for this query.
              const cacheAllTags = cloneDeep(
                proxy.readQuery<AllTagsQuery>({ query: AllTagsDocument })
              );

              const newFileData = updateData.data;

              if (
                newFileData?.updateLinkById?.link?.tags &&
                cacheAllTags?.allTags
              ) {
                const updatedFileTags = cloneDeep(
                  newFileData.updateLinkById.link.tags
                );
                const existingTags = cacheAllTags.allTags.nodes;
                const updatedCacheAllTags = appendNewTags(
                  props.linkById?.orgId,
                  props.linkById?.spaceId,
                  updatedFileTags,
                  existingTags
                );
                cacheAllTags.allTags.nodes = (updatedCacheAllTags ?? []).map(
                  (tag) => {
                    return {
                      ...tag,
                      __typename: "Tag" as "Tag",
                      orgId: tag!.orgId!,
                      name: tag!.name!,
                    };
                  }
                );
                proxy.writeQuery({
                  query: AllTagsDocument,
                  data: cacheAllTags,
                });
              }
            },
            variables: updateLink,
          });
        }
        break;

      case LinkPreviewActions.RENAME:
        if (data.title) {
          const updateLink: UpdateLinkByIdMutationVariables = {
            input: {
              id: linkId,
              name: data.title,
            },
          };
          await updateLinkById({
            variables: updateLink,
            refetchQueries: [refetchLinkQueries()],
          });
        }
        break;

      case LinkPreviewActions.FAVORITE:
        const updateFavoriteLinkVariables: UpdateLinkIsFavoriteMutationVariables = {
          input: {
            linkIds: [linkId],
            isFavorite: data.checked ?? false,
          },
        };
        await updateLinkIsFavorite({
          variables: updateFavoriteLinkVariables,
        });
        break;

      case LinkPreviewActions.DELETE:
        if (props.linkById) {
          const { linkById } = props;
          const message = await getDeleteLinkConfirmMsg(
            linkById,
            linkById.castedScreenByLinkId.totalCount,
            linkById.castedScreenByLinkId.nodes
          );
          const { confirm } = await context.modal.confirm(message, {
            confirm: (
              <FormattedMessage
                id="ui_component.common.label.delete"
                defaultMessage="Delete"
              />
            ),
            isDanger: true,
          });

          if (confirm) {
            await deleteLinkById(linkId);
            context.modal.closeModals();
          }
        }
        break;

      case LinkPreviewActions.SHOW_DELETE_CONFIRM:
        setShowDeleteConfirm(true);

        break;

      case LinkPreviewActions.HIDE_DELETE_CONFIRM:
        setShowDeleteConfirm(false);
        break;

      case LinkPreviewActions.SHOW_SCREEN_PICKER:
        context.modal.openNavigationControlModal(
          <ScreenPicker
            callback={(selectedScreens, screenPickerAction, expiresAt) =>
              onScreenPickerCallback(
                selectedScreens,
                screenPickerAction,
                expiresAt
              )
            }
            disabledAction={false}
            itemToCast={props.linkById}
            title
          />,
          context.intl.formatMessage({
            defaultMessage: "Select screens to play",
            id: "ui_component.common.label.select_screens_to_play",
          }) +
            " " +
            props.linkById?.name,
          { opts: { disableTitle: false, overflow: true } }
        );
        break;
      case LinkPreviewActions.UPDATE_CACHE_OPTIONS:
        const updateLink: UpdateLinkByIdMutationVariables = {
          input: {
            id: linkId,
            cacheBusting: data.cacheBusting,
            autoReload: data.autoReload,
            autoReloadDurationMs: data.autoReloadDurationMs,
            params: data.params,
          },
        };
        await updateLinkById({
          update: (cache, updateData) => {
            if (updateData?.data?.updateLinkById?.link) {
              cache.modify({
                id: cache.identify(updateData.data.updateLinkById.link),
                fields: {
                  cacheBusting() {
                    return data.cacheBusting;
                  },
                  autoReload() {
                    return data.autoReload;
                  },
                  autoReloadDurationMs() {
                    return data.autoReloadDurationMs;
                  },
                  params() {
                    return data.params;
                  },
                },
              });
            }
          },
          variables: updateLink,
        });

        break;
      case LinkPreviewActions.UPDATE_CURRENT_URL:
        handleCreateLinkOpen(context, data.linkById);
        break;
      default:
        break;
    }
    if (props.onLinkPreviewCallBack) {
      props.onLinkPreviewCallBack(linkId, action, data);
    }
  };

  const onScreenPickerCallback = async (
    data: string[],
    action: ScreenPickerActions,
    expiresAt?: Date
  ) => {
    switch (action) {
      case ScreenPickerActions.SET_CONTENT:
        const setContents = data.map((screenId) => {
          const setLinkContent: SetScreenContentByLinkIdMutationVariables = {
            input: {
              screenId,
              linkId: props.linkById?.id,
            },
          };

          return setContentByLinkId({
            variables: setLinkContent,
          });
        });
        await Promise.all(setContents);
        context.modal.closeNavigationControlModal();
        break;
      case ScreenPickerActions.CASTING:
        const castStartVar: CreateCastsByLinkIdMutationVariables = {
          input: {
            screenIds: data,
            linkId: props.linkById?.id,
            expiresAt,
          },
        };

        const variable: { [key: string]: any } = {
          spaceId: context.user.settings.spaceId,
        };

        createCastsByLinkId({
          refetchQueries: [
            {
              query: LinkListDocument,
              variables: variable,
            },
          ],
          variables: castStartVar,
        }).then(() => {
          queryHelper.updateCastedScreen(context.user.settings.spaceId);
          context.modal.closeNavigationControlModal();
        });

        break;
      case ScreenPickerActions.CANCEL:
        if (asScreenPicker) {
          context.modal.closeNavigationControlModal();
        }
        break;
      case ScreenPickerActions.ADD_SCREEN:
        context.modal.closeNavigationControlModal();
        context.modal.closeModals();
        history.push("/screens/add");
        break;
      default:
    }
  };

  const getAllTags = () => {
    return (
      props.getAllTags?.data?.allTags?.nodes.map((item) => {
        return { text: item.name, value: item.name };
      }) ?? []
    );
  };

  if (props.linkById) {
    const createdBy = props.linkById?.userByCreatedBy
      ? props.linkById?.userByCreatedBy.givenName +
        " " +
        props.linkById?.userByCreatedBy.familyName
      : "";
    const allTags = getAllTags();
    const tagsList = [
      ...allTags,
      ...(props.linkById?.tags ?? []).map((tag) => ({ text: tag, value: tag })),
    ] as DropdownItemProps[];
    return (
      <LinkPreviewUI
        id={props.linkById?.id}
        spaceId={props.linkById?.spaceId}
        title={props.linkById?.name}
        url={props.linkById?.url}
        src={
          (props.linkById?.fileByFileId &&
            generateImgixURL(
              props.linkById?.fileByFileId as File,
              1024,
              1024,
              context.secureMediaPolicy
            )) ??
          ""
        }
        tags={props.linkById?.tags as string[]}
        tagsList={tagsList ?? []}
        cacheBusting={props.linkById?.cacheBusting ?? false}
        autoReload={props.linkById?.autoReload ?? false}
        autoReloadDurationMs={props.linkById?.autoReloadDurationMs ?? 3600000}
        params={props.linkById?.params ?? {}}
        logs={[]}
        favorite={props.linkById?.isFavorite!}
        createdAt={props.linkById?.createdAt}
        createdBy={createdBy!}
        callBack={onLinkPreviewCallBack}
        advanceSettingsCallBack={onLinkPreviewCallBack}
        showDeleteConfirm={showDeleteConfirm}
        castedScreens={
          (props.linkById?.castedScreenByLinkId.nodes ?? []).length
        }
        castedScreensData={props.linkById?.castedScreenByLinkId.nodes}
        castedScreensCallback={onCastedScreensCallback}
        linkById={props.linkById}
        qrcodeEnabled={props.linkById?.qrcodeEnabled ?? false}
      />
    );
  } else {
    return <LoaderBar />;
  }
};

export default withData(LinkPreviewComponent);
