import { AppContextType } from "src/AppContextProvider/type";
import { OnBoardingRefType } from "src/constants/constants";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";

export const isOrgCreatedMoreThan120Days = (createdAt: string): boolean => {
  const givenDate = new Date(createdAt);
  const currentDate = new Date();

  const timeDifferenceInMilliseconds =
    currentDate.getTime() - givenDate.getTime();
  const timeDifferenceInDays =
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);

  return timeDifferenceInDays > 120;
};

export const shouldHideGetStartedKCBot = (context: AppContextType) => {
  const isUserHideGetStartedKCBot =
    context.currentUser?.preferences.onboarding &&
    context.currentUser?.preferences.onboarding[OnBoardingRefType.KC_BOT_HIDE];

  return (
    isUserHideGetStartedKCBot ||
    isOrgCreatedMoreThan120Days(context.currentOrg?.createdAt) ||
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.RESELLER) ||
    !context.shouldShowFeature(FEATURE_FLAGS_ENUM.KC_BOT)
  );
};
