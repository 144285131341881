import {
  Dropdown,
  Icon,
  InfoBanner,
  Input,
  Loader,
  StatusInput,
  TagInput,
  ValidationStatus,
} from "@screencloud/screencloud-ui-components";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../../AppContextProvider/AppContext";
import { AppContextType } from "../../../AppContextProvider/type";
import { features } from "../../../constants/constants";
import { openNewTab } from "../../../helpers/adsHelper";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import { Styled } from "./styles";

export enum SitePickerViewActionMode {
  Create,
  Update,
}

export enum SitePickerViewMode {
  Initial = "initial",
  Validating = "validating",
  ValidBasic = "valid/basic",
  ValidCloud = "valid/cloud",
  InvalidError = "invalid/error",
  InvalidBadUrl = "invalid/unknown",
  CloudRenderUpgradeRequired = "upgrade/cloud",
}

export interface SitePickerViewProps {
  url: string;
  onUrlChange: (data: any) => void;
  onValidateUrl: (data: any) => void;

  name: string;
  nameError?: boolean;
  onNameChange: (data: any) => void;
  onValidateName: (data: any) => void;

  refreshInterval: string;
  onRefreshIntervalChange: (data: any) => void;

  refreshIntervalUnit: string;
  onRefreshIntervalUnitChange: (event: any, data: any) => void;

  tags: string[];
  onTagsChange: (data: any) => void;

  thumbnailData?: string;
  thumbnailError?: string;
  tagOptions: any[];

  actionMode: SitePickerViewActionMode;
  viewMode: SitePickerViewMode;

  onSubmit: (data: any) => void;

  isSaving: boolean;
  isDirty: boolean;

  // Advanced Settings
  zoom: number;
  scrollTo: number;
  scrollFrom: number;
  onZoomChange: (value: number) => void;
  onScrollToChange: (value: number) => void;
  onScrollFromChange: (value: number) => void;
}

interface UrlInputProps {
  url: string;
  onUrlChange: (data: any) => void;
  onValidateUrl: (data: any) => void;
  isValidating: boolean;
  validationStatus?: ValidationStatus;
  actionMode: SitePickerViewActionMode;
}

const UrlInput = (props: UrlInputProps) => {
  return (
    <>
      {props.actionMode === SitePickerViewActionMode.Create ? (
        <>
          <p>
            <FormattedMessage
              id="ui_component.site.picker.input_url_message"
              defaultMessage="Enter your url here:"
            />
          </p>
          <StatusInput
            placeholder="https://"
            onChange={props.onUrlChange}
            onBlur={props.onValidateUrl}
            value={props.url}
            isValidating={props.isValidating}
            isValidateComplete={props.validationStatus !== undefined}
            validationStatus={
              props.validationStatus !== undefined
                ? props.validationStatus
                : ValidationStatus.Success
            }
            spellCheck={false}
          />
        </>
      ) : (
        <>
          <p>
            <FormattedMessage
              id="ui_component.site.picker.url_message"
              defaultMessage="Site url:"
            />
          </p>
          <Input value={props.url} disabled={true} fluid={true} icon="lock" />
        </>
      )}
    </>
  );
};

interface SiteDetailProps {
  name: string;
  nameError?: boolean;
  refreshInterval: string;
  refreshIntervalUnit: string;
  tags: string[];
  tagOptions: any[];

  onNameChange: (data: any) => void;
  onValidateName: (data: any) => void;
  onRefreshIntervalChange: (data: any) => void;
  onRefreshIntervalUnitChange: (event: any, data: any) => void;
  onTagsChange: (data: any) => void;
}

const SiteDetail = (props: SiteDetailProps) => {
  const refreshIntervalOptions = [
    { key: "s", text: "seconds", value: "s" },
    { key: "m", text: "minutes", value: "m" },
    { key: "h", text: "hours", value: "h" },
  ];

  return (
    <div className="input">
      <div className="site-title">
        <h3 className="input-name">
          <FormattedMessage
            id="ui_component.common.label.name"
            defaultMessage="Name"
          />
        </h3>
        <Input
          error={props.nameError}
          onChange={props.onNameChange}
          onBlur={props.onValidateName}
          value={props.name}
        />
      </div>
      <div className="site-refresh-interval">
        <h3 className="input-name">
          <FormattedMessage
            id="ui_component.common.label.refresh_interval"
            defaultMessage="Refresh interval"
          />
        </h3>
        <Input
          label={
            <Dropdown
              className="refresh-interval-units"
              onChange={props.onRefreshIntervalUnitChange}
              value={props.refreshIntervalUnit}
              options={refreshIntervalOptions}
            />
          }
          labelPosition="right"
          value={props.refreshInterval}
          onChange={props.onRefreshIntervalChange}
          type="number"
          min={1}
        />
      </div>
      <div className="site-tag">
        <h3>
          <FormattedMessage
            id="ui_component.common.label.tags"
            defaultMessage="Tags"
          />
        </h3>
        <TagInput
          tagTitle="Tags"
          onTagsChanged={props.onTagsChange}
          currentTags={props.tags}
          tagsList={props.tagOptions}
        />
      </div>
    </div>
  );
};

interface SubmitActionProps {
  url: string;
  name: string;
  nameError?: boolean;
  actionMode: SitePickerViewActionMode;
  viewMode: SitePickerViewMode;
  onSubmit: (data: any) => void;
  isSaving: boolean;
  isDirty: boolean;
}

const isSubmitDisabled = (props: SubmitActionProps): boolean => {
  switch (props.viewMode) {
    case SitePickerViewMode.Initial:
    case SitePickerViewMode.Validating:
    case SitePickerViewMode.InvalidError:
    case SitePickerViewMode.CloudRenderUpgradeRequired:
      return true;
  }

  if (props.url.trim() === "") {
    return true;
  }

  if (
    props.name.trim() === "" ||
    (props.nameError !== undefined && props.nameError)
  ) {
    return true;
  }

  return !props.isDirty; // If the form isnt dirty, dont enable the update button.
};

const SubmitAction = (props: SubmitActionProps) => {
  return (
    <PrimaryButton
      className="submit"
      onClick={props.onSubmit}
      disabled={isSubmitDisabled(props)}
    >
      {props.actionMode === SitePickerViewActionMode.Create ? (
        props.viewMode === SitePickerViewMode.ValidCloud ? (
          <FormattedMessage
            id="ui_component.site.continue_to_secure_site"
            defaultMessage="Continue to Dashboard"
          />
        ) : (
          <FormattedMessage
            id="ui_component.site.add_site"
            defaultMessage="Add Dashboard"
          />
        )
      ) : (
        <FormattedMessage
          id="ui_component.site.update_site"
          defaultMessage="Update Dashboard"
        />
      )}
    </PrimaryButton>
  );
};

const PreviewInstructions = () => (
  <div className="instruction-hero">
    <div className="title">
      <FormattedMessage
        id="aui_component.site.picker.hero.title"
        defaultMessage="Enter a link to start"
      />
    </div>
    <div className="description">
      <FormattedMessage
        id="ui_component.site.picker.hero.sub_title"
        defaultMessage="Copy & paste the url to load preview"
      />
    </div>
  </div>
);

const EmptyPreview = () => <div className="site-preview" />;

interface ThumbnailPreviewProps {
  thumbnailData?: string;
  thumbnailError?: string;
}

const ThumbnailPreview = (props: ThumbnailPreviewProps) => {
  const [toastOpen, updateToastOpen] = useState(true);

  return (
    <>
      {props.thumbnailData ? (
        <div className="image-wrapper">
          <img src={props.thumbnailData} />
        </div>
      ) : props.thumbnailError ? (
        <>
          <div className={`toast error ${toastOpen ? "open" : ""}`}>
            <div className="close" onClick={(e) => updateToastOpen(false)}>
              <Icon name="close" />
            </div>
            <div className="title">
              <FormattedMessage
                id="aui_component.site.picker.hero.title_error"
                defaultMessage="Oops..."
              />
            </div>
            <div className="description">
              <FormattedMessage
                id="ui_component.site.picker.hero.sub_title_error_preview"
                defaultMessage="Something went wrong loading the preview.  You can continue adding the site."
              />
            </div>
          </div>
          <EmptyPreview />
        </>
      ) : (
        <Loader size="small" inline active />
      )}
    </>
  );
};

// TODO - Collapse Error and Warning toasts into single component
// Pass type & message details in props
const SiteError = (props: React.PropsWithChildren<{}>) => {
  const [toastOpen, updateToastOpen] = useState(true);

  return (
    <>
      <div className={`toast error ${toastOpen ? "open" : ""}`}>
        <div className="close" onClick={(e) => updateToastOpen(false)}>
          <Icon name="close" />
        </div>
        <div className="title">
          <FormattedMessage
            id="aui_component.site.picker.hero.title_error"
            defaultMessage="Oops..."
          />
        </div>
        <div className="description">
          <FormattedMessage
            id="ui_component.site.picker.hero.sub_title_error"
            defaultMessage="Something went wrong trying to check this site.  Please try again later."
          />
        </div>
      </div>
      {props.children}
    </>
  );
};

const InternalSite = (props: React.PropsWithChildren<{}>) => {
  const [toastOpen, updateToastOpen] = useState(true);

  return (
    <>
      <div className={`toast warning ${toastOpen ? "open" : ""}`}>
        <div className="close" onClick={(e) => updateToastOpen(false)}>
          <Icon name="close" />
        </div>
        <div className="title">
          <FormattedMessage
            id="aui_component.site.picker.hero.title_internal"
            defaultMessage="Internal Site?"
          />
        </div>
        <div className="description">
          <FormattedMessage
            id="ui_component.site.picker.hero.sub_title_internal"
            defaultMessage="We couldn't check this site.  It might be behind your firewall.  You can continue to add it, but note it might not display properly."
          />
        </div>
      </div>
      {props.children}
    </>
  );
};

class UpgradeRequired extends React.Component<{}, {}> {
  public static contextType = AppContext;
  public context: AppContextType;

  public render() {
    return (
      <div className="toast rendered open">
        <div className="title">Secure Sites</div>
        <div className="description">
          This site needs something a little extra special.
        </div>
        <div className="description">
          Dashboards allow you to display password-protected websites with
          confidence and ease.
        </div>
        <div className="sales-button">
          <PrimaryButton
            onClick={() => {
              openNewTab({
                section: features.DASHBOARDS,
                user: this.context.currentUser,
              });
            }}
            className="setup-button"
          >
            {this.context.intl.formatMessage({
              defaultMessage: "Contact Sales",
              id: "ads.contact_sales.button",
            })}
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export interface SitePickerViewModeConfig {
  urlInputElement: JSX.Element;
  siteDetailElement: JSX.Element;
  advancedSettings: JSX.Element;
  previewElement: JSX.Element;
}

function configureRendererForView(
  props: SitePickerViewProps
): SitePickerViewModeConfig {
  switch (props.viewMode) {
    case SitePickerViewMode.Initial:
      return {
        urlInputElement: <UrlInput isValidating={false} {...props} />,
        siteDetailElement: <div />,
        advancedSettings: <div />,
        previewElement: <PreviewInstructions />,
      };
    case SitePickerViewMode.Validating:
      return {
        urlInputElement: <UrlInput isValidating={true} {...props} />,
        siteDetailElement: <div />,
        advancedSettings: <div />,
        previewElement: <EmptyPreview />,
      };
    case SitePickerViewMode.ValidBasic:
      return {
        urlInputElement: (
          <UrlInput
            isValidating={false}
            validationStatus={ValidationStatus.Success}
            {...props}
          />
        ),
        siteDetailElement: <SiteDetail {...props} />,
        advancedSettings: <div />,
        previewElement: <ThumbnailPreview {...props} />,
      };
    case SitePickerViewMode.ValidCloud:
      return {
        urlInputElement: (
          <UrlInput
            isValidating={false}
            validationStatus={ValidationStatus.Success}
            {...props}
          />
        ),
        siteDetailElement: (
          <InfoBanner
            title="This website does not support display in an iframe"
            description={
              <span className="info-text">
                You can continue setting up this website as a dashboard.
              </span>
            }
            color="#4C4D52"
            icon="warning"
          />
        ),
        advancedSettings: <></>,
        previewElement: <ThumbnailPreview {...props} />,
      };
    case SitePickerViewMode.InvalidError:
      return {
        urlInputElement: (
          <UrlInput
            isValidating={false}
            validationStatus={ValidationStatus.Error}
            {...props}
          />
        ),
        siteDetailElement: <div />,
        advancedSettings: <div />,
        previewElement: (
          <SiteError>
            <EmptyPreview />
          </SiteError>
        ),
      };
    case SitePickerViewMode.InvalidBadUrl:
      return {
        urlInputElement: (
          <UrlInput
            isValidating={false}
            validationStatus={ValidationStatus.Warning}
            {...props}
          />
        ),
        siteDetailElement: <SiteDetail {...props} />,
        advancedSettings: <div />,
        previewElement: (
          <InternalSite>
            <EmptyPreview />
          </InternalSite>
        ),
      };
    case SitePickerViewMode.CloudRenderUpgradeRequired:
      return {
        urlInputElement: (
          <UrlInput
            isValidating={false}
            validationStatus={ValidationStatus.Warning}
            {...props}
          />
        ),
        siteDetailElement: <div />,
        advancedSettings: <div />,
        previewElement: (
          <UpgradeRequired>
            <EmptyPreview />
          </UpgradeRequired>
        ),
      };
  }
}

const SitePickerView = (props: SitePickerViewProps) => {
  const state = configureRendererForView(props);

  return (
    <Styled>
      <div className="site-config">
        <div className="site-form">
          <div className="site-input">{state.urlInputElement}</div>
          <div className="site-detail">{state.siteDetailElement}</div>
          <div className="site-advanced-settings">{state.advancedSettings}</div>
        </div>
        <div className="site-preview">{state.previewElement}</div>
      </div>
      <div className="site-action">
        <SubmitAction {...props} />
      </div>
    </Styled>
  );
};

export default SitePickerView;
