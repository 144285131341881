import { Cast } from "src/types.g";

/**
 *This is just an abstraction layer to make it easier to understand that createdAt is castedDate and updated at is not relevant
 */
export function getCastedDate(cast: Pick<Cast, "createdAt">): string {
  return cast.createdAt || "";
}

export function getExpiredDate(cast: Pick<Cast, "expiresAt">): string {
  return cast.expiresAt || "";
}
