import styled from "styled-components";

export const NoteStyled = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  background: var(--color-yellow-100);
  padding-bottom: calc(var(--spacing-xl, 24px) + 5px);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

  min-height: 80px;
  max-height: 40vh;

  .loading {
    background: var(--color-yellow-50);
  }
  &:hover:not(.loading) {
    background: var(--color-yellow-200);
  }

  .note-textarea {
    border: 0;
    background: transparent;
    color: var(--color-yellow-900);
    font-size: 16px;
    line-height: 24px;
    resize: none;
    flex: 1;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .note-textarea::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .updated-info {
    position: absolute;
    bottom: 5px;
    right: 10px;

    color: var(--color-yellow-600, #d19800);
    text-align: right;
    font-style: italic;
    font-weight: 700;
    font-size: 12px;

    height: 25px;
  }
  .remove-button {
    display: none;
  }

  &.modal {
    padding: var(--spacing-xl, 24px);
    padding-bottom: calc(var(--spacing-xl, 24px) + 36px);

    max-height: 80vh;
    min-height: 200px;

    .updated-info {
      bottom: var(--spacing-xl, 24px);
      right: var(--spacing-xl, 24px);
    }
    .remove-button {
      display: block;
      position: absolute;
      bottom: var(--spacing-xl, 24px);
      left: var(--spacing-xl, 24px);
    }
    .note-textarea {
      width: calc(100% - 25px);
    }
  }
`;
