import { useApolloClient } from "@apollo/client";
import { CastableEntity } from "src/domains/castableEntity/castableEntity";
import { useRootFolderId } from "src/domains/folder/hooks/useRootFolderId";

export function useCacheInvalidation() {
  const client = useApolloClient();
  const cache = client.cache;
  const rootFolderId = useRootFolderId();

  /**
   * This is actually Invalidate items from folderById Query but this name is easy to understand.
   * Use case: when restored medias: It will go back to the root folder always no matter what is the location of that media before even if it is on subfolder
   *
   */
  const invalidateFolderByIdCacheOnRootFolder = () => {
    cache.evict({ id: `Folder:${rootFolderId}` });
    cache.gc();
  };

  const invalidateEntityCache = (entity: CastableEntity) => {
    cache.evict({ id: cache.identify(entity) });
    cache.gc();
  };

  const invalidateScreenGroupCache = () => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "defaultScreenGroupBySpaceId",
    });
    cache.gc();
  };

  return {
    invalidateFolderByIdCacheOnRootFolder,
    invalidateScreenGroupCache,
    invalidateEntityCache,
  };
}
