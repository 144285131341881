import { Button, Icon } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { AvailableAppInstanceFragment } from "../../types.g";
import { AppTabPaneItemActions } from "../AppTabPane/appItem";
import { CanvasAppTabPaneList } from "./canvasAppTabPaneList";
import { StyledAppinstall } from "../AppTabPane/styles";
import { PropsWithChildren } from "react";

export interface CanvasAppTabPaneContainerProps {
  searchTerms: string;
  addItemToPlaylist: (
    app: AvailableAppInstanceFragment,
    action: AppTabPaneItemActions
  ) => void;
}

export function CanvasAppTabPane(props: PropsWithChildren<{}>) {
  const { children } = props;
  const history = useHistory();
  const handleAddNewCanvas = () => {
    history.push({ pathname: "/canvas" });
  };

  return (
    <StyledAppinstall>
      <div className={`layout-list app-section`}>
        <FormattedMessage id="canvas.canvases" defaultMessage="Canvases" />
        <Button
          className="add-appinstance"
          mini
          icon
          onClick={handleAddNewCanvas}
          data-testid="add-app-button"
        >
          <Icon name="plus" />
        </Button>
      </div>
      <div className="layout-container" id="scrollableDiv">
        <div>
          <div className="app-instance-list">{children}</div>
        </div>
      </div>
    </StyledAppinstall>
  );
}

export function CanvasAppTabPaneContainer(
  props: CanvasAppTabPaneContainerProps
) {
  const { addItemToPlaylist, searchTerms } = props;
  return (
    <CanvasAppTabPane>
      <CanvasAppTabPaneList
        searchTerms={searchTerms}
        addItemToPlaylist={addItemToPlaylist}
      />
    </CanvasAppTabPane>
  );
}
