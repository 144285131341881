import { appConfig } from "../appConfig";
import { User } from "../types.g";

export const getFullName = (user: Partial<User>) => {
  return [user?.givenName, user?.familyName].filter(Boolean).join(" ");
};

/**
 * Display full name, If there is no full name, use email to display
 */
export const getDisplayName = (user: Partial<User> | undefined | null) => {
  if (!user) {
    return "";
  }

  const fullName = getFullName(user);
  return fullName ? fullName : user.email ?? "";
};

export const getAvatar = (user: User) => user?.metadata?.picture;

export const getTokenPayloadByRegions = (tokenPayloadsByRegion) => {
  const result: any = {
    all: [],
    current: {},
    other: [],
  };

  if (!!tokenPayloadsByRegion) {
    tokenPayloadsByRegion.map((region) => {
      result.all.push(...region.tokenPayloads);

      if (region.region.key === appConfig.regions.current.key) {
        let currentItem;
        region.tokenPayloads.map((item) => {
          currentItem = { ...item, ...region.region };
        });
        result.current = { ...result.current, ...currentItem };
      } else {
        let otherItem;
        region.tokenPayloads.map((item) => {
          otherItem = { ...item, ...region.region };
          result.other.push(otherItem);
        });
      }
    });
  }

  return result;
};
