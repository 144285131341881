import { Button, Dropdown, Icon } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { Styled } from "./styles";

import { useAppContext } from "../../hooks/useAppContext";
import { AppContextType } from "src/AppContextProvider/type";

export enum LinkListHeaderActions {
  SORT_BY_DATE = "SORT_BY_DATE",
  SORT_BY_NAME = "SORT_BY_NAME",
  SORT_BY_STARRED_ASC = "SORT_BY_STARRED_ASC",
  SORT_BY_STARRED_DESC = "SORT_BY_STARRED_DESC",
}

export interface Props {
  callBack: (action: LinkListHeaderActions) => void;
  className?: string;
  sortBy: LinkListHeaderActions;
}

export interface State {}

const sortingDropdown = [
  {
    key: LinkListHeaderActions.SORT_BY_DATE,
    text: (
      <FormattedMessage
        id="ui_component.common.label.latest"
        defaultMessage="Latest"
      />
    ),
    value: LinkListHeaderActions.SORT_BY_DATE,
  },
  {
    key: LinkListHeaderActions.SORT_BY_NAME,
    text: (
      <FormattedMessage
        id="ui_component.common.label.name"
        defaultMessage="Name"
      />
    ),
    value: LinkListHeaderActions.SORT_BY_NAME,
  },
];

function LinkListHeader(props: Props) {
  const context: AppContextType = useAppContext();

  // disabled for now since no API support for order by favorite yet
  const handleColumnStarredClick = () => {
    // let data: LinkListHeaderActions
    // switch (props.sortBy) {
    //   case LinkListHeaderActions.SORT_BY_STARRED_ASC:
    //     data = LinkListHeaderActions.SORT_BY_STARRED_DESC
    //     break
    //   case LinkListHeaderActions.SORT_BY_STARRED_DESC:
    //     data = LinkListHeaderActions.SORT_BY_STARRED_ASC
    //     break
    //   default:
    //     data = LinkListHeaderActions.SORT_BY_STARRED_ASC
    // }
    //  props.callBack(data)
  };

  const handleSortingDropdown = (event, data) => {
    props.callBack(data.value);
  };

  return (
    <Styled className={props.className}>
      <div className="media-core">
        <div className="media-alpha">
          <Dropdown
            data-testid="sortby-dropdown"
            inline
            options={sortingDropdown}
            defaultValue={sortingDropdown[0].key}
            onChange={handleSortingDropdown}
          />
        </div>
        {context.shouldShowFeature(FEATURE_FLAGS_ENUM.STARRING) && (
          <div className="media-starred">
            <Button transparent onClick={() => handleColumnStarredClick()}>
              <FormattedMessage
                id="subheader.label.starred"
                defaultMessage="Starred"
              />
              {props.sortBy === LinkListHeaderActions.SORT_BY_STARRED_ASC && (
                <Icon name="caret-down" />
              )}
              {props.sortBy === LinkListHeaderActions.SORT_BY_STARRED_DESC && (
                <Icon name="caret-up" />
              )}
            </Button>
          </div>
        )}

        {context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING) && (
          <div className="media-casting">
            <FormattedMessage
              id="subheader.label.casting"
              defaultMessage="Casting"
            />
          </div>
        )}
        <div className="media-type">
          <FormattedMessage id="subheader.label.type" defaultMessage="Type" />
        </div>
        <div className="media-url">
          <FormattedMessage id="subheader.label.url" defaultMessage="URL" />
        </div>
        <div className="media-tags">
          <FormattedMessage id="subheader.label.tags" defaultMessage="Tags" />
        </div>
      </div>
    </Styled>
  );
}

export default LinkListHeader;
