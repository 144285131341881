import {
  CollectionSubscriptionCallback,
  EntitySubscriptionCallback,
  EntityType,
  FirestoreClient,
  UnsubscribeCallback,
} from "@screencloud/signage-firestore-client";

import { appConfig } from "../appConfig";
import { EMPTY_VOID_FUNCTION } from "../constants/constants";

let client: FirestoreClient | null = null;

function getClient(orgId: string) {
  if (client === null) {
    client = new FirestoreClient({
      firebase: appConfig.firebase,
      organizationId: orgId,
    });
  }
  if (client.organizationId !== orgId) {
    client.organizationId = orgId;
  }
  return client;
}

export function subscribeToOrganization(
  orgId: string,
  subscriptionCallback: EntitySubscriptionCallback,
  skipInitialCallback?: boolean
) {
  return getClient(orgId).subscribeToOrganizationChange(
    subscriptionCallback,
    skipInitialCallback
  );
}

export function subscribeToListUpdates(
  orgId: string,
  documentType: EntityType,
  subscriptionCallback: CollectionSubscriptionCallback,
  skipInitialCallback?: boolean
) {
  return getClient(orgId).subscribeToCollectionChange(
    documentType,
    subscriptionCallback,
    skipInitialCallback
  );
}

export function subscribeToDocumentUpdates(
  orgId: string,
  documentType: EntityType,
  documentId: string,
  subscriptionCallback: EntitySubscriptionCallback,
  skipInitialCallback?: boolean
): UnsubscribeCallback {
  if (!orgId || !documentType || !documentId) {
    console.warn(
      "[subscribeToDocumentUpdates] can't subscribe, some variables are missing",
      {
        orgId,
        documentType,
        documentId,
      }
    );
    return EMPTY_VOID_FUNCTION;
  }

  return getClient(orgId).subscribeToEntityChange(
    documentType,
    documentId,
    subscriptionCallback,
    skipInitialCallback
  );
}

export function unsubscribeFromListUpdates(
  orgId: string,
  documentType: EntityType
) {
  getClient(orgId).unsubscribeFromCollectionChange(documentType);
}

export function unsubscribeFromDocumentUpdates(
  orgId: string,
  documentType: EntityType,
  documentId: string
) {
  getClient(orgId).unsubscribeFromEntityChange(documentType, documentId);
}
