/**
 * In some case such as clicking foler/file
 * user might click ctril/shift in order to select multiple items
 * so we might not want to trigger clicking event so we use this helper to check
 */
export function isInMultipleSelection(event?: React.MouseEvent) {
  if (!event) {
    return;
  }

  // metaKey is command key on Mac
  const { shiftKey, metaKey, ctrlKey } = event;
  return shiftKey || metaKey || ctrlKey;
}
