import { uniqBy } from "lodash";
import { fieldsPolicyType } from "./apolloClient";

export const screenListField: fieldsPolicyType = {
  screenGroupsBySpaceId: {
    keyArgs: ["condition", "orderBy"],
    merge(existing, incoming, options) {
      const existingNodes = existing ? [...existing.nodes] : [];
      let incomingNodes = incoming?.nodes ? [...incoming.nodes] : [];
      let nodes = uniqBy([...existingNodes, ...incomingNodes], "__ref");
      if (options.storeFieldName.includes('"condition":{"isPrimary":false}')) {
        incomingNodes = incomingNodes.filter((item) => {
          return options.readField("isPrimary", item) === false;
        });
      }

      nodes = uniqBy([...existingNodes, ...incomingNodes], "__ref");

      return {
        ...options.mergeObjects(existing, incoming),
        nodes,
        pageInfo: incoming.pageInfo,
      };
    },
    read(existing, options) {
      let existingNodes = existing?.nodes ? [...existing?.nodes] : [];
      if (options.storeFieldName.includes('"condition":{"isPrimary":false}')) {
        existingNodes = existingNodes.filter((item) => {
          return options.readField("isPrimary", item) === false;
        });
      }

      return (
        existing && {
          ...existing,
          nodes: Object.values(existingNodes),
          pageInfo: existing.pageInfo,
        }
      );
    },
  },
};
