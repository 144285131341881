import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  background-color: ${Theme.color.nearWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  img {
    width: 27%;
    margin: 10% auto 5% auto;
  }

  .not-configured-instructions {
    span {
      font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
      font-size: 16px;
    }
  }
`;
