import { Button, Checkbox, Icon } from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import { useCallback, useState } from "react";
import {
  AppInstanceByIdExtendedAssociationQuery,
  File,
  FileAssociationsFragment,
  GetCurrentReplacementJobsDocument,
  Link,
  PlaylistAndChannelAssociationsFragment,
  useCreateContentReplacementJobMutation,
} from "src/types.g";
import { RefType } from "../../constants/constants";
import { PrimaryButton } from "../../helpers/whiteLabel";
import { useAppContext } from "../../hooks/useAppContext";
import { getCurrentUserInterfaceVisibilitiesByContext } from "../../userInterfaceVisibility/useUserInterfaceVisibilities";
import { MediaPickerActionMode } from "../MediaPicker/media";
import { renderContentPreview } from "../MediaPreview/utils/getPlayerPreview";
import { ScreenPickerActions } from "../ScreenPicker";
import { StyledReplaceContent } from "./styles";
import { Entity, EntityType } from "./types";
import { filterAssociations, getUsageEntities } from "./utils";
import { useReplaceContentStatus } from "../ReplaceContentStatus/hooks/useReplaceContentStatus";
import { AppInstancesExtendedAssociation } from "src/hooks/useAllAppinstances";
import { isVideo } from "src/helpers/mediaHelper";

type Props = {
  onCloseModal: () => void;
  associations: PlaylistAndChannelAssociationsFragment[];
  originContent: File | AppInstancesExtendedAssociation | Link;
};

export type Content =
  | (File & { type: RefType.FILE })
  | (Link & { type: RefType.LINK })
  | (AppInstancesExtendedAssociation & { type: RefType.APP })
  | (AppInstanceByIdExtendedAssociationQuery["appInstanceById"] & {
      type: RefType.APP;
    })
  | undefined;

const ReplaceContent = (props: Props): JSX.Element => {
  const { associations, onCloseModal, originContent } = props;
  const context = useAppContext();
  const { secureMediaPolicy } = context;
  const [selectedEntities, setSelectedEntities] = useState<Entity[]>([]);
  const [replacementContent, setReplacementContent] = useState<Content>(
    undefined
  );
  const [createReplacementJob] = useCreateContentReplacementJobMutation();
  const { setIsFromReplaceModal } = useReplaceContentStatus();

  const filteredAssociations = filterAssociations(
    associations ? (associations as any) : ([] as FileAssociationsFragment[])
  );

  const usageEntities = getUsageEntities(
    Object.keys(filteredAssociations),
    filteredAssociations
  );
  const { playlists, channels } = usageEntities;
  const sortedChannels = channels.sort((a, b) => (b.name < a.name ? 1 : -1));
  const sortedPlaylists = playlists.sort((a, b) => (b.name < a.name ? 1 : -1));

  const onChooseReplacementContent = async (_event) => {
    const { mediaPicker } = getCurrentUserInterfaceVisibilitiesByContext(
      context
    );
    let menu: RefType[] = [
      RefType.FILE,
      RefType.LINK,
      RefType.CANVAS,
      RefType.APP,
    ];
    if (!mediaPicker.isShowLink) {
      menu = menu.filter((m) => m !== RefType.LINK);
    }
    const result = await context.modal.openMediaPicker(
      MediaPickerActionMode.REPLACE,
      "Select Replacement Content",
      {
        menu,
        multiple: false,
        section: RefType.FILE,
        screenPickerAction: ScreenPickerActions.SET_CONTENT,
      }
    );
    // if user select video, reset replacement content to make video player reload
    if (result.mediaType === "file" && isVideo(result.data[0] as File)) {
      setReplacementContent(undefined);
    }
    setReplacementContent({
      ...result.data[0],
      type: result.mediaType,
    } as Content);
  };

  const onSelectAllEntity = (entityType: EntityType) => {
    if (isSelectedAll(entityType)) {
      const filteredEntities = selectedEntities.filter(
        (entity) => entity.type !== entityType.toLowerCase()
      );
      setSelectedEntities(filteredEntities);
    } else {
      if (entityType === EntityType.Channel) {
        setSelectedEntities([...selectedEntities, ...channels]);
      } else {
        setSelectedEntities([...selectedEntities, ...playlists]);
      }
    }
  };

  const isEntitySelected = (entityId: UUID): boolean => {
    return selectedEntities.some((entity) => entity.id === entityId);
  };

  const isSelectedAll = (type: EntityType): boolean => {
    if (type === EntityType.Channel) {
      return channels.every((item) => {
        return selectedEntities.find((i) => i.id === item.id);
      });
    } else {
      return playlists.every((item) => {
        return selectedEntities.find((i) => i.id === item.id);
      });
    }
  };

  const someIsSelected = (type: EntityType): boolean => {
    return (
      !isSelectedAll(type) &&
      selectedEntities.filter((i) => i.type === type.toLowerCase()).length > 0
    );
  };

  const toggleSelectedEntity = (entity: Entity) => {
    const isSelected = selectedEntities.some((i) => i.id === entity.id);
    if (isSelected) {
      setSelectedEntities(selectedEntities.filter((i) => i.id !== entity.id));
    } else {
      setSelectedEntities([...selectedEntities, entity]);
    }
  };

  const getOriginalContentType = useCallback(() => {
    if (originContent.__typename === "File") {
      return RefType.FILE;
    } else if (originContent.__typename === "AppInstance") {
      return RefType.APP;
    }
    return RefType.LINK;
  }, [originContent]);

  const onReplace = async () => {
    const currentSpaceId = context.currentSpace?.id;
    setIsFromReplaceModal(true);

    await createReplacementJob({
      variables: {
        input: {
          targetEntities: selectedEntities.map((entity) => ({
            id: entity.id,
            type: entity.type,
          })),
          spaceId: currentSpaceId,
          originContentId: originContent.id,
          originContentType: getOriginalContentType(),
          newContentId: replacementContent?.id,
          newContentType: replacementContent?.type,
        },
      },
      refetchQueries: [
        {
          query: GetCurrentReplacementJobsDocument,
          variables: {
            spaceId: context.currentSpace?.id,
          },
        },
      ],
    });
    onCloseModal();
  };

  return (
    <StyledReplaceContent>
      <div className="preview-section">
        <div className="block current-file">
          <span className="label">Current</span>
          <div className="media-preview">
            {renderContentPreview(
              { ...originContent, type: getOriginalContentType() as any },
              secureMediaPolicy as string
            )}
          </div>
        </div>
        <div className="block replacement-file">
          <span className="label">Replacement</span>
          <div className="media-preview">
            {replacementContent ? (
              <>
                {renderContentPreview(
                  replacementContent,
                  secureMediaPolicy as string
                )}
                <PrimaryButton onClick={onChooseReplacementContent} mini>
                  Choose Replacement
                </PrimaryButton>
              </>
            ) : (
              <PrimaryButton onClick={onChooseReplacementContent} mini>
                Choose Replacement
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
      <div className="row assignment-section">
        <p>
          <b>{originContent.name}</b>&nbsp;<span>is currently found in</span>
        </p>
        {channels.length > 0 && (
          <div className="group">
            <div className="group-head">
              <Icon name="channel" />
              <h3>Channels</h3>
              <Checkbox
                onClick={() => onSelectAllEntity(EntityType.Channel)}
                checked={isSelectedAll(EntityType.Channel)}
                indeterminate={someIsSelected(EntityType.Channel)}
              />
            </div>
            <div className="list">
              {sortedChannels.map((channel) => (
                <div
                  className="list-item"
                  onClick={() => toggleSelectedEntity(channel)}
                  key={`channel-item-${channel.id}`}
                >
                  <div
                    className="thumbnail"
                    style={{
                      background: channel.coverImageSource
                        ? `url(${channel.coverImageSource}) center center/cover no-repeat`
                        : `linear-gradient(-45deg, ${channel.coverData.color.top} 0%, ${channel.coverData.color.bottom} 100%)`,
                    }}
                  ></div>
                  <p>{channel.name}</p>
                  <Checkbox
                    checked={isEntitySelected(channel.id)}
                    onClick={() => toggleSelectedEntity(channel)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {playlists.length > 0 && (
          <div className="group">
            <div className="group-head">
              <Icon name="playlist" />
              <h3>Playlists</h3>
              <Checkbox
                onClick={() => onSelectAllEntity(EntityType.Playlist)}
                checked={isSelectedAll(EntityType.Playlist)}
                indeterminate={someIsSelected(EntityType.Playlist)}
              />
            </div>
            <div className="list">
              {sortedPlaylists.map((playlist) => (
                <div
                  className="list-item"
                  onClick={() => toggleSelectedEntity(playlist)}
                  key={`playlist-item-${playlist.id}`}
                >
                  <div
                    className="playlist-thumbnail"
                    style={{
                      backgroundColor: playlist.color,
                      transform: "scale(1)",
                    }}
                  >
                    <Icon name="playlist" />
                  </div>
                  <p>{playlist.name}</p>
                  <Checkbox
                    checked={isEntitySelected(playlist.id)}
                    onClick={() => toggleSelectedEntity(playlist)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="md-footer">
        <Button
          data-testid="replace-content-button"
          data-cy="replace-content-button"
          onClick={() => onCloseModal()}
        >
          Go Back
        </Button>
        <PrimaryButton
          onClick={onReplace}
          disabled={selectedEntities.length <= 0 || !replacementContent}
        >
          Replace
        </PrimaryButton>
      </div>
    </StyledReplaceContent>
  );
};

export default ReplaceContent;
