import { File, Folder } from "../types.g";
export enum MediaItemType {
  MEDIA = "MEDIA",
  MEDIA_FILE = "MEDIA_FILE",
  MEDIA_FOLDER = "MEDIA_FOLDER",
}

export interface MediaDropItem {
  mediaId: string;
  type: MediaItemType;
  selectedMediaItems?: (File | Folder)[];
}
export interface MediaDropResult {
  source: MediaDropItem;
  destination: MediaDropItem;
}
