import { Component } from "react";
import { CoverColor } from "../CoverThumbnail";
import { colorsPreset } from "./colorsPreset";

export interface Props {
  onChangeColor?: (color: CoverColor) => void;
  selectedColor: CoverColor;
}

export interface State {
  selectedColor: CoverColor;
}

class BackgroundColorPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedColor: this.props.selectedColor,
    };
  }

  public getBgColorIdxByName = (selectedColor: CoverColor | undefined) => {
    if (selectedColor) {
      for (let i = 0; i < colorsPreset.length; i++) {
        const color: CoverColor = colorsPreset[i];
        if (color.name === selectedColor.name) {
          return i;
        }
      }
    }
    return -1;
  };

  public onChangeColorHandler = (
    selectedBgIdx: number,
    selectedColor: CoverColor
  ) => {
    this.setState({ selectedColor });
    if (this.props.onChangeColor) {
      this.props.onChangeColor(selectedColor);
    }
  };

  public render(): JSX.Element | any {
    const selectedColorIdx: number = this.getBgColorIdxByName(
      this.state.selectedColor
    );
    const colors = colorsPreset.map((color, idx) => {
      return (
        <div
          onClick={() => this.onChangeColorHandler(idx, color)}
          key={`bg-color-${idx}`}
          className="color-palette"
          style={{
            background: `linear-gradient(-45deg, ${color.top} 0%, ${color.bottom} 100%)`,
          }}
        >
          <span
            id={`target${idx}`}
            className={`color-active target ${
              selectedColorIdx === idx ? "selected" : ""
            }`}
          />
        </div>
      );
    });
    return (
      <div className="panel-container">
        <div className="color-container">{colors}</div>
      </div>
    );
  }
}

export default BackgroundColorPanel;
