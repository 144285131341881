import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Button,
  Icon,
  Loader,
  Popover,
} from "@screencloud/screencloud-ui-components";
import { CastedScreenInfoStyled, Styled } from "./style";
import { useAppContext } from "../../hooks/useAppContext";
import { cloneDeep } from "lodash";
import { UNKNOWN_DEVICE } from "../../constants/constants";

export enum CastedScreenInfoActions {
  NAVIGATE_SCREEN = "NAVIGATE_SCREEN",
  NAVIGATE_DEVICE = "NAVIGATE_DEVICE",
  STOP_CAST = "STOP_CAST",
}

export interface CastedScreenInfoProps {
  callBack?: (data: string, action: CastedScreenInfoActions) => void;
  screens: ScreenDetailProps[];
  totalCount?: number;
  hideScreenCount?: boolean;
}

export interface ScreenDetailProps {
  screenId: string;
  deviceId: string;
  screenName: string;
  deviceModel: string;
}

export function CastedScreenInfo(props: CastedScreenInfoProps) {
  const { screens, callBack, totalCount } = props;
  const context = useAppContext();
  const [screenUpdatingList, setScreenUpdatingList] = useState<string[]>([]);

  useEffect(() => {
    const screenIds = screens.map((s) => s.screenId);
    setScreenUpdatingList(
      screenUpdatingList.filter((v) => screenIds.includes(v))
    );
    return () => {
      setScreenUpdatingList([]);
    };
  }, [screens]);

  const handleCallback = (data: string, action: CastedScreenInfoActions) => {
    if (action === CastedScreenInfoActions.STOP_CAST) {
      const screenUpdating = cloneDeep(screenUpdatingList);
      screenUpdating.push(data);
      setScreenUpdatingList(screenUpdating);
    }
    return callBack && callBack(data, action);
  };

  return screens.length > 0 ? (
    <Popover
      data-testid={"screen-casting-popover"}
      inverted
      className="pop-modal"
      trigger={
        <Styled
          data-testid={"screen-casting-trigger"}
          className="on-screen"
          title={`Casting on ${screens.length} screen${
            screens.length > 1 ? "s" : ""
          }`}
        >
          <Button>
            <Icon name="casting" />
            {!props.hideScreenCount && (
              <span className="screen-count">{screens.length}</span>
            )}
          </Button>
        </Styled>
      }
      on="click"
      content={
        <CastedScreenInfoStyled>
          <div className="header">
            <FormattedMessage
              id="ui_component.common.currently_casting_on"
              defaultMessage="Currently casting on ..."
            />
          </div>
          {screens.map((screen, index) => (
            <div className="screen-item" key={`screen-${index}`}>
              <div className="screen-alpha">
                <h3
                  title={screen.screenName}
                  onClick={() => {
                    callBack &&
                      handleCallback(
                        screen.screenId,
                        CastedScreenInfoActions.NAVIGATE_SCREEN
                      );
                  }}
                >
                  {screen.screenName}
                </h3>
                <span
                  title={screen.deviceModel || UNKNOWN_DEVICE}
                  onClick={() => {
                    callBack &&
                      handleCallback(
                        screen.deviceId,
                        CastedScreenInfoActions.NAVIGATE_DEVICE
                      );
                  }}
                >
                  {screen.deviceModel || UNKNOWN_DEVICE}
                </span>
              </div>
              {callBack &&
                (screenUpdatingList.indexOf(screen.screenId) > -1 ? (
                  <div title="stopping" className="screen-pending">
                    <Loader inline active size="small" />
                  </div>
                ) : (
                  context.currentPermissions.validateCurrentSpace(
                    "screen",
                    "cast"
                  ) && (
                    <div
                      data-testid="stop-casting-button"
                      title="Stop casting"
                      className="screen-stop"
                      onClick={() => {
                        handleCallback(
                          screen.screenId,
                          CastedScreenInfoActions.STOP_CAST
                        );
                      }}
                    >
                      <Icon name="stop" />
                    </div>
                  )
                ))}
            </div>
          ))}
          {(totalCount ?? screens.length) > screens.length && (
            <div className="loadmore-screen">Load More</div>
          )}
        </CastedScreenInfoStyled>
      }
    />
  ) : null;
}

export default CastedScreenInfo;
