import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  gap: var(--spacing-lg);

  &.loading {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .row {
    display: block;
    margin: 0;
  }

  h3 {
    margin-bottom: 5px;
  }

  .custom-dimension {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;

    input {
      width: 100%;
    }

    .custom-width {
      width: calc(50% - 40px);
    }
    .custom-height {
      width: calc(50% - 40px);
      margin: 0;
    }

    .swap-button {
      position: relative;
      width: 50px;
      padding: 0;
      margin: 0 10px;
      min-width: 0;

      i {
        transform: rotate(90deg);
      }
    }
  }

  .controller {
    display: flex;
    gap: var(--spacing-md);

    button {
      flex: 1 0 0;
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .custom-dimension {
      .custom-width,
      .custom-height {
        width: 100%;
        margin: 0;
      }

      .swap-button {
        width: 200px;
        margin: 10px 0;

        i {
          transform: rotate(0deg);
        }
      }
    }
  }
  @media screen and (max-width: ${Theme.break.tiny}) {
    padding: 20px 15px;
  }
`;
