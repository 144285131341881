/**
 * Create a new array containing only unique elements from the given array.
 *
 * @param array - The array to filter for uniqueness.
 */
export function unique<T = string>(array: T[]): T[] {
  return Array.from(new Set<T>(array));
}

/**
 * Generate a deep copy of the provided array.
 *
 * @param array - The array to duplicate deeply.
 */
export function copy<T = string[]>(array: T[]): T[] {
  return JSON.parse(JSON.stringify(array));
}
