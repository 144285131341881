export function onCmdOPress(callback: () => void) {
  // Cmd + Shift + O
  const downHandler = ({ key, metaKey, shiftKey }: KeyboardEvent) => {
    if (key.toLowerCase() === "o" && metaKey && shiftKey) {
      callback();
    }
  };

  window.addEventListener("keydown", downHandler);

  // Make sure to remove event listener when it's no longer needed
  return () => {
    window.removeEventListener("keydown", downHandler);
  };
}
