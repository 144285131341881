import { Loader } from "@screencloud/screencloud-ui-components";
import React from "react";
import { buildURL } from "react-imgix";
import { FormattedMessage } from "react-intl";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import {
  AppInstance,
  File,
  Link,
  Playlist,
  ScreensByScreenGroupIdAndOrgIdFragment,
} from "../../types.g";
import { Styled } from "./styles";

export enum ScreenPickerActions {
  ADD_SCREEN = "ADD_SCREEN",
  CASTING = "Casting",
  SET_CONTENT = "SET_CONTENT",
  CANCEL = "Cancel",
}
import { PrimaryButton } from "../../helpers/whiteLabel";
import { UseStateSearch } from "../../hooks/useStateSearch";
import videoIcon from "../../images/video.svg";
import { ContentSettingGroup } from "./contentSettingGroup";
import { AppContextType } from "src/AppContextProvider/type";
import { withData } from "./apollo";
import { ScreenPickerSearch } from "./components/ScreenPickerSearch";
import { ScreenPickerScreenList } from "./components/ScreenPickerScreenList";

export type ScreenPickerOnCallBack = (
  data: string[],
  action: ScreenPickerActions,
  expiresAt?: Date
) => void;

export interface ScreenPickerProps extends UseStateSearch {
  title?: React.ReactNode | string;
  callback?: ScreenPickerOnCallBack;
  itemToCast:
    | Partial<Link>
    | Partial<File>
    | Partial<Playlist>
    | Partial<AppInstance>;
  disabledAction: boolean;
  isSelectAll: boolean;
  isSelectAllIndeterminate: boolean;
  selectAll: () => void;
  selectedScreens: ScreensByScreenGroupIdAndOrgIdFragment[];
  updateSelectedScreen: (
    screens: ScreensByScreenGroupIdAndOrgIdFragment[]
  ) => void;
  toggleSelectedAll: () => void;
  clearSelectedItems: () => void;
  screens: ScreensByScreenGroupIdAndOrgIdFragment[];
  loading: boolean;
  hasNextPage: boolean;
  loadMore: () => void;
  totalCount: number;
}

export interface ScreenPickerState {
  action: ScreenPickerActions;
  expireDate: Date | undefined;
}
class ScreenPicker extends React.PureComponent<
  ScreenPickerProps,
  ScreenPickerState
> {
  public static contextType = AppContext;
  public context: AppContextType;
  private customContainer;

  constructor(props: ScreenPickerProps) {
    super(props);
    this.state = {
      action: ScreenPickerActions.SET_CONTENT,
      expireDate: undefined,
    };
  }

  public onSearchInputChange = (
    event: React.SyntheticEvent<any>,
    data: { [key: string]: any }
  ) => {
    this.props.updateSearchString(data.value);
  };

  public toggleSelect = (id: any) => {
    const {
      selectedScreens,
      updateSelectedScreen,
      screens: screenList,
    } = this.props;
    const isItemInArray = selectedScreens.some((item) => item.id === id);
    if (isItemInArray) {
      updateSelectedScreen(selectedScreens.filter((item) => item.id !== id));
    } else {
      const screenToAdd = screenList.find((item) => item.id === id);
      if (screenToAdd) {
        updateSelectedScreen([...selectedScreens, screenToAdd]);
      }
    }
  };

  public isSelectedItemsEmpty() {
    return this.props.selectedScreens.length < 1;
  }

  public onButtonClick = () => {
    const { callback, selectedScreens } = this.props;
    const { action, expireDate } = this.state;

    callback &&
      callback(
        selectedScreens.map((item) => item.id),
        action,
        expireDate
      );
  };

  public screenPickerCallback = (checked: boolean, id: string) => {
    this.toggleSelect(id);
  };

  public handleAddScreen = () => {
    const { callback } = this.props;
    callback && callback([], ScreenPickerActions.ADD_SCREEN);
  };

  public getMediaFileThumbnail = (): string => {
    const media = this.props.itemToCast as File;
    const nodes = media.fileOutputsByFileId!.nodes;

    let fileThumbnail = `${appConfig.imgixBaseUrl}/${media.orgId}/originals/${
      media.metadata!.key
    }`;

    if (nodes.length) {
      const file = nodes.find((fileOutput) =>
        fileOutput!.mimetype!.startsWith("image/")
      );
      if (file) {
        fileThumbnail = file.content.keys
          ? `${appConfig.imgixBaseUrl}/${file.content!.keys[0]}`
          : videoIcon;
      }
    }

    return buildURL(fileThumbnail, { w: 60, h: 60, fit: "clip", fm: "jpg" });
  };

  public render() {
    const {
      clearSearch,
      disabledAction,
      selectedScreens,
      searchString,
      loading,
    } = this.props;
    const { action } = this.state;
    const isActionEnabled = disabledAction === false;
    return (
      <Styled className="sp">
        {isActionEnabled && (
          <ScreenPickerSearch
            isSingleSelect={false}
            selectedScreens={selectedScreens}
            searchString={searchString}
            clearSearch={clearSearch}
            onSearchInputChange={this.onSearchInputChange}
            isSelectAll={this.props.isSelectAll}
            isSelectAllIndeterminate={this.props.isSelectAllIndeterminate}
            toggleSelectedAll={this.props.toggleSelectedAll}
          />
        )}

        <div
          className="container"
          ref={(el) => (this.customContainer = el)}
          id="lazy-ref-container"
        >
          {disabledAction === false && (
            <div className="sp-item-header">
              <div className="screen-core">
                <div className="screen-alpha">
                  <FormattedMessage
                    id="subheader.label.name"
                    defaultMessage="Name"
                  />
                </div>
                <div className="screen-group">
                  <FormattedMessage
                    id="subheader.label.group"
                    defaultMessage="Group"
                  />
                </div>
                <div className="screen-player">
                  <FormattedMessage
                    id="subheader.label.playing_casting"
                    defaultMessage="Playing/Casting"
                  />
                </div>
                <div className="screen-state">
                  <FormattedMessage
                    id="subheader.label.status"
                    defaultMessage="Status"
                  />
                </div>
              </div>
            </div>
          )}

          <div className="list">
            <ScreenPickerScreenList
              handleAddScreen={this.handleAddScreen}
              customContainer={this.customContainer}
              screenPickerCallback={this.screenPickerCallback}
              screens={this.props.screens}
              selectedScreens={selectedScreens}
              loadMore={this.props.loadMore}
              hasNextPage={this.props.hasNextPage}
              isSearch={this.props.isSearch}
              loading={this.props.loading}
              clearSearch={clearSearch}
            />
          </div>
        </div>

        {disabledAction === false && (
          <div className="md-footer result-options">
            <ContentSettingGroup
              action={action}
              setAction={(action: ScreenPickerActions) =>
                this.setState({ action })
              }
              onExpireDateChange={(expireDate) => this.setState({ expireDate })}
            ></ContentSettingGroup>
            <div className="selected-actions">
              <PrimaryButton
                loading={loading}
                size="big"
                onClick={() => this.onButtonClick()}
                disabled={this.isSelectedItemsEmpty()}
              >
                {loading ? (
                  <Loader inline active={loading} size="small" />
                ) : (
                  <>
                    <FormattedMessage
                      id="ui_component.common.label.confirm"
                      defaultMessage="Confirm"
                    />
                  </>
                )}
              </PrimaryButton>
            </div>
          </div>
        )}
      </Styled>
    );
  }
}

export default withData(ScreenPicker);
