import classNames from "clsx";
import * as React from "react";

export interface Props {
  children: React.ReactNode;
  disabled?: boolean;
}

const FullScreenModalContent = (props: Props) => {
  return (
    <div
      className={classNames("panel-wrapper", { "no-footer": props.disabled })}
    >
      {props.children}
    </div>
  );
};

export default FullScreenModalContent;
