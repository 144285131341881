import { Button, Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledDroppableContainer = styled.div`
  > div:first-child + div {
    display: "none" !important;
  }
`;
export const StyledDraggableWrapper = styled.div`
  > div:first-child + div + div {
    display: none !important;
  }
`;

export const StyledDropdown = styled.div`
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .ui.dropdown {
    > i {
      width: 24px;
      height: 24px;
    }
  }

  &.dnd-dragging-active {
    padding: 8px;
    ${Theme.default.borderRadius};
    background-color: ${Theme.color.silver};
  }

  .ui.dropdown .menu.dnd-dropdown-menu > .item:hover {
    background-color: ${Theme.color.white};
  }

  .ui.dropdown .menu.dnd-dropdown-menu > .item.active {
    background-color: ${Theme.color.silver};
  }
`;

export const StyledMediaList = styled.div`
  .upload-media {
    width: calc(100% - 201px);
    position: absolute;
    top: -39px;
    right: 0;
    height: 28px;

    i {
      background-color: ${Theme.color.white};
      width: 14px;
    }

    :hover,
    :active {
      height: 28px;

      i {
        background-color: ${Theme.color.white};
      }
    }
  }

  .ui.breadcrumb {
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${Theme.color.silver};
    margin-bottom: 10px;

    .ui.dropdown {
      .menu {
        .item {
          font-size: 14px;
        }
      }
    }
  }

  .empty-media {
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;

    p {
      font-size: 0.875em;
      color: rgba(31, 32, 39, 0.5);
    }
  }
`;

export const StyledDragLayer = styled.div`
  height: 60px;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 60px;
  z-index: 100;

  .items-wrapper {
    position: relative;
  }

  .badge {
    position: absolute;
    top: 16px;
    left: 16px;
    background-color: var(--color-primary-backrgound);
    color: var(--color-primary-foreground);
    border-radius: 24px;
    font-size: 0.875em;
    font-weight: bold;
    line-height: 27px;
    text-align: center;
    height: 28px;
    width: 28px;
    z-index: 100;
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, 0.1);
  }

  .file-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .folder-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    z-index: 100;
  }
`;

export const ScreensCastingStyled = styled.div`
  padding: 2px 5px;

  .screen-item {
    height: 60px;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid ${Theme.color.darkGreyHover};
    width: 300px;

    .screen-alpha {
      width: calc(100% - 40px);

      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }

      span {
        color: ${Theme.color.grey};
      }
    }

    .screen-state {
      width: 40px;
      align-items: center;
      justify-content: center;
      display: inline-flex;

      > span.active {
        background-color: ${Theme.color.green};
      }
      > span {
        display: inline-flex;
        background-color: ${Theme.color.red};
        width: 15px;
        height: 15px;
        border-radius: 100%;
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;

        span {
          display: none;
        }
      }
    }

    :last-child {
      border-bottom: none;
    }
  }
`;

export const AlertStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .alertMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    padding: 10px 0 0;

    .message {
      margin-bottom: 5px;
    }

    i {
      margin-bottom: 20px;
    }

    span {
      font-size: 1.2em;
      margin-right: 1px;

      &[class^="dot"] {
        margin: 0 1px;
      }
    }

    @keyframes loading {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .dot-1 {
      animation: loading 0.4s ease 1.2s infinite alternate;
    }
    .dot-2 {
      animation: loading 0.4s ease 0.6s infinite alternate;
    }
    .dot-3 {
      animation: loading 0.4s ease infinite alternate;
    }

    > span:last-child {
      font-size: 1em;
      font-weight: normal;
      color: ${Theme.color.midGrey};
    }
  }

  .failed {
    color: ${Theme.color.error};
    i {
      background-color: ${Theme.color.error};
      width: 30px;
      height: 30px;
    }
  }
  .processing {
    color: ${Theme.color.warning};
    i {
      background-color: ${Theme.color.warning};
      width: 30px;
      height: 30px;
    }
  }
`;
export const StyledEditNameIcon = styled.div`
  display: flex;
  cursor: pointer;
  gap: 5px;

  > div {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  i {
    opacity: 0;
  }
  :hover {
    i {
      opacity: 1;
    }
  }

  span {
    justify-content: flex-start;
  }
`;

export const StyledFolderButton = styled(Button)``;
