import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export interface StyledPresetCardProps {
  selected?: boolean;
  onClick: () => void;
}

export const StyledPresetCard = styled.div<StyledPresetCardProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  padding: 10px 40px;

  background-color: ${(props) =>
    props.selected ? Theme.color.base : Theme.color.white};
  ${Theme.default.borderRadius};
  border: 1px solid
    ${(props) => (props.selected ? Theme.color.base : Theme.color.lightGrey)};
  cursor: pointer;
  text-align: left;

  .card-icon {
    width: 50px;
    height: 50px;
    background-color: ${(props) =>
      props.selected ? Theme.color.white : Theme.color.darkGrey};
  }

  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    font-weight: bold;
    color: ${(props) =>
      props.selected ? Theme.color.white : Theme.color.base};
  }

  .card-subheader {
    color: ${(props) =>
      props.selected ? Theme.color.lightGrey : Theme.color.darkGrey};
    text-align: center;
    margin: 10px 0;
  }

  .selected-icon {
    width: 20px;
    height: 20px;
    background-color: ${(props) =>
      props.selected ? Theme.color.white : Theme.color.lightGrey};
    align-self: center;
    margin-top: auto;
  }

  .title-label {
    margin-left: 5px !important;
  }

  ul {
    color: ${Theme.color.midGrey};
  }
`;

export const PresetStyled = styled.div`
  padding-top: 10px;

  .card {
    ${Theme.default.borderRadius};
    background: ${Theme.color.white};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease-out;
    width: 160px;
    height: 110px;
    white-space: normal;
    word-wrap: break-word;
    cursor: pointer;

    &-highlighted {
      ${Theme.default.borderRadius};
      background-color: ${Theme.color.black};
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      transition: all 0.1s ease-out;
      width: 160px;
      height: 110px;
      white-space: normal;
      word-wrap: break-word;

      .text-container {
        font-weight: bold;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .sub-text-highlighted {
          color: ${Theme.color.grey};
          font-size: small;
        }
      }
    }
    .text-container {
      font-weight: bold;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .sub-text {
        color: ${Theme.color.lightGrey};
        font-size: small;

        &-highlighted {
          color: ${Theme.color.grey};
          font-size: small;
        }
      }
    }
  }
`;

export const Styled = styled.div`
  height: 100%;
  background: ${Theme.color.nearWhite};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0 0 ${Theme.default.borderRadiusValue}
    ${Theme.default.borderRadiusValue};

  .secure-site-configuration {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .compute-size-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .advanced-settings {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
  }

  .preset-options-container {
    padding: 20px;

    .preset-options {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .button-text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .sub-text {
          color: ${Theme.color.lightGrey};
          font-size: small;
        }
      }

      .preset-button:focus {
        background-color: var(--color-primary-background);
      }
    }
  }

  .title {
    font-weight: bold;
    border-bottom: 1px solid ${Theme.color.lightGrey};
    padding-bottom: 4px;
  }

  .options-container {
    padding: 20px;
    margin-top: 20px;
    border: 1px solid ${Theme.color.lightGrey};
    ${Theme.default.borderRadius};
    background-color: #eee;

    .separator {
      border: none;
      width: 100%;
      height: 1px;
      background-color: ${Theme.color.lightGrey};
      margin: 0;
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
    }

    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 6px;
      border-radius: 5px;
      background: var(--color-primary-background);
      outline: none;
      opacity: 0.7;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    .slider::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: var(--color-primary-background);
      cursor: pointer;
    }
  }

  .header-text {
    font-weight: bold;
  }

  .compute {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .site-refresh-interval {
    margin-top: 20px;
    .ui.input {
      width: 100%;
    }
  }

  .compute-options {
    border: 1px solid ${Theme.color.lightGrey};
    background: ${Theme.color.white};
    text-align: center;
    width: 43%;
    padding: 10px;
    font-size: 0.875em;
  }

  .refresh-options {
    border: 1px solid ${Theme.color.lightGrey};
    text-align: center;
    width: 100px !important;
    padding: 10px;
    font-size: 0.875em;
  }

  .refresh-interval-units {
    width: 100px !important;
    background-color: ${Theme.color.white};
  }

  .footer-container {
    background: ${Theme.color.white};
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${Theme.color.lightGrey};
    height: 65px;
    overflow: visible;
    border-radius: 0 0 ${Theme.default.borderRadiusValue}
      ${Theme.default.borderRadiusValue};
  }
`;
