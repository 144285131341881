import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  border-bottom: 1px solid ${Theme.color.lightGrey};
  padding-right: var(--spacing-sm);

  .ui.dropdown {
    .dropdown.icon {
      margin-left: 5px;
      color: ${Theme.color.grey};
    }

    .menu {
      .item {
        span {
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          height: auto;
        }
        &.active {
          span {
            font-weight: bold;
          }
        }
      }
    }
  }

  span,
  .button {
    text-transform: uppercase;
    color: ${Theme.color.grey};
    z-index: 1;
    position: relative;
    height: 40px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    font-weight: 900;
    letter-spacing: 0.1em;

    i {
      width: 8px;
      margin-left: 5px;
      height: 4px;
      background-color: ${Theme.color.grey};
    }
  }

  .media-core {
    width: calc(100% - 32px);
    display: flex;

    [class^="media-"] {
      text-align: center;
    }

    .media-alpha {
      width: 34%;
      text-align: left;
    }

    .media-starred {
      width: 9.4%;
    }

    .media-casting {
      width: 9.4%;
    }

    .media-type {
      width: 9.4%;
    }

    .media-url {
      width: 18.8%;
    }

    .media-tags {
      width: 19%;
    }
  }

  @media screen and (max-width: ${Theme.break.large}) {
    .media-core {
      .media-starred {
        width: 11%;
      }

      .media-casting {
        width: 11%;
      }

      .media-type {
        width: 11%;
      }

      .media-url {
        width: 22%;
      }

      .media-tags {
        width: 11%;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .media-core {
      .media-alpha {
        width: 40%;
        min-width: 40%;
      }

      .media-starred {
        width: 15%;
      }

      .media-casting {
        width: 15%;
      }

      .media-type {
        display: none;
      }

      .media-url {
        display: 15%;
      }

      .media-tags {
        width: 15%;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .media-core {
      .media-alpha {
        width: 60%;
      }

      .media-starred {
        width: 20%;
      }

      .media-casting {
        width: 20%;
      }

      .media-type {
        display: none;
      }

      .media-url {
        display: none;
      }

      .media-tags {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .media-core {
      .media-alpha {
        width: calc(100% - 80px);
      }

      .media-starred {
        width: 80px;
      }

      .media-casting {
        display: none;
      }

      .media-type {
        display: none;
      }

      .media-url {
        display: none;
      }

      .media-tags {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .media-core {
      .media-alpha {
        width: 100%;
      }

      .media-starred {
        display: none;
      }
    }
  }
`;
