import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export interface ScreenItemSearchResultStyleProps {
  selected: boolean;
}

export const Styled = styled.div<ScreenItemSearchResultStyleProps>`
  cursor: initial;
  .editable {
    cursor: pointer;
  }

  background: var(--color-white-100);
  border: 1px solid var(--color-gray-100);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-out;
  padding: 8px;
  display: flex;
  border-radius: var(--radius-lg);

  .screen-core {
    width: calc(100% - 44px);
    display: flex;
    align-items: center;

    [class^="screen-"] {
      text-align: center;

      span {
        display: block;
        color: ${Theme.color.base};
        font-size: 0.875em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .screen-alpha {
      display: block;
      width: 50%;
      display: flex;

      .thumbnail-preview {
        width: 56px;
        height: 56px;
        position: relative;

        a {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: var(--radius-md);

          i {
            width: 100%;
            height: 100%;
            display: block;
            mask-size: 36px;
            min-width: auto;
            min-height: auto;
          }

          :hover {
            background: var(--color-gray-50);
          }
        }
      }

      .screen-title {
        width: calc(100% - 56px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 8px;
        text-align: left;

        h3 {
          font-size: 1em;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;

          a {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${Theme.color.base};
          }
        }

        .screen-meta__group {
          display: none;
        }
      }
    }

    .screen-group {
      display: none;
    }

    .screen-player {
      width: calc(50% - 120px);
      min-width: auto;
    }

    .screen-tags {
      display: none;
    }

    .screen-state {
      width: 120px;
      text-align: center;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .screen-group {
      padding: 0 10px;
      text-align: left;
    }

    .screen-player {
      .casting {
        background: var(--color-gray-50);
        border-radius: var(--radius-md);

        .ui.label {
          background-color: ${Theme.color.darkGrey};
          color: ${Theme.color.white};
        }
      }

      .casting,
      .channel {
        width: 100%;
        display: inline-flex;
        align-items: center;
        padding: 8px;
        gap: 8px;

        i {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          background-color: var(--color-gray-950);
        }
      }
    }
  }

  :hover {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.2);
  }

  ${(props: ScreenItemSearchResultStyleProps) =>
    props.selected &&
    `
      box-shadow: inset 0 0 0 4px var(--color-primary-background);
      border-color: var(--color-primary-background);

      :hover {
        box-shadow: inset 0 0 0 4px var(--color-primary-background);

        .screen-check {
          button {
            i {
              background-color: ${Theme.color.darkGrey};
            }
          }
        }
      }
    `}

  &.visible.transition {
    display: flex !important;
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .screen-core {
      .screen-alpha {
        width: calc(100% - 56px);
      }

      .screen-player {
        display: none;
      }

      .screen-state {
        width: 56px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    height: 60px;

    .screen-core {
      .screen-alpha {
        .thumbnail-preview {
          width: 40px;
          height: 40px;

          a {
            display: flex;
          }

          i {
            mask-size: 30px;
          }
        }

        .screen-title {
          width: calc(100% - 40px);
          h3 {
            font-size: 1em;
            margin: 0;

            + i {
              display: inline-block;
              height: 12px;
              width: 12px;

              .label {
                display: none;
              }
            }
          }

          .playing {
            display: flex;
            align-items: center;

            i {
              display: inline-block;
              width: 9px;
              margin-right: 2px;
              background-color: ${Theme.color.grey};
              height: 14px;
            }

            .label {
              display: none;
            }
          }
        }
      }
    }
  }
`;
