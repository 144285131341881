import { orderBy, uniqBy } from "lodash";
import { fieldsPolicyType } from "./apolloClient";

const channelListPolicy = {
  merge(existing, incoming, options) {
    const existingNodes = existing ? [...existing.nodes] : [];
    const incomingNodes = incoming?.nodes ? [...incoming.nodes] : [];
    const nodes =
      incomingNodes.length < existingNodes.length &&
      options.variables?.endChannel === null
        ? incomingNodes // if do the delete action
        : uniqBy([...existingNodes, ...incomingNodes], "__ref");

    if (options.storeFieldName.includes('"equalTo":')) {
      const reOrder = orderBy(
        nodes.map((node) => {
          const name = options.readField("name", node) as string;
          return {
            name,
            node,
          };
        }),
        [(node) => node?.name?.toLowerCase()],
        ["asc"]
      );
      const finalNodesRef = reOrder.map((re) => re.node);

      return {
        ...options.mergeObjects(existing, incoming),
        nodes: finalNodesRef,
      };
    } else {
      return {
        ...options.mergeObjects(existing, incoming),
        nodes,
      };
    }
  },
};

export const channelListField: fieldsPolicyType = {
  publishedChannelsBySpaceId: {
    keyArgs: ["filter"],
    ...channelListPolicy,
  },
};

export const channelPickerField: fieldsPolicyType = {
  channelsByOrgId: {
    keyArgs: ["orgId", "spaceId", "filter"],
    ...channelListPolicy,
  },
};

export const searchChannelListField: fieldsPolicyType = {
  searchChannel: {
    keyArgs: ["query", "spaceId", "filter"],
    merge(existing, incoming, options) {
      const existingNodes = existing ? [...existing.nodes] : [];
      const incomingNodes = incoming?.nodes ? [...incoming.nodes] : [];
      const nodes = uniqBy([...existingNodes, ...incomingNodes], "__ref");

      return {
        ...options.mergeObjects(existing, incoming),
        nodes,
      };
    },
  },
};
