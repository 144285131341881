import { ApolloQueryResult } from "@apollo/client";
import { UUID } from "@screencloud/uuid";
import { useMemo, useState } from "react";
import {
  FIRST_FETCH_ITEMS,
  MAXIMUM_FETCH_MORE_ITEMS,
  MINIMUM_FETCH_MORE_ITEMS,
} from "src/constants/constants";
import { renderFetchMoreButton } from "src/helpers/generalHelper";
import { useAppContext } from "src/hooks/useAppContext";
import {
  Exact,
  Maybe,
  SiteFilter,
  SiteFragment,
  SiteListQuery,
  SiteListQueryVariables,
  SitesOrderBy,
  useSiteListQuery,
} from "src/types.g";

export interface UseAllSites {
  sites: SiteFragment[];
  hasNextPage: boolean;
  loadMore: () => void;
  loading: boolean;
  isLoading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            spaceId: any;
            first: Maybe<number>;
            endCursor: any;
            orderBy: Maybe<SitesOrderBy[]>;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<SiteListQuery>>;
  isFirstTimeAlreadyLoaded: boolean;
  totalCount: number;
  renderFetchMoreButton: React.ReactNode;
}

export type AllSiteSectionInput = {
  filter?: SiteFilter;
  spaceId?: UUID;
  skip?: boolean;
};

const useAllSiteSection = (props: AllSiteSectionInput): UseAllSites => {
  const context = useAppContext();
  const [fetchCount, setFetchCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const spaceId = props.spaceId ?? context?.currentSpace?.id;

  const variables: SiteListQueryVariables = {
    spaceId,
    first: FIRST_FETCH_ITEMS,
    endCursor: null,
    orderBy: [SitesOrderBy.NameAsc, SitesOrderBy.IdDesc],
    filter: props.filter ?? {},
  };

  const { data: siteData, fetchMore, refetch, loading } = useSiteListQuery({
    variables,
    fetchPolicy: "cache-first",
    skip: props.skip,
  });

  const loadMore = () => {
    if (isLoading) return;
    setIsLoading(true);
    const endCursor =
      siteData?.spaceById?.availableSitesBySpaceId?.pageInfo?.endCursor;
    fetchMore({
      variables: {
        first:
          fetchCount > 1 ? MAXIMUM_FETCH_MORE_ITEMS : MINIMUM_FETCH_MORE_ITEMS,
        endCursor,
      },
    }).then(() => {
      setIsLoading(false);
      setFetchCount(fetchCount + 1);
    });
  };
  const totalCount = useMemo(
    () => siteData?.spaceById?.availableSitesBySpaceId.totalCount ?? 0,
    [siteData?.spaceById?.availableSitesBySpaceId.totalCount]
  );

  const hasNextPage = useMemo(
    () =>
      Boolean(
        siteData?.spaceById?.availableSitesBySpaceId.pageInfo.hasNextPage
      ) &&
      siteData?.spaceById?.availableSitesBySpaceId?.nodes.length! <
        siteData?.spaceById?.availableSitesBySpaceId?.totalCount!,
    [
      siteData?.spaceById?.availableSitesBySpaceId.pageInfo!.hasNextPage,
      siteData?.spaceById?.availableSitesBySpaceId.totalCount,
    ]
  );

  const isFirstTimeAlreadyLoaded = useMemo(
    () => Boolean(siteData?.spaceById?.availableSitesBySpaceId),
    [siteData?.spaceById?.availableSitesBySpaceId]
  );

  const currentItemsCount =
    siteData?.spaceById?.availableSitesBySpaceId?.nodes.length ?? 0;

  const fechMoreButton = renderFetchMoreButton(
    currentItemsCount,
    totalCount,
    isLoading,
    hasNextPage,
    loadMore
  );

  return {
    sites: siteData?.spaceById?.availableSitesBySpaceId?.nodes ?? [],
    hasNextPage,
    loadMore,
    refetch,
    loading,
    isLoading,
    isFirstTimeAlreadyLoaded,
    totalCount,
    renderFetchMoreButton: fechMoreButton,
  };
};

export const useAllSitesInCurrentSpace = (props: AllSiteSectionInput) => {
  const { spaceId, skip } = props;
  const context = useAppContext();
  const selectSpaceId = spaceId ?? context?.currentSpace?.id;
  const filter: SiteFilter = { spaceId: { equalTo: selectSpaceId } };

  const variables: AllSiteSectionInput = {
    filter,
    skip,
    spaceId,
  };
  return useAllSiteSection(variables);
};

export const useAllSitesFromOtherSpaces = (props: AllSiteSectionInput) => {
  const { skip } = props;
  const context = useAppContext();
  const spaceId = context?.currentSpace?.id;
  const filter: SiteFilter = { spaceId: { notEqualTo: spaceId } };

  const variables: AllSiteSectionInput = {
    filter,
    skip,
    spaceId,
  };
  return useAllSiteSection(variables);
};

export const useAllSites = (props: AllSiteSectionInput) => {
  const { spaceId, skip, filter } = props;
  const variables: AllSiteSectionInput = {
    spaceId,
    skip,
    filter,
  };
  return useAllSiteSection(variables);
};
