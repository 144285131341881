import { Icon } from "@screencloud/screencloud-ui-components";
import {
  ElementData,
  Message,
  MessageType,
  SelectElement,
} from "@screencloud/secure-sites-extension-types";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getChromeExtensionId } from "../../SitePicker/CreateSite/chromeExtensionUtils";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import { useAppContext } from "../../../hooks/useAppContext";
import { Site } from "../../../types.g";
import { PreviewMode, SitePreview } from "../../SitePreview";
import { createActionFromMessage } from "./actions/factory";
import {
  NavigateAction,
  SiteRecorderAction,
  SiteRecorderActionType,
} from "./actions/models";
import { NavigationType } from "./navigationcontrol";
import { ActionMessage, ActionMessageType } from "./reducers";
import { isGeckoboard, isPowerBi } from "./siteHelpers";
import { PreviewSubMessage, SiteFramePanel } from "./styles";
import { APP_STORE_DISCOVER_PATH_NANE } from "src/pages/Apps/AppStore/constants";
import { SiteRecorderActionMode } from "..";

export interface SiteFrameProps {
  url: string;
  mode: NavigationType;
  isRecording: boolean;
  isSelectingElement: boolean;
  selectedElement?: ElementData;
  editorType?: SiteRecorderActionType;
  editorAction?: SiteRecorderAction;
  dispatch: (action: ActionMessage) => void;
  actions: SiteRecorderAction[];
  hasOtc?: boolean;
  navigateCount: number;
  site: Partial<Site> | null | undefined;
  isChangesToSave: boolean;
  actionMode: SiteRecorderActionMode;
}

export const SiteFrame = (props: React.PropsWithChildren<SiteFrameProps>) => {
  const [port, setPort] = useState<chrome.runtime.Port>();
  const [isSiteBlocked, setSiteBlocked] = useState(false);
  const { intl } = useAppContext();
  const [hasActions, setHasActions] = useState(false);

  const onDisconnect = useCallback(
    (extPort: chrome.runtime.Port) => {
      console.log("disconnect");
    },
    [port]
  );

  useEffect(() => {
    if (!port) {
      const extensionPort = chrome.runtime.connect(getChromeExtensionId());
      extensionPort.onDisconnect.addListener(onDisconnect);
      setPort(extensionPort);
    }

    return () => {
      port?.disconnect();
    };
  }, [port]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    // We only want to send a heartbeat if the connection has already been established.
    // We also only care about sending it before the user has started recording their journey in the extension window, as
    // the extension window being open is enough to keep the connection alive.
    if (port && !props.isRecording && props.actions.length === 1) {
      interval = setInterval(() => {
        port.postMessage({ type: "heartbeat" });
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [port, props.isRecording, props.actions]);

  useEffect(() => {
    function onMessage(message: Message, extPort: chrome.runtime.Port) {
      if (message.type === MessageType.Navigate) {
        props.dispatch({
          type: ActionMessageType.AddAction,
          payload: new NavigateAction("Navigate to URL", {
            url: message.payload.url,
          }),
        });
        return;
      } else if (message.type === MessageType.SiteLoadComplete) {
        setSiteBlocked(false);
        const hasNavigated = props.actions.some(
          (existingAction) =>
            existingAction.type === SiteRecorderActionType.Navigate
        );

        if (!hasNavigated) {
          props.dispatch({
            type: ActionMessageType.ToggleRecording,
            payload: true,
          });

          const acceptLanguage =
            message.payload && message.payload.acceptLanguage
              ? message.payload.acceptLanguage
              : "";

          const navAction = new NavigateAction("Navigate to URL", {
            url: props.url,
            acceptLanguage,
          });

          props.dispatch({
            type: ActionMessageType.AddAction,
            payload: navAction,
          });
        }

        if (message.payload && message.payload.extensionVersion) {
          props.dispatch({
            type: ActionMessageType.UpdateExtensionVersion,
            payload: message.payload.extensionVersion,
          });
        }

        sendNotification(props.url, intl, port);
      } else if (
        message.type === MessageType.SiteLoadError &&
        message.payload.isBlocked
      ) {
        setSiteBlocked(true);
      } else if (message.type === MessageType.CurrentUrl) {
        return props.dispatch({
          type: ActionMessageType.UpdateRecorderUrl,
          payload: message.payload.url,
        });
      } else if (message.type === MessageType.SelectElement) {
        const selectElementMessage = message as SelectElement;
        props.dispatch({
          type: ActionMessageType.UpdateSelectedElement,
          payload: selectElementMessage.payload?.element,
        });
        return;
      } else if (message.type === MessageType.SelectCustomSnapshot) {
        const selectElementMessage = message as SelectElement;
        props.dispatch({
          type: ActionMessageType.UpdateSnapshotElement,
          payload: selectElementMessage.payload,
        });
      } else if (message.type === MessageType.PopupClosed) {
        props.dispatch({
          type: ActionMessageType.ToggleRecording,
          payload: false,
        });
      } else if (message.type === MessageType.OpenNotificationLink) {
        window.open(message.payload.url);
        return;
      } else if (message.type === MessageType.CurrentPageTitle) {
        props.dispatch({
          type: ActionMessageType.UpdateTitle,
          payload: message.payload.value,
        });
        props.dispatch({
          type: ActionMessageType.UpdateRecorderUrl,
          payload: message.payload.url,
        });
      }

      if (!props.isRecording || props.isSelectingElement) {
        return;
      }

      const action = createActionFromMessage(message);

      if (action) {
        props.dispatch({ type: ActionMessageType.AddAction, payload: action });
      }
    }

    port?.onMessage.addListener(onMessage);

    return () => {
      port?.onMessage.removeListener(onMessage);
    };
  }, [
    port,
    props.actions,
    props.isSelectingElement,
    props.isRecording,
    props.url,
  ]);

  useEffect(() => {
    if (props.hasOtc) {
      const dashboardsMfaGuideUrl =
        "https://support.screencloud.com/hc/en-gb/articles/360017800177";
      const title = intl.formatMessage({
        id: "ui_component.site.recorder.mfa_notification_title",
        defaultMessage: `Looks like you are using 2FA`,
      });
      const text = intl.formatMessage(
        {
          id: "ui_component.site.recorder.mfa_notification_text",
          defaultMessage: `To set up 2FA to work correctly with Dashboards we require access to your authenticators Secret Code. Please follow <a href="${dashboardsMfaGuideUrl}">our documentation</a> to set this correctly.`,
        },
        { dashboardsMfaGuideUrl }
      );

      port?.postMessage({
        type: "notify",
        payload: {
          notificationType: "info",
          title,
          text,
        },
      });
    }
  }, [props.hasOtc]);

  useEffect(() => {
    if (props.url !== "") {
      port?.postMessage({
        type: "navigate",
        payload: {
          url: props.url,
          isPopup: props.mode === NavigationType.Popup,
        },
      });

      setSiteBlocked(false);
    }
  }, [props.url, props.mode, props.navigateCount]);

  useEffect(() => {
    if (!props.isSelectingElement) {
      port?.postMessage({ type: "selecting:stop" });
    } else if (!props.selectedElement) {
      port?.postMessage({ type: "selecting:start" });
    }
  }, [props.isSelectingElement, props.selectedElement]);

  useEffect(() => {
    // catch resetting of action list
    if (hasActions && !props.actions.length) {
      closePopup();
    }
    // Actions will always have a minimum of 1 action, as the manage session action is now always present.
    // We don't want to include manage sessions in this check, so we check if there are more than 1 action present
    setHasActions(props.actions.length > 1);
  }, [props.actions]);

  const closePopup = useCallback(() => {
    port?.postMessage({
      type: MessageType.ClosePopup,
    });
  }, [port]);

  const focusOnPopup = () => {
    port?.postMessage({
      type: "popup:focus",
    });
  };

  let content;
  if (props.site?.id && !props.isRecording && props.actions.length === 1) {
    content = (
      <>
        <SitePreview
          siteId={props.site.id!}
          siteType={props.site.type!}
          previewMode={PreviewMode.None}
          focusOnPopup={focusOnPopup}
        />
      </>
    );
  } else {
    let mode = props.isRecording
      ? PreviewMode.IsRecording
      : PreviewMode.Previewing;
    if (mode === PreviewMode.Previewing && props.isChangesToSave) {
      mode = PreviewMode.Recorded;
    }

    content = isSiteBlocked ? (
      <div className="url-blocked-container">
        <div className="url-blocked-message">
          <FormattedMessage
            id="ui_component.site.recorder.frame_blocked"
            defaultMessage="This site can't be embedded, please open it in a popup."
          />
        </div>
        <PrimaryButton
          fullWidth
          onClick={() => {
            props.dispatch({ type: ActionMessageType.ClearAllActions });

            props.dispatch({
              type: ActionMessageType.UpdateNavigationMode,
              payload: NavigationType.Popup,
            });
          }}
        >
          <FormattedMessage
            id="ui_component.site.recorder.open_in_popup"
            defaultMessage="Open in popup"
          />
        </PrimaryButton>
      </div>
    ) : (
      <>
        {props.mode === NavigationType.Pane ? (
          <>
            {props.url !== "" && <iframe className="frame" src={props.url} />}
          </>
        ) : (
          <>
            <SitePreview
              siteId={props.site?.id}
              siteType={props.site?.type}
              previewMode={mode}
              focusOnPopup={focusOnPopup}
            />
            {mode === PreviewMode.Previewing &&
              props.actionMode === SiteRecorderActionMode.Create && (
                <PreviewSubMessage>
                  <Icon name="clock" className="icon" />
                  <FormattedMessage
                    id="ui_component.site.recorder.preview_loading_message"
                    defaultMessage="Your preview may take several minutes to load"
                  />
                </PreviewSubMessage>
              )}
          </>
        )}
      </>
    );
  }
  return <SiteFramePanel>{content}</SiteFramePanel>;
};

const sendNotification = (
  url: string,
  intl: ReactIntl.InjectedIntl,
  port?: chrome.runtime.Port
) => {
  const appName = isPowerBi(url)
    ? "PowerBI"
    : isGeckoboard(url)
    ? "Geckoboard"
    : "";
  if (appName) {
    const appStoreRoute = APP_STORE_DISCOVER_PATH_NANE;
    const title = intl.formatMessage(
      {
        id: "ui_component.site.recorder.app_notification_title",
        defaultMessage: `Looks like you are setting up a ${appName} dashboard`,
      },
      { appName }
    );
    const link = intl.formatMessage(
      {
        id: "ui_component.site.recorder.app_notification_text_link",
        defaultMessage: "here",
      },
      { appName }
    );
    let text = `${intl.formatMessage(
      {
        id: "ui_component.site.recorder.app_notification_text_part_one",
        defaultMessage: `We have a ${appName} app with more advanced features specifically for your ${appName} dashboards.`,
      },
      { appName }
    )}`;
    text += ` ${intl.formatMessage(
      {
        id: "ui_component.site.recorder.app_notification_text_part_two",
        defaultMessage: `Click here to go to the app store, and search '${appName}'`,
      },
      {
        link: `<a href="${window.location.origin + appStoreRoute}">${link}</a>`,
        appName,
      }
    )}`;

    port?.postMessage({
      type: "notify",
      payload: {
        notificationType: "info",
        title,
        text,
      },
    });
  }
};
