import { StudioPermissions } from "@screencloud/studio-permissions";
import { User } from "../types.g";

export function getStudioPermissionByUser(
  currentUser: User | null,
  currentSpaceId: string
) {
  return currentUser && currentSpaceId
    ? new StudioPermissions({
        permissions: currentUser!.permissions! as string[],
        currentSpaceId,
        permissionsByGroupIds: currentUser!.permissionsByGroupIds!,
        permissionsBySpaceIds: currentUser!.permissionsBySpaceIds!,
      })
    : new StudioPermissions();
}
