import { produceSecureUrl } from "@screencloud/studio-media-client";
import { replaceWithSecureURL } from "src/helpers/mediaHelper";

export const getSecureUrl = (
  url,
  secureMediaPolicy: string | null | undefined
) => {
  if (secureMediaPolicy) {
    url = replaceWithSecureURL(
      produceSecureUrl({
        baseUrl: url,
        policy: secureMediaPolicy,
      })
    );
  }
  return url;
};
