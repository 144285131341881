import addMinutes from "date-fns/addMinutes";

export const ScheduleOptionValue = {
  Indefinitely: "indefinitely",
};

/**
 *
 * @param timeValue can be indefinitely or other number string in munutes
 * '30' means going to be expired in next 30 minutes
 * return date with GMT
 */
export function getExpirationDate(timeValue: string) {
  if (timeValue === ScheduleOptionValue.Indefinitely) {
    return undefined;
  }

  // Variables here is for the convenient of debugging
  const currentDate = Date.now();
  const minutes = Number(timeValue);
  const expirationDate = addMinutes(currentDate, minutes);
  return expirationDate;
}
