import { orderBy, uniqBy } from "lodash";
import { AppInstancesOrderBy } from "../types.g";

import { fieldsPolicyType } from "./apolloClient";

const appInstancePolicy = {
  merge(existing, incoming, options) {
    const orderbyState = options.variables?.orderBy
      ? options.variables?.orderBy[0]
      : AppInstancesOrderBy.NameAsc;
    const existingNodes = existing ? [...existing.nodes] : [];
    const incomingNodes = incoming?.nodes ? [...incoming.nodes] : [];

    const nodes = uniqBy([...existingNodes, ...incomingNodes], "__ref");

    const appInstanceOrderBy =
      orderbyState === AppInstancesOrderBy.NameAsc
        ? [(node) => node?.name?.toLowerCase()]
        : ["created"];

    const reOrder = orderBy(
      nodes.map((node) => {
        const name = options.readField("name", node) as string;
        const created = options.readField("createdAt", node) as string;
        return {
          name,
          created,
          node,
        };
      }),
      appInstanceOrderBy,
      orderbyState === AppInstancesOrderBy.NameAsc ? ["asc"] : ["desc"]
    );

    const finalNodesRef = reOrder.map((re) => re.node);

    // fix pageInfo for reorder and fetchmore
    const pageInfo =
      !options.variables?.endCursor && existing?.pageInfo
        ? existing.pageInfo
        : incoming?.pageInfo;

    return {
      ...options.mergeObjects(existing, incoming),
      nodes: finalNodesRef,
      pageInfo,
    };
  },
};

export const appInstancesField: fieldsPolicyType = {
  availableAppInstancesBySpaceIdAndAppId: {
    keyArgs: ["spaceId", "orderBy", "appId", "condition", "filter"],
    ...appInstancePolicy,
  },
};

export const appInstancePickerField: fieldsPolicyType = {
  appInstancesByOrgId: {
    keyArgs: ["orgId", "spaceId", "orderBy", "appId", "condition", "filter"],
    ...appInstancePolicy,
  },
};

export const appInstancesBySpaceIdField: fieldsPolicyType = {
  availableAppInstancesBySpaceId: {
    keyArgs: ["spaceId", "orderBy", "appId", "filter"],
    merge(existing, incoming, options) {
      const existingNodes = existing ? [...existing.nodes] : [];
      const incomingNode = incoming?.nodes ? [...incoming.nodes] : [];
      const nodes = uniqBy([...existingNodes, ...incomingNode], "__ref");
      const reOrder = orderBy(
        nodes.map((node) => {
          const created = options.readField("createdAt", node) as string;
          return {
            created,
            node,
          };
        }),
        ["created"],
        ["desc"]
      );
      const finalNodesRef = reOrder.map((re) => re.node);

      // fix pageInfo for reorder and fetchmore
      const pageInfo =
        !options.variables?.endCursor && existing?.pageInfo
          ? existing.pageInfo
          : incoming?.pageInfo;
      return {
        ...options.mergeObjects(existing, incoming),
        nodes: finalNodesRef,
        pageInfo,
      };
    },
  },
};

const searchAppInstancesPolicy = {
  merge(existing, incoming, options) {
    const existingNodes = existing ? [...existing.nodes] : [];
    const incomingNode = incoming?.nodes ? [...incoming.nodes] : [];
    const nodes = uniqBy([...existingNodes, ...incomingNode], "__ref");
    const reOrder = orderBy(
      nodes.map((node) => {
        const name = options.readField("name", node) as string;
        return {
          name,
          node,
        };
      }),
      [(node) => node?.name?.toLowerCase()],
      ["asc"]
    );
    const finalNodesRef = reOrder.map((re) => re.node);

    // fix pageInfo for reorder and fetchmore
    const pageInfo =
      !options.variables?.endCursor && existing?.pageInfo
        ? existing.pageInfo
        : incoming?.pageInfo;
    return {
      ...options.mergeObjects(existing, incoming),
      nodes: finalNodesRef,
      pageInfo,
    };
  },
};

export const searchAppinstancesField: fieldsPolicyType = {
  searchAppInstance: {
    keyArgs: ["query", "spaceId", "orderBy", "filter", "condition"],
    ...searchAppInstancesPolicy,
  },
};

export const searchAppInstancesByAppIdField: fieldsPolicyType = {
  searchAppInstanceSpecificAppId: {
    keyArgs: ["query", "spaceId", "orderBy", "appId"],
    ...searchAppInstancesPolicy,
  },
};
