import { FormattedMessage } from "react-intl";
import { File, Maybe } from "src/types.g";
import { Styled } from "src/components/Admin/Theme/ThemePreview/styles";
import { Font } from "src/components/Admin/Theme/ThemeConfigure";
import {
  IStudioCustomCssFont,
  IStudioCustomFileFont,
  StudioCustomCssFont,
  StudioCustomFileFont,
} from "@screencloud/studio-custom-fonts";
import { useEffect } from "react";
import { cloneDeep } from "lodash";

export interface FontData {
  boldFont: string;
  boldItalicFont: string;
  cssUrl: string;
  fontFamily: string;
  italicFont: string;
  regularFont: string;
}
export interface ThemePreviewProps {
  className?: string;
  primaryColor: string;
  secondaryColor: string;
  titleColor: string;
  bodyColor: string;
  headingFont?: Font;
  bodyFont?: Font;
  image?: Maybe<Partial<File>> | null;
  headingFontData?: FontData;
  bodyFontData?: FontData;
}

export const isFontDataExist = (fontData?: FontData) => {
  const keys = [
    "boldFont",
    "boldItalicFont",
    "cssUrl",
    "fontFamily",
    "italicFont",
    "regularFont",
  ];
  return keys.some((key) => fontData?.[key]);
};

const ThemePreview = (props: ThemePreviewProps) => {
  const { headingFontData, bodyFontData } = props;

  const regisCustomFont = (fontData?: FontData) => {
    const cf = fontData?.cssUrl
      ? new StudioCustomCssFont({ font: fontData as IStudioCustomCssFont })
      : new StudioCustomFileFont({ font: fontData as IStudioCustomFileFont });

    cf.register();
    return () => {
      cf.unregister();
    };
  };

  useEffect(() => {
    [headingFontData, bodyFontData].forEach((fontData) => {
      if (isFontDataExist(fontData)) {
        regisCustomFont(fontData);
      }
    });
  }, [headingFontData, bodyFontData]);

  const newProps = cloneDeep(props);
  if (props.headingFontData?.fontFamily && newProps.headingFont?.family) {
    newProps.headingFont.family = props.headingFontData?.fontFamily;
  }

  if (props.bodyFontData?.fontFamily && newProps.bodyFont?.family) {
    newProps.bodyFont.family = props.bodyFontData?.fontFamily;
  }

  return (
    <Styled {...newProps}>
      <div data-testid="theme-preview">
        <p data-testid="theme-preview-title" className="title">
          <FormattedMessage
            id="admin.themes.preview"
            defaultMessage="Preview"
          />
        </p>
        <div className="wrapper">
          <p className="body" data-testid="theme-preview-body">
            <FormattedMessage
              id="admin.themes.the_quick_brown_fox"
              defaultMessage="The quick brown fox jumps over the lazy dog"
            />
          </p>
          <div
            className="horizontal-bar"
            data-testid="theme-preview-horizontal-bar"
          />
        </div>
      </div>
    </Styled>
  );
};

export default ThemePreview;
