import { IStudioSpacePermissionTree } from "@screencloud/studio-permissions/build/studioTypes.g";
import { UUID } from "@screencloud/uuid";
import { appConfig } from "../appConfig";
import { AppContextState } from "../AppContextProvider";
import { FEATURE_FLAGS_ENUM } from "../constants/featureFlag";
import query from "../state/helper/query";
import { getSpaceNameById } from "./spaceHelper";
import { SharedSpaceWithSharingModesConnection } from "src/types.g";

type Nullable<T> = T | null | undefined;

export interface Shareable {
  __typename?: string;
  isEmbedPublic?: Nullable<boolean>;
  isEmbedEnabled?: Nullable<boolean>;
  isSharedAll?: Nullable<boolean>;
  isShared?: Nullable<boolean>;
  isSharedExplicit?: Nullable<boolean>;
  spaceId?: string | undefined;
  sharedSpacesWithSharingModeByChannelId?: Nullable<SharedSpaceWithSharingModesConnection>;
  id: string;
  preferences?: { default_sharing_mode?: string };
  childOf?: Nullable<string>;
}

export function isSupportEmbededLink({
  context,
  shareable,
}: {
  context: Pick<
    AppContextState,
    "allSpaces" | "currentPermissions" | "currentSpace" | "shouldShowFeature"
  >;
  shareable: Pick<Shareable, "__typename">;
}) {
  const supportedEmbededLinkType = ["Playlist", "Channel"];
  return (
    supportedEmbededLinkType.some((item) => item === shareable?.__typename) &&
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.EMBED_PLAYER)
  );
}

function getPermissionObject(
  shareable: Pick<Shareable, "__typename">
): keyof IStudioSpacePermissionTree | undefined {
  const object = ({
    Playlist: "playlist",
    Channel: "channel",
    CommonAppInstanceTemplate: "app_instance",
    AppInstance: "app_instance",
    Folder: "media",
    File: "media",
    Site: "site",
  } as { [key: string]: keyof IStudioSpacePermissionTree })[
    shareable?.__typename || ""
  ];

  if (!object) {
    console.error(
      "shareable helper > getPermissionObject > not supported object ",
      shareable.__typename
    );
  }

  return object;
}

/**
 *
 * @param param0 spaceId come from any object under Space and can use for checking the object owner and share able
 */
export function isOwner({
  context,
  spaceId,
}: {
  context: Pick<AppContextState, "currentSpace">;
  spaceId?: UUID;
}) {
  return spaceId === context?.currentSpace?.id;
}

export function isShareableOwner({
  context,
  shareable,
}: {
  context: Pick<AppContextState, "currentSpace">;
  shareable: Pick<Shareable, "spaceId">;
}) {
  return shareable.spaceId === context?.currentSpace?.id;
}

export function isShareFeatureEnabled({
  context,
  shareable,
}: {
  context: Pick<
    AppContextState,
    "allSpaces" | "currentPermissions" | "currentSpace" | "shouldShowFeature"
  >;
  shareable: Pick<Shareable, "__typename">;
}) {
  const isShareToOtherSpacesEnable =
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.SHARING) &&
    (context?.allSpaces ?? []).length > 1;
  return (
    isShareToOtherSpacesEnable || isSupportEmbededLink({ shareable, context })
  );
}

/**
 *
 * @param param0 Can be shared to another space
 * In case of Folder, please check canFolderBeShared in folderHepler
 */
export function canBeShared({
  context,
  shareable,
}: {
  context: Pick<
    AppContextState,
    "allSpaces" | "currentPermissions" | "currentSpace" | "shouldShowFeature"
  >;
  shareable: Pick<Shareable, "spaceId" | "__typename">;
}) {
  const permissionObject = getPermissionObject(shareable);
  const hasPermissionToShare =
    permissionObject &&
    context.currentPermissions.validateCurrentSpace(permissionObject, "share");
  return (
    isShareFeatureEnabled({ context, shareable }) &&
    isOwner({ context, spaceId: shareable.spaceId }) &&
    hasPermissionToShare
  );
}

export function shouldShowShareIcon({
  context,
  shareable,
}: {
  context: Pick<
    AppContextState,
    "allSpaces" | "currentPermissions" | "currentSpace" | "shouldShowFeature"
  >;
  shareable: Pick<Shareable, "__typename">;
}) {
  if (shareable && !shareable?.__typename) {
    console.error(
      "shouldShowShareButton, shareable must have typename, shareable ===",
      shareable
    );
  }
  return isShareFeatureEnabled({ context, shareable });
}

export function shouldShowShareButton({
  context,
  shareable,
}: {
  context: Pick<
    AppContextState,
    "allSpaces" | "currentPermissions" | "currentSpace" | "shouldShowFeature"
  >;
  shareable: Shareable;
}) {
  if (shareable && !shareable?.__typename) {
    console.error(
      "shouldShowShareButton, shareable must have typename, shareable ===",
      shareable
    );
  }

  return canBeShared({ context, shareable });
}

/**
 * Is this sharable item shared from/to other
 *
 * @param shareable
 */
export function isShared(
  shareable: Pick<Shareable, "isShared" | "isSharedExplicit" | "isSharedAll">
): boolean {
  return Boolean(
    shareable?.isShared || shareable?.isSharedExplicit || shareable?.isSharedAll
  );
}

/**
 * Is this sharable item shared by link/embeded
 *
 * @param param0
 */
export function isSharedByLink({
  context,
  shareable,
}: {
  context: Pick<AppContextState, "shouldShowFeature">;
  shareable: Shareable;
}) {
  return (
    Boolean(shareable?.isEmbedEnabled) &&
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.EMBED_PLAYER)
  );
}

export function isSharedBySpaceOrLink({
  context,
  shareable,
}: {
  context: Pick<AppContextState, "shouldShowFeature">;
  shareable: Shareable;
}) {
  return isShared(shareable) || isSharedByLink({ context, shareable });
}

export function isShareByLinkPublicly({
  context,
  shareable,
}: {
  context: Pick<AppContextState, "shouldShowFeature">;
  shareable: Shareable;
}) {
  return (
    isSharedByLink({ shareable, context }) && Boolean(shareable?.isEmbedPublic)
  );
}

export function isShareByLinkWithinOrg({
  context,
  shareable,
}: {
  context: Pick<AppContextState, "shouldShowFeature">;
  shareable: Shareable;
}) {
  return (
    isSharedByLink({ shareable, context }) && !Boolean(shareable?.isEmbedPublic)
  );
}

export async function getEmbededLink(
  context: AppContextState,
  shareable: Shareable,
  embedShortId?: string,
  isOnboarding: boolean = false
) {
  if (!isSupportEmbededLink({ shareable, context }) && !isOnboarding) {
    return "";
  }
  if (embedShortId) {
    return `${appConfig.embedPlayerUrl}/${appConfig.regions.current.key}/${embedShortId}`;
  } else {
    const shortId = await query.getEmbedShortId(shareable.id);
    return `${appConfig.embedPlayerUrl}/${appConfig.regions.current.key}/${shortId}`;
  }
}

export function getShareButtonContent({
  context,
  shareable,
}: {
  context: Pick<
    AppContextState,
    "allSpaces" | "currentOrg" | "currentSpace" | "intl" | "shouldShowFeature"
  >;
  shareable: Shareable;
}) {
  const spaceName = getSpaceNameById(context, shareable?.spaceId);
  const isSharedFromAnotherSpace =
    !isOwner({ context, spaceId: shareable.spaceId }) && isShared(shareable);
  const orgName = context.currentOrg?.name;

  if (
    isOwner({ context, spaceId: shareable.spaceId }) &&
    !isShared(shareable) &&
    !isSharedByLink({ context, shareable })
  ) {
    return "Share";
  }

  if (isSharedFromAnotherSpace && !isSharedByLink({ context, shareable })) {
    return `Shared by ${spaceName} space`;
  }

  if (
    isSharedFromAnotherSpace &&
    isShareByLinkPublicly({ context, shareable })
  ) {
    return `Shared by ${spaceName} space and anyone with link can view`;
  }

  if (
    isSharedFromAnotherSpace &&
    isShareByLinkWithinOrg({ context, shareable })
  ) {
    return `Shared by ${spaceName} space and anyone in your ${orgName} with link can view`;
  }

  if (
    isOwner({ context, spaceId: shareable.spaceId }) &&
    isShared(shareable) &&
    isShareByLinkPublicly({ context, shareable })
  ) {
    return `Shared and anyone with link can view`;
  }

  if (
    isOwner({ context, spaceId: shareable.spaceId }) &&
    isShared(shareable) &&
    isShareByLinkWithinOrg({ context, shareable })
  ) {
    return `Shared and anyone in your ${orgName} with link can view`;
  }

  if (
    isOwner({ context, spaceId: shareable.spaceId }) &&
    isShareByLinkPublicly({ context, shareable })
  ) {
    return `Anyone with link can view`;
  }

  if (
    isOwner({ context, spaceId: shareable.spaceId }) &&
    isShareByLinkWithinOrg({ context, shareable })
  ) {
    return `Anyone in your ${orgName} with link can view`;
  }

  return "Shared";
}

export function canBeDeleted({
  context,
  shareable,
}: {
  context: Pick<AppContextState, "currentSpace" | "currentPermissions">;
  shareable: Pick<Shareable, "spaceId" | "__typename">;
}) {
  const permissionObject = getPermissionObject(shareable);
  return (
    isOwner({ context, spaceId: shareable.spaceId }) &&
    permissionObject &&
    context.currentPermissions.validateCurrentSpace(permissionObject, "delete")
  );
}

export function canBeUpdated({
  context,
  shareable,
}: {
  context: Pick<AppContextState, "currentSpace" | "currentPermissions">;
  shareable: Pick<Shareable, "spaceId" | "__typename">;
}) {
  const permissionObject = getPermissionObject(shareable);
  return (
    isOwner({ context, spaceId: shareable.spaceId }) &&
    permissionObject &&
    context.currentPermissions.validateCurrentSpace(permissionObject, "update")
  );
}

export function getShareableData(obj): Shareable {
  return {
    __typename: obj?.__typename,
    isEmbedPublic: obj?.isEmbedPublic,
    isEmbedEnabled: obj?.isEmbedEnabled,
    isSharedAll: obj?.isSharedAll,
    isSharedExplicit: obj?.isSharedExplicit,
    spaceId: obj?.spaceId,
    sharedSpacesWithSharingModeByChannelId:
      obj?.sharedSpacesWithSharingModeByChannelId,
    id: obj?.id,
    preferences: obj.preferences,
    childOf: obj?.childOf,
  };
}
