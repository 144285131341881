import styled from "styled-components";
import { Theme } from "@screencloud/screencloud-ui-components";
import { WhiteLabelStyles } from "../../helpers/whiteLabel";

interface StyleProps {
  hideSearchInput?: boolean;
}

export const Styled = styled.div<StyleProps & WhiteLabelStyles>`
  padding: 0 10px;
  width: 100%;

  .ui.dropdown.selection.search {
    width: 100%;
    min-width: 0;
    height: 50px;
    background: ${(props) =>
      props.bgColor ? `var(--color-black-10)` : `var(--color-black-50)`};
    border: 1px solid
      ${(props) =>
        props.bgColor ? `var(--color-white-20)` : `var(--color-white-20)`};
    margin-bottom: 10px;
    padding: 0 15px;

    :hover,
    &.active {
      background: ${(props) =>
        props.bgColor ? `var(--color-black-20)` : `var(--color-black-80)`};
      border: 1px solid
        ${(props) =>
          props.bgColor ? `var(--color-white-50)` : `var(--color-white-50)`};
    }
  }

  .ui.active.selection.dropdown {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .ui.selection.dropdown > .dropdown.icon {
    display: none;
  }

  .current-group {
    color: ${(props) =>
      props.textColor
        ? `var(--color-primary-foreground-active)`
        : `var(--color-white-100)`};
    width: 100%;

    span {
      line-height: 1.5;
    }
  }

  .ui.dropdown.selection.search .current-group .subheader-input-text {
    color: ${(props) =>
      props.textColor
        ? `var(--color-primary-foreground-active)`
        : `var(--color-white-100)`};
    line-height: 16px;
    opacity: 0.5;
  }

  .ui.dropdown.search.visible .ui.input {
    width: 320px;
    margin-top: 4px;
  }

  .ui.dropdown.search.visible .menu {
    top: 48px;

    .item {
      &.custom-divider {
        border-top: 1px solid var(--color-border);
        margin-top: 10px;
        padding-top: 18px !important;
      }
    }

    width: 320px;
    margin-top: 66px;
    max-height: 400px;
    height: auto;
  }

  ${(props: StyleProps) =>
    props.hideSearchInput &&
    `
    .ui.dropdown.search.visible .menu {
      margin-top: 0;
      top: 53px;

      @media (max-width: ${Theme.break.largeHD}) {
        top: 36px;
      }

      @media (max-width: ${Theme.break.small}) {
        top: 46px;
      }
    }
  `}

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .ui.dropdown.selection.search {
      .current-group {
        display: inline-flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .subheader-input-text {
          display: none;
        }
        p {
          font-size: 1.25em;
          visibility: hidden;

          :first-letter {
            visibility: visible;
            font-weight: 900;
          }
        }
      }

      &.visible {
        .input {
          width: 320px;
        }

        .menu {
          width: 320px;
        }
      }
    }
  }

  @media (max-width: ${Theme.break.small}) {
    .ui.dropdown.selection.search {
      .current-group {
        display: block;
        text-align: left;

        .subheader-input-text {
          display: block;
        }
        p {
          font-size: 1em;
          visibility: visible;

          :first-letter {
            font-weight: normal;
          }
        }
      }

      &.visible {
        .input {
          top: 49px;
          width: calc(100% + 2px);
        }

        .menu {
          width: 100%;
        }
      }
    }
  }
`;

export const CustomOptionStyled = styled.div``;
