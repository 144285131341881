import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  @keyframes bgFade {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .wrapper {
    &.detail {
      .column-title {
        width: auto;

        h1 {
          line-height: initial;
        }
      }
    }
  }

  .player-preview {
    > .preview {
      > .playback-bar {
        > .playback-buttons {
          width: calc(100% - 80px);
          position: absolute;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &.playlist-detail {
    height: 100%;
    > div {
      height: 100%;
    }

    .header {
      height: 90px;
      flex-shrink: 0;

      .wrapper {
        &.detail {
          .column-options {
            > .ui.button {
              min-width: 45px;

              &.btn-preview {
                i {
                  display: none;
                }
              }

              &.share-button,
              &.shared-button,
              &.button-casting,
              &.duplicate-button {
                width: 45px;
              }
            }
          }
        }
      }
    }

    .container {
      padding: 0;
      display: flex;
      flex-direction: column;

      .playlist-content-options {
        display: flex;
        justify-content: space-between;
        padding: var(--spacing-lg, 16px) var(--spacing-xxl, 40px)
          var(--spacing-lg, 16px) var(--spacing-xxxl, 56px);
        gap: var(--spacing-sm, 8px);
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 7;
        background-color: var(--color-gray-50);

        .total-duration {
          display: flex;
          flex-direction: column;

          span {
            color: var(--color-subtle);
            font-size: 14px;
            line-height: 20px;
          }

          h3 {
            font-size: 20px;
            line-height: 24px;
            margin: 0;
          }
        }
      }

      .playlist-content-list {
        width: 100%;

        > div {
          padding: 0 0 var(--spacing-xxxl, 56px) var(--spacing-xxxl, 56px);
          height: 100%;
        }

        .drop-zone {
          width: 100%;
          min-height: 100%;
          height: auto;
        }

        .empty-state {
          height: 100%;
          padding: 0;
          position: absolute;
          padding-bottom: 87px;
          top: 0;

          .ui.button {
            display: none;
          }

          + .drop-zone {
            + .btn-add-content {
              display: none;
            }
          }
        }

        div[draggable="false"],
        div[data-react-beautiful-dnd-draggable="0"] {
          background: var(--color-background);
          border: 1px solid var(--color-gray-100);
          border-top: 1px solid transparent;
          margin-right: 40px;
          position: relative;

          &:hover {
            z-index: 3;
            background: var(--color-gray-50);
          }

          &.fade-item {
            :before {
              animation: bgFade 2s linear;
              animation-fill-mode: forwards;
              display: block;
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background: var(--color-background-active);
              content: "";
              z-index: 0;
            }
          }
        }

        div[draggable="false"]:first-child,
        div[data-react-beautiful-dnd-draggable="0"]:first-child {
          border-radius: ${Theme.default.borderRadiusValue}
            ${Theme.default.borderRadiusValue} 0 0;
          border: 1px solid var(--color-gray-100);
        }

        div[draggable="false"]:last-child,
        div[data-react-beautiful-dnd-draggable="0"]:last-child {
          border-radius: 0 0 ${Theme.default.borderRadiusValue}
            ${Theme.default.borderRadiusValue};
        }

        div[draggable="false"]:only-child,
        div[data-react-beautiful-dnd-draggable="0"]:only-child {
          ${Theme.default.borderRadius};
        }

        .btn-add-content {
          display: none;
        }
      }
    }

    .sidebar-playlist {
      .ui.menu.secondary {
        .item {
          display: inline-block;
          text-align: center;
          align-self: auto;
          width: 50%;
          padding: 0;
        }
      }

      .wrapper {
        &.content {
          gap: var(--spacing-xs);

          > div {
            margin-top: 0;
          }
        }
      }

      .default-duration {
        padding: 0 10px 0;
        background: ${Theme.color.nearWhite};
        ${Theme.default.borderRadius};
        border: 1px solid ${Theme.color.silver};
        display: flex;
        flex-direction: column;

        .row {
          display: inline-flex;
          align-items: center;
          padding: 10px 0;
          border-bottom: 1px solid ${Theme.color.silver};

          .column {
            width: 50%;

            :last-child {
              width: 50%;
              display: inline-flex;
              justify-content: flex-end;
            }
          }
          :last-child {
            border-bottom: none;
          }
        }
      }
    }

    @media screen and (max-width: ${Theme.break.largeHD}) {
      .container {
        .playlist-content-options {
          padding: var(--spacing-lg, 16px) var(--spacing-xxl, 40px);
        }

        .playlist-content-list {
          > div {
            padding: 0 0 var(--spacing-xxl, 40px) var(--spacing-xxl, 40px);
          }
        }
      }
    }

    @media screen and (max-width: ${Theme.break.mediumAlt}) {
      .header {
        .wrapper.detail {
          .column-title {
            max-width: calc(100% - 60px);
          }

          .column-options {
            width: 100%;
            max-width: calc(100% - 70px);
          }
        }
      }

      .container {
        min-height: 400px;
        width: 100%;

        .playlist-content-list {
          height: 100%;
          max-height: 1200px;

          .empty-state {
            .ui.button {
              display: block;
            }

            &.empty-playlist-detail {
              padding: 0 20px;
              position: relative;
            }

            + .drop-zone {
              height: 0;
              min-height: 0;
              padding: 0;
            }
          }

          .drop-zone {
            min-height: 0;
          }
        }
      }

      .sidebar-playlist {
        height: 320px;

        .menu {
          width: 100%;
          z-index: 0;
        }

        .wrapper {
          height: auto;
          overflow: auto;
        }
      }
    }

    @media screen and (max-width: ${Theme.break.small}) {
      .container {
        height: 100%;
        overflow: unset;
        top: 0;

        .playlist-content-options {
          padding: var(--spacing-lg, 16px) var(--spacing-lg, 16px);
        }

        .playlist-content-list {
          > div {
            padding: 0 0 var(--spacing-xxl, 40px) var(--spacing-lg, 16px);
          }

          .drop-zone {
            min-height: 0;
          }

          .btn-add-content {
            margin-left: 20px;
          }
        }
      }

      .header {
        .wrapper {
          &.detail {
            .column-options {
              justify-content: flex-start;
              width: 100%;
              max-width: 100%;

              > .ui.button {
                max-width: 100%;
                width: calc(50% - 77.5px);

                &.btn-preview {
                  margin-left: auto;
                  width: auto;
                }
              }

              .button-publish {
                width: calc(33.33% - 25px);

                .button-group {
                  width: 100%;

                  .button-dropdown {
                    .menu {
                      ${Theme.default.borderRadius};
                      top: auto;
                      right: 0;
                    }
                  }

                  .button-action {
                    width: calc(100% - 32px);
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: ${Theme.break.tiny}) {
      .header {
        .wrapper {
          &.detail {
            .column-title {
              padding: 0;

              h1 {
                width: calc(100% - 40px);
              }
            }

            .column-options {
              > .ui.button,
              .button-publish {
                width: calc(100% - 55px);
              }

              > .ui.button {
                &.btn-preview {
                  padding: 0;
                  width: 45px;
                  min-width: 45px;

                  i {
                    display: block;
                  }
                  span {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const StyledOption = styled.div`
  &.wrapper {
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .playlist-actions-btn {
    width: 100%;
  }
  .playlist-color-picker {
    margin-top: 20px;
  }

  &.readonly {
    pointer-events: none;
    user-select: none !important;
    opacity: 0.45;

    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;

    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }
`;
