import { Loader, Theme } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import AppTabPaneItem, {
  AppTabPaneItemActionEnum,
  AppTabPaneItemActions,
} from "../../components/AppTabPane/appItem";
import EmptyState from "../../components/EmptyState";
import LinkContentItemWrapper from "../../components/LinkContentItem";
import { LinkListItemActions } from "../../components/LinkListItem";
import MediaFile from "../../components/Media/mediaFile";
import MediaFolder from "../../components/Media/mediaFolder";
import MediaItemDnD from "../../components/Media/mediaItemDnD";
import SiteContentItemWrapper from "../../components/SiteContentItem";
import { SiteListItemActions } from "../../components/SiteListItem";
import { Typenames } from "../../constants/constants";
import {
  AppInstance,
  AvailableAppInstanceFragment,
  Exact,
  File,
  Folder,
  JobStatus,
  Link,
  Maybe,
  Scalars,
  SidebarQueryQuery,
  Site,
} from "../../types.g";
import { MediaFileActions } from "../Media/mediaFile";
import { MediaFolderActions } from "../Media/mediaFolder";
import { SidebarSearchProps, withSidebarSearchData } from "./apollo";
import { StyledSearchAllList } from "./styles";
import { ApolloQueryResult } from "@apollo/client";

export interface SidebarProps {
  sidebarData: SidebarQueryQuery;
  sidebarDataLoading: boolean;
  sidebarRefetch: (
    variables?:
      | Partial<
          Exact<{
            spaceId: any;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<SidebarQueryQuery>>;
  loadMore: any;
  searchTerms: string;
  selectedMediaFileCallback?: (media: File | Folder) => void;
  onClickMediaFileCallback: (
    mediaId: string[],
    action: MediaFileActions,
    value: Partial<File>[] | string | boolean
  ) => void;
  onClickMediaFolderCallback: (
    mediaId: string,
    action: MediaFolderActions,
    value: Partial<File>[]
  ) => void;
  onClickLinkCallback: (
    action: LinkListItemActions,
    value: Partial<Link> | string | boolean,
    event?: React.SyntheticEvent
  ) => void;
  selectedLinkCallback: (link: Partial<Link>) => void;
  onClickSiteCallback: (
    action: SiteListItemActions,
    value: Partial<Site> | string | boolean,
    event?: React.SyntheticEvent
  ) => void;
  selectedSiteCallback: (site: Partial<Site>) => void;
  addItemToPlaylist: (
    app: AvailableAppInstanceFragment,
    action: AppTabPaneItemActions
  ) => void;
}

export interface SidebarState {
  isPreviewOpen: boolean;
  isOpenMediaSideBarUpload: boolean;
  isCreatingFolder: boolean;
  showCreateFolderForm: boolean;
  selectedNewFolderId: Scalars["UUID"];
  allItems: (
    | File
    | Folder
    | Partial<Link>
    | AvailableAppInstanceFragment
    | Partial<Site>
  )[];
  isSearchLoading: boolean;
}

class Sidebar extends React.Component<
  SidebarProps & SidebarSearchProps,
  SidebarState
> {
  public static contextType = AppContext;

  public static getDerivedStateFromProps(
    nextProps: SidebarProps & SidebarSearchProps,
    prevState: SidebarState
  ) {
    let allItems: (
      | Maybe<Partial<File>>
      | Maybe<Partial<Folder>>
      | Maybe<Partial<Link>>
      | AvailableAppInstanceFragment
      | Maybe<Partial<Site>>
    )[] = [];
    if (
      nextProps.sidebarData?.spaceById &&
      nextProps.sidebarData.spaceById.filesBySpaceId &&
      nextProps.sidebarData.spaceById.linksBySpaceId &&
      nextProps.sidebarData.spaceById.availableAppInstancesBySpaceId &&
      nextProps.sidebarData.spaceById.availableSitesBySpaceId
    ) {
      allItems = [
        ...(nextProps.sidebarData.spaceById.filesBySpaceId.nodes.filter(
          (node) => node!.folderId !== null
        ) as File[]),
        ...(nextProps.sidebarData.spaceById.linksBySpaceId.nodes as Link[]),
        ...(nextProps.sidebarData.spaceById.availableAppInstancesBySpaceId
          .nodes as AppInstance[]),
        ...(nextProps.sidebarData.spaceById.availableSitesBySpaceId
          .nodes as Site[]),
      ];
    }
    if (
      nextProps.searchTerms.length > 0 &&
      nextProps.sidebarSearchData?.searchFile &&
      nextProps.sidebarSearchData?.searchFolder &&
      nextProps.sidebarSearchData?.searchAppInstance &&
      nextProps.sidebarSearchData?.searchLink &&
      nextProps.sidebarSearchData?.searchSite
    ) {
      allItems = [
        ...(nextProps.sidebarSearchData.searchAppInstance
          .nodes as AppInstance[]),
        ...(nextProps.sidebarSearchData.searchFile.nodes as File[]),
        ...(nextProps.sidebarSearchData.searchFolder.nodes as Folder[]),
        ...(nextProps.sidebarSearchData.searchLink.nodes as Link[]),
        ...(nextProps.sidebarSearchData.searchSite.nodes as Site[]),
      ];
    }
    return {
      allItems: allItems.sort((a, b) => {
        const dateA = new Date(a!.createdAt);
        const dateB = new Date(b!.createdAt);
        return dateB > dateA ? 1 : dateA > dateB ? -1 : 0;
      }),
    };
  }
  public context: AppContextType;
  public _isMounted: boolean;
  constructor(props: SidebarProps & SidebarSearchProps) {
    super(props);
    this.state = {
      allItems: [],
      isCreatingFolder: false,
      isOpenMediaSideBarUpload: false,
      isPreviewOpen: false,
      isSearchLoading: false,
      selectedNewFolderId: "",
      showCreateFolderForm: false,
    };
  }
  public componentDidMount() {
    this._isMounted = true;
  }

  public componentDidUpdate(
    prevProps: SidebarProps & SidebarSearchProps,
    prevState: SidebarState
  ) {
    if (prevProps.searchTerms !== this.props.searchTerms) {
      this.setState({
        allItems: [],
      });
    }
  }

  public async componentWillUnmount() {
    this._isMounted = false;
  }

  public onShowAppconfigure = async (app: AvailableAppInstanceFragment) => {
    if (app) {
      const { success } = await this.context.modal.openAppConfigure(
        app.id,
        undefined,
        undefined,
        undefined,
        <FormattedMessage id="apps.configure" defaultMessage="Configure" />
      );
      if (success && this.props.addItemToPlaylist) {
        await this.props.sidebarRefetch();
        if (this.props.sidebarSearchData) {
          await this.props.sidebarSearchRefetch();
        }
        this.props.addItemToPlaylist(app, AppTabPaneItemActionEnum.PREVIEW);
      }
    }
  };

  public handleMediaFileCallback = async (
    id: string,
    action: MediaFileActions,
    value: string | boolean,
    event?: React.SyntheticEvent,
    index?: number
  ) => {
    if (index !== undefined) {
      const mediaFile = this.state.allItems[index] as File;
      if (mediaFile) {
        this.props.onClickMediaFileCallback([id], action, [mediaFile]);
      }
    }
  };

  public handleMediaFolderCallback = async (
    id: string,
    action: MediaFolderActions,
    value: File[] | string | boolean,
    event?: React.SyntheticEvent,
    index?: number
  ) => {
    switch (action) {
      case MediaFolderActions.SELECTED:
        if (this.props.canSelectFolder && event && index !== undefined) {
        }
        break;
      case MediaFolderActions.ENTER_FOLDER:
        this.props.onClickMediaFolderCallback(id, action, []);
        break;
      case MediaFolderActions.ADD:
        if (value) {
          this.props.onClickMediaFolderCallback(id, action, value as File[]);
        }
        break;
      default:
      // this.props.onMediaFolderCallback(id, action, value)
    }
  };

  public renderMediaFolder = (
    folder: Folder,
    index: number,
    selected: boolean
  ) => {
    return (
      <MediaItemDnD
        key={folder.id}
        index={index}
        media={folder}
        selectedMediaItems={[folder]}
        selectedMediaFileCallback={this.props.selectedMediaFileCallback}
        isDraggable={true}
        isDroppable={false}
        onDrop={this.props.onDropMediaItems}
        useReactDnD={false}
      >
        <MediaFolder
          key={`media-folder-picker-${folder.id}`}
          callBack={this.handleMediaFolderCallback}
          index={index}
          media={folder as Folder}
          canSelect={true}
          isReadonly={true}
          isSelected={selected}
          isNewFolder={false}
          isCompactLayout={true}
          showActionMenu={false}
          showFavorite={false}
          isVisible={true}
        />
      </MediaItemDnD>
    );
  };

  public renderMediaFile = (file: File, index: number, selected: boolean) => {
    return (
      <MediaItemDnD
        key={file.id}
        index={index}
        media={file}
        selectedMediaItems={[file]}
        selectedMediaFileCallback={this.props.selectedMediaFileCallback}
        isDraggable={true}
        isDroppable={false}
        useReactDnD={false}
      >
        <MediaFile
          key={`media-file-picker-${file.id}`}
          callBack={this.handleMediaFileCallback}
          castedScreensCallback={this.props.castedScreensCallback}
          index={index}
          duration={0}
          media={file}
          canAdd={file.fileProcessingStatus === JobStatus.Failed ? false : true}
          canDelete={false}
          canSelect={false}
          canPreview={true}
          isReadonly={false}
          isSelected={selected}
          isGridMode={false}
          isCompactLayout={true}
          showActionMenu={false}
          showFavorite={false}
          showTags={false}
          showAddToPlaylists={this.props.showAddToPlaylists}
          shouldHighlighted={false}
          isVisible={true}
        />
      </MediaItemDnD>
    );
  };

  public renderLink = (link: Link, index) => {
    return (
      <Droppable
        isDropDisabled={true}
        droppableId={`link-${link.id}`}
        key={link.id}
      >
        {(
          droppableProvided: DroppableProvided,
          droppableSnapshot: DroppableStateSnapshot
        ) => (
          <div
            key={`link-column-${link.id}`}
            className="column link-item"
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            style={{
              backgroundColor: droppableSnapshot.isDraggingOver
                ? Theme.color.silver
                : "transparent",
            }}
          >
            <LinkContentItemWrapper
              key={`link-content-${link.id}`}
              selectedLinkCallback={this.props.selectedLinkCallback}
              onClickLinkCallback={this.props.onClickLinkCallback}
              index={index}
              linkItem={link}
              secureMediaPolicy={this.context.secureMediaPolicy}
            />
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  public renderSite = (site: Site, index) => {
    return (
      <Droppable
        isDropDisabled={true}
        droppableId={`site-${site.id}`}
        key={site.id}
      >
        {(
          droppableProvided: DroppableProvided,
          droppableSnapshot: DroppableStateSnapshot
        ) => (
          <div
            key={`site-column-${site.id}`}
            className="column site-item"
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            style={{
              backgroundColor: droppableSnapshot.isDraggingOver
                ? "#eee"
                : "transparent",
            }}
          >
            <SiteContentItemWrapper
              key={`site-content-${site.id}`}
              selectedSiteCallback={this.props.selectedSiteCallback}
              onClickSiteCallback={this.props.onClickSiteCallback}
              index={index}
              siteItem={site}
              secureMediaPolicy={this.context.secureMediaPolicy}
            />
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  public renderAppInstance = (
    appInstance: AvailableAppInstanceFragment,
    index
  ) => {
    return (
      <Droppable
        isDropDisabled={true}
        droppableId={`app-${appInstance.id}`}
        key={appInstance.id}
      >
        {(
          droppableProvided: DroppableProvided,
          droppableSnapshot: DroppableStateSnapshot
        ) => (
          <div
            key={`app-instance-column-${appInstance.id}`}
            className="column app-item"
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            style={{
              backgroundColor: droppableSnapshot.isDraggingOver
                ? Theme.color.silver
                : "transparent",
            }}
          >
            <AppTabPaneItem
              key={`app-instance-column-${appInstance.id}`}
              addItemToPlaylist={this.props.addItemToPlaylist}
              onShowAppConfigure={this.onShowAppconfigure}
              index={index}
              app={appInstance}
              isCanvas={this.context.canvasAppId === appInstance.appByAppId?.id}
            />
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  public render() {
    const {
      sidebarDataLoading,
      searchTerms,
      sidebarSearchDataLoading,
    } = this.props;
    const { isSearchLoading, allItems } = this.state;
    const hasSearchTerm = searchTerms.length;
    const isLoading = sidebarSearchDataLoading || sidebarDataLoading;

    const renderAllItems = allItems
      .map((item, index) => {
        if (item.__typename === "File") {
          return this.renderMediaFile(item as File, index, false);
        } else if (item.__typename === "Folder") {
          if (this.context.currentSpace?.rootFolderId !== item.id) {
            return this.renderMediaFolder(item as Folder, index, false);
          }
          return null;
        } else if (item.__typename === "Link") {
          return this.renderLink(item as Link, index);
        } else if (item.__typename === Typenames.Site) {
          return this.renderSite(item as Site, index);
        } else {
          return this.renderAppInstance(
            item as AvailableAppInstanceFragment,
            index
          );
        }
      })
      .filter(Boolean);

    return (
      <StyledSearchAllList loading={isSearchLoading || isLoading}>
        {isSearchLoading || isLoading ? (
          <Loader inline size="small" />
        ) : (
          <>
            <div className="breadcrumb-sidebar">
              <div style={{ lineHeight: "36px", fontWeight: "bold" }}>All</div>
            </div>
            <div className="layout-container">
              <div key="layout-list" className="layout-list">
                {renderAllItems.length > 0 ? (
                  renderAllItems
                ) : hasSearchTerm ? (
                  <EmptyState
                    key="empty-state"
                    section="media-sidebar"
                    cover={false}
                    className="empty"
                  >
                    <p key="empty-paragraph">
                      <FormattedMessage
                        key="empty-result-notfound-formatted-message"
                        id="common.text.no_result_found"
                        defaultMessage="No results found"
                      />
                    </p>
                  </EmptyState>
                ) : (
                  <EmptyState
                    key="empty-state-2"
                    section="media-sidebar"
                    cover={false}
                    className="empty"
                  >
                    <h3 key="empty-no-content-upload">
                      No content uploaded yet
                    </h3>
                    <p key="empty-no-content-in-playlist">
                      Upload your content to add to this playlist
                    </p>
                  </EmptyState>
                )}
              </div>
            </div>
          </>
        )}
      </StyledSearchAllList>
    );
  }
}

export default withSidebarSearchData(
  Sidebar
) as React.ComponentType<SidebarProps>;
