import { useModifyFolderCache } from "src/hooks/caches/modifications/useModifyFolderCache";
import { useCacheInvalidation } from "src/hooks/useCacheInvalidation";
import { File } from "src/types.g";

interface OnFileDeletedProps {
  file: Pick<File, "__typename" | "id" | "folderId">;
}

export const useOnDeleteFile = () => {
  const {
    invalidateEntityCache,
    invalidateScreenGroupCache,
  } = useCacheInvalidation();
  const { removeFileFromFolder } = useModifyFolderCache();

  const onFileDeleted = ({ file }: OnFileDeletedProps) => {
    removeFileFromFolder({ file });
    invalidateEntityCache(file);
    invalidateScreenGroupCache();
  };

  return {
    onFileDeleted,
  };
};
