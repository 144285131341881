import { Component, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Styled } from "./styles";
import appNotConfiguredImage from "../../../images/configure-app.svg";
export interface AppNotConfiguredProps {
  message?: string | ReactNode;
}

class AppNotConfigured extends Component<AppNotConfiguredProps, {}> {
  public render() {
    const { message } = this.props;

    return (
      <Styled>
        <img src={appNotConfiguredImage} alt="Application not yet configured" />
        <div className={"not-configured-title"}>
          <h3>
            <FormattedMessage
              defaultMessage="App instance preview"
              id="apps.no_yet_configure_title"
            />
          </h3>
        </div>
        <div className={"not-configured-instructions"}>
          {message ? (
            message
          ) : (
            <FormattedMessage
              defaultMessage="Edit and save the configuration to preview this app instance"
              id="apps.no_yet_configure"
            />
          )}
        </div>
      </Styled>
    );
  }
}

export default AppNotConfigured;
