import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

// todo: disabled prop is not used, remove?
export const Form = styled.form<{ disabled?: boolean; children?: any }>`
  .row {
    margin: 0 0 20px;
    display: block;

    .heading {
      margin-bottom: 10px;
    }

    &.gap {
      margin: 0 0 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .label {
    margin: 0 0 8px;
    font-size: 16px;
  }
  .desc {
    margin: 0 0 10px;
  }
  .help {
    margin-top: 8px;
    font-size: 14px;
    color: rgba(31, 32, 39, 0.8);
  }
  .buttons {
    padding-top: 24px;
    &.none {
      padding-top: 0;
    }
  }

  .row.checkbox {
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    .label {
      margin: 0 12px 0 0;
      flex-grow: 1;
    }
    .help {
      width: 100%;
    }
  }

  /* Style for api token form */
  &.token {
    height: 100%;

    .content {
      padding: 20px;
      overflow: auto;
      .message {
        i {
          width: 40px;
          background-color: ${Theme.color.darkGrey};
        }

        span {
          width: calc(100% - 40px);
          font-size: 0.875em;
          line-height: 1.357;
        }
      }

      /* Styles for new accordion */
      .window > .accordion {
        .custom-content > div {
          padding: 0 5px 0 20px;
        }
      }
    }

    .md-footer {
      border-radius: 0 0 ${Theme.default.borderRadiusValue}
        ${Theme.default.borderRadiusValue};

      .button {
        width: calc(50% - 5px);

        &.btn-regenerate {
          margin-left: 10px;
        }

        &.btn-create {
          width: 100%;
        }
      }
    }
  }
`;
