import { ApolloQueryResult } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  FIRST_FETCH_ITEMS,
  MAXIMUM_FETCH_MORE_ITEMS,
  MINIMUM_FETCH_MORE_ITEMS,
} from "../constants/constants";
import { renderFetchMoreButton } from "../helpers/generalHelper";
import {
  Maybe,
  Exact,
  AvailableAppInstanceFragment,
  AppInstanceFilter,
  useSearchAppInstanceSpecificAppIdQuery,
  AppInstanceCondition,
  SearchAppInstanceSpecificAppIdQueryVariables,
  SearchAppInstanceSpecificAppIdQuery,
  AppInstance,
  SearchAppInstanceSpecificAppIdQueryResult,
  PageInfo,
  useSearchCanvasQuery,
  SearchCanvasQueryVariables,
  SearchCanvasQuery,
  SearchCanvasQueryResult,
  useCanvasCountQuery,
} from "../types.g";
import { useAppContext } from "./useAppContext";
import { AppInstancesExtendedAssociation } from "./useAllAppinstances";

export interface UseSearchCanvasInstances {
  appInstances:
    | AvailableAppInstanceFragment[]
    | AppInstancesExtendedAssociation[];
  hasNextPage: boolean;
  loadMore: () => void;
  loading: boolean;
  isLoading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            query: Maybe<string>;
            first: Maybe<number>;
            endCursor: any;
            spaceId: any;
            appId: any;
          }>
        >
      | undefined
  ) => Promise<
    ApolloQueryResult<SearchAppInstanceSpecificAppIdQuery | SearchCanvasQuery>
  >;
  isFirstTimeAlreadyLoaded: boolean;
  totalCount: number;
  renderFetchMoreButton: React.ReactNode;
}

export interface SearchCanvasInstancesProps {
  searchTerms: string;
  condition: AppInstanceCondition;
  filter?: AppInstanceFilter;
  skip?: boolean;
}

function useSearchCanvasAppInstancesSection(
  props: SearchCanvasInstancesProps
): UseSearchCanvasInstances {
  const context = useAppContext();
  const [totalCount, setTotalCount] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] = useState(
    false
  );
  const DEFAULT_PAGE_INFO = {
    hasNextPage: false,
    endCursor: null,
  } as { hasNextPage: boolean; endCursor: null | string };
  const [appInstances, setAppInstances] = useState([] as AppInstance[]);
  const [pageInfo, setPageInfo] = useState(DEFAULT_PAGE_INFO);

  const spaceId = context?.currentSpace?.id;
  const variables: SearchAppInstanceSpecificAppIdQueryVariables = {
    query: props.searchTerms,
    first: FIRST_FETCH_ITEMS,
    spaceId,
    endCursor: null,
    condition: props.condition,
    appId: context.canvasAppId,
    filter: props.filter ?? {},
  };

  const {
    data: searchAppInstancesSpecificAppIdQuery,
    fetchMore,
    refetch,
    loading,
  } = useSearchAppInstanceSpecificAppIdQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !props.searchTerms || props.skip,
  });

  useEffect(() => {
    if (isFirstTimeAlreadyLoaded === false) {
      setIsFirstTimeAlreadyLoaded(
        Boolean(
          searchAppInstancesSpecificAppIdQuery?.searchAppInstanceSpecificAppId
        )
      );
    }

    setAppInstances(
      (searchAppInstancesSpecificAppIdQuery?.searchAppInstanceSpecificAppId
        ?.nodes as AppInstance[]) || ([] as AppInstance[])
    );

    const pageInfo = (searchAppInstancesSpecificAppIdQuery
      ?.searchAppInstanceSpecificAppId?.pageInfo ||
      DEFAULT_PAGE_INFO) as PageInfo;
    setPageInfo(pageInfo);
  }, [
    searchAppInstancesSpecificAppIdQuery?.searchAppInstanceSpecificAppId?.nodes,
  ]);

  const loadMore = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const { endCursor } = pageInfo;

      const variables = {
        first:
          fetchCount > 1 ? MAXIMUM_FETCH_MORE_ITEMS : MINIMUM_FETCH_MORE_ITEMS,
        endCursor,
      };

      const { data: searchAppInstancesSpecificAppIdQuery } = (await fetchMore({
        variables,
      })) as SearchAppInstanceSpecificAppIdQueryResult;

      const newSearchAppInstances: AppInstance[] =
        (searchAppInstancesSpecificAppIdQuery?.searchAppInstanceSpecificAppId
          ?.nodes as AppInstance[]) || ([] as AppInstance[]);
      const newAppInstances = [...appInstances, ...newSearchAppInstances];
      setAppInstances(newAppInstances);
      setPageInfo(
        searchAppInstancesSpecificAppIdQuery?.searchAppInstanceSpecificAppId
          ?.pageInfo as PageInfo
      );
      setIsLoading(false);
      setFetchCount(fetchCount + 1);
    }
  };

  useEffect(() => {
    setTotalCount(
      searchAppInstancesSpecificAppIdQuery?.searchAppInstanceSpecificAppId
        ?.totalCount ?? 0
    );
  }, [
    searchAppInstancesSpecificAppIdQuery?.searchAppInstanceSpecificAppId
      ?.totalCount,
  ]);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(
      Boolean(
        searchAppInstancesSpecificAppIdQuery?.searchAppInstanceSpecificAppId
      )
    );
  }, [searchAppInstancesSpecificAppIdQuery?.searchAppInstanceSpecificAppId]);

  const fechMoreButton = renderFetchMoreButton(
    Number(appInstances.length),
    totalCount,
    isLoading,
    pageInfo.hasNextPage,
    loadMore
  );

  return {
    appInstances,
    hasNextPage: pageInfo.hasNextPage,
    loadMore,
    refetch,
    loading,
    isLoading,
    isFirstTimeAlreadyLoaded,
    totalCount,
    renderFetchMoreButton: fechMoreButton,
  };
}

export function useSearchCanvasInstancesPicker(query: string) {
  const variables: SearchCanvasInstancesProps = {
    searchTerms: query,
    condition: { isTemplate: false },
  };
  return useSearchCanvasAppInstancesSection(variables);
}

export function useSearchSharedCanvasInstances(
  query: string,
  isTemplate: boolean,
  skip?: boolean
) {
  const context = useAppContext();
  const spaceId = context.currentSpace?.id;

  const variables: SearchCanvasInstancesProps = {
    searchTerms: query,
    condition: { isTemplate },
    filter: { spaceId: { notEqualTo: spaceId } },
    skip,
  };
  return useSearchCanvasAppInstancesWithOffset(variables);
}

export function useSearchCanvasInstances(
  query: string,
  isTemplate: boolean,
  skip?: boolean
) {
  const context = useAppContext();
  const spaceId = context.currentSpace?.id;
  const variables: SearchCanvasInstancesProps = {
    searchTerms: query,
    condition: { isTemplate },
    filter: { spaceId: { equalTo: spaceId } },
    skip,
  };
  return useSearchCanvasAppInstancesWithOffset(variables);
}

const useSearchCanvasAppInstancesWithOffset = (
  props: SearchCanvasInstancesProps
): UseSearchCanvasInstances => {
  const context = useAppContext();
  const [totalCount, setTotalCount] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalLoaded, setTotalLoaded] = useState(0);
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] = useState(
    false
  );
  const [appInstances, setAppInstances] = useState([] as AppInstance[]);
  const [hasNextPage, setHasNextPage] = useState(false);

  const spaceId = context?.currentSpace?.id;
  const variables: SearchCanvasQueryVariables = {
    query: props.searchTerms,
    first: FIRST_FETCH_ITEMS,
    offset: 0,
    spaceId,
    condition: props.condition,
    filter: props.filter ?? {},
    appId: context.canvasAppId,
  };

  const { data: canvasCountQuery } = useCanvasCountQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !props.searchTerms,
  });

  const {
    data: searchCanvasQuery,
    fetchMore,
    refetch,
    loading,
  } = useSearchCanvasQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !props.searchTerms || props.skip,
  });

  useEffect(() => {
    if (isFirstTimeAlreadyLoaded === false) {
      setIsFirstTimeAlreadyLoaded(
        Boolean(searchCanvasQuery?.searchAppInstanceSpecificAppId)
      );
    }

    setAppInstances(
      (searchCanvasQuery?.searchAppInstanceSpecificAppId
        ?.nodes as AppInstance[]) || ([] as AppInstance[])
    );

    setTotalLoaded(
      searchCanvasQuery?.searchAppInstanceSpecificAppId?.nodes.length || 0
    );
  }, [searchCanvasQuery?.searchAppInstanceSpecificAppId?.nodes]);

  const loadMore = async () => {
    if (!isLoading) {
      setIsLoading(true);

      const variables = {
        first:
          fetchCount > 1 ? MAXIMUM_FETCH_MORE_ITEMS : MINIMUM_FETCH_MORE_ITEMS,
        offset: totalLoaded,
      };

      const { data: searchCanvasQuery } = (await fetchMore({
        variables,
      })) as SearchCanvasQueryResult;

      const newSearchAppInstances: AppInstance[] =
        (searchCanvasQuery?.searchAppInstanceSpecificAppId
          ?.nodes as AppInstance[]) || ([] as AppInstance[]);
      const newAppInstances = [...appInstances, ...newSearchAppInstances];
      setAppInstances(newAppInstances);
      setTotalLoaded(totalLoaded + newSearchAppInstances.length);
      setIsLoading(false);
      setFetchCount(fetchCount + 1);
    }
  };

  useEffect(() => {
    setTotalCount(
      canvasCountQuery?.searchAppInstanceSpecificAppId?.totalCount ?? 0
    );
    if (totalLoaded < totalCount) {
      setHasNextPage(true);
    } else {
      setHasNextPage(false);
    }
  }, [totalCount, totalLoaded]);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(
      Boolean(searchCanvasQuery?.searchAppInstanceSpecificAppId)
    );
  }, [searchCanvasQuery?.searchAppInstanceSpecificAppId]);

  const fechMoreButton = renderFetchMoreButton(
    Number(appInstances.length),
    totalCount,
    isLoading,
    hasNextPage,
    loadMore
  );

  return {
    appInstances,
    hasNextPage,
    loadMore,
    refetch,
    loading,
    isLoading,
    isFirstTimeAlreadyLoaded,
    totalCount,
    renderFetchMoreButton: fechMoreButton,
  };
};
