import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "../../utils/compose";

import { ssm } from "../../state/session/ssm";
import {
  AvailableLayoutsForSizeQuery,
  AvailableLayoutsForSizeQueryVariables,
  CreateLayoutMutationFn,
  DeleteLayoutMutationFn,
  LayoutBySpaceIdQueryQuery,
  UpdateLayoutMutationFn,
  withAvailableLayoutsForSize,
  withCreateLayout,
  withDeleteLayout,
  withLayoutBySpaceIdQuery,
  withUpdateLayout,
} from "../../types.g";
export interface ApolloProps
  extends RouteComponentProps<any>,
    WithApolloClient<{}> {
  availableLayoutsData: AvailableLayoutsForSizeQuery;
  layoutsData: LayoutBySpaceIdQueryQuery;
  createLayout: CreateLayoutMutationFn;
  updateLayout: UpdateLayoutMutationFn;
  deleteLayout: DeleteLayoutMutationFn;
}

export const withData = compose(
  withRouter,
  withApollo,
  withAvailableLayoutsForSize({
    name: "availableLayoutsData",
    options: (props: AvailableLayoutsForSizeQueryVariables) => {
      const queryVar = {
        height: props.height,
        spaceId: ssm.current.settings.spaceId,
        width: props.width,
      };

      return {
        fetchPolicy: "cache-and-network",
        variables: queryVar,
      };
    },
  }),
  withLayoutBySpaceIdQuery({
    name: "layoutsData",
    options: () => {
      const queryVar = {
        spaceId: ssm.current.settings.spaceId,
      };

      return {
        fetchPolicy: "cache-and-network",
        variables: queryVar,
      };
    },
  }),
  withCreateLayout({
    name: "createLayout",
  }),
  withUpdateLayout({
    name: "updateLayout",
  }),
  withDeleteLayout({
    name: "deleteLayout",
  })
);
