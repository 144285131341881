import { UseStateSearch, useStateSearch } from "../../hooks/useStateSearch";

import { compose } from "../../utils/compose";
import {
  useMediaPickerAppInstances,
  UseAppInstancePicker,
} from "./hooks/useAppInstancePicker";
import {
  UseAppInstallPicker,
  useAppInstallsPicker,
} from "./hooks/useAppInstallsPicker";
import { AppContentPickerProps } from ".";
import { App } from "src/types.g";
import { getDistinctAppsByAppInstalls } from "src/domains/appInstall";

export interface WithAppPicker extends UseStateSearch, UseAppInstallPicker {
  apps: App[];
}

export const withAppPicker = compose(
  (Component) => (props: AppContentPickerProps) => {
    const appInstallsProps = useAppInstallsPicker(props.spaceId);
    const apps = getDistinctAppsByAppInstalls(appInstallsProps.appInstalls);
    const appPickerProps: Omit<WithAppPicker, "appInstalls"> = {
      ...useStateSearch(),
      ...appInstallsProps,
      apps,
    };

    return <Component {...props} {...appPickerProps} />;
  }
);

export interface WithAppInstances extends UseAppInstancePicker {}

export const withAppInstances = compose(
  (Component) => (props: { appId: string }) => {
    const appInstancePickerProps = useMediaPickerAppInstances(props.appId);

    const appInstancesProps: WithAppInstances = {
      ...appInstancePickerProps,
    };

    return <Component {...props} {...appInstancesProps} />;
  }
);
