import {
  CodeEditor,
  LanguageMode,
  Tab,
  ZoneLayout,
} from "@screencloud/screencloud-ui-components";
import { css as prettifyCSS } from "js-beautify";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../../AppContextProvider/AppContext";
import { AppContextType } from "../../../AppContextProvider/type";
import { IsValidJSONString } from "../../../helpers/objectHelper";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import FullScreenModalContent from "../../FullScreenModal/FullScreenModalContent";
import FullScreenModalContentMain from "../../FullScreenModal/FullScreenModalContentMain";
import FullScreenModalContentSidebar from "../../FullScreenModal/FullScreenModalContentSidebar";
import FullScreenModalHeader from "../../FullScreenModal/FullScreenModalHeader";
import FullScreenModalWrapper from "../../FullScreenModal/FullScreenModalWrapper";
import { Styled } from "./styles";
export interface ICustomLayoutConfigProps {
  name: string;
  config: any;
  height: number;
  width: number;
  onSave: (newLayout: CustomLayoutData) => void;
  onClose: () => void;
}

export interface ICustomLayoutConfigState {
  name: string;
  config: any;
  configString: string;
  cssString: string;
  errorMessage: ReactNode | null;
}

export type CustomLayoutData = ICustomLayoutConfigState;

class CustomLayoutConfig extends React.Component<
  ICustomLayoutConfigProps,
  ICustomLayoutConfigState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props) {
    super(props);

    const { config, configString, cssString } = this.getInitialConfig();

    this.state = {
      config,
      configString,
      cssString,
      errorMessage: null,
      name: this.props.name,
    };
  }

  public getInitialConfig = () => {
    const { css, ...config } = { ...this.props.config } as any;
    delete config.name;

    return {
      config,
      configString: JSON.stringify(config, null, 4),
      cssString: prettifyCSS(css.replace(/<style>|<(\/)style>/g, "")),
    };
  };

  public isPristine = () => {
    const { cssString, configString } = this.getInitialConfig();
    return (
      this.state.configString === configString &&
      this.state.cssString === cssString
    );
  };

  public handleLayoutSave = () => {
    const { configString, cssString } = this.state;
    try {
      const filteredCSS = cssString.match(/([#]\s*\S+\s*{[^}]*})+/g);
      if (JSON.parse(configString) && filteredCSS && filteredCSS.length) {
        this.props.onSave({
          ...this.state,
        });
      }
    } catch (e) {
      this.setState({
        errorMessage: (
          <FormattedMessage
            id="common.text.error_invalid_config"
            defaultMessage="Error: Invalid config"
          />
        ),
      });
    }
  };

  public onLayoutNameChange = (e: React.SyntheticEvent<any>) => {
    this.setState({
      name: e.currentTarget.value,
    });
  };

  public handleClose = async () => {
    const { onClose } = this.props;
    if (!this.isPristine()) {
      const { confirm } = await this.context.modal.confirmSaveData();
      if (confirm) {
        this.handleLayoutSave();
      } else {
        this.context.modal.closeFullscreenModal();
      }
    } else {
      onClose();
    }
  };

  public render() {
    const { config, cssString, configString, errorMessage } = this.state;

    let layoutWidth = 0;
    let layoutHeight = 0;
    let errorMessageText = errorMessage;
    try {
      // remove all space before use JSON.parse
      const tempDimension = JSON.parse(
        configString.replace(/(\s+|\r\n|\n|\r)/gm, "")
      );
      layoutWidth = Math.round(tempDimension.width);
      layoutHeight = Math.round(tempDimension.height);
    } catch (e) {
      errorMessageText = (
        <FormattedMessage
          id="common.text.error_invalid_config"
          defaultMessage="Error: Invalid config"
        />
      );
    }

    let newConfig = { ...config };

    if (IsValidJSONString(configString)) {
      newConfig = JSON.parse(configString);
    }

    newConfig.css = `<style>` + cssString + `</style>`;

    return (
      <Styled>
        <FullScreenModalWrapper>
          <FullScreenModalHeader
            handleClose={this.handleClose}
            title={``}
            instanceName={this.state.name}
            isEdit={true}
            handleNameSaved={this.onLayoutNameChange}
            textPlaceholder={``}
            className="layout"
          >
            <PrimaryButton
              className="button-save"
              onClick={this.handleLayoutSave}
            >
              <FormattedMessage
                id="common.text.save_and_close"
                defaultMessage="Save &amp; Close"
              />
            </PrimaryButton>
          </FullScreenModalHeader>

          <FullScreenModalContent>
            <FullScreenModalContentSidebar>
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={[
                  {
                    menuItem: "Config",
                    render: () => (
                      <div className="config-wrapper">
                        <h3>
                          <FormattedMessage
                            defaultMessage="Config data (JSON)"
                            id="channels.layout.config_data"
                          />
                        </h3>
                        <p>
                          <FormattedMessage
                            defaultMessage="Provide the configuration values for each zone here. Make sure the number of zones here match the same amount in the CSS editor."
                            id="channels.layout.config_info"
                          />
                        </p>
                        <CodeEditor
                          value={configString}
                          onChange={(value) =>
                            this.setState({
                              configString: value,
                              errorMessage: "",
                            })
                          }
                          language={LanguageMode.JSON}
                          width="auto"
                        />
                        {errorMessageText !== null && (
                          <span style={{ color: "red" }}>
                            {errorMessageText}
                          </span>
                        )}
                        <h3>
                          <FormattedMessage
                            defaultMessage="is_flexible"
                            id="channels.layout.config_is_flexible"
                          />{" "}
                          :
                        </h3>
                        <p>
                          <FormattedMessage
                            defaultMessage="if set to true, this layout will stretch to fit any screen size or aspect ratio."
                            id="channels.layout.config_flexible_help_message"
                          />
                        </p>
                        <h3>
                          <FormattedMessage
                            defaultMessage="is_scallable"
                            id="channels.layout.config_is_scalable"
                          />{" "}
                          :
                        </h3>
                        <p>
                          <FormattedMessage
                            defaultMessage="if is_flexible is false, is_scallable will keep the layout's aspect ratio regardless of screen size. If set to false, the layout will not adapt to different screen sizes."
                            id="channels.layout.config_scalable_help_message"
                          />
                        </p>
                      </div>
                    ),
                  },
                  {
                    menuItem: "CSS",
                    render: () => (
                      <div className="config-wrapper">
                        <h3>
                          <FormattedMessage
                            defaultMessage="How to?"
                            id="channels.layout.how_to"
                          />
                        </h3>
                        <p>
                          <FormattedMessage
                            defaultMessage="Provide a size and position for each zone. The position is relative to the top left corner of the screen. Values may be pixel or percentage based. If you add or remove zones, please edit the Config as well."
                            id="channels.layout.editor_info"
                          />
                        </p>
                        <div className="layoutCSS-editor">
                          <CodeEditor
                            value={cssString}
                            onBlur={(value) =>
                              this.setState({
                                cssString: value,
                                errorMessage: "",
                              })
                            }
                            onChange={(value) =>
                              this.setState({
                                cssString: value,
                                errorMessage: "",
                              })
                            }
                            language={LanguageMode.CSS}
                            width="auto"
                          />
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </FullScreenModalContentSidebar>

            <FullScreenModalContentMain>
              <div className="custom-layout-wrapper">
                <div className="custom-layout-size">
                  <h3>
                    <FormattedMessage
                      defaultMessage="Preview"
                      id="ui_component.common.label.preview"
                    />
                  </h3>
                  <span>
                    {layoutWidth} × {layoutHeight}
                  </span>
                </div>
                <ZoneLayout
                  config={newConfig}
                  width={layoutWidth}
                  height={layoutHeight}
                  maxWidth={640}
                  maxHeight={640}
                />
              </div>
            </FullScreenModalContentMain>
          </FullScreenModalContent>
        </FullScreenModalWrapper>
      </Styled>
    );
  }
}

export default CustomLayoutConfig;
