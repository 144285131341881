import { UUID } from "@screencloud/uuid";

export enum ExclusiveResller {
  Ridgelogic = "5b6452fe-d48b-4501-8b0d-e375fe1f0685",
}

export const isResellerShowSupport = (orgId: UUID): boolean => {
  return Object.values(ExclusiveResller).includes(orgId as ExclusiveResller);
};

export const getResellerSupportLink = (orgId: UUID): string => {
  if (orgId === ExclusiveResller.Ridgelogic) {
    return "https://ridgelogic.com/knowledgebase/";
  }
  return "https://help.screencloud.com/s/";
};
