import React from "react";
import { useHistory } from "react-router";
import { concatenateQuerystring, getQuerystring } from "src/utils/url";

const TAB_QUERYSTRING = "tab";

export type UseTabIndex = {
  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  onTabChange: (url: string, index: number) => void;
};
type Params = {
  totalItemCount: number;
  totalSharedItemCount: number;
  isContentLoading: boolean;
  isSharedContentLoading: boolean;
};

export const useTabIndex = (params: Params): UseTabIndex => {
  const {
    totalItemCount,
    totalSharedItemCount,
    isContentLoading,
    isSharedContentLoading,
  } = params;

  const history = useHistory();
  const defaultTabActiveIndex = parseInt(
    getQuerystring(TAB_QUERYSTRING) || "0"
  );
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(
    defaultTabActiveIndex
  );

  React.useEffect(() => {
    if (!isContentLoading && !isSharedContentLoading) {
      if (totalItemCount > 0 && totalSharedItemCount > 0) {
        setActiveTabIndex(defaultTabActiveIndex || 0);
      } else if (totalItemCount <= 0 && totalSharedItemCount > 0) {
        setActiveTabIndex(1);
      }
    }
    return () => {
      setActiveTabIndex(0);
    };
  }, [totalItemCount, defaultTabActiveIndex, totalSharedItemCount]);

  const onTabChange = (url: string, index: number) => {
    const newSearch = concatenateQuerystring({
      key: TAB_QUERYSTRING,
      value: `${index}`,
    });
    history.push({
      pathname: url,
      search: newSearch,
    });
    setActiveTabIndex(index);
  };

  return {
    activeTabIndex,
    setActiveTabIndex,
    onTabChange,
  };
};
