import { Theme, ThemeListItemFragment } from "src/types.g";
import { ThemeOption } from ".";
import { ThemeConfig } from "src/pages/Admin/Themes";

export function filterOutTheme(
  targetTheme: ThemeListItemFragment,
  allThemes: ThemeListItemFragment[]
): ThemeListItemFragment[] {
  return allThemes.filter((theme: Theme) => targetTheme.id !== theme.id);
}

export const getSelectedTheme = (
  themes: ThemeOption[],
  selectedThemeId: string | null
): ThemeOption => {
  const selectedTheme = themes.find((theme: ThemeOption) => {
    return theme.id === selectedThemeId;
  });
  return selectedTheme ? selectedTheme : themes[0];
};

export const getThemes = (
  allThemes: ThemeListItemFragment[] | undefined,
  defaultThemeId: string | null
): ThemeOption[] => {
  let themes = allThemes || [];
  const defaultTheme = themes.find((theme: ThemeListItemFragment) => {
    return defaultThemeId === theme.id;
  });
  if (defaultTheme) {
    themes = [defaultTheme, ...filterOutTheme(defaultTheme, themes)];
  }
  return themes.map(
    (theme: ThemeListItemFragment): ThemeOption => {
      return {
        config: theme.config as ThemeConfig,
        id: theme.id,
        name: theme.name,
      };
    }
  );
};
