import { UUID } from "@screencloud/uuid";
import {
  FROM_CHANNEL_PAGE_QUERYSTRING,
  FROM_DETAIL_PAGE_QUERYSTRING,
} from "src/constants/constants";

interface Querystring {
  key: string;
  value: string;
}

export const concatenateQuerystring = (querystring: Querystring): string => {
  const search = new URLSearchParams(window.location.search);
  search.set(querystring.key, querystring.value);
  return search.toString();
};

export const getQuerystring = (key: string): string | null => {
  const search = new URLSearchParams(window.location.search);
  return search.get(key);
};

export const isFromDetailPage = (): boolean => {
  const searchParams = new URLSearchParams(window.location.search);
  return !!searchParams.get(FROM_DETAIL_PAGE_QUERYSTRING) || false;
};

export const getChannelIdFromUrl = (): UUID => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get(FROM_CHANNEL_PAGE_QUERYSTRING)) {
    return searchParams.get(FROM_CHANNEL_PAGE_QUERYSTRING) as UUID;
  } else {
    return "";
  }
};
