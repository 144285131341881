import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import { State } from "./index";

const fontColorHighlight = Theme.color.white;
const controlsBarWidth = "310px";

interface PlayerPreviewStyledProps extends State {
  scale: number;
}

export const Styled = styled.div<PlayerPreviewStyledProps>`
  display: flex;
  height: 100%;
  overflow: auto;

  .sdk-player-container {
    height: 100%;
    width: 100%;
    background: black;
    overflow: hidden;
  }

  > .preview {
    height: 100%;
    overflow: hidden;
    background: var(--color-gray-800);
    display: inline-block;
    width: calc(100% - ${controlsBarWidth});
    position: relative;
    min-height: 800px;
    &.onboarding-player-preview {
      min-height: initial;
    }

    .header-bar {
      align-items: flex-end;
      background: transparent;
      display: flex;
      height: 60px;
      justify-content: space-between;
      margin: 0 auto;
      position: relative;
      width: calc(90% + 16px);

      .title {
        display: inline-flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin: 0;
        width: calc(100% - 360px);

        span {
          color: ${Theme.color.grey};
          font-size: 0.875em;
        }
        h2 {
          color: var(--color-white-100);
          font-size: 1.25em;
          margin: 0;
        }
      }

      .fullscreen-button,
      .exit-fullscreen-button {
        border: 1px solid var(--color-invert-border, rgba(255, 255, 255, 0.20));
        background: var(--color-invert-background, rgba(255, 255, 255, 0.00));

        span {
          color: var(--color-white-100);
        }

        i {
          background-color: var(--color-white-100);
        }

        :hover {
          border: 1px solid var(--color-invert-border-active, rgba(255, 255, 255, 0.40));
          background: var(--color-invert-background-active, rgba(255, 255, 255, 0.05));
        }
      }
      .right-wrapper {
        display: flex;
        margin-left: auto;

        .resolutions-dropdown-container {
          margin-left: auto;
          position: relative;
        }

        .resolutions-dropdown {
          border: 1px solid var(--color-invert-border, rgba(255, 255, 255, 0.20));
          background: var(--color-invert-background, rgba(255, 255, 255, 0.00));
          width: 180px;
          justify-content: space-between;
          margin-right: auto;
          height: var(--size-lg, 44px);
          z-index: 1;

          .dropdown.icon {
            color: var(--color-white-100);
          }
          :hover,
          :focus,
          &.active,
          &.active:focus,
          &.active:hover {
            border: 1px solid var(--color-invert-border-active, rgba(255, 255, 255, 0.40));
            background: var(--color-invert-background-active, rgba(255, 255, 255, 0.05));
          }
          > .text,
          &.active > .text {
            position: relative;
            color: var(--color-white-100);
          }

          + .selected-device-resolution {
            color: var(--color-white-100);
            font-size: 0.75em;
            left: 12px;
            position: absolute;
            top: 20px;
            z-index: 0;
          }

          .menu {
            .item {
              min-height: auto;
              align-items: center;

              .device-info {
                display: flex;
                flex-direction: column;
                flex: 1 0 0;

                .device-resolution {
                  font-size: 12px;
                  line-height: 16px;
                  color: var(--color-gray-400);
                }
              }
            }
          }
        }
      }
    }
    .available-space {
      flex-direction: column;
      align-items: center;
      display: flex;
      height: calc(100% - 120px);
      margin: 0 auto;
      overflow: visible;
      justify-content: center;
      overflow: hidden;

      > .player-container-outer-wrapper {
        display: flex;
        position: relative;
        height: ${(props: PlayerPreviewStyledProps) =>
          props.scale < 1 && !props.showPlayerOnly
            ? `${Number(props.previewResolutionHeight) * props.scale}px`
            : "100%"};
        width: ${(props: PlayerPreviewStyledProps) =>
          props.scale < 1 && !props.showPlayerOnly
            ? `${Number(props.previewResolutionWidth) * props.scale}px`
            : "100%"};

        :before {
          display: block;
          background: var(--color-gray-950);
          content: '';
          width: calc(100% + 16px);
          height: calc(100% + 16px);
          position: absolute;
          left: -8px;
          top: -8px;
          border: 8px solid ${Theme.color.black};
          border-radius: 2px;
        }

        > .player-container-wrapper {
          width: ${(props: PlayerPreviewStyledProps) => props.playerWidth};
          margin: 0 auto;
          display: table;
          height: 100%;

          > .inner-wrapper {
            display: table-cell;
            vertical-align: middle;

            > .player-container {
              position: relative;
              margin: 0;
              height:${(props: PlayerPreviewStyledProps) =>
                props.previewResolutionHeight}px;
              width:${(props: PlayerPreviewStyledProps) =>
                props.previewResolutionWidth}px;

              > .wrapper {
                height:${(props: PlayerPreviewStyledProps) =>
                  props.previewResolutionHeight}px;
                width:${(props: PlayerPreviewStyledProps) =>
                  props.previewResolutionWidth}px;
                transform: scale(${(props: PlayerPreviewStyledProps) =>
                  props.scale});
                transform-origin: 0 0;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    > .playback-bar {
      align-items: center;
      display: flex;
      height: 100px;
      width: 100%;

      > .playback-buttons {
        display: inline-block;
        position: absolute;
        left: 40px;
        width: calc(100% - 80px);
        display: flex;
        justify-content: space-between;

        > .button {
          > i {
            background-color: ${fontColorHighlight};
          }
          &:active, &:focus {
            > i {
              background-color: ${fontColorHighlight};
            }
          }
          &:not(:first-of-type) {
            margin-left: 15px;
          }
          &.round-border {
            border: 1px solid white;
            border-radius: 50%;
            &:active, &:focus, &:hover {
              border-color: white;
            }
          }
        }
      }
      > .info {
        display: inline-block;
        width: 100%;
        text-align: center;

        > .content-type-label {
          display: inline-block;
          ${Theme.default.borderRadius};
          background-color: ${Theme.color.nearWhite};
          color: black;
          padding-left: 6px;
          padding-right: 6px;
          font-size: .7em;
          margin-bottom: 4px;
        }
        > .content-title {
          display: inline-block;
          color: ${fontColorHighlight};
          font-weight: bold;
          white-space: nowrap;
          word-break: keep-all;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        > .playback-time {
          display: inline-block;
          color: ${Theme.color.midGrey};
          margin-top: -4px;
          vertical-align: top;
        }
      }
      > .time {
        display: inline-block;
        color: var(--color-white-100);
        vertical-align: top;
        position: absolute;
        right: 60px;

        > i {
          background-color: var(--color-white-100);
        }

        > .playback-time {
          display: inline-block;
          margin-left: 20px;
          vertical-align: text-bottom;
        }
      }
    }
  }
  > .controls {
    background: var(--color-gray-900);;
    display: inline-block;
    height: 100%;
    min-height: 800px;
    position: relative;
    width: ${controlsBarWidth};
    overflow: auto;

    .btn-hide-controls {
      cursor: pointer;
      width: 25px;
      left: -25px;
      position: absolute;
      background: var(--color-gray-950);
      margin: 5px 0;
      height: 60px;
      border-radius: 4px 0 0 4px;
      box-shadow: inset -1px 0px 2px rgba(0,0,0,.5);

      i {
        background-color: ${Theme.color.darkGrey};
        width: 8px;
      }

      :hover {
        background: ${Theme.color.black};

        i {
          background-color: ${Theme.color.grey};
        }
      }
    }

    h3 {
      border-bottom: 1px solid ${Theme.color.darkGrey};
      color: ${Theme.color.grey};
      padding: 10px 0 5px;
      margin: 0 20px;
      font-size: 0.875em;
    }

    .edit-content {
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: relative;

      > button {
        margin-top: 20px;
      }

      > span {
        user-select: none;
      }
    }

    .zone-selector {
      display: flex;
      align-items: center;
      padding: 16px 20px 0;

      > .ui.dropdown.selection {
        border: 1px solid var(--color-invert-border, rgba(255, 255, 255, 0.20));
        background: var(--color-invert-background, rgba(255, 255, 255, 0.00));

        > .text {
          color: var(--color-white-100);
        }

        .icon {
          color: var(--color-white-100);
        }

        &:hover,
        &.active.visible {
          border: 1px solid var(--color-invert-border-active, rgba(255, 255, 255, 0.40));
          background: var(--color-invert-background-active, rgba(255, 255, 255, 0.05));
        }
      }

      + .list {
        height: calc(100% - 60px);
      }
    }
    > .list {
      color: ${Theme.color.lightGrey};
      height: auto;
      overflow: auto;
      padding-bottom: 60px;
      padding-top: 10px;

      div[class^="report-"] {
        border-top: 1px solid ${Theme.color.darkGrey};
        height: 60px;
        display: inline-flex;
        flex-direction: row;
        width: 100%;
        padding: 10px 0 10px 20px;
        align-items: center;
        user-select: none;
        cursor: pointer;
        position: relative;

        > * {
          z-index: 1;
        }

        .item-bg {
          width: 0;
          z-index: 0;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }

        :first-child {
          border-top: none;
        }

        &.disabled {
          opacity: 0.2;
        }

        &.playing {
          background: rgba(0,0,0,.2);

          > .icon-block,
          .playlist-icon {
            position: relative;
          }

          > .item-bg {
            background: ${Theme.color.black};
            width: 100%;
          }

          :after {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            display: block;
            content: '';
            position: absolute;
            background: var(--color-white-100);
            left: 7px;
            top: 27px;
          }
        }

        > .icon-block {
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: ${Theme.color.black};
          ${Theme.default.borderRadius};
          position: relative;
        }

        .content {
          margin: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 60px);
          color: var(--color-white-100);
          line-height: 40px;
        }
      }

      .content-details-container {
        width: 100%;
        display: grid;

        &.with-rules {
          grid-template-columns: 3fr 2fr;
        }

        .main-content-container {
          display: flex;
          width: 100%;
          align-items: center;
        }

        .text-ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon-block {
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: ${Theme.color.black};
          ${Theme.default.borderRadius};
          position: relative;
        }
      }

      .report-list-item {
        i {
          position: absolute;
        }
        img {
          max-width: 40px;
          max-height: 40px;
        }
      }

      div.report-playlist-item {
        height: auto;

        &.playing {
          :before {
            display: none;
          }
        }

        .playlist-accordion {
          width: 100%;

          .title {
            position: relative;

            .dropdown.icon {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              margin: 0;
              right: 0;

              ::before {
                color: ${Theme.color.midGrey};
              }
            }
            &.active:only-child {
              :after {
                content: "This playlist is empty";
                font-size: 0.875em;
                display: inline;
                position: relative;
                z-index: 10;
                font-style: italic;
                color: ${Theme.color.midGrey};
                padding: 0 0 0 50px;
                line-height: 1.3125;
              }

              .dropdown.icon {
                top: calc(50% - 24px);
              }
            }
          }

          .playlist-header {
            display: flex;

            .playlist-icon {
              width: 40px;
              height: 40px;
              background: ${Theme.color.darkGrey};
              ${Theme.default.borderRadius};
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }

            .playing-icon {
              background: ${Theme.color.black};
            }
          }

          > .content {
            padding: 0;
            margin: 0 10px 0 40px;

            .playlist-items {
              height: 100%;
              display: flex;
              flex-direction: column;

              div.report-list-item {
                background-color: transparent;
                padding: 5px 0;
                height: 50px;

                .content {
                  font-size: 0.875em;
                  line-height: 1.357;
                }
              }
            }
          }
        }
      }
    }
  }

  &.hide-control {
    > .preview {
      width: 100%;

      > .playback-bar {
        width: 100%
      }
    }

    > .controls {
      width: 0;

      .zone-selector,
      h3,
      .list {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    > .preview {
      width: 100%;
      min-height: auto;
      height: 100%;

      .playback-bar {
        width: 100%;
      }
    }

    > .controls {
        display: none;
      }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    > .preview {
      .available-space {
        height: calc(100% - 234px);
      }
      .playback-bar {
        .time {
          right: 20px;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    > .preview {
      .header-bar {
        .fullscreen-button {
          width: calc(50% - 5px);
          padding: 0;
        }

        .right-wrapper {
          width: calc(50% - 5px);

          .resolutions-dropdown-container {
            width: 100%;

          .resolutions-dropdown {
            width: 100%;
          }
        }

        .action-buttons {
          position: unset;
        }
      }

      .playback-bar {
        > .content-title {
          > .info {
            max-width: 50%;
          }
        }
      }
    }
  }
`;
