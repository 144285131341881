import { useState } from "react";

interface Props<T> {
  allItems: T[];
  allItemTotalCount: number;
  setSelectedItems: (items: T[]) => void;
}

export function useSelectAll<T>({
  allItems,
  allItemTotalCount,
  setSelectedItems,
}: Props<T>) {
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSelectAllIndeterminate, setIsSelectAllIndeterminate] = useState(
    false
  );

  const setAllItemsAsSelected = () => {
    setSelectedItems([...allItems]);
  };

  const selectAll = () => {
    setAllItemsAsSelected();
    setIsSelectAll(true);
    setIsSelectAllIndeterminate(false);
  };

  const clearSelectedItems = () => {
    setSelectedItems([]);
    setIsSelectAll(false);
    setIsSelectAllIndeterminate(false);
  };

  const setAsInderterminate = (items: T[]) => {
    setIsSelectAll(false);
    setIsSelectAllIndeterminate(true);
    setSelectedItems(items);
  };

  const updateSelectedItems = (items: T[]) => {
    const isNoItemSelected = items.length === 0;
    const isAllItemSelected = items.length === allItemTotalCount;
    if (isNoItemSelected) {
      clearSelectedItems();
    } else if (isAllItemSelected) {
      selectAll();
    } else {
      setAsInderterminate(items);
    }
  };

  const toggleSelectedAll = () => {
    if (isSelectAllIndeterminate || isSelectAll) {
      clearSelectedItems();
    } else {
      selectAll();
    }
  };

  return {
    isSelectAll,
    isSelectAllIndeterminate,
    updateSelectedItems,
    clearSelectedItems,
    setAllItemsAsSelected,
    selectAll,
    toggleSelectedAll,
  };
}
