import * as React from "react";

export interface Props {
  children: React.ReactNode;
}

const FullScreenModalContentSidebar = (props: Props) => (
  <div className="panel-config">{props.children}</div>
);

export default FullScreenModalContentSidebar;
