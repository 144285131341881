import React from "react";
import { Link } from "react-router-dom";

import { OrgGlyph } from "../OrgGlyph/OrgGlyph";

import { Styled } from "./styles";

interface LogoBannerProps {
  organizationName: string;
  glyph?: string;
  height?: number;
  glyphBorderRadius?: number;
  className?: string;
  supportResponsive?: boolean;
  url?: string;
}

export const LogoBanner: React.FC<LogoBannerProps> = ({
  glyph,
  organizationName,
  height,
  glyphBorderRadius,
  className,
  supportResponsive,
  url,
}) => {
  const component = (
    <div className="logo-banner">
      <OrgGlyph
        glyph={glyph}
        organizationName={organizationName}
        height={height}
        width={height}
        borderRadius={glyphBorderRadius}
      />
      <span className="banner-organization-name">{organizationName}</span>
    </div>
  );

  return (
    <Styled className={className} supportResponsive={supportResponsive ?? true}>
      {url ? <Link to={url}>{component}</Link> : component}
    </Styled>
  );
};
