import { Loader } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";

const Init = () => {
  return (
    <div className="content">
      <h2>
        <FormattedMessage
          id="ui_component.site.new_site_modal.init.title"
          defaultMessage="Checking your settings"
        />
      </h2>
      <p>
        <FormattedMessage
          id="ui_component.site.new_site_modal.init.subtitle"
          defaultMessage="Please wait while we check your browser and extension."
        />
      </p>
      <p>
        <Loader inline size="large" />
      </p>
    </div>
  );
};

export default Init;
