import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;

  .preview-title {
    height: 70px;
    border-bottom: solid 1px ${Theme.color.lightGrey};
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;

    h1 {
      font-size: 1.25em;
      max-width: calc(100% - 50px);
      height: 26px;
      display: flex;
      .edit-false {
        &:hover {
          border: none !important;
        }
      }

      &.inline-input {
        width: 100%;
      }

      i {
        background-color: ${Theme.color.grey60};
        cursor: pointer;
        opacity: 1;
        align-self: center;
        width: 20px;
        -webkit-mask-size: 20px;
        position: relative;
        z-index: 1;
        height: 20px;
        flex-shrink: 0;
        margin-left: 5px;
      }

      :hover {
        i {
          background: ${Theme.color.grey100};
        }
      }

      .clickable {
        cursor: pointer;
      }

      .title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      :hover {
        i {
          opacity: 1;
        }
      }
    }
  }

  .media-preview {
    background: ${Theme.color.nearWhite};
    border-radius: ${Theme.default.borderRadiusValue} 0 0
      ${Theme.default.borderRadiusValue};
    height: calc(100% - 70px);
    padding: 0 40px;
    position: relative;
    transition: all 0.2s cubic-bezier(0.7, 0.1, 0.3, 1.2);
    width: calc(100% - 330px);

    .preview-spinner {
      left: 50%;
      position: absolute;
      top: 50%;
    }

    .content-preview {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;

      .media-content {
        width: 100%;
        height: 100%;
        max-height: 74%;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.3s;
      }

      .document img {
        object-fit: contain;
      }

      .video-preview {
        width: 100%;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .media-cast {
      position: absolute;
      bottom: 20px;
    }

    .media-status {
      display: flex;
      height: 65px;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      padding: 0 20px;

      > * {
        &.casting {
          i {
            background-color: ${Theme.color.base};
          }
          span {
            color: ${Theme.color.base};
          }
        }
      }
    }
  }

  .media-info {
    transition: all 0.2s cubic-bezier(0.7, 0.1, 0.3, 1.2);
    width: 330px;
    background: ${Theme.color.white};
    border-left: 1px solid ${Theme.color.borderSection};
    border-radius: 0 6px 6px 0;
    position: relative;
    max-height: 100%;

    .icon-button-tab {
      margin-bottom: 10px;
      padding: 0 10px;

      .close-modal {
        padding: 20px;
        position: static;
      }
    }

    .media-info-title {
      height: 60px;
      margin-bottom: 10px;
      overflow: hidden;

      h1 {
        padding: 0 20px;
        font-size: 1.5em;
        word-break: break-word;

        .title {
          display: inline-block;
          width: 100%;
        }
      }

      .edit-true {
        textarea {
          min-height: 60px;
        }
      }
    }

    .media-tabs {
      transition: all 0.2s cubic-bezier(0.7, 0.1, 0.3, 1.2);
      height: 100%;

      .ui.secondary.pointing.menu {
        margin-bottom: 0;
      }

      .ui.tab {
        height: calc(100% - 50px);
        overflow: auto;

        .wrapper {
          .table {
            table-layout: fixed;
            background-color: ${Theme.color.nearWhite};
            border-color: ${Theme.color.silver};
            padding: 20px 20px 10px;
            margin: 0;

            tr {
              td {
                border-top: none;
                padding: 0 0 10px;
                font-size: 0.875em;
                color: ${Theme.color.base};
                font-weight: bold;
                width: 50% !important;
                line-height: 1.3;
                vertical-align: baseline;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              td:first-child {
                color: rgba(31, 32, 39, 0.5);
                font-weight: normal;
                text-align: right;
                padding-right: 10px;
              }
            }
          }

          h3 {
            font-size: 1em;
            font-weight: normal;
            position: relative;
            margin: 0;
            display: inline-flex;
            align-items: flex-end;
            flex-direction: row;
            justify-content: space-between;
          }

          .tags {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-xxs);
            .ui.dropdown {
              .label:last-child {
                margin-bottom: 7px;
              }
            }
          }

          .publication {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-sm);

            .head {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;

              .text {
                align-items: center;
                display: inline-flex;
                margin: 0 20px 0 0;
                width: calc(100% - 80px);

                h3 {
                  margin: 0;
                  display: inline-block;

                  + i {
                    display: inline-block;
                    vertical-align: middle;
                    height: 12px;
                    background-color: ${Theme.color.darkGrey};
                    padding: 4px 0;
                    margin-left: 5px;
                    width: 12px;
                  }
                }
              }
            }

            .expand {
              background: ${Theme.color.nearWhite};
              border: 1px solid ${Theme.color.silver};
              ${Theme.default.borderRadius};
              gap: var(--spacing-sm);
              padding: var(--spacing-md);
              display: flex;
              flex-direction: column;

              > div {
                gap: var(--spacing-xs);
                display: flex;
                flex-direction: column;

                > div {
                  gap: var(--spacing-sm);
                }

                .date-picker,
                .time-input {
                  margin: 0;
                  width: calc(50% - 4px);
                }
                .desc {
                  color: ${Theme.color.darkGrey};
                  font-size: 0.875em;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .media-options {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      padding: 10px 20px;
      bottom: 0;
      border-top: 1px solid ${Theme.color.borderInput};

      .button {
        &.link {
          width: 45px;
          height: 45px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          :hover {
            background-color: ${Theme.color.silver};
            ${Theme.default.borderRadius};
            cursor: pointer;
            i {
              background-color: ${Theme.color.base};
            }
          }
          i {
            background-color: ${Theme.color.darkGrey};
          }
        }
      }
    }
  }

  .cast-on {
    display: none;
    opacity: 0;
    height: 0;
  }

  &.casting {
    .media-preview {
      height: 140px;
      width: 140px;
      padding: 20px;

      .media-content {
        margin: 0;
      }
    }

    .media-info {
      width: calc(100% - 140px);
      height: 140px;
      display: inline-flex;
      align-items: center;

      .media-info-title {
        padding-bottom: 0;
        width: 100%;

        h1 {
          padding: 0 60px 0 40px;

          .edit-true,
          .edit-false {
            display: inline-block;
            width: 100%;
          }
        }
      }

      .media-tabs,
      .media-options {
        display: none;
      }
    }

    .cast-on {
      display: block;
      width: 100%;
      height: calc(100% - 140px);
      opacity: 1;
      border-top: 1px solid ${Theme.color.lightGrey};

      .head {
        background: rgba(31, 32, 39, 0.8);
        color: ${Theme.color.white};
        height: 50px;
        font-weight: bold;
        text-align: center;
        font-size: 1.125em;
        line-height: 50px;
      }
    }
  }

  .delete-confirm {
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: ${Theme.color.white};
    transition: all 0.2s ease;

    .delete-confirm-body {
      display: none;
    }
  }

  &.deleting {
    .media-preview {
      .media-content {
        display: none;
      }

      .media-cast {
        display: none;
      }
    }

    .media-info {
      display: none;
    }

    .delete-confirm {
      display: flex;
      width: 100%;
      height: 100%;
      opacity: 1;
      border-top: 1px solid ${Theme.color.lightGrey};
      bottom: 0;
      left: 0;

      .delete-confirm-body {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        .confirm-message {
          white-space: pre-line;
          font-size: 1.25em;

          strong {
            &::before {
              content: " ";
              display: block;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .media-info {
      .media-info-title {
        height: unset;
        h1 {
          font-size: 1.25em;
        }
      }

      .media-tabs {
        height: calc(100% - 152px);

        .wrapper {
          display: flex;
          flex-flow: column;

          ::after {
            display: block;
            clear: both;
            content: "";
          }

          .ui.menu {
            background: ${Theme.color.white};
            position: absolute;
            top: 0;
            width: 100%;
          }

          .ui.tab {
            height: calc(100% - 40px);
            overflow-y: scroll;

            .wrapper {
              .ui.table {
                width: 100%;
                margin-right: 20px;
                margin-bottom: 0;

                tbody {
                  display: table-row-group !important;

                  tr {
                    padding: 0;
                    box-shadow: none !important;

                    td:first-child {
                      color: rgba(31, 32, 39, 0.5);
                      font-weight: normal;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.casting {
      .media-preview {
        height: 100px;
        width: 100px;
        padding: 10px;

        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: inline-block;
        }
      }

      .media-info {
        width: calc(100% - 100px);
        height: 100px;

        .icon-button-tab {
          margin-bottom: 0;
        }
      }

      .cast-on {
        height: calc(100% - 100px);
      }
    }

    &.casting {
      .media-info {
        .media-info-title {
          h1 {
            max-height: 75px;
            overflow: hidden;
            padding-left: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .media-preview {
      width: 100%;
      height: 320px;
      padding: 10px 40px;
    }

    .preview-title {
      h1 {
        max-width: calc(100% - 60px);
        width: 100%;
      }
    }

    .media-info {
      height: calc(100% - 392px);
      width: 100%;
      border-left: none;
      border-top: 1px solid ${Theme.color.lightGrey};

      .media-tabs {
        height: calc(100% - 65px);

        .ui.tab {
          .wrapper {
            margin: 0 auto;
            max-width: 480px;

            .table {
              display: flex;

              tbody {
                width: 100%;
              }
              tr {
                display: table-row;
                box-shadow: none;
                width: 100%;
                padding: 0;

                td {
                  display: table-cell;
                  padding: 0 0 10px;
                }
              }
            }
          }
        }
      }

      .media-options {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .media-info {
      height: calc(100vh - 392px);

      .media-tabs {
        .ui.tab {
          .wrapper {
            flex-flow: column;

            .table {
              width: 100% !important;
            }

            .publication {
              .expand {
                > div {
                  width: 100%;
                  margin-right: 0;

                  :nth-child(odd) {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }

      .media-options {
        padding: 10px;
      }
    }
  }

  @supports (padding-bottom: 10px) {
    .media-info {
      .media-options {
        --safe-area-inset-bottom: env(safe-area-inset-bottom);
        height: calc(65px + (var(--safe-area-inset-bottom)));
        padding-bottom: max(10px, env(safe-area-inset-bottom));
      }
    }
  }
`;

export const ScreensCastingStyled = styled.div`
  padding: 2px 5px;

  .screen-item {
    height: 60px;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid ${Theme.color.darkGreyHover};
    width: 300px;

    .screen-alpha {
      width: calc(100% - 40px);

      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }

      span {
        color: ${Theme.color.grey};
      }
    }

    .screen-state {
      width: 40px;
      align-items: center;
      justify-content: center;
      display: inline-flex;

      > span.active {
        background-color: ${Theme.color.green};
      }
      > span {
        display: inline-flex;
        background-color: ${Theme.color.red};
        width: 15px;
        height: 15px;
        border-radius: 100%;
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;

        span {
          display: none;
        }
      }
    }

    :last-child {
      border-bottom: none;
    }
  }
`;
