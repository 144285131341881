import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "src/helpers/whiteLabel";
import { Playlist } from "src/types.g";
import { StyledModalContent } from "src/components/AvailabilityModal/styles";
import { StyledMediaPickerContainer } from "src/components/MediaPicker/styles";
import PlaylistPickerComponent from "src/components/PlaylistPicker";

export interface AddToPlaylistModalProps {
  onAddToPlaylistClick: (playlists: Playlist[]) => void;
}

const AddToPlaylistModal = (props: AddToPlaylistModalProps) => {
  const { onAddToPlaylistClick } = props;
  const [selectedPlaylists, setSelectedPlaylists] = useState<Playlist[]>([]);
  return (
    <StyledModalContent>
      <StyledMediaPickerContainer>
        <PlaylistPickerComponent
          multiple
          allowSelectAll
          excludeShare
          callback={(_ids, playlists) => {
            setSelectedPlaylists(playlists);
          }}
        />
      </StyledMediaPickerContainer>
      <div className="md-footer">
        <PrimaryButton
          disabled={selectedPlaylists.length === 0}
          onClick={() => onAddToPlaylistClick(selectedPlaylists)}
        >
          <FormattedMessage
            id="playlists.picker.add_to_playlists"
            defaultMessage="Add to Playlists"
          />
        </PrimaryButton>
      </div>
    </StyledModalContent>
  );
};

export default AddToPlaylistModal;
