import { Styled } from "./styles";

interface OrgGlyphBaseProps {
  height?: number;
  width?: number;
  borderRadius?: number;
}
interface WithGlyphProps extends OrgGlyphBaseProps {
  glyph: string;
}
interface WithOrgNameProps extends OrgGlyphBaseProps {
  organizationName: string;

  fontSize?: string;
  fontWeight?: number;
}
type OrgGlyphProps = WithGlyphProps | WithOrgNameProps;

export const OrgGlyph = (props: OrgGlyphProps) => {
  const { height, width, borderRadius } = props;

  const renderLogo = () => {
    if ("glyph" in props && props.glyph) {
      return <img src={props.glyph} />;
    } else if ("organizationName" in props && props.organizationName) {
      const { fontSize, fontWeight } = props;
      return (
        <span
          style={{
            fontSize: fontSize ?? "20px",
            fontWeight: fontWeight ?? 900,
          }}
        >
          {props.organizationName[0] ?? ""}
        </span>
      );
    }
    return <></>;
  };

  const removeGlyphBackgroundColor: boolean = "glyph" in props && !!props.glyph;

  return (
    <Styled
      borderRadius={borderRadius}
      width={width}
      height={height}
      removeBackgroundColor={removeGlyphBackgroundColor}
    >
      <div className="org-glyph">{renderLogo()}</div>
    </Styled>
  );
};
