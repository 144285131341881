import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

type Props = {
  primaryColor?: string;
  secondaryColor?: string;
};

export const Styled = styled.div<Props>`
  .theme-header {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--spacing-lg, 16px);

    .text-container {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs, 6px);
      width: 80%;
      .title {
        color: var(--color-foreground, #404145);
        font-size: var(--font-size-md, 16px);
        font-weight: 400;
        line-height: var(--font-line-height-md, 24px);
      }
      .description {
        color: var(--color-gray-400, #7d7e84);
        font-size: var(--font-size-sm, 14px);
        font-style: normal;
        font-weight: 400;
        line-height: var(--font-line-height-sm, 20px);
      }
    }
    .toggle {
      width: 20%;
    }
    .info {
      background-color: var(--color-grey100);
      width: 16px;
      height: 16px;
    }
  }

  .theme-cover {
    display: flex;
    padding: var(--spacing-lg, 16px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-lg, 16px);
    align-self: stretch;
    width: 100%;
    height: 204px;

    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--color-gray-100, #e2e3e5);
    background: var(--color-gray-50, #f6f6f7);
    ${Theme.default.borderRadius};
    font-size: 16px;
    font-weight: bold;
  }

  .theme-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xs, 6px);
    width: 100%;
  }

  .theme-title {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    margin-top: 10px p {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .button {
    width: 100%;
  }

  .no-themes {
    background: ${Theme.color.warning};
    color: ${Theme.color.white};
    font-size: 16px;
    white-space: nowrap;
    height: 73px;
    margin-top: 10px;
    ${Theme.default.borderRadius};
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;

    .no-theme-desc {
      font-size: 14px;
      font-weight: normal;
    }

    .theme-link {
      color: ${Theme.color.white};
      text-decoration: underline;
    }

    i {
      height: 50%;
      width: 16px;
      margin: 15px;
      background-color: ${Theme.color.white};
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
