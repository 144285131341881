import { Button } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: var(--spacing-xl, 24px);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-lg, 16px);
  align-self: stretch;
  .button.dashboard-button {
    margin: 0;
  }
`;

export const DashboardContainer = styled.div`
  display: flex;
  min-width: 320px;
  max-width: 560px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-lg, 16px);
  align-self: stretch;
`;

export const Title = styled.div`
  color: var(--color-foreground, #404145);
  text-align: center;

  font-size: var(--font-size-lg, 20px);
  font-style: normal;
  font-weight: 900;
  line-height: var(--font-line-height-lg, 28px);
`;

export const Description = styled.div`
  color: var(--color-subtle, #7d7e84);
  text-align: center;

  font-size: var(--font-size-sm, 14px);
  font-weight: 400;
  line-height: var(--font-line-height-sm, 20px);
`;
export const HelpButton = styled(Button)`
  &.button.help-button {
    color: var(--color-yellow-800, #927400);
    margin: 0;
  }
`;
