import { appConfig } from "src/appConfig";
import { getUserInfo } from "src/domains/user/user";
import { Maybe, User } from "src/types.g";
import { DEMO_REQUETST_URL } from "src/pages/Sites/LandingPage/constants";

export const getPrefillDemoRequestUrl = (currentUser: Maybe<User>): string => {
  if (currentUser) {
    const { givenName, familyName, email } = currentUser;
    const userInfo = getUserInfo(currentUser);
    const region = appConfig.regions.current.name;
    let url = `${DEMO_REQUETST_URL}?firstname=${givenName}&lastname=${familyName}&email=${email}&region=${region}`;
    if (userInfo?.job?.title) {
      url += `&jobtitle=${userInfo.job.title}`;
    }
    if (userInfo?.job?.jobFunction) {
      url += `&job_function=${userInfo.job.jobFunction}`;
    }
    return url;
  }
  return "";
};
