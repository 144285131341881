import React, { FC } from "react";
import { Button, TagSelector } from "@screencloud/screencloud-ui-components";
import {
  Content,
  SubTitle,
  TagSelectorModalContainer,
  TagSelectorModalContent,
  TagSelectorModalFooter,
} from "./styles";
import { isEqual } from "lodash";
import { FormattedMessage } from "react-intl";

export interface TagSelectorModalProps {
  placeholder: string;
  saveText: string;
  availableTags: string[];
  tags: string[];
  onSaveTags: (tags: string[]) => void;
  limit?: number;
  isBulkUpdate?: boolean;
  message?: string;
  disabled?: boolean;
  subTitle?: string;
}

const TagSelectorModal: FC<TagSelectorModalProps> = (props) => {
  const [selectedTags, setSelectedTags] = React.useState<string[]>(props.tags);
  const onTagChanged = (values: string[]) => {
    setSelectedTags(values);
  };

  const onSave = () => {
    props.onSaveTags(selectedTags);
  };

  return (
    <TagSelectorModalContainer>
      <TagSelectorModalContent>
        <TagSelector
          availableTags={props.availableTags}
          placeholder={props.placeholder}
          tags={props.tags}
          onTagChanged={onTagChanged}
          limit={props.limit}
          disabled={props.disabled}
        />

        <SubTitle>{props.subTitle}</SubTitle>
        {props.isBulkUpdate && (
          <SubTitle
            data-testid="bulk-update-warning"
            data-cy="bulk-update-warning"
          >
            <FormattedMessage
              id="tag.existing_will_be_replace"
              defaultMessage="{subTitle} Any existing tags will be replaced."
              values={{ subTitle: props.subTitle }}
            />
          </SubTitle>
        )}
        {props.message ? <Content>{props.message}</Content> : null}
      </TagSelectorModalContent>
      <TagSelectorModalFooter>
        <Button
          primary
          onClick={onSave}
          disabled={props.disabled || isEqual(selectedTags, props.tags)}
        >
          {props.saveText}
        </Button>
      </TagSelectorModalFooter>
    </TagSelectorModalContainer>
  );
};

export default TagSelectorModal;
