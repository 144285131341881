import { debounce } from "lodash";
import { useState, useCallback, useEffect } from "react";
import { DEBOUNCE_TIMEOUT_MS } from "src/constants/constants";

export function useStateInnerWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const updateDimensions = useCallback(
    debounce(() => {
      setWidth(window.innerWidth);
    }, DEBOUNCE_TIMEOUT_MS),
    []
  );

  return width;
}
