const DASHBOARDS_ONBOARDING_REMINDER_KEY = "doNotShowDashboardsOnboardingModal";

export const getOnboardingRemindSetting = () =>
  localStorage.getItem(DASHBOARDS_ONBOARDING_REMINDER_KEY) === "true";

export const setOnboardingRemindSetting = () => {
  localStorage.setItem(DASHBOARDS_ONBOARDING_REMINDER_KEY, "true");
};

export const clearOnboardingRemindSetting = () => {
  localStorage.removeItem(DASHBOARDS_ONBOARDING_REMINDER_KEY);
};
