import { CardGroup, ModalSize } from "@screencloud/screencloud-ui-components";
import { CastedScreenInfoActions } from "../../components/CastedScreenInfo";
import { SiteCard } from "../../components/SiteCard";
import SiteListHeader, {
  SiteListHeaderActions,
} from "../../components/SiteListHeader";
import SiteListItem, {
  SiteListItemActions,
} from "../../components/SiteListItem";
import { generateImgixThumbnail } from "../../helpers/mediaHelper";
import DashboardsOnboardingModal from "../../pages/Sites/Onboarding";
import { getOnboardingRemindSetting } from "../../pages/Sites/Onboarding/utils";
import { File, Site, SiteFragment, SiteType } from "../../types.g";
import {
  hasSitePermissionByContext,
  PERMISSION_GROUPS,
  PERMISSION_KEYS,
} from "./helpers/permissions";
import "./list.css";
import SearchSiteList from "./search";
import { useAppContext } from "src/hooks/useAppContext";
import { useEffect } from "react";

export interface SitesListProps {
  viewType: string;
  inTrial: boolean;
  searchTerms: string;
  sortBy: SiteListHeaderActions;
  sites: SiteFragment[];
  sharedSites: SiteFragment[];
  onCreateNewSiteClick: () => void;
  onUpdateSiteClick: (site: SiteFragment) => void;
  onCastSiteCallback: (site: SiteFragment) => void;
  onDeleteSiteCallback: (site: SiteFragment) => void;
  onShareSiteCallback: (site: SiteFragment) => void;
  onClickCardCallback: (site: SiteFragment) => void;
  onHeaderSortChange: (action: SiteListHeaderActions) => void;
  onCastedScreensCallback: (
    data: string,
    action: CastedScreenInfoActions
  ) => void;
  onUpdateFavoriteSiteCallback: (
    site: SiteFragment,
    isFavorite?: boolean
  ) => void;
  onSaveSiteNameCallback: (site: SiteFragment, name: string) => void;
  isSharedByOtherTabActive: boolean;
  renderSitesFetchMoreButton: React.ReactNode;
  renderSharedSitesFetchMoreButton: React.ReactNode;
  setTotalCountCallback: ({
    totalCount,
    totalSharedCount,
  }: {
    totalCount: number;
    totalSharedCount: number;
  }) => void;
}

export enum SiteListType {
  FEATURED = "FEATURED",
  SHARED = "SHARE",
  SPACE = "SPACE",
}

const SitesList = (props: SitesListProps) => {
  const context = useAppContext();

  useEffect(() => {
    if (
      hasSitePermissionByContext(
        context,
        PERMISSION_GROUPS.Site,
        PERMISSION_KEYS.Create
      ) &&
      !getOnboardingRemindSetting()
    ) {
      context.modal.openNavigationControlModal(
        <DashboardsOnboardingModal
          onClose={() => context.modal.closeNavigationControlModal()}
        />,
        "",
        {
          opts: {
            className: "dashboards-onboarding-modal",
            closeOnDimmerClick: true,
            disableCloseButton: true,
            disableTitle: true,
            size: ModalSize.NORMAL,
          },
        }
      );
    }
    return () => {
      context.modal.closeNavigationControlModal();
    };
  }, []);

  const handleCallback = (
    site: Site,
    action: SiteListItemActions,
    value?: string | boolean
  ) => {
    switch (action) {
      case SiteListItemActions.DELETE:
        props.onDeleteSiteCallback(site);
        break;
      case SiteListItemActions.FAVORITE:
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.onUpdateFavoriteSiteCallback(site, Boolean(value) ?? false);
        break;
      case SiteListItemActions.VIEW:
        props.onClickCardCallback(site);
        break;
      case SiteListItemActions.CAST:
        props.onCastSiteCallback(site);
        break;
      case SiteListItemActions.SHARE:
        props.onShareSiteCallback(site);
        break;
    }
  };

  const canShare = () => {
    // check permission and space count
    return (
      hasSitePermissionByContext(
        context,
        PERMISSION_GROUPS.Site,
        PERMISSION_KEYS.Share
      ) && context.allSpaces.length > 1
    );
  };
  const renderSiteList = (
    // tslint:disable-next-line: array-type
    sites: SiteFragment[],
    itemType: string,
    siteListType: SiteListType,
    showSecureSiteColumns: boolean
  ): React.ReactNode[] => {
    const nodes: React.ReactNode[] = [];
    if (itemType !== "grid" && sites.length) {
      nodes.push(
        getSitesHeader(
          sites.length > 0,
          itemType,
          siteListType,
          showSecureSiteColumns
        )
      );
    }

    sites.map((site) => {
      const canBeUpdate =
        site.spaceId === context.currentSpace?.id &&
        hasSitePermissionByContext(
          context,
          PERMISSION_GROUPS.Site,
          PERMISSION_KEYS.Update
        );

      if (itemType === "grid") {
        nodes.push(
          <SiteCard
            key={site.id}
            id={site.id}
            name={site.name || ""}
            site={site}
            siteType={site.type ? site.type : SiteType.Basic}
            canShare={canShare() && siteListType !== SiteListType.SHARED}
            canCast={hasSitePermissionByContext(
              context,
              PERMISSION_GROUPS.Screen,
              PERMISSION_KEYS.Cast
            )}
            canDelete={
              hasSitePermissionByContext(
                context,
                PERMISSION_GROUPS.Site,
                PERMISSION_KEYS.Delete
              ) && siteListType !== SiteListType.SHARED
            }
            canUpdate={canBeUpdate}
            thumbnail={
              site.fileByThumbnailId
                ? generateImgixThumbnail(
                    site.fileByThumbnailId,
                    context.secureMediaPolicy
                  )
                : ""
            }
            castSiteCallback={() => props.onCastSiteCallback(site)}
            deleteSiteCallback={() => props.onDeleteSiteCallback(site)}
            shareSiteCallback={() => props.onShareSiteCallback(site)}
            onClickCallback={() => props.onClickCardCallback(site)}
            onSaveSiteNameCallback={(name: string) =>
              props.onSaveSiteNameCallback(site, name)
            }
            onUpdateSiteClick={() => props.onUpdateSiteClick(site)}
          />
        );
      } else {
        nodes.push(
          <SiteListItem
            key={site.id}
            castedScreensCallback={props.onCastedScreensCallback}
            siteType={site.type}
            callBack={handleCallback}
            site={site}
            media={(site.fileByThumbnailId as File) || undefined}
            tags={site.tags as string[]}
            previewOpen={false}
            shareSiteCallback={() => props.onShareSiteCallback(site)}
            showSecureSiteColumns={showSecureSiteColumns}
            onSaveSiteNameCallback={(name: string) =>
              props.onSaveSiteNameCallback(site, name)
            }
            canUpdate={canBeUpdate}
            onUpdateSiteClick={props.onUpdateSiteClick}
          />
        );
      }
    });
    return nodes;
  };

  const getSitesHeader = (
    showSortButton: boolean,
    viewType: string,
    listType: SiteListType,
    showSecureSiteColumns: boolean
  ) => {
    return (
      <SiteListHeader
        viewType={viewType}
        listType={listType}
        callback={(action) => props.onHeaderSortChange(action)}
        sortBy={props.sortBy}
        showSortButton={showSortButton}
        showSecureSiteColumns={showSecureSiteColumns}
      />
    );
  };

  const getSitesList = (
    spaceSites: SiteFragment[],
    sharedSites: SiteFragment[],
    viewType: string
  ) => {
    const hasSecureSite =
      spaceSites.some((site) => site.type === SiteType.Secure) ||
      sharedSites.some((site) => site.type === SiteType.Secure);

    const spaceSitesList = renderSiteList(
      spaceSites,
      viewType,
      SiteListType.SPACE,
      hasSecureSite
    );
    const sharedSitesList = renderSiteList(
      sharedSites,
      viewType,
      SiteListType.SHARED,
      hasSecureSite
    );

    return (
      <>
        {!props.isSharedByOtherTabActive && spaceSites.length > 0 && (
          <div>
            {viewType === "grid" ? (
              <div className="sub-wrapper">
                <CardGroup>
                  {spaceSitesList}
                  {props.renderSitesFetchMoreButton}
                </CardGroup>
              </div>
            ) : (
              <>
                {spaceSitesList}
                {props.renderSitesFetchMoreButton}
              </>
            )}
          </div>
        )}
        {props.isSharedByOtherTabActive && sharedSites.length > 0 && (
          <div>
            {viewType === "grid" ? (
              <div className="sub-wrapper">
                <CardGroup>
                  {sharedSitesList}
                  {props.renderSharedSitesFetchMoreButton}
                </CardGroup>
              </div>
            ) : (
              <>
                {sharedSitesList}
                {props.renderSharedSitesFetchMoreButton}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const sitesList = getSitesList(
    props.sites,
    props.sharedSites,
    props.viewType
  );

  return (
    <div>
      {props.searchTerms.length > 0 ? (
        <SearchSiteList
          sortBy={props.sortBy}
          searchTerms={props.searchTerms}
          viewType={props.viewType}
          onUpdateSiteClick={props.onUpdateSiteClick}
          onCastSiteCallback={props.onCastSiteCallback}
          onDeleteSiteCallback={props.onDeleteSiteCallback}
          onShareSiteCallback={props.onShareSiteCallback}
          onClickCallback={props.onClickCardCallback}
          onSaveSiteNameCallback={props.onSaveSiteNameCallback}
          isSharedByOtherTabActive={props.isSharedByOtherTabActive}
          setTotalCountCallback={props.setTotalCountCallback}
        />
      ) : (
        <>{sitesList}</>
      )}
    </div>
  );
};

export default SitesList as React.ComponentType<SitesListProps>;
