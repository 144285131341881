import { ApolloProvider } from "@apollo/client";
import { Component, ErrorInfo } from "react";
import App from "./App";
import { withStudioSessionInitialized } from "./AppContextProvider/hocs/WithStudioSessionInitialized";
import apolloClient from "./state/apolloClient";

class AppWrapper extends Component {
  public componentDidCatch(error: Error, info: ErrorInfo) {
    // todo: show notification for user
    // todo: send error report to developers
    // console.log('Error react component stack:', info.componentStack)
    throw error;
  }

  public render() {
    return (
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    );
  }
}

/**
 * Later we should wrap everything here in this page such as component did catch and apollo provider in hoc
 */
export default withStudioSessionInitialized(AppWrapper);
