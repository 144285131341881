import { getOnCastableDeletedRefetchQueries } from "src/domains/castableEntity/events/onCastableEntityDeleted";
import { useOnDeleteFile } from "src/domains/file/events/useOnFileDeleted";
import {
  File,
  DeleteFileByIdMutationVariables,
  useDeleteFileByIdMutation,
} from "../../../types.g";

export interface UseDeleteFile {
  deleteFile: (file: Pick<File, "id">) => Promise<any>;
}

export function useDeleteFile() {
  const [deleteFileById] = useDeleteFileByIdMutation();
  const { onFileDeleted } = useOnDeleteFile();
  const deleteFile = async (file: Pick<File, "id">) => {
    const deleteFileByIdMutationVariables: DeleteFileByIdMutationVariables = {
      input: {
        fileId: file.id,
      },
    };

    return deleteFileById({
      update: (_, { data }) => {
        if (data?.deleteFileById?.file) {
          onFileDeleted({ file: data?.deleteFileById?.file });
        }
      },
      variables: deleteFileByIdMutationVariables,
      refetchQueries: getOnCastableDeletedRefetchQueries(),
    });
  };
  return { deleteFile };
}
