import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "../../utils/compose";
import { useStateSearch } from "../../hooks/useStateSearch";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { UseLinkPicker, useLinkPicker } from "./hooks/useLinkPicker";

export interface ApolloProps extends RouteComponentProps<any>, UseLinkPicker {
  searchString: string;
  updateSearchString: (searchString: string) => void;
  clearSearch: () => void;
  query: string;
}

export const withData = compose(
  withApollo,
  withRouter,
  (Component) => (props) => {
    const linkListProps = useLinkPicker(props.spaceId);
    const {
      query,
      searchString,
      updateSearchString,
      clearSearch,
    } = useStateSearch();

    return (
      <Component
        {...props}
        {...linkListProps}
        query={query}
        searchString={searchString}
        updateSearchString={updateSearchString}
        clearSearch={clearSearch}
      />
    );
  }
);
