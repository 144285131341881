import {
  Button,
  CloseButtonPosition,
  CoverBackgroundType,
  CoverColor,
  Dropdown,
  Icon,
  LoaderBar,
  ModalSize,
  Tab,
} from "@screencloud/screencloud-ui-components";
import { EntityType } from "@screencloud/signage-firestore-client";
import { Direction, TransitionType } from "@screencloud/signage-player";
import { cloneDeep, find } from "lodash";
import { Component, SyntheticEvent } from "react";
import {
  DragDropContext,
  DraggableLocation,
  DropResult,
} from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../../AppContextProvider/AppContext";

import { ChannelContentActions } from "../../../components/ChannelContentItem";
import { ChannelPlayingScreenActions } from "../../../components/ChannelPlayingScreen/playingScreenContent";
import CoverThumbnail from "../../../components/CoverThumbnail";
import Main from "../../../components/Main";
import { MediaPickerActionMode } from "../../../components/MediaPicker/media";
import PlayerPreview, { PreviewType } from "../../../components/PlayerPreview/";
import SidebarRight from "../../../components/SidebarRight";
import ThemePane from "../../../components/ThemePane";

import UpdateChannelCoverContainer from "../../../components/UpdateChannelCoverContainer";
import {
  BLANK_ZONES_LAYOUT_ID,
  ChannelListContentItem,
  ChannelZone,
  DEBOUNCE_TIMEOUT_MS,
  DEFAULT_GLOBAL_DURATIONS,
  FROM_CHANNEL_PAGE_QUERYSTRING,
  Orientation,
  PG_MAX_SAFE_SMALLINT,
  RefType,
  ScheduleRules,
  SizingTypeRules,
  TitlePageSufix,
  Typenames,
  ZoneLayoutNames,
} from "../../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../../constants/featureFlag";
import { generateImgixURL } from "../../../helpers/mediaHelper";
import {
  AppInstance,
  AppInstancesConnection,
  Channel,
  ChannelByIdQuery,
  File,
  FilesConnection,
  Layout,
  Link,
  LinksConnection,
  Maybe,
  Playlist,
  PlaylistsConnection,
  PublishDraftChannelMutationVariables,
  Scalars,
  Site,
  SitesConnection,
  UpdateChannelCoverMutationVariables,
  UpdateChannelMutationVariables,
  UpdateChannelThemeMutationVariables,
} from "../../../types.g";
import { ApolloProps, withData } from "./apollo";
import ChannelContentsList from "./channelContentsList";

import { subscribeToDocumentUpdates } from "../../../state/liveUpdateManager";

import LayoutPicker from "../../../components/LayoutPicker";
import {
  channelOptimisticDataByInput,
  checkAndUpdateZonesContent,
  convertToChannelPlayerZone,
  getChannelContentByOwner,
  getZoneContentBySelectedZone,
  getZoneOptions,
  newItemsFromPickerResult,
  ZoneOption,
  getChannelOrientation,
} from "../../../helpers/channelHelper";
import Forbidden from "../../Forbidden";
import ChannelDetailHeader from "./ChannelDetailHeader/index";
import LayoutSetting from "./LayoutSetting";
import { Styled } from "./styles";
import { isOwner } from "../../../helpers/shareableHelper";
import { ScreenPickerActions } from "../../../../src/components/ScreenPicker";
import { AppContextType } from "src/AppContextProvider/type";
import {
  getChannelItemByRefType,
  getContentByRefId,
  getValidChannelContent,
  formatContentTemplateInput,
  validateChannelByIdQuery,
} from "./helper";
import { ChannelEditCoverTitle } from "./ChannelEditCoverTitle";
import { getCurrentUserInterfaceVisibilitiesByContext } from "src/userInterfaceVisibility/useUserInterfaceVisibilities";
import PageContainer from "src/components/PageContainer";
import Container from "src/components/Cont";

interface SizingTypeDropdownOption {
  key: "fit" | "fill";
  value: "fit" | "fill";
  text: React.ReactElement;
}

const sizingTypeDropdownOptions: SizingTypeDropdownOption[] = [
  {
    key: "fill",
    text: (
      <FormattedMessage
        data-testid="fill-zone"
        id={"channels.fill_content_to_zone"}
        defaultMessage={"Fill Zone"}
      />
    ),
    value: "fill",
  },
  {
    key: "fit",
    text: (
      <FormattedMessage
        data-testid="fill-zone"
        id={"channels.fit_content_to_zone"}
        defaultMessage={"Fit to Zone"}
      />
    ),
    value: "fit",
  },
];

const defaultSizingType = sizingTypeDropdownOptions[0].value;

export interface IZonesReport {
  zones: JSON;
}

export enum ChannelDetailMode {
  Preview,
  Edit,
}

interface State {
  isDrafting: boolean;
  isLayoutLandscapeEnable: boolean;
  isLayoutPortraitEnable: boolean;
  selectedMediaItem: string;
  channelItems: any[];
  defaultTimeValue: { start: string; end: string }[];
  selectedZone: string;
  isHidePublishAnimation: boolean;
  isPublishing: boolean;
  isDragging: boolean;
  isRevertChange: boolean;
  newDuration: number | null;
  isSaving: boolean;
}

type Prop = ApolloProps & { router?: any } & {
  mode: ChannelDetailMode;
  isFullScreen: boolean;
  isNew: boolean;
  onEditChannelClick: () => void;
  onPreviewChannelClick: () => void;
  onPreviewChannelFromDetailPageClick: () => void;
  onPreviewFullscreen: () => void;
  onBackToListPage: () => void;
};

const DEFAULT_ZONE = "zone1";
export class ChannelDetail extends Component<Prop, State> {
  public static contextType = AppContext;

  public layoutsData: Layout[];
  public addedApps: AppInstance[];
  public addedFiles: Maybe<File>[];
  public addedPlaylists: Partial<Playlist>[];
  public addedLinks: Link[];
  public addedSites: Site[];
  public _isMounted: boolean;
  public _isSubscribeToLiveupdate: boolean;
  public _isSubscribeChannelToLiveupdate: boolean;
  public _refreshPlayerData: () => void;
  public context: AppContextType;
  private unsubscribeCoverImageLiveUpdateFn;
  private unsubscribeChannelLiveUpdateFn;
  private debounceUpdateChannelTimeout;
  private debounceUpdateCustomDuration;

  constructor(props: Prop) {
    super(props);

    this.state = {
      channelItems: this.channelItemsBySelectedZone(DEFAULT_ZONE),
      defaultTimeValue: [
        {
          end: "18:30",
          start: "08:00",
        },
      ],
      isDrafting: false,
      isHidePublishAnimation: false,
      isLayoutLandscapeEnable: true,
      isLayoutPortraitEnable: true,
      isPublishing: false,
      selectedMediaItem: "",
      selectedZone: DEFAULT_ZONE,
      isDragging: false,
      isRevertChange: false,
      newDuration: null,
      isSaving: false,
    };

    this.addedApps = [];
    this.addedFiles = [];
    this.addedPlaylists = [];
    this.addedLinks = [];
    this.addedSites = [];
    this._isSubscribeToLiveupdate = false;
    this._isSubscribeChannelToLiveupdate = false;
  }

  public componentDidMount() {
    this._isMounted = true;
    if (this.props.data?.data?.channelById?.coverImageId) {
      this.subscribeCoverImageToLiveUpdate(
        this.props.data.data.channelById.coverImageId
      );
    }
    this.subscribeToLiveUpdate();
    if (this.props.data?.data?.channelById) {
      document.title = `${this.props.data.data.channelById.name} - ${TitlePageSufix}`;
    }

    this.openPreview();
  }

  public openPreview = () => {
    const { data } = this.props;
    const isChannelOwner = isOwner({
      context: this.context,
      spaceId: data.data?.channelById?.spaceId,
    });
    const channelDraft = getChannelContentByOwner({
      context: this.context,
      channel: data?.data?.channelById,
    });
    if (channelDraft) {
      const orientation = getChannelOrientation(channelDraft);
      if (this.isPreViewMode) {
        this.context.modal.openModal(
          <PlayerPreview
            id={channelDraft.id}
            previewType={PreviewType.CHANNEL}
            orientation={orientation}
            isOwner={isChannelOwner}
            presetHeight={channelDraft.height}
            presetWidth={channelDraft.width}
          />,
          null,
          {
            opts: {
              closeButtonPosition: CloseButtonPosition.LEFT,
              disableTitle: true,
              size: ModalSize.FULLSCREEN,
            },
            onClose: () => {
              this.props.history.goBack();
            },
          }
        );
      }
    }
  };

  public componentDidUpdate(prevProps: Prop, prevState: State) {
    const { isFullScreen } = this.props;
    const prevChannelById = prevProps.data.data?.channelById;
    const channelById = this.props.data.data?.channelById;
    if (prevChannelById !== channelById) {
      if (
        channelById &&
        channelById?.draft?.filesByChannelId &&
        channelById.draft.playlistsByChannelId &&
        channelById.draft.linksByChannelId &&
        channelById.draft.sitesByChannelId &&
        channelById.draft.appInstancesByChannelId
      ) {
        const selectedZone = this.state.selectedZone;
        this.setState({
          channelItems: this.channelItemsBySelectedZone(selectedZone),
        });
      }
      if (
        channelById?.coverImageId !==
        prevProps.data.data?.channelById?.coverImageId
      ) {
        this.subscribeCoverImageToLiveUpdate(channelById?.coverImageId);
      }

      this.openPreview();
    }

    const isFirstTimeDataLoaded =
      !this.isDataLoaded(prevProps) && this.isDataLoaded(this.props);
    const shouldOpenFullscreen =
      (!prevProps.isFullScreen && isFullScreen) ||
      (isFirstTimeDataLoaded && isFullScreen);
    if (shouldOpenFullscreen) {
      this.previewFullscreen();
    }

    const shouldCloseFullScreen = prevProps.isFullScreen && !isFullScreen;
    if (shouldCloseFullScreen) {
      this.context.modal.closeModals();
    }

    if (this.props.data.data?.channelById) {
      document.title = `${this.props.data.data.channelById.name} - ${TitlePageSufix}`;
    }

    if (
      this.props.history.action === "POP" &&
      this.props.location.pathname !== prevProps.location.pathname &&
      this.context.modal.isModalShowing
    ) {
      this.context.modal.closeModals();
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.clearTimeout();
    if (this.props.data.data?.channelById?.coverImageId) {
      this.unsubscribeCoverImageFromLiveUpdate();
    }
    this.unsubscribeFromLiveUpdate();
  }

  public subscribeToLiveUpdate = () => {
    if (this._isSubscribeChannelToLiveupdate === false) {
      if (this.unsubscribeChannelLiveUpdateFn) {
        this.unsubscribeChannelLiveUpdateFn();
        this.unsubscribeChannelLiveUpdateFn = undefined;
      }
      this.unsubscribeChannelLiveUpdateFn = subscribeToDocumentUpdates(
        this.context.user.claims.orgId || "",
        EntityType.CHANNEL,
        this.props.match.params.channelId,
        async () => {
          if (this.props.data) {
            await this.props.data.refetch({
              id: this.props.match.params.channelId,
            });
          }
        }
      );
      this._isSubscribeChannelToLiveupdate = true;
    }
  };

  public unsubscribeFromLiveUpdate = () => {
    if (this.unsubscribeChannelLiveUpdateFn) {
      this.unsubscribeChannelLiveUpdateFn();
      this.unsubscribeChannelLiveUpdateFn = undefined;
      this._isSubscribeChannelToLiveupdate = false;
    }
  };

  public subscribeCoverImageToLiveUpdate = (coverImageId: string) => {
    if (
      this.props.data?.data?.channelById &&
      coverImageId &&
      this._isSubscribeToLiveupdate === false
    ) {
      if (this.unsubscribeCoverImageLiveUpdateFn) {
        this.unsubscribeCoverImageLiveUpdateFn();
        this.unsubscribeCoverImageLiveUpdateFn = undefined;
      }
      this.unsubscribeCoverImageLiveUpdateFn = subscribeToDocumentUpdates(
        this.context.user.claims.orgId || "",
        EntityType.FILE,
        coverImageId,
        async () => {
          if (this.props.data) {
            this.props.data.refetch({ id: this.props.match.params.channelId });
          }
        }
      );
      this._isSubscribeToLiveupdate = true;
    }
  };

  public unsubscribeCoverImageFromLiveUpdate = () => {
    const { data } = this.props;
    if (data.data?.channelById?.coverImageId) {
      if (this.unsubscribeCoverImageLiveUpdateFn) {
        this.unsubscribeCoverImageLiveUpdateFn();
        this.unsubscribeCoverImageLiveUpdateFn = undefined;
      }
      this._isSubscribeToLiveupdate = false;
    }
  };

  public getZonesReport = (channel: Partial<Channel>): IZonesReport => {
    const channelZones: JSON = channel.content.zones || [];
    return { zones: channelZones };
  };

  public getLayoutDropdownOptions = (): ZoneOption[] => {
    if (this.props.data?.data?.channelById?.draft) {
      const { channelById } = this.props.data.data;
      const zones = getZoneOptions(channelById.draft as Channel, {
        showHidden: true,
        isForPlayer: false,
      });
      return zones;
    }
    return [];
  };

  public updateSelectedZone() {
    if (
      this.state.selectedZone === "" &&
      this.props.data &&
      this.props.data.data?.channelById
    ) {
      const { draft } = this.props.data.data.channelById;
      if (draft) {
        this.setState({ selectedZone: "zone1" });
      }
    }
  }

  public channelItemsBySelectedZone = (selectedZoneOption: string) => {
    if (
      this.props.data.data?.channelById?.draft &&
      this.props.data.data?.channelById?.draft?.content
    ) {
      const channelById = this.props.data.data.channelById;
      const draft = channelById.draft;
      const filesByChannelId = draft?.filesByChannelId
        ? draft.filesByChannelId.nodes
        : [];
      const playlistsByChannelId = draft?.playlistsByChannelId
        ? draft.playlistsByChannelId.nodes
        : [];
      const linksByChannelId = draft?.linksByChannelId
        ? draft.linksByChannelId.nodes
        : [];
      const sitesByChannelId = draft?.sitesByChannelId
        ? draft.sitesByChannelId.nodes
        : [];
      const appsByChannelId = draft?.appInstancesByChannelId
        ? draft.appInstancesByChannelId.nodes
        : [];
      const zoneContent = getZoneContentBySelectedZone(
        selectedZoneOption,
        (channelById.draft as unknown) as Channel
      );
      const channelContent = draft?.content;
      const channelItems = this.itemsByZoneContent(
        channelContent,
        zoneContent,
        filesByChannelId as File[],
        playlistsByChannelId as Playlist[],
        linksByChannelId as Link[],
        sitesByChannelId as Site[],
        appsByChannelId as AppInstance[]
      );
      return channelItems;
    }
    // content draft data not available
    return [];
  };

  public itemsByZoneContent = (
    channelContent: Scalars["JSON"],
    zoneContent: ChannelZone | undefined,
    filesByChannelId: File[],
    playlistsByChannelId: Playlist[],
    linksByChannelId: Link[],
    sitesByChannelId: Site[],
    appsByChannelId: AppInstance[]
  ) => {
    return zoneContent && zoneContent.list
      ? zoneContent.list
          .reduce(
            (
              accumulator: ChannelListContentItem[],
              list: ChannelListContentItem
            ) => {
              let channelItem;
              const contentByRefId = getContentByRefId({
                list,
                filesByChannelId,
                playlistsByChannelId,
                linksByChannelId,
                sitesByChannelId,
                appsByChannelId,
              });

              const list_id = list.list_id;
              if (list && list.content && list.content?._ref) {
                const listRefType =
                  list.content?._ref.type === RefType.FILE
                    ? "image"
                    : list.content?._ref.type;
                const defaultDuration =
                  channelContent.props &&
                  channelContent.props.default_durations &&
                  channelContent.props.default_durations[listRefType]
                    ? channelContent.props.default_durations[listRefType]
                    : list.content?._ref.type === RefType.PLAYLIST
                    ? 0
                    : DEFAULT_GLOBAL_DURATIONS[listRefType];

                const duration = list.content?.props.duration
                  ? list.content?.props.duration
                  : defaultDuration;

                channelItem = getChannelItemByRefType({
                  listId: list_id,
                  duration,
                  refType: list.content?._ref.type,
                  list,
                  contentByRefId,
                  secureMediaPolicy: this.context?.secureMediaPolicy,
                });
              }
              if (channelItem) {
                return [...accumulator, channelItem];
              } else {
                return [...accumulator];
              }
            },
            []
          )
          .filter((item) => {
            return item !== undefined && item !== null;
          })
      : [];
  };

  public updateChannel = async (
    channelInput: UpdateChannelMutationVariables
  ) => {
    if (this._isMounted) {
      this.setState({
        isDrafting: true,
        isRevertChange: false,
      });
    }

    if (
      !this.props.data ||
      !this.props.data.data?.channelById ||
      ((!this.props.data.data?.channelById.draft as unknown) as Channel)
    ) {
      return;
    }

    const channel = { ...this.props.data.data.channelById };
    let channelDraft = { ...this.props.data.data.channelById.draft } as Channel;
    const newContent = await checkAndUpdateZonesContent(channelDraft);

    if (newContent) {
      channelDraft.content = newContent;
    }

    channelDraft = channelOptimisticDataByInput(
      channelDraft as Channel,
      channelInput,
      this.layoutsData
    );

    channelInput.input.content = channelDraft.content;

    const filesByChannelIdRef = ({
      ...((this.props.data.data?.channelById?.draft
        ?.filesByChannelId as unknown) as File),
    } as unknown) as FilesConnection;
    filesByChannelIdRef.nodes = [
      ...filesByChannelIdRef.nodes,
      ...(this.addedFiles as typeof filesByChannelIdRef.nodes),
    ];

    const playlistsByChannelIdRef = ({
      ...this.props.data.data?.channelById?.draft?.playlistsByChannelId,
    } as unknown) as PlaylistsConnection;
    playlistsByChannelIdRef.nodes = [
      ...playlistsByChannelIdRef.nodes,
      ...(this.addedPlaylists as typeof playlistsByChannelIdRef.nodes),
    ];

    const linksByChannelIdRef = ({
      ...this.props.data.data?.channelById?.draft?.linksByChannelId,
    } as unknown) as LinksConnection;
    linksByChannelIdRef.nodes = [
      ...linksByChannelIdRef.nodes,
      ...(this.addedLinks as typeof linksByChannelIdRef.nodes),
    ];

    const sitesByChannelIdRef = ({
      ...this.props.data.data?.channelById?.draft?.sitesByChannelId,
    } as unknown) as SitesConnection;
    sitesByChannelIdRef.nodes = [
      ...sitesByChannelIdRef.nodes,
      ...(this.addedSites as typeof sitesByChannelIdRef.nodes),
    ];

    const appsByChannelIdRef = ({
      ...this.props.data.data?.channelById?.draft?.appInstancesByChannelId,
    } as unknown) as AppInstancesConnection;
    appsByChannelIdRef.nodes = [
      ...appsByChannelIdRef.nodes,
      ...(this.addedApps as typeof appsByChannelIdRef.nodes),
    ];

    const channelDraftFinal: NonNullable<
      ChannelByIdQuery["channelById"]
    >["draft"] = {
      ...channelDraft,
      __typename: "Channel",
      appInstancesByChannelId: appsByChannelIdRef,
      filesByChannelId: filesByChannelIdRef,
      linksByChannelId: linksByChannelIdRef,
      sitesByChannelId: sitesByChannelIdRef,
      playlistsByChannelId: playlistsByChannelIdRef,
    };

    if (
      channelDraftFinal != null &&
      channelInput.input.height &&
      channelInput.input.width
    ) {
      channelDraftFinal.height = channelInput.input.height;
      channelDraftFinal.width = channelInput.input.width;
    }

    if (
      channelInput.input.layoutId &&
      channelInput.input.layoutId === BLANK_ZONES_LAYOUT_ID
    ) {
      channelDraftFinal!.layoutId = null;
    }

    if (!!channel.childOf) {
      channelInput.input.content = {
        ...channelInput.input.content,
        props: channelDraftFinal.content.props,
        zones: formatContentTemplateInput(channelDraftFinal.content.zones),
      };
    }
    this.props
      .updateChannel({
        optimisticResponse: {
          __typename: "Mutation",
          updateChannel: {
            __typename: "UpdateChannelPayload",
            channel: {
              ...channel,
              draft: channelDraftFinal,
            },
          },
        },
        variables: channelInput,
      })
      .then(() => {
        if (this._isMounted && this.state.selectedZone) {
          this.setState({
            channelItems: this.channelItemsBySelectedZone(
              this.state.selectedZone
            ),
            isDrafting: false,
            isSaving: false,
          });
        }
      });
  };

  public handleRevertChange = async () => {
    this.setState({
      isRevertChange: true,
    });
  };

  public handlePublishChannel = async () => {
    if (!this.state.isPublishing || !this.state.isHidePublishAnimation) {
      if (
        this.context.currentOrg?.preferences?.settings?.confirm_before_publish
      ) {
        const confirmationMessage = (
          <>
            <div>
              <strong>
                <FormattedMessage
                  id="ui_component.confirm.publish_change_header"
                  defaultMessage="Publish changes?"
                />
              </strong>
            </div>
            <div style={{ padding: "10px 0 0 0" }}>
              <FormattedMessage
                id="ui_component.confirm.publish_confirm_message"
                defaultMessage="Any screens currently playing this {name} will restart playback from the beginning."
                values={{ name: "channel" }}
              />
            </div>
          </>
        );
        const confirmPublishing = await this.context.modal.confirm(
          confirmationMessage,
          {
            confirm: (
              <FormattedMessage
                id="common.button.publish_now"
                defaultMessage="Publish now"
              />
            ),
            cancel: (
              <FormattedMessage
                id="common.button.leave_as_draft"
                defaultMessage="Leave {name} as draft"
                values={{ name: "channel" }}
              />
            ),
          }
        );

        if (confirmPublishing.confirm) {
          this.publishChannel();
        }
      } else {
        this.publishChannel();
      }
    }
  };

  public publishChannel = async () => {
    if (this.props.data.data?.channelById?.draft) {
      const variables: PublishDraftChannelMutationVariables = {
        input: { id: this.props.match.params.channelId },
      };
      if (this._isMounted) {
        this.setState({ isPublishing: true });
      }
      if (this.props.data.data?.channelById?.draft) {
        const newContent = await checkAndUpdateZonesContent(
          this.props.data.data?.channelById.draft as Channel
        );
        if (newContent) {
          // need to update draft before published
          const channelInput: UpdateChannelMutationVariables = {
            input: {
              content: newContent,
              id: this.props.data.data?.channelById.id,
            },
          };
          await this.props.updateChannel({
            variables: channelInput,
          });
        }
      }
      this.props
        .publishDraftChannel({
          variables,
        })
        .then(() => {
          if (this._isMounted) {
            this.setState({
              isHidePublishAnimation: true,
              isPublishing: false,
            });
          }
          window.setTimeout(() => {
            // animation delaying after publish mutation for 0.7 seconds
            if (this._isMounted) {
              this.setState({ isHidePublishAnimation: false });
            }
          }, 700);
        });
    }
  };

  public showMediaPicker = async () => {
    if (
      this.props.data &&
      this.props.data.data?.channelById &&
      ((this.props.data.data?.channelById.draft as unknown) as Channel)
    ) {
      const content = { ...this.props.data.data.channelById?.draft?.content };
      const zones: Maybe<Scalars["JSON"]> = { ...content.zones };
      const { mediaPicker } = getCurrentUserInterfaceVisibilitiesByContext(
        this.context
      );

      const isAbleToViewMedia = this.context.currentPermissions.validateCurrentSpace(
        "media",
        "read"
      );
      const isAbleToViewApp = this.context.currentPermissions.validateCurrentSpace(
        "app_instance",
        "read"
      );
      const isAbleToViewPlaylist = this.context.currentPermissions.validateCurrentSpace(
        "playlist",
        "read"
      );

      const menu: RefType[] = isAbleToViewPlaylist ? [RefType.PLAYLIST] : [];

      if (isAbleToViewMedia) {
        menu.push(RefType.FILE);
      }
      if (mediaPicker.isShowLink) {
        menu.push(RefType.LINK);
      }
      if (mediaPicker.isShowDashboard) {
        menu.push(RefType.SITE);
      }

      // add app after link for the right menu order
      if (isAbleToViewApp) {
        menu.push(RefType.CANVAS);
        menu.push(RefType.APP);
      }

      const result = await this.context.modal.openMediaPicker(
        MediaPickerActionMode.ADD,
        "Media Picker",
        {
          menu,
          multiple: true,
          screenPickerAction: ScreenPickerActions.SET_CONTENT,
        }
      );
      if (result.mediaType) {
        // todo: refactoring as any should be change
        const createdItems: {
          newItems: (File | Playlist | Link | Site | AppInstance)[];
          apps: AppInstance[];
          files: File[];
          playlists: Playlist[];
          links: Link[];
          sites: Site[];
        } = newItemsFromPickerResult(
          result,
          content,
          this.context.secureMediaPolicy
        );

        switch (result.mediaType) {
          case RefType.FILE:
            this.addedFiles = createdItems.files;
            break;
          case RefType.PLAYLIST:
            this.addedPlaylists = createdItems.playlists;
            break;
          case RefType.LINK:
            this.addedLinks = createdItems.links;
            break;
          case RefType.SITE:
            this.addedSites = createdItems.sites;
            break;
          case RefType.APP:
            this.addedApps = createdItems.apps;
            break;
          case RefType.APP_INSTANCE:
            this.addedApps = createdItems.apps;
            break;
        }

        const channelItem = [
          ...this.state.channelItems,
          ...createdItems.newItems,
        ];
        if (this._isMounted) {
          this.setState({ channelItems: channelItem }, () => {
            if (
              this.props.data?.data?.channelById?.draft &&
              this.state.selectedZone
            ) {
              let zoneContent = cloneDeep(
                getZoneContentBySelectedZone(
                  this.state.selectedZone,
                  (this.props.data.data?.channelById
                    .draft as unknown) as Channel
                )
              );
              zoneContent.list = zoneContent.list ? zoneContent.list : [];
              const updatedList = channelItem.map((item) => {
                const listObj:
                  | ChannelListContentItem
                  | undefined = zoneContent.list!.find(
                  (zoneItem) => zoneItem.list_id === item.list_id
                );

                if (listObj) {
                  return listObj;
                } else {
                  return {
                    content: {
                      _ref: { type: item.type, id: item.id },
                      props: {},
                    },
                    list_id: item.list_id,
                    rules: [],
                  };
                }
              });
              zoneContent = { ...zoneContent };
              zoneContent.list = updatedList;

              if (!zoneContent.props) {
                zoneContent.props = {};
              }

              if (!zoneContent.props.sizing_type) {
                zoneContent.props.sizing_type = {
                  document: defaultSizingType,
                  image: defaultSizingType,
                  video: defaultSizingType,
                };
              }

              content.zones = this.updateSelectedZonesContent(
                zones,
                zoneContent
              );

              const channelInput: UpdateChannelMutationVariables = {
                input: {
                  content,
                  id: this.props.data.data?.channelById?.id,
                },
              };
              this.debounceUpdateChannel(this.updateChannel, channelInput);
            }
          });
        }
      }
    }
  };

  public isDataLoaded = (props = this.props) => {
    const { data } = props;
    return data.data?.channelById?.draft;
  };

  public onPlayerZoneChange = (playerSelectedZone: string) => {
    const selectedZone = playerSelectedZone.replace("Channel_", "");
    this.setState({
      channelItems: this.channelItemsBySelectedZone(selectedZone),
      selectedZone,
    });
  };

  public previewFullscreen = () => {
    const { onPreviewChannelClick, data } = this.props;
    const { selectedZone } = this.state;

    if (this.isDataLoaded()) {
      // const {id, width, height} = this.channelDraft!
      const { id, width, height } = data.data?.channelById?.draft!;
      // todo: introduce better way to create mock entities
      // todo: IChannel not compatple with new type
      let orientation = Orientation.Landscape;

      if (
        this.props.data.data?.channelById?.height &&
        this.props.data.data?.channelById?.width &&
        this.props.data.data?.channelById?.height >
          this.props.data.data?.channelById.width
      ) {
        orientation = Orientation.Portrait;
      }
      const isChannelOwner =
        this.context.user.settings.spaceId === data?.data?.channelById?.spaceId;

      const playerPreview = (
        <PlayerPreview
          id={id}
          previewType={PreviewType.CHANNEL}
          orientation={orientation}
          showExitFullScreenButton
          presetWidth={width}
          presetHeight={height}
          onExitFullScreenClicked={onPreviewChannelClick}
          defaultSelectedZone={convertToChannelPlayerZone(selectedZone)}
          onZoneChange={this.onPlayerZoneChange}
          isOwner={isChannelOwner}
        />
      );

      this.context.modal.openModal(playerPreview, null, {
        opts: {
          disableCloseButton: true,
          disableTitle: false,
          size: ModalSize.FULLSCREEN,
        },
      });
    }
  };

  public onMediaSelect = (mediaId: string) => {
    this.setState({ selectedMediaItem: mediaId });
  };

  public handleLayoutChange = () => {
    if (this.props.data.data?.channelById?.draft?.layoutId) {
      const selectedId = this.props.data.data?.channelById.draft.layoutId;
      const channelId = this.props.data.data?.channelById.draft.id;
      this.context.modal.openNavigationControlModal(
        <LayoutPicker
          channelId={channelId}
          selectedId={selectedId}
          onSelectLayout={this.handleSelectLayout}
          height={this.props.data.data?.channelById.draft.height ?? 1080}
          width={this.props.data.data?.channelById.draft.width ?? 1920}
          isParentChannel={this.props.data.data?.channelById.isParent}
        />,
        "Choose Layout",
        {
          opts: {
            disableTitle: false,
            size: ModalSize.LARGE,
          },
        }
      );
    }
  };

  public handleLayoutHiddenToggle = (
    event: React.SyntheticEvent<any>,
    value: any
  ) => {
    if (this.props.data.data?.channelById?.draft) {
      const channelDraft = this.props.data.data.channelById.draft;
      const channelInput: UpdateChannelMutationVariables = {
        input: {
          hiddenZone: !channelDraft?.hiddenZone,
          id: this.props.data.data?.channelById.id,
        },
      };

      if (!value) {
        this.setState({ selectedZone: "zone1" });
      } else {
        this.setState({ selectedZone: "hidden" });
      }

      this.debounceUpdateChannel(this.updateChannel, channelInput);
    }
  };

  public onUpdateContentList = (
    listId: string,
    channelItem: any[],
    action: ChannelContentActions,
    data?: ScheduleRules[] | Maybe<Scalars["JSON"]>
  ) => {
    if (this.props.data.data?.channelById?.draft && this.state.selectedZone) {
      const content = { ...this.props.data.data?.channelById?.draft.content };
      const zones: Maybe<Scalars["JSON"]> = { ...content.zones };
      const selectedZoneOption = this.state.selectedZone;
      let zoneContent = getZoneContentBySelectedZone(
        selectedZoneOption,
        this.props.data.data?.channelById?.draft
      );

      const updatedList: ChannelListContentItem[] = channelItem.map((list) => {
        const listObj: ChannelListContentItem | undefined = find(
          zoneContent.list,
          {
            list_id: list.list_id,
          }
        ) as ChannelListContentItem | undefined;
        const newListObj: ChannelListContentItem = cloneDeep(
          listObj
        ) as ChannelListContentItem;
        if (newListObj && newListObj.list_id === listId && data) {
          switch (action) {
            case ChannelContentActions.SCHEDULE:
              newListObj.rules = data as ScheduleRules[];
              break;
            case ChannelContentActions.DURATION:
              this.setState({ newDuration: data.duration });
              break;
            case ChannelContentActions.PLAYBACK_MODE:
              newListObj.content.props = {
                ...newListObj.content.props,
                playback: {
                  ...newListObj.content.props.playback,
                  mode: data.playbackMode,
                },
              };
              break;
            case ChannelContentActions.PICK_TO_PLAY:
              newListObj.content.props = {
                ...newListObj.content.props,
                playback: {
                  ...newListObj.content.props.playback,
                  pick_to_play: data.pickToPlay,
                },
              };
              if (!data.pickToPlay) {
                delete newListObj.content.props?.playback?.pick_to_play;
              }
              break;
          }
          return newListObj;
        } else {
          return newListObj;
        }
      });
      zoneContent = { ...zoneContent };
      zoneContent.list = updatedList;
      content.zones = this.updateSelectedZonesContent(zones, zoneContent);
      const channelInput: UpdateChannelMutationVariables = {
        input: {
          content,
          id: this.props.data.data.channelById.id,
        },
      };

      if (action !== ChannelContentActions.DURATION) {
        this.debounceUpdateChannel(this.updateChannel, channelInput);
      }
    }
  };

  public updateSelectedZonesContent = (zones, zoneContent) => {
    if (this.state.selectedZone) {
      const selectedZoneOption = this.state.selectedZone;
      zones[selectedZoneOption] = zoneContent;
      return zones;
    }
  };

  public onPreviewClick = (content: any, contentType: string) => {
    switch (contentType) {
      case Typenames.AppInstance:
        this.onShowAppconfigure(content as AppInstance);
        break;
      case Typenames.Link:
        this.context.modal.openLinkDetail(content.id);
        break;
      case Typenames.Site:
        // TODO - Show link detail dialog
        break;
      default:
        this.context.modal.openMediaDetail({ fileId: content.id });
        break;
    }
  };

  public onShowAppconfigure = async (app: AppInstance) => {
    if (app) {
      await this.context.modal.openAppConfigure(
        app.id,
        undefined,
        undefined,
        undefined,
        <FormattedMessage id="app.configure" defaultMessage="Configure" />
      );
    }
  };

  public onChannelItemChange = (
    listId: string,
    action: ChannelContentActions,
    data: any
  ) => {
    let newChannelItems = this.state.channelItems;
    switch (action) {
      case ChannelContentActions.SCHEDULE:
        newChannelItems = this.state.channelItems.map((item) => {
          if (item.list_id === listId) {
            return {
              ...item,
              schedule: data as ScheduleRules[],
            };
          }
          return item;
        });
        break;
      case ChannelContentActions.DURATION:
        newChannelItems = newChannelItems.map((item) => {
          if (item.list_id === listId) {
            return {
              ...item,
              duration: data.duration,
            };
          }
          return item;
        });
        break;
      case ChannelContentActions.REMOVE:
        newChannelItems = newChannelItems.filter(
          (item) => item.list_id !== listId
        );
        break;
      case ChannelContentActions.VIEW:
        const contentItem = find(newChannelItems, ["list_id", listId]);
        if (contentItem.type === RefType.FILE) {
          this.context.modal.openMediaDetail({ fileId: contentItem.data.id });
        } else if (contentItem.type === RefType.APP) {
          this.onShowAppconfigure(contentItem.data as AppInstance);
        } else if (contentItem.type === RefType.LINK) {
          this.context.modal.openLinkDetail(contentItem.data.id);
        } else if (contentItem.type === RefType.SITE) {
          // TODO - Open Site Detail Dialog
        } else if (contentItem.type === RefType.PLAYLIST) {
          this.props.history.push(
            `/playlists/${contentItem.id}?${FROM_CHANNEL_PAGE_QUERYSTRING}=${this.props.data.data?.channelById?.id}`
          );
        }
        break;
      default:
        break;
    }

    // call update if action isn't view
    if (action !== ChannelContentActions.VIEW) {
      this.setState({ channelItems: newChannelItems });
      this.onUpdateContentList(listId, newChannelItems, action, data);
    }
  };

  public handleChangeZoneDropdown = (
    event: React.SyntheticEvent<any>,
    data: Maybe<Scalars["JSON"]>
  ) => {
    const { value } = data;
    this.setState({
      channelItems: this.channelItemsBySelectedZone(value),
      selectedZone: value,
    });
  };

  public onDragStart = async () => {
    this.setState({ isDragging: true });
    this.cancelDebounceUpdate();
  };

  public onDragEnd = async (dropResult: DropResult) => {
    this.setState({ isDragging: false });

    if (!dropResult.destination) {
      return;
    }
    if (this.props.data.data?.channelById?.draft && this.state.selectedZone) {
      if (dropResult.destination.index !== dropResult.source.index) {
        const content = { ...this.props.data.data.channelById.draft.content };
        const zones: Maybe<Scalars["JSON"]> = { ...content.zones };
        const selectedZoneOption = this.state.selectedZone;
        let zoneContent = getZoneContentBySelectedZone(
          selectedZoneOption,
          (this.props.data.data.channelById.draft as unknown) as Channel
        );

        const source: DraggableLocation = dropResult.source;
        const destination: DraggableLocation = dropResult.destination;

        const parentContentItem = this.state.channelItems.filter(
          (item) => item.data.isReadOnly
        );
        const orderedChannelItems = this.state.channelItems.filter(
          (item) => !item.data.isReadOnly
        );
        if (source.droppableId === destination.droppableId) {
          // move playlist item
          const [removed] = orderedChannelItems.splice(
            dropResult.source.index,
            1
          );
          orderedChannelItems.splice(dropResult.destination.index, 0, removed);
        }

        const allChannelItems = [
          ...parentContentItem,
          ...orderedChannelItems,
        ].filter(Boolean);

        const updatedList: ChannelListContentItem[] = allChannelItems.map(
          (list) => {
            const listObj: ChannelListContentItem | undefined = find(
              zoneContent.list,
              {
                list_id: list.list_id,
              }
            ) as ChannelListContentItem;
            if (listObj) {
              return listObj;
            } else {
              return {
                content: { _ref: { type: list.type, id: list.id }, props: {} },
                list_id: list.list_id,
                rules: [],
              };
            }
          }
        );
        zoneContent = { ...zoneContent };
        zoneContent.list = updatedList;
        content.zones = this.updateSelectedZonesContent(zones, zoneContent);
        this.setState({
          channelItems: allChannelItems,
        });
        const channelInput: UpdateChannelMutationVariables = {
          input: {
            content,
            id: this.props.data.data.channelById.id,
          },
        };
        this.debounceUpdateChannel(this.updateChannel, channelInput);
        // debound to save lists to graph
      }
    }
  };

  public onCoverModalCallBack = (
    backgroundType: CoverBackgroundType,
    color: CoverColor
  ) => {
    const channelById = this.props.data.data?.channelById;

    if (channelById) {
      if (channelById.coverImageId) {
        // unsubscribe before update cover
        this.unsubscribeCoverImageFromLiveUpdate();
      }

      const channelCoverSettings = {
        ...channelById.coverData,
        color,
      };
      // TODO: Add uploaded image uuid to be save from the popup
      const channelCoverInput: UpdateChannelCoverMutationVariables = {
        input: {
          coverData: channelCoverSettings,
          id: channelById.id,
        },
      };
      this.props.updateChannelCover({
        optimisticResponse: {
          __typename: "Mutation",
          updateChannelCover: {
            __typename: "UpdateChannelCoverPayload",
            channel: {
              ...channelById,
              content: {
                ...channelById.content,
                props: {
                  ...channelById.content.props,
                  cover: channelCoverSettings,
                },
              },
            },
          },
        },
        variables: channelCoverInput,
      });
    }
  };

  public calculateRevertZoneData = (
    previousZonesContent: Partial<Channel>,
    zones: number
  ) => {
    const allZones = Object.entries(previousZonesContent);
    const remainingZones = allZones.filter(([key]) => {
      return +key.split("zone")[1] <= zones;
    });
    return Object.fromEntries(remainingZones);
  };

  public handleSelectLayout = async (
    id: string,
    totalZones: number,
    isRevert?: { isRevertToSingleZone: boolean },
    shouldCloseModel: boolean = true
  ) => {
    const channelById = this.props.data.data?.channelById;
    const zones = {};

    for (let i = 0; i < totalZones; i++) {
      zones["zone" + (i + 1)] = { list: [] };
    }

    if (channelById?.draft) {
      if (channelById?.layoutId) {
        let previousZonesContent = channelById.draft!.content.zones;
        if (isRevert) {
          previousZonesContent = this.calculateRevertZoneData(
            previousZonesContent,
            totalZones
          );
          if (isRevert.isRevertToSingleZone) {
            // reset all full_screen rule content to be false as it will be revert to have only 1 zone
            previousZonesContent = {
              ...previousZonesContent,
              ["zone1"]: {
                ...previousZonesContent["zone1"],
                list: (previousZonesContent["zone1"]?.list ?? []).map(
                  (contentList) => {
                    return {
                      ...contentList,
                      rules: contentList.rules.map((rule) => {
                        return { ...rule, full_screen: false };
                      }),
                    };
                  }
                ),
              },
            };
          }
        }
        const channelInput: UpdateChannelMutationVariables = {
          input: {
            content: {
              ...channelById.content,
              zones: { ...zones, ...previousZonesContent },
            },
            id: channelById.id,
          },
        };
        await this.props.updateChannel({
          variables: {
            input: {
              ...channelInput.input,
              layoutId: id,
            },
          },
        });
      }

      this.setState({
        channelItems: this.channelItemsBySelectedZone(DEFAULT_ZONE),
        selectedZone: DEFAULT_ZONE,
      });

      if (shouldCloseModel) {
        this.context.modal.closeNavigationControlModal();
      }
    }
  };

  public onCoverChange = () => {
    const channelById = this.props.data.data?.channelById;
    if (channelById?.content) {
      this.context.modal.openModal(
        <UpdateChannelCoverContainer
          channel={channelById as Channel}
          onBeforeChangingCover={this.unsubscribeCoverImageFromLiveUpdate}
          onCoverImageUpdated={this.subscribeCoverImageToLiveUpdate}
        />,
        <ChannelEditCoverTitle />,
        {
          opts: {
            className: "channel-cover-modal",
            size: ModalSize.MEDIUM,
            disableTitle: false,
          },
        }
      );
    }
  };

  public onSizingTypeChange = (e: SyntheticEvent, data) => {
    if (!this.props.data.data?.channelById) {
      return;
    }

    const channelById = this.props.data.data.channelById;
    const draft = (channelById.draft as unknown) as Channel;

    if (!draft) {
      return;
    }

    const { zones } = cloneDeep(this.getZonesReport(draft));
    const { selectedZone } = this.state;

    if (zones[selectedZone].props?.list) {
      delete zones[selectedZone].props["list"];
    }

    zones[selectedZone].props = {
      ...zones[selectedZone].props,
      sizing_type: {
        document: data.value,
        image: data.value,
        video: data.value,
      },
    };

    const channelInput = {
      content: {
        ...draft.content,
        zones: {
          ...zones,
        },
      },
      id: channelById.id,
    };
    this.debounceUpdateChannel(this.updateChannel, { input: channelInput });
  };

  public onThemeSelectedCallback = (selectedThemeID: string | null) => {
    const input: UpdateChannelThemeMutationVariables = {
      input: {
        id: this.props.match.params.channelId,
        themeId: selectedThemeID,
      },
    };

    this.updateTheme(input);
  };

  public updateTheme(input: UpdateChannelThemeMutationVariables) {
    if (this._isMounted) {
      this.setState({ isDrafting: true });
    }

    this.props
      .updateChannelTheme({
        variables: input,
      })
      .then(() => {
        if (this._isMounted) {
          this.setState({
            isDrafting: false,
          });
        }
      });
  }

  public playingScreenCallback = (
    screenId: string,
    action: ChannelPlayingScreenActions
  ) => {
    switch (action) {
      case ChannelPlayingScreenActions.NAVIGATE_SCREEN:
        this.props.history.push("/screens/" + screenId);
        break;
      default:
        break;
    }
  };

  public handleZoneClick = (zoneId: string) => {
    this.setState({
      channelItems: this.channelItemsBySelectedZone(zoneId),
      selectedZone: zoneId,
    });
  };

  public mergeLayoutToOneZone = async () => {
    const singleZoneLayout =
      ZoneLayoutNames[`common_layout_landscape_1_zone_type_a`];
    const { layoutsData } = this.props;
    if (layoutsData.layoutsBySpaceId) {
      const defaultLayout = layoutsData.layoutsBySpaceId.nodes.find(
        (layout) => !!layout && layout.name === singleZoneLayout.common_name
      );
      const { channelById } = this.props.data?.data!;
      if (
        defaultLayout &&
        defaultLayout.id &&
        channelById &&
        ((channelById.draft as unknown) as Channel)
      ) {
        const previousZonesContent = channelById.draft!.content.zones;
        const channelInput: UpdateChannelMutationVariables = {
          input: {
            content: {
              ...channelById.content,
              zones: {
                ...previousZonesContent,
              },
            },
            id: channelById.id,
          },
        };

        if (channelById && channelById.layoutId) {
          await this.props.updateChannel({
            variables: {
              input: {
                ...channelInput.input,
                layoutId: defaultLayout.id,
              },
            },
          });
        }
      }
    }
  };

  public handleDimensionChange = async (
    width: number,
    height: number,
    merge?: boolean
  ) => {
    if (merge) {
      this.mergeLayoutToOneZone();
    }

    if (this.props.data.data?.channelById) {
      if (width > PG_MAX_SAFE_SMALLINT) {
        width = PG_MAX_SAFE_SMALLINT;
      }

      if (height > PG_MAX_SAFE_SMALLINT) {
        height = PG_MAX_SAFE_SMALLINT;
      }

      const channelInput: UpdateChannelMutationVariables = {
        input: {
          height,
          id: this.props.data.data.channelById.id,
          width,
        },
      };
      this.debounceUpdateChannel(this.updateChannel, channelInput);
    }
  };

  public handleTransitionChange = (
    type: TransitionType,
    color: string,
    duration: number,
    direction: Direction | undefined
  ) => {
    if (this.props.data.data?.channelById) {
      const content = cloneDeep(
        this.props.data.data.channelById.draft?.content
      );

      if (!!content.zones[this.state.selectedZone]) {
        content.zones[this.state.selectedZone].props = {
          ...content.zones[this.state.selectedZone].props,
          transition: {
            type,
            color,
            duration,
            direction,
          },
        };
      } else {
        content.zones = {
          ...content.zones,
          [this.state.selectedZone]: {
            ...content.zones[this.state.selectedZone],
            props: {
              transition: {
                type,
                color,
                duration,
                direction,
              },
            },
          },
        };
      }

      const channelInput: UpdateChannelMutationVariables = {
        input: {
          id: this.props.data.data.channelById.id,
          content,
        },
      };

      this.debounceUpdateChannel(this.updateChannel, channelInput);
    }
  };

  public get isPreViewMode() {
    const data = this.props.data;
    const canUpdateChannel = this.context.currentPermissions.validateCurrentSpace(
      "channel",
      "update"
    );
    const canPublish = this.context.currentPermissions.validateCurrentSpace(
      "channel",
      "publish"
    );
    return (
      (!canUpdateChannel && !canPublish) ||
      !isOwner({
        context: this.context,
        spaceId: data.data?.channelById?.spaceId,
      })
    );
  }

  public renderPlayerPreview = () => {
    const { data, onEditChannelClick, onPreviewFullscreen } = this.props;
    const channelDraft = getChannelContentByOwner({
      context: this.context,
      channel: data.data?.channelById,
    });
    if (channelDraft && data.data?.channelById) {
      const orientation = getChannelOrientation(data.data?.channelById);
      const isChannelOwner = isOwner({
        context: this.context,
        spaceId: this.props.data.data?.channelById?.spaceId,
      });
      if (!this.isPreViewMode && !this.props.isFullScreen) {
        return (
          <PlayerPreview
            id={channelDraft.id}
            previewType={PreviewType.CHANNEL}
            isPageMode
            orientation={orientation}
            onFullScreenClick={onPreviewFullscreen}
            showFullScreenButton
            showToggleControlButton={false}
            presetWidth={channelDraft.width}
            presetHeight={channelDraft.height}
            onEditButonClick={onEditChannelClick}
            defaultSelectedZone={convertToChannelPlayerZone(
              this.state.selectedZone
            )}
            onZoneChange={this.onPlayerZoneChange}
            setRefreshMethod={(refreshMethod) =>
              (this._refreshPlayerData = refreshMethod)
            }
            isOwner={isChannelOwner}
          />
        );
      }
    }
    return null;
  };

  public cancelDebounceUpdate = () => {
    if (this.debounceUpdateChannelTimeout) {
      clearTimeout(this.debounceUpdateChannelTimeout);
      this.debounceUpdateChannelTimeout = null;
    }
  };
  public debounceUpdateChannel = (
    updateChannel: (
      channelInput: UpdateChannelMutationVariables
    ) => Promise<void>,
    channelInput: UpdateChannelMutationVariables
  ) => {
    this.setState({ isSaving: true });
    this.cancelDebounceUpdate();
    this.debounceUpdateChannelTimeout = setTimeout(
      () => updateChannel(channelInput),
      1000
    );
  };

  public cancelDebounceUpdateCustomDuration = () => {
    if (this.debounceUpdateCustomDuration) {
      clearTimeout(this.debounceUpdateCustomDuration);
      this.debounceUpdateCustomDuration = null;
    }
  };

  public onDurationFocus = (listId: string, isFocus: boolean) => {
    if (!isFocus) {
      this.cancelDebounceUpdateCustomDuration();
      this.debounceUpdateCustomDuration = setTimeout(() => {
        if (
          this.props.data.data?.channelById?.draft &&
          this.state.selectedZone
        ) {
          const content = {
            ...this.props.data.data?.channelById?.draft.content,
          };
          const zones: Maybe<Scalars["JSON"]> = { ...content.zones };
          const selectedZoneOption = this.state.selectedZone;
          let zoneContent = getZoneContentBySelectedZone(
            selectedZoneOption,
            this.props.data.data?.channelById?.draft
          );
          const updatedList: ChannelListContentItem[] = this.state.channelItems.map(
            (list) => {
              const listObj: ChannelListContentItem | undefined = find(
                zoneContent.list,
                {
                  list_id: list.list_id,
                }
              );
              const newListObj = { ...listObj } as ChannelListContentItem;
              if (newListObj && newListObj.list_id === listId) {
                const contentProps: Maybe<Scalars["JSON"]> = {
                  ...newListObj.content,
                };
                contentProps.props = { duration: this.state.newDuration };
                newListObj.content = contentProps;
                return newListObj;
              } else {
                return newListObj;
              }
            }
          );

          zoneContent = {
            ...zoneContent,
            list: updatedList,
          };

          content.zones = this.updateSelectedZonesContent(zones, zoneContent);
          const channelInput: UpdateChannelMutationVariables = {
            input: {
              content,
              id: this.props.data.data.channelById.id,
            },
          };
          this.updateChannel(channelInput);
        }
      }, DEBOUNCE_TIMEOUT_MS);
    }
  };

  public onCleanupChannel = (
    shouldDisplayConfirmModal: boolean
  ) => async () => {
    const data = this.props.data?.data;
    if (!data) return;
    const performCleanup = () => {
      const channelInput: UpdateChannelMutationVariables = {
        input: {
          content: getValidChannelContent(data).content,
          id: data.channelById?.id,
        },
      };
      this.debounceUpdateChannel(this.updateChannel, channelInput);
    };
    if (shouldDisplayConfirmModal) {
      const { confirm } = await this.context.modal.confirm(
        <FormattedMessage
          id="ui_component.common.label.confirm_cleanup"
          defaultMessage="You are removing expired content from all zones - remove anyway?"
        />,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.delete"
              defaultMessage="Delete"
            />
          ),
          isDanger: true,
        }
      );
      if (confirm === true) {
        performCleanup();
      }
    } else {
      performCleanup();
    }
  };

  public renderHeader() {
    const {
      isHidePublishAnimation,
      isPublishing,
      isDrafting,
      isSaving,
    } = this.state;
    const {
      isFullScreen,
      isNew,
      onEditChannelClick,
      onPreviewChannelFromDetailPageClick,
      onPreviewFullscreen,
      onBackToListPage,
      mode,
    } = this.props;
    const channelById = this.props.data.data?.channelById;
    const {
      shouldDisplayCleanupButton,
      doesNonMainZoneContainInvalidItems,
    } = validateChannelByIdQuery(this.props.data.data);
    return (
      <ChannelDetailHeader
        createdUserId={channelById?.createdBy}
        updatedUserId={channelById?.updatedBy}
        publishUserId={channelById?.publishedBy}
        channelById={channelById as Channel}
        refetchChannel={() =>
          this.props.data?.refetch({ id: this.props.match.params.channelId })
        }
        isHidePublishAnimation={isHidePublishAnimation}
        isLoading={isPublishing || isSaving}
        isDrafting={isDrafting}
        isFullScreen={isFullScreen}
        isNew={isNew}
        mode={mode}
        onEditChannelClick={onEditChannelClick}
        onPreviewChannelClick={onPreviewChannelFromDetailPageClick}
        onPreviewFullscreen={onPreviewFullscreen}
        onBackToListPage={onBackToListPage}
        handlePublishChannel={this.handlePublishChannel}
        handleRevertChange={this.handleRevertChange}
        refreshPlayerData={this._refreshPlayerData}
        shouldDisplayCleanupButton={shouldDisplayCleanupButton}
        onCleanupChannel={this.onCleanupChannel(
          doesNonMainZoneContainInvalidItems
        )}
      />
    );
  }

  public renderPreviewMode = () => {
    const { data } = this.props;
    if (!data?.data?.channelById) {
      return null;
    }

    const canUpdateChannel = this.context.currentPermissions.validateCurrentSpace(
      "channel",
      "update"
    );
    const canPublish = this.context.currentPermissions.validateCurrentSpace(
      "channel",
      "publish"
    );
    const isChannelOwner = isOwner({
      context: this.context,
      spaceId: data.data.channelById.spaceId,
    });
    return (
      <Styled
        isEditableSharedChannel={!!data.data.channelById.childOf}
        className="channel-detail"
      >
        <Main>
          {(canUpdateChannel || canPublish) &&
            isChannelOwner &&
            this.renderHeader()}
          {this.renderPlayerPreview()}
        </Main>
      </Styled>
    );
  };

  public renderEditMode = () => {
    const { data } = this.props;
    if (!data?.data?.channelById) {
      return null;
    }
    const { selectedZone, channelItems } = this.state;
    const zoneOptions = this.getLayoutDropdownOptions();
    const { currentOrg } = this.context;
    const { channelById } = data.data;
    const draft = channelById.draft;
    const selectedZoneContent =
      this.state.selectedZone && channelById
        ? getZoneContentBySelectedZone(
            this.state.selectedZone,
            draft as Channel
          )
        : null;
    const { zones } = this.getZonesReport(
      (channelById.draft! as unknown) as Channel
    );
    const cover = channelById.coverData;
    const isChannelOwner =
      this.context.user.settings.spaceId === channelById.spaceId;
    const fileByCoverImageId = channelById.fileByCoverImageId as File;
    const channelWidth: number = draft && draft.width ? draft.width : 0;
    const channelHeight: number = draft && draft.height ? draft.height : 0;
    const canUpdateChannel = this.context.currentPermissions.validateCurrentSpace(
      "channel",
      "update"
    );

    const isEditableSharedChannel = !!channelById.childOf;

    const isImageCover = !!fileByCoverImageId;
    const coverType = isImageCover
      ? CoverBackgroundType.TYPE_IMAGE
      : CoverBackgroundType.TYPE_COLOR;
    const coverColor = !isImageCover
      ? { top: cover.color.top, bottom: cover.color.bottom }
      : null;
    const coverImage = isImageCover
      ? {
          url: generateImgixURL(
            fileByCoverImageId,
            165,
            220,
            this.context.secureMediaPolicy
          ),
        }
      : null;

    return (
      <>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        >
          <Styled
            isEditableSharedChannel={isEditableSharedChannel}
            className="channel-detail"
          >
            <Main>
              {this.renderHeader()}
              <PageContainer className="wrapper">
                <Container sidebar={true} className="container">
                  <div className="channel-content-options">
                    {selectedZone && zoneOptions.length > 1 && (
                      <Dropdown
                        id="zone-dropdown"
                        value={selectedZone}
                        options={zoneOptions}
                        onChange={this.handleChangeZoneDropdown}
                        selection
                      />
                    )}

                    {!isEditableSharedChannel &&
                      canUpdateChannel &&
                      channelItems.length !== 0 &&
                      this.state.selectedZone !== "zone_hidden" && (
                        <Dropdown
                          className="fit-fill"
                          disabled={
                            false /* todo: disable when mutation or loading in process*/
                          }
                          selection
                          data-testid="fitfill-button"
                          value={
                            selectedZoneContent &&
                            selectedZoneContent.props &&
                            selectedZoneContent.props.sizing_type &&
                            (selectedZoneContent.props
                              .sizing_type as SizingTypeRules).image
                              ? (selectedZoneContent.props
                                  .sizing_type as SizingTypeRules).image
                              : defaultSizingType
                          }
                          options={sizingTypeDropdownOptions}
                          onChange={this.onSizingTypeChange}
                        />
                      )}

                    {canUpdateChannel && this.state.channelItems.length !== 0 && (
                      <Button
                        data-testid="add-content-button"
                        className={`btn-add-content`}
                        onClick={this.showMediaPicker}
                        disabled={!isChannelOwner}
                      >
                        <Icon name="plus-circle" />
                        <FormattedMessage
                          id="channels.channel_content_empty.add_content"
                          defaultMessage="Add Content"
                        />
                      </Button>
                    )}
                  </div>
                  <ChannelContentsList
                    defaultTimeValue={this.state.defaultTimeValue}
                    disabled={!isChannelOwner}
                    channelItems={this.state.channelItems}
                    isRevertChange={this.state.isRevertChange}
                    totalZones={Object.keys(zones).length}
                    selectedZone={this.state.selectedZone}
                    onChannelItemChange={this.onChannelItemChange}
                    onContentPick={this.showMediaPicker}
                    onPreviewClick={this.onPreviewClick}
                    onDurationFocus={this.onDurationFocus}
                    isEditableSharedChannel={isEditableSharedChannel}
                    parentChannelByChildOf={channelById.parentChannelByChildOf}
                  />
                </Container>
                <SidebarRight
                  className="sidebar-right sidebar-channel"
                  data-testid="sidebar-right"
                >
                  <Tab
                    className="tabs"
                    defaultActiveIndex={0}
                    menu={{ secondary: true, pointing: true }}
                    center={true}
                    panes={[
                      {
                        menuItem: this.context.intl.formatMessage({
                          id: "common.layout",
                          defaultMessage: "Layout",
                        }),
                        render: () => (
                          <div className="wrapper">
                            <LayoutSetting
                              layout={draft!.layoutByChannel as any}
                              zones={Object.keys(zones).length}
                              hiddenZoneValue={
                                draft && (draft.hiddenZone as any)
                              }
                              onToggleHiddenZone={this.handleLayoutHiddenToggle}
                              onToggleTransition={() =>
                                this.handleTransitionChange
                              }
                              onTransitionChange={this.handleTransitionChange}
                              onLayoutChange={this.handleLayoutChange}
                              onZoneClick={this.handleZoneClick}
                              onDimensionChange={this.handleDimensionChange}
                              selectedZone={this.state.selectedZone}
                              channelHeight={channelHeight}
                              channelWidth={channelWidth}
                              disabled={!isChannelOwner}
                              zoneTransition={
                                this.context.shouldShowFeature(
                                  FEATURE_FLAGS_ENUM.CHANNEL_TRANSITION
                                ) &&
                                !!draft?.content?.zones[
                                  this.state.selectedZone
                                ] &&
                                !!draft?.content?.zones[this.state.selectedZone]
                                  .props?.transition
                                  ? draft?.content.zones[
                                      this.state.selectedZone
                                    ].props.transition
                                  : undefined
                              }
                              isEditableSharedChannel={isEditableSharedChannel}
                            />
                          </div>
                        ),
                      },
                      {
                        menuItem: this.context.intl.formatMessage({
                          id: "common.setting",
                          defaultMessage: "Settings",
                        }),
                        render: () => (
                          <div className="wrapper">
                            <div className="channel-cover component">
                              <ChannelEditCoverTitle showDescription />
                              <div className="panel">
                                <CoverThumbnail
                                  type={coverType}
                                  color={coverColor}
                                  image={coverImage}
                                  onClick={this.onCoverChange}
                                  editIcon
                                  disabled={!canUpdateChannel}
                                />
                              </div>
                            </div>
                            <ThemePane
                              selectedThemeId={draft!.themeId}
                              onThemeSelectedCallback={
                                this.onThemeSelectedCallback
                              }
                              defaultThemeId={
                                currentOrg && currentOrg.defaultChannelThemeId
                              }
                            />
                          </div>
                        ),
                      },
                    ]}
                    totaltab={2}
                  />
                </SidebarRight>
              </PageContainer>
            </Main>
          </Styled>
        </DragDropContext>
      </>
    );
  };

  public render() {
    if (
      !this.context.currentPermissions.validateCurrentSpace("channel", "read")
    ) {
      this.context.redirectToDefaultPath();
      return <Forbidden />;
    }
    const { mode, data } = this.props;

    if (!data?.data?.channelById) {
      return <LoaderBar />;
    }

    return mode === ChannelDetailMode.Preview
      ? this.renderPreviewMode()
      : this.renderEditMode();
  }
}

export default withData(ChannelDetail) as React.ComponentType<Prop>;
