import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const StyledModalContent = styled.div`
  height: calc(100% - 65px);
  .media-content {
    &.playlist {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .content-header {
        display: flex;
        gap: 8px;
        > .search {
          width: calc(100% - 60px);
        }
        .search-checkbox {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .container {
        padding: 0 16px;
        flex: 1 0 0;
        overflow: auto;
        .layout-list {
        }
      }
    }
  }
  .close-button {
    display: flex;
    padding: 25px 20px 0 20px;
    justify-content: flex-end;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 40px 40px;
    border-radius: 0 0 ${Theme.default.borderRadiusValue};
    &.availability {
      padding: 20px 30px 40px 30px;
      background: ${Theme.color.nearWhite};
      &.bulk-update {
        padding-bottom: 20px;
      }
    }
    .sub-text {
      font-size: 0.875em;
      margin: 0 0 10px 0;
      line-height: 1.4;
    }
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .title {
        font-size: 1.1em;
        font-weight: 700;
      }
      .sub-title {
        font-size: 0.875em;
        color: ${Theme.color.darkGrey};
      }
    }

    .enable-app-availability-toggle {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 16px;
      justify-content: space-between;
      width: 100%;
      .text {
        span {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          + span {
            font-size: 0.875em;
            color: ${Theme.color.grey60};
          }
        }
      }
    }

    .time-editor {
      background-color: ${Theme.color.grey20};
      border: 1px solid ${Theme.color.grey30};
      border-radius: ${Theme.default.borderRadiusValue};
      width: 100%;
      padding: 0 20px;
      .expiry-description {
        line-height: 1.4;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        span {
          font-size: 0.875em;
          color: ${Theme.color.grey70};
        }
      }
      .available {
        margin: 10px 0;
      }

      .expire {
      }

      .sub-text {
        display: flex;
        justify-content: space-between;
        .red {
          color: ${Theme.color.red};
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
          &.disabled {
            color: ${Theme.color.grey40};
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .button {
      margin-top: 10px;
    }

    .tags {
      padding: 10px 0 20px;
      width: 80%;
    }
  }
`;
