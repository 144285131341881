import * as React from "react";
import { Styled } from "./styles";

export interface PageContainerProps {
  children?: React.ReactNode;
  className?: string;
}

const PageContainer = (props: PageContainerProps) => {
  const { className, children } = props;

  return <Styled className={className}>{children}</Styled>;
};

export default PageContainer;
