import { Icon } from "@screencloud/screencloud-ui-components";
import { Component } from "react";
import { StyledThumbnail } from "./styles";

export interface ChannelCoverModalProps {
  type: CoverBackgroundType;
  image: CoverImage | null;
  color: CoverColor | null;
  editIcon?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export interface CoverColor {
  name?: string;
  top: string;
  bottom: string;
}

export interface CoverImage {
  name?: string;
  url: string;
  id?: string;
}

export enum CoverBackgroundType {
  TYPE_IMAGE = "image",
  TYPE_COLOR = "color",
}

class ChannelCover extends Component<ChannelCoverModalProps> {
  constructor(props: ChannelCoverModalProps) {
    super(props);
  }

  public render(): JSX.Element {
    const { disabled, type, image, color, editIcon, onClick } = this.props;
    return (
      <StyledThumbnail
        onClick={!disabled ? onClick : undefined}
        type={type}
        image={image}
        color={color}
        customWidth={editIcon && 150}
      >
        {editIcon && (
          <span className="edit">
            <Icon name="edit" />
          </span>
        )}

        <span className="overlay">
          <span className="box" />
        </span>
      </StyledThumbnail>
    );
  }
}

export default ChannelCover;
