import { FormattedMessage } from "react-intl";

import {
  CreateDuplicateJobMutationVariables,
  DuplicateJobType,
  GetImportJobsDocument,
  SnapshotType,
  Space,
  useCreateDuplicateJobMutation,
} from "src/types.g";
import { useAppContext } from "src/hooks/useAppContext";
import { getDuplicateSpaceName } from "./utils";
import { useOpenDuplicationModal } from "./useOpenDuplicationModal";

export interface UseDuplicateSpace {
  duplicateSpace: (
    space: Pick<Space, "id" | "name">,
    callback?: () => void
  ) => Promise<void>;
}

export function useDuplicateSpace(): UseDuplicateSpace {
  const context = useAppContext();
  const [createDuplicateJob] = useCreateDuplicateJobMutation();
  const { openInprogressModalDetail } = useOpenDuplicationModal();
  const duplicateSpace = async (
    space: Pick<Space, "id" | "name">,
    callback?: () => void
  ) => {
    const { confirm } = await context.modal.confirm(
      <>
        <h2>
          <FormattedMessage
            id="ui_component.confirm.duplicate_space"
            defaultMessage="Duplicate {spacename}"
            values={{ spacename: space.name }}
          />
        </h2>
        <p>
          <FormattedMessage
            id="ui_component.confirm.duplicate_space_confirm_message"
            defaultMessage="This space, including all of its contents and settings, will be duplicated. Dashboards will not be included."
            values={{ name: <strong>{space.name}</strong> }}
          />
        </p>
      </>,
      {
        confirm: (
          <FormattedMessage
            id="ui_component.common.label.continue"
            defaultMessage="Continue"
          />
        ),
        cancel: (
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        ),
      }
    );
    if (confirm) {
      const snapshotInput: CreateDuplicateJobMutationVariables = {
        input: {
          jobType: DuplicateJobType.SnapshotAndImport,
          sourceSpaceId: space.id,
          snapshotType: SnapshotType.Space,
          isPublicSnapshot: false,
          targetNewName: getDuplicateSpaceName(space.name),
        },
      };
      createDuplicateJob({
        variables: snapshotInput,
        refetchQueries: [
          {
            query: GetImportJobsDocument,
            variables: {
              snapshotType: SnapshotType.Space,
              sourceSpaceId: null,
            },
          },
        ],
      });
      openInprogressModalDetail(callback);
      context.modal.closeNavigationControlModal();
    }
  };

  return { duplicateSpace };
}
