import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import {
  ChannelByIdProps,
  CreateFileMutationFn,
  UpdateChannelCoverMutationFn,
  withCreateFile,
  withUpdateChannelCover,
} from "../../types.g";
import { compose } from "../../utils/compose";

export interface ApolloProps extends WithApolloClient<{}>, ChannelByIdProps {
  updateChannelCover: UpdateChannelCoverMutationFn;
  createFile: CreateFileMutationFn;
}

export const withData = compose(
  withApollo,
  withUpdateChannelCover({
    name: "updateChannelCover",
  }),
  withCreateFile({
    name: "createFile",
  })
);
