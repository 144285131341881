import { Typenames } from "../../../constants/constants";
import {
  DeleteFileByIdsMutationVariables,
  File,
  useDeleteFileByIdsMutation,
} from "../../../types.g";
import { useOnFilesDeleted } from "src/domains/file/events/useOnFilesDeleted";
import { getOnCastableDeletedRefetchQueries } from "src/domains/castableEntity/events/onCastableEntityDeleted";

export interface UseDeleteFiles {
  deleteFiles: (
    file: Pick<File, "id" | "folderId" | "castedScreenByFileId">[]
  ) => Promise<any>;
}

export function useDeleteFiles(): UseDeleteFiles {
  const [deleteFileByIds] = useDeleteFileByIdsMutation();
  const { onFilesDeleted } = useOnFilesDeleted();

  const deleteFiles = async (
    files: Pick<File, "id" | "folderId" | "castedScreenByFileId">[]
  ) => {
    if (files?.length === 0) {
      return null;
    }

    const fileIds = files.map((item) => item.id);
    const deleteFileByIdsMutationVariables: DeleteFileByIdsMutationVariables = {
      input: {
        fileIds,
      },
    };

    return deleteFileByIds({
      optimisticResponse: {
        __typename: Typenames.Mutation,
        deleteFileByIds: {
          __typename: Typenames.DeleteFileByIdsPayload,
          files,
        } as any,
      },
      update: (_, deleteFileByIdsMutationResult) => {
        const files =
          deleteFileByIdsMutationResult.data?.deleteFileByIds?.files;
        if (files) {
          onFilesDeleted({ files });
        }
      },
      variables: deleteFileByIdsMutationVariables,
      refetchQueries: getOnCastableDeletedRefetchQueries(),
    });
  };

  return { deleteFiles };
}
