import { Icon } from "@screencloud/screencloud-ui-components";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { File, Link, LinkType, Maybe } from "../../types.g";

import { EntityType } from "@screencloud/signage-firestore-client";
import { generateImgixThumbnail } from "../../helpers/mediaHelper";
import { subscribeToDocumentUpdates } from "../../state/liveUpdateManager";
import DateTime from "../DateTime";

export enum LinkContentPickerItemCallBackActionsEnum {
  REFETCH = "REFECTH",
}

export type LinkContentPickerItemCallBackActions = LinkContentPickerItemCallBackActionsEnum.REFETCH;
export interface LinkContentPickerItemProps {
  callBack: (action: LinkContentPickerItemCallBackActions) => void;
  link: Maybe<Partial<Link>>;
  secureMediaPolicy: string | undefined;
}

export interface LinkContentPickerItemState {}

class LinkContentPickerItem extends Component<
  LinkContentPickerItemProps,
  LinkContentPickerItemState
> {
  private unsubscribeLiveUpdateFn?: () => void;

  constructor(props: LinkContentPickerItemProps) {
    super(props);
  }

  public componentDidMount() {
    this.subscribeToLiveUpdate();
  }

  public componentDidUpdate(props, state) {
    this.subscribeToLiveUpdate();
  }

  public componentWillUnmount() {
    this.unsubscribeFromLiveUpdate();
  }

  public subscribeToLiveUpdate() {
    this.unsubscribeFromLiveUpdate();

    if (
      this.props.link &&
      this.props.link.fileByFileId &&
      this.props.link.fileByFileId.id
    ) {
      this.unsubscribeLiveUpdateFn = subscribeToDocumentUpdates(
        this.props.link.fileByFileId.orgId || "",
        EntityType.FILE,
        this.props.link.fileByFileId.id,
        () => {
          // todo comback later to remove setTimeout need to check for file processing trigger function is doing the correct seq
          this.props.callBack(LinkContentPickerItemCallBackActionsEnum.REFETCH);
        },
        true
      );
    }
  }

  public unsubscribeFromLiveUpdate() {
    if (this.unsubscribeLiveUpdateFn) {
      this.unsubscribeLiveUpdateFn();
      this.unsubscribeLiveUpdateFn = undefined;
    }
  }

  public render(): JSX.Element | any {
    return (
      <>
        <div className="media-core">
          <div className="media-alpha">
            <div className="thumbnail-preview">
              <div className="thumbnail">
                <div className="wrapper">
                  <img
                    src={
                      (this.props.link &&
                        this.props.link.fileByFileId &&
                        generateImgixThumbnail(
                          this.props.link.fileByFileId as File,
                          this.props.secureMediaPolicy,
                          true
                        )) ||
                      ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="media-title">
              <h3>{this.props.link && this.props.link.name}</h3>
              <div>
                <span className="media-item__date">
                  <FormattedMessage
                    id="common.text.created_on"
                    defaultMessage="Created on"
                  />{" "}
                  <DateTime
                    value={this.props.link && this.props.link.createdAt}
                  />
                </span>
                <span className="media-item__url">
                  {this.props.link && this.props.link.url}
                </span>
              </div>
            </div>
          </div>

          <div className="media-starred">
            <Icon
              name={this.props.link && this.props.link.isFavorite ? "star" : ""}
            />
          </div>

          <div className="media-type">
            {this.props.link &&
              this.props.link.linkType === LinkType.Internal && (
                <FormattedMessage
                  id="ui_component.link.type_internal_website"
                  defaultMessage="Internal Website"
                />
              )}
            {this.props.link && this.props.link.linkType === LinkType.Cloud && (
              <FormattedMessage
                id="ui_component.link.type_cloud_render"
                defaultMessage="Cloud Render"
              />
            )}
            {this.props.link &&
              this.props.link.linkType === LinkType.Standard && (
                <FormattedMessage
                  id="ui_component.link.type_website"
                  defaultMessage="Website"
                />
              )}
          </div>

          <div className="media-tags">{/* add tags here */}</div>
        </div>
        <div className="media-checked">
          <Icon name="checked-circle" />
        </div>
      </>
    );
  }
}

export default LinkContentPickerItem;
