import styled from "styled-components";
import { Theme } from "@screencloud/screencloud-ui-components";

export const Styled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;

  width: 100%;
  height: 60px;

  border-top: 1px solid ${Theme.color.grey40};

  .avatar {
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
    border-radius: 40px;
  }

  .disabled {
    opacity: 50%;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    flex-grow: 1;
    justify-content: center;
    width: 100%;

    .email {
      width: 144px;
      height: 20px;
      color: ${Theme.color.grey70};
    }
  }

  .actions {
    display: contents;
  }

  .checkbox {
    i {
      background-color: ${Theme.color.base};
    }
    :hover {
      i {
        background-color: ${Theme.color.base};
      }
    }
  }
`;
