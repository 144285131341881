import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { isOrgCreatedMoreThan120Days } from "../GetStartedKCBot/utils";
import { AppContextType } from "src/AppContextProvider/type";

export const shouldHideGetStartedKCBot = (context: AppContextType) => {
  return (
    isOrgCreatedMoreThan120Days(context.currentOrg?.createdAt) ||
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.RESELLER) ||
    !context.shouldShowFeature(FEATURE_FLAGS_ENUM.KC_BOT)
  );
};
