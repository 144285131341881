import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { FEATURE_FLAGS_ENUM } from "../../../constants/featureFlag";
import { useAppContext } from "../../../hooks/useAppContext";
import { Styled } from "./styles";
import { isUuid } from "@screencloud/uuid";
import { useCustomer } from "../../../billinglatest/hooks/useCustomer";

interface Nav {
  text: string | React.ReactNode;
  route: string;
  isVisible: boolean;
  target?: string;
}
interface AdminNavProps {
  customTabs?: Nav[];
  className?: string;
}

export const AdminNav = (props: AdminNavProps) => {
  const context = useAppContext();
  const displayCustomTabs = (props.customTabs?.length ?? 0) > 0;
  const customer = useCustomer();
  // Only get customer if the main Admin Nav is showing
  // The ability to hide the billing tab depends on this
  React.useEffect(() => {
    const fetchCustomer = async () => {
      try {
        await customer.fetch();
      } catch (error) {
        console.error(
          "There was a problem getting your customer details. Please try again."
        );
      }
    };
    if (!displayCustomTabs) {
      fetchCustomer();
    }
  }, [displayCustomTabs]);

  const getNav = (nav: Nav) => {
    const location = useLocation();
    const route = location.pathname.split("/")[1];
    const subRoute = location.pathname.split("/")[2];
    let isSelected = route === nav.route.split("/")[1];

    const linkText = typeof nav.text === "string" ? nav.text : "";

    if (
      displayCustomTabs &&
      subRoute !== "" &&
      !isUuid(subRoute) &&
      route !== "screens-manager" &&
      route !== "usb-provisioning"
    ) {
      isSelected = subRoute === nav.route.split("/")[2];
    }

    if (route === "playback-logs" && nav.route === "/proof-of-play") {
      isSelected = true;
    }

    // External link
    if (nav.route.startsWith("http")) {
      return (
        <li className="items-item" key={nav.route}>
          <a
            href={nav.route}
            className={`items-link ${isSelected ? "active" : ""}`}
            data-testid={`item-${nav.route.replace(/ /g, "-")}`}
            target={nav.target ?? ""}
          >
            {nav.text}
          </a>
        </li>
      );
    }

    // Internal link
    return (
      <li className="items-item" key={linkText}>
        <Link
          to={nav.route}
          className={`items-link ${isSelected ? "active" : ""}`}
          data-testid={`item-${linkText.replace(/ /g, "-")}`}
        >
          {/* Removes whitespace from FormattedMessage ID to make sure ID is valid*/}
          {/* Example: 'groups & roles' will become 'groups&roles' */}
          <FormattedMessage
            id={`admin.${linkText.replace(/ /g, "")}`}
            defaultMessage={linkText}
          />
        </Link>
      </li>
    );
  };

  const renderNav = () => {
    const shouldOrgTabVisible =
      context.user.isOwner ||
      context.currentPermissions.validateOrg("org", "brand") ||
      context.currentPermissions.validateOrg("org", "features") ||
      context.currentPermissions.validateOrg("org", "update");

    const shoudBillingTabVisible =
      context.currentPermissions.validateAnySpace("billing", "manage") &&
      !context.currentOrg?.isDraft &&
      !customer.get().partner;
    const shouldGroupsAndRolesTabVisible =
      context.currentPermissions.validateOrg("group", "read") &&
      context.shouldShowFeature(FEATURE_FLAGS_ENUM.CUSTOM_PERMISSION_SETS);
    const shouldCredentialsTabVisible =
      context.currentPermissions.validateOrg("credentials" as any, "read") &&
      context.shouldShowFeature(FEATURE_FLAGS_ENUM.CREDENTIALS_SETTINGS);
    const shouldThemeTabVisible =
      context.currentPermissions.validateOrg("theme", "read") &&
      (context.currentPermissions.validateOrg("theme", "update") ||
        context.currentPermissions.validateOrg("theme", "create") ||
        context.currentPermissions.validateOrg("theme", "delete"));
    const shouldDeveloperTabVisible =
      context.currentPermissions.validateOrg("token", "read") &&
      (context.currentPermissions.validateOrg("token", "update") ||
        context.currentPermissions.validateOrg("token", "create"));

    const shouldGroupTabVisible =
      context.currentPermissions.validateOrg("group", "read") &&
      (context.currentPermissions.validateOrg("group", "create") ||
        context.currentPermissions.validateOrg("group", "delete") ||
        context.currentPermissions.validateOrg("group", "update") ||
        context.currentPermissions.validateOrg("group", "permissions") ||
        context.currentPermissions.validateOrg("group", "members"));

    const shouldSpaceTabVisible =
      context.currentPermissions.validateAnySpace("space", "read") &&
      (context.currentPermissions.validateAnySpace("space", "create") ||
        context.currentPermissions.validateAnySpace("space", "update") ||
        context.currentPermissions.validateAnySpace("space", "delete") ||
        context.currentPermissions.validateAnySpace("space", "permissions"));
    let navs: JSX.Element[] = [];
    if (displayCustomTabs) {
      navs =
        props.customTabs
          ?.filter((nav) => nav.isVisible)
          .map((nav) => getNav(nav)) ?? [];
    } else {
      navs =
        [
          {
            text: "account",
            route: "/account",
            isVisible: true,
          },
          {
            text: "organization",
            route: "/organization",
            isVisible: shouldOrgTabVisible,
          },
          {
            text: "look & feel",
            route: "/themes",
            isVisible: shouldThemeTabVisible,
          },
          {
            text: "billing",
            route: context.hasSbbFlag() ? "/billing" : "/billing/latest",
            isVisible: shoudBillingTabVisible,
          },
          {
            text: "people",
            route: "/people",
            isVisible: context.currentPermissions.validateOrg("user", "read"),
          },
          {
            text: shouldGroupsAndRolesTabVisible ? "groups & roles" : "groups",
            route: "/groups",
            isVisible: shouldGroupTabVisible,
          },
          {
            text: "spaces",
            route: "/spaces",
            isVisible: shouldSpaceTabVisible,
          },
          {
            text: "developer",
            route: "/developer",
            isVisible: shouldDeveloperTabVisible,
          },
          {
            text: "credentials",
            route: "/credentials",
            isVisible: shouldCredentialsTabVisible,
          },
          {
            text: "proof of play",
            route: "/proof-of-play",
            isVisible: context.currentPermissions.validateAnySpace(
              "playback_log",
              "read"
            ),
          },
          {
            text: "audit logs",
            route: "/logs",
            isVisible: context.currentPermissions.validateOrg("org", "audit"),
          },
        ]
          .filter((nav) => nav.isVisible)
          .map((nav) => getNav(nav)) ?? [];
    }

    return navs;
  };

  const nav = renderNav();
  if (nav.length < 2) {
    return null;
  }
  return (
    <Styled className={props.className} isCustomTabs={displayCustomTabs}>
      <div className="wrapper">
        <ul className="items">{nav}</ul>
      </div>
    </Styled>
  );
};

export default AdminNav as React.ComponentType<AdminNavProps>;
