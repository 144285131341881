import styled from "styled-components";

export const StyledReplaceContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;

  .find-and-replace-header {
    height: 50px;
    padding: 5px 10px 5px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--color-grey40);
    gap: 10px;
  }

  .main {
    display: flex;
    flex-direction: column;
    height: calc(100% - 115px);
  }

  .preview-section {
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1px;
    background: var(--grey-80, #474749);

    .block {
      display: flex;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      position: relative;
      height: 176px;
      background: var(--grey-90, #242424);
      box-sizing: content-box;

      .label {
        position: absolute;
        left: 20px;
        top: 10px;

        color: var(--grey-50, var(--grey-50, #ccc));
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }

      .media-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .media-content {
          width: 100%;
          height: 100%;
          max-height: 74%;
          display: inline-block;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          transition: 0.3s;
        }

        .video-preview {
          /* width: 80%; */
          object-fit: contain;
          height: 100%;
        }

        .document {
          left: 50%;
          transform: translateX(-50%);
          position: relative;
        }

        img {
          width: auto;
          height: 132px;
        }
      }

      &.replacement-file {
        .media-preview {
          > .button {
            position: absolute;
            box-sizing: border-box;
            left: calc(50% - 77px);
            top: calc(50% - 15px);
            width: 154px;
            z-index: 1;
          }

          > div,
          > img,
          > video {
            + .button {
              opacity: 0;
            }
          }
        }

        :hover {
          .media-preview {
            > div,
            > img,
            > video {
              opacity: 0.6;
            }
            > .button {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .assignment-section {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    overflow: auto;
    flex: 1 0 0;
  }

  .assignment-section p {
    color: var(--grey-100, var(--grey-100, #19191b));

    /* Body/16 */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }

  .assignment-section .group .group-head {
    display: flex;
    padding: 0px 8px 0px 16px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--grey-40, #ddd);
    background: var(--white-100, #fff);
  }

  .assignment-section .group {
    width: 100%;
    display: flex;
    padding-bottom: 8px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--grey-40, #ddd);
  }

  .assignment-section .group .group-head i {
    width: 16px;
    height: 16px;
    background: var(--grey-100, var(--grey-100, #19191b));
  }

  .assignment-section .group .group-head h3 {
    flex: 1 0 0;
    color: var(--grey-100, var(--grey-100, #19191b));
    /* Body/14B */
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    margin: 0;
  }

  .assignment-section .group .list {
    width: 100%;
  }

  .assignment-section .group .list .list-item {
    display: flex;
    padding: 2px 8px 2px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 16px;
    cursor: pointer;
    border-bottom: 1px solid var(--grey-40, #ddd);
  }

  .assignment-section .group .list-item:hover {
    background: var(--grey-30, #eee);
  }

  .assignment-section .group .list .list-item .thumbnail {
    display: flex;
    width: 24px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .assignment-section .group .list .list-item .playlist-thumbnail {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    i {
      width: 16px;
      height: 16px;
      background: #ffffff;
    }
  }

  .assignment-section .group .list .list-item p {
    overflow: hidden;
    color: var(--grey-100, var(--grey-100, #19191b));
    text-overflow: ellipsis;
    whitespace: nowrap;

    height: 24px;
    flex: 1 0 0;

    /* Body/16 */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }

  .md-footer {
    display: flex;
    justify-content: flex-end !important;

    button {
      margin-left: 10px;
    }
  }
`;
