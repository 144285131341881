import {
  Checkbox,
  DataTable,
  DurationInput,
} from "@screencloud/screencloud-ui-components";
import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { DEBOUNCE_TIMEOUT_MS } from "src/constants/constants";
import { Scalars } from "../../types.g";
import { Styled } from "./styles";

export interface LinkAdVanceSettingsPayload {
  cacheBusting?: boolean;
  autoReload?: boolean;
  autoReloadDurationMs?: number;
  params?: Scalars["JSON"];
}
interface LinkAdvanceSettingsProps {
  callBack?: (data: LinkAdVanceSettingsPayload) => void;
  url: string;
  cacheBusting?: boolean | null;
  autoReload?: boolean | null;
  autoReloadDurationMs?: number | null;
  params?: Scalars["JSON"] | null;
}

interface LinkAdvanceSettingsState {
  cacheBusting: boolean;
  autoReload: boolean;
  autoReloadDurationMs: number;
  params: Scalars["JSON"];
  addParamEnable: boolean;
}

export const LinkAdvanceSettings = (props: LinkAdvanceSettingsProps) => {
  const isMountRef = useRef(true);
  const [addParamEnable, setAddParamEnable] = useState<
    LinkAdvanceSettingsState["addParamEnable"]
  >(props.params && !isEmpty(props.params));
  const [params, setParams] = useState<LinkAdvanceSettingsState["params"]>(
    props.params ?? {}
  );
  const [autoReload, setAutoReload] = useState<
    LinkAdvanceSettingsState["autoReload"]
  >(props.autoReload ?? false);
  const [autoReloadDurationMs, setAutoReloadDurationMs] = useState<
    LinkAdvanceSettingsState["autoReloadDurationMs"]
  >(
    props.autoReloadDurationMs && props.autoReloadDurationMs >= 5000
      ? props.autoReloadDurationMs
      : 3600000
  );
  const [cacheBusting, setCacheBusting] = useState<
    LinkAdvanceSettingsState["cacheBusting"]
  >(props.cacheBusting ?? false);

  let debounceUpdateCustomDuration;

  useEffect(() => {
    if (!isMountRef.current) {
      cancelDebounceUpdateCustomDuration();
      debounceUpdateCustomDuration = setTimeout(() => {
        props.callBack &&
          props.callBack({
            cacheBusting,
            autoReload,
            autoReloadDurationMs,
            params,
          });
      }, DEBOUNCE_TIMEOUT_MS);
    }
    isMountRef.current = false;
    return () => {
      cancelDebounceUpdateCustomDuration();
    };
  }, [params, autoReload, autoReloadDurationMs, cacheBusting, addParamEnable]);

  const handleDisableCacheChange = (e, data) => {
    setCacheBusting(data.checked);
  };

  const handleAutoReloadChange = (e, data) => {
    setAutoReload(data.checked);
  };

  const handleAddParamChange = (e, data) => {
    setAddParamEnable(data.checked);
    setParams({});
  };

  const onSaveDataTable = (dataparams: Record<string, string>) => {
    setParams(dataparams);
  };

  const cancelDebounceUpdateCustomDuration = () => {
    if (debounceUpdateCustomDuration) {
      clearTimeout(debounceUpdateCustomDuration);
      debounceUpdateCustomDuration = null;
    }
  };

  const onAutoDurationChange = useCallback(
    (value: number) => {
      setAutoReloadDurationMs(value);
    },
    [props.url]
  );

  return (
    <Styled>
      <div className="link-cache-option">
        <div className="link-cache-option-item">
          <span>Disable Cache</span>
          <Checkbox
            data-testid="link-cache-busting"
            toggle
            checked={cacheBusting}
            onChange={handleDisableCacheChange}
          />
        </div>
        <div className="link-cache-option-item">
          <span>Automatically reload page</span>
          <Checkbox
            data-testid="link-auto-reload"
            toggle
            checked={autoReload}
            onChange={handleAutoReloadChange}
          />
        </div>
        {autoReload ? (
          <div
            className="link-cache-option-item align-right"
            data-testid="link-auto-reload-duration"
          >
            <span>Every</span>
            <DurationInput
              showBorder={true}
              defaultValue={3600000}
              max={86399000}
              min={5000}
              value={autoReloadDurationMs}
              onDurationValueChange={onAutoDurationChange}
            />
          </div>
        ) : null}
        <div className="link-cache-option-item">
          <span>Add URL parameters</span>
          <Checkbox
            data-testid="link-add-params"
            toggle
            checked={addParamEnable}
            onChange={handleAddParamChange}
          />
        </div>
        {addParamEnable ? (
          <>
            <div className="link-cache-option-item-description">
              {props.url}
              <strong>?key=value</strong>
            </div>
            <div
              className="link-cache-option-item"
              data-testid="link-data-table"
            >
              <DataTable value={params} onSaved={onSaveDataTable} />
            </div>
          </>
        ) : null}
      </div>
    </Styled>
  );
};

export default memo(LinkAdvanceSettings);
