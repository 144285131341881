import { useState, useEffect } from "react";
import { UUID } from "@screencloud/uuid";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import {
  MAXIMUM_FETCH_MORE_ITEMS,
  MINIMUM_FETCH_MORE_ITEMS,
  FIRST_FETCH_ITEMS,
} from "../constants/constants";
import {
  ChannelListQueryVariables,
  ChannelFilter,
  Maybe,
  Exact,
  useChannelListQuery,
  useSharedChannelListQuery,
  ChannelListItemFragment,
  Channel,
  useAllChannelInSpaceQuery,
} from "../types.g";
import { useAppContext } from "./useAppContext";
export interface UseChannelList {
  channels: ChannelListItemFragment[];
  hasNextPage: boolean;
  loadMore: () => void;
  loading: boolean;
  refetch: (
    variables?: Partial<
      Exact<{ spaceId: any; endCursor: any; orderBy: Maybe<ChannelFilter[]> }>
    >
  ) => void;
  isFirstTimeAlreadyLoaded: boolean;
  totalCount: number;
  variables: ChannelListQueryVariables;
  renderFetchMoreButton: React.ReactNode;
}

export enum ChannelQueryType {
  ALL,
  CURENT_SPACE_ONLY,
  SHARED_FROM_OTHER_SPACE_ONLY,
}

export interface Props {
  queryType: ChannelQueryType;
  skip?: boolean;
  spaceId?: UUID;
}

export function useChannels(props: Props) {
  const { queryType, skip } = props;
  const context = useAppContext();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isFirstTimeAlreadyLoaded, setIsFirstTimeAlreadyLoaded] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const spaceId = props.spaceId ?? context?.currentSpace?.id;

  const variables: ChannelListQueryVariables = {
    spaceId,
    first: FIRST_FETCH_ITEMS,
    endChannel: null,
  };
  const channelQuery = {
    [ChannelQueryType.SHARED_FROM_OTHER_SPACE_ONLY]: useSharedChannelListQuery,
    [ChannelQueryType.CURENT_SPACE_ONLY]: useChannelListQuery,
    [ChannelQueryType.ALL]: useAllChannelInSpaceQuery,
  }[queryType];

  const { data: channelListQuery, fetchMore, refetch, loading } = channelQuery({
    variables,
    fetchPolicy: "cache-first",
    skip: Boolean(skip),
  });

  const loadMore = () => {
    if (!isLoading) {
      setIsLoading(true);
      const endChannel =
        channelListQuery?.spaceById?.publishedChannelsBySpaceId.pageInfo
          .endCursor;
      fetchMore({
        variables: {
          first:
            fetchCount > 1
              ? MAXIMUM_FETCH_MORE_ITEMS
              : MINIMUM_FETCH_MORE_ITEMS,
          endChannel,
        },
      }).then(() => {
        setIsLoading(false);
        setFetchCount(fetchCount + 1);
      });
    }
  };

  useEffect(() => {
    setHasNextPage(
      Boolean(
        channelListQuery?.spaceById?.publishedChannelsBySpaceId.pageInfo
          .hasNextPage
      ) &&
        channelListQuery?.spaceById?.publishedChannelsBySpaceId?.nodes!
          .length! <
          channelListQuery?.spaceById?.publishedChannelsBySpaceId?.totalCount!
    );
  }, [
    channelListQuery?.spaceById?.publishedChannelsBySpaceId.pageInfo!
      .hasNextPage,
    channelListQuery?.spaceById?.publishedChannelsBySpaceId?.totalCount,
  ]);

  useEffect(() => {
    setTotalCount(
      channelListQuery?.spaceById?.publishedChannelsBySpaceId.totalCount ?? 0
    );
  }, [channelListQuery?.spaceById?.publishedChannelsBySpaceId.totalCount]);

  useEffect(() => {
    setIsFirstTimeAlreadyLoaded(Boolean(channelListQuery?.spaceById));
  }, [channelListQuery?.spaceById]);

  const currentItemsCount =
    channelListQuery?.spaceById?.publishedChannelsBySpaceId.nodes.length ?? 0;

  return {
    channels: (channelListQuery?.spaceById?.publishedChannelsBySpaceId?.nodes ??
      []) as Channel[],
    hasNextPage,
    loadMore,
    refetch,
    loading,
    isFirstTimeAlreadyLoaded,
    totalCount,
    variables,
    renderFetchMoreButton: renderFetchMorePickerButton(
      currentItemsCount,
      totalCount,
      isLoading,
      hasNextPage,
      loadMore
    ),
  };
}

/**
 *
 * @returns Channel in current space and channel shared from other spaces
 *
 */
export function useAllChannelsInCurrentSpace({
  spaceId,
  skip,
}: {
  spaceId?: string;
  skip?: boolean;
}) {
  return useChannels({ queryType: ChannelQueryType.ALL, spaceId, skip });
}

/**
 *
 * @returns Channel in current space excluding channel shared from other space
 */
export function useChannelsInCurrentSpaceOnly() {
  return useChannels({ queryType: ChannelQueryType.CURENT_SPACE_ONLY });
}

/**
 *
 * @returns Channel in current space excluding channel shared from other space
 */
export function useSharedChannelFromOtherSpaces() {
  return useChannels({
    queryType: ChannelQueryType.SHARED_FROM_OTHER_SPACE_ONLY,
  });
}
