import * as React from "react";
import { CheckboxProps } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

import {
  Button,
  Checkbox,
  Icon,
  ModalSize,
} from "@screencloud/screencloud-ui-components";

import ChannelThemePicker from "src/components/ChannelThemePicker";
import StyledTag from "src/components/Tag";
import ThemePreview from "src/components/ThemePane/ThemePreview";
import { ApolloProps, withData } from "src/components/ThemePane/apollo";
import { getSelectedTheme, getThemes } from "src/components/ThemePane/utils";
import { useAppContext } from "src/hooks/useAppContext";

import { ThemeConfig } from "src/pages/Admin/Themes";
import { Styled } from "src/components/ThemePane/styles";

export interface ThemeOption {
  name: string;
  id: string;
  config: ThemeConfig;
}
interface ThemePaneProps {
  selectedThemeId: string | null;
  onThemeSelectedCallback: (selectedThemeId: string | null) => void;
  defaultThemeId: string | null;
}

const ThemePane = (props: ThemePaneProps & ApolloProps) => {
  const context = useAppContext();
  const {
    allThemes,
    defaultThemeId,
    loading,
    selectedThemeId,
    onThemeSelectedCallback,
  } = props;

  const [enableThemes, setEnableThemes] = React.useState<boolean>(
    !!selectedThemeId
  );

  const themes: ThemeOption[] = React.useMemo(
    () => getThemes(allThemes?.nodes, defaultThemeId),
    [allThemes, defaultThemeId]
  );
  const selectedTheme: ThemeOption = React.useMemo(
    () => getSelectedTheme(themes, selectedThemeId),
    [themes, selectedThemeId]
  );

  const onToggleEnableThemes = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      event.preventDefault();
      setEnableThemes(!enableThemes);
      if (themes.length > 0) {
        if (enableThemes) {
          onThemeSelectedCallback(null);
        } else {
          onThemeSelectedCallback(selectedTheme.id);
        }
      }
    },
    [enableThemes, themes, selectedTheme]
  );

  const openThemeModal = (): any => {
    context.modal.openModal(
      <ChannelThemePicker
        defaultSelectedThemeId={selectedThemeId}
        {...props}
      />,
      "Channel Theme",
      {
        opts: {
          className: "fix-height md-themes",
          closeOnDimmerClick: true,
          disableTitle: true,
          overflow: false,
          size: ModalSize.MEDIUM,
        },
      }
    );
  };

  const renderThemeOptions = () => {
    if (loading) {
      return <div> Loading ...</div>;
    }
    if (themes.length === 0) {
      return (
        <div className="no-themes">
          <Icon name="info" />
          <span>
            No themes created yet.
            <br />
            <span className="no-theme-desc">
              Go to{" "}
              <a
                href="/themes"
                className="theme-link"
                data-testid="theme-link-setting"
              >
                Theme Settings
              </a>{" "}
              to add one.
            </span>
          </span>
        </div>
      );
    }

    return (
      <div className="theme-cover">
        <div className="theme-info">
          <ThemePreview config={selectedTheme.config} />
          <span className="theme-title">
            <p>{selectedTheme.name}</p> &nbsp;{" "}
            {selectedTheme.id === defaultThemeId && (
              <StyledTag color="#09CD77">
                <span data-testid="default-tag">
                  {context.intl.formatMessage({
                    defaultMessage: "DEFAULT",
                    id: "admin.themes.default_caps",
                  })}
                </span>
              </StyledTag>
            )}
          </span>
        </div>
        <Button data-testid="switch-theme-button" onClick={openThemeModal} mini>
          <FormattedMessage
            id="theme_preview.switch"
            defaultMessage="Switch Theme"
          />
        </Button>
      </div>
    );
  };

  return (
    <Styled className="component">
      <div className="theme-header">
        <div className="text-container">
          <div className="title">
            <FormattedMessage
              id="theme_preview.apply_theme"
              defaultMessage="Apply Theme"
            />
          </div>
          <div className="description">
            <FormattedMessage
              id="theme_preview.choose_theme"
              defaultMessage="Choose a theme to consistently style all content within this channel."
            />
          </div>
        </div>
        <Checkbox
          className="theme-pane-enable"
          toggle
          checked={enableThemes}
          onChange={onToggleEnableThemes}
        />
      </div>
      {enableThemes && renderThemeOptions()}
    </Styled>
  );
};

export default withData(ThemePane) as React.ComponentType<ThemePaneProps>;
