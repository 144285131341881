import { getOnCastableDeletedRefetchQueries } from "src/domains/castableEntity/events/onCastableEntityDeleted";
import { useOnLinkDeleted } from "src/domains/link/events/useOnLinkDeleted";
import {
  DeleteLinkByIdMutationVariables,
  useDeleteLinkByIdMutation,
} from "../../../types.g";

export interface UseDeleteLink {
  deleteLinkById: (linkId: string) => Promise<any>;
}

export function useDeleteLink(): UseDeleteLink {
  const [deleteLinkByIdMutation] = useDeleteLinkByIdMutation();
  const { onLinkDeleted } = useOnLinkDeleted();

  const deleteLinkById = (linkId: string) => {
    const variables: DeleteLinkByIdMutationVariables = {
      input: {
        id: linkId,
      },
    };

    return deleteLinkByIdMutation({
      variables,
      update: (_, mutaionResult) => {
        const link = mutaionResult.data?.deleteLinkById?.link;
        if (link) {
          onLinkDeleted({ link });
        }
      },
      refetchQueries: getOnCastableDeletedRefetchQueries(),
    });
  };

  return { deleteLinkById };
}
