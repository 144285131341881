import { Modal, ModalSize } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { PrimaryButton } from "../../../../../helpers/whiteLabel";
import { OAUTH2_PROVIDER_URN } from "../../../constants";
import { IdentityProvider } from "../../../models/IdentityProvider";

interface CredentialProvidersModalState {
  selectedProvider?: IdentityProvider;
}

interface CredentialProvidersModalProps {
  identityProviders: IdentityProvider[];
  onProviderSelected: (provider?: IdentityProvider) => void;
}

class CredentialProvidersModal extends React.Component<
  CredentialProvidersModalProps,
  CredentialProvidersModalState
> {
  constructor(props: CredentialProvidersModalProps) {
    super(props);

    this.state = {};
  }

  public render(): React.ReactNode {
    const { selectedProvider } = this.state;
    const { identityProviders } = this.props;

    const providers = !identityProviders
      ? []
      : identityProviders
          .filter((provider) => provider.type.startsWith(OAUTH2_PROVIDER_URN))
          .map((provider) => (
            <div
              className={`provider-card ${
                provider === selectedProvider ? "selected" : ""
              }`}
              key={provider.id}
              onClick={() => {
                this.setState({
                  selectedProvider: provider,
                });
              }}
            >
              <span className="icon">
                <img src={provider.iconUrl || ""} />
              </span>
              <span className="label">{provider.name}</span>
            </div>
          ));

    return (
      <Modal
        open={true}
        size={ModalSize.MEDIUM}
        title="Select Credential Provider"
        overflow
        onClose={() => {
          this.props.onProviderSelected(undefined);
        }}
        className="credential-provider-modal"
      >
        <section className="provider-select-modal">
          <div className="provider-tile-group">{providers}</div>

          <div className="model-footer">
            <PrimaryButton
              disabled={!selectedProvider}
              onClick={() => {
                this.props.onProviderSelected(selectedProvider);
              }}
            >
              Continue
            </PrimaryButton>
          </div>
        </section>
      </Modal>
    );
  }
}

export default CredentialProvidersModal;
